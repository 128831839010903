import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import StatusUpdated from "../../../components/general/StatusUpdated";
import {
  BASE_URL,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
  PUT_HOLIDAY_STAFF_MEMBER_URL,
} from "../../../env";
import {
  getRecords,
  updateRecords,
  catchAsync,
  isSuccess,
  formateDate,
  calculateHours,
  filterColumns,
  filterRowsByProperty,
  displayEnumProperty,
} from "../../../helper/helper";

function HolidaysAbsence({ staffHeadingBlock }) {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setRecords] = useState([]);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      staffRequesterFields: "_id email name profile_picture_url",
      staffMemberFields: "staffMemberName",
      usersFields: "profile_picture_url",
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(
      GET_STAFFS_HOLIDAYS_DETAILS_URL,
      apiQuery
    );
    processFetchRecords("get", response);
  });

  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const userData = data?.data?.staffHolidayRequests?.map((item) => item);
      setIsLoading(false);
      setRecords(userData);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        return (
          <span className="usersNameInfo">
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.staffRequester?.profile_picture_url}`}
                alt={`${row?.staffRequester?.name}`}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.staffRequester?.name}`}</span>
            </span>
          </span>
        );
      },
      width: "35%",
    },
    {
      name: "Date",
      selector: (row) => {
        const formatEdDate = formateDate(row?.createdAt, "m/d/y");
        const formatEdHours = calculateHours(
          row?.hldRqStartDate,
          row?.hldRqEndDate
        );
        return (
          <>
            <span className="tableText danger">
              {formatEdDate}
              <br />
              {formatEdHours}hrs
            </span>
          </>
        );
      },
      width: "25%",
    },
    {
      name: "Type",
      selector: (row) => {
        const hldRequestType = displayEnumProperty(
          "hldRequestType",
          row?.hldRequestType
        );
        return hldRequestType;
      },
      width: "20%",
    },
    {
      name: "Remain Hours",
      selector: (row) => {
        return <span>{`${row?.hldRqTotalHours}`} Hours</span>;
      },
      width: "20%",
    },
  ];
  const filteredColumns = filterColumns(columns, (column) => column !== null);
  const filteredRows = filterRowsByProperty(
    userData,
    (row) => row?.staffRequester?.name && row?.hldRqStatus !== undefined
  );
  return (
    <>
      <h4>{staffHeadingBlock}</h4>
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable maxWidthTableRowWidth"
          columns={filteredColumns}
          data={filteredRows}
          fixedHeader
          fixedHeaderScrollHeight="340px"
          highlightOnHover
          responsive={true}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
        />
      </div>
    </>
  );
}

export default HolidaysAbsence;
