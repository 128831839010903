import React, { useState, useEffect } from "react";
import humanBody from "../../assets/images/humanBody.svg";
import circleHealthIssue from "../../assets/images/icons/circleHealthIssue.svg";
import Layout from "../../components/layouts/Layout";
import UserDetailReview from "../../components/user-detail/UserDetailReview";

import { Modal } from "react-bootstrap";
import AddHealthIssues from "./AddHealthIssues";
import { GET_HEALTH_ISSUES_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  handleRedirectIfProfileNotPresent
} from "../../helper/helper";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import HealthIssuePopover from "./HealthIssuePopover";
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import ActiveIssues from "./issues/ActiveIssues";
import ResolvedIssues from "./issues/ResolvedIssues";
import AllIssues from "./issues/AllIssues";
import { useNavigate } from "react-router-dom";
import UserDetailReviewVertical from "../../components/user-detail/UserDetailReviewVertical";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';

function CurrentHealthIssues() {

  // const storedUserDetails = localStorage.getItem("profileUserDetails");
  // const [userDetails, setuserDetail] = useState(storedUserDetails)
  // console.log("🚀 ~ file: CurrentHealthIssues.jsx:35 ~ CurrentHealthIssues ~ userDetails:", userDetails)
  // const navigate = useNavigate();
  // useEffect(() => {
  //   // Check if storedUserDetails is not undefined and not null
  //   if (storedUserDetails !== undefined && storedUserDetails !== null) {
  //     // Update the state with parsed JSON data
  //     setuserDetail(JSON.parse(storedUserDetails));
  //   }
  //   else{
  //     handleRedirectIfProfileNotPresent('profileUserDetails', '/service-users', navigate)
  //   }
  // }, [navigate]);

  // Variables
  const [show, setShow] = useState(false);
  const [activePopover, setActivePopover] = useState(null);
  const [showAddHealthIssuesModal, setShowAddHealthIssuesModal] = useState(false);
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  console.log("🚀 ~ CurrentHealthIssues ~ userDetails:", userDetails)
  const [healthIssueListData, setHealthIssueListData] = useState([]);
  const [userId, setUserId] = useState(userDetails?.modelId?._id);
  const [isLoading, setIsLoading] = useState(true);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [issueStatus, setIssueStatus] = useState("");
  const apiQuery = {
    userId: userId,
  };
  // console.log("🚀 ~ file: CurrentHealthIssues.jsx:61 ~ CurrentHealthIssues ~ apiQuery:", apiQuery)
  const [healthIssues, setHealthIssues] = useState([]);
  const [healthIssuesStatus, setHealthIssuesStatus] = useState({});


  // Functions

  // Get latest data on modal hide
  const handleShowHide = () => {
    setShow(!show);
  }
  useEffect(() => {

    fetchAllRecords();

  }, [show]);



  // Fetch All Health Issues 
  const processEnquiryData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;
      // console.log("🚀 ~ file: CurrentHealthIssues.jsx:81 ~ processEnquiryData ~ allData:", allData)
      if (allData && allData.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        // setRecords(allData);
        setHealthIssueListData(allData);
      }
    }
  };
  const fetchAllRecords = catchAsync(async () => {
    const getHealthIssues = await getRecords(GET_HEALTH_ISSUES_URL, apiQuery);
    console.log("🚀 ~ file: CurrentHealthIssues.jsx:94 ~ fetchAllRecords ~ getHealthIssues:", getHealthIssues)
    processEnquiryData("get", getHealthIssues);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleSuccessSubmission = () => {
    fetchAllRecords();
  };

  // useEffect to get Health Issues list
  useEffect(() => {
    const uniqueCategories = Array.from(new Set(
      healthIssueListData
        .filter(item => item?.category?.enum)
        .map(item => JSON.stringify({
          name: item?.category?.name,
          enum: item?.category?.enum,
          status: item?.status
        }))
    )).map(unique => JSON.parse(unique));

    setHealthIssues(uniqueCategories);

    const initialStatus = uniqueCategories.reduce((acc, category) => {
      return {
        ...acc,
        [category.enum]: false,
      };
    }, {});
    setHealthIssuesStatus(initialStatus);
  }, [healthIssueListData]);


  const handlePopoverClick = (placement) => {
    if (activePopover === placement) {
      setActivePopover(null);
    } else {
      setActivePopover(placement);
    }
  };



  return (
    <>
      <Layout pageTitleText="Health Issues">
        <div className="profileDetailWrapper currentHealthIssuesWrapper">
          <div className="row">
            <div className="col-md-3">
              <UserDetailReviewVertical
                getUserRecord={userDetails}
                avatarActionButtons={false}
                viewNotes={false}
                profileDetail={false}
                verticalDetail={true}
              />
            </div>
            <div className="col-md-9">
              <div className="userDetail currentHealthIssues">
                {/* <StaffRecordHeader /> */}
                <header>
                  <div className="titleandButton">
                    <h3>Current Health Issues</h3>
                    <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                      <span
                        onClick={handleShowHide}
                        className="btn btn-sm btn-primary"
                      >
                        {" "}
                        Add Health Issues
                      </span>
                    </ProtectedComponent>
                  </div>
                </header>
                <Tabs
                  defaultActiveKey="allIssues"
                  id="uncontrolled-tab-example"
                  className="tabContainer"
                >
                  <Tab eventKey="allIssues" title="All Issues">
                    <AllIssues
                      allHealthIssues={healthIssues}
                      allHealthIssueListData={healthIssueListData}
                      handleSuccessSubmission={handleSuccessSubmission}
                      noRecordFound={noRecordFound}
                      isLoading={isLoading}
                    />
                  </Tab>
                  <Tab eventKey="activeIssues" title="Active Issues">
                    <ActiveIssues
                      allHealthIssues={healthIssues}
                      allHealthIssueListData={healthIssueListData}
                      handleSuccessSubmission={handleSuccessSubmission}
                      noRecordFound={noRecordFound}
                      isLoading={isLoading}
                    />
                  </Tab>
                  <Tab eventKey="resolvedIssues" title="Resolved Issues">
                    <ResolvedIssues
                      allHealthIssues={healthIssues}
                      allHealthIssueListData={healthIssueListData}
                      handleSuccessSubmission={handleSuccessSubmission}
                      noRecordFound={noRecordFound}
                      isLoading={isLoading}
                    />
                  </Tab>
                </Tabs>


              </div>
            </div>
          </div>
        </div>
      </Layout>
      <ProtectedComponent requiredPermissions={[permissions.ADD]}>
        <Modal
          show={show}
          onHide={handleShowHide}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Health Issue</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <AddHealthIssues handleShowHide={setShow} userDataId={userId} />
          </Modal.Body>

        </Modal>
      </ProtectedComponent>
    </>
  );
}

export default CurrentHealthIssues;
