import React, { useState } from "react";
import ProfileDetails from "../../components/user-detail/ProfileDetails";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import AllDocuments from "./AllDocuments";

function DocumentsIndividual({ userIdData }) {
  const [userId, setUserId] = useState(parseInt(userIdData));

  return (
    <>
      <div className="profileDetailWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails userIdData={userId} />
          </div>
          <div className="col-md-9">
            <div className="userDetail documentdDetail rowClickUnset">
              <UserDetailHeader addNewDocument={true} />
              <AllDocuments />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default DocumentsIndividual;
