import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import { addPbsPlansStock } from "../../schemas";
import { useFormik } from "formik";
import { Formik, Form, ErrorMessage } from 'formik';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_PBS_PLANS_URL } from "../../env";
import {
  sampleDataAboutPlan,
  sampleDataMOBP,
  sampleDataSecondaryPrevention,
  sampleDataReactiveStartegies,
  sampleDataPostIncidentSupport
} from "./SamplePbsData";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import Layout from '../../components/layouts/Layout';
import { Link } from 'react-router-dom';

function AddPbsPlans() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("staffId", userId)
  };

  const editor = useRef(null);
  const [aboutPlan, setAboutPlan] = useState('');
  const [managementOfBehaviorPlan, setManagementOfBehaviorPlan] = useState('');
  const [secondaryPrevention, setSecondaryPrevention] = useState('');
  const [reactiveStartegies, setReactiveStartegies] = useState('');
  const [postIncidentSupport, setPostIncidentSupport] = useState('');

  const [suFormData, setSuFormData] = useState({

    userId: userDetails?._id,
    staffId: selectedUserId,
    aboutPlan: "Start writing",
    managementOfBehaviorPlan: "Start writing",
    secondaryPrevention: "Start writing",
    reactiveStartegies: "Start writing",
    postIncidentSupport: "Start writing",

  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addPbsPlansStock,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postAddPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "/pbs-plans", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
      }, 2000);
    }
  };

  const postAddPbsPlansFormData = catchAsync(async (req, res) => {
    const updatedValues = {
      ...values,
      aboutPlan: aboutPlan,
      managementOfBehaviorPlan: managementOfBehaviorPlan,
      secondaryPrevention: secondaryPrevention,
      reactiveStartegies: reactiveStartegies,
      postIncidentSupport: postIncidentSupport,

    }
    const response = await postRecords(POST_PBS_PLANS_URL, updatedValues);
    console.log("🚀 ~ postAddPbsPlansFormData ~ updatedValues:", updatedValues)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddPbsPlansFormData", response);
  });


  const loadSamplePbsData = (fieldName, sampleData, fieldVariable) => {
    setFieldValue(fieldName, sampleData)
    fieldVariable(sampleData)
  }

  return (
    <>
      <Layout
        pageTitleText={
          <>
            PBS Plans
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent">
            <div className="mainContentText">
              <h3>Add New Record</h3>

              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="staffId"
                          onSelect={handleUserSelect}
                          filterBy={""}
                          label={"Select Staff"}
                          customError={touched?.staffId && errors?.staffId}
                          errorMessage={errors?.staffId}
                          errorClass={
                            touched?.staffId && errors?.staffId
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark">
                          <h3>About Plan</h3>
                          <span onClick={() => { loadSamplePbsData("aboutPlan", sampleDataAboutPlan, setAboutPlan) }} className='btn btn-sm btn-success'>Load Sample Data</span>
                        </header>
                        <JoditEditor
                          name="aboutPlanEditor"
                          ref={editor}
                          value={values?.aboutPlan}
                          tabIndex={1}
                          onChange={(newContent) => { setAboutPlan(newContent) }}
                          onBlur={handleBlur}
                        />
                        {/* {errors.aboutPlanEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.aboutPlanEditor}</div>
                          </div>
                        )} */}


                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark mobpBg">
                          <h3>Management of behavioural presentation</h3>
                          <span onClick={() => { loadSamplePbsData("managementOfBehaviorPlan", sampleDataMOBP, setManagementOfBehaviorPlan) }} className='btn btn-sm btn-success'>Load Sample Data</span>
                        </header>
                        <JoditEditor
                          name="managementOfBehaviorPlanEditor"
                          ref={editor}
                          value={values?.managementOfBehaviorPlan}
                          tabIndex={2}
                          onChange={(newContent) => { setManagementOfBehaviorPlan(newContent) }}
                          onBlur={handleBlur}
                          className='mobp'
                        />
                        {/* {errors.managementOfBehaviorPlanEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.managementOfBehaviorPlanEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark spBg">
                          <h3>Secondary Prevention</h3>
                          <span onClick={() => { loadSamplePbsData("secondaryPrevention", sampleDataSecondaryPrevention, setSecondaryPrevention) }} className='btn btn-sm btn-success'>Load Sample Data</span>
                        </header>
                        <JoditEditor
                          name="secondaryPreventionEditor"
                          ref={editor}
                          value={values?.secondaryPrevention}
                          tabIndex={3}
                          onChange={(newContent) => { setSecondaryPrevention(newContent) }}
                          onBlur={handleBlur}
                          className='secondaryPrevention'
                        />
                        {/* {errors.secondaryPreventionEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.secondaryPreventionEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark rsBg">
                          <h3>Reactive Strategies</h3>
                          <span onClick={() => { loadSamplePbsData("reactiveStartegies", sampleDataReactiveStartegies, setReactiveStartegies) }} className='btn btn-sm btn-success'>Load Sample Data</span>
                        </header>
                        <JoditEditor
                          name="reactiveStartegiesEditor"
                          ref={editor}
                          value={values?.reactiveStartegies}
                          tabIndex={4}
                          onChange={(newContent) => { setReactiveStartegies(newContent) }}
                          onBlur={handleBlur}
                          className='reactiveStartegies'
                        />
                        {/* {errors.reactiveStartegiesEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.reactiveStartegiesEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark pisrBg">
                          <h3>Post Incident Support- Recovery</h3>
                          <span onClick={() => { loadSamplePbsData("postIncidentSupport", sampleDataPostIncidentSupport, setPostIncidentSupport) }} className='btn btn-sm btn-success'>Load Sample Data</span>
                        </header>
                        <JoditEditor
                          name="postIncidentSupportEditor"
                          ref={editor}
                          value={values?.postIncidentSupport}
                          tabIndex={5}
                          onChange={(newContent) => { setPostIncidentSupport(newContent) }}
                          onBlur={handleBlur}
                          className='pisr'
                        />
                        {/* {errors.postIncidentSupportEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.postIncidentSupportEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>

                  </div>
                </div>
                <div className="formActionButtons mt-5">
                  <Link to="/pbs-plans">
                    <span
                      className="btn btn-sm btn-outline-primary"
                    >
                      Cancel
                    </span>
                  </Link>
                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default AddPbsPlans;