import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import loadingGif from "../../../assets/images/icons/loadingGif.gif";
import camera from "../../../assets/images/icons/camera.svg";
import Input from "../../../components/forms-fields/Input";
import GroupMembersListing from "./GroupMembersListing";
import {
  ADMIN_ID,
  BASE_URL,
  GET_GROUP_INFO_URL,
  PUT_UPDATE_GROUP_DETAILS_URL,
  PUT_GROUP_PROFILE_PICTURE_URL,
} from "../../../env";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Select from "react-select";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  getRecords,
  formateDate,
  toastSuccessMessages,
} from "../../../helper/helper";
import {
  convertMillisecondsToTime,
  defaultHours,
  statusOptions,
  CustomOption,
  calculateTimeDifference,
  uploadGroupFile,
} from "../../../helper/helperJsx";

function GroupInfo({
  handleShowModel,
  handleCloseModel,
  groupItem,
  fetchCompleteRecords,
}) {
  console.log("🚀 ~ groupItem:", groupItem)
  const [isToast, setIsToast] = useState(false);
  const [groupInfoState, setGroupInfoState] = useState(false);
  // const [startTimeState, setStartTimeState] = useState(false);
  // const [endTimeState, setEndTimeState] = useState(false);
  // const [totalWorkHoursState, setTotalWorkHoursState] = useState(false);
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingIcon, setIsLoadingIcon] = useState(false);
  const [profilePicture, setProfilePicture] = useState(false);
  const [showGroupMembersModal, setShowGroupMembersModal] = useState(false);

  const apiQuery = {
    groupId: groupItem && groupItem?._id,
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_GROUP_INFO_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data && data?.data;
      // const startTime = data?.data?.groupWorkingScheduleTime?.startTime;
      // const endTime = data?.data?.groupWorkingScheduleTime?.endTime;
      // const totalWorkHours =
      //   data?.data?.groupWorkingScheduleTime?.totalWorkHours;
      const document = {
        groupId: result?._id,
        groupName: result?.groupName,
        groupImage: result?.groupImage,
        // groupWorkingScheduleTime: {
        //   startTime: result?.groupWorkingScheduleTime?.startTime,
        //   endTime: result?.groupWorkingScheduleTime?.endTime,
        //   totalWorkHours: result?.groupWorkingScheduleTime?.totalWorkHours,
        // },
        isActive: result?.isActive,
      };
      
      // if (startTime && endTime !== 0) {
      //   const convertedStartTime = convertMillisecondsToTime(startTime);
      //   const convertedEndTime = convertMillisecondsToTime(endTime);
      //   setStartTimeState(convertedStartTime);
      //   setEndTimeState(convertedEndTime);
      //   setTotalWorkHoursState(totalWorkHours);
      // } else {
      //   setStartTimeState("");
      //   setEndTimeState("");
      //   setTotalWorkHoursState(defaultHours);
      // }
      setProfilePicture(BASE_URL + result?.groupImage);
      setValues(document);
      setGroupInfoState(result);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      await fetchCompleteRecords();
      handleCloseModel();
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const validationSchema = Yup.object().shape({
    groupName: Yup.string().required("Group Name is required."),
    // groupWorkingScheduleTime: Yup.object().shape({
    //   startTime: Yup.number().required("Start Time is required."),
    //   endTime: Yup.number()
    //     .required("End Time is required")
    //     .min(
    //       Yup.ref("groupWorkingScheduleTime.startTime"),
    //       "End Time must be greater than Start Time"
    //     ),
    //   totalWorkHours: Yup.string().required("Total Work Hours is required."),
    // }),
  });

  const formData = useFormik({
    initialValues: {
      groupName: "",
      groupImage: null,
      // groupWorkingScheduleTime: {
      //   startTime: null,
      //   endTime: null,
      //   totalWorkHours: "",
      // },
      isActive: null,
      lastModifiedBy: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitClicked(true);
        await handleUpdateGroupInfo(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          1000,
          1000
        );
        setIsFormSubmitClicked(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = formData;

  const handleUpdateGroupInfo = catchAsync(async (req, res) => {
    const response = await postRecords(PUT_UPDATE_GROUP_DETAILS_URL, req);
    await fetchCompleteRecords();
    processFetchRecords("update", response);
  });

  function getMillisecondsFromTimeString(timeString) {
    const timeMoment = moment(timeString, "HH:mm");
    const midnightMoment = moment().startOf("day");
    const diff = timeMoment.diff(midnightMoment);
    return diff >= 0 ? diff : null;
  }

  const handleTimeChangeNumericFunc = async (e, name) => {
    const timeValue = e?.target?.value;
    const numericValue = getMillisecondsFromTimeString(timeValue);
    if (numericValue) {
      setFieldValue("groupWorkingScheduleTime." + name, numericValue);
    }
  };

  // const fetchData = async (startTime, endTime) => {
  //   try {
  //     if (startTime !== 0 && endTime !== 0) {
  //       const formattedDifference = await calculateTimeDifference(
  //         startTime,
  //         endTime
  //       );
  //       if (formattedDifference) {
  //         setTotalWorkHoursState(formattedDifference);
  //         setFieldValue(
  //           "groupWorkingScheduleTime.totalWorkHours",
  //           formattedDifference
  //         );
  //       }
  //     } else {
  //       setTotalWorkHoursState(defaultHours);
  //     }
  //   } catch (error) {
  //     console.error("Error calculating time difference:", error);
  //   }
  // };

  const handleFileChangeUploadDoc = async (e) => {
    try {
      setIsLoadingIcon(true);
      const selectedUploadDocFile = e.target?.files[0];
      const groupId = groupInfoState && groupInfoState?._id;
      await uploadGroupFile({
        groupId: groupId,
        fileName: "file",
        file: selectedUploadDocFile,
        apiUrl: PUT_GROUP_PROFILE_PICTURE_URL,
        successCallback: (result) => {
          handleFileChange(e);
          setIsLoadingIcon(false);
          fetchAllRecords();
          setIsToast(true);
          toastSuccessMessages(toast?.success, result?.message, "", 1000, 1000);
        },
      });
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfilePicture(event?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  // useEffect(() => {
  //   fetchData(
  //     values?.groupWorkingScheduleTime?.startTime,
  //     values?.groupWorkingScheduleTime?.endTime
  //   );
  // }, [
  //   values?.groupWorkingScheduleTime?.startTime,
  //   values?.groupWorkingScheduleTime?.endTime,
  // ]);

  const renderGroupMembers = (infoState) => {
    if (!infoState) return null;
    return infoState?.map((user, index) => {
      const { profile_picture_url, name } = user;
      return (
        <span
          className="imageChainBlock"
          style={{ zIndex: index }}
          key={`image-${index}`}
        >
          <span className="chainImage">
            <img src={`${BASE_URL}${profile_picture_url}`} alt={name} />
          </span>
        </span>
      );
    });
  };

  const GroupMembers = (state) => {
    const imageElements = renderGroupMembers(state)?.slice(0, 7);
    const moreUsersCount = imageElements?.length;
    return (
      <div
        className="chainBlocks cursor-pointer"
        onClick={(e) => handleShowGroupMembers(e, state)}
      >
        {imageElements}
        {moreUsersCount > 0 && (
          <span className="moreUsersCounts ml-6">{moreUsersCount}</span>
        )}
      </div>
    );
  };

  const handleSubmitFormData = async (e) => {
    e?.preventDefault();
    setIsFormSubmitClicked(true);
    await handleUpdateGroupInfo(values);
  };

  const handleShowGroupMembers = async (e, members) => {
    e?.preventDefault();
    setShowGroupMembersModal(members);
  };

  const handleCloseGroupMembersModal = async () => {
    setShowGroupMembersModal(false);
  };

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Group Information</Modal.Title>
          </Modal.Header>
          <form enctype="multipart/form-data">
            <Modal.Body>
              <div className="mt-1 d-flex-space-between-">
                <div className="group-info mt-2">
                  <p>
                    {groupInfoState
                      ? formateDate(groupInfoState?.createdAt, "m/d/y")
                      : "Loading..."}
                  </p>
                </div>
                <div className="group-info">
                  <p>
                    <span className="member-count">
                      {GroupMembers(
                        groupInfoState && groupInfoState?.groupMembers
                      )}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-sm-12 mb-2 mt-minus">
                <div className="text-center">
                  <label htmlFor="profilePicture" className="upload-icon">
                    {isLoadingIcon ? (
                      <div className="avatarAction">
                        <img src={loadingGif} alt={"Loading..."} />
                      </div>
                    ) : (
                      <div className="avatarAction">
                        <input
                          type="file"
                          className="uploadGroupImageIcon"
                          id="profilePicture"
                          name="profilePic"
                          accept="image/*"
                          style={{ display: "none" }}
                          onChange={(e) => {
                            handleFileChangeUploadDoc(e);
                          }}
                        />
                        <img
                          src={profilePicture}
                          alt={values?.groupName}
                          style={{ cursor: "pointer" }}
                        />
                        <span className="uploadImage uploadGroupImageIcon">
                          <img src={camera} alt="Camera" />
                        </span>
                      </div>
                    )}
                  </label>
                </div>
              </div>
              <div className="col-sm-12">
                <div className="form-group">
                  <Input
                    type="text"
                    name="groupName"
                    value={values?.groupName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelText="Group Name"
                    customError={touched?.groupName && errors?.groupName}
                    errorMessage={errors?.groupName}
                    errorClass={
                      touched?.groupName && errors?.groupName
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                </div>
              </div>
              {/* <div className="row align-items-center">
                <div className="custom-d-grid">
                  <Input
                    type="time"
                    name="startTime"
                    value={values?.groupWorkingScheduleTime?.startTime}
                    onChange={(e) => {
                      handleTimeChangeNumericFunc(e, "startTime");
                      setStartTimeState(e?.target?.value);
                    }}
                    onBlur={handleBlur}
                    labelText="Start Time"
                    customError={touched?.startTime && errors?.startTime}
                    errorMessage={errors?.startTime}
                    errorClass={
                      touched?.startTime && errors?.startTime
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                  <Input
                    type="time"
                    name="endTime"
                    value={values?.groupWorkingScheduleTime?.endTime}
                    onChange={(e) => {
                      handleTimeChangeNumericFunc(e, "endTime");
                      setEndTimeState(e?.target?.value);
                    }}
                    onBlur={handleBlur}
                    labelText="End Time"
                    customError={touched?.endTime && errors?.endTime}
                    errorMessage={errors?.endTime}
                    errorClass={
                      touched?.endTime && errors?.endTime
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                  <div className="ui-ux-color font-size">
                    {totalWorkHoursState + "  "}Hours
                  </div>
                </div>
              </div> */}
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <div className={`customSelectBox `}>
                    <Select
                      name="isActive"
                      value={statusOptions?.find(
                        (option) => option?.value === values?.isActive
                      )}
                      className="basic-multi-select cursor-pointer"
                      classNamePrefix="select"
                      options={statusOptions}
                      onChange={(selectedOptions) => {
                        setFieldValue("isActive", selectedOptions?.value);
                      }}
                      isSearchable
                      getOptionLabel={(option) => option?.label}
                      getOptionValue={(option) => option?.value}
                      components={{ Option: CustomOption }}
                    />
                    <label className="floatingLabel">
                      Status
                      <span className="text-danger">*</span>
                    </label>
                    {errors?.isActive && (
                      <div className="text-danger">{errors?.isActive}</div>
                    )}
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isFormSubmitClicked ? (
                <ProgressComponent />
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={handleCloseModel}
                  >
                    Cancel
                  </Button>
                  <Button
                    className="btn-sm cursor-pointer"
                    type="submit"
                    variant="primary"
                    onClick={handleSubmitFormData}
                  >
                    Update
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {showGroupMembersModal && (
        <GroupMembersListing
          handleShowModel={showGroupMembersModal}
          handleCloseModel={handleCloseGroupMembersModal}
          groupName={groupInfoState && groupInfoState?.groupName}
          groupMembers={showGroupMembersModal}
        />
      )}
      {isToast && <ToastContainer />}
    </>
  );
}

export default GroupInfo;
