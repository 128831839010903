import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../../components/general/ProgressComponent";
import StatusUpdated from "../../../components/general/StatusUpdated";
import {
  BASE_URL,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
  PUT_HOLIDAY_STAFF_MEMBER_URL,
  ADMIN_ID,
} from "../../../env";
import {
  getRecords,
  updateRecords,
  isSuccess,
  formateDate,
  calculateHours,
  filterColumns,
  filterRowsByProperty,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
} from "../../../helper/helper";
import Layout from "../../../components/layouts/Layout";
import moment from "moment";
import { Form } from "react-bootstrap";

function ShiftsActions() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [isSuccessReturn, setIsSuccessReturn] = useState(false);
  const [isToastShiftsActionss, setIsToastShiftsActionss] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      staffRequesterFields: "_id email name profile_picture_url",
      staffMemberFields: "staffMemberName",
      usersFields: "profile_picture_url",
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastShiftsActionss(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const userData = data?.data?.staffHolidayRequests || [];
      console.log(userData);
      setUserData(userData);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      fetchAllRecords();
      setIsToastShiftsActionss(true);
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    try {
      const response = await getRecords(
        GET_STAFFS_HOLIDAYS_DETAILS_URL,
        apiQuery
      );
      processFetchRecords("get", response);
    } catch (error) {
      console.error("Error fetching staff holidays:", error);
    }
  });

  const updateStaffHoliday = catchAsync(async (req, res) => {
    try {
      console.log(req);
      const response = await updateRecords(PUT_HOLIDAY_STAFF_MEMBER_URL, req);
      processFetchRecords("update", response);
    } catch (error) {
      console.error("Error updating staff holidays:", error);
    }
  });

  const handleStatusChange = (event, statusValue, row) => {
    event.preventDefault();

    const startDate = row?.hldRqStartDate;
    const endDate = row?.hldRqEndDate;
    const totalDays = row?.hldRqTotalDays;
    const totalHours = row?.hldRqTotalHours;
    const holidayId = row?._id;
    const status = statusValue;
    const approveBy = ADMIN_ID;
    const staffId = row?.staffRequester?.modelId?._id;

    if (
      startDate &&
      endDate &&
      totalDays &&
      totalHours &&
      holidayId &&
      status &&
      approveBy &&
      staffId
    ) {
      const payload = {
        hldRqStartDate: startDate,
        hldRqEndDate: endDate,
        hldRqTotalDays: totalDays,
        hldRqTotalHours: totalHours,
        staffHolidayRequestid: holidayId,
        hldRqStatus: status,
        approvedBy: approveBy,
        staffRequester: staffId,
      };
      updateStaffHoliday(payload);
    } else {
      console.error("Invalid data for updating status.");
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => <span>{index + 1}</span>,
      width: "5%",
      center: true,
    },
    {
      name: "Staff Member",
      selector: (row) => (
        <span className="usersNameInfo">
          <span className="userImage">
            <img
              src={`${BASE_URL}${row?.staffRequester?.profile_picture_url}`}
              alt={row?.staffRequester?.name}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock">{row?.staffRequester?.name}</span>
          </span>
        </span>
      ),
      width: "200px",
    },
    {
      name: "Shift Date",
      selector: (row) => {
        const formatEdDate = formateDate(row?.createdAt, "m/d/y");
        const formatEdHours = calculateHours(
          row?.hldRqStartDate,
          row?.hldRqEndDate
        );
        return (
          <span className="tableText danger">{moment(row?.createdAt).format("DD/MM/YYYY")}</span>
        );
      },
      width: "200px",
    },
    {
      name: "Service User",
      selector: (row) => (
        <span className="usersNameInfo">
          <span className="userImage">
            <img
              src={`${BASE_URL}${row?.staffRequester?.profile_picture_url}`}
              alt={row?.staffRequester?.name}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock">Ethan Thompson</span>
          </span>
        </span>
      ),
      width: "200px",
    },

    {
      name: "Shift Time",
      selector: (row) => <span><span class="time">20:00 - 08:00</span></span>,
      center: true
    },
    {
      name: "Shift Hours",
      selector: (row) => <span>{`${row?.hldRqTotalHours} hours`}</span>,
      center: true
    },

    {
      name: "Action",
      selector: (row) => (
        <Form.Select
          aria-label="Default select example"
          onChange={handleStatusChange}
        >
          <>
            <option value="approved">Approved</option>
            <option value="pending">Pending</option>
            <option value="rejected">Reject</option>
          </>
        </Form.Select>
      ),
      width: "12%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== "");
  const filteredRows = filterRowsByProperty(
    userData,
    (row) => row?.staffRequester?.name && row?.hldRqStatus !== undefined
  );




  return (
    <>
      <Layout pageTitleText="Shift Actions">
        <div className="mainContent rowClickUnset absenceLeave">
          {/* <h3>Holidays & Absence</h3> */}
          <div className="dataTableWrapper">
            <DataTable
              className="maxWidthTable minWidthTable"
              columns={filteredColumns}
              data={filteredRows}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              fixedHeader
              fixedHeaderScrollHeight="470px"
              highlightOnHover
              responsive={true}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
            />
          </div>
          {isToastShiftsActionss && <ToastContainer />}
        </div>
      </Layout>
    </>

  );
}

export default ShiftsActions;
