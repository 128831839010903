import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddGroupNewMembers from "./AddGroupNewMembers";
import {
  ADMIN_ID,
  BASE_URL,
  GET_LIST_ALL_USERS_URL,
  POST_ADD_NEW_MESSAGE_URL,
  GET_ALL_GROUPS_MESSAGES_URL,
  POST_SEND_MESSAGE_IN_GROUP_URL,
} from "../../../env";
import ProgressComponent from "../../../components/general/ProgressComponent";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  getRecords,
  toastSuccessMessages,
  filterArrayBySearchTerm,
} from "../../../helper/helper";

function ForwardMessage({ handleShowModel, handleCloseModel, groupItem }) {
  const [isToast, setIsToast] = useState(false);
  const [allGroupsData, setAllGroupsData] = useState(false);
  const [isAddNewMembers, setIsAddNewMembers] = useState(false);
  const [allUsersData, setAllUsersData] = useState(false);
  const [isUserIdMatchState, setIsUserIdMatchState] = useState(false);
  const [isIdGroupIdMatchState, setIsIdGroupIdMatchState] = useState(false);
  const [isGroupData, setIsGroupData] = useState(true);
  const [isUserData, setIsUserData] = useState(true);
  const [searchInput, setSearchInput] = useState("");
  const [filteredRows, setFilteredRows] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: {
        active: true,
        role: {
          $in: [
            "serviceuser",
            "staffmember",
            "manager",
            "supervisor",
            "teamlead",
          ],
        },
      },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const groupsApiQuery = {
    sortProperty: "createdAt",
    sortOrder: -1,
    offset: 0,
    limit: 100000,
    query: {
      critarion: { groupMembers: { $in: ADMIN_ID && ADMIN_ID } },
    },
  };

  const fetchAllUsersRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_LIST_ALL_USERS_URL, apiQuery);
    processFetchRecords("getUsers", response);
  });

  const fetchAllGroupsRecords = catchAsync(async (req, res) => {
    if (ADMIN_ID) {
      const response = await getRecords(
        GET_ALL_GROUPS_MESSAGES_URL,
        groupsApiQuery
      );
      processFetchRecords("getGroups", response);
    }
  });

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "getUsers" && data?.status === isSuccess) {
      const filteredUsers = data?.data?.users?.filter((item) => item);
      setFilteredRows(filteredUsers);
      setAllUsersData(filteredUsers);
      setIsUserData(false);
    } else if (method === "getGroups" && data?.status === isSuccess) {
      const result = data && data?.data;
      setAllGroupsData(result);
      setIsGroupData(false);
    }
  };

  const sendUserMessage = async (req) => {
    try {
      const request = {
        from: ADMIN_ID,
        to: req && req?._id,
        message: groupItem && groupItem?.message,
      };
      const result = await postRecords(POST_ADD_NEW_MESSAGE_URL, request);
      console.log(result);
      if (result?.status !== isSuccess) {
        setIsToast(true);
        toastErrorMessages(toast?.error, result?.message, "", 500, 500);
      } else if (result?.status === isSuccess) {
        setIsToast(true);
        toastSuccessMessages(toast?.success, result?.message, "", 500, 500);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const sendGroupMessage = async (req) => {
    try {
      const request = {
        groupId: req && req?._id,
        userId: ADMIN_ID,
        message: groupItem && groupItem?.message,
      };
      const result = await postRecords(POST_SEND_MESSAGE_IN_GROUP_URL, request);
      if (result?.status === isSuccess) {
        setIsToast(true);
        toastSuccessMessages(toast?.success, result?.message, "", 500, 500);
      } else if (result?.status !== isSuccess) {
        setIsToast(true);
        toastErrorMessages(toast?.error, result?.message, "", 500, 500);
        console.error("Message not sent error:", result);
      }
    } catch (error) {
      console.error("Error sending message:", error);
    }
  };

  const handleSendUserMessage = async (e, item) => {
    e?.preventDefault();
    sendUserMessage(item);
    setIsUserIdMatchState(item?._id);
  };

  const handleSendGroupMessage = async (e, item) => {
    e?.preventDefault();
    sendGroupMessage(item);
    setIsIdGroupIdMatchState(item?._id);
  };

  const handleCloseAddNewMemberModel = async () => {
    setIsAddNewMembers(false);
  };

  const UserItem = ({ user, isIdMatchState }) => {
    return (
      <div className="childContainer">
        <div className="usersNameInfo avatarContainer">
          <span className="userImage">
            <img
              src={`${BASE_URL}${user?.profile_picture_url}`}
              alt={user?.name}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock fs-10">{`${user?.name}`}</span>
          </span>
        </div>
        <div className="sendButtonContainer">
          {user?._id === isIdMatchState ? (
            <Button
              className="btn-sm sendButton"
              variant="primary"
              type="submit"
              disabled
            >
              Sent
            </Button>
          ) : (
            <Button
              className="btn-sm sendButton"
              variant="primary"
              type="submit"
              onClick={(e) => handleSendUserMessage(e, user)}
            >
              Send
            </Button>
          )}
        </div>
      </div>
    );
  };

  const GroupItem = ({ group, isIdMatchState }) => {
    return (
      <div className="childContainer">
        <div className="usersNameInfo avatarContainer">
          <span className="userImage">
            <img
              src={`${BASE_URL}${group?.groupImage}`}
              alt={group?.groupName}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock fs-10">{`${group?.groupName}`}</span>
          </span>
        </div>
        <div className="sendButtonContainer">
          {group?._id === isIdMatchState ? (
            <Button
              className="btn-sm sendButton"
              variant="primary"
              type="submit"
              disabled
            >
              Sent
            </Button>
          ) : (
            <Button
              className="btn-sm sendButton"
              variant="primary"
              type="submit"
              onClick={(e) => handleSendGroupMessage(e, group)}
            >
              Send
            </Button>
          )}
        </div>
      </div>
    );
  };

  const handleSearchInputChange = (e) => {
    setSearchInput(e?.target?.value?.toLowerCase());
  };

  const filteredUsers = searchInput
    ? allUsersData &&
      allUsersData?.filter((item) =>
        item?.name?.toLowerCase()?.includes(searchInput)
      )
    : allUsersData;

  const filteredGroups = searchInput
    ? allGroupsData &&
      allGroupsData?.filter((item) =>
        item?.groupName?.toLowerCase()?.includes(searchInput)
      )
    : allGroupsData;

  useEffect(() => {
    fetchAllUsersRecords();
  }, []);

  useEffect(() => {
    fetchAllGroupsRecords();
  }, []);
  return (
    <>
      {isUserData === false && isGroupData === false && (
        <>
          <Modal
            show={handleShowModel}
            onHide={handleCloseModel}
            className="ftcModalPopup"
          >
            <Modal.Header closeButton>
              <Modal.Title>Forward Message</Modal.Title>
            </Modal.Header>
            <form>
              <Modal.Body>
                <div className="searchContainer p-2">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Search by name"
                      value={searchInput}
                      onChange={handleSearchInputChange}
                    />
                  </div>
                </div>
                <div className="forwardMessageModelContainer">
                  {filteredGroups &&
                    filteredGroups?.map((item, index) => (
                      <GroupItem
                        key={index}
                        group={item}
                        isIdMatchState={isIdGroupIdMatchState}
                      />
                    ))}
                  {filteredUsers &&
                    filteredUsers?.map((item, index) => (
                      <UserItem
                        key={index}
                        user={item}
                        isIdMatchState={isUserIdMatchState}
                      />
                    ))}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={handleCloseModel}
                >
                  Close
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </>
      )}
      {isAddNewMembers && (
        <AddGroupNewMembers
          handleShowModel={isAddNewMembers}
          handleCloseModel={handleCloseAddNewMemberModel}
          groupItem={isAddNewMembers}
          fetchRecords={fetchAllUsersRecords}
        />
      )}
      {isUserData && <ProgressComponent />}
      {isGroupData && <ProgressComponent />}
      {isToast && <ToastContainer />}
    </>
  );
}

export default ForwardMessage;
