import { Label } from "@mui/icons-material";
import React, { useState, useEffect } from "react";
import Switch from "react-switch";

const ToggleSwitchButton = ({
  initialChecked,
  onChange,
  labelOn,
  labelOff,
}) => {
  const [isChecked, setIsChecked] = useState(initialChecked);

  const handleSwitchChange = (checked) => {
    const newChecked = checked;
    setIsChecked(newChecked);
    onChange(newChecked);
  };

  useEffect(() => {
    setIsChecked(initialChecked);
  }, [initialChecked]);

  return (
    <div className="onOffSwitchContainer">
      <span>
        <label
          htmlFor="material-switch"
          id="switch-label"
          className="cursor-pointer"
        >
          {isChecked ? labelOn : labelOff}
        </label>
      </span>
      <span>
        <Switch
          onChange={handleSwitchChange}
          checked={isChecked}
          height={20}
          width={40}
          handleDiameter={18}
          className="react-switch"
          id="material-switch"
          aria-labelledby="switch-label"
        />
      </span>
    </div>
  );
};

export default ToggleSwitchButton;
