import React, { useState, useEffect } from "react";
import { BASE_URL, GET_LIST_ALL_USERS_URL } from "../../../env";
import { getRecords, isSuccess, catchAsync } from "../../../helper/helper";
import { useNavigate } from "react-router-dom";
import ProgressComponent from "../../../components/general/ProgressComponent";
import { Modal, Button } from "react-bootstrap";
import { addNewChatSingleUser } from '../../../redux/actions/addNewChatSingleUserAction';
import { connect, useDispatch } from 'react-redux';
function UserList({ handleShowModel, onClose, addNewChatSingleUser }) {
  // console.log("🚀 ~ UserList ~ addNewChatSingleUser:", addNewChatSingleUser)
  const [isLoading, setIsLoading] = useState(true);
  const [allRecords, setRecords] = useState([]);
  // const [userListData, setUserListData] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const dispatch = useDispatch();
  // const [selectedUserIdForNewChat, setSelectedUserIdForNewChat] = useState(selectedUser?._id)

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: {
        active: true,
        role: {
          $in: [
            "serviceuser",
            "staffmember",
            "manager",
            "supervisor",
            "teamlead",
          ],
        },
      },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };


  const processServiceUserData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data?.users;
      if (allData && allData.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        setRecords(allData);
        // setUserListData(allData);
      }
    }
  };

  const getAllServiceUserData = catchAsync(async () => {
    const response = await getRecords(GET_LIST_ALL_USERS_URL, apiQuery);
    processServiceUserData("get", response);
    // console.log("🚀 ~ getAllServiceUserData ~ response:", response)
  });


  useEffect(() => {
    getAllServiceUserData();
  }, []);

  const handleSearchInputChange = (e) => {
    setSearchInput(e?.target?.value?.toLowerCase());
  };

  // Search Filter
  const filteredRecords = searchInput
    ? allRecords?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchInput)
    )
    : allRecords;

  // Handle Open Message
  const handleOpenMessage = (userData) => {
    // console.log("🚀 ~ handleOpenMessage ~ userData:", userData);
    try {
      dispatch(addNewChatSingleUser(userData));
      onClose();
    } catch (error) {
      console.log("Error dispatching action:", error);
    }
    // console.log("🚀 ~ handleOpenMessage ~ addNewChatSingleUser:", addNewChatSingleUser);
  };
  return (
    <>
      <Modal
        show={handleShowModel}
        onHide={onClose}
        className="ftcModalPopup"
      >
        <Modal.Header closeButton>
          <Modal.Title>All Users</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {noRecordFound ? (
            <>
              <div className="noRecordFound">
                <p>No records found.</p>
              </div>
            </>
          ) : isLoading ? (
            <div className="preLoaderWrapper">
              <ProgressComponent />
            </div>
          ) : (
            <>
              <div className="searchContainer p-2 mb-3">
                <div className="searchTextBox">
                  <input
                    type="text"
                    placeholder="Search by name"
                    value={searchInput}
                    onChange={handleSearchInputChange}
                  />
                </div>
              </div>
              {
                <>
                  <div className="userListAddChat">
                    {
                      filteredRecords.map((item, index) => (
                        <>
                          <div className="cards">
                            <div className="cardsUserDetail">
                              <div
                                className="usersNameInfo curSorPointer"
                                onClick={() => handleOpenMessage(item)}
                              >
                                <div className="userImage">
                                  <img
                                    src={BASE_URL + item?.profile_picture_url}
                                    alt={item?.name}
                                  />
                                </div>
                                <div className="userInfo">
                                  <span className="nameBlock">
                                    {item?.name}{" "}
                                    {item?.modelId?.suLastName && item?.modelId?.suLastName}
                                  </span>
                                  <span className="infoBlock">
                                    {item?.email}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ))
                    }
                  </div>
                </>
              }
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn-sm"
            variant="outline-primary"
            onClick={onClose}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>

    </>
  );
}

// Map dispatch actions to component props
const mapDispatchToProps = (dispatch) => ({
  addNewChatSingleUser: (memberData) => dispatch(addNewChatSingleUser(memberData)),
});

export default connect(null, mapDispatchToProps)(UserList);