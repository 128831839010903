import React from "react";
import Table from "react-bootstrap/Table";

function KeyContacts() {
  return (
    <>
      <h3>Key Contacts</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Type</th>
            <th>Name</th>
            <th>Relation</th>
            <th>Address</th>
            <th>Email</th>
            <th>Phone No.</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Phone No.</td>
            <td>Jacob Smith</td>
            <td>Self</td>
            <td>Gloucester, 1-2 Street 7th</td>
            <td>Same as Patient In.</td>
            <td>+1 (0) 00 0000 0000</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default KeyContacts;
