import React, { useState, useEffect } from "react";
import moment from "moment";
import * as Yup from "yup";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import Input from "../../../components/forms-fields/Input";
import CustomSelectWeekDays from "../../../components/forms-fields/CustomSelectWeek";
import TextArea from "../../../components/forms-fields/TextArea";
import ProgressComponent from "../../../components/general/ProgressComponent";
import CustomSelectPatient from "../../../components/forms-fields/CustomSelectServiceUsers";
import CustomSelectStaffMember from "../../../components/forms-fields/CustomSelectStaffMembers";
import {
  handleSetIdFormikFormData,
  handleTimeChangeToNumeric,
  useShiftEffect,
  weeksOptions,
  getFormDataProps,
  GenerateScheduleFormData,
  handleFormUpdate,
  handleSelectPreDefinedTime,
  twoWeeksOptions,
  fourWeeksOptions,
  TIME_08_14,
  TIME_14_20,
  TIME_08_20,
  TIME_20_08,
  TIME_10_16,
  defaultHours,
  setShiftPreDefinedTimeState,
  calculateTimeDifference,
} from "../../../helper/helperJsx";
import {
  POST_ADD_ROTA_2ND_INDIVIDUAL_SHIFT_DAY_URL,
  POST_UPDATE_ROTA_INDIVIDUAL_SHIFT_DAY_URL,
  POST_ADD_ROTA_NEW_INDIVIDUAL_TEMPLATE_SHIFT_DAY_URL,
  ADMIN_ID,
} from "../../../env";

function EditScheduleTemplate({
  handleShowModel,
  handleCloseModel,
  templateData,
  shiftArrayItem,
  dayItem,
  templateWeekNum,
  activeWeekTabNum,
  selectedLocationId,
  fetchAllRecords,
  isIndividualUpdate,
  isAddNewIndividualShift,
  isAddSecondIndividualShift,
  scheduleTitle,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [totalShiftHours, setTotalShiftHours] = useState(defaultHours);
  const [shiftStartTimeState, setShiftStartTimeState] = useState(false);
  const [shiftEndTimeState, setShiftEndTimeState] = useState(false);
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isOverNightStay, setIsOverNightStay] = useState(false);
  const [isSleepOver, setIsSleepOver] = useState(false);

  const [shiftPreDefinedTimeState1, setShiftPreDefinedTimeState1] =
    useState(false);
  const [shiftPreDefinedTimeState2, setShiftPreDefinedTimeState2] =
    useState(false);
  const [shiftPreDefinedTimeState3, setShiftPreDefinedTimeState3] =
    useState(false);
  const [shiftPreDefinedTimeState4, setShiftPreDefinedTimeState4] =
    useState(false);
  const [shiftPreDefinedTimeState5, setShiftPreDefinedTimeState5] =
    useState(false);

    const validationSchema = Yup.object().shape({
      // patientId: Yup.string().required("Service User is required."),
      shiftStartTime: Yup.number().required("Start time is required."),
      shiftEndTime: Yup.number()
        .required("End Time is required"),
        // .min(
        //   Yup.ref("shiftStartTime"),
        //   "End Time must be greater than Start Time"
        // ),
      days: Yup.array().min(1, "Please select at least one day."),
      workHrs: Yup?.string()?.required("Work Hours is required."),
      note: Yup.string(),
      isSleepOver: Yup.boolean(),
    });

  const updateIndividualShiftFormData = GenerateScheduleFormData(
    POST_UPDATE_ROTA_INDIVIDUAL_SHIFT_DAY_URL,
    validationSchema,
    fetchAllRecords,
    setIsFormSubmitting,
    handleCloseModel
  );

  const addSecondIndividualShiftFormData = GenerateScheduleFormData(
    POST_ADD_ROTA_2ND_INDIVIDUAL_SHIFT_DAY_URL,
    validationSchema,
    fetchAllRecords,
    setIsFormSubmitting,
    handleCloseModel
  );
  // console.log("🚀 ~ addSecondIndividualShiftFormData:", addSecondIndividualShiftFormData)

  const addNewIndividualShiftFormData = GenerateScheduleFormData(
    POST_ADD_ROTA_NEW_INDIVIDUAL_TEMPLATE_SHIFT_DAY_URL,
    validationSchema,
    fetchAllRecords,
    setIsFormSubmitting,
    handleCloseModel
  );

  const {
    handleChange: handleChangeIndividual1,
    handleBlur: handleBlurIndividual1,
    handleSubmit: handleSubmitIndividual1,
    values: valuesIndividual1,
    errors: errorsIndividual1,
    touched: touchedIndividual1,
    setFieldValue: setFieldValueIndividual1,
    setValues: setValuesIndividual1,
    resetForm: resetFormIndividual1,
  } = getFormDataProps(updateIndividualShiftFormData);

  const {
    handleChange: handleChangeIndividual2,
    handleBlur: handleBlurIndividual2,
    handleSubmit: handleSubmitIndividual2,
    values: valuesIndividual2,
    errors: errorsIndividual2,
    touched: touchedIndividual2,
    setFieldValue: setFieldValueIndividual2,
    setValues: setValuesIndividual2,
    resetForm: resetFormIndividual2,
  } = getFormDataProps(addSecondIndividualShiftFormData);

  const {
    handleChange: handleChangeIndividual3,
    handleBlur: handleBlurIndividual3,
    handleSubmit: handleSubmitIndividual3,
    values: valuesIndividual3,
    errors: errorsIndividual3,
    touched: touchedIndividual3,
    setFieldValue: setFieldValueIndividual3,
    setValues: setValuesIndividual3,
    resetForm: resetFormIndividual3,
  } = getFormDataProps(addNewIndividualShiftFormData);

  const handleSetIndividualUpdateFormData = async (
    template,
    shiftArrayObj,
    day
  ) => {
    await setValuesIndividual1({
      tempId: template?._id,
      locationId: selectedLocationId,
      staffUserId: shiftArrayObj?.staffUserId?._id,
      patientId: day?.patientId?._id,
      shiftId: shiftArrayObj?._id,
      dayId: day?._id,
      day: day?.day,
      secondaryWeeks: shiftArrayObj?.secondaryWeeks,
      primaryWeekNo: shiftArrayObj?.primaryWeekNo,
      shiftStartTime: day?.shiftStartTime,
      shiftEndTime: day?.shiftEndTime,
      workHrs: day?.workHrs,
      isSleepOver: day?.isSleepOver,
      isOverNightStay: day?.isOverNightStay,
      note: day?.note,
      lastModifiedBy: ADMIN_ID,
    });
    setIsSleepOver(day?.isSleepOver);
    setIsOverNightStay(day?.isOverNightStay);
    await setStateValues(dayItem);
    setIsLoading(false);
  };

  const handleSetSecondIndividualShiftFormData = async (
    template,
    shiftArrayObj,
    day
  ) => {
    await setValuesIndividual2({
      tempId: template?._id,
      shiftId: shiftArrayObj?._id,
      locationId: selectedLocationId,
      staffUserId: shiftArrayObj?.staffUserId?._id,
      patientId: day?.patientId?._id,
      secondaryWeeks: [activeWeekTabNum?.toString()],
      day: day?.day,
      shiftStartTime: day?.shiftStartTime,
      shiftEndTime: day?.shiftEndTime,
      workHrs: day?.workHrs,
      primaryWeekNo: shiftArrayObj?.primaryWeekNo,
      note: day?.note,
      isSleepOver: false,
      isOverNightStay: false,
      addedby: ADMIN_ID,
      lastModifiedBy: ADMIN_ID,
    });
    await setStateValues(shiftArrayObj);
    setIsLoading(false);
  };

  const handleSetNewIndividualShiftFormData = async (
    template,
    shiftArrayObj,
    day
  ) => {
    await setValuesIndividual3({
      tempId: template?._id,
      shiftId: shiftArrayObj?._id,
      locationId: selectedLocationId,
      staffUserId: shiftArrayObj?.staffUserId?._id,
      patientId: shiftArrayObj?.patientId?._id,
      secondaryWeeks: [activeWeekTabNum?.toString()],
      day: day,
      shiftStartTime: shiftArrayObj?.shiftStartTime,
      shiftEndTime: shiftArrayObj?.shiftEndTime,
      workHrs: shiftArrayObj?.workHrs,
      note: shiftArrayObj?.note,
      isSleepOver: false,
      isOverNightStay: false,
      addedby: ADMIN_ID,
      lastModifiedBy: ADMIN_ID,
    });
    await setStateValues(shiftArrayObj);
    setIsLoading(false);
  };

  async function setStateValues(item) {
    const formattedHoursDifference = await calculateTimeDifference(
      item?.shiftStartTime,
      item?.shiftEndTime
    );
    const startTime = moment(item?.shiftStartTime)?.format("HH:mm");
    const endTime = moment(item?.shiftEndTime)?.format("HH:mm");
    setShiftStartTimeState(startTime);
    setShiftEndTimeState(endTime);
    setTotalShiftHours(formattedHoursDifference);
    setShiftPreDefinedTimeState(
      startTime,
      endTime,
      TIME_10_16?.startTime,
      TIME_10_16?.endTime,
      setShiftPreDefinedTimeState1
    );
    setShiftPreDefinedTimeState(
      startTime,
      endTime,
      TIME_08_14?.startTime,
      TIME_08_14?.endTime,
      setShiftPreDefinedTimeState2
    );
    setShiftPreDefinedTimeState(
      startTime,
      endTime,
      TIME_14_20?.startTime,
      TIME_14_20?.endTime,
      setShiftPreDefinedTimeState3
    );
    setShiftPreDefinedTimeState(
      startTime,
      endTime,
      TIME_08_20?.startTime,
      TIME_08_20?.endTime,
      setShiftPreDefinedTimeState4
    );
    setShiftPreDefinedTimeState(
      startTime,
      endTime,
      TIME_20_08?.startTime,
      TIME_20_08?.endTime,
      setShiftPreDefinedTimeState5
    );
  }

  const handleTimeChangeNumericFunc = async (e, name, setFieldValue) => {
    const numericValue = await handleTimeChangeToNumeric(e);
    if (numericValue) {
      setFieldValue(name, numericValue);
    }
  };

  const handleOvernightStayChange = (valuesSetter) => {
    const updatedValue = !isOverNightStay;
    setIsOverNightStay(updatedValue);
    setIsSleepOver(false);
    valuesSetter("isOverNightStay", updatedValue);
    valuesSetter("isSleepOver", false);
  };

  const handleOvernightSleepChange = (valuesSetter) => {
    const updatedValue = !isSleepOver;
    setIsSleepOver(updatedValue);
    setIsOverNightStay(false);
    valuesSetter("isSleepOver", updatedValue);
    valuesSetter("isOverNightStay", false);
  };

  useShiftEffect(
    valuesIndividual1,
    setTotalShiftHours,
    setFieldValueIndividual1
  );

  useShiftEffect(
    valuesIndividual2,
    setTotalShiftHours,
    setFieldValueIndividual2
  );

  useShiftEffect(
    valuesIndividual3,
    setTotalShiftHours,
    setFieldValueIndividual3
  );

  useEffect(() => {
    if (isIndividualUpdate) {
      handleSetIndividualUpdateFormData(templateData, shiftArrayItem, dayItem);
    } else if (isAddSecondIndividualShift) {
      handleSetSecondIndividualShiftFormData(
        templateData,
        shiftArrayItem,
        dayItem
      );
    } else if (isAddNewIndividualShift) {
      handleSetNewIndividualShiftFormData(
        templateData,
        shiftArrayItem,
        dayItem
      );
    }
  }, [
    isIndividualUpdate,
    isAddNewIndividualShift,
    isAddSecondIndividualShift,
    shiftArrayItem,
  ]);

  useEffect(() => {
    if (valuesIndividual1?.isSleepOver) {
      setFieldValueIndividual1("isOverNightStay", false);
    }
    if (valuesIndividual1?.isOverNightStay) {
      setFieldValueIndividual1("isSleepOver", false);
    }
  }, [valuesIndividual1?.isSleepOver, valuesIndividual1?.isOverNightStay]);

  useEffect(() => {
    if (valuesIndividual2?.isSleepOver) {
      setFieldValueIndividual2("isOverNightStay", false);
    }
    if (valuesIndividual2?.isOverNightStay) {
      setFieldValueIndividual2("isSleepOver", false);
    }
  }, [valuesIndividual2?.isSleepOver, valuesIndividual2?.isOverNightStay]);

  useEffect(() => {
    if (valuesIndividual3?.isSleepOver) {
      setFieldValueIndividual3("isOverNightStay", false);
    }
    if (valuesIndividual3?.isOverNightStay) {
      setFieldValueIndividual3("isSleepOver", false);
    }
  }, [valuesIndividual3?.isSleepOver, valuesIndividual3?.isOverNightStay]);

  const renderFormJsx = (
    setField,
    formValues,
    formSubmit,
    formTouched,
    formErrors,
    formBlur
  ) => {
    return (
      <form method="post" onSubmit={formSubmit}>
        <Modal.Body>
          <div className="row align-items-center mt-4">
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <Input
                  type="text"
                  name="day"
                  value={formValues?.day}
                  labelText="Day"
                  disabledStatus={true}
                  requiredStar={true}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <CustomSelectWeekDays
                  onSelect={(value) => setField("secondaryWeeks", value)}
                  label="Weeks"
                  filterBy="weeks"
                  defaultWeeks={formValues?.secondaryWeeks}
                  weekOptions={
                    templateWeekNum === 2 ? twoWeeksOptions : fourWeeksOptions
                  }
                  omBlurFunc={formBlur}
                  customError={
                    formTouched?.secondaryWeeks && formErrors?.secondaryWeeks
                  }
                  errorMessage={formErrors?.secondaryWeeks}
                  errorClass={
                    formTouched?.secondaryWeeks && formErrors?.secondaryWeeks
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <CustomSelectStaffMember
                  onSelect={(value) => setField("staffUserId", value)}
                  defaultValue={formValues?.staffUserId}
                  label="Staff Name"
                  omBlurFunc={formBlur}
                  customError={
                    formTouched?.staffUserId && formErrors?.staffUserId
                  }
                  errorMessage={formErrors?.staffUserId}
                  errorClass={
                    formTouched?.staffUserId && formErrors?.staffUserId
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                  isDisabled={true}
                />
              </div>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <CustomSelectPatient
                  onSelect={(value) => setField("patientId", value)}
                  defaultSelectedValue={formValues?.patientId ? formValues?.patientId : ""}
                  label="Service User"
                  omBlurFunc={formBlur}
                  customError={formTouched?.patientId && formErrors?.patientId}
                  errorMessage={formErrors?.patientId}
                  errorClass={
                    formTouched?.patientId && formErrors?.patientId
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>
            </div>
            <div className="custom-d-grid">
              <Input
                type="time"
                name="shiftStartTime"
                value={shiftStartTimeState}
                onChange={(e) => {
                  handleTimeChangeNumericFunc(e, "shiftStartTime", setField);
                  setShiftStartTimeState(e?.target?.value);
                }}
                onBlur={formBlur}
                labelText="Start time"
                customError={
                  formTouched?.shiftStartTime && formErrors?.shiftStartTime
                }
                errorMessage={formErrors?.shiftStartTime}
                errorClass={
                  formTouched?.shiftStartTime && formErrors?.shiftStartTime
                    ? "validationError"
                    : ""
                }
                requiredStar={true}
                disabledStatus={true}
              />

              <Input
                type="time"
                name="shiftEndTime"
                value={shiftEndTimeState}
                onChange={(e) => {
                  handleTimeChangeNumericFunc(e, "shiftEndTimeState", setField);
                  setShiftEndTimeState(e?.target?.value);
                }}
                onBlur={formBlur}
                labelText="End time"
                customError={
                  formTouched?.shiftEndTime && formErrors?.shiftEndTime
                }
                errorMessage={formErrors?.shiftEndTime}
                errorClass={
                  formTouched?.shiftEndTime && formErrors?.shiftEndTime
                    ? "validationError"
                    : ""
                }
                requiredStar={true}
                disabledStatus={true}
              />
              <div className="ui-ux-color font-size">
                {totalShiftHours + "  "}
                Hours
              </div>
            </div>
            <div className="d-flex-space-between mb-3">
              <label className="radioContainer">
                <input
                  type="radio"
                  className="radioBoxSize"
                  name="shiftTime"
                  checked={shiftPreDefinedTimeState1}
                  onChange={(e) => {
                    handleSelectPreDefinedTime(
                      e,
                      1,
                      setShiftPreDefinedTimeState1,
                      setShiftStartTimeState,
                      setShiftEndTimeState,
                      setField,
                      TIME_10_16?.startTimeNum,
                      TIME_10_16?.endTimeNum,
                      setShiftPreDefinedTimeState2,
                      setShiftPreDefinedTimeState3,
                      setShiftPreDefinedTimeState4,
                      setShiftPreDefinedTimeState5
                    );
                  }}
                />
                <span className="labelText radioLabel">
                  {TIME_10_16?.label}
                </span>
                <span className="radioMark"></span>
              </label>
              <label className="radioContainer">
                <input
                  type="radio"
                  className="radioBoxSize"
                  name="shiftTime"
                  checked={shiftPreDefinedTimeState2}
                  onChange={(e) => {
                    handleSelectPreDefinedTime(
                      e,
                      2,
                      setShiftPreDefinedTimeState2,
                      setShiftStartTimeState,
                      setShiftEndTimeState,
                      setField,
                      TIME_08_14?.startTimeNum,
                      TIME_08_14?.endTimeNum,
                      setShiftPreDefinedTimeState1,
                      setShiftPreDefinedTimeState3,
                      setShiftPreDefinedTimeState4,
                      setShiftPreDefinedTimeState5
                    );
                  }}
                />
                <span className="labelText radioLabel">
                  {TIME_08_14?.label}
                </span>
                <span className="radioMark"></span>
              </label>
              <label className="radioContainer">
                <input
                  type="radio"
                  className="radioBoxSize"
                  name="shiftTime"
                  checked={shiftPreDefinedTimeState3}
                  onChange={(e) => {
                    handleSelectPreDefinedTime(
                      e,
                      3,
                      setShiftPreDefinedTimeState3,
                      setShiftStartTimeState,
                      setShiftEndTimeState,
                      setField,
                      TIME_14_20?.startTimeNum,
                      TIME_14_20?.endTimeNum,
                      setShiftPreDefinedTimeState1,
                      setShiftPreDefinedTimeState2,
                      setShiftPreDefinedTimeState4,
                      setShiftPreDefinedTimeState5
                    );
                  }}
                />
                <span className="labelText radioLabel">
                  {TIME_14_20?.label}
                </span>
                <span className="radioMark"></span>
              </label>
              <label className="radioContainer mt-2">
                <input
                  type="radio"
                  className="radioBoxSize"
                  name="shiftTime"
                  checked={shiftPreDefinedTimeState4}
                  onChange={(e) => {
                    handleSelectPreDefinedTime(
                      e,
                      4,
                      setShiftPreDefinedTimeState4,
                      setShiftStartTimeState,
                      setShiftEndTimeState,
                      setField,
                      TIME_08_20?.startTimeNum,
                      TIME_08_20?.endTimeNum,
                      setShiftPreDefinedTimeState1,
                      setShiftPreDefinedTimeState2,
                      setShiftPreDefinedTimeState3,
                      setShiftPreDefinedTimeState5
                    );
                  }}
                />
                <span className="labelText radioLabel">
                  {TIME_08_20?.label}
                </span>
                <span className="radioMark"></span>
              </label>
              <label className="radioContainer mt-2">
                <input
                  type="radio"
                  className="radioBoxSize"
                  name="shiftTime"
                  checked={shiftPreDefinedTimeState5}
                  onChange={(e) => {
                    handleSelectPreDefinedTime(
                      e,
                      5,
                      setShiftPreDefinedTimeState5,
                      setShiftStartTimeState,
                      setShiftEndTimeState,
                      setField,
                      TIME_20_08?.startTimeNum,
                      TIME_20_08?.endTimeNum,
                      setShiftPreDefinedTimeState1,
                      setShiftPreDefinedTimeState2,
                      setShiftPreDefinedTimeState3,
                      setShiftPreDefinedTimeState4
                    );
                  }}
                />
                <span className="labelText radioLabel">
                  {TIME_20_08?.label}
                </span>
                <span className="radioMark"></span>
              </label>
            </div>
            <div className="mb-3 d-flex-gap-43">
              <label className="radioContainer cursor-pointer">
                <input
                  type="checkbox"
                  className="radioBoxSize cursor-pointer"
                  name="overnightOption"
                  checked={isOverNightStay}
                  onChange={() => handleOvernightStayChange(setField)}
                />
                <span className="labelText radioLabel">{"Waking Night"}</span>
                <span className="radioMark"></span>
              </label>
              <label className="radioContainer cursor-pointer">
                <input
                  type="checkbox"
                  className="radioBoxSize cursor-pointer"
                  name="overnightOption"
                  checked={isSleepOver}
                  onChange={() => handleOvernightSleepChange(setField)}
                />
                <span className="labelText radioLabel">
                  {"Overnight Sleep"}
                </span>
                <span className="radioMark "></span>
              </label>
            </div>
            <div className="col-sm-12 col-md-12">
              <div className="form-group">
                <TextArea
                  type="text"
                  name="note"
                  value={formValues?.note}
                  onChange={(e) => setField("note", e?.target?.value)}
                  omBlurFunc={formBlur}
                  labelText="Note (Optional)"
                  rows={5}
                  cols={5}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {isFormSubmitting ? (
            <ProgressComponent />
          ) : (
            <>
              <Button
                className="btn-sm"
                variant="outline-primary"
                onClick={handleCloseModel}
              >
                Cancel
              </Button>

              <Button className="btn-sm" type="submit" variant="primary">
                Save
              </Button>
            </>
          )}
        </Modal.Footer>
      </form>
    );
  };

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{scheduleTitle}</Modal.Title>
          </Modal.Header>
          {isIndividualUpdate
            ? renderFormJsx(
              setFieldValueIndividual1,
              valuesIndividual1,
              handleSubmitIndividual1,
              touchedIndividual1,
              errorsIndividual1,
              handleBlurIndividual1
            )
            : isAddSecondIndividualShift
              ? renderFormJsx(
                setFieldValueIndividual2,
                valuesIndividual2,
                handleSubmitIndividual2,
                touchedIndividual2,
                errorsIndividual2,
                handleBlurIndividual2
              )
              : isAddNewIndividualShift
                ? renderFormJsx(
                  setFieldValueIndividual3,
                  valuesIndividual3,
                  handleSubmitIndividual3,
                  touchedIndividual3,
                  errorsIndividual3,
                  handleBlurIndividual3
                )
                : null}
        </Modal>
      )}
    </>
  );
}

export default EditScheduleTemplate;
