import React, { useState, useEffect } from "react";
import { BASE_URL, GET_ALL_SERVICE_USERS_URL_ENQUIRY } from "../../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
} from "../../../helper/helper";
import ProgressComponent from "../../../components/general/ProgressComponent";
import moment from 'moment';

function ServiceUserEnquiryList() {
  const [isLoading, setIsLoading] = useState(true);
  const [allRecords, setRecords] = useState([]);
  const [reviewEnquiryListData, setReviewEnquiryListData] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    limit: 50,
    query: {
      critarion: { active: true },
      usersFields: "profile_picture_url",
      addedby: "_id email first_name",
      lastModifiedBy: "_id email first_name",
    },
  };
  const processEnquiryData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allRecords = data?.data?.suEnquirys?.filter((item) => item?.suEnqStatus === "pending");

      if (allRecords && allRecords.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        setRecords(allRecords);
        setReviewEnquiryListData(allRecords);
      }
    }
  };

  const getAllServiceUserEnquiry = catchAsync(async () => {
    const response = await getRecords(
      GET_ALL_SERVICE_USERS_URL_ENQUIRY,
      apiQuery
    );
    processEnquiryData("get", response);
  });

  useEffect(() => {
    getAllServiceUserEnquiry();
  }, []);
  return (
    <>
      <div className="reviewsContentCards">
        {noRecordFound ? (
          <>
            <div className="noRecordFound">
              <p>No records found.</p>
            </div>
          </>
        ) : isLoading ? (
          <ProgressComponent />
        ) : (
          reviewEnquiryListData.map((item, index) =>

            <>
              <div className="cards">
                <div className="cardsUserDetail">
                  <div className="usersNameInfo">
                    <div className="userImage">
                      <img
                        src={
                          BASE_URL + item?.serviceUser?.profile_picture_url
                        }
                        alt={item?.serviceUser?.modelId?.name}
                      />
                    </div>
                    <div className="userInfo">
                      <span className="nameBlock">
                        {item?.serviceUser?.modelId?.name}{" "}
                        {item?.serviceUser?.modelId?.suLastName}
                      </span>
                      <span className="infoBlock">
                        {item?.serviceUser?.modelId?.suAge &&
                          item?.serviceUser?.modelId?.suSex ? (
                          <>
                            {item?.serviceUser?.modelId?.suAge} Yrs |{" "}
                            {item?.serviceUser?.modelId?.suSex}
                          </>
                        ) : (
                          <span>
                            <small>
                              Initial Contact Date:
                              <br />
                              {moment(item?.initialContactDate).format("DD/MM/YYYY")}
                              {/* {formateDate(item?.initialContactDate, "m/d/y")} */}
                            </small>
                          </span>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </>

          )
        )}
      </div>
    </>
  );
}

export default ServiceUserEnquiryList;
