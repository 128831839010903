import React, { useState, useEffect, useRef } from 'react';
import ReactHtmlParser from 'html-react-parser';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, GET_CARE_PLAN_DOC_URL } from "../../../env";
import {
  getRecords,
  catchAsync,
  isSuccess,
} from "../../../helper/helper";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from '../../../components/user-detail/UserDetailReview';
import ProgressComponent from '../../../components/general/ProgressComponent';
import editGrid from "../../../assets/images/icons/editGrid.svg";
import Modal from 'react-bootstrap/Modal';
import EditCarePlanPopUp from './EditCarePlanPopUp';
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import '@progress/kendo-theme-default/dist/all.css';
import IsReviewed from "./IsReviewed";
import { Table } from 'react-bootstrap';
import DailyData from '../common-options/daily-summary/DailyData';
import ProtectedComponent from "../../../permissions/ProtectedComponent";
import { permissions } from "../../../permissions/roles";

function ViewCarePlan() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showReviewedPopup, setShowReviewedPopup] = useState(false);
  const [carePlanData, setCarePlanData] = useState('')
  const [isLoading, setIsLoading] = useState(true);

  const [modalPopupData, setModalPopupData] = useState();
  const [modalPopupTitle, setModalPopupTitle] = useState();
  const handelModalPopup = (modalPopupDataItem) => {
    let carePlanDetail = localStorage.getItem("carePlanDetail");

    if (!carePlanDetail) {
      carePlanDetail = JSON.stringify(carePlanData);
      localStorage.setItem("carePlanDetail", carePlanDetail);
    }
    setModalPopupData(modalPopupDataItem)
    setModalPopupTitle(modalPopupDataItem?.sectionTitle)
    setShow(true)
  };

  const handelReviewedModalPopup = (modalPopupDataItem) => {
    let carePlanDetail = localStorage.getItem("carePlanDetail");

    if (!carePlanDetail) {
      carePlanDetail = JSON.stringify(carePlanData);
      localStorage.setItem("carePlanDetail", carePlanDetail);
    }
    setModalPopupData(modalPopupDataItem)
    setShowReviewedPopup(true)
  };

  const handelModalPopupClose = () => {
    setShow(false)
    setShowReviewedPopup(false)
    fetchAllRecords();
  };
  // Getting records from DB

  const apiQuery = {
    query: {
      critarion: {
        userId: userData?._id
      }
    }
  }

  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      setCarePlanData(data?.data?.list[0])
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CARE_PLAN_DOC_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);


  const lastIndex = carePlanData?.lastModifiedBy?.length - 1;


  const viewCarePlanData = [
    { sectionTitle: "Involvement & Consent", sectionId: "involvementAndConsent", fieldValueContent: carePlanData?.involvementAndConsent },
    { sectionTitle: "One Page Profile", sectionId: "onePageProfile", fieldValueContent: carePlanData?.onePageProfile },
    { sectionTitle: "Information About Me", sectionId: "informationAboutMe", fieldValueContent: carePlanData?.informationAboutMe },
    { sectionTitle: "My Contacts", sectionId: "myContacts", fieldValueContent: carePlanData?.myContacts },
    { sectionTitle: "My Life History", sectionId: "myLifeHistory", fieldValueContent: carePlanData?.myLifeHistory },
    { sectionTitle: "My Dreams and Aspirations", sectionId: "myDreamsAndAspirations", fieldValueContent: carePlanData?.myDreamsAndAspirations },
    { sectionTitle: "My Goals and Outcomes", sectionId: "myGoalsAndOutcomes", fieldValueContent: carePlanData?.myGoalsAndOutcomes },
    { sectionTitle: "My Key Worker", sectionId: "myKeyWorker", fieldValueContent: carePlanData?.myKeyWorker },
    // { sectionTitle: "My Person Centred Support Plans", sectionId: "myPersonCentredSupportPlans", fieldValueContent: carePlanData?.myPersonCentredSupportPlans },
    { sectionTitle: "Communication", sectionId: "communication", fieldValueContent: carePlanData?.communication },
    { sectionTitle: "My Important People", sectionId: "myImportantPeople", fieldValueContent: carePlanData?.myImportantPeople },
    { sectionTitle: "My Likes and Dislikes", sectionId: "myLikesAndDislikes", fieldValueContent: carePlanData?.myLikesAndDislikes },
    { sectionTitle: "My Relationships", sectionId: "myRelationships", fieldValueContent: carePlanData?.myRelationships },
    { sectionTitle: "My Routines", sectionId: "myRoutines", fieldValueContent: carePlanData?.myRoutines },
    { sectionTitle: "My Sleeping", sectionId: "mySleeping", fieldValueContent: carePlanData?.mySleeping },
    { sectionTitle: "My Personal Care", sectionId: "myPersonalCare", fieldValueContent: carePlanData?.myPersonalCare },
    { sectionTitle: "My Weekly Budget", sectionId: "myWeeklyBudget", fieldValueContent: carePlanData?.myWeeklyBudget },
    { sectionTitle: "My Activities and Interests", sectionId: "myActivitiesAndInterests", fieldValueContent: carePlanData?.myActivitiesAndInterests },
    { sectionTitle: "My Activity Plan", sectionId: "myActivityPlan", fieldValueContent: carePlanData?.myActivityPlan },
    { sectionTitle: "My Perfect Week", sectionId: "myPerfectWeek", fieldValueContent: carePlanData?.myPerfectWeek },
    { sectionTitle: "Being Part of the Community", sectionId: "myBeingPartOfTheCommunity", fieldValueContent: carePlanData?.myBeingPartOfTheCommunity },
    { sectionTitle: "College, Career, Work Experience, Volunteering", sectionId: "myCollegeCareerWorkExperienceVolunteering", fieldValueContent: carePlanData?.myCollegeCareerWorkExperienceVolunteering },
    { sectionTitle: "My Daily Living Skills", sectionId: "myDailyLivingSkills", fieldValueContent: carePlanData?.myDailyLivingSkills },
    { sectionTitle: "My Mental Health", sectionId: "myMentalHealth", fieldValueContent: carePlanData?.myMentalHealth },
    { sectionTitle: "My Crisis Support", sectionId: "myCrisisSupport", fieldValueContent: carePlanData?.myCrisisSupport },
    { sectionTitle: "My Travelling", sectionId: "myTravelling", fieldValueContent: carePlanData?.myTravelling },
    { sectionTitle: "My Food and Drink", sectionId: "myFoodAndDrink", fieldValueContent: carePlanData?.myFoodAndDrink },
    { sectionTitle: "Support on Good and Bad Days", sectionId: "supportOnGoodBadDays", fieldValueContent: carePlanData?.supportOnGoodBadDays },
    { sectionTitle: "My Feelings and Behaviours", sectionId: "myFeelingsAndBehaviours", fieldValueContent: carePlanData?.myFeelingsAndBehaviours },
    { sectionTitle: "Keeping Safe and Well", sectionId: "keepingSafeAndWell", fieldValueContent: carePlanData?.keepingSafeAndWell },
    { sectionTitle: "Making My Own Decisions", sectionId: "makingMyownDecisions", fieldValueContent: carePlanData?.makingMyownDecisions },
    { sectionTitle: "My Health", sectionId: "myHealth", fieldValueContent: carePlanData?.myHealth },
    { sectionTitle: "My Medication", sectionId: "myMedication", fieldValueContent: carePlanData?.myMedication },
    { sectionTitle: "My Sexual Health", sectionId: "mySexualHealth", fieldValueContent: carePlanData?.mySexualHealth },
    { sectionTitle: "Religious, Spiritual, and Life Choices", sectionId: "religiousSpiritualAndLifeChoices", fieldValueContent: carePlanData?.religiousSpiritualAndLifeChoices },
    { sectionTitle: "My Finances", sectionId: "myFinances", fieldValueContent: carePlanData?.myFinances },
    { sectionTitle: "My Tenancy Information", sectionId: "myTenancyInformation", fieldValueContent: carePlanData?.myTenancyInformation },
    { sectionTitle: "My Reviews", sectionId: "myReviews", fieldValueContent: carePlanData?.myReviews },
    { sectionTitle: "My Health Action Plan", sectionId: "myHealthActionPlan", fieldValueContent: carePlanData?.myHealthActionPlan },
    { sectionTitle: "Risk Assessments", sectionId: "riskAssessments", fieldValueContent: "" },
    { sectionTitle: "PEEP", sectionId: "PEEP", fieldValueContent: carePlanData?.PEEP },
    { sectionTitle: "Missing Persons Profile", sectionId: "missingPersonsProfile", fieldValueContent: carePlanData?.missingPersonsProfile },
    { sectionTitle: "Personal Inventory", sectionId: "personalInventory", fieldValueContent: carePlanData?.personalInventory },
    { sectionTitle: "After My Death", sectionId: "afterMyDeath", fieldValueContent: carePlanData?.afterMyDeath },
    // { sectionTitle: "Recording File", sectionId: "recordingFile", fieldValueContent: carePlanData?.recordingFile },
    { sectionTitle: "Daily Diary", sectionId: "dailyDiary", fieldValueContent: "" },
    { sectionTitle: "My Key Worker Key Work Meetings", sectionId: "myKeyWorkerKeyWorkMeetings", fieldValueContent: carePlanData?.myKeyWorkerKeyWorkMeetings },
    { sectionTitle: "MCA Best Interests Decisions DoLS", sectionId: "MCABestInterestsDecisionsDoLS", fieldValueContent: "" },
    { sectionTitle: "ABC Charts", sectionId: "aBCCharts", fieldValueContent: "" },
    { sectionTitle: "Health Appointment Records", sectionId: "healthAppointmentRecords", fieldValueContent: "" },
    // { sectionTitle: "Menstruation", sectionId: "menstruation", fieldValueContent: carePlanData?.menstruation },
    { sectionTitle: "Weight Records", sectionId: "weightRecords", fieldValueContent: "" },
    { sectionTitle: "Seizure Records", sectionId: "seizureRecords", fieldValueContent: carePlanData?.seizureRecords },
    { sectionTitle: "Financial Records", sectionId: "financialRecords", fieldValueContent: carePlanData?.financialRecords },
  ]

  const pdfExportComponent = useRef(null);
  const [showInPrint, setShowInPrint] = useState(false);
  const exportPDF = () => {
    if (pdfExportComponent.current) {
      setShowInPrint(true);
      setTimeout(() => {
        pdfExportComponent.current.save();
      }, 100);
      setTimeout(() => {
        setShowInPrint(false);
      }, 1000);


    }
  };
  return (
    <>
      <Layout
        pageTitleText={
          <>
            Care Plan
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="viewEditorWrapper">
          <div className="mainContent mt-3 pt-4">

            {isLoading ? (
              <ProgressComponent />
            ) : (
              <>
                <header className="mainContentHeader pt-0 pb-4">
                  <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                    <span className='isReviewed btn btn-sm btn-outline-success' onClick={() => handelReviewedModalPopup(carePlanData)}>
                      Mark as reviewed
                    </span>
                  </ProtectedComponent>
                  <div>
                    <div className="actionColumns">
                      <span className="btn btn-sm btn-outline-primary"
                        onClick={exportPDF}
                      >Export to PDF</span>

                      <span className="btn btn-sm btn-primary" onClick={() => {
                        navigate("/care-plans");
                      }}>Back to Care Plan Summary</span>

                    </div>

                  </div>
                </header>
                <PDFExport
                  ref={pdfExportComponent}
                  paperSize={["900pt", "900pt"]}
                  margin={20}
                  fileName={`Daily Care Plan`}
                >
                  <div className="viewEditorContent">
                    <header className='createAndModifyStatus'>
                      <div className="enteredBy">
                        <span className="usersNameInfo">
                          <span className="userImage">
                            <img src={`${BASE_URL}${carePlanData?.addedby?.profile_picture_url}`} alt={carePlanData?.addedby?.name} />
                          </span>
                          <span className="userInfo">
                            {/* <h1 className="no-print">This will be excluded from the PDF</h1> */}
                            <span><small>Created by:</small></span>
                            <strong >{carePlanData?.addedby?.name}</strong>
                            <span><small>{moment(carePlanData?.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}</small></span>
                          </span>
                        </span>
                      </div>
                      {
                        carePlanData?.lastModifiedBy !== null && (
                          carePlanData?.lastModifiedBy[lastIndex]?.modifiedUser ? (
                            <>
                              <div className="enteredBy">
                                <span className="usersNameInfo">
                                  <span className="userImage">
                                    <img src={`${BASE_URL}${carePlanData?.lastModifiedBy[lastIndex]?.modifiedUser?.profile_picture_url}`} alt={carePlanData?.lastModifiedBy[lastIndex]?.modifiedUser?.name} />
                                  </span>
                                  <span className="userInfo">
                                    <span><small>Modified by:</small></span>
                                    <strong >{carePlanData?.lastModifiedBy[lastIndex]?.modifiedUser?.name}</strong>
                                    <span><small>{moment(carePlanData?.lastModifiedBy[lastIndex]?.modifiedTime?.updatedAt).format("DD/MM/YYYY / hh:mm:ss A")}</small> </span>
                                  </span>
                                </span>
                              </div></>
                          ) : null
                        )

                      }

                    </header>
                    {
                      viewCarePlanData?.map((item, index) => (
                        <>
                          <div className="sectionWrapper">
                            <div className="sectionContent pbsFieldWrapper">
                              <header className="alert alert-dark">
                                <h3 className='viewCarePlanTitle'>
                                  {item?.sectionTitle}
                                  {
                                    item?.sectionId === "riskAssessments" ? (
                                      <>
                                        <span className={
                                          showInPrint ? ("hiddenDiv") : ("")
                                        }>
                                          <Link to="/risk-assessments" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                        </span>
                                      </>
                                    ) : item?.sectionId === "aBCCharts" ? (
                                      <>
                                        <span className={
                                          showInPrint ? ("hiddenDiv") : ("")
                                        }>
                                          <Link to="/care-plan/abc" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                        </span>
                                      </>
                                    ) : item?.sectionId === "MCABestInterestsDecisionsDoLS" ? (
                                      <>
                                        <span className={
                                          showInPrint ? ("hiddenDiv") : ("")
                                        }>
                                          <Link to="/care-plan/consent-capacity-mca-dols" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                        </span>
                                      </>
                                    ) : item?.sectionId === "healthAppointmentRecords" ? (
                                      <>
                                        <span className={
                                          showInPrint ? ("hiddenDiv") : ("")
                                        }>
                                          <Link to="/care-plan/health-appointments" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                        </span>
                                      </>
                                    ) : item?.sectionId === "weightRecords" ? (
                                      <>
                                        <span className={
                                          showInPrint ? ("hiddenDiv") : ("")
                                        }>
                                          <Link to="/care-plan/weight-height" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                        </span>
                                      </>
                                    ) : item?.sectionId === "dailyDiary" ? (
                                      <>
                                        <span className={
                                          showInPrint ? ("hiddenDiv") : ("")
                                        }>
                                          <Link to="/care-plan/daily-summary" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                        </span>
                                      </>
                                    ) : (
                                      <span className={
                                        showInPrint ? ("hiddenDiv") : ("edit")
                                      }
                                        onClick={() => handelModalPopup(item)}>
                                        <img src={editGrid} alt="Edit" />
                                      </span>
                                    )
                                  }

                                </h3>
                              </header>
                              {
                                (item?.sectionId !== "riskAssessments" &&
                                  item?.sectionId !== "aBCCharts" &&
                                  item?.sectionId !== "MCABestInterestsDecisionsDoLS" &&
                                  item?.sectionId !== "healthAppointmentRecords" &&
                                  item?.sectionId !== "weightRecords" &&
                                  item?.sectionId !== "dailyDiary" &&
                                  item?.sectionId !== "myContacts") &&
                                (
                                  <div className="p-3">
                                    {ReactHtmlParser(item?.fieldValueContent)}
                                  </div>
                                )
                              }
                              {
                                item?.sectionId === "dailyDiary" && (

                                  // showInPrint && (
                                  <>
                                    <div className={
                                      showInPrint ? ("displayDiv") : ("hiddenDiv")
                                    }>
                                      <DailyData titleandButton={false} dailySummaryDate={false} />
                                    </div>

                                  </>

                                )
                              }


                              {
                                item?.sectionId === "myContacts" && (
                                  carePlanData?.myContacts.length > 0 && (
                                    <>
                                      <div className="table-responsive">
                                        <Table striped bordered hover className='myContactsViewCarePlan'>
                                          <thead>
                                            <tr>
                                              <th>Sr No.</th>
                                              <th>Name</th>
                                              <th>Relationship</th>
                                              <th>Email</th>
                                              <th>Phone Number</th>
                                              <th>Address</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {
                                              carePlanData?.myContacts?.map((item, index) => (
                                                <>
                                                  <tr>
                                                    <td><p>{index + 1}</p></td>
                                                    <td><p>{item?.name}</p></td>
                                                    <td><p>{item?.relationship}</p></td>
                                                    <td><p>{item?.email}</p></td>
                                                    <td><p>{item?.phoneNumber}</p></td>
                                                    <td><p>{item?.address}</p></td>
                                                  </tr>
                                                </>
                                              ))
                                            }
                                          </tbody>
                                        </Table>
                                      </div>
                                    </>
                                  )
                                )
                              }
                            </div>
                          </div >
                        </>
                      ))
                    }
                  </div>
                </PDFExport>
              </>
            )}
          </div>
        </div>
      </Layout >

      {/* Edit Care Plan Document */}
      < Modal show={show} onHide={handelModalPopupClose} className="ftcModalPopup" size="lg" >
        <Modal.Header closeButton>
          <Modal.Title>{modalPopupTitle}</Modal.Title>
        </Modal.Header>
        <EditCarePlanPopUp handelModalPopupClose={handelModalPopupClose} modalPopupData={modalPopupData} />
      </Modal >


      {/* Mark as reviewed */}
      <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
        < Modal show={showReviewedPopup} onHide={handelModalPopupClose} className="ftcModalPopup" >
          <Modal.Header closeButton>
            <Modal.Title>Mark as reviewed</Modal.Title>
          </Modal.Header>
          <IsReviewed handelModalPopupClose={handelModalPopupClose} modalPopupData={carePlanData} />
        </Modal >
      </ProtectedComponent>
    </>
  );
}

export default ViewCarePlan