const supportPlanDummyData = `
<p>Jack is a 19-year-old young man, who lives at home with his mum (Kerry) and brother (Freddie), aged
    7, who also has Autism. Jack sees his dad (Mike) regularly, he does not live with him but supports his
    family. He is often at the house when staff drop Jack home. Jack also sees his maternal Grandma and
    Uncle (James) regularly, who is often called to help in a physical incident at home.
    Jack has a diagnosis of Moderate Learning Difficulties, Severe Autism Spectrum Disorder, and
    evidence of Disturbance of Hyperactivity and impulsively (ADHD). Jack also experiences sleep related
    difficulties.</p>
<p>Jack can be shy at first but when he feels more comfortable, he will approach you more. Jack likes
    being in the garden, upcycling things, watching TV, playing on the iPad, researching foreign movies,
    and chatting to staff about Harry Potter, foreign languages, and VHS tapes.
    Jack often repeats things and phrases he has heard / things from the past, and he loves it when
    people engage in role play with him.
</p>
<p>Jack used to go to the Apperley Centre for education, however due to his behaviours being difficult to
    manage there, this stopped, and he now receives an education provision from FTCS.
    Jack likes his own space and will say “all done” or “all fine” or “you can leave now” to indicate he
    wants space or does not want to continue the conversation. Whilst travelling with staff in the car, Jack
    will often opt to sit in the front but can sometimes choose to sit in the back. Jack will need reminding
    to be careful of other cars when opening his door, however he does have a blue badge so please
    utilise a disabled space.
</p>
<p>Jack has previously received support on a 3:1 staffing ratio decreasing to a 2:1 staffing ratio. Jack is
    now receiving support on a 1:1 staff basis due to a steep decline in target behaviour.
</p>
<hr>
<h3>Aim of Positive Behaviour Support Plan </h3>
<p>The aim of the positive behaviour support plan in implementation is to promote alternative coping
    mechanisms for Jack in times of crisis.
</p>
<ul>
    <li><span style="font-size: 14px;">To enable Jack to access the community and engage in activities.</span></li>
    <li><span style="font-size: 14px;">To reduce target behaviours.</span></li>
    <li><span style="font-size: 14px;">Promote emotional wellbeing and managing ongoing heightened behaviours.</span></li>
    <li><span style="font-size: 14px;">Promote positive relationships with those around Jack and encouraging positive family
            relationships.</span></li>
</ul>
<hr>
<h3>Setting Events (Slow triggers)</h3>
<ul>
    <li><span style="font-size: 14px;">Constipation</span></li>
    <li><span style="font-size: 14px;">Absence of his brother e.g., when he is at school and Jack is at home.</span></li>
    <li><span style="font-size: 14px;">In growing toenail</span></li>
    <li><span style="font-size: 14px;">Illness</span></li>
    <li><span style="font-size: 14px;">Underlying anxieties</span></li>
    <li><span style="font-size: 14px;">Busy or crowded environments</span></li>
    <li><span style="font-size: 14px;">Lack of closure of past events</span></li>
    <li><span style="font-size: 14px;">Historical DVD’s</span></li>
    <li><span style="font-size: 14px;">Historical events (available in history books).</span></li>
</ul>
<hr>
<h3>Discriminative Stimuli (Fast triggers)</h3>
<ul>
    <li><span style="font-size: 14px;">Recalling a past event / missed opportunity.</span></li>
    <li><span style="font-size: 14px;">Over-excitement</span></li>
    <li><span style="font-size: 14px;">Being asked to turn his iPad down.</span></li>
    <li><span style="font-size: 14px;">Frustration.</span></li>
    <li><span style="font-size: 14px;">His education timetable being changed / the Butterfly Centre being cancelled.</span></li>
    <li><span style="font-size: 14px;">Being asked to do something difficult.</span></li>
    <li><span style="font-size: 14px;">Loud music / radio (of music he doesn’t like)</span></li>
    <li><span style="font-size: 14px;">Being interrupted whilst e.g., on his iPad</span></li>
    <li><span style="font-size: 14px;">People in his personal space.</span></li>
    <li><span style="font-size: 14px;">Mention of “Stroud Gym”.</span></li>
    <li><span style="font-size: 14px;">Planned activities being cancelled.</span></li>
    <li><span style="font-size: 14px;">Waiting for others/in queues.</span></li>
    <li><span style="font-size: 14px;">Others not paying attention to Jack.</span></li>
    <li><span style="font-size: 14px;">Unexpected change.</span></li>
    <li><span style="font-size: 14px;">Being told he can’t go somewhere he has requested.</span></li>
    <li><span style="font-size: 14px;">Not understanding what to do.</span></li>
    <li><span style="font-size: 14px;">Ruminating on past/missed events.</span></li>
    <li><span style="font-size: 14px;">If Jack notices an abrasion on himself</span></li>
    <li><span style="font-size: 14px;">If people Jack is communicating to, do not understand what he is talking about / meaning.</span></li>
    <li><span style="font-size: 14px;">Not understanding what he is doing / where he’s going.</span></li>
    <li><span style="font-size: 14px;">Demands/requests to do something he doesn’t want to do.</span></li>
    <li><span style="font-size: 14px;">Something being taken from him.</span></li>
    <li><span style="font-size: 14px;">Noises made by others /brother shouting.</span></li>
    <li><span style="font-size: 14px;">Changes to his routine or the order in which he completes his routine.</span></li>
    <li><span style="font-size: 14px;">Denied access to items/activities/food.</span></li>
</ul>
<hr>
<h3>Behaviour of concern
</h3>
<p><strong>Self-Injurious behaviour: </strong></p>
<p>Jack displays self-injurious behaviour, typically:
</p>
<ul>
    <li><span style="font-size: 14px;">Biting his lower arms</span></li>
    <li><span style="font-size: 14px;">Biting the skin around his fingers</span></li>
    <li><span style="font-size: 14px;">Biting his fingernails until they bleed</span></li>
    <li><span style="font-size: 14px;">Picking scabs off wounds</span></li>
    <li><span style="font-size: 14px;">Picking his toenails off.</span></li>
</ul>
<p><strong>Verbal &amp; Physical Aggression:</strong></p>
<p> When heightened, Jack can display verbal aggression towards staff, such as:</p>
<ul>
    <li> <span style="font-size: 14px;">Screaming</span></li>
    <li><span style="font-size: 14px;">Shouting</span></li>
</ul>
<p>Jack has also been known to display physical aggression towards staff, such as:
</p>
<ul>
    <li><span style="font-size: 14px;">Biting</span></li>
    <li><span style="font-size: 14px;">Punching</span></li>
    <li><span style="font-size: 14px;">Grabbing</span></li>
    <li><span style="font-size: 14px;">Charging</span></li>
</ul>
<p><strong>Repetitive Behaviour:</strong> </p>
<p>Jack is known to repeat questions around events that have not taken place, particularly Billy Elliot
    anniversary being cancelled due to ‘bad year 2020’ (COVID 19), this will often further distress him.</p>
<p>Jack is known to frequently ask “what is your first film”, this is Jack asking what the first film is you
    have ever watched. Jack will become frustrated if you cannot recall this detail or if he doesn’t
    know the date the film was released. </p>
<p>Jack is known to pace back and forth when heightened. Jack will usually do this with his fingers in
    his ears.</p>
<hr>`;

export {
  supportPlanDummyData,
}