import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'html-react-parser';
import moment from 'moment';
import "react-toastify/dist/ReactToastify.css";
import { BASE_URL, GET_SUPPORT_PLAN_URL } from "../../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
} from "../../../helper/helper";
import { useNavigate } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from '../../../components/user-detail/UserDetailReview';
import ProgressComponent from '../../../components/general/ProgressComponent';

function CrisisManagement() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const [isLoading, setIsLoading] = useState(true);
  const [supportPlanData, setSupportPlanData] = useState({});
  const [addSupportPlan, setAddSupportPlan] = useState(false);
  const navigate = useNavigate();

  const apiQuery = {
    sortProperty: "createdAt",
    sortOrder: -1,
    offset: 0,
    limit: 10,
    query: {
      critarion: {
        userId: userData?._id
      }
    }
  }
  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const actionSupportPlanContentData = data?.data?.carePlans?.filter((item) => item?.supportType === "overview" && item?.active === true)
      if (actionSupportPlanContentData.length  === 0) {
        setAddSupportPlan(true)
      }
      else {
        setAddSupportPlan(false)
      }
      const supportPlanContentData = actionSupportPlanContentData?.map((item) => item);
      console.log("🚀 ~ processFetchRecords ~ supportPlanContentData:", supportPlanContentData)
      setSupportPlanData(supportPlanContentData[0]);
      setIsLoading(false);

    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_SUPPORT_PLAN_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ GET_SUPPORT_PLAN_URL:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);


  const handleEditViewCarePlans = (item, url) => {
    localStorage.setItem(
      "supportPlanDetail",
      JSON.stringify(item)
    );
    navigate(url);
  };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Support Plan - Crisis Management
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="viewEditorWrapper">
          <div className="mainContent mt-3 pt-4">
            {isLoading ? (
              <ProgressComponent />
            ) : (

              <>
                <header className="mainContentHeader">
                  <div className="usersNameInfo">
                    <h3>Crisis Management</h3>
                  </div>
                  <div>
                    <span className="btn btn-sm btn-primary" onClick={() => {
                      navigate("/support-plan");
                    }}>Back to Support Plan</span>
                    &nbsp;
                    {
                      addSupportPlan ? (
                        <>
                          <span className="btn btn-sm btn-secondary" onClick={() => {
                            navigate("/support-plan/add-crisis-management");
                          }}>Add</span>
                        </>
                      ) : (
                        <>
                          <span className="btn btn-sm btn-secondary"
                            onClick={() => handleEditViewCarePlans(supportPlanData, "/support-plan/modify-crisis-management")}
                          >Modify</span>
                        </>
                      )
                    }

                  </div>
                </header>
                <div className="viewEditorContent">
                  {
                    !addSupportPlan ? (
                      <>
                        <header className='createAndModifyStatus'>
                          <div className="enteredBy">
                            <span className="usersNameInfo">
                              <span className="userImage">
                                <img src={`${BASE_URL}${supportPlanData?.addedby?.profile_picture_url}`} alt={supportPlanData?.addedby?.name} />
                              </span>
                              <span className="userInfo">
                                <span><small>Created by:</small></span>
                                <strong >{supportPlanData?.addedby?.name}</strong>
                                <span><small>{moment(supportPlanData?.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}</small></span>
                              </span>
                            </span>
                          </div>
                        </header>
                        <h3> {supportPlanData?.title}</h3>
                        <div className="mt-3">
                          {
                            supportPlanData?.note ? (
                              ReactHtmlParser(supportPlanData?.note)
                            ) : ''
                          }
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="noRecordFound">
                          <p>No records found.</p>
                        </div>
                      </>
                    )
                  }

                </div>
              </>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default CrisisManagement