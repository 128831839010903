import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import DocumentsGroup from "./DocumentsGroup";
import DocumentsIndividual from "./DocumentsIndividual";

function Documents() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userID = searchParams.get("userId");

  return (
    <Layout pageTitleText="Documents">
      {userID ? (
        <DocumentsIndividual userIdData={userID} />
      ) : (
        <DocumentsGroup />
      )}
    </Layout>
  );
}

export default Documents;
