
import React from 'react'
import Authentication from '../components/layouts/Authentication'
import appointmentCancel from "../assets/images/icons/appointmentCancel.svg";
import { Link } from 'react-router-dom';

function PermissionErrorForWebAccess() {
  localStorage.removeItem("token");
  localStorage.removeItem("profileUserDetails");
  localStorage.removeItem("staffRoles");
  localStorage.removeItem("reduxState");
  localStorage.removeItem("userId");
  localStorage.removeItem("userLoggedInDetails");
  return (
    <Authentication>
      <div class="permissionDenied text-center">
        <img src={appointmentCancel} alt="Access Denied" />
        <p>You don't have permission to access this web app.<br />Please contact your administrator.</p>
      </div>
    </Authentication>
  )
}

export default PermissionErrorForWebAccess