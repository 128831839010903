import React from "react";

function DateInput({
  type,
  id,
  name,
  placeholder,
  valueText,
  value,
  onChange,
  onBlur,
  required,
  labelText,
  readOnly,
  errorClass,
  customError,
  errorMessage,
  requiredStar,
  disabledStatus,
  marginBottom,
  hidden,
  min,
  max,
  pattern,
  style
}) {
  return (
    <div className={`form-group ${errorClass ? "validationError" : ""} ${marginBottom ? "mb-2" : ""} ${hidden ? "hiddenDiv" : ""}`}>
      <input
        type={"text"}
        id={id}
        name={name}
        placeholder={placeholder}
        value={valueText}
        onChange={onChange}
        required={required}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabledStatus}
        min={min}
        max={max}
        pattern={pattern}
        style={style}
        className="inputTypeText"
      />
      <label htmlFor={id} className="floatingLabel">
        {labelText}
        {requiredStar ? <span className="text-danger">*</span> : null}
      </label>
      {customError ? <div className="text-danger">{errorMessage}</div> : null}
      <input
        type={"date"}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required={required}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabledStatus}
        min={min}
        max={max}
        pattern={pattern}
        style={style}
        className="inputTypeDate"
      />
    </div>
  );
}

export default DateInput;
