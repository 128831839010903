// Droppable.jsx
import React from 'react';
import { useDroppable } from '@dnd-kit/core';

export function Droppable(props) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
  });

  const style = {
    backgroundColor: isOver ? 'lightblue' : undefined,
  };

  return (

    <div className='droppableArea' ref={setNodeRef} style={style}>
      {props.children}
    </div>

  );
}

