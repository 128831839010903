import React from "react";

function ServiceUsersOptionsBlocks({ blockIcon, heading, linkedComponent }) {
  return (
    <>

      <div className="cards" onClick={linkedComponent}>
        <div className="cardContent">
          <span className="contentIcon">
            <img src={blockIcon} alt={heading} />
          </span>
          <h3>{heading}</h3>
        </div>
      </div>
    </>
  );
}

export default ServiceUsersOptionsBlocks;
