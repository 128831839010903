import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../components/forms-fields/Input";
import { useFormik } from "formik";
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { PUT_UPDATE_ROTA_TEMPLATE_URL, ADMIN_ID } from "../../../env";
import {
  updateRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import SelectBox from "../../../components/forms-fields/SelectBox";
// import { editRotaTemplate } from "../../../schemas";
function PublishRota({ publishDataTemplate, onSuccess }) {

  const storedRotaTemplateDetails = localStorage.getItem("RotaTemplateSchedule");
  const [rotaTemplateDetail, setRotaTemplateDetail] = useState(JSON.parse(storedRotaTemplateDetails));


  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastEditRotaTemplate, setIsToastEditRotaTemplate] = useState(false);

  const todayDate = moment().valueOf();


  const [suFormData, setSuFormData] = useState({
    tempId: rotaTemplateDetail?._id,
    status: rotaTemplateDetail?.status,
    startDate: rotaTemplateDetail?.startDate || null,
    lastModifiedBy: ADMIN_ID,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    // validationSchema: editRotaTemplate,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postEditRotaTemplateFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastEditRotaTemplate(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => (
        setIsToastEditRotaTemplate(false)
      ), 1000)
    } else if (method === "postEditRotaTemplateFormData" && data?.status === isSuccess) {
      setIsToastEditRotaTemplate(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => (
        setIsToastEditRotaTemplate(false)
      ), 1000)
      onSuccess()
    }
  };

  const postEditRotaTemplateFormData = catchAsync(async () => {
    const updateValues = {
      ...values,
      status: "active"
    };
    console.log("🚀 ~ postEditRotaTemplateFormData ~ updateValues:", updateValues)
    const response = await updateRecords(PUT_UPDATE_ROTA_TEMPLATE_URL, updateValues);
    console.log("🚀 ~ postEditRotaTemplateFormData ~ response:", response)
    processPostRecords("postEditRotaTemplateFormData", response);
    setIsSubmitButtonClicked(false);
  });

  useEffect(() => {
    if (values?.startDate === 0 || values?.startDate === null || !values?.startDate)
      setFieldValue("startDate", todayDate);
  }, [values])


  // useEffect(() => {
  //   if (isToastEditRotaTemplate) {
  //     fetchAllRecordOnPublish();
  //   }
  // }, [isToastEditRotaTemplate, fetchAllRecordOnPublish]);
  return (
    <>
      <form onSubmit={handleSubmit}>
        {isSubmitButtonClicked ? (
          <Button className="btn-sm" variant="primary" disabled>
            Loading...
          </Button>
        ) : (
          <Button className="btn-sm" variant="primary" type="submit">
            Publish
          </Button>
        )}
      </form>
      {isToastEditRotaTemplate && <ToastContainer />}
    </>

  )
}

export default PublishRota