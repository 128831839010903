import React from "react";

function MedicineStatus() {
  return (
    <>
      <span>R = Refused</span>
      <span>N = Nausea / Vomiting</span>
      <span>H = Hospital</span>
      <span>L = On Leave</span>
      <span>D = Destroyed</span>
      <span>S = Sleeping</span>
      <span>P = Pulse Abnormal</span>
      <span>NR = Not Required</span>
      <span>O = Other</span>
    </>
  );
}

export default MedicineStatus;
