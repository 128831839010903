import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Layout from "../../../components/layouts/Layout";
import editBlock from "../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../assets/images/icons/eventDel.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import password_eye_icon_circle from "../../../assets/images/icons/password_eye_icon_circle.svg";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL,
  GET_CARE_PLAN_DOC_URL,
  DELETE_CARE_PLAN_DOC_URL,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../../helper/helper";
import moment from "moment";
import { Link, useNavigate } from "react-router-dom";
import UserDetailReview from '../../../components/user-detail/UserDetailReview';
function CarePlans() {
  if (localStorage.getItem('carePlanDetail')) {
    localStorage.removeItem('carePlanDetail');
  }
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [addCarePlan, setAddCarePlan] = useState(false);
  const [userData, setUserData] = useState([]);
  // Show Hide add button
  useEffect(() => {
    setAddCarePlan(userData.length > 0 ? true : false);
  }, [userData]);
  const apiQuery = {
    query: {
      critarion: {
        userId: userDetails?._id,
      }
    },
    sortProperty: "createdAt",
    sortOrder: -1
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [carePlanIdData, setCarePlanIdData] = useState(
    {
      id: null,
    });
  const [isCarePlans, setIsCarePlans] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);



  // Delete CarePlan
  const [showDeleteCarePlans, setShowDeleteCarePlans] = useState(false);
  const subModalDeleteCarePlans = (carePlanItemId) => {
    setCarePlanIdData({ id: carePlanItemId });
    setShowDeleteCarePlans(!showDeleteCarePlans);
    fetchAllRecords();
  };


  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const carePlanDataResponse = data?.data?.list?.map((item) => item);
      setUserData(carePlanDataResponse);
      setIsLoading(false);

    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsCarePlans(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CARE_PLAN_DOC_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns
  const columns = [
    {
      name: "Document Created Date - Time",
      selector: (row) => {
        return (
          <div>
            <span>{moment(row?.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}</span>
          </div>
        );
      },
      width: "22%",
    },
    {
      name: "Entered By",
      selector: (row) => {
        return (
          <>
            <span className="usersNameInfo">
              <span className="userImage">
                <img src={`${BASE_URL}${row?.addedby?.profile_picture_url}`} alt={row?.addedby?.name} />
              </span>
              <span className="userInfo">
                <span className="nameBlock">{row?.addedby?.name}</span>
              </span>
            </span>
          </>
        );
      },
      width: "22%",
      wrap: true,
    },
    {
      name: "Last Modified Date - Time",
      selector: (row) => {
        const lastIndex = row.lastModifiedBy.length - 1;
        return (
          row?.lastModifiedBy[lastIndex]?.modifiedUser?.name ? (
            <>
              <div>
                <span className="m-0">{moment(row?.lastModifiedBy[lastIndex]?.modifiedTime).format("DD/MM/YYYY / hh:mm:ss A")}</span>
              </div>
            </>
          ) : (
            <span>&nbsp;</span>
          )
        );
      },
      width: "22%",
    },
    {
      name: "Last Modified By",
      selector: (row) => {
        const lastIndex = row.lastModifiedBy.length - 1;
        return (
          row?.lastModifiedBy[lastIndex]?.modifiedUser?.name ? (
            <>
              <span className="usersNameInfo">
                <span className="userImage">
                  <img src={`${BASE_URL}${row?.lastModifiedBy[lastIndex]?.modifiedUser?.profile_picture_url}`} alt={row?.lastModifiedBy[lastIndex]?.modifiedUser?.name} />
                </span>
                <span className="userInfo">
                  <span className="nameBlock">{row?.lastModifiedBy[lastIndex]?.modifiedUser?.name}</span>
                </span>

              </span>
            </>
          ) : (
            <span>&nbsp;</span>
          )
        );
      },
      width: "22%",
    },

    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">

            <span className="edit" onClick={() => handleEditViewCarePlans(row, "/view-care-plan")}>
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span>

            {/* <span className="edit" onClick={() => handleEditViewCarePlans(row, "/edit-care-plan")}>
              <img src={editBlock} alt="Edit" title="Edit" />
            </span> */}

            {/* <span
              className="delete"
              onClick={() => {
                subModalDeleteCarePlans(row?._id);
              }}
            >
              <img src={eventDel} alt="Delete" title="Delete" />
            </span> */}
          </div>
        );
      },
      width: "12%",
      center: true,
      wrap: true,
    },
  ];

  // Delete CarePlans records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (carePlanIdData !== "") {
      const response = await deleteRecords(
        DELETE_CARE_PLAN_DOC_URL,
        carePlanIdData
      );

      setIsCarePlans(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteCarePlans(!showDeleteCarePlans);
    } else {
      setShowDeleteCarePlans(!showDeleteCarePlans);
      setIsSubmitButtonClicked(false);
      setIsCarePlans(true);
      toastErrorMessages(toast.error, "Unable to remove", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsCarePlans(false);
    }, 2000);
  }, [isCarePlans])

  const handleEditViewCarePlans = (item, url) => {
    localStorage.setItem(
      "carePlanDetail",
      JSON.stringify(item)
    );
    navigate(url);
  };



  return (
    <>
      <Layout pageTitleText="Care Plans">
        <UserDetailReview
          getUserRecord={userDetails}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="rowClickUnset mt-3">
          <div className="mainContent">
            <div className="dataTableWrapper carePlanDataTableWrapper">
              <DataTable
                className="maxWidthTable minWidthTable"
                columns={columns}
                data={userData}
                highlightOnHover
                actions={
                  <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                    {
                      addCarePlan ? (
                        ''
                      ) : (
                        <>
                          <Link to="/add-care-plans" className="btn btn-sm btn-primary">
                            Add New Care Plan
                          </Link>
                        </>
                      )
                    }
                  </ProtectedComponent>
                }
                responsive={true}
                subHeader={true}
                subHeaderComponent={''}
                subHeaderAlign="left"
                subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
                progressPending={isLoading}
                progressComponent={<ProgressComponent />}
              />
            </div>
          </div>
        </div>
        {
          addCarePlan ? (
            <>
              <div className="row">
                <div className="col-md-6">
                  <div className="lastModifiedList">
                    <header>
                      <h3 className="sectionTitle">Modified By</h3>
                    </header>
                    <div className="lastModifiedListContent">
                      {userData?.map((item, index) => {
                        return item?.lastModifiedBy?.slice()?.reverse()?.map((modified, modifierIndex) => {
                          return (
                            <>
                              <div className="listContentItem" key={modifierIndex}>
                                <span className="usersNameInfo">
                                  <span className="userImage">
                                    <img src={`${BASE_URL}${modified?.modifiedUser?.profile_picture_url}`} alt={modified?.modifiedUser?.name} />
                                  </span>
                                  <span className="userInfo">
                                    <span><small>Modified by:</small></span>
                                    <strong >{modified?.modifiedUser?.name}</strong>
                                    {/* <span><small>Modified on:</small></span> */}
                                    <small>{moment(modified?.modifiedTime).format("DD/MM/YYYY / hh:mm:ss A")}</small>
                                  </span>
                                </span>
                              </div>
                            </>
                          )
                        })
                      })
                      }
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="lastModifiedList">
                    <header>
                      <h3 className="sectionTitle">Reviewed By</h3>
                    </header>
                    <div className="lastModifiedListContent">
                      {userData?.map((item, index) => {
                        return item?.reviewedBy?.slice()?.reverse()?.map((reviewed, reviewedIndex) => {
                          return (
                            <>
                              <div className="listContentItem" key={reviewedIndex}>
                                <span className="usersNameInfo">
                                  <span className="userImage">
                                    <img src={`${BASE_URL}${reviewed?.reviewedUser?.profile_picture_url}`} alt={reviewed?.reviewedUser?.name} />
                                  </span>
                                  <span className="userInfo">
                                    <span><small>Reviewed by:</small></span>
                                    <strong >{reviewed?.reviewedUser?.name}</strong>
                                    {
                                      reviewed?.involvementAndConsentCheck ? (
                                        <>
                                          <span className="suInvolvement text-success">
                                            <small>The Service User has been involved in the care plan.</small>
                                          </span>
                                        </>
                                      ) : (
                                        <>
                                          <span className="suInvolvement text-danger">
                                            <small>The Service User has not been involved in the care plan.</small>
                                          </span>
                                        </>
                                      )
                                    }
                                    {/* <span><small>Modified on:</small></span> */}
                                    <small>{moment(reviewed?.reviewedTime).format("DD/MM/YYYY / hh:mm:ss A")}</small>
                                  </span>
                                </span>
                              </div>
                            </>
                          )
                        })
                      })
                      }
                    </div>
                  </div>
                </div>
              </div>



            </>

          ) : ("")
        }
      </Layout>


      {/* Delete Care Plans */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteCarePlans}
          onHide={subModalDeleteCarePlans}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this item?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteCarePlans}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isCarePlans && <ToastContainer />}
    </>
  );
}

export default CarePlans;
