import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addHealthConsentCapacityMCADOLS } from "../../../../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_CARE_PLAN_URL } from "../../../../../../env";
import ReactDOMServer from 'react-dom/server';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
import { Form, Table } from "react-bootstrap";
import actionButtonDel from "../../../../../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../../../../../assets/images/icons/actionButtonTick.svg";
function AddConsentCapacityMCADOLS() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedby", userId);
  };

  const [suFormData, setSuFormData] = useState({
    addedby: selectedUserId,
    eventDateTime: moment().valueOf(),
    comments: "",
    section1: "",
    section2: "",
    section3Name: "",
    section3Role: "",
    section3Organisation: "",
    section3Address: "",
    section3Tel: "",
    section3Email: "",
    section3DateTime: moment().valueOf(),
    section4: "",
    section5Describe: "",
    section5Viable: "",
    section5WhyTicked: "",
    section6: "",
    section6RelevantDecision: "",
    section6MakeDecision: "",
    section6ProcessMakeDecision: "",
    section6CommunicateDecision: "",
    section6Evidence: "",
    section7: "",
    section7Seeking: "",
    section8: "",
    section9HaveDecisionName: "",
    section9HaveDecisionDate: moment().valueOf(),
    section9DontHaveDecisionName: "",
    section9DontHaveDecisionDate: moment().valueOf(),
    section9GiveReason: "",
    section9GiveReasonDate: moment().valueOf(),
    section9Name: "",
    section9Designation: "",
    section9BaseAddress: "",
    section9ContactDetails: "",
    learningDisability: "",
    relevantDecision: "",
    makeDecision: "",
    processMakeDecision: "",
    communicateDecision: "",
    coercionOrUndueInfluence: "",
    isIMCArequired: "",
    isMCARequired: "",
    decisionKeyPoints1: false,
    decisionKeyPoints2: false,
    decisionKeyPoints3: false,
    causativeNexusPoints1: false,
    causativeNexusPoints2: false,


  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addHealthConsentCapacityMCADOLS,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      userId: userDetails?._id,
      addedby: selectedUserId,
      noteType: "ConsentCapacityMCADOLS",
      isHTML: true,
      title: values?.comments,
      flag: false,
      noteDetails: noteDetailsString,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_CARE_PLAN_URL, updateValues);
    // console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };

  useEffect(() => {
    console.log("🚀 ~ AddConsentCapacityMCADOLS ~ values:", values)
  }, [values])
  const [htmlData, setHtmlData] = useState("")

  useEffect(() => {
    setHtmlData(
      <>
        <div className="row">
          <div className="col-md-12">
            <p><strong>Is an MCA required to be completed?</strong> <span id="isMCARequiredData">{values?.isMCARequired}</span></p>
          </div>
        </div>
        {/* Section1 */}
        <div className="row">
          <div className="col-md-12">
            <p><strong>Comments</strong> <span id="commentsData">{values?.comments}</span></p>
          </div>
          <div className="col-md-12">
            <p>
              <strong>This Mental Capacity Assessment must adhere to the Act’s 5 principles:</strong>
            </p>
            <ul>
              <li>Every adult has the right to make his or her own decisions and must be assumed to have capacity to make them unless proved otherwise.</li>
              <li>A person must be given all practicable help before anyone treat them as not being able to make their own decisions.</li>
              <li>Just because an individual makes what may be seen as an unwise decision, they should not be treated as lacking capacity to make that decision</li>
              <li>Anything done or any decision made on behalf of a person who lacks capacity must be done in their best interests. </li>
              <li>Anything done or any decision made on behalf of a person who lacks capacity should be the least restrictive of their basic rights and freedoms.</li>
            </ul>
          </div>
          <div className="col-md-12">
            <p>This form has been developed to support compliance with the Mental Capacity Act 2005.
              There is a statutory requirement for anyone undertaking an assessment to have regard to the Code of Practice for the Mental Capacity Act.  References given below refer to the relevant paragraphs of the Mental Capacity Act Code of Practice.  Please also refer to MCA and DoLS Policy and Guidance. (For day to day decisions, please print out/ fill in relevant sections 1.1  - 1.10)
            </p>
            <p>
              <strong>Detail</strong>
              <span id="section1Data">{values?.section1}</span>
            </p>
          </div>
        </div>

        {/* Section2 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.2 What is the specific decision relevant to this mental capacity assessment?</strong>  Please ensure that the decision is phrased in a way to enable all viable options to be discussed.  The MCA Code paragraph 4.4 states 'An assessment of a person’s capacity must be based on their ability to make a specific decision at the time it needs to be made, and not their ability to make decisions in general.'
            </p>
            <p>
              <strong>Detail</strong>
              <span id="section2Data">{values?.section2}</span>
            </p>

          </div>
        </div>

        {/* Section3 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.3 Person undertaking/or who has undertaken this assessment of capacity?</strong> The person with greatest responsibility for the specific decision is known as the ‘decision-maker’ and should assess capacity.  The decision maker is the person intending to make the decision or carry out the action.  Complex decisions may require specialist assessment - seek guidance.   See 4.38 to 4.43 of the Code.</p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Name</strong>
              <span id="section3NameData">{values?.section3Name}</span>
            </p>

          </div>
          <div className="col-md-6">
            <p>
              <strong>Role</strong>
              <span id="section3RoleData">{values?.section3Role}</span>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Organisation</strong>
              <span id="section3OrganisationData">{values?.section3Organisation}</span>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Address</strong>
              <span id="section3AddressData">{values?.section3Address}</span>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Tel</strong>
              <span id="section3TelData">{values?.section3Tel}</span>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Email</strong>
              <span id="section3EmailData">{values?.section3Email}</span>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Date and time of assessment</strong>
              <span id="section3DateTimeData">
                {moment(values?.section3DateTime).format("DD/MM/YYYY / hh:mm A")}
              </span>
            </p>
          </div>
        </div>

        {/* Section4 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.4 What concerns/triggers have given rise to this assessment of capacity?</strong> People have the right to make decisions that others might think are unwise.  A person who makes a decision that others think is unwise should not automatically be labelled as lacking the capacity to make a decision. See MCA Code 4.35.</p>
          </div>
          <div className="col-md-12">
            <p>
              <strong>What is the reason to believe this person may lack capacity to make this particular decision?  State your evidence:</strong></p>

            <p>
              <strong>Detail</strong>
              <span id="section4Data">{values?.section4}</span>
            </p>
          </div>
        </div>

        {/* Section5 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.5 Record your evidence here of the actions you have taken to support the person.  </strong>
              Consider what kind of help and support you can give the person to help them understand, retain, weigh up information and communicate their decision. </p>
            <p>
              <strong>Have you discussed with the person and/or appropriate others the most suitable venue for the assessment? </strong>
              For example: Does the person feel more comfortable in their own room? Does it need to be quiet? See MCA Code 3.13.</p>
            <p>
              <strong>Have you discussed with the person and/or appropriate others to establish timing of assessment</strong> For example: Is there a time of day that is better for the person? Would it help to have a particular person present? See MCA Code 3.14.</p>
            <p>
              <strong>Does the person have any language/communication issues? </strong> For example: Do they have hearing or speech difficulties? Do you need an interpreter? Do they communicate using special equipment e.g. a light talker communication device? See MCA Code 3.11.</p>
            <p>
              <strong>Have you provided all the information, regarding all viable and available options that the person   needs to consider, to make an informed decision? </strong>See MCA Code 3.7. The assessor must ensure that the person has:</p>
            <ol>
              <li>Sufficiently detailed alternative plans explained to them to allow them to weigh up the alternatives and make an informed choice where possible.</li>
              <li>Been supported by the assessor to explore the reasonably foreseeable consequences of deciding one way or another, or failing to make the decision.</li>
            </ol>
          </div>
          <p>
            <strong>Describe</strong>
            <span id="section5DescribeData">{values?.section5Describe}</span>
          </p>

          <p>
            <strong>Viable options considered</strong>
            <span id="section5ViableData">{values?.section5Viable}</span>
          </p>

          <div className="col-md-12">
            <p>
              <strong>If the decision is not urgent can it be delayed because the person is likely to regain or develop the capacity to make it for themselves?</strong></p>
            <div id="keyPoints" className="keyChecksWrapper">
              <div className="keyPoints">
                <div className="table-responsive">
                  <Table striped bordered hover className='dynamicRows'>
                    <tbody>
                      {decisionKeyPoints.map((row, index) => (
                        <tr key={row.id}>
                          <td>{row?.requirements}</td>
                          <td>
                            {
                              row?.fullfil ? (
                                <>
                                  <span id={`decisionKeyPoints${row.id}Data`} className="displayNone">
                                    Yes
                                  </span>
                                  <img
                                    src={actionButtonTick}
                                    alt="Tick Answer"
                                  />
                                </>
                              ) : (
                                <>
                                  <span id={`decisionKeyPoints${row.id}Data`} className="displayNone">
                                    No
                                  </span>
                                  <img
                                    src={actionButtonDel}
                                    alt="Close"
                                  />
                                </>
                              )
                            }
                          </td>
                          {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeKeyPoints(row.id) }} /></td> */}
                        </tr>
                      ))}

                      {/* <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addKeyPoints} /></td>
                              </tr> */}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
            <p>
              <strong>Explain why you have ticked box(s)</strong>
              <span id="section5WhyTickedData">{values?.section5WhyTicked}</span>
            </p>
          </div>
        </div>

        {/* Section6 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.6 Two Stage Capacity Assessment</strong> Answer the question with facts. The questions cannot be answered with a simple “yes” or “no” and you are asked to describe the assessment process. See MCA Code Ch. 4.  </p>
            <p><strong>Stage 1. Is there an impairment or disturbance in the functioning of the person’s mind or brain</strong> The person may not have a diagnosis but the Code says that proof of an impairment or disturbance of the functioning of the mind or brain is required.  You should record here your reasons for believing this to be the case. See 4.11 - 4.12 of the Code.  This could be because of, for example, a head injury, a suspected infection or stroke, a diagnosed dementia, mental illness, or learning disability.</p>


            <div className="checkboxList spaceInCheckList mb-3">
              <p><span id="learningDisabilityData">{values?.learningDisability}</span></p>
            </div>

            <p>
              <strong>Describe</strong>
              <span id="section6Data">{values?.section6}</span>
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <strong>If the person does not meet Stage 1, the assessment should immediately stop.
                Stage 2. Record here how the identified impairment or disturbance in Stage 1 is affecting the person’s ability to make the decision.
              </strong>See 4.13 to 4.30 of the Code.</p>
            <p><strong>Can the person understand the information relevant to the decision? </strong> See 4.16 to 4.19 of the Code.</p>
            <div className="checkboxList spaceInCheckList mb-3">
              <p><span id="relevantDecisionData">{values?.relevantDecision}</span></p>
            </div>
            <p>
              <strong>Describe how you assessed this</strong>
              <span id="section6RelevantDecisionData">{values?.section6RelevantDecision}</span>
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <strong>Can they retain that information long enough to make the decision? </strong>See 4.20 to 4.22 of the Code.</p>
            <div className="checkboxList spaceInCheckList mb-3">
              <p><span id="makeDecisionData">{values?.makeDecision}</span></p>
            </div>
            <p>
              <strong>Describe how you assessed this</strong>
              <span id="section6MakeDecisionData">{values?.section6MakeDecision}</span>
            </p>

          </div>
          <div className="col-md-12">
            <p>
              <strong>Can they use or weigh up that information as part of the process of making the decision? </strong>See 4.21 to 4.22 of the Code.</p>
            <div className="checkboxList spaceInCheckList mb-3">
              <p><span id="processMakeDecisionData">{values?.processMakeDecision}</span></p>
            </div>
            <p>
              <strong>Describe how you assessed this</strong>
              <span id="section6ProcessMakeDecisionData">{values?.section6ProcessMakeDecision}</span>
            </p>

          </div>
          <div className="col-md-12">
            <p>
              <strong>Can they communicate their decision, by any means available to them? </strong>See 4.23 to 4.25 of the Code.</p>
            <div className="checkboxList spaceInCheckList mb-3">
              <p><span id="communicateDecisionData">{values?.communicateDecision}</span></p>
            </div>


            <p>
              <strong>Describe the reasons for your conclusion</strong>
              <span id="section6CommunicateDecisionData">{values?.section6CommunicateDecision}</span>
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <strong>NB.  If all of the answers to the four questions above are YES, then Stage 2 is not met and the assessment must end.  </strong></p>
            <p><strong>Stage 3:  Causative Nexus</strong> There is a causative link between the impairment or disturbance in the functioning of mind and brain AND the inability to make the required decision. You must be able to evidence that the reason the person is unable to make the decision is because of the impairment or disturbance in the functioning of mind or brain and for no other reason.  </p>

            <div className="keyChecksWrapper">
              <div className="keyPoints">
                <div className="table-responsive">
                  <Table striped bordered hover className='dynamicRows'>
                    <tbody>
                      {causativeNexus.map((row, index) => (
                        <tr key={row.id}>
                          <td>{row?.requirements}</td>
                          <td>
                            {
                              row?.fullfil ? (
                                <>
                                 <span id={`causativeNexusPoints${row.id}Data`} className="displayNone">
                                    Yes
                                  </span>
                                  <img
                                    src={actionButtonTick}
                                    alt="Tick Answer"
                                  />
                                </>
                              ) : (
                                <>
                                 <span id={`causativeNexusPoints${row.id}Data`} className="displayNone">
                                    No
                                  </span>
                                  <img
                                    src={actionButtonDel}
                                    alt="Close"
                                  />
                                </>
                              )
                            }
                          </td>
                          {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeKeyPoints(row.id) }} /></td> */}
                        </tr>
                      ))}
                      <tr className='displayNone'>
                        <td colSpan={2}></td>
                      </tr>
                      {/* <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addKeyPoints} /></td>
                              </tr> */}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>


            <p>
              <strong>Evidence</strong>
              <span id="section6EvidenceData">{values?.section6Evidence}</span>
            </p>
          </div>
        </div>

        {/* Section7 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.7 Lack of mental capacity as a result of an impairment/disturbance in mind/brain must be distinguished from a situation where a person is unable to make their own decision as a result of duress or undue influence. A person who has the mental capacity to make decisions may have their ability to give free and true consent impaired if they are under constraint, coercion or undue influence. Duress and undue influence may be affected by eroded confidence due to fear of reprisal or abandonment, sense of obligation, cultural factors, power relationships or coercive control within domestic abuse.  Do you have a concern that the person may be under duress/coercion or undue influence in relation to the making of this decision?  If so, this will not satisfy the Stage 1 (Diagnostic) test.  You have to have an impairment or disturbance of the mind or brain to satisfy that test.  </strong></p>
            <p><strong>Do you have a concern that the person may be under duress, coercion or undue influence?</strong></p>

            <div className="checkboxList spaceInCheckList mb-3">
              <p><span id="coercionOrUndueInfluenceData">{values?.coercionOrUndueInfluence}</span></p>
            </div>
            <p>
              <strong>If yes, what is your evidence for saying this?</strong>
              <span id="section7Data">{values?.section7}</span>
            </p>
            <p>If yes, what actions you intend to take (including consideration of seeking management/legal advice)</p>
            <p>
              <strong>Describe</strong>
              <span id="section7SeekingData">{values?.section7Seeking}</span>
            </p>
          </div>
        </div>

        {/* Section8 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.8 Please record here any further information or content of your interview with the person.</strong></p>
            <p>
              <strong>Describe</strong>
              <span id="section8Data">{values?.section8}</span>
            </p>
          </div>
        </div>

        {/* Section9 */}
        <div className="row">
          <div className="col-md-12">
            <p>
              <strong>1.9 Determination of Capacity</strong></p>
            <p>I have assessed this person’s capacity to make the specific decision and determined on the balance of probability that they do not have the capacity to make this decision at this time.</p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Name</strong>
              <span id="section9HaveDecisionNameData">{values?.section9HaveDecisionName}</span>
            </p>

          </div>
          <div className="col-md-6">
            <p>
              <strong>Date</strong>
              <span id="section9HaveDecisionDateData">
                {moment(values?.section9HaveDecisionDate).format("DD/MM/YYYY / hh:mm A")}
              </span>
            </p>
          </div>
          <div className="col-md-12">
            <p>I have assessed this person’s capacity to make the specific decision and determined that on the balance of probability that they have the capacity to make this decision at this time.</p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Name</strong>
              <span id="section9DontHaveDecisionNameData">{values?.section9DontHaveDecisionName}</span>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Date</strong>
              <span id="section9DontHaveDecisionDateData">
                {moment(values?.section9DontHaveDecisionDate).format("DD/MM/YYYY / hh:mm A")}
              </span>
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <strong>Is an IMCA Required?</strong></p>
            <ul>
              <li>If the person (16+) is unbefriended and the decision is about a change of accommodation, or serious medical treatment, you MUST involve an IMCA.</li>
              <li>If a friend or family member exists, but they may not act in the person’s best interests (for example because they are the alleged victim or abuser in a Safeguarding Adults investigation) you MAY involve an IMCA.</li>
              <li>If the person is unbefriended and a health or social care review is being carried out, you MAY CONSIDER involving an IMCA as good practice.</li>
              <li>Although you may involve an IMCA under the Mental Capacity Act legislation, if there is no appropriate person, for people over age 18, you MUST instruct a Care Act Advocate if the person has substantial difficulty engaging with the relevant assessment & support planning/review/safeguarding process.  Please use the most appropriate legislation to ensure entitlement to advocacy. </li>
            </ul>

            <p>Does the individual require an IMCA? </p>
            <div className="checkboxList spaceInCheckList mb-3">
              <p><span id="isIMCArequiredData">{values?.isIMCArequired}</span></p>
            </div>
            <p>
              <strong>If not, please give reasons.</strong>
              <span id="section9GiveReasonData">{values?.section9GiveReason}</span>
            </p>
            <p>
              <strong>Date</strong>
              <span id="section9GiveReasonDateData">
                {moment(values?.section9GiveReasonDate).format("DD/MM/YYYY / hh:mm A")}
              </span>
            </p>
          </div>
          <div className="col-md-12">
            <p>
              <strong>Assessors Details.</strong></p></div>
          <div className="col-md-6">
            <p>
              <strong>Name</strong>
              <span id="section9NameData">{values?.section9Name}</span>
            </p>
          </div>
          <div className="col-md-6">
            <p>
              <strong>Designation</strong>
              <span id="section9DesignationData">{values?.section9Designation}</span>
            </p>
          </div>

          <div className="col-md-6">
            <p>
              <strong>Base / Address</strong>
              <span id="section9BaseAddressData">{values?.section9BaseAddress}</span>
            </p>

          </div>
          <div className="col-md-6">
            <p>
              <strong>Contact Details</strong>
              <span id="section9ContactDetailsData">{values?.section9ContactDetails}</span>
            </p>
          </div>
        </div>
      </>
    );
  }, [values]);

  // Section 6 Checkboxes
  const [learningDisability, setLearningDisability] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);
  const [relevantDecision, setRelevantDecision] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);
  const [makeDecision, setMakeDecision] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);
  const [processMakeDecision, setProcessMakeDecision] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);
  const [communicateDecision, setCommunicateDecision] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);

  // coercion or undue influence check boxes list
  const [coercionOrUndueInfluence, setCoercionOrUndueInfluence] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);

  // IMCA required check boxes list
  const [isIMCArequired, setIsIMCArequired] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);

  // is MCA required check boxes list
  const [isMCARequired, setIsParentContacted] = useState([
    { id: 1, values: "Yes", label: "Yes", checked: true },
    { id: 2, values: "No", label: "No", checked: false },
  ]);


  // Decision Key Points
  const [decisionKeyPoints, setDecisionKeyPoints] = useState([
    {
      id: 1,
      requirements: "The decision can be delayed",
      fullfil: values?.decisionKeyPoints1
    },
    {
      id: 2,
      requirements: "Not appropriate to delay the decision",
      fullfil: values?.decisionKeyPoints2
    },
    {
      id: 3,
      requirements: "Person not likely to gain or develop capacity ",
      fullfil: values?.decisionKeyPoints3
    },
  ]);

  // console.log("🚀 ~ AddConsentCapacityMCADOLS ~ decisionKeyPoints:", decisionKeyPoints)
  // console.log("🚀 ~ AddConsentCapacityMCADOLS ~ values:", values)
  // Causative Nexus Points
  const [causativeNexus, setCausativeNexus] = useState([

    {
      id: 1,
      requirements: "Yes, there is a causative link ",
      fullfil: values?.causativeNexusPoints1
    },
    {
      id: 2,
      requirements: "No, there is not a causative link, so the person has capacity to make the relevant decision.  The decision may therefore be an unwise decision. ",
      fullfil: values?.causativeNexusPoints2
    },
  ]);

  const handleKeyPointsInputChange = (keyPoints, id, field, value, setKeyPoints, valuesArray) => {
    const updatedRows = keyPoints.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setKeyPoints(updatedRows);
    setFieldValue(valuesArray, value)
  };



  return (

    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Add Consent, Capacity, MCA & DOLS note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={selectedUserId}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-12">
                      <TextArea
                        id="comments"
                        name="comments"
                        value={values?.comments}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Comments"
                        rows="3"
                        className="mb-0"
                        customError={touched?.comments && errors?.comments}
                        errorMessage={errors?.comments}
                        erroClass={
                          touched?.comments && errors?.comments
                            ? "validationError"
                            : ""
                        }
                      /*requiredStar={true} */
                      />
                    </div>

                    <div className="col-md-12 mb-3">
                      <label className="general m-2">Is an MCA required to be completed?</label>
                      <div className="checkboxList spaceInCheckList">
                        {isMCARequired.map((row, index) => (
                          <>
                            <Form.Check
                              key={row.id}
                              name={"isMCARequired"}
                              type="radio"
                              id={row?.label}
                              label={row?.label}
                              value={row.values}
                              onChange={handleInputChangePrevFields}
                              onBlur={handleBlur}
                            />
                          </>
                        ))}

                      </div>
                    </div>
                  </div>
                  {values?.isMCARequired === "Yes" && (
                    <>
                      {/* Section1 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>This Mental Capacity Assessment must adhere to the Act’s 5 principles:</strong>
                          </p>
                          <ul>
                            <li>Every adult has the right to make his or her own decisions and must be assumed to have capacity to make them unless proved otherwise.</li>
                            <li>A person must be given all practicable help before anyone treat them as not being able to make their own decisions.</li>
                            <li>Just because an individual makes what may be seen as an unwise decision, they should not be treated as lacking capacity to make that decision</li>
                            <li>Anything done or any decision made on behalf of a person who lacks capacity must be done in their best interests. </li>
                            <li>Anything done or any decision made on behalf of a person who lacks capacity should be the least restrictive of their basic rights and freedoms.</li>
                          </ul>
                        </div>
                        <div className="col-md-12">
                          <p>This form has been developed to support compliance with the Mental Capacity Act 2005.
                            There is a statutory requirement for anyone undertaking an assessment to have regard to the Code of Practice for the Mental Capacity Act.  References given below refer to the relevant paragraphs of the Mental Capacity Act Code of Practice.  Please also refer to MCA and DoLS Policy and Guidance. (For day to day decisions, please print out/ fill in relevant sections 1.1  - 1.10)
                          </p>
                          <TextArea
                            id="section1"
                            name="section1"
                            value={values?.section1}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Detail"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section1 && errors?.section1}
                            errorMessage={errors?.section1}
                            erroClass={
                              touched?.section1 && errors?.section1
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                      </div>

                      {/* Section2 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.2 What is the specific decision relevant to this mental capacity assessment?</strong>  Please ensure that the decision is phrased in a way to enable all viable options to be discussed.  The MCA Code paragraph 4.4 states 'An assessment of a person’s capacity must be based on their ability to make a specific decision at the time it needs to be made, and not their ability to make decisions in general.'
                          </p>
                          <TextArea
                            id=""
                            name="section2"
                            value={values?.section2}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Detail"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section2 && errors?.section2}
                            errorMessage={errors?.section2}
                            erroClass={
                              touched?.section2 && errors?.section2
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                      </div>

                      {/* Section3 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.3 Person undertaking/or who has undertaken this assessment of capacity?</strong> The person with greatest responsibility for the specific decision is known as the ‘decision-maker’ and should assess capacity.  The decision maker is the person intending to make the decision or carry out the action.  Complex decisions may require specialist assessment - seek guidance.   See 4.38 to 4.43 of the Code.</p>
                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="section3Name"
                            name="section3Name"
                            value={values?.section3Name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Name"
                            customError={touched?.section3Name && errors?.section3Name}
                            errorMessage={errors?.section3Name}
                            erroClass={
                              touched?.section3Name && errors?.section3Name
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="section3Role"
                            name="section3Role"
                            value={values?.section3Role}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Role"
                            customError={touched?.section3Role && errors?.section3Role}
                            errorMessage={errors?.section3Role}
                            erroClass={
                              touched?.section3Role && errors?.section3Role
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="section3Organisation"
                            name="section3Organisation"
                            value={values?.section3Organisation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Organisation"
                            customError={touched?.section3Organisation && errors?.section3Organisation}
                            errorMessage={errors?.section3Organisation}
                            erroClass={
                              touched?.section3Organisation && errors?.section3Organisation
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="section3Address"
                            name="section3Address"
                            value={values?.section3Address}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Address"
                            customError={touched?.section3Address && errors?.section3Address}
                            errorMessage={errors?.section3Address}
                            erroClass={
                              touched?.section3Address && errors?.heisection3Addressght
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="tel"
                            id="section3Tel"
                            name="section3Tel"
                            value={values?.section3Tel}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Tel"
                            customError={touched?.section3Tel && errors?.section3Tel}
                            errorMessage={errors?.section3Tel}
                            erroClass={
                              touched?.section3Tel && errors?.section3Tel
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="section3Email"
                            name="section3Email"
                            value={values?.section3Email}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Email"
                            customError={touched?.section3Email && errors?.hesection3Emailight}
                            errorMessage={errors?.section3Email}
                            erroClass={
                              touched?.section3Email && errors?.section3Email
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="datetime-local"
                            id="section3DateTime"
                            name="section3DateTime"
                            value={moment(values?.section3DateTime).format('YYYY-MM-DDTHH:mm')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Date and time of assessment"
                            customError={touched?.section3DateTime && errors?.section3DateTime}
                            errorMessage={errors?.section3DateTime}
                            erroClass={
                              touched?.section3DateTime && errors?.section3DateTime
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                      </div>

                      {/* Section4 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.4 What concerns/triggers have given rise to this assessment of capacity?</strong> People have the right to make decisions that others might think are unwise.  A person who makes a decision that others think is unwise should not automatically be labelled as lacking the capacity to make a decision. See MCA Code 4.35.</p>
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>What is the reason to believe this person may lack capacity to make this particular decision?  State your evidence:</strong></p>
                          <TextArea
                            id=""
                            name="section4"
                            value={values?.section4}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Detail"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section4 && errors?.section4}
                            errorMessage={errors?.section4}
                            erroClass={
                              touched?.section4 && errors?.section4
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                      </div>

                      {/* Section5 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.5 Record your evidence here of the actions you have taken to support the person.  </strong>
                            Consider what kind of help and support you can give the person to help them understand, retain, weigh up information and communicate their decision. </p>
                          <p>
                            <strong>Have you discussed with the person and/or appropriate others the most suitable venue for the assessment? </strong>
                            For example: Does the person feel more comfortable in their own room? Does it need to be quiet? See MCA Code 3.13.</p>
                          <p>
                            <strong>Have you discussed with the person and/or appropriate others to establish timing of assessment</strong> For example: Is there a time of day that is better for the person? Would it help to have a particular person present? See MCA Code 3.14.</p>
                          <p>
                            <strong>Does the person have any language/communication issues? </strong> For example: Do they have hearing or speech difficulties? Do you need an interpreter? Do they communicate using special equipment e.g. a light talker communication device? See MCA Code 3.11.</p>
                          <p>
                            <strong>Have you provided all the information, regarding all viable and available options that the person   needs to consider, to make an informed decision? </strong>See MCA Code 3.7. The assessor must ensure that the person has:</p>
                          <ol>
                            <li>Sufficiently detailed alternative plans explained to them to allow them to weigh up the alternatives and make an informed choice where possible.</li>
                            <li>Been supported by the assessor to explore the reasonably foreseeable consequences of deciding one way or another, or failing to make the decision.</li>
                          </ol>
                        </div>
                        <TextArea
                          id=""
                          name="section5Describe"
                          value={values?.section5Describe}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Describe"
                          rows="3"
                          className="mb-0"
                          customError={touched?.section5Describe && errors?.section5Describe}
                          errorMessage={errors?.section5Describe}
                          erroClass={
                            touched?.section5Describe && errors?.section5Describe
                              ? "validationError"
                              : ""
                          }
                        /*requiredStar={true} */
                        />
                        <TextArea
                          id="section5Viable"
                          name="section5Viable"
                          value={values?.section5Viable}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Viable options considered"
                          rows="3"
                          className="mb-0"
                          customError={touched?.section5Viable && errors?.section5Viable}
                          errorMessage={errors?.section5Viable}
                          erroClass={
                            touched?.section5Viable && errors?.section5Viable
                              ? "validationError"
                              : ""
                          }
                        /*requiredStar={true} */
                        />
                        <div className="col-md-12">
                          <p>
                            <strong>If the decision is not urgent can it be delayed because the person is likely to regain or develop the capacity to make it for themselves?</strong></p>
                          <div className="keyChecksWrapper">
                            <div className="keyPoints">
                              <div className="table-responsive">
                                <Table striped bordered hover className='dynamicRows'>
                                  <tbody>
                                    {decisionKeyPoints.map((row, index) => (
                                      <tr key={row.id}>
                                        <td>{row?.requirements}</td>
                                        <td>
                                          {/* <Form.Check aria-label="option 1" /> */}
                                          <Form.Check
                                            id={`decisionKeyPoints${row.id}Data`}
                                            name={`decisionKeyPoints${row.id}Data`}
                                            // aria-label={row.requirements}
                                            checked={row.fullfil}
                                            onChange={(e) => handleKeyPointsInputChange(decisionKeyPoints, row.id, 'fullfil', e.target.checked, setDecisionKeyPoints, `decisionKeyPoints${row.id}Data`)}
                                          />
                                          {/* {row?.fullfil} */}
                                        </td>
                                        {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeKeyPoints(row.id) }} /></td> */}
                                      </tr>
                                    ))}

                                    {/* <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addKeyPoints} /></td>
                              </tr> */}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>
                          <TextArea
                            id=""
                            name="section5WhyTicked"
                            value={values?.section5WhyTicked}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Explain why you have ticked box(s)"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section5WhyTicked && errors?.section5WhyTicked}
                            errorMessage={errors?.section5WhyTicked}
                            erroClass={
                              touched?.section5WhyTicked && errors?.section5WhyTicked
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                      </div>

                      {/* Section6 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.6 Two Stage Capacity Assessment</strong> Answer the question with facts. The questions cannot be answered with a simple “yes” or “no” and you are asked to describe the assessment process. See MCA Code Ch. 4.  </p>
                          <p><strong>Stage 1. Is there an impairment or disturbance in the functioning of the person’s mind or brain</strong> The person may not have a diagnosis but the Code says that proof of an impairment or disturbance of the functioning of the mind or brain is required.  You should record here your reasons for believing this to be the case. See 4.11 - 4.12 of the Code.  This could be because of, for example, a head injury, a suspected infection or stroke, a diagnosed dementia, mental illness, or learning disability.</p>


                          <div className="checkboxList spaceInCheckList mb-3">
                            {learningDisability.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name={"learningDisability"}
                                  type="radio"
                                  id={row?.label}
                                  label={row?.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>


                          <TextArea
                            id=""
                            name="section6"
                            value={values?.section6}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Describe"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section6 && errors?.section6}
                            errorMessage={errors?.section6}
                            erroClass={
                              touched?.section6 && errors?.section6
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>If the person does not meet Stage 1, the assessment should immediately stop.
                              Stage 2. Record here how the identified impairment or disturbance in Stage 1 is affecting the person’s ability to make the decision.
                            </strong>See 4.13 to 4.30 of the Code.</p>
                          <p><strong>Can the person understand the information relevant to the decision? </strong> See 4.16 to 4.19 of the Code.</p>
                          <div className="checkboxList spaceInCheckList mb-3">
                            {relevantDecision.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name={"relevantDecision"}
                                  type="radio"
                                  id={row?.label}
                                  label={row?.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>


                          <TextArea
                            id=""
                            name="section6RelevantDecision"
                            value={values?.section6RelevantDecision}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Describe how you assessed this"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section6RelevantDecision && errors?.section6RelevantDecision}
                            errorMessage={errors?.section6RelevantDecision}
                            erroClass={
                              touched?.section6RelevantDecision && errors?.section6RelevantDecision
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>Can they retain that information long enough to make the decision? </strong>See 4.20 to 4.22 of the Code.</p>
                          <div className="checkboxList spaceInCheckList mb-3">
                            {makeDecision.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name={"makeDecision"}
                                  type="radio"
                                  id={row?.label}
                                  label={row?.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>


                          <TextArea
                            id=""
                            name="section6MakeDecision"
                            value={values?.section6MakeDecision}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Describe how you assessed this"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section6MakeDecision && errors?.section6MakeDecision}
                            errorMessage={errors?.section6MakeDecision}
                            erroClass={
                              touched?.section6MakeDecision && errors?.section6MakeDecision
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>Can they use or weigh up that information as part of the process of making the decision? </strong>See 4.21 to 4.22 of the Code.</p>
                          <div className="checkboxList spaceInCheckList mb-3">
                            {processMakeDecision.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name={"processMakeDecision"}
                                  type="radio"
                                  id={row?.label}
                                  label={row?.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>


                          <TextArea
                            id=""
                            name="section6ProcessMakeDecision"
                            value={values?.section6ProcessMakeDecision}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Describe how you assessed this"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section6ProcessMakeDecision && errors?.section6ProcessMakeDecision}
                            errorMessage={errors?.section6ProcessMakeDecision}
                            erroClass={
                              touched?.section6ProcessMakeDecision && errors?.section6ProcessMakeDecision
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>Can they communicate their decision, by any means available to them? </strong>See 4.23 to 4.25 of the Code.</p>
                          <div className="checkboxList spaceInCheckList mb-3">
                            {communicateDecision.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name={"communicateDecision"}
                                  type="radio"
                                  id={row?.label}
                                  label={row?.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>


                          <TextArea
                            id=""
                            name="section6CommunicateDecision"
                            value={values?.section6CommunicateDecision}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Describe the reasons for your conclusion"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section6CommunicateDecision && errors?.section6CommunicateDecision}
                            errorMessage={errors?.section6CommunicateDecision}
                            erroClass={
                              touched?.section6CommunicateDecision && errors?.section6CommunicateDecision
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          /></div>
                        <div className="col-md-12">
                          <p>
                            <strong>NB.  If all of the answers to the four questions above are YES, then Stage 2 is not met and the assessment must end.  </strong></p>
                          <p><strong>Stage 3:  Causative Nexus</strong> There is a causative link between the impairment or disturbance in the functioning of mind and brain AND the inability to make the required decision. You must be able to evidence that the reason the person is unable to make the decision is because of the impairment or disturbance in the functioning of mind or brain and for no other reason.  </p>

                          <div className="keyChecksWrapper">
                            <div className="keyPoints">
                              <div className="table-responsive">
                                <Table striped bordered hover className='dynamicRows'>
                                  <tbody>
                                    {causativeNexus.map((row, index) => (
                                      <tr key={row.id}>
                                        <td>{row?.requirements}</td>
                                        <td>
                                          {/* <Form.Check aria-label="option 1" /> */}
                                          <Form.Check
                                            id={`causativeNexusPoints${row.id}Data`}
                                            name={`causativeNexusPoints${row.id}Data`}
                                            // aria-label={row.requirements}
                                            checked={row.fullfil}
                                            onChange={(e) => handleKeyPointsInputChange(causativeNexus, row.id, 'fullfil', e.target.checked, setCausativeNexus, `causativeNexusPoints${row.id}Data`)}
                                          />
                                         
                                          {/* {row?.fullfil} */}
                                        </td>
                                        {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeKeyPoints(row.id) }} /></td> */}
                                      </tr>
                                    ))}
                                    <tr className='displayNone'>
                                      <td colSpan={2}></td>
                                    </tr>
                                    {/* <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addKeyPoints} /></td>
                              </tr> */}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </div>


                          <TextArea
                            id=""
                            name="section6Evidence"
                            value={values?.section6Evidence}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Evidence"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section6Evidence && errors?.section6Evidence}
                            errorMessage={errors?.section6Evidence}
                            erroClass={
                              touched?.section6Evidence && errors?.section6Evidence
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                      </div>

                      {/* Section7 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.7 Lack of mental capacity as a result of an impairment/disturbance in mind/brain must be distinguished from a situation where a person is unable to make their own decision as a result of duress or undue influence. A person who has the mental capacity to make decisions may have their ability to give free and true consent impaired if they are under constraint, coercion or undue influence. Duress and undue influence may be affected by eroded confidence due to fear of reprisal or abandonment, sense of obligation, cultural factors, power relationships or coercive control within domestic abuse.  Do you have a concern that the person may be under duress/coercion or undue influence in relation to the making of this decision?  If so, this will not satisfy the Stage 1 (Diagnostic) test.  You have to have an impairment or disturbance of the mind or brain to satisfy that test.  </strong></p>
                          <p><strong>Do you have a concern that the person may be under duress, coercion or undue influence?</strong></p>

                          <div className="checkboxList spaceInCheckList mb-3">
                            {coercionOrUndueInfluence.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name={"coercionOrUndueInfluence"}
                                  type="radio"
                                  id={row?.label}
                                  label={row?.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>


                          <TextArea
                            id=""
                            name="section7"
                            value={values?.section7}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="If yes, what is your evidence for saying this?"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section7 && errors?.section7}
                            errorMessage={errors?.section7}
                            erroClass={
                              touched?.section7 && errors?.section7
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                          <p>If yes, what actions you intend to take (including consideration of seeking management/legal advice)</p>
                          <TextArea
                            id=""
                            name="section7Seeking"
                            value={values?.section7Seeking}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Describe"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section7Seeking && errors?.section7Seeking}
                            errorMessage={errors?.section7Seeking}
                            erroClass={
                              touched?.section7Seeking && errors?.section7Seeking
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                      </div>

                      {/* Section8 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.8 Please record here any further information or content of your interview with the person.</strong></p>
                          <TextArea
                            id=""
                            name="section8"
                            value={values?.section8}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="Describe"
                            rows="3"
                            className="mb-0"
                            customError={touched?.section8 && errors?.section8}
                            errorMessage={errors?.section8}
                            erroClass={
                              touched?.section8 && errors?.section8
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                      </div>

                      {/* Section9 */}
                      <div className="row">
                        <div className="col-md-12">
                          <p>
                            <strong>1.9 Determination of Capacity</strong></p>
                          <p>I have assessed this person’s capacity to make the specific decision and determined on the balance of probability that they do not have the capacity to make this decision at this time.</p>
                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id=""
                            name="section9HaveDecisionName"
                            value={values?.section9HaveDecisionName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Name"
                            customError={touched?.section9HaveDecisionName && errors?.section9HaveDecisionName}
                            errorMessage={errors?.section9HaveDecisionName}
                            erroClass={
                              touched?.section9HaveDecisionName && errors?.section9HaveDecisionName
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="datetime-local"
                            id=""
                            name="section9HaveDecisionDate"
                            value={moment(values?.section9HaveDecisionDate).format('YYYY-MM-DDTHH:mm')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Date"
                            customError={touched?.section9HaveDecisionDate && errors?.section9HaveDecisionDate}
                            errorMessage={errors?.section9HaveDecisionDate}
                            erroClass={
                              touched?.section9HaveDecisionDate && errors?.section9HaveDecisionDate
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-12">
                          <p>I have assessed this person’s capacity to make the specific decision and determined that on the balance of probability that they have the capacity to make this decision at this time.</p>
                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id=""
                            name="section9DontHaveDecisionName"
                            value={values?.section9DontHaveDecisionName}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Name"
                            customError={touched?.section9DontHaveDecisionName && errors?.section9DontHaveDecisionName}
                            errorMessage={errors?.section9DontHaveDecisionName}
                            erroClass={
                              touched?.section9DontHaveDecisionName && errors?.section9DontHaveDecisionName
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="datetime-local"
                            id=""
                            name="section9DontHaveDecisionDate"
                            value={moment(values?.section9DontHaveDecisionDate).format('YYYY-MM-DDTHH:mm')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Date"
                            customError={touched?.section9DontHaveDecisionDate && errors?.section9DontHaveDecisionDate}
                            errorMessage={errors?.section9DontHaveDecisionDate}
                            erroClass={
                              touched?.section9DontHaveDecisionDate && errors?.section9DontHaveDecisionDate
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>Is an IMCA Required?</strong></p>
                          <ul>
                            <li>If the person (16+) is unbefriended and the decision is about a change of accommodation, or serious medical treatment, you MUST involve an IMCA.</li>
                            <li>If a friend or family member exists, but they may not act in the person’s best interests (for example because they are the alleged victim or abuser in a Safeguarding Adults investigation) you MAY involve an IMCA.</li>
                            <li>If the person is unbefriended and a health or social care review is being carried out, you MAY CONSIDER involving an IMCA as good practice.</li>
                            <li>Although you may involve an IMCA under the Mental Capacity Act legislation, if there is no appropriate person, for people over age 18, you MUST instruct a Care Act Advocate if the person has substantial difficulty engaging with the relevant assessment & support planning/review/safeguarding process.  Please use the most appropriate legislation to ensure entitlement to advocacy. </li>
                          </ul>

                          <p>Does the individual require an IMCA? </p>
                          <div className="checkboxList spaceInCheckList mb-3">
                            {isIMCArequired.map((row, index) => (
                              <>
                                <Form.Check
                                  key={row.id}
                                  name={"isIMCArequired"}
                                  type="radio"
                                  id={row?.label}
                                  label={row?.label}
                                  value={row.values}
                                  onChange={handleInputChangePrevFields}
                                  onBlur={handleBlur}
                                />
                              </>
                            ))}

                          </div>


                          <TextArea
                            id=""
                            name="section9GiveReason"
                            value={values?.section9GiveReason}
                            onChange={handleInputChangePrevFields}
                            onBlur={handleBlur}
                            labelText="If not, please give reasons."
                            rows="3"
                            className="mb-0"
                            customError={touched?.section9GiveReason && errors?.section9GiveReason}
                            errorMessage={errors?.section9GiveReason}
                            erroClass={
                              touched?.section9GiveReason && errors?.section9GiveReason
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                          <Input
                            type="datetime-local"
                            id=""
                            name="section9GiveReasonDate"
                            value={moment(values?.section9GiveReasonDate).format('YYYY-MM-DDTHH:mm')}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Date"
                            customError={touched?.section9GiveReasonDate && errors?.section9GiveReasonDate}
                            errorMessage={errors?.section9GiveReasonDate}
                            erroClass={
                              touched?.section9GiveReasonDate && errors?.section9GiveReasonDate
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />
                        </div>
                        <div className="col-md-12">
                          <p>
                            <strong>Assessors Details.</strong></p></div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id=""
                            name="section9Name"
                            value={values?.section9Name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Name"
                            customError={touched?.section9Name && errors?.section9Name}
                            errorMessage={errors?.section9Name}
                            erroClass={
                              touched?.section9Name && errors?.section9Name
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id=""
                            name="section9Designation"
                            value={values?.section9Designation}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Designation"
                            customError={touched?.section9Designation && errors?.section9Designation}
                            errorMessage={errors?.section9Designation}
                            erroClass={
                              touched?.section9Designation && errors?.section9Designation
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>

                        <div className="col-md-6">
                          <Input
                            type="text"
                            id=""
                            name="section9BaseAddress"
                            value={values?.section9BaseAddress}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Base / Address"
                            customError={touched?.section9BaseAddress && errors?.section9BaseAddress}
                            errorMessage={errors?.section9BaseAddress}
                            erroClass={
                              touched?.section9BaseAddress && errors?.section9BaseAddress
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                        <div className="col-md-6">
                          <Input
                            type="text"
                            id="section9ContactDetails"
                            name="section9ContactDetails"
                            value={values?.section9ContactDetails}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Contact Details"
                            customError={touched?.section9ContactDetails && errors?.section9ContactDetails}
                            errorMessage={errors?.section9ContactDetails}
                            erroClass={
                              touched?.section9ContactDetails && errors?.section9ContactDetails
                                ? "validationError"
                                : ""
                            }
                          /*requiredStar={true} */
                          />

                        </div>
                      </div>
                    </>
                  )}
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>

              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AddConsentCapacityMCADOLS