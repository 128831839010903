import React, { useEffect, useState } from 'react';
import Layout from "../../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import TextArea from '../../../components/forms-fields/TextArea';
import SelectBox from '../../../components/forms-fields/SelectBox';
import Input from '../../../components/forms-fields/Input';
import { useFormik } from "formik";
import { addServiceUserReview } from "../../../schemas";
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import { UPDATE_SERVICE_USERS_REVIEW } from "../../../env";
import Table from 'react-bootstrap/Table';
import { ToastContainer, toast } from "react-toastify";
import eventClose from "../../../assets/images/icons/eventClose.svg";
import eventAdd from "../../../assets/images/icons/eventAdd.svg";
import moment from 'moment';
import { Form } from "react-bootstrap";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import { connect } from 'react-redux';
import { selectStaffMember } from '../../../redux/actions/selectStaffMemberAction';
function EditReview({ selectedUserId, handleUserSelect }) {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const userReviewDetails = localStorage.getItem("userReviewData");
  const userReviewData = JSON.parse(userReviewDetails);


  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddAppointment, setIsToastAddAppointment] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isStaffId, setIsStaffId] = useState(false);

  const [reviewFormData, setReviewFormData] = useState({
    id: userReviewData?._id,
    commentBy: selectedUserId,
    commentFor: userDetails?._id,
    review: userReviewData?.review,
    personalDetails: {
      locality: userReviewData?.personalDetails?.locality,
      liquidLogic: userReviewData?.personalDetails?.liquidLogic,
      dolsApplicable: userReviewData?.personalDetails?.dolsApplicable,
      dor: moment(userReviewData?.personalDetails?.dor).format("YYYY-MM-DD"),
    },
    mdtDetails: {
      nominatedIndividual: userReviewData?.mdtDetails?.nominatedIndividual,
      suManager: userReviewData?.mdtDetails?.suManager,
      dptSuManager: userReviewData?.mdtDetails?.dptSuManager,
      socWorker: userReviewData?.mdtDetails?.socWorker,
      family: userReviewData?.mdtDetails?.family,
      other: userReviewData?.mdtDetails?.other,
    },
    icDiagnosisOther: userReviewData?.icDiagnosisOther,
    supportAreas: [],
    medications: {
      regular: userReviewData?.medications?.regular,
      prn: userReviewData?.medications?.prn,
      physicalHealth: userReviewData?.medications?.physicalHealth,
    },
    actionPoints: [],
    legalStatus: {
      regular: userReviewData?.legalStatus?.regular,
      mood_mentalState: userReviewData?.legalStatus?.mood_mentalState,
      interactions: userReviewData?.legalStatus?.interactions,
      diet: userReviewData?.legalStatus?.diet,
      medConcordance: userReviewData?.legalStatus?.medConcordance,
      sleep: userReviewData?.legalStatus?.sleep,
    },
    incidentLogged: [],
    comments: userReviewData?.comments,
    managerFeedbackComments: userReviewData?.managerFeedbackComments,
    keyCheckByTeam: [],
    educationFeedbackComments: userReviewData?.educationFeedbackComments,
    engagement: {
      activityOrSocial: userReviewData?.engagement?.activityOrSocial,
      engInTheraputicTimeTable: userReviewData?.engagement?.engInTheraputicTimeTable,
    },
    socialWorkFeedback: {
      involvement: userReviewData?.socialWorkFeedback?.involvement,
      safeguarding: userReviewData?.socialWorkFeedback?.safeguarding,
    },
    familyFeedback: {
      involvement: userReviewData?.familyFeedback?.involvement,
    },
    mdtDiscussions: {
      safeguarding: userReviewData?.mdtDiscussions?.safeguarding,
    },
    riskAssesments: [],
    riskAssesmentComments: userReviewData?.riskAssesmentComments,
    summaryOfPlan: [],
    requests: [],
    riskPlanChanged: userReviewData?.riskPlanChanged,
    suPlanUptoDate: userReviewData?.suPlanUptoDate,
    agreedActions: [],
    managementUseOnly: [],

  });


  // Form Wizards
  const [currentStep, setCurrentStep] = useState(1);

  const handleNext = () => {
    setCurrentStep((prevStep) => prevStep + 1);
  };

  const handlePrev = () => {
    setCurrentStep((prevStep) => prevStep - 1);
  };


  const trueFalseOptions = [
    { value: true, label: "Yes" },
    { value: false, label: "No" }
  ]
  const threatOptions = [
    { value: "Yes", label: "Yes" },
    { value: "No", label: "No" }
  ]
  const futureRisk = [
    { value: "Low", label: "Low" },
    { value: "Medium", label: "Medium" },
    { value: "High", label: "High" }
  ]

  // Formik and Yup
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: reviewFormData,
    validationSchema: addServiceUserReview,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data UpdatedValues:", values);
        setIsSubmitButtonClicked(true);
        await postServiceUserReviewData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });


  const processPostRecords = (method, data) => {
    if (data?.status !== "Success") {
      setIsToastAddAppointment(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postServiceUserReviewData" &&
      data?.status === "Success"
    ) {
      setIsToastAddAppointment(true);
      toastSuccessMessages(toast.success, data?.message, "/individual-reviews", 500, 500);
      setIsSubmitButtonClicked(false);
    }
  };

  //   [
  //     "Community Support",
  //     "Education"
  // ]

  // [
  //     "Residential Supported Living",
  //     "Community Support"
  // ]
  const postServiceUserReviewData = catchAsync(async (req, res) => {
    const updatedValues = {
      ...values,
      commentBy: selectedUserId,

      actionPoints: actionPoints.map(row => ({
        action: row.action,
        achieved: row.achieved
      })),
      // supportAreas: supportAreaLabels,
      incidentLogged: incidentsPoint.map(row => ({
        date: row.date,
        incident: row.incident
      })),
      keyCheckByTeam: keyPoints.map(row => ({
        requirements: row.requirements,
        fullfil: row.fullfil
      })),
      riskAssesments: riskAssesmentsPoints.map(row => ({
        outcomes: row.outcomes,
        threat: row.threat,
        futureRisk: row.futureRisk,
      })),
      summaryOfPlan: summaryOfPlansPoints.map(row => ({
        plan: row.plan,
        comment: row.comment,
      })),
      requests: mySayRequestPoints.map(row => ({
        myRequests: row.myRequests,
        discussion: row.discussion,
      })),
      agreedActions: agreedActionsPoints.map(row => ({
        action: row.action,
        resonsiblePerson: row.resonsiblePerson,
        completedBy: row.completedBy,
      })),
      managementUseOnly: managementUseOnlyPoints.map(row => ({
        filedAway: row.filedAway,
        location: row.location,
        sign: row.sign,
      })),
      supportAreas: supportAreaCheckBoxes.map(row => ({
        values: row.values,
        checked: row.checked,
      })),

    };
    const response = await postRecords(UPDATE_SERVICE_USERS_REVIEW, updatedValues);
    console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postServiceUserReviewData", response);
  });



  // Actions Points
  const [actionPoints, setActionPoints] = useState(userReviewData?.actionPoints);
  const addActionPointsRow = () => {
    const newRow = {
      _id: actionPoints.length + 1,
      action: "",
      achieved: ""
    };
    setActionPoints([...actionPoints, newRow]);
  };

  const removeActionPointsRow = (id) => {
    const newRows = actionPoints.filter((row) => row._id !== id);
    setActionPoints(newRows);
  };

  const handleActionPointsInputChange = (id, field, value) => {
    const updatedRows = actionPoints.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setActionPoints(updatedRows);
  };

  // Any Incident/ABC
  const [incidentsPoint, setIncidentsPoint] = useState(userReviewData?.incidentLogged);
  const addIncidentsPoint = () => {
    const newRow = {
      _id: incidentsPoint.length + 1,
      date: "",
      incident: ""
    };
    setIncidentsPoint([...incidentsPoint, newRow]);
  };

  const removeIncidentsPoint = (id) => {
    const newRows = incidentsPoint.filter((row) => row._id !== id);
    setIncidentsPoint(newRows);
  };

  const handleIncidentsPointInputChange = (id, field, value) => {
    const updatedRows = incidentsPoint.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setIncidentsPoint(updatedRows);
  };

  // Key Points

  // const [editKeyPointsCheckBoxes, setEditKeyPointsCheckBoxes] = useState(userReviewData?.keyCheckByTeam)
  // console.log("🚀 ~ EditReview ~ editKeyPointsCheckBoxes:", editKeyPointsCheckBoxes)

  const [keyPoints, setKeyPoints] = useState(userReviewData?.keyCheckByTeam);
  // console.log("🚀 ~ EditReview ~ keyPoints:", keyPoints)

  const handleKeyPointsInputChange = (id, field, value) => {
    const updatedRows = keyPoints.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setKeyPoints(updatedRows);
  };

  // Risk Assessments
  const [riskAssesmentsPoints, setRiskAssesmentsPoints] = useState(userReviewData?.riskAssesments);
  const handleRiskAssesmentsPointsInputChange = (id, field, value) => {
    const updatedRows = riskAssesmentsPoints.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setRiskAssesmentsPoints(updatedRows);
  };

  // Summary of Plans
  const [summaryOfPlansPoints, setSummaryOfPlansPoints] = useState(userReviewData?.summaryOfPlan);
  const addSummaryOfPlansPoints = () => {
    const newRow = {
      id: summaryOfPlansPoints.length + 1,
      plan: "",
      comment: ""
    };
    setSummaryOfPlansPoints([...summaryOfPlansPoints, newRow]);
  };

  const removeSummaryOfPlansPoints = (id) => {
    const newRows = summaryOfPlansPoints.filter((row) => row.id !== id);
    setSummaryOfPlansPoints(newRows);
  };

  const handleSummaryOfPlansPointsInputChange = (id, field, value) => {
    const updatedRows = summaryOfPlansPoints.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setSummaryOfPlansPoints(updatedRows);
  };

  // My Say request
  const [mySayRequestPoints, setMySayRequestPoints] = useState(userReviewData?.requests);
  const addMySayRequestPoints = () => {
    const newRow = {
      _id: mySayRequestPoints.length + 1,
      myRequests: "",
      discussion: ""
    };
    setMySayRequestPoints([...mySayRequestPoints, newRow]);
  };

  const removeMySayRequestPoints = (id) => {
    const newRows = mySayRequestPoints.filter((row) => row._id !== id);
    setMySayRequestPoints(newRows);
  };

  const handleMySayRequestPointsInputChange = (id, field, value) => {
    const updatedRows = mySayRequestPoints.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setMySayRequestPoints(updatedRows);
  };

  // Agreed action
  const [agreedActionsPoints, setAgreedActionsPoints] = useState(userReviewData?.agreedActions);
  const addAgreedActionsPoints = () => {
    const newRow = {
      _id: agreedActionsPoints.length + 1,
      plan: "",
      comment: ""
    };
    setAgreedActionsPoints([...agreedActionsPoints, newRow]);
  };

  const removeAgreedActionsPoints = (id) => {
    const newRows = agreedActionsPoints.filter((row) => row._id !== id);
    setAgreedActionsPoints(newRows);
  };

  const handleAgreedActionsPointsInputChange = (id, field, value) => {
    const updatedRows = agreedActionsPoints.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setAgreedActionsPoints(updatedRows);
  };


  // Management Use Only
  const [managementUseOnlyPoints, setManagementUseOnlyPoints] = useState(userReviewData?.managementUseOnly);
  const addManagementUseOnlyPoints = () => {
    const newRow = {
      _id: managementUseOnlyPoints.length + 1,
      plan: "",
      comment: ""
    };
    setManagementUseOnlyPoints([...managementUseOnlyPoints, newRow]);
  };

  const removeManagementUseOnlyPoints = (id) => {
    const newRows = managementUseOnlyPoints.filter((row) => row._id !== id);
    setManagementUseOnlyPoints(newRows);
  };
  const handleManagementUseOnlyPointsInputChange = (id, field, value) => {
    const updatedRows = managementUseOnlyPoints.map((row) =>
      row._id === id ? { ...row, [field]: value } : row
    );
    setManagementUseOnlyPoints(updatedRows);
  };

  // const [editSpportAreaCheckBoxes, setEditSpportAreaCheckBoxes] = useState(userReviewData?.supportAreas)
  // Step 1 support area check boxes list
  const [supportAreaCheckBoxes, setSupportAreaCheckBoxes] = useState(userReviewData?.supportAreas);
  console.log("🚀 ~ EditReview ~ supportAreaCheckBoxes:", supportAreaCheckBoxes)

  useEffect(() => {
    const updatedSupportAreaCheckBoxes = supportAreaCheckBoxes.map((item, index) => ({
      id: index + 1,
      ...item
    }));
    setSupportAreaCheckBoxes(updatedSupportAreaCheckBoxes);
  }, []);

  const handleInputChange = (id, field, value) => {
    const updatedRows = supportAreaCheckBoxes.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setSupportAreaCheckBoxes(updatedRows);
  };


  return (

    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>Internal Reviews </small>
            {/* ------ {userDetails?._id} */}
          </>
        }
      >
        <div className="mainContent">
          {/* {
            supportAreaLabels.map(label => (<h1>{ label }</h1>))
          } */}
          <div className="reviewContent">
            <div className='formBlockContent reviewFormWizard'>
              <form onSubmit={handleSubmit}>
                {/* Step 1 */}
                {currentStep === 1 && (
                  <div className='wizardSteps step1'>
                    {/* Personal Detail  */}
                    <div className="personalDetail">
                      <header className="mainContentHeader">
                        <h3>Personal Detail</h3>
                      </header>
                      <div className="row">
                        <div className="col-sm-6 col-md-4">
                          <div className="form-group disabled">
                            <CustomSelect
                              id=""
                              name="commentBy"
                              onSelect={handleUserSelect}
                              defaultValue={userReviewData.commentBy._id}
                              filterBy=""
                              label="Review By"
                              isDisabled={true}
                              errorClass={isStaffId ? "validationError" : ""}
                              customError={isStaffId}
                              errorMessage={isStaffId ? errorMessage : "Please select review by"}
                            />
                          </div>
                        </div>



                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="personalDetails.locality"
                            value={values?.personalDetails?.locality}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Locality"
                            customError={
                              touched?.personalDetails?.locality &&
                              errors?.personalDetails?.locality
                            }
                            errorMessage={errors?.personalDetails?.locality}
                            erroClass={
                              touched?.personalDetails?.locality &&
                                errors?.personalDetails?.locality
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>

                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="personalDetails.liquidLogic"
                            value={values?.personalDetails?.liquidLogic}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Liquid Logic"
                            customError={
                              touched?.personalDetails?.liquidLogic &&
                              errors?.personalDetails?.liquidLogic
                            }
                            errorMessage={errors?.personalDetails?.liquidLogic}
                            erroClass={
                              touched?.personalDetails?.liquidLogic &&
                                errors?.personalDetails?.liquidLogic
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>

                        <div className="col-sm-6 col-md-4">

                          <SelectBox
                            name="personalDetails.dolsApplicable"
                            id="personalDetails.dolsApplicable"
                            value={values?.personalDetails?.dolsApplicable}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={trueFalseOptions}
                            labelText="DOLS Applicable"
                          />


                        </div>


                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="date"
                            id=""
                            name="personalDetails.dor"
                            value={values?.personalDetails?.dor}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Date of Review"
                            customError={
                              touched?.personalDetails?.dor &&
                              errors?.personalDetails?.dor
                            }
                            errorMessage={errors?.personalDetails?.dor}
                            erroClass={
                              touched?.personalDetails?.dor &&
                                errors?.personalDetails?.dor
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                      </div>
                    </div>
                    {/* MDT Details */}
                    <div className="mdtDetails">
                      <header className="mainContentHeader">
                        <h3>MDT Details</h3>
                      </header>
                      <div className="row">

                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="mdtDetails.nominatedIndividual"
                            value={values?.mdtDetails?.nominatedIndividual}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Nominated Individual"
                            customError={
                              touched?.mdtDetails?.nominatedIndividual &&
                              errors?.mdtDetails?.nominatedIndividual
                            }
                            errorMessage={errors?.mdtDetails?.nominatedIndividual}
                            erroClass={
                              touched?.mdtDetails?.nominatedIndividual &&
                                errors?.mdtDetails?.nominatedIndividual
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="mdtDetails.suManager"
                            value={values?.mdtDetails?.suManager}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Service Manager"
                            customError={
                              touched?.mdtDetails?.suManager &&
                              errors?.mdtDetails?.suManager
                            }
                            errorMessage={errors?.mdtDetails?.suManager}
                            erroClass={
                              touched?.mdtDetails?.suManager &&
                                errors?.mdtDetails?.suManager
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="mdtDetails.dptSuManager"
                            value={values?.mdtDetails?.dptSuManager}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Deputy Service Manager (s)"
                            customError={
                              touched?.mdtDetails?.dptSuManager &&
                              errors?.mdtDetails?.dptSuManager
                            }
                            errorMessage={errors?.mdtDetails?.dptSuManager}
                            erroClass={
                              touched?.mdtDetails?.dptSuManager &&
                                errors?.mdtDetails?.dptSuManager
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>



                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="mdtDetails.socWorker"
                            value={values?.mdtDetails?.socWorker}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Social Worker"
                            customError={
                              touched?.mdtDetails?.socWorker &&
                              errors?.mdtDetails?.socWorker
                            }
                            errorMessage={errors?.mdtDetails?.socWorker}
                            erroClass={
                              touched?.mdtDetails?.socWorker &&
                                errors?.mdtDetails?.socWorker
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>



                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="mdtDetails.family"
                            value={values?.mdtDetails?.family}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Family"
                            customError={
                              touched?.mdtDetails?.family &&
                              errors?.mdtDetails?.family
                            }
                            errorMessage={errors?.mdtDetails?.family}
                            erroClass={
                              touched?.mdtDetails?.family &&
                                errors?.mdtDetails?.family
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>



                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="mdtDetails.other"
                            value={values?.mdtDetails?.other}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Other"
                            customError={
                              touched?.mdtDetails?.other &&
                              errors?.mdtDetails?.other
                            }
                            errorMessage={errors?.mdtDetails?.other}
                            erroClass={
                              touched?.mdtDetails?.other &&
                                errors?.mdtDetails?.other
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                      </div>
                    </div>
                    {/* ICD 10 Diagnosis & any other */}
                    <div className="diagnosisOther">
                      <header className="mainContentHeader">
                        <h3>ICD 10 Diagnosis & any other</h3>
                      </header>
                      <div className="row">
                        <div className="col-sm-12">
                          <TextArea
                            id="icDiagnosisOther"
                            name="icDiagnosisOther"
                            value={values?.icDiagnosisOther}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText=""
                            rows="3"
                            customError={
                              touched?.icDiagnosisOther &&
                              errors?.icDiagnosisOther
                            }
                            errorMessage={errors?.icDiagnosisOther}
                            erroClass={
                              touched?.icDiagnosisOther &&
                                errors?.icDiagnosisOther
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                    {/* Please Tick Support Area(s) Applicable */}
                    <div className="supportArea">
                      <header className="mainContentHeader">
                        <h3>Please Tick Support Area(s) Applicable</h3>
                      </header>
                      <div className="row">
                        <div className="checkboxList">
                          {supportAreaCheckBoxes.map((row, index) => (
                            <>
                              <Form.Check
                                name={row?.id}
                                id={row?.id}
                                aria-label={row?.values}
                                values={row?.values}
                                label={row?.values}
                                checked={row?.checked}
                                onChange={(e) => handleInputChange(row?.id, 'checked', e.target.checked)}
                              />
                            </>
                          ))}

                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Step 2 */}
                {currentStep === 2 && (
                  <div className='wizardSteps step2'>

                    {/* Medication (Dose, frequency) */}
                    <div className="medicationDoseFrequency">
                      <header className="mainContentHeader">
                        <h3>Medication (Dose, frequency)</h3>
                      </header>
                      <div className="row">
                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="medications.regular"
                            value={values?.medications?.regular}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Regular"
                            customError={
                              touched?.medications?.regular &&
                              errors?.medications?.regular
                            }
                            errorMessage={errors?.medications?.regular}
                            erroClass={
                              touched?.medications?.regular &&
                                errors?.medications?.regular
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="medications.prn"
                            value={values?.medications?.prn}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="PRN"
                            customError={
                              touched?.medications?.prn &&
                              errors?.medications?.prn
                            }
                            errorMessage={errors?.medications?.prn}
                            erroClass={
                              touched?.medications?.prn &&
                                errors?.medications?.prn
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="medications.physicalHealth"
                            value={values?.medications?.physicalHealth}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Physical Health"
                            customError={
                              touched?.medications?.physicalHealth &&
                              errors?.medications?.physicalHealth
                            }
                            errorMessage={errors?.medications?.physicalHealth}
                            erroClass={
                              touched?.medications?.physicalHealth &&
                                errors?.medications?.physicalHealth
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>




                      </div>
                      <div className="actionsPoint">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              <tr>
                                <th>Action Points from previous meeting</th>
                                <th>Achieved?</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {actionPoints.map((row, id) => (
                                <tr>
                                  <td><Input
                                    type="text"
                                    id={`action_${row?._id}`}
                                    name={`action_${row?._id}`}
                                    value={row.action}
                                    onChange={(e) => handleActionPointsInputChange(row?._id, 'action', e.target.value)}
                                    // onChange={(e) => handleInputChange(row.id, 'actionPoints', e.target.value)}
                                    onBlur={handleBlur}
                                  /></td>
                                  <td>
                                    <SelectBox
                                      id={`achieved_${row?._id}`}
                                      name={`achieved_${row?._id}`}
                                      value={row.achieved}
                                      onChange={(e) => handleActionPointsInputChange(row?._id, 'achieved', e.target.value)}
                                      onBlur={handleBlur}
                                      options={trueFalseOptions}
                                    />

                                  </td>
                                  <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeActionPointsRow(row._id) }} /></td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addActionPointsRow} /></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                    {/* Deputy Feedback */}
                    <div className="deputyFeedback">
                      <header className="mainContentHeader">
                        <h3>Deputy Feedback</h3>
                      </header>
                      <div className="row">

                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="legalStatus.regular"
                            value={values?.legalStatus?.regular}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Legal Status"
                            customError={
                              touched?.legalStatus?.regular &&
                              errors?.legalStatus?.regular
                            }
                            errorMessage={errors?.legalStatus?.regular}
                            erroClass={
                              touched?.legalStatus?.regular &&
                                errors?.legalStatus?.regular
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="legalStatus.mood_mentalState"
                            value={values?.legalStatus?.mood_mentalState}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Mood & Mental State"
                            customError={
                              touched?.legalStatus?.mood_mentalState &&
                              errors?.legalStatus?.mood_mentalState
                            }
                            errorMessage={errors?.legalStatus?.mood_mentalState}
                            erroClass={
                              touched?.legalStatus?.mood_mentalState &&
                                errors?.legalStatus?.mood_mentalState
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="legalStatus.interactions"
                            value={values?.legalStatus?.interactions}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Interactions & Engagement"
                            customError={
                              touched?.legalStatus?.interactions &&
                              errors?.legalStatus?.interactions
                            }
                            errorMessage={errors?.legalStatus?.interactions}
                            erroClass={
                              touched?.legalStatus?.interactions &&
                                errors?.legalStatus?.interactions
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>



                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="legalStatus.diet"
                            value={values?.legalStatus?.diet}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Diet/ Fluid Intake"
                            customError={
                              touched?.legalStatus?.diet &&
                              errors?.legalStatus?.diet
                            }
                            errorMessage={errors?.legalStatus?.diet}
                            erroClass={
                              touched?.legalStatus?.diet &&
                                errors?.legalStatus?.diet
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>



                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="legalStatus.medConcordance"
                            value={values?.legalStatus?.medConcordance}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Medication Concordance"
                            customError={
                              touched?.legalStatus?.medConcordance &&
                              errors?.legalStatus?.medConcordance
                            }
                            errorMessage={errors?.legalStatus?.medConcordance}
                            erroClass={
                              touched?.legalStatus?.medConcordance &&
                                errors?.legalStatus?.medConcordance
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>



                        <div className="col-sm-6 col-md-4">
                          <Input
                            type="text"
                            id=""
                            name="legalStatus.sleep"
                            value={values?.legalStatus?.sleep}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Sleep"
                            customError={
                              touched?.legalStatus?.sleep &&
                              errors?.legalStatus?.sleep
                            }
                            errorMessage={errors?.legalStatus?.sleep}
                            erroClass={
                              touched?.legalStatus?.sleep &&
                                errors?.legalStatus?.sleep
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>


                      </div>
                    </div>
                    {/* Any Incident/ABC logged since last internal review */}
                    <div className="anyIncident">
                      <header className="mainContentHeader">
                        <h3>Any Incident/ABC logged since last internal review</h3>
                      </header>

                      <div className="incidentsPoint">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              <tr>
                                <th>Date</th>
                                <th>Incident/ABC</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>

                              {incidentsPoint.map((row, index) => (
                                <tr>
                                  <td>
                                    <Input
                                      type="date"
                                      id={`date_${row?._id}`}
                                      name={`date_${row?._id}`}
                                      value={moment(row.date).format('YYYY-MM-DD')}
                                      onChange={(e) => handleIncidentsPointInputChange(row?._id, 'date', e.target.value)}
                                    /></td>
                                  <td>
                                    <Input
                                      type="text"
                                      id={`incident_${row?._id}`}
                                      name={`incident_${row?._id}`}
                                      value={row.incident}
                                      onChange={(e) => handleIncidentsPointInputChange(row?._id, 'incident', e.target.value)}
                                    />
                                  </td>
                                  <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeIncidentsPoint(row?._id) }} /></td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addIncidentsPoint} /></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-sm-12">
                          <TextArea
                            id="comments"
                            name="comments"
                            value={values?.comments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Comments"
                            rows="3"
                            customError={
                              touched?.comments &&
                              errors?.comments
                            }
                            errorMessage={errors?.comments}
                            erroClass={
                              touched?.comments &&
                                errors?.comments
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {/* Step 3 */}
                {currentStep === 3 && (
                  <div className='wizardSteps step3'>

                    {/* Manager Feedback */}
                    <div className="managerFeedback">
                      <header className="mainContentHeader">
                        <h3>Manager Feedback</h3>
                      </header>
                      <div className="row">
                        <div className="col-sm-12">
                          <TextArea
                            id="managerFeedbackComments"
                            name="managerFeedbackComments"
                            value={values?.managerFeedbackComments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Comments"
                            rows="3"
                            customError={
                              touched?.managerFeedbackComments &&
                              errors?.managerFeedbackComments
                            }
                            errorMessage={errors?.managerFeedbackComments}
                            erroClass={
                              touched?.managerFeedbackComments &&
                                errors?.managerFeedbackComments
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                    {/* Key Checks by Team */}
                    <div className="keyChecksWrapper">
                      <header className="mainContentHeader">
                        <h3>Key Checks by Team</h3>
                      </header>
                      <div className="keyPoints">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              <tr>
                                <th>Requirement</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>

                              {keyPoints.map((row, index) => (
                                <tr key={row?._id}>
                                  <td>{row?.requirements}</td>
                                  <td>
                                    {/* <Form.Check aria-label="option 1" /> */}
                                    <Form.Check
                                      id={`fullfil_${row?._id}`}
                                      name={`fullfil_${row?._id}`}
                                      // aria-label={row.requirements}
                                      checked={row.fullfil}
                                      onChange={(e) => handleKeyPointsInputChange(row?._id, 'fullfil', e.target.checked)}
                                    />
                                    {/* {row?.fullfil} */}
                                  </td>
                                  {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeKeyPoints(row.id) }} /></td> */}
                                </tr>
                              ))}
                              <tr className='displayNone'>
                                <td colSpan={2}></td>
                              </tr>
                              {/* <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addKeyPoints} /></td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                    {/* Education Feedback */}
                    <div className="educationFeedback">
                      <header className="mainContentHeader">
                        <h3>Education Feedback</h3>
                      </header>
                      <div className="row">
                        <div className="col-sm-12">
                          <TextArea
                            id="educationFeedbackComments"
                            name="educationFeedbackComments"
                            value={values?.educationFeedbackComments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Comments"
                            rows="3"
                            customError={
                              touched?.educationFeedbackComments &&
                              errors?.educationFeedbackComments
                            }
                            errorMessage={errors?.educationFeedbackComments}
                            erroClass={
                              touched?.educationFeedbackComments &&
                                errors?.educationFeedbackComments
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 4 */}
                {currentStep === 4 && (
                  <div className='wizardSteps step4'>

                    {/* Engagement */}
                    <div className="engagement">
                      <header className="mainContentHeader">
                        <h3>Engagement</h3>
                      </header>
                      <div className="row">
                        <div className="col-sm-6">
                          <TextArea
                            id="engagement.activityOrSocial"
                            name="engagement.activityOrSocial"
                            value={values?.engagement?.activityOrSocial}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Activity / social engagement"
                            rows="3"
                            customError={
                              touched?.engagement?.activityOrSocial &&
                              errors?.engagement?.activityOrSocial
                            }
                            errorMessage={errors?.engagement?.activityOrSocial}
                            erroClass={
                              touched?.engagement?.activityOrSocial &&
                                errors?.engagement?.activityOrSocial
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                        <div className="col-sm-6">
                          <TextArea
                            id="engagement.engInTheraputicTimeTable"
                            name="engagement.engInTheraputicTimeTable"
                            value={values?.engagement?.engInTheraputicTimeTable}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Engagement in therapeutic timetable"
                            rows="3"
                            customError={
                              touched?.engagement?.engInTheraputicTimeTable &&
                              errors?.engagement?.engInTheraputicTimeTable
                            }
                            errorMessage={errors?.engagement?.engInTheraputicTimeTable}
                            erroClass={
                              touched?.engagement?.engInTheraputicTimeTable &&
                                errors?.engagement?.engInTheraputicTimeTable
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                    {/* Social Work Feedback */}
                    <div className="socialWorkFeedback">
                      <header className="mainContentHeader">
                        <h3>Social Work Feedback</h3>
                      </header>
                      <div className="row">
                        <div className="col-sm-6">
                          <TextArea
                            id="socialWorkFeedback.involvement"
                            name="socialWorkFeedback.involvement"
                            value={values?.socialWorkFeedback?.involvement}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Involvement"
                            rows="3"
                            customError={
                              touched?.socialWorkFeedback?.involvement &&
                              errors?.socialWorkFeedback?.involvement
                            }
                            errorMessage={errors?.socialWorkFeedback?.involvement}
                            erroClass={
                              touched?.socialWorkFeedback?.involvement &&
                                errors?.socialWorkFeedback?.involvement
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                        <div className="col-sm-6">
                          <TextArea
                            id="socialWorkFeedback.safeguarding"
                            name="socialWorkFeedback.safeguarding"
                            value={values?.socialWorkFeedback?.safeguarding}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Safeguarding"
                            rows="3"
                            customError={
                              touched?.socialWorkFeedback?.safeguarding &&
                              errors?.socialWorkFeedback?.safeguarding
                            }
                            errorMessage={errors?.socialWorkFeedback?.safeguarding}
                            erroClass={
                              touched?.socialWorkFeedback?.safeguarding &&
                                errors?.socialWorkFeedback?.safeguarding
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                    {/* Family Feedback */}
                    <div className="familyFeedback">

                      <div className="row">

                        <div className="col-sm-6">
                          <header className="mainContentHeader">
                            <h3>Family Feedback</h3>
                          </header>
                          <TextArea
                            id="familyFeedback.involvement"
                            name="familyFeedback.involvement"
                            value={values?.familyFeedback?.involvement}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Involvement"
                            rows="3"
                            customError={
                              touched?.familyFeedback?.involvement &&
                              errors?.familyFeedback?.involvement
                            }
                            errorMessage={errors?.familyFeedback?.involvement}
                            erroClass={
                              touched?.familyFeedback?.involvement &&
                                errors?.familyFeedback?.involvement
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                        <div className="col-sm-6">
                          <header className="mainContentHeader">
                            <h3>MDT Discussion/Comments</h3>
                          </header>
                          <TextArea
                            id="mdtDiscussions.safeguarding"
                            name="mdtDiscussions.safeguarding"
                            value={values?.mdtDiscussions?.safeguarding}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText="Safeguarding"
                            rows="3"
                            customError={
                              touched?.mdtDiscussions?.safeguarding &&
                              errors?.mdtDiscussions?.safeguarding
                            }
                            errorMessage={errors?.mdtDiscussions?.safeguarding}
                            erroClass={
                              touched?.mdtDiscussions?.safeguarding &&
                                errors?.mdtDiscussions?.safeguarding
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                    {/* Risk Assessment */}
                    <div className="riskAssementReview">
                      <header className="mainContentHeader">
                        <h3>Risk Assessment</h3>
                      </header>
                      <div className="riskAssesmentsPoints">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              {/* <tr>
                                <th colSpan={2} rowSpan={2} className='leftAlignText'>Adverse Outcomes</th>
                                <th colSpan={2}>Threat</th>
                                <th colSpan={3}>Future Risk</th>
                              </tr>
                              <tr>
                                <th>Yes</th>
                                <th>No</th>
                                <th>Low</th>
                                <th>Medium</th>
                                <th>High</th>
                              </tr> */}
                              <tr>
                                <th colSpan={2} className='leftAlignText'>Adverse Outcomes</th>
                                <th>Threat</th>
                                <th >Future Risk</th>
                              </tr>
                            </thead>
                            <tbody>

                              {riskAssesmentsPoints.map((row, index) => (
                                <tr>
                                  <td>{index + 1}</td>
                                  <td className='leftAlignText'>{row?.outcomes}</td>
                                  <td>
                                    <SelectBox
                                      name="riskAssesments.threat"
                                      id="riskAssesments.threat"
                                      value={row?.threat}
                                      onChange={(e) => handleRiskAssesmentsPointsInputChange(row?._id, 'threat', e.target.value)}
                                      onBlur={handleBlur}
                                      options={threatOptions}
                                    />
                                  </td>
                                  <td>
                                    <SelectBox
                                      name="riskAssesments.futureRisk"
                                      id="riskAssesments.futureRisk"
                                      value={row?.futureRisk}
                                      onChange={(e) => handleRiskAssesmentsPointsInputChange(row?._id, 'futureRisk', e.target.value)}
                                      onBlur={handleBlur}
                                      options={futureRisk}
                                    />
                                  </td>
                                  {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeRiskAssesmentsPoints(row.id) }} /></td> */}
                                </tr>
                              ))}
                              {/* <tr>
                                <td colSpan={7}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addRiskAssesmentsPoints} /></td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-sm-12">
                          <TextArea
                            id="riskAssesmentComments"
                            name="riskAssesmentComments"
                            value={values?.riskAssesmentComments}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText=""
                            rows="3"
                            customError={
                              touched?.riskAssesmentComments &&
                              errors?.riskAssesmentComments
                            }
                            errorMessage={errors?.riskAssesmentComments}
                            erroClass={
                              touched?.riskAssesmentComments &&
                                errors?.riskAssesmentComments
                                ? "validationError"
                                : ""
                            }

                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {/* Step 5 */}
                {currentStep === 5 && (
                  <div className='wizardSteps step5'>

                    {/* Summary of plan agreed based on risk and progress */}
                    <div className="summaryOfPlans">
                      <header className="mainContentHeader">
                        <h3>Summary of plan agreed based on risk and progress</h3>
                      </header>
                      {/* Summary of Plan Points */}
                      <div className="summaryOfPlansPoints">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              <tr>
                                <th>Action Points from previous meeting</th>
                                <th className='leftAlignText'>Achieved?</th>

                              </tr>
                            </thead>
                            <tbody>

                              {summaryOfPlansPoints.map((row, index) => (
                                <tr>
                                  <td>
                                    {row.plan}
                                  </td>

                                  <td><Input
                                    type="text"
                                    id={`comment_${row?._id}`}
                                    name={`comment_${row?._id}`}
                                    value={row.comment}
                                    onChange={(e) => handleSummaryOfPlansPointsInputChange(row?._id, 'comment', e.target.value)}
                                    onBlur={handleBlur}

                                  /></td>
                                  {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeSummaryOfPlansPoints(row.id) }} /></td> */}
                                </tr>
                              ))}
                              {/* <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addSummaryOfPlansPoints} /></td>
                              </tr> */}
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      {/* My Say Request */}
                      <div className="mySayRequestPoints">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              <tr>
                                <th>My Say Requests</th>
                                <th>Discussion/Agreement</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>

                              {mySayRequestPoints.map((row, index) => (
                                <tr>
                                  <td><Input
                                    type="text"
                                    id={`myRequests_${row?._id}`}
                                    name={`myRequests_${row?._id}`}
                                    value={row.myRequests}
                                    onChange={(e) => handleMySayRequestPointsInputChange(row?._id, 'myRequests', e.target.value)}
                                    onBlur={handleBlur}

                                  /></td>
                                  <td>
                                    <Input
                                      type="text"
                                      id={`discussion_${row?._id}`}
                                      name={`discussion_${row?._id}`}
                                      value={row.discussion}
                                      onChange={(e) => handleMySayRequestPointsInputChange(row?._id, 'discussion', e.target.value)}
                                      onBlur={handleBlur}

                                    />
                                  </td>
                                  <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeMySayRequestPoints(row._id) }} /></td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={2}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addMySayRequestPoints} /></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                    {/* Agreed Actions */}
                    <div className="agreedActionWrapper">
                      <header className="mainContentHeader">
                        <h3>Agreed Actions</h3>
                      </header>
                      <div className="agreedActionsPoints">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              <tr>
                                <th>Action</th>
                                <th>Responsible Person</th>
                                <th>To complete by</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                              {agreedActionsPoints.map((row, index) => (
                                <tr>
                                  <td><Input
                                    type="text"
                                    id={`action_${row?._id}`}
                                    name={`action_${row?._id}`}
                                    value={row.action}
                                    onChange={(e) => handleAgreedActionsPointsInputChange(row?._id, 'action', e.target.value)}
                                    onBlur={handleBlur}

                                  /></td>
                                  <td>
                                    <Input
                                      type="text"
                                      id={`resonsiblePerson_${row?._id}`}
                                      name={`resonsiblePerson_${row?._id}`}
                                      value={row.resonsiblePerson}
                                      onChange={(e) => handleAgreedActionsPointsInputChange(row?._id, 'resonsiblePerson', e.target.value)}
                                      onBlur={handleBlur}

                                    />
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      id={`completedBy_${row?._id}`}
                                      name={`completedBy_${row?._id}`}
                                      value={row.completedBy}
                                      onChange={(e) => handleAgreedActionsPointsInputChange(row?._id, 'completedBy', e.target.value)}
                                      onBlur={handleBlur}

                                    />
                                  </td>
                                  <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeAgreedActionsPoints(row.id) }} /></td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={3}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addAgreedActionsPoints} /></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                    {/* Management Use only */}
                    <div className="managementUseOnly">
                      <header className="mainContentHeader">
                        <h3>Management Use only</h3>
                      </header>
                      <div className="managementUseOnlyPoints">
                        <div className="table-responsive">
                          <Table striped bordered hover className='dynamicRows'>
                            <thead>
                              <tr>
                                <th>Filed away on</th>
                                <th>Location</th>
                                <th>Signed</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>

                              {managementUseOnlyPoints.map((row, index) => (
                                <tr>
                                  <td><Input
                                    type="text"
                                    id={`filedAway_${row?._id}`}
                                    name={`filedAway_${row?._id}`}
                                    value={row.filedAway}
                                    onChange={(e) => handleManagementUseOnlyPointsInputChange(row?._id, 'filedAway', e.target.value)}
                                    onBlur={handleBlur}

                                  /></td>
                                  <td>
                                    <Input
                                      type="text"
                                      id={`location_${row?._id}`}
                                      name={`location_${row?._id}`}
                                      value={row.location}
                                      onChange={(e) => handleManagementUseOnlyPointsInputChange(row?._id, 'location', e.target.value)}
                                      onBlur={handleBlur}

                                    />
                                  </td>
                                  <td>
                                    <Input
                                      type="text"
                                      id={`sign_${row?._id}`}
                                      name={`sign_${row?._id}`}
                                      value={row.sign}
                                      onChange={(e) => handleManagementUseOnlyPointsInputChange(row?._id, 'sign', e.target.value)}
                                      onBlur={handleBlur}

                                    />
                                  </td>
                                  <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeManagementUseOnlyPoints(row.id) }} /></td>
                                </tr>
                              ))}
                              <tr>
                                <td colSpan={3}></td>
                                <td><img className='addRow' src={eventAdd} alt="Add" onClick={addManagementUseOnlyPoints} /></td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>

                    </div>


                  </div>
                )}

                {/* Steps Button */}
                <div className="col-md-12">
                  <div className="formActionButtons">
                    {currentStep > 1 && (
                      <Button variant="outline-primary" size="sm" onClick={handlePrev}>
                        Previous
                      </Button>

                    )}

                    {currentStep < 5 ? (
                      <Button variant="primary" size="sm" onClick={handleNext}>
                        Next
                      </Button>
                    ) : (
                      null
                    )}
                    <br />
                    {currentStep === 5 ? (

                      isSubmitButtonClicked ? (
                        <Button className="btn-sm" variant="primary" disabled>
                          Loading...
                        </Button>
                      ) : (
                        <Button
                          className="btn-sm"
                          variant="primary"
                          type="submit"
                        >
                          Submit
                        </Button>
                      )
                    ) : null}
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>
      </Layout >
      {isToastAddAppointment && <ToastContainer />}
    </>
  );
}

// Map state from Redux store to component props
const mapStateToProps = (state) => ({
  selectedUserId: state.selectStaffMember.memberData, // Map selectedUserId from Redux store state
});

// Map dispatch actions to component props
const mapDispatchToProps = (dispatch) => ({
  handleUserSelect: (userId) => dispatch(selectStaffMember(userId)), // Dispatch selectStaffMember action
});

// Connect your component to Redux store
export default connect(mapStateToProps, mapDispatchToProps)(EditReview);