import React, { useState } from "react";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Sidenav from "../Sidenav";
const drawerWidth = 240;

const Layout = ({ children, pageTitleText }) => {
  const [pageTitle, setPageTitle] = useState(pageTitleText);

  return (
    <>
      <Box sx={{ display: "flex" }}>
        <Sidenav routePageTitle={pageTitle} />

        <Box
          component="main"
          className="mainWrapper"
          sx={{
            flexGrow: 1,
            p: 3,
            width: { sm: `calc(100% - ${drawerWidth}px)` },
          }}
        >
          <Toolbar />
          <div className="contentWrapper">{children}</div>
        </Box>
      </Box>
    </>
  );
};

export default Layout;
