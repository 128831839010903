import React from "react";
import useAuthorization from "./useAuthorization";
import { useAuth } from "./AuthContext";

const ProtectedComponent = ({ requiredPermissions, children }) => {
  const { userRoles } = useAuth();
  const isAuthorized = useAuthorization(userRoles, requiredPermissions);

  // console.log("🚀 ~ ProtectedComponent ~ userRoles:", userRoles);
  // console.log("🚀 ~ ProtectedComponent ~ isAuthorized:", isAuthorized);

  if (!isAuthorized) {
    return null; // or return a fallback UI
  }

  return <>{children}</>;
};

export default ProtectedComponent;