import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import loadingGif from "../../assets/images/icons/loadingGif.gif";
import eyeView from "../../assets/images/icons/eyeview.svg";
import eyeViewHide from "../../assets/images/icons/eye_password_see_view_icon.svg";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Input from "../forms-fields/Input";
import { ToastContainer, toast } from "react-toastify";
import ProgressComponent from "../general/ProgressComponent";
import "react-toastify/dist/ReactToastify.css";
import Select from "react-select";
import CustomSelect from "../forms-fields/CustomSelectStaffMembers";
import CustomSelectCheckbox from "../../components/forms-fields/CustomCheckBox";
import CustomSelectSupervisionTemplates from "../../components/forms-fields/CustomSelectSupervisionTemplates";
import camera from "../../assets/images/icons/camera.svg";
import {
  isSuccess,
  toastErrorMessages,
  toastSuccessMessages,
  catchAsync,
  getRecords,
  phoneRegExp,
  formateDateString,
  formateDate,
  calculateNextYearEndDate,
  calculateHolidayHours,
  convertDateFormat,
} from "../../helper/helper";
import {
  CustomOption,
  handleReload,
  calculateTimeDifference,
  handleSetIdFormikFormData,
  handleTimeChangeToNumeric,
  useShiftEffect,
  days,
  weeksOptions,
  getFormDataProps,
  GenerateScheduleFormData,
  handleFormUpdate,
  handleCheckboxChange,
  useCheckedEffect,
  uploadFile,
  staffDesignationOptions,
  vehicleTypeOptions,
  userInputFields,
  workLoadFields,
  sprStatus,
  sprResult,
  handleSelectChange,
  updateDesignationFields,
  updateStaffWorkLoad,
  passwordRegex,
} from "../../helper/helperJsx";
import {
  PUT_USERS_PROFILE_PICTURE_URL,
  POST_UPDATE_STAFF_MEMBER_DETAILS_URL,
  ADMIN_AUTH_TOKEN,
  BASE_URL,
} from "../../env";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";

function UserProfileUpdate({
  showModal,
  closeModal,
  userData,
  fetchAllRecords,
}) {
  const initialUserData = userData;
  const [userDataRecord, setUserDataRecord] = useState(initialUserData);
  const [isFileUploadedToast, setIsFileUploadedToast] = useState(false);
  const [isToastUpdateUserRecord, setIsToastUpdateUserRecord] = useState(false);
  const [isLoadingIcon, setIsLoadingIcon] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    BASE_URL + initialUserData?.user?.profile_picture_url
  );
  const [isDesignationManager, setIsDesignationManager] = useState(false);
  const [isDesignationSupervisor, setIsDesignationSupervisor] = useState(false);
  const [isHealthCheckedState, setIsHealthCheckedState] = useState(false);
  const [isUpdateFormSubmit, setIsUpdateFormSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);
  const [isDriverCheckedState, setIsDriverCheckedState] = useState(false);

  const staffValidationSchema = Yup.object({
    profile_picture_url: Yup.string(),
    name: Yup.string().required("Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    phoneNumber: Yup.string()
      .matches(phoneRegExp, "Contact is not valid")
      .required("Contact is required"),
    deviceId: Yup.string(),
    version: Yup.string(),
    role: Yup.string(),
    userModelName: Yup.string(),
    active: Yup.boolean(),
    is_verified: Yup.boolean(),
    approved: Yup.boolean(),
    signUpFromWeb: Yup.boolean(),
    fcm_tokens: Yup.object().shape({
      token: Yup.string(),
      deviceType: Yup.string(),
    }),
    location: Yup.object().shape({
      type: Yup.string(),
      coordinates: Yup.array().of(Yup.number()),
    }),
    covidCheck: Yup.boolean(),
    supervisorId: Yup.string(),
    managerId: Yup.string(),
    stafDob: Yup.date().required("Date of Birth is required"),
    staffWorkLoad: Yup.object().shape({
      isCurrentWrkLd: Yup.boolean(),
      startDate: Yup.date().required("Start Date is required"),
      endDate: Yup.date(),
      holidayEntitlement: Yup.object().shape({
        numberOfDays: Yup.number(),
        numberOfHours: Yup.number(),
        numberOfWeeks: Yup.number(),
      }),
      holidayAlwnNoOfDys: Yup.number(),
      holidayAlwnNoOfHours: Yup.number(),
      holidaysAvailed: Yup.number(),
      holidaysRemaining: Yup.number(),
      carriedOverHours: Yup.number(),
    }),
    contractedHours: Yup.object().shape({
      contractedHours: Yup.number().required("Contract Hours is required"),
      totalShiftHoursWeek: Yup.number(),
      noOfShifts: Yup.number(),
    }),
    supervision: Yup.object().shape({
      supervisionName: Yup.string(),
      sprDueDate: Yup.date().required("Supervision Due Date is required"),
      sprStatus: Yup.string(),
      sprResult: Yup.string(),
      templateTitleId: Yup.string().required(
        "Supervision Template is required"
      ),
    }),
    holidays: Yup.array(),
    complianceDocuments: Yup.array(),
    niNumber: Yup.string(),
    kin: Yup.string(),
    clients: Yup.array(),
    driverFields: Yup.object().shape({
      isDriver: Yup.boolean(),
      vehicleType: Yup.string(),
    }),
  });
  console.log(userDataRecord);
  const updateUserFormData = useFormik({
    initialValues: {
      userId: userDataRecord?.user?._id || initialUserData?._id,
      name: userDataRecord?.user?.name,
      email: userDataRecord?.user?.email,
      password: userDataRecord?.user?.password,
      phoneNumber: userDataRecord?.user?.phoneNumber,
      deviceId: userDataRecord?.user?.deviceId,
      version: userDataRecord?.user?.version,
      role: userDataRecord?.user?.role,
      userModelName: "staffMembers",
      active: userDataRecord?.user?.active,
      is_verified: userDataRecord?.user?.is_verified,
      approved: userDataRecord?.user?.approved,
      signUpFromWeb: true,
      fcm_tokens: {
        token: "",
        deviceType: userDataRecord?.fcm_tokens?.deviceType,
      },
      location: {
        type: "Point",
        coordinates: [74.28911289869138, 31.624848273644956],
      },
      supervisorId: userDataRecord?.supervisorId?._id,
      managerId: userDataRecord?.managerId?._id,
      stafDob: formateDate(userDataRecord?.stafDob, "yyyy-mm-dd"),
      staffOnLeave: userDataRecord?.staffOnLeave,
      staffDesignation: userDataRecord?.staffDesignation,
      staffWorkLoad: {
        isCurrentWrkLd:
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.isCurrentWrkLd,
        startDate: formateDate(
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.startDate,
          "yyyy-mm-dd"
        ),
        endDate: formateDate(
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.endDate,
          "yyyy-mm-dd"
        ),
        holidayEntitlement: {
          numberOfDays:
            userDataRecord?.staffWorkLoads &&
            userDataRecord?.staffWorkLoads[0]?.holidayEntitlement?.numberOfDays,
          numberOfHours:
            userDataRecord?.staffWorkLoads &&
            userDataRecord?.staffWorkLoads[0]?.holidayEntitlement
              ?.numberOfHours,
          numberOfWeeks:
            userDataRecord?.staffWorkLoads &&
            userDataRecord?.staffWorkLoads[0]?.holidayEntitlement
              ?.numberOfWeeks,
        },
        holidayAlwnNoOfDys:
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.holidayAlwnNoOfDys,
        holidayAlwnNoOfHours:
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.holidayAlwnNoOfHours,
        holidaysAvailed:
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.holidaysAvailed,
        holidaysRemaining:
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.holidaysRemaining,
        carriedOverHours:
          userDataRecord?.staffWorkLoads &&
          userDataRecord?.staffWorkLoads[0]?.carriedOverHours,
      },
      contractedHours: {
        contractedHours: userDataRecord?.contractedHours?.contractedHours,
        totalShiftHoursWeek:
          userDataRecord?.contractedHours?.totalShiftHoursWeek,
        noOfShifts: userDataRecord?.contractedHours?.noOfShifts,
        perHourRate: userDataRecord?.contractedHours?.perHourRate || 0,
        overNightSleepRate: userDataRecord?.contractedHours?.overNightSleepRate || 0,
        enhancedRate: userDataRecord?.contractedHours?.enhancedRate || 0,
        trainingRate: userDataRecord?.contractedHours?.trainingRate || 0,
        overtimeRate: userDataRecord?.contractedHours?.overtimeRate || 0,
      },
      supervision: {
        supervisionName: userDataRecord?.supervision?.supervisionName,
        sprDueDate: formateDate(
          userDataRecord?.supervision?.sprDueDate,
          "yyyy-mm-dd"
        ),
        sprStatus: userDataRecord?.supervision?.sprStatus,
        sprResult: userDataRecord?.supervision?.sprResult,
        templateTitleId: userDataRecord?.supervision?.templateTitleId,
      },
      holidays: [],
      complianceDocuments: [],
      niNumber: userDataRecord?.niNumber,
      kin: userDataRecord?.kin,
      clients: [],
      covidCheck: userDataRecord?.covidCheck,
      driverFields: {
        isDriver: userDataRecord?.driverFields?.isDriver,
        vehicleType: userDataRecord?.driverFields?.vehicleType,
      },
    },
    validationSchema: staffValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setIsUpdateFormSubmit(true);
        await updateUserRecordFunc(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error . Please try again.",
          "",
          1000,
          1000
        );
        setIsUpdateFormSubmit(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = updateUserFormData;

  const updateUserRecordFunc = catchAsync(async (req, res) => {
    const response = await getRecords(
      POST_UPDATE_STAFF_MEMBER_DETAILS_URL,
      req
    );
    processFetchRecords("update", response);
  });

  const handleFileChangeUploadDoc = async (e) => {
    try {
      setIsLoadingIcon(true);
      const selectedUploadDocFile = e.target?.files[0];
      const userId = userData?._id || userData?._id;
      await uploadFile({
        isUpload: false,
        updateFieldName: "userId",
        updateFieldId: userId,
        fileName: "file",
        file: selectedUploadDocFile,
        apiUrl: PUT_USERS_PROFILE_PICTURE_URL,
        successCallback: (result) => {
          handleFileChange(e);
          setIsLoadingIcon(false);
          fetchAllRecords();
          setIsFileUploadedToast(true);
          toastSuccessMessages(toast?.success, result?.message, "", 1000, 1000);
        },
      });
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastUpdateUserRecord(true);
      setIsUpdateFormSubmit(false);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "update" && data?.status === isSuccess) {
      fetchAllRecords();
      setIsUpdateFormSubmit(false);
      setIsToastUpdateUserRecord(true);
      closeModal();
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target?.files?.[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        setProfilePicture(event?.target?.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleManagerSelect = (userId) => {
    handleSelectChange(setFieldValue, "managerId", userId);
  };

  const handleSupervisorSelect = (userId) => {
    handleSelectChange(setFieldValue, "supervisorId", userId);
  };

  const handleSupervisionTemplateSelect = (templateId) => {
    handleSelectChange(
      setFieldValue,
      "supervision.templateTitleId",
      templateId
    );
  };

  useEffect(() => {
    updateStaffWorkLoad(
      setValues,
      values,
      setIsDesignationManager,
      setIsDesignationSupervisor
    );
  }, [values?.staffWorkLoad?.startDate]);

  useEffect(() => {
    if (values?.staffDesignation && values?.staffDesignation !== null) {
      if (values?.staffDesignation === "manager") {
        setIsDesignationManager(false);
        setIsDesignationSupervisor(true);
      } else if (values?.staffDesignation === "supervisor") {
        setIsDesignationSupervisor(false);
        setIsDesignationManager(true);
      } else {
        setIsDesignationManager(true);
        setIsDesignationSupervisor(true);
      }
    }
  }, [values?.staffDesignation, userDataRecord]);

  const handleIsDriverCheckBox = (e) => {
    handleCheckboxChange(e, setFieldValue, "driverFields.isDriver");
  };

  useEffect(() => {
    if (values?.covidCheck) {
      setIsHealthCheckedState(true);
    } else {
      setIsHealthCheckedState(false);
    }
  }, [values?.covidCheck]);

  useEffect(() => {
    if (values?.driverFields?.isDriver) {
      setFieldValue(
        "driverFields.vehicleType",
        userDataRecord?.driverFields?.vehicleType
      );
      setIsDriverCheckedState(true);
    } else {
      setFieldValue("driverFields.vehicleType", "");
      setIsDriverCheckedState(false);
    }
  }, [values?.driverFields?.isDriver]);
  return (
    <>
      <Modal
        show={showModal}
        onHide={closeModal}
        className="ftcModalPopup editProfileModalPopup suProfileEdit"
      >
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="closeButton modalCloseButton modal-closer-icon-container">
              <span onClick={closeModal}>X</span>
            </div>
            <div className="editBlockContent mt-4">
              <div className="text-center">
                <label htmlFor="profilePicture" className="upload-icon">
                  {isLoadingIcon ? (
                    <div className="avatarAction">
                      <img src={loadingGif} alt={"Loading..."} />
                    </div>
                  ) : (
                    <div className="avatarAction">
                      <input
                        type="file"
                        id="profilePicture"
                        name="profilePic"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(e) => {
                          handleFileChangeUploadDoc(e);
                        }}
                      />
                      <img
                        src={profilePicture}
                        alt={initialUserData?.user?.name}
                        style={{ cursor: "pointer" }}
                      />
                      <span className="uploadImage">
                        <img src={camera} alt="Camera" />
                      </span>
                    </div>
                  )}
                </label>
                <h3 className="userTitle">
                  {initialUserData?.user?.name}
                  <a
                    href={`mailto:${initialUserData?.user?.email}`}
                    className="greyColor"
                  >
                    <small>{initialUserData?.user?.email}</small>
                  </a>
                </h3>
              </div>
              <div className="formBlockContent row">
                {userInputFields?.map((field, index) => (
                  <div key={index} className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <Input
                        type={field?.type}
                        name={field?.name}
                        value={values[field?.name]}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText={field?.labelText}
                        customError={
                          touched[field?.name] && errors[field?.name]
                        }
                        errorMessage={errors[field?.name]}
                        errorClass={
                          touched[field?.name] && errors[field?.name]
                            ? "validationError"
                            : ""
                        }
                        requiredStar={field?.required}
                      />

                    </div>
                  </div>
                ))}
                {!isDriverCheckedState ? (
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <CustomSelectCheckbox
                        name="isDriver"
                        tick={
                          values?.driverFields?.isDriver &&
                          values?.driverFields?.isDriver
                        }
                        value={isDriverCheckedState}
                        onChange={handleIsDriverCheckBox}
                        onBlur={handleBlur}
                        labelText={"Driving Status"}
                      />
                    </div>
                  </div>
                ) : null}
                {isDriverCheckedState ? (
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <div
                        className={`customSelectBox ${touched?.driverFields?.vehicleType &&
                          errors?.driverFields?.vehicleType
                          ? "validationError"
                          : ""
                          }`}
                      >
                        <>
                          <Select
                            className="basic-multi-select cursor-pointer"
                            classNamePrefix="select"
                            value={vehicleTypeOptions?.find(
                              (option) =>
                                option?.value ===
                                values?.driverFields?.vehicleType
                            )}
                            options={vehicleTypeOptions}
                            onChange={(selectedOptions) => {
                              setFieldValue(
                                "driverFields.vehicleType",
                                selectedOptions?.value
                              );
                              console.log(selectedOptions);
                            }}
                            onBlur={handleBlur}
                            isSearchable
                            getOptionLabel={(selectedOptions) =>
                              selectedOptions?.label
                            }
                            getOptionValue={(selectedOptions) =>
                              selectedOptions?.value
                            }
                            components={{ Option: CustomOption }}
                          />
                          <label className="floatingLabel">
                            Vehicle Type
                            <span className="text-danger">*</span>
                          </label>
                          {touched?.driverFields?.vehicleType &&
                            errors?.driverFields?.vehicleType && (
                              <div className="text-danger">
                                {errors?.driverFields?.vehicleType}
                              </div>
                            )}
                        </>
                      </div>
                    </div>
                  </div>
                ) : null}
                {isDriverCheckedState ? (
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <CustomSelectCheckbox
                        name="isDriver"
                        tick={
                          values?.driverFields?.isDriver &&
                          values?.driverFields?.isDriver
                        }
                        value={isDriverCheckedState}
                        onChange={handleIsDriverCheckBox}
                        onBlur={handleBlur}
                        labelText={"Driving Status"}
                      />
                    </div>
                  </div>
                ) : null}
                <p>
                  <strong>Staff Workload</strong>
                </p>
                <div className="col-sm-12 col-md-6">
                  <Input
                    type="date"
                    name="startDate"
                    value={values?.staffWorkLoad?.startDate}
                    onChange={(e) => {
                      setFieldValue("staffWorkLoad.startDate", e.target?.value);
                    }}
                    onBlur={handleBlur}
                    labelText="Start Date"
                    customError={
                      touched?.staffWorkLoad?.startDate &&
                      errors?.staffWorkLoad?.startDate
                    }
                    errorMessage={errors?.staffWorkLoad?.startDate}
                    errorClass={
                      touched?.staffWorkLoad?.startDate &&
                        errors?.staffWorkLoad?.startDate
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                </div>
                {workLoadFields?.map((field, index) => (
                  <div key={index} className="col-sm-12 col-md-6">
                    <Input
                      type={field?.type}
                      name={field?.name}
                      value={
                        field?.type === "date"
                          ? values?.staffWorkLoad?.[field?.name]
                          : values?.staffWorkLoad?.holidayEntitlement?.[
                          field?.name
                          ]
                      }
                      onChange={(e) => {
                        setFieldValue("staffWorkLoad.endDate", e.target?.value);
                      }}
                      customError={
                        touched?.staffWorkLoad?.[field?.name] &&
                        errors?.staffWorkLoad?.[field?.name]
                      }
                      errorMessage={errors?.staffWorkLoad?.[field?.name]}
                      errorClass={
                        touched?.staffWorkLoad?.[field?.name] &&
                          errors?.staffWorkLoad?.[field?.name]
                          ? "validationError"
                          : ""
                      }
                      requiredStar={field?.required}
                      labelText={field?.labelText}
                    // disabledStatus={field?.disabled}
                    />
                  </div>
                ))}
                <div className="col-md-12">
                  <p>
                    <strong>Contract Hours</strong>
                  </p>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="col">
                    <Input
                      type="number"
                      name="contractedHours"
                      value={values?.contractedHours?.contractedHours}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.contractedHours",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Contract Hours"
                      customError={
                        touched?.contractedHours?.contractedHours &&
                        errors?.contractedHours?.contractedHours
                      }
                      errorMessage={errors?.contractedHours?.contractedHours}
                      errorClass={
                        touched?.contractedHours?.contractedHours &&
                          errors?.contractedHours?.contractedHours
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <ProtectedComponent requiredPermissions={[permissions.PAYROLL]}>
                  <div className="col-sm-6 col-md-4">
                    <div className="col">
                      <Input
                        type="number"
                        name="perHourRate"
                        value={values?.contractedHours?.perHourRate}
                        onChange={(e) => {
                          setFieldValue(
                            "contractedHours.perHourRate",
                            e.target?.value
                          );
                        }}
                        onBlur={handleBlur}
                        labelText="Contracted Hours Rate"
                        customError={
                          touched?.contractedHours?.perHourRate &&
                          errors?.contractedHours?.perHourRate
                        }
                        errorMessage={errors?.contractedHours?.perHourRate}
                        errorClass={
                          touched?.contractedHours?.perHourRate &&
                            errors?.contractedHours?.perHourRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <div className="col">
                      <Input
                        type="number"
                        name="overNightSleepRate"
                        value={values?.contractedHours?.overNightSleepRate}
                        onChange={(e) => {
                          setFieldValue(
                            "contractedHours.overNightSleepRate",
                            e.target?.value
                          );
                        }}
                        onBlur={handleBlur}
                        labelText="Over Night Sleep Rate"
                        customError={
                          touched?.contractedHours?.overNightSleepRate &&
                          errors?.contractedHours?.overNightSleepRate
                        }
                        errorMessage={errors?.contractedHours?.overNightSleepRate}
                        errorClass={
                          touched?.contractedHours?.overNightSleepRate &&
                            errors?.contractedHours?.overNightSleepRate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="enhancedRate"
                      value={values?.contractedHours?.enhancedRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.enhancedRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Enhanced Rate"
                      customError={
                        touched?.contractedHours?.enhancedRate &&
                        errors?.contractedHours?.enhancedRate
                      }
                      errorMessage={errors?.contractedHours?.enhancedRate}
                      errorClass={
                        touched?.contractedHours?.enhancedRate &&
                          errors?.contractedHours?.enhancedRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="trainingRate"
                      value={values?.contractedHours?.trainingRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.trainingRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Training Rate"
                      customError={
                        touched?.contractedHours?.trainingRate &&
                        errors?.contractedHours?.trainingRate
                      }
                      errorMessage={errors?.contractedHours?.trainingRate}
                      errorClass={
                        touched?.contractedHours?.trainingRate &&
                          errors?.contractedHours?.trainingRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="overtimeRate"
                      value={values?.contractedHours?.overtimeRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.overtimeRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Overtime Rate"
                      customError={
                        touched?.contractedHours?.overtimeRate &&
                        errors?.contractedHours?.overtimeRate
                      }
                      errorMessage={errors?.contractedHours?.overtimeRate}
                      errorClass={
                        touched?.contractedHours?.overtimeRate &&
                          errors?.contractedHours?.overtimeRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </ProtectedComponent>
                <p>
                  <strong>Staff Designation</strong>
                </p>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <div
                      className={`customSelectBox ${errors?.staffDesignation ? "validationError" : ""
                        }`}
                    >
                      <Select
                        name="staffDesignation"
                        value={staffDesignationOptions?.find(
                          (option) => option?.value === values?.staffDesignation
                        )}
                        className="basic-multi-select cursor-pointer"
                        classNamePrefix="select"
                        options={staffDesignationOptions}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            "staffDesignation",
                            selectedOption?.value
                          );
                        }}
                        isSearchable
                        getOptionLabel={(option) => option?.label}
                        getOptionValue={(option) => option?.value}
                        components={{ Option: CustomOption }}
                      />
                      <label className="floatingLabel">
                        Staff Designation
                        <span className="text-danger">*</span>
                      </label>
                      {errors?.staffDesignation && (
                        <div className="text-danger">
                          {errors?.staffDesignation}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {isDesignationSupervisor && (
                  <div className="col-sm-12 col-md-6">
                    <p>
                      <strong>Supervisor</strong>
                    </p>
                    <div className="form-group">
                      <CustomSelect
                        value={values?.supervisorId}
                        defaultValue={values?.supervisorId}
                        filterBy="supervisor"
                        onSelect={handleSupervisorSelect}
                        customError={
                          touched?.supervisorId && errors?.supervisorId
                        }
                        errorMessage={errors?.supervisorId}
                        errorClass={
                          touched?.supervisorId && errors?.supervisorId
                            ? "validationError"
                            : ""
                        }
                        label="Supervisor"
                        required={true}
                      />
                    </div>
                  </div>
                )}
                {isDesignationManager && (
                  <div className="col-sm-12 col-md-6">
                    <p>
                      <strong>Manager</strong>
                    </p>
                    <div className="form-group">
                      <CustomSelect
                        value={values?.managerId}
                        onSelect={handleManagerSelect}
                        defaultValue={values?.managerId}
                        filterBy="manager"
                        label="Manager"
                        customError={touched?.managerId && errors?.managerId}
                        errorMessage={errors?.managerId}
                        errorClass={
                          touched?.managerId && errors?.managerId
                            ? "validationError"
                            : ""
                        }
                        required={true}
                      />
                    </div>
                  </div>
                )}
                <p>
                  <strong>Supervision</strong>
                </p>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <CustomSelectSupervisionTemplates
                      onSelect={handleSupervisionTemplateSelect}
                      value={values?.supervision?.templateTitleId}
                      defaultValue={values?.supervision?.templateTitleId}
                      filterBy=""
                      label="Supervision Template"
                      customError={
                        touched?.supervision?.templateTitleId &&
                        errors?.supervision?.templateTitleId
                      }
                      errorMessage={errors?.supervision?.templateTitleId}
                      errorClass={
                        touched?.supervision?.templateTitleId &&
                          errors?.supervision?.templateTitleId
                          ? "validationError"
                          : ""
                      }
                      required={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <Input
                    type="date"
                    name="sprDueDate"
                    value={values?.supervision?.sprDueDate}
                    onChange={(e) => {
                      setFieldValue("supervision.sprDueDate", e.target?.value);
                    }}
                    onBlur={handleBlur}
                    labelText="Supervision Due Date"
                    customError={
                      touched?.supervision?.sprDueDate &&
                      errors?.supervision?.sprDueDate
                    }
                    errorMessage={errors?.supervision?.sprDueDate}
                    errorClass={
                      touched?.supervision?.sprDueDate &&
                        errors?.supervision?.sprDueDate
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                </div>
                <div className="col-sm-12 col-md-4">
                  <p>
                    <strong>COVID-19</strong>
                  </p>
                  <div className="form-group">
                    <CustomSelectCheckbox
                      name="covidCheck"
                      tick={values?.covidCheck && values?.covidCheck}
                      value={values?.covidCheck}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelText={
                        isHealthCheckedState ? "Status Checked" : "Check"
                      }
                      customError={touched?.covidCheck && errors?.covidCheck}
                      errorMessage={errors?.covidCheck}
                      errorClass={
                        touched?.covidCheck && errors?.covidCheck
                          ? "validationError"
                          : ""
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isUpdateFormSubmit ? (
              <ProgressComponent />
            ) : (
              <>
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={closeModal}
                >
                  Close
                </Button>
                <Button className="btn-sm" variant="primary" type="submit">
                  Update
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {isFileUploadedToast && <ToastContainer />}
      {isToastUpdateUserRecord && <ToastContainer />}
    </>
  );
}

export default UserProfileUpdate;
