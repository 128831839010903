import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import TextArea from "../../components/forms-fields/TextArea";
import Input from "../../components/forms-fields/Input";
import { updateHealthIssueValidation } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDATE_HEALTHISSUES_URL } from "../../env";
import {
  updateRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import HealthCategories from "./HealthCategories";

function EditHealthIssue({ handleShowHide, userDataId, userHealthIssueData, handleSuccessSubmission }) {
  // console.log("🚀 ~ file: EditHealthIssue.jsx:20 ~ EditHealthIssue ~ userHealthIssueData:", userHealthIssueData)
  const [updateHealthIssue, setUpdateHealthIssue] = useState(userHealthIssueData);
  console.log("🚀 ~ file: EditHealthIssue.jsx:22 ~ EditHealthIssue ~ updateHealthIssue:", updateHealthIssue)
  const updateApiQuery = {
    issueId: updateHealthIssue?.issueId,
    category: updateHealthIssue?.categoryId,
    complaint: updateHealthIssue?.complaint,
    healthNote: updateHealthIssue?.healthNote,
    // category: updateHealthIssue?.category,
  }
  // console.log("🚀 ~ file: AddHealthIssues.jsx:26 ~ AddHealthIssues ~ updateApiQuery:", updateApiQuery)
  const [userId, setUserId] = useState(userDataId);
  const handleShowHideModal = () => {
    handleShowHide((prevShow) => !prevShow);
  };
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [isSuFormData, setIsSuFormData] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  const [suFormData, setSuFormData] = useState({
    issueId: updateHealthIssue?.issueId,
    category: updateHealthIssue?.categoryId,
    subcategory: updateHealthIssue?.categoryId,
    healthNote: updateHealthIssue?.healthNote,
    complaint: updateHealthIssue?.complaint,
    status: updateHealthIssue?.status,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: updateHealthIssueValidation,
    onSubmit: async (values) => {
      try {

        console.log("Form Data:", values);
        setIsSuFormData(false);
        setIsSubmitButtonClicked(true);
        await updateHealthIssueFormData(values);
        handleSuccessSubmission()
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processFetchRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToast(true);
      handleShowHideModal();
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      
    } else if (
      method === "updateHealthIssueFormData" &&
      data?.status === isSuccess
    ) {
      setIsToast(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const updateHealthIssueFormData = catchAsync(async (req, res) => {
    // console.log({ req });
    const response = await updateRecords(UPDATE_HEALTHISSUES_URL, req);
    processFetchRecords("updateHealthIssueFormData", response);
  });

  const handleUserSelect = async (userId) => {
    setSelectedUserId(userId);
    setIsSuFormData(true);

    setSuFormData((prevFormData) => ({
      ...prevFormData,
      category: userId,
    }));
  };

  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    handleChange(e);
    handleBlur(e);
  };
  const handleSubCatValidationStatus = (status) => {
    // console.log("Sub Cat Validation Status (Parent):", status);
    setParentSubCatValidationStatus(status);
  };

  useEffect(() => {
    // console.log("Parent Sub Cat Validation Status 01:", parentSubCatValidationStatus);
  }, [parentSubCatValidationStatus]);






  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit}>
          <div className="formBlockContent">
            <div className="row">

              <div className="col-md-12">
                <Input
                  type="text"
                  id=""
                  name="healthNote"
                  value={values?.healthNote}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Health Note"
                  customError={touched?.healthNote && errors?.healthNote}
                  errorMessage={errors?.healthNote}
                  erroClass={
                    touched?.healthNote && errors?.healthNote
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>

              <div className="col-md-12">
                <TextArea
                  id="complaint"
                  name="complaint"
                  value={values?.complaint}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Complaint"
                  rows="3"
                  className="mb-3"
                  customError={errors.complaint && touched.complaint}
                  errorMessage={errors.complaint}
                  erroClass={errors.complaint && touched.complaint}
                  requiredStar={true}
                />
              </div>
            </div>
          </div>

          <div className="formActionButtons">
            <span
              onClick={handleShowHideModal}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={handleShowHideModal}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToast && <ToastContainer />}
    </>
  );
}

export default EditHealthIssue;