import React from "react";
import Layout from "../../../../../../components/layouts/Layout";
import AddCareNotes from "../../../AddCareNotes";

function AddGeneralOthers() {
  return (
    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Add General Other note</h3>
              <AddCareNotes noteType="GeneralOther" parentListNoteUrl="/care-plan/general-notes" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AddGeneralOthers