import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/forms-fields/Input";
import Select from "react-select";
import ProgressComponent from "../../../components/general/ProgressComponent";
import {
  postRecords,
  isSuccess,
  catchAsync,
  formateDate,
  toastErrorMessages,
  toastSuccessMessages,
} from "../../../helper/helper";
import {
  ADMIN_ID,
  PUT_UPDATE_ROTA_TEMPLATE_URL,
  POST_NEW_ROTA_TEMPLATE_URL,
} from "../../../env";

function PublishRotaTemplate({
  handleShowModel,
  handleCloseModel,
  tempData,
  fetchAllRecords,
  scheduleTitle,
}) {
  const currentDate = moment().endOf("day").valueOf();
  const [isLoading, setIsLoading] = useState(true);
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [isPublish, setIsPublish] = useState(true);
  const [isFuturePublish, setIsFuturePublish] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const [startDateState, setStartDateState] = useState(
    currentDate && formateDate(currentDate, "yyyy-mm-dd")
  );

  const validationSchema = Yup?.object().shape({
    rotaTemplateTitle: Yup?.string()?.required("Title is required."),
    startDate: Yup.number()
      .required("Start date is required")
      .test(
        "isValidDate",
        "Start date is required",
        (value) => value !== undefined && value !== 0
      ),
    templateWeeks: Yup?.number()
      .required("No of weeks is required.")
      .oneOf([2, 4], "Please select either 2 weeks or 4 weeks."),
  });

  const updateExistingRotaTemplate = useFormik({
    initialValues: {
      tempId: "",
      rotaTemplateTitle: "",
      templateWeeks: "",
      status: "",
      startDate: "",
      lastModifiedBy: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitClicked(true);
        if (isPublish) {
          await handleUpdateRotaTemp(values);
        } else {
          await handleAddRotaTemp(values);
        }
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          1000,
          1000
        );
        setIsFormSubmitClicked(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = updateExistingRotaTemplate;

  const handleUpdateRotaTemp = catchAsync(async (req, res) => {
    setIsFormSubmitClicked(true);
    const updatedDoc = {
      ...req,
      status: "active",
      startDate: currentDate,
      lastModifiedBy: ADMIN_ID,
    };
    const response = await postRecords(
      PUT_UPDATE_ROTA_TEMPLATE_URL,
      updatedDoc
    );
    await fetchAllRecords();
    setIsToast(true);
    toastSuccessMessages(toast?.success, response?.message, "", 500, 500);
    setTimeout(() => {
      handleCloseModel();
    }, 500);
  });

  const handleAddRotaTemp = catchAsync(async (req, res) => {
    setIsFormSubmitClicked(true);
    const updatedDoc = {
      ...req,
      status: "future",
      addedby: ADMIN_ID,
    };
    const response = await postRecords(POST_NEW_ROTA_TEMPLATE_URL, updatedDoc);
    await fetchAllRecords();
    setIsToast(true);
    toastSuccessMessages(toast?.success, response?.message, "", 500, 500);
    setTimeout(() => {
      handleCloseModel();
    }, 500);
  });

  const handleDateChangeToNumeric = (e) => {
    const dateValue = e.target?.value;
    const dateObject = new Date(dateValue);
    setFieldValue("startDate", dateObject.getTime());
    setStartDateState(dateValue);
  };

  const handlePublish = () => {
    setIsPublish(true);
    setIsFuturePublish(false);
  };

  const handleFuturePublish = () => {
    setIsFuturePublish(true);
    setIsPublish(false);
  };

  useEffect(() => {
    if (tempData?.status === "active") {
      setIsPublish(false);
      setIsFuturePublish(true);
    }
  }, [tempData?.status]);

  const handleSetValues = async (template) => {
    await setValues({
      tempId: template?._id,
      rotaTemplateTitle: template?.rotaTemplateTitle,
      templateWeeks: template?.templateWeeks,
      shiftLocation: template?.shiftLocation,
      startDate: currentDate,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (tempData) {
      handleSetValues(tempData);
    }
  }, [tempData]);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{scheduleTitle}</Modal.Title>
          </Modal.Header>
          <form method="post" onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row mt-1">
                {tempData?.status !== "active" && (
                  <div className="col-sm-4">
                    <label className="radioContainer mt-2">
                      <input
                        type="radio"
                        className="radioBoxSize"
                        name="active"
                        checked={isPublish}
                        onChange={() => {
                          handlePublish();
                        }}
                      />{" "}
                      <span className="labelText radioLabel">Publish Now</span>
                      <span className="radioMark"></span>
                    </label>
                  </div>
                )}
                <div className="col-sm-4">
                  <label className="radioContainer mt-2">
                    <input
                      type="radio"
                      className="radioBoxSize"
                      name="shiftTime"
                      checked={isFuturePublish}
                      onChange={() => {
                        handleFuturePublish();
                      }}
                    />{" "}
                    <span className="labelText radioLabel">Future Publish</span>
                    <span className="radioMark"></span>
                  </label>
                </div>
                <div className="mt-4 mb-2">
                  {isPublish && (
                    <p className="publishScheduleText mt-2">
                      Publish your template right now
                    </p>
                  )}
                  {isFuturePublish && (
                    <div className="col-sm-12 mt-2">
                      <div className="form-group futureTempInput">
                        <Input
                          type="date"
                          name="startDate"
                          value={startDateState}
                          onChange={handleDateChangeToNumeric}
                          omBlurFunc={handleBlur}
                          labelText="Select Publish Date"
                          customError={touched?.startDate && errors?.startDate}
                          errorMessage={errors?.startDate}
                          errorClass={
                            touched?.startDate && errors?.startDate
                              ? "validationError"
                              : ""
                          }
                          requiredStar={true}
                        />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isFormSubmitClicked ? (
                <ProgressComponent />
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={handleCloseModel}
                  >
                    Cancel
                  </Button>
                  <Button className="btn-sm" type="submit" variant="primary">
                    Publish
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isToast && <ToastContainer />}
    </>
  );
}

export default PublishRotaTemplate;
