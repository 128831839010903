import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import StaffRecordsGroup from "./StaffRecordsGroup";
import StaffRecordsIndividual from "./StaffRecordsIndividual";

function StaffRecords() {
  // const searchParams = new URLSearchParams(location.search);
  // const userID = searchParams.get("userId");
  const location = useLocation();

  const userData = location?.state && location?.state?.userId;

  return (
    <Layout pageTitleText="Staff Records">
      {userData ? (
        <StaffRecordsIndividual getUserRecord={userData} />
      ) : (
        <StaffRecordsGroup />
      )}
    </Layout>
  );
}

export default StaffRecords;
