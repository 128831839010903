import React from "react";
import buttonLoader from "../../assets/images/animation/buttonLoader.svg";
function ButtonLoading() {
  return (
    <>
      <img
        className="loadingButtonIcon"
        src={buttonLoader}
        alt="Loading Image"
      />
    </>
  );
}

export default ButtonLoading;
