import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import { BASE_URL, GET_ALL_BRADFORD_INCIDENTS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  toastErrorMessages,
  catchAsync,
  filterColumns,
  filterArrayBySearchTerm,
} from "../../helper/helper";

function BradfordScoreGroup() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState();
  const [filteredRows, setFilteredRows] = useState();
  const [isToastBradFordScore, setIsToastBradFordScore] = useState(false);
  const [selectedSortLevel, setSelectedSortLevel] = useState("");
  const [level1Records, setLevel1] = useState([]);
  const [level2Records, setLevel2] = useState([]);
  const [level3Records, setLevel3] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: {
        active: true,
        hldRqStatus: { $in: ["rejected", "pending"] },
        hldRequestType: { $in: ["absent", "sickLeave"] },
      },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const getLevelRecords = (threshold, staffMembers) => {
    const result = staffMembers?.filter(({ staffHolidayRequests }) => {
      const isHolidayRequestsArray = Array?.isArray(staffHolidayRequests);
      const matchingRequests = staffMembers?.filter(
        (request) => request?.thresholdLevel === threshold
      );
      return isHolidayRequestsArray && matchingRequests.length > 0;
    });
    return result;
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastBradFordScore(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const staffMembers = Array?.isArray(data?.data?.staffHolidayRequests)
        ? data?.data?.staffHolidayRequests
        : [];
      const level1Records = getLevelRecords("1", staffMembers);
      const level2Records = getLevelRecords("2", staffMembers);
      const level3Records = getLevelRecords("3", staffMembers);
      setFilteredRows(staffMembers);
      setUserData(staffMembers);
      setIsLoading(false);
      setLevel1(level1Records);
      setLevel2(level2Records);
      setLevel3(level3Records);
    }
  };

  console.log(userData);
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_BRADFORD_INCIDENTS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        userData,
        lowercaseSearch,
        (record) => record?.staffRequester[0]?.name?.toLowerCase()
      );
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };

  const handleRowClickModifiedBradfordScore = (row) => {
    navigate("/bradford-score", { state: { userRecord: row } });
  };

  const handleRowClickStaffMember = (e, row) => {
    navigate("/bradford-score", { state: { userRecord: row } });
  };

  const handleSortChange = (e) => {
    const selectedLevel = e.target?.value;
    setSelectedSortLevel(selectedLevel);
    const levelRecordsMap = {
      level1: level1Records,
      level2: level2Records,
      level3: level3Records,
    };
    setFilteredRows(levelRecordsMap[selectedLevel] || userData);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // useEffect(() => {
  //   console.log("Selected Sort Level:", selectedSortLevel);
  //   console.log("filtered:", filteredRows);
  // }, [selectedSortLevel]);

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        return (
          <span
            className="usersNameInfo"
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.staffRequester[0]?.profile_picture_url}`}
                alt={`${row?.staffRequester[0]?.name}`}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.staffRequester[0]?.name}`}</span>
            </span>
          </span>
        );
      },
    },
    {
      name: "Incidents",
      selector: (row) => {
        return (
          <span onClick={(e) => handleRowClickStaffMember(e, row)}>
            {row?.totalAbsences}
          </span>
        );
      },
      center: true,
    },
    {
      name: "Threshold Level",
      selector: (row) => {
        return (
          <span onClick={(e) => handleRowClickStaffMember(e, row)}>
            {`Level ${row?.thresholdLevel}`}
          </span>
        );
      },
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  return (
    <>
      <div className="mainContent">
        <div className="dataTableWrapper">
          <DataTable
            style={{ cursor: "cursor-pointer" }}
            className="maxWidthTable"
            columns={filteredColumns}
            data={filteredRows}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="600px"
            highlightOnHover
            actions={
              <>
                <select
                  className="headerSelect"
                  name="sortByLevel"
                  id="sortByLevel"
                  onChange={(e) => handleSortChange(e)}
                  value={selectedSortLevel}
                >
                  <option value="" disabled>
                    Sort By
                  </option>
                  <option value="all">Default</option>
                  <option value="level1">Level 1</option>
                  <option value="level2">Level 2</option>
                  <option value="level3">Level 3</option>
                </select>
              </>
            }
            responsive={true}
            subHeader={true}
            subHeaderComponent={
              <div className="searchContainer">
                <div className="searchTextBox">
                  <input
                    type="text"
                    placeholder="Type your search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            }
            subHeaderAlign="left"
            subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
            onRowClicked={handleRowClickModifiedBradfordScore}
          />
        </div>
      </div>
      {isToastBradFordScore && <ToastContainer />}
    </>
  );
}

export default BradfordScoreGroup;
