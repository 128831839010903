import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useFormik } from "formik";
import moment from "moment";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/forms-fields/Input";
import { setChatGroupTiming } from "../../../schemas";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
} from "../../../helper/helper";
import {
  ADMIN_ID,
  PUT_SET_UPDATE_GROUP_SCHEDULE_TIMING_URL,
} from "../../../env";

function SetGroupWorkingHours({
  handleShowModel,
  handleCloseModel,
  groupItem,
  fetchCompleteRecords,
}) {
  // console.log("🚀 ~ groupItem:", groupItem);
  // console.log("🚀 ~ fetchCompleteRecords:", fetchCompleteRecords);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastTimeSchedule, setIsToastTimeSchedule] = useState(false);

  const [aptMinutes, setAptMinutes] = useState("");
  const [durationHours, setDurationHours] = useState("");
  const [durationMinutes, setDurationMinutes] = useState("");

  const [suFormData, setSuFormData] = useState({
    groupId: groupItem?._id,
    groupWorkingScheduleTime: {
      startTime: moment(groupItem?.groupWorkingScheduleTime?.startTime).format('HH:mm'),
      endTime: moment(groupItem?.groupWorkingScheduleTime?.endTime).format('HH:mm'),
      totalWorkHours: groupItem?.groupWorkingScheduleTime?.totalWorkHours,
    },
    addedby: groupItem?.lastModifiedBy,
    lastModifiedBy: groupItem?.lastModifiedBy,
  });

  // console.log("Start Time:", moment(suFormData?.groupWorkingScheduleTime?.startTime).format('HH:mm'));
  // console.log("End Time:", moment(suFormData?.groupWorkingScheduleTime?.endTime).format('HH:mm'));

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: setChatGroupTiming,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postAddWorkingHoursFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  // console.log("🚀 ~ values:", values);

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastTimeSchedule(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleCloseModel();
      }, 1000)
    } else if (
      method === "postAddWorkingHoursFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastTimeSchedule(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleCloseModel();
      }, 1000)
    }
  };

  const postAddWorkingHoursFormData = catchAsync(async (req, res) => {

    const updatedValues = {
      ...values,
      groupWorkingScheduleTime: {
        startTime: moment(values.groupWorkingScheduleTime.startTime, 'HH:mm').valueOf(),
        endTime: moment(values.groupWorkingScheduleTime.endTime, 'HH:mm').valueOf(),
        totalWorkHours: durationHours,
      },
      addedby: ADMIN_ID,
      lastModifiedBy: ADMIN_ID,

    };
    console.log("🚀 ~ postAddWorkingHoursFormData ~ updatedValues:", updatedValues);

    const response = await postRecords(PUT_SET_UPDATE_GROUP_SCHEDULE_TIMING_URL, updatedValues);
    console.log("🚀 ~ postAddWorkingHoursFormData ~ response:", response);
    processPostRecords("postAddWorkingHoursFormData", response);
    setIsSubmitButtonClicked(false);
  });

  useEffect(() => {
    const startTime = moment(values.groupWorkingScheduleTime.startTime, 'HH:mm').toDate();
    const endTime = moment(values.groupWorkingScheduleTime.endTime, 'HH:mm').toDate();

    if ((startTime !== NaN) && (endTime !== NaN)) {
      const durationInMilliseconds = endTime - startTime;
      // console.log("🚀 ~ file: AddAppointments.jsx:107 ~ useEffect ~ durationInMilliseconds:",  )

      const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const durationInMinutes = durationInMilliseconds / (1000 * 60);
      setAptMinutes(durationInMinutes);
      setDurationHours(hours);
      setDurationMinutes(minutes);
    }
    else {
      setDurationHours(0);
      setDurationMinutes(0);
      setAptMinutes(0);
    }
  }, [values.groupWorkingScheduleTime.startTime, values.groupWorkingScheduleTime.endTime]);

  return (
    <>
      <Modal
        show={handleShowModel}
        onHide={handleCloseModel}
        className="ftcModalPopup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Set Working Hours</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row mt-4">

              <div className="col-md-6">
                <Input
                  type="time"
                  id="groupWorkingScheduleTime.startTime"
                  name="groupWorkingScheduleTime.startTime"
                  value={values.groupWorkingScheduleTime.startTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Start Time"
                  marginBottom={true}
                  customError={touched?.groupWorkingScheduleTime?.startTime && errors?.groupWorkingScheduleTime?.startTime}
                  errorMessage={errors?.groupWorkingScheduleTime?.startTime}
                  erroClass={
                    touched?.groupWorkingScheduleTime?.startTime && errors?.groupWorkingScheduleTime?.startTime
                      ? "validationError"
                      : ""
                  }
                />
              </div>
              <div className="col-md-6">
                <Input
                  type="time"
                  id="groupWorkingScheduleTime.endTime"
                  name="groupWorkingScheduleTime.endTime"
                  value={values.groupWorkingScheduleTime.endTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="End Time"
                  marginBottom={true}
                  customError={touched?.groupWorkingScheduleTime?.endTime && errors?.groupWorkingScheduleTime?.endTime}
                  errorMessage={errors?.groupWorkingScheduleTime?.endTime}
                  erroClass={
                    touched?.groupWorkingScheduleTime?.endTime && errors?.groupWorkingScheduleTime?.endTime
                      ? "validationError"
                      : ""
                  }
                />
              </div>
              <div className="col-md-12">
                <p>
                  <strong><small>Duration {durationHours} hours and {durationMinutes} minutes</small></strong>
                </p>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-sm"
              variant="outline-primary"
              onClick={handleCloseModel}
            >
              Cancel
            </Button>
            {isSubmitButtonClicked ? (
              <Button
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </Modal.Footer>
        </form>
        {isToastTimeSchedule && <ToastContainer />}
      </Modal>

    </>
  );
}

export default SetGroupWorkingHours;
