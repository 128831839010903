// AuthContext.jsx
import React, { createContext, useState, useEffect, useContext } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [userRoles, setUserRoles] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const storedRoles = localStorage.getItem('staffRoles');
    if (storedRoles) {
      try {
        const roles = JSON.parse(storedRoles);
        setUserRoles(roles);
      } catch (e) {
        console.error('Failed to parse roles from local storage', e);
        setUserRoles([]);
      }
    }
    setLoading(false);
  }, []);

  return (
    <AuthContext.Provider value={{ userRoles, loading, setUserRoles }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);
