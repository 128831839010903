import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import eyeView from "../../assets/images/icons/eyeview.svg";
import eyeViewHide from "../../assets/images/icons/eye_password_see_view_icon.svg";
import Select from "react-select";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import CustomSelectSupervisionTemplates from "../../components/forms-fields/CustomSelectSupervisionTemplates";
import CustomSelectCheckbox from "../../components/forms-fields/CustomCheckBox";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";

import {
  POST_STAFF_RECORD_URL,
  POST_USER_PROFILE_PICTURE_URL,
  ADMIN_AUTH_TOKEN,
} from "../../env";
import {
  CustomOption,
  handleReload,
  calculateTimeDifference,
  handleSetIdFormikFormData,
  handleTimeChangeToNumeric,
  useShiftEffect,
  days,
  weeksOptions,
  getFormDataProps,
  GenerateScheduleFormData,
  handleFormUpdate,
  handleCheckboxChange,
  useCheckedEffect,
  uploadFile,
  staffDesignationOptions,
  vehicleTypeOptions,
  userInputFields,
  workLoadFields,
  sprStatus,
  sprResult,
  handleSelectChange,
  updateDesignationFields,
  updateStaffWorkLoad,
  passwordRegex,
  staffValidationSchema,
} from "../../helper/helperJsx";
import {
  postRecords,
  isSuccess,
  catchAsync,
  calculateHolidayHours,
  calculateNextYearEndDate,
  toastSuccessMessages,
  toastErrorMessages,
  updateFormFields,
  phoneRegExp,
  MAX_FILE_SIZE,
} from "../../helper/helper";

function AddNewStaff() {
  const navigate = useNavigate();
  const [isDesignationManager, setIsDesignationManager] = useState(false);
  const [isDesignationSupervisor, setIsDesignationSupervisor] = useState(false);
  const [isStaffSingUpToast, setIsStaffSingUpToast] = useState(false);
  const [isHealthCheckedState, setIsHealthCheckedState] = useState(false);
  const [isDriverCheckedState, setIsDriverCheckedState] = useState(false);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isShowPassword, setIsShowPassword] = useState(false);

  const registerStaffMemberFormData = useFormik({
    initialValues: {
      profile_picture_url: "/uploads/dp/default.png",
      name: "",
      email: "",
      password: "",
      phoneNumber: "",
      deviceId: "",
      version: "0",
      role: "staffmember",
      userModelName: "staffMembers",
      active: true,
      is_verified: false,
      approved: false,
      signUpFromWeb: true,
      fcm_tokens: {
        token: "",
        deviceType: "android",
      },
      location: {
        type: "Point",
        coordinates: [74.28911289869139, 31.624848273644957],
      },
      covidCheck: false,
      supervisorId: "",
      managerId: "",
      stafDob: "",
      staffOnLeave: false,
      staffDesignation: "staffmember",
      staffWorkLoad: {
        isCurrentWrkLd: true,
        startDate: "2024-12-18",
        endDate: "2024-12-31",
        holidayEntitlement: {
          numberOfDays: 13,
          numberOfHours: 312,
          numberOfWeeks: 1,
        },
        holidayAlwnNoOfDys: 13,
        holidayAlwnNoOfHours: 312,
        holidaysAvailed: 0,
        holidaysRemaining: 13,
        carriedOverHours: 0,
      },
      contractedHours: {
        contractedHours: 0,
        totalShiftHoursWeek: 0,
        noOfShifts: 0,
        perHourRate: 0,
        overNightSleepRate: 0,
        enhancedRate: 0,
        trainingRate: 0,
        overtimeRate: 0,
      },
      supervision: {
        supervisionName: "default",
        sprDueDate: "2023-12-18",
        sprStatus: "assigned",
        sprResult: "passed",
        templateTitleId: "",
      },
      holidays: [],
      complianceDocuments: [],
      niNumber: "",
      kin: "",
      clients: [],
      driverFields: {
        isDriver: false,
        vehicleType: "auto",
      },
    },
    validationSchema: staffValidationSchema,
    onSubmit: async (values, { resetForm, setSubmitting }) => {
      try {
        setIsFormSubmit(true);
        await addNewStaffMember(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          500,
          500
        );
        setIsFormSubmit(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
  } = registerStaffMemberFormData;

  const vehicleTypeOptions = [
    { value: "auto", label: "Automatic" },
    { value: "manual", label: "Manual" },
  ];

  const addNewStaffMember = catchAsync(async (req, res) => {
    const response = await postRecords(POST_STAFF_RECORD_URL, req);
    processFetchRecords("signUpStaff", response);
  });

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsStaffSingUpToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
      setIsFormSubmit(false);
    } else if (method === "signUpStaff" && data?.status === isSuccess) {
      navigate("/staff-records");
      setIsStaffSingUpToast(true);
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
  };

  const handleManagerSelect = (userId) => {
    handleSelectChange(setFieldValue, "managerId", userId);
  };

  const handleSupervisorSelect = (userId) => {
    handleSelectChange(setFieldValue, "supervisorId", userId);
  };

  const handleSupervisionTemplateSelect = (templateId) => {
    handleSelectChange(
      setFieldValue,
      "supervision.templateTitleId",
      templateId
    );
  };

  const handleIsDriverCheckBox = (e) => {
    handleCheckboxChange(e, setFieldValue, "driverFields.isDriver");
  };

  const handleFileChangeUploadDoc = async (e) => {
    try {
      const selectedUploadDocFile = e.target?.files[0];
      await uploadFile({
        isUpload: true,
        updateFieldName: "",
        updateFieldId: "",
        fileName: "file",
        file: selectedUploadDocFile,
        apiUrl: POST_USER_PROFILE_PICTURE_URL,
        successCallback: (result) => {
          handleAddPath(result);
        },
      });
    } catch (error) {
      console.error("Error during file upload:", error);
    }
  };

  const handleAddPath = (filePath) => {
    handleFormUpdate(setFieldValue, "profile_picture_url", filePath);
  };

  useEffect(() => {
    updateStaffWorkLoad(
      setValues,
      values,
      setIsDesignationManager,
      setIsDesignationSupervisor
    );
  }, [values?.staffWorkLoad?.startDate]);

  useEffect(() => {
    updateDesignationFields(
      setValues,
      values,
      setIsDesignationManager,
      setIsDesignationSupervisor
    );
  }, [values?.staffDesignation]);

  useEffect(() => {
    if (values?.covidCheck) {
      setIsHealthCheckedState(true);
    } else {
      setIsHealthCheckedState(false);
    }
  }, [values?.covidCheck]);

  useEffect(() => {
    if (values?.driverFields?.isDriver) {
      setFieldValue("driverFields.vehicleType", "auto");
      setIsDriverCheckedState(true);
    } else {
      setIsDriverCheckedState(false);
      setFieldValue("driverFields.vehicleType", "");
    }
  }, [values?.driverFields?.isDriver]);

  console.log(values);
  return (
    <>
      <Layout pageTitleText="Staff Records">
        <form method="post" onSubmit={handleSubmit}>
          <div className="incidents consentCapacityAddNew">
            <div className="mainContent">
              <div className="mainContentText">
                <h3>Add New Staff</h3>
                <div className="formBlockContent row">
                  <p>
                    <strong> Personal Detail</strong>
                  </p>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <Input
                        type="file"
                        name={"profile_picture_url"}
                        accept={"image/*"}
                        onChange={(e) => handleFileChangeUploadDoc(e)}
                        labelText={"Choose Picture"}
                        className="form-control uploadProfileImage"
                      />
                    </div>
                  </div>
                  {userInputFields?.map((field, index) => (
                    <div key={index} className="col-sm-12 col-md-6">
                      <div className="form-group">
                        {field?.name === "password" ? (
                          <span>
                            <Input
                              type={isShowPassword ? "text" : field?.type}
                              name={field?.name}
                              value={values[field?.name]}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              labelText={field?.labelText}
                              customError={
                                touched[field?.name] && errors[field?.name]
                              }
                              errorMessage={errors[field?.name]}
                              errorClass={
                                touched[field?.name] && errors[field?.name]
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={field?.required}
                            />
                            <img
                              className="passwordShowEye cursor-pointer"
                              src={isShowPassword ? eyeView : eyeViewHide}
                              alt="isShowPassword"
                              onClick={() => setIsShowPassword(!isShowPassword)}
                            />
                          </span>
                        ) : (
                          <Input
                            type={field?.type}
                            name={field?.name}
                            value={values[field?.name]}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            labelText={field?.labelText}
                            customError={
                              touched[field?.name] && errors[field?.name]
                            }
                            errorMessage={errors[field?.name]}
                            errorClass={
                              touched[field?.name] && errors[field?.name]
                                ? "validationError"
                                : ""
                            }
                            requiredStar={field?.required}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <CustomSelectCheckbox
                        name="isDriver"
                        value={isDriverCheckedState}
                        onChange={handleIsDriverCheckBox}
                        onBlur={handleBlur}
                        labelText={"Driving Status"}
                      />
                    </div>
                  </div>
                  {isDriverCheckedState ? (
                    <div className="col-sm-12 col-md-6">
                      <div className="form-group">
                        <div
                          className={`customSelectBox ${touched?.driverFields?.vehicleType &&
                            errors?.driverFields?.vehicleType
                            ? "validationError"
                            : ""
                            }`}
                        >
                          <>
                            <Select
                              className="basic-multi-select cursor-pointer"
                              classNamePrefix="select"
                              value={vehicleTypeOptions?.filter(
                                (option) =>
                                  option?.value ===
                                  values?.driverFields?.vehicleType
                              )}
                              options={vehicleTypeOptions}
                              onChange={(selectedOptions) => {
                                setFieldValue(
                                  "driverFields.vehicleType",
                                  selectedOptions?.value
                                );
                              }}
                              onBlur={handleBlur}
                              isSearchable
                              getOptionLabel={(selectedOptions) =>
                                selectedOptions?.label
                              }
                              getOptionValue={(selectedOptions) =>
                                selectedOptions?.value
                              }
                              components={{ Option: CustomOption }}
                            />
                            <label className="floatingLabel">
                              Vehicle Type
                              <span className="text-danger">*</span>
                            </label>
                            {touched?.driverFields?.vehicleType &&
                              errors?.driverFields?.vehicleType && (
                                <div className="text-danger">
                                  {errors?.driverFields?.vehicleType}
                                </div>
                              )}
                          </>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <p>
                    <strong> Staff Workload</strong>
                  </p>
                  <div className="col-sm-12 col-md-6">
                    <Input
                      type="date"
                      name="startDate"
                      value={values?.staffWorkLoad?.startDate}
                      onChange={(e) => {
                        setFieldValue(
                          "staffWorkLoad.startDate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Start Date"
                      customError={
                        touched?.staffWorkLoad?.startDate &&
                        errors?.staffWorkLoad?.startDate
                      }
                      errorMessage={errors?.staffWorkLoad?.startDate}
                      errorClass={
                        touched?.staffWorkLoad?.startDate &&
                          errors?.staffWorkLoad?.startDate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                  {workLoadFields?.map((field, index) => (
                    <div key={index} className="col-sm-12 col-md-6">
                      <Input
                        type={field?.type}
                        name={field?.name}
                        value={
                          field?.type === "date"
                            ? values?.staffWorkLoad?.[field?.name]
                            : values?.staffWorkLoad?.holidayEntitlement?.[
                            field?.name
                            ]
                        }
                        customError={
                          touched?.staffWorkLoad?.[field?.name] &&
                          errors?.staffWorkLoad?.[field?.name]
                        }
                        errorMessage={errors?.staffWorkLoad?.[field?.name]}
                        errorClass={
                          touched?.staffWorkLoad?.[field?.name] &&
                            errors?.staffWorkLoad?.[field?.name]
                            ? "validationError"
                            : ""
                        }
                        requiredStar={field?.required}
                        labelText={field?.labelText}
                      // disabledStatus={field?.disabled}
                      />
                    </div>
                  ))}
                  <p>
                    <strong>Contract</strong>
                  </p>
                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="contractedHours"
                      value={values?.contractedHours?.contractedHours}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.contractedHours",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Contract Hours"
                      customError={
                        touched?.contractedHours?.contractedHours &&
                        errors?.contractedHours?.contractedHours
                      }
                      errorMessage={errors?.contractedHours?.contractedHours}
                      errorClass={
                        touched?.contractedHours?.contractedHours &&
                          errors?.contractedHours?.contractedHours
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                  <ProtectedComponent requiredPermissions={[permissions.PAYROLL]}>
                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="perHourRate"
                      value={values?.contractedHours?.perHourRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.perHourRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Contracted Hours Rate"
                      customError={
                        touched?.contractedHours?.perHourRate &&
                        errors?.contractedHours?.perHourRate
                      }
                      errorMessage={errors?.contractedHours?.perHourRate}
                      errorClass={
                        touched?.contractedHours?.perHourRate &&
                          errors?.contractedHours?.perHourRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="overNightSleepRate"
                      value={values?.contractedHours?.overNightSleepRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.overNightSleepRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Over Night Sleep Rate"
                      customError={
                        touched?.contractedHours?.overNightSleepRate &&
                        errors?.contractedHours?.overNightSleepRate
                      }
                      errorMessage={errors?.contractedHours?.overNightSleepRate}
                      errorClass={
                        touched?.contractedHours?.overNightSleepRate &&
                          errors?.contractedHours?.overNightSleepRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="enhancedRate"
                      value={values?.contractedHours?.enhancedRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.enhancedRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Enhanced Rate"
                      customError={
                        touched?.contractedHours?.enhancedRate &&
                        errors?.contractedHours?.enhancedRate
                      }
                      errorMessage={errors?.contractedHours?.enhancedRate}
                      errorClass={
                        touched?.contractedHours?.enhancedRate &&
                          errors?.contractedHours?.enhancedRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="trainingRate"
                      value={values?.contractedHours?.trainingRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.trainingRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Training Rate"
                      customError={
                        touched?.contractedHours?.trainingRate &&
                        errors?.contractedHours?.trainingRate
                      }
                      errorMessage={errors?.contractedHours?.trainingRate}
                      errorClass={
                        touched?.contractedHours?.trainingRate &&
                          errors?.contractedHours?.trainingRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>

                  <div className="col-sm-6 col-md-4">
                    <Input
                      type="number"
                      name="overtimeRate"
                      value={values?.contractedHours?.overtimeRate}
                      onChange={(e) => {
                        setFieldValue(
                          "contractedHours.overtimeRate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Overtime Rate"
                      customError={
                        touched?.contractedHours?.overtimeRate &&
                        errors?.contractedHours?.overtimeRate
                      }
                      errorMessage={errors?.contractedHours?.overtimeRate}
                      errorClass={
                        touched?.contractedHours?.overtimeRate &&
                          errors?.contractedHours?.overtimeRate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                  </ProtectedComponent>
                  <p>
                    <strong>Staff Designation</strong>
                  </p>
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <div
                        className={`customSelectBox ${errors?.staffDesignation ? "validationError" : ""
                          }`}
                      >
                        <Select
                          name="staffDesignation"
                          value={staffDesignationOptions?.find(
                            (option) =>
                              option?.value === values?.staffDesignation
                          )}
                          className="basic-multi-select cursor-pointer"
                          classNamePrefix="select"
                          options={staffDesignationOptions}
                          onChange={(selectedOption) => {
                            setFieldValue(
                              "staffDesignation",
                              selectedOption?.value
                            );
                          }}
                          isSearchable
                          getOptionLabel={(option) => option?.label}
                          getOptionValue={(option) => option?.value}
                          components={{ Option: CustomOption }}
                        />
                        <label className="floatingLabel">
                          Staff Designation
                          <span className="text-danger">*</span>
                        </label>
                        {errors?.staffDesignation && (
                          <div className="text-danger">
                            {errors?.staffDesignation}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  {isDesignationSupervisor && (
                    <div className="col-sm-12 col-md-6">
                      <div className="row">
                        <p>
                          <strong>Supervisor</strong>
                        </p>
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <CustomSelect
                              value={values?.supervisorId}
                              filterBy="supervisor"
                              onSelect={handleSupervisorSelect}
                              customError={
                                touched?.supervisorId && errors?.supervisorId
                              }
                              errorMessage={errors?.supervisorId}
                              errorClass={
                                touched?.supervisorId && errors?.supervisorId
                                  ? "validationError"
                                  : ""
                              }
                              label="Supervisor"
                              requiredStar={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  {isDesignationManager && (
                    <div className="col-sm-12 col-md-6">
                      <div className="row">
                        <p>
                          <strong>Manager</strong>
                        </p>
                        <div className="col-sm-12 col-md-12">
                          <div className="form-group">
                            <CustomSelect
                              value={values?.managerId}
                              onSelect={handleManagerSelect}
                              filterBy="manager"
                              label="Manager"
                              customError={
                                touched?.managerId && errors?.managerId
                              }
                              errorMessage={errors?.managerId}
                              errorClass={
                                touched?.managerId && errors?.managerId
                                  ? "validationError"
                                  : ""
                              }
                              requiredStar={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                  <p>
                    <strong>Supervision</strong>
                  </p>
                  <div className="col-sm-12 col-md-6">
                    <div className="form-group">
                      <CustomSelectSupervisionTemplates
                        onSelect={handleSupervisionTemplateSelect}
                        value={values?.supervision?.templateTitleId}
                        filterBy=""
                        label="Supervision Template"
                        customError={
                          touched?.supervision?.templateTitleId &&
                          errors?.supervision?.templateTitleId
                        }
                        errorMessage={errors?.supervision?.templateTitleId}
                        errorClass={
                          touched?.supervision?.templateTitleId &&
                            errors?.supervision?.templateTitleId
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="col-sm-12 col-md-6">
                    <Input
                      type="date"
                      name="sprDueDate"
                      value={values?.supervision?.sprDueDate}
                      onChange={(e) => {
                        setFieldValue(
                          "supervision.sprDueDate",
                          e.target?.value
                        );
                      }}
                      onBlur={handleBlur}
                      labelText="Supervision Due Date"
                      customError={
                        touched?.supervision?.sprDueDate &&
                        errors?.supervision?.sprDueDate
                      }
                      errorMessage={errors?.supervision?.sprDueDate}
                      errorClass={
                        touched?.supervision?.sprDueDate &&
                          errors?.supervision?.sprDueDate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                  <div className="col-sm-12 col-md-4">
                    <p>
                      <strong>COVID-19</strong>
                    </p>
                    <div className="form-group">
                      <CustomSelectCheckbox
                        name="covidCheck"
                        value={values?.covidCheck}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText={
                          isHealthCheckedState ? "Status Checked" : "Check"
                        }
                        customError={touched?.covidCheck && errors?.covidCheck}
                        errorMessage={errors?.covidCheck}
                        errorClass={
                          touched?.covidCheck && errors?.covidCheck
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="formActionButtons">
            {isFormSubmit ? (
              <Button type="button" className="btn-sm btn-primary" disabled>
                Loading...
              </Button>
            ) : (
              <>
                <Link
                  to="/staff-records"
                  className="btn btn-sm btn-outline-primary"
                >
                  Cancel
                </Link>
                <Button type="submit" className="btn-sm btn-primary">
                  Submit
                </Button>
              </>
            )}
          </div>
        </form>
      </Layout>
      {isStaffSingUpToast && <ToastContainer />}
    </>
  );
}

export default AddNewStaff;
