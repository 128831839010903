import React, { useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction"; // Import interactionPlugin for dateClick

function AppointmentDummy() {
  const [calendarEvents, setCalendarEvents] = useState([]);

  const handleDateClick = (arg) => {
    const title = prompt("Enter event title:");
    if (title) {
      const newEvent = {
        title,
        start: arg.date,
        allDay: arg.allDay,
      };
      setCalendarEvents([...calendarEvents, newEvent]);
    }
  };

  return (
    <FullCalendar
      plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
      initialView="dayGridMonth"
      headerToolbar={{
        left: "prev,next today",
        center: "title",
        right: "dayGridMonth,timeGridWeek,timeGridDay",
      }}
      events={calendarEvents}
      dateClick={handleDateClick} // Attach dateClick event handler
    />
  );
}

export default AppointmentDummy;
