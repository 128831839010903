// roles.js
export const roles = {
  SUPER_ADMIN: "superadmin",
  SLT_HEAD: "slthead",
  SLT_GENERAL: "sltgeneral",
  OFFICE_STAFF: "officestaff",
  FINANCE_ADMIN: "financeadmin",
  STAFF_USER: "staffuser",
  SUPERVISION_ACCESS: "supervisionaccess",
  ROSTER_ACCESS: "rosteraccess",
};
export const permissions = {
  ADMIN: "superAdmin",
  ADD: "add",
  EDIT: "edit",
  DELETE: "delete",
  VIEW: "view",
  PAYROLL: "payroll",
  ROSTER: "roster",
  DISCIPLINARYINFO: "disciplinaryinfo",
  SUPERVISION: "supervision",
  HOLIDAYS: "holidays",
  RECRUITMENT: "recruitment",
  TRAINING: "training",
};
