import React from "react";
import Layout from "../../components/layouts/Layout";
import InformationForm from "./InformationForm";

function AddNewServiceUser() {
  return (
    <>
      <Layout pageTitleText="Service User Information">
        <div className="mainContent youngInformation">
          <header>
            <br />
            <h3>Service User Information</h3>
          </header>
          <InformationForm />
        </div>
      </Layout>
    </>
  );
}

export default AddNewServiceUser;
