import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getRecords, catchAsync, isSuccess } from "../../helper/helper";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../env";
import { CustomOption } from "../../helper/helperJsx";

const CustomSelect = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  filterBy,
  label,
  defaultUser,
  isDisable,
  isMulti,
}) => {
  const [usersRecords, setUserRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultValue, setDefaultValue] = useState();

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const fetchAllRecords = async () => {
    try {
      const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);

      if (response?.status === isSuccess) {
        const filteredStaffMembers = filterBy
          ? response?.data?.staffMembers?.filter(
              (item) => item?.staffDesignation === filterBy
            )
          : response?.data?.staffMembers?.filter((item) => item?.user?.name);

        const getUsers = filteredStaffMembers?.map((item) => ({
          value: item?.user?._id,
          label: item?.user?.name,
          image: item?.user?.profile_picture_url,
        }));

        if (defaultUser && !isMulti) {
          const foundUsers = getUsers?.filter((user) =>
            defaultUser?.includes(user?.value)
          );
          if (foundUsers?.length > 0) {
            setDefaultValue(foundUsers);
            onSelect(foundUsers?.value);
          }
        } else if (defaultUser && isMulti) {
          const foundUsers = getUsers?.filter((user) =>
            defaultUser?.includes(user?.value)
          );
          if (foundUsers?.length > 0) {
            setDefaultValue(foundUsers);
            onSelect(foundUsers?.map((user) => user?.value));
          }
        }

        setUserRecords(getUsers);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, [setDefaultValue]);

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleInputChange = () => {
    setIsLoading(false);
  };

  return (
    <div className={`customSelectBox ${errorClass}`}>
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          <Select
            className="basic-single cursor-pointer"
            classNamePrefix="select"
            options={usersRecords}
            value={defaultValue}
            isDisabled={isDisable}
            isClearable
            isSearchable
            isMulti
            onChange={(selectedOption) => {
              const selectedIds = selectedOption?.map(
                (option) => option?.value
              );
              setDefaultValue(selectedOption);
              onSelect(selectedIds);
            }}
            closeMenuOnSelect={false}
            placeholder="Select"
            onInputChange={handleInputChange}
            getOptionLabel={(selectedOption) => selectedOption?.label}
            getOptionValue={(selectedOption) => selectedOption?.value}
            components={{ Option: CustomOption }}
          />

          {label && <label className="floatingLabel">{label}</label>}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default CustomSelect;
