import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { useFormik } from "formik";
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_NEW_ROTA_TEMPLATE_URL, ADMIN_ID } from "../../env";
import {
  updateRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import SelectBox from "../../components/forms-fields/SelectBox";
import { duplicateRotaTemplate } from "../../schemas";

function DuplicateRotaTemplate({ subModalDuplicateRota, rotaStockDetail }) {
  const handleShowHideModal = () => {
    subModalDuplicateRota((prevShow) => !prevShow);
  };

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastDuplicateRotaTemplate, setIsToastDuplicateRotaTemplate] = useState(false);
  const [startDateStatus, setStartDateStatus] = useState(false);
  const [endDateStatus, setEndDateStatus] = useState(false);
  const todayDate = moment().valueOf();

  const rotaTempWeeksOptions = [
    { value: 2, label: 2 },
    { value: 4, label: 4 },
  ];

  const statusOptions = [
    { value: "saved", label: "Saved" },
    { value: "future", label: "Future" },
  ];

  const [suFormData, setSuFormData] = useState({
    tempId: rotaStockDetail?._id,
    rotaTemplateTitle: rotaStockDetail?.rotaTemplateTitle,
    templateWeeks: rotaStockDetail?.templateWeeks,
    status: "future",
    shiftLocation: rotaStockDetail?.shiftLocation,
    startDate: rotaStockDetail?.startDate || null,
    endDate: rotaStockDetail?.endDate || null, // Allow endDate to be null
    addedby: rotaStockDetail?.addedby,
    lastModifiedBy: ADMIN_ID,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: duplicateRotaTemplate,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postDuplicateRotaTemplateFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastDuplicateRotaTemplate(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (method === "postDuplicateRotaTemplateFormData" && data?.status === isSuccess) {
      setIsToastDuplicateRotaTemplate(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postDuplicateRotaTemplateFormData = catchAsync(async () => {
    const updateValues = {
      ...values,
    };
    console.log("🚀 ~ postDuplicateRotaTemplateFormData ~ updateValues:", updateValues)
    const response = await updateRecords(POST_NEW_ROTA_TEMPLATE_URL, updateValues);
    console.log("🚀 ~ postDuplicateRotaTemplateFormData ~ response:", response)
    processPostRecords("postDuplicateRotaTemplateFormData", response);
    setIsSubmitButtonClicked(false);
  });

  const onChangeSelectStatus = (e) => {
    setFieldValue("status", e.target.value);
  };

  useEffect(() => {
    if (values.status !== "saved") {
      setStartDateStatus(true);
      setEndDateStatus(true);
      if (values?.startDate === 0) {
        setFieldValue("startDate", todayDate)
      }

    } else {
      setStartDateStatus(false);
      setEndDateStatus(false);
      setFieldValue("startDate", 0);
    }
  }, [values.status]);

  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit}>
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-12">
                <Input
                  type="text"
                  name="rotaTemplateTitle"
                  value={values?.rotaTemplateTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Name"
                  customError={touched?.rotaTemplateTitle && errors?.rotaTemplateTitle}
                  errorMessage={errors?.rotaTemplateTitle}
                  erroClass={touched?.rotaTemplateTitle && errors?.rotaTemplateTitle ? "validationError" : ""}
                  requiredStar={true}
                />
              </div>

              <div className="col-md-12">

                <SelectBox
                  id="templateWeeks"
                  name="templateWeeks"
                  value={values?.templateWeeks}
                  onChange={handleChange}
                  options={rotaTempWeeksOptions}
                  labelText="Weeks"
                  requiredStar={true}
                />
              </div>

              <div className="col-md-12">
                <SelectBox
                  id="status"
                  name="status"
                  value={values?.status}
                  onChange={onChangeSelectStatus}
                  options={statusOptions}
                  labelText="Status"
                  requiredStar={true}
                />
              </div>

              {(startDateStatus && values?.status != "saved") && (
                <div className="col-md-12">
                  <Input
                    type="date"
                    name="startDate"
                    value={moment(values?.startDate).format("YYYY-MM-DD")}
                    onChange={(e) => {
                      setFieldValue('startDate', moment(e.target.value).valueOf());
                    }}
                    onBlur={handleBlur}
                    labelText="Start Date"
                    customError={touched?.startDate && errors?.startDate}
                    errorMessage={errors?.startDate}
                    erroClass={touched?.startDate && errors?.startDate ? "validationError" : ""}
                    requiredStar={true}
                  />
                </div>
              )}

              {(endDateStatus && values?.status != "saved") && (
                <div className="col-md-12">
                  <Input
                    type="date"
                    name="endDate"
                    value={values?.endDate ? moment(values?.endDate).format("YYYY-MM-DD") : ''}
                    onChange={(e) => {
                      setFieldValue('endDate', e.target.value ? moment(e.target.value).valueOf() : null);
                    }}
                    onBlur={handleBlur}
                    labelText="End Date"
                    customError={touched?.endDate && errors?.endDate}
                    errorMessage={errors?.endDate}
                    erroClass={touched?.endDate && errors?.endDate ? "validationError" : ""}
                    requiredStar={true}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="formActionButtons">
            <span onClick={subModalDuplicateRota} className="btn btn-sm btn-outline-primary">
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="primary" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastDuplicateRotaTemplate && <ToastContainer />}
    </>
  );
}

export default DuplicateRotaTemplate;
