import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/Layout";

import {
  BASE_URL,
  GET_ALL_SERVICE_USERS_URL,
  UPDATE_SERVICE_USER_BY_ID_URL,
  GET_ALL_SERVICE_USERS_URL_ENQUIRY,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
  updateRecords,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  apiQuery,
} from "../../../helper/helper";
import { useNavigate } from "react-router-dom";
import ProgressComponent from "../../../components/general/ProgressComponent";
import TeamLead from "./TeamLead";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AssignUser() {
  const [isLoading, setIsLoading] = useState(true);
  const [reviewListData, setReviewListData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedServiceUserId, setSelectedServiceUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [isToast, setIsToast] = useState(false);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    limit: 50,
    query: {
      critarion: { active: true },
      usersFields: "profile_picture_url",
      addedby: "_id email first_name",
      lastModifiedBy: "_id email first_name",
    },
  };
  const processEnquiryData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allRecords = data?.data?.suEnquirys;
      if (allRecords.some(record => record.suEnqStatus === "accepted")) {
        const acceptedRecords = allRecords.filter(record => record.suEnqStatus === "accepted");
        setReviewListData(acceptedRecords);
        setNoRecordFound(false);
        setIsLoading(false);
      } else {
        setNoRecordFound(true);
        setIsLoading(false);
        setReviewListData([]);
      }

    }
  };

  const getAllServiceUserEnquiry = catchAsync(async () => {
    const response = await getRecords(
      GET_ALL_SERVICE_USERS_URL_ENQUIRY,
      apiQuery
    );
    console.log("🚀 SU ENQUIRY:", response);
    processEnquiryData("get", response);
  });

  useEffect(() => {
    getAllServiceUserEnquiry();
  }, []);

  // Assign Teamlead

  const handleUserSelect = (userId, itemId) => {
    setSelectedUserId(userId);
    setSelectedServiceUserId(itemId);
    const assignSuQuery = {
      serviceUserid: selectedServiceUserId,
      suProvider: selectedUserId,
      active: true,
    };
  };

  useEffect(() => {
    if (selectedUserId !== null && selectedServiceUserId !== null) {
      const assignSuQuery = {
        serviceUserid: selectedServiceUserId,
        suProvider: selectedUserId,
        active: true,
        // serviceUserid: "657c0142ec4a34aa29536c9c",
        // suProvider: "657c0113ec4a34aa29536c11"
      };
      console.log(
        "🚀 ~ file: AssignUser.jsx:82 ~ useEffect ~ assignSuQuery:",
        assignSuQuery
      );

      const updateData = async () => {
        try {
          const response = await updateRecords(
            UPDATE_SERVICE_USER_BY_ID_URL,
            assignSuQuery
          );

          if (response?.status !== isSuccess) {
            setIsToast(true);
            toastErrorMessages(toast?.error, response?.message, "", 500, 500);
            console.log("Update failed");
          } else {
            console.log(
              "🚀 ~ file: AssignUser.jsx:86 ~ updateData ~ response:",
              response
            );
            getAllServiceUserEnquiry();
            setIsToast(true);

            toastSuccessMessages(
              toast?.success,
              response?.message,
              "",
              500,
              500
            );
            console.log("Update successful");
          }
          // if (response?.status !== isSuccess) {
          //   console.log("Update failed");
          // } else {
          //   console.log("Update successful");
          // }
        } catch (error) {
          console.error("Error updating data:", error);
        }
      };

      updateData();
    }
  }, [selectedUserId, selectedServiceUserId]);

  const filteredReviewList = reviewListData.filter((item) => {
    const fullName = `${item?.serviceUser?.modelId?.name} ${item?.serviceUser?.modelId?.suLastName}`;
    return fullName.toLowerCase().includes(searchQuery.toLowerCase());
  });



  return (
    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>Assign Staff</small>
          </>
        }
      >
        <div className="addNewRequestSU">
          <div className="mainContent p-0">
            <header className="mainContentHeader">
              <h3>Assign Staff</h3>
              <div className="actionButtons">
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Type your search"
                      value={searchQuery}
                      onChange={(e) => setSearchQuery(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </header>
          </div>
          <div className="requestContentCards assignUsersContentCards">
            {noRecordFound ? (
              <>
                <div className="noRecordFound">
                  <p>No records found.</p>
                </div>
              </>
            ) : isLoading ? (
              <ProgressComponent />
            ) : (
              filteredReviewList?.map((item, index) =>
                item?.suEnqStatus === "accepted" ? (
                  <>
                    <div className="cards">
                      <div className="cardsUserDetail">
                        <div className="usersNameInfo curSorPointer">
                          <div className="userImage">
                            <img
                              src={
                                BASE_URL +
                                item?.serviceUser?.profile_picture_url
                              }
                              alt={
                                item?.serviceUser?.modelId?.name +
                                item?.serviceUser?.modelId?.suLastName
                              }
                            />
                          </div>
                          <div className="userInfo">
                            <span className="nameBlock">
                              {item?.serviceUser?.modelId?.name}{" "}
                              {item?.serviceUser?.modelId?.suLastName}
                            </span>
                          </div>
                        </div>
                        {!isLoading && (
                          <TeamLead
                            filterBy={"teamlead"}
                            onSelect={(userId) =>
                              handleUserSelect(
                                userId,
                                item?.serviceUser?.modelId?._id
                              )
                            }
                            defaultSelectedValue={
                              item?.serviceUser?.modelId?.suProvider[0]
                            }
                          />
                        )}
                      </div>
                      <div className="commentBox">
                        <header className="assignUser">
                          <p> <strong>Ref ID:</strong><br />{item?.referenceId}<br />
                            {/* {item?.suEnqContactNo}<br /> */}
                          </p>
                          <p>
                            <strong>Email:</strong>
                            <br />
                            {item?.suEnqEmail}
                          </p>
                        </header>
                        {/* <p> {item?._id}</p> */}

                        <p>
                          <strong>Comments:</strong>
                          <br />
                          {item?.suEnqComments.length > 80
                            ? item?.suEnqComments.slice(0, 100) + "..."
                            : item?.suEnqComments}
                        </p>
                      </div>
                    </div>
                  </>
                ) : null
              )
            )}
          </div>
        </div>
      </Layout>
      {isToast && <ToastContainer />}
    </>
  );
}

export default AssignUser;
