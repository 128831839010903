import React, { useState } from "react";
import Layout from "../../components/layouts/Layout";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import NameSpace from "./NameSpace";
function MessagingSystem() {
  const [mobileNameSpace, setMobileNameSpace] = useState(false);
  const nameSpaceToggle = () => {
    setMobileNameSpace(!mobileNameSpace);
    console.log(mobileNameSpace);
  };
  return (
    <Layout pageTitleText="Messaging System">
      <div className="messageWrapper">
        <UserDetailHeader addNewMessage={true} />
        <div className={
          !mobileNameSpace ? ("messageContainer") : "messageContainer responsiveMessageContainer"
        }>
          <NameSpace mobileDisplayStatus={mobileNameSpace} onMobile={nameSpaceToggle} />
        </div>


      </div>
    </Layout>
  );
}

export default MessagingSystem;
