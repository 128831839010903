// ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from './AuthContext';
import useAuthorization from './useAuthorization';
import Loading from './Loading'; // Import the Loading component if needed

const ProtectedRoute = ({ element, requiredPermissions }) => {
  const { userRoles, loading } = useAuth();

  // Call useAuthorization at the top level
  const isAuthorized = useAuthorization(userRoles, requiredPermissions);

  // Render loading component if still loading
  if (loading) {
    return <Loading />;
  }

  // Check if user is authenticated and authorized
  if (!userRoles.length || !isAuthorized) {
    return <Navigate to="/permission-error" replace />;
  }

  // Render the element if authorized
  return element;
};

export default ProtectedRoute;
