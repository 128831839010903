import React, { useState, useEffect } from "react";
import { Button, Tab, Nav } from "react-bootstrap";
import moment from "moment";
import StaffMembersRosterTabs from "./StaffMembersRosterTabs";
import ServiceUsersRosterTabs from "./ServiceUsersRosterTabs";
import AutoAddShifts from "./components/AutoAddShifts";


function LocationRoster({ shiftLocationId, onData }) {

  const locationId = shiftLocationId
  const [weekNumber, setWeekNumber] = useState(1);
  const [staffMemberTab, setStaffMemberTab] = useState(true)
  const [serviceUserTab, setServiceUserTab] = useState(false)
  const [isCurrentDate, setIsCurrentDate] = useState(false);
  const preLoadComponent = (activeSetter, inactiveSetter) => {
    activeSetter(true);
    inactiveSetter(false);
  };


  const [calendarDays, setCalendarDaysArray] = useState([]);
  const [currentStartDate, setCurrentStartDate] = useState(moment().startOf("isoWeek"));

  const getWeekOfMonth = (date) => {
    const startOfMonth = moment(date).startOf('month');
    const startOfWeek = moment(date).startOf('week');

    return startOfWeek.diff(startOfMonth, 'weeks') + 1;
  };



  useEffect(() => {
    const weekOfMonth = getWeekOfMonth(currentStartDate);
    setWeekNumber(weekOfMonth);
    setIsCurrentDate(true)
    onData(currentStartDate);
  }, [currentStartDate]);





  const handleDateChange = (direction) => {
    setCurrentStartDate((prevStartDate) => {
      return direction === "next"
        ? moment(prevStartDate).add(7, "days")
        : moment(prevStartDate).subtract(7, "days");
    });
  };
  useEffect(() => {

    setCalendarDaysArray(
      getDaysArray(
        currentStartDate.startOf("isoWeek").valueOf(),
        currentStartDate.endOf("isoWeek").valueOf()
      )
    );
  }, [currentStartDate]);

  const weekdays = ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday",];

  // Function to generate an array of days between two dates
  const getDaysArray = (start, end) => {
    const days = [];
    for (
      let date = new Date(start);
      date <= end;
      date.setDate(date.getDate() + 1)
    ) {
      days.push(date.getTime()); // Push the millisecond timestamp
    }
    return days;
  };

  return (
    <>
      <div className="rowClickUnset">
        {/* Date Filter range and Status symbol */}
        <div className="mainContent p-0 mt-3 mb-3">
          <div className="subTabsWrapper">
            <div className="subTabsHeader radioTabsHeader">

              <div className="tabsBlocks">
                <div className="actionButtons">
                  <Button
                    className="btn btn-sm btn-primary"
                    // disabled={isDisablePrevButton}
                    onClick={() => handleDateChange("prev")}
                  >
                    Previous
                  </Button>
                  &nbsp;
                  <Button
                    className="btn btn-sm btn-primary"
                    // disabled={isDisableNextButton}
                    onClick={() => handleDateChange("next")}
                  >
                    Next
                  </Button>
                </div>
              </div>
              <div className="tabsBlocks">

                {/* Range Filter and action button */}
                <div className="rangeWithButtons">
                  <div className="rangNavigation">
                    {/* <span>
                      <img src={lessThan} alt="Less Then" />
                    </span> */}
                    <p>
                      {moment(currentStartDate)
                        .startOf("isoWeek")
                        .format("DD/MM/YYYY")}
                      &nbsp;to&nbsp;
                      {moment(currentStartDate)
                        .endOf("isoWeek")
                        .format("DD/MM/YYYY")}
                    </p>
                    {/* <span>
                      <img src={greaterThan} alt="Greater Then" />
                    </span> */}
                  </div>

                </div>
              </div>
              <div className="tabsBlocks">
                <div className="colorStatus">
                  <div className="colorStatusItem">
                    <span className="color green">&nbsp;</span> Available
                  </div>
                  <div className="colorStatusItem">
                    <span className="color red">&nbsp;</span> Not Available
                  </div>
                  <div className="colorStatusItem">
                    <span className="color grey">&nbsp;</span> On leave
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="mainContent p-0">
          <div className="p-4 pb-0">
            <p className="mb-0">Current week number of the month: {weekNumber}</p>
          </div>
          <div className="subTabsWrapper">
            <Tab.Container id="myTab" defaultActiveKey="staffMember">
              {/* {shiftLocationName} */}
              <div className="subTabsHeader radioTabsHeader">
                <div className="tabsBlocks">
                  <Nav variant="tabs">
                    <Nav.Item>
                      <Nav.Link eventKey="staffMember" onClick={() => (preLoadComponent(setStaffMemberTab, setServiceUserTab))}>Staff Member</Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="serviceUser" onClick={() => (preLoadComponent(setServiceUserTab, setStaffMemberTab))}>Service User</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>


              </div>

              <Tab.Content>
                <Tab.Pane eventKey="staffMember">
                  <div className="rosterTabsContent" style={{ backgroundColor: "FF0000" }}>
                    {
                      staffMemberTab && (
                        <StaffMembersRosterTabs
                          shiftLocationId={locationId}
                          weekNumber={weekNumber}
                          weekdays={weekdays}
                          calendarDays={calendarDays}
                          currentStartDate={currentStartDate}
                        />
                      )
                    }


                  </div>
                </Tab.Pane>
                <Tab.Pane eventKey="serviceUser">
                  <div className="rosterTabsContent">

                    {serviceUserTab && (
                      <ServiceUsersRosterTabs
                        shiftLocationId={locationId}
                        weekNumber={weekNumber}
                        weekdays={weekdays}
                        calendarDays={calendarDays}
                        currentStartDate={currentStartDate}
                      />
                    )}


                  </div>
                </Tab.Pane>
              </Tab.Content>
            </Tab.Container>
          </div>
        </div>


      </div>
    </>
  )
}

export default LocationRoster