import React, { useState, useEffect } from "react";

import PayrollBlocks from "./components/PayrollBlocks";

import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import ProfileDetails from "../../components/user-detail/ProfileDetails";

function PayrollIndividual({ userIdData }) {
  const [userId, setUserId] = useState(parseInt(userIdData));

  return (
    <>
      <div className="profileDetailWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails userIdData={userId} />
          </div>
          <div className="col-md-9">
            <div className="userDetail userPayrollDetail">
              <UserDetailHeader actionButtons={true} />
              <PayrollBlocks currentStatus={true} />

              <div className="contentOverview payrollOverview">
                <h3>Payroll Overview</h3>

                <div className="calendarOverview">
                  <div className="headerRow dayRow">
                    <div className="dayCell">19 Sat</div>
                    <div className="dayCell">20 Sun</div>
                    <div className="dayCell">21 Mon</div>
                    <div className="dayCell">22 Tue</div>
                    <div className="dayCell">23 Wed</div>
                    <div className="dayCell">24 Thu</div>
                    <div className="dayCell">25 Fri</div>
                  </div>
                  <div className="bodyRow dayRow">
                    <div className="dayCell">
                      <div className="contentcell greenCell">
                        <span className="time">
                          7am-7pm <span className="statusBall">&nbsp;</span>
                        </span>
                        <span className="type">12hrs, ‘Worker type’</span>
                      </div>
                    </div>

                    <div className="dayCell holidayCell">
                      <div className="contentcell">
                        <span className="request">Requested Holiday</span>
                        <span className="requestStatus text-danger">
                          Pending
                        </span>
                      </div>
                    </div>

                    <div className="dayCell">
                      <div className="contentcell greyCell">
                        <span className="time">
                          7am-7pm <span className="statusBall">&nbsp;</span>
                        </span>
                        <span className="type">12hrs, ‘Worker type’</span>
                      </div>
                    </div>

                    <div className="dayCell sleepCell">
                      <div className="contentcell">
                        <span className="request">Sleep In</span>
                      </div>
                    </div>

                    <div className="dayCell">
                      <div className="contentcell greenCell">
                        <span className="time">
                          7am-7pm <span className="statusBall">&nbsp;</span>
                        </span>
                        <span className="type">12hrs, ‘Worker type’</span>
                      </div>
                    </div>

                    <div className="dayCell">
                      <div className="contentcell greenCell">
                        <span className="time">
                          7am-7pm <span className="statusBall">&nbsp;</span>
                        </span>
                        <span className="type">12hrs, ‘Worker type’</span>
                      </div>
                    </div>

                    <div className="dayCell">
                      <div className="contentcell greenCell">
                        <span className="time">
                          7am-7pm <span className="statusBall">&nbsp;</span>
                        </span>
                        <span className="type">12hrs, ‘Worker type’</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PayrollIndividual;
