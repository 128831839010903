import React from "react";

function CheckBox({ id, name, tick, onChange, labelText }) {
  return (
    <label className="checkboxContainer">
      <span className="labelText">{labelText}</span>
      <input
        type="checkbox"
        id={id}
        name={name}
        checked={tick}
        onChange={onChange}
      />
      <span className="checkmark"></span>
    </label>
  );
}

export default CheckBox;
