import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/forms-fields/Input";
import ProgressComponent from "../../../components/general/ProgressComponent";
import CustomSelectStaffMember from "../../../components/forms-fields/CustomSelectMultipleStaffMembers";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
} from "../../../helper/helper";
import { ADMIN_ID, POST_CREATE_GROUP_CHAT_URL } from "../../../env";

function AddNewTemplate({ handleShowModel, handleCloseModel }) {
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [isToastRotaSchedule, setIsToastRotaSchedule] = useState(false);

  const validationSchema = Yup?.object().shape({
    groupName: Yup?.string()?.required("Group name is required."),
    groupMembers: Yup?.array().min(1, "At least one member is required."),
  });

  const rotaScheduleFormData = useFormik({
    initialValues: {
      groupName: "",
      groupMembers: [],
      addedby: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitClicked(true);
        await handleCreateGroup(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          1000,
          1000
        );
        setIsFormSubmitClicked(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    resetForm,
    setFieldValue,
  } = rotaScheduleFormData;

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsFormSubmitClicked(false);
      setIsToastRotaSchedule(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "post" && data?.status === isSuccess) {
      window.location.reload();
    }
  };

  const handleCreateGroup = catchAsync(async (req, res) => {
    const updatedDoc = {
      ...req,
      groupMembers: [...req.groupMembers, ADMIN_ID],
    };
    const response = await postRecords(POST_CREATE_GROUP_CHAT_URL, updatedDoc);
    processFetchRecords("post", response);
  });

  const handleStaffSelect = (array) => {
    setFieldValue("groupMembers", array);
  };

  return (
    <>
      <Modal
        show={handleShowModel}
        onHide={handleCloseModel}
        className="ftcModalPopup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create Group</Modal.Title>
        </Modal.Header>
        <form method="post" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row align-items-center mt-4">
              <div className="col-sm-12">
                <div className="form-group">
                  <Input
                    type="text"
                    name="groupName"
                    value={values?.groupName}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelText="Name"
                    placeholder={"Type here.."}
                    customError={touched?.groupName && errors?.groupName}
                    errorMessage={errors?.groupName}
                    errorClass={
                      touched?.groupName && errors?.groupName
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                </div>
              </div>
              <div className="col-sm-12 col-md-12">
                <div className="form-group">
                  <CustomSelectStaffMember
                    onSelect={handleStaffSelect}
                    label="Members"
                    isMulti={true}
                    defaultValue={false}
                    onBlur={handleBlur}
                    customError={touched?.groupMembers && errors?.groupMembers}
                    errorMessage={errors?.groupMembers}
                    errorClass={
                      touched?.groupMembers &&
                      errors?.groupMembers &&
                      "validationError"
                    }
                    requiredStar={true}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isFormSubmitClicked ? (
              <ProgressComponent />
            ) : (
              <>
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={handleCloseModel}
                >
                  Cancel
                </Button>

                <Button className="btn-sm" type="submit" variant="primary">
                  Save
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {isToastRotaSchedule && <ToastContainer />}
    </>
  );
}

export default AddNewTemplate;
