import React, { useState, useEffect } from "react";
import { Dropdown, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import {
  getRecords,
  catchAsync,
  isSuccess,
} from "../../helper/helper";
import { GET_CONSENT_TEMPLATE_CAT_URL } from "../../env";

function ClickableHealthCategories(
  {addHealthIssueOnClick}
) {
  const [allRecords, setRecords] = useState([]);

  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;

      setRecords(allData);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CONSENT_TEMPLATE_CAT_URL);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleClick = (idValue, nameValue) => {
    addHealthIssueOnClick(idValue, nameValue); 
    console.log("test", idValue, nameValue);
  };
  return (
    <div className="clickableTargetPoints">
      {allRecords.map((healthCategoryItem, index) => (
        <>
          {healthCategoryItem.subcat && healthCategoryItem.subcat.length > 0 ? (
            healthCategoryItem.subcat.map((subcategory, subcategoryIndex) => (
              <span className="clickPoint" onClick={()=>{handleClick(subcategory?._id, subcategory?.name)}}>
                <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{subcategory?.name}</Tooltip>}>
                  <span className={`targetPoint ${subcategory?.enum}`} key={subcategory?._id}>
                    <span className="issuePointDetail">
                      &nbsp;
                    </span>
                  </span>
                </OverlayTrigger>
              </span>

            ))
          ) : (
            <span className="clickPoint" onClick={()=>{handleClick(healthCategoryItem?._id, healthCategoryItem?.name)}}>
              <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{healthCategoryItem?.name}</Tooltip>}>
                <span className={`targetPoint ${healthCategoryItem?.enum}`} key={index}>
                  <span className="issuePointDetail">
                    &nbsp;
                  </span>
                </span>
              </OverlayTrigger>
            </span>

          )}
        </>
      ))}
    </div>
  )
}

export default ClickableHealthCategories