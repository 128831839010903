import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { DBSCStageValidation } from "../../../schemas";
import { Form } from "react-bootstrap";
import {
  POST_UPDATE_RECRUITMENT_CANDIDATE_URL,
  UPLOAD_REFERENCE_DOCUMENT_URL,
  ADMIN_AUTH_TOKEN,
  BASE_URL,
  ADMIN_ID
} from "../../../env";
import {
  postRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  catchAsync,
} from "../../../helper/helper";
import TextArea from "../../../components/forms-fields/TextArea";

function DBSCProcessPopup({ recCandidateDetail, subModalViewCarePlans }) {
  console.log("🚀 ~ DBSCProcessPopup ~ recCandidateDetail:", recCandidateDetail)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [uploadedFileLink, setUploadedFileLink] = useState(false);
  const [isRecrUserData, setIsRecrUserData] = useState(false);
  const [trainingStatus, setTrainingStatus] = useState(false);
  const [commentBox, setCommentBox] = useState("");
  const handleClose = () => {
    subModalViewCarePlans(subModalViewCarePlans);
  };

  const [DBSCFormFields] = useState([
    { id: 1, name: "DBSCertificate", label: "DBS Certificate", documentEnum: "DBSCertificate" },

  ]);

  const [DBSCUploadedDoc, setInterviewUploadedDoc] = useState([
    { documentEnum: "DBSCertificate", path: "", title: "DBS Certificate" },
  ]);

  useEffect(() => {

    if (recCandidateDetail?.DSBCDoc?.length > 0) {
      setInterviewUploadedDoc(recCandidateDetail.DSBCDoc);
    }
  }, [recCandidateDetail]);

  const [suFormData, setSuFormData] = useState({
    recruitId: recCandidateDetail?._id || "",
    DBSCertificate: recCandidateDetail.DSBCDoc[0]?.path || "",
    isDbscChecked: trainingStatus,
    commentBox: ""
  });
  // console.log("🚀 ~ DBSCProcessPopup ~ suFormDatasuFormDatasuFormData:", suFormData)
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: DBSCStageValidation,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsRecrUserData(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (method === "postAddCarePlansFormData" && data?.status === isSuccess) {
      setIsRecrUserData(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const updateValues = {
      ...values,
      DSBCDoc: DBSCUploadedDoc,
      isDbscChecked: trainingStatus,
      notes: [
        ...recCandidateDetail?.notes,
        ...(commentBox !== "" ? [{
          staffId: ADMIN_ID,
          note: commentBox,
          process: recCandidateDetail?.processStatus,
        }] : [])
      ],
    };
    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_UPDATE_RECRUITMENT_CANDIDATE_URL, updateValues);
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });

  const handleFileUpload = async (e, documentEnum, settingTheArray) => {
    setIsSubmitButtonClicked(true);
    try {
      const file = e.target.files[0];
      const fileName = file.name;
      const fileType = file.type;
      const fileSize = file.size;

      // Check file size (1MB = 1048576 bytes)
      if (fileSize > 1048576) {
        alert('File size exceeds 1MB');
        setFileSizeError(true);
        setIsSubmitButtonClicked(false);
        return;
      }

      // Check file type
      const acceptedFileTypes = [
        'application/pdf',
        'application/msword',
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        'image/jpeg',
        'image/jpg',
        'image/svg+xml'
      ];

      if (acceptedFileTypes.includes(fileType)) {
        const fileData = new FormData();
        fileData.append('attachements', file);

        const apiResponse = await fetch(UPLOAD_REFERENCE_DOCUMENT_URL, {
          method: 'POST',
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });

        const resultResponse = await apiResponse.json();
        console.log("🚀 ~ handleFileUpload ~ resultResponse:", resultResponse)
        const fileLink = `${resultResponse?.data[0]?.fileNameInDirectory}`;
        setUploadedFileLink(fileLink);

        settingTheArray((prevValues) =>
          prevValues.map((doc) =>
            doc.documentEnum === documentEnum ? { ...doc, path: fileLink } : doc
          )
        );
      } else {
        alert('Unsupported file format');
      }

      setIsSubmitButtonClicked(false);
    } catch (error) {
      console.error('Error uploading file:', error);
      setIsSubmitButtonClicked(false);
    }
  };

  useEffect(() => {
    setValues((prevValues) => ({
      ...prevValues,
      DSBCDoc: DBSCUploadedDoc,
      isDbscChecked: trainingStatus
    }));
  }, [DBSCUploadedDoc]);

  return (
    <>
      <main>
        <form onSubmit={handleSubmit} encType="multipart/form-data">

          <div className="row">

            {recCandidateDetail?.processStatus === 'DBSC' &&
              recCandidateDetail.DSBCDoc.length >= 1 && (
                <>
                  <div className="col-md-12">
                    <p>
                      <strong>Attached Documents</strong>
                    </p>
                    <div className="attachedDocumentWrapper">
                      {
                        DBSCUploadedDoc.map((item, index) => (

                          <div key={index} className="alert alert-secondary" role="alert">
                            <a target="_blank" href={`${BASE_URL}${item?.path}`}>{item?.title}</a>
                          </div>



                        ))
                      }
                    </div>
                  </div>
                </>
              )}

            <div className="col-md-12 mt-5">
              <p>
                <strong>Attach required documents</strong>
                <small>Please upload a file that is 1MB or smaller.</small>
              </p>
            </div>
            {DBSCFormFields.map((item, index) => (
              <div key={index} className="col-md-12">
                <div className="form-group">
                  <input
                    name={item.name}
                    id={item.name}
                    type="file"
                    accept=".jpg,.jpeg,.pdf,.doc,.docx,.svg"
                    onBlur={handleBlur}
                    onChange={(e) => {
                      handleChange(e);
                      handleFileUpload(e, item.documentEnum, setInterviewUploadedDoc);
                    }}
                  />
                  <label htmlFor={item.name} className="floatingLabel">
                    {item.label}
                    <span className="text-danger">*</span>
                  </label>
                  {errors[item.name] && touched[item.name] && (
                    <div className="validationError">
                      <div className="text-danger">{errors[item.name]}</div>
                    </div>
                  )}
                  {fileSizeError && (
                    <div className="validationError">
                      <div className="text-danger">
                        Error: File size exceeds the 1MB limit. Please upload a smaller file.
                      </div>
                    </div>
                  )}
                </div>
              </div>
            ))}

            <div className="col-md-12 mb-3">
              <Form.Check
                type="checkbox"
                id={'isDbscChecked'}
                name={'isDbscChecked'}
                checked={values?.isDbscChecked}
                onChange={(e) => {
                  setFieldValue('isDbscChecked', e.target.checked);
                  setTrainingStatus(!trainingStatus);
                }}
                label={'Please tick to confirm that all training has been sent to the candidate'}
              />
              {errors.isDbscChecked && touched.isDbscChecked && (
                <div className="validationError">
                  <div className="text-danger">{errors.isDbscChecked}</div>
                </div>
              )}
            </div>

            <div className="col-md-12">
              <TextArea
                name="commentBox"
                value={commentBox}
                onChange={(e) => { setCommentBox(e.target.value); handleChange(e); }}
                onBlur={handleBlur}
                cols={5}
                rows={5}
                labelText="Notes"

              />
            </div>
          </div>
          <div className="formActionButtons">
            {isSubmitButtonClicked ? (
              <Button
                onClick={handleClose}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
            <span
              onClick={handleClose}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
          </div>
        </form>
      </main>
      {isRecrUserData && <ToastContainer />}
    </>
  );
}

export default DBSCProcessPopup;
