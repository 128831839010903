import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import AddGroupNewMembers from "../modals/AddGroupNewMembers";
import {
  ADMIN_ID,
  BASE_URL,
  GET_GROUP_INFO_URL,
  PUT_REMOVE_MEMBER_FROM_GROUP_URL,
} from "../../../env";
import ProgressComponent from "../../../components/general/ProgressComponent";
import actionButtonDel from "../../../assets/images/icons/actionButtonDel.svg";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  getRecords,
} from "../../../helper/helper";
function EditGroupMembers({ handleShowModel, handleCloseModel, groupItem }) {
  const [isToastRotaSchedule, setIsToast] = useState(false);
  const [groupInfoState, setGroupInfoState] = useState(false);
  const [isAddNewMembers, setIsAddNewMembers] = useState(false);
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const apiQuery = {
    groupId: groupItem && groupItem?._id,
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_GROUP_INFO_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data && data?.data;
      setGroupInfoState(result);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      await fetchAllRecords();
      await fetchAllRecords();
    }
  };

  const handleUpdateGroupInfo = catchAsync(async (req, res) => {
    const response = await postRecords(PUT_REMOVE_MEMBER_FROM_GROUP_URL, req);
    processFetchRecords("update", response);
  });

  const handleRemoveMember = async (e, item) => {
    e?.preventDefault();
    // setIsLoading(true);
    const req = {
      groupId: groupInfoState && groupInfoState?._id,
      memberIdToRemove: item?._id,
    };
    if (groupInfoState?._id) {
      handleUpdateGroupInfo(req);
    }
  };

  const handleAddNewMember = async (e) => {
    e?.preventDefault();
    setIsAddNewMembers(groupInfoState);
  };

  const handleCloseAddNewMemberModel = async () => {
    setIsAddNewMembers(false);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const RegularMemberComponent = ({ item, index, handleRemoveMember }) => {
    return (
      <div
        className="usersNameInfo"
        key={index}
      >
        <span className="userImage">
          <img
            src={`${BASE_URL}${item?.profile_picture_url}`}
            alt={item?.name}
          />
        </span>
        <span className="userInfo">
          <span className="nameBlock">{`${item?.name}`}</span>
          <span className="infoBlock">{item?.email}</span>
        </span>
        <div className="crossImageContainer">
          <span className="closeButton" onClick={(e) => handleRemoveMember(e, item)}>
            <img src={actionButtonDel} alt="Action Close" />
          </span>
        </div>
      </div>
    );
  };

  const AdminMemberComponent = ({ item, index }) => {
    return (
      <div
        className="usersNameInfo"
        key={index}
      >
        <span className="userImage">
          <img
            src={`${BASE_URL}${item?.profile_picture_url}`}
            alt={item?.name}
          />
        </span>
        <span className="userInfo">
          <span className="nameBlock">{`${item?.name}`} <small>(Admin)</small></span>
          <span className="infoBlock">{item?.email}</span>
        </span>

      </div>
    );
  };

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <>
          <Modal
            show={handleShowModel}
            onHide={handleCloseModel}
            className="ftcModalPopup"
          >
            <Modal.Header closeButton>
              <Modal.Title>Edit Group Members</Modal.Title>
            </Modal.Header>
            <form>
              <Modal.Body>
                <div className="userListAddChat editGroupInfo">
                  {groupInfoState &&
                    groupInfoState?.groupMembers?.map(
                      (item, index) =>
                        item?._id === ADMIN_ID && (
                          <div className="cards">
                            <div className="cardsUserDetail">
                              <AdminMemberComponent
                                key={index}
                                item={item}
                                index={index}
                              />
                            </div>
                          </div>
                        )
                    )}
                  {groupInfoState &&
                    groupInfoState?.groupMembers?.map(
                      (item, index) =>
                        item?._id !== ADMIN_ID && (
                          <div className="cards">
                            <div className="cardsUserDetail">
                              <RegularMemberComponent
                                key={index}
                                item={item}
                                index={index}
                                handleRemoveMember={handleRemoveMember}
                              />
                            </div>
                          </div>
                        )
                    )}
                </div>
              </Modal.Body>
              <Modal.Footer>
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={handleCloseModel}
                >
                  Close
                </Button>
                <Button className="btn-sm" onClick={handleAddNewMember}>
                  Add New Member
                </Button>
              </Modal.Footer>
            </form>
          </Modal>
        </>
      )}
      {isToastRotaSchedule && <ToastContainer />}
      {isAddNewMembers && (
        <AddGroupNewMembers
          handleShowModel={isAddNewMembers}
          handleCloseModel={handleCloseAddNewMemberModel}
          groupItem={isAddNewMembers}
          fetchRecords={fetchAllRecords}
        />
      )}
    </>
  );
}

export default EditGroupMembers;
