import React, { useEffect, useState } from 'react';
import Select from 'react-select';

function RotaWeeksHeader({ setWeekNumber }) {
  const storedRotaTemplateDetails = localStorage.getItem("RotaTemplateSchedule");
  const [rotaTemplateDetail, setRotaTemplateDetail] = useState(JSON.parse(storedRotaTemplateDetails));
  const [selectedWeeks, setSelectedWeeks] = useState([]);

  const generateWeeksArray = (numberOfWeeks) => {
    const week = [];
    for (let i = 1; i <= numberOfWeeks; i++) {
      week.push({ value: i, label: `Week ${i}` });
    }
    return week;
  }

  useEffect(() => {
    const weeks = generateWeeksArray(rotaTemplateDetail?.templateWeeks || 0);
    setSelectedWeeks(weeks);
  }, [rotaTemplateDetail?.templateWeeks]);

  return (
    <>
      <div className="mainContent p-0 mt-3 mb-3">
        <div className="subTabsWrapper">
          <div className="subTabsHeader radioTabsHeader">
            <div className="tabsBlocks">
              <div className="customSelectBox">
                <div className="weekSelector selectWeekWidthRota">
                  {selectedWeeks.length > 0 && (
                    <Select
                      className="basic-multi-select cursor-pointer"
                      classNamePrefix="select"
                      defaultValue={selectedWeeks[0]} // Setting default value to "Week 1"
                      options={selectedWeeks}
                      onChange={(selectedOption) => setWeekNumber(selectedOption.value)}
                    />
                  )}
                </div>
              </div>
            </div>
            <div className="tabsBlocks">
              <div className="colorStatus">
                <div className="colorStatusItem">
                  <span className="color green">&nbsp;</span> Available
                </div>
                <div className="colorStatusItem">
                  <span className="color red">&nbsp;</span> Not Available
                </div>
                <div className="colorStatusItem">
                  <span className="color grey">&nbsp;</span> On leave
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default RotaWeeksHeader;
