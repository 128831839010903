import React, { useState, useEffect } from "react";
import Form from "react-bootstrap/Form";
import { Tab, Nav, Button, Modal } from "react-bootstrap";

function StatusUpdated({ initialStatus, onChangeFun }) {
  const [status, setStatus] = useState(initialStatus);
  const statusClassMapping = {
    pending: "background-warning text-dark",
    rejected: "background-danger text-white",
  };
  console.log(initialStatus);
  const handleStatusChange = (event) => {
    const newStatus = event?.target?.value;
    onChangeFun(event, newStatus);
    setStatus(newStatus);
  };

  useEffect(() => {
    setStatus(initialStatus);
  }, [setStatus]);

  return (
    <div className={`select-box ${statusClassMapping[status]}`}>
      <Form.Select
        aria-label="Default select example"
        value={status}
        onChange={handleStatusChange}
      >
        {status === "pending" ? (
          <>
            <option value="pending">Pending</option>
            <option value="approved">Exempt</option>
          </>
        ) : status === "rejected" ? (
          <>
            <option value="rejected">Rejected</option>
            <option value="approved">Exempt</option>
          </>
        ) : (
          ""
        )}
      </Form.Select>
    </div>
  );
}

export default StatusUpdated;
