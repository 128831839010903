import React, { useState } from "react";
import ProfileDetails from "../../components/user-detail/ProfileDetails";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import AllTraining from "./AllTraining";
import TrainingBlocks from "./TrainingBlocks";

function TrainingIndividual({ userIdData }) {
  const [userId, setUserId] = useState(parseInt(userIdData));

  return (
    <>
      <div className="profileDetailWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails userIdData={userId} />
          </div>
          <div className="col-md-9">
            <div className="userDetail rowClickUnset">
              <UserDetailHeader addNewTraining={true} />
              <TrainingBlocks />
              <AllTraining />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainingIndividual;
