import React, { useEffect, useState } from "react";
import ProgressComponent from "../../components/general/ProgressComponent";
import StatusUpdated from "../../components/general/StatusUpdated";
import { GET_STAFFS_HOLIDAYS_DETAILS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  filterRecordsByCurrentMonthAndLabel,
  apiQuery,
} from "../../helper/helper";

function StaffOverviewBlocks() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setRecords] = useState([]);
  function processFetchRecords(method, data) {
    if (method === "get") {
      if (data?.status === isSuccess) {
        const userData = data?.data?.staffHolidayRequests?.map((item) => item);
        const users = data?.data?.staffHolidayRequests?.map(
          (item) => item?.user
        );
        setIsLoading(false);
        setRecords(userData);
      }
    }
  }
  const getAllStaffHolidays = catchAsync(async (req, res) => {
    const response = await getRecords(
      GET_STAFFS_HOLIDAYS_DETAILS_URL,
      apiQuery
    );
    console.log(response);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    getAllStaffHolidays();
  }, []);

  const holiday = "holiday";
  const sickLeave = "sickLeave";
  const absent = "absent";

  const holidayCounts = filterRecordsByCurrentMonthAndLabel(userData, holiday);
  const sickLeaveCounts = filterRecordsByCurrentMonthAndLabel(
    userData,
    sickLeave
  );
  const absentCounts = filterRecordsByCurrentMonthAndLabel(userData, absent);

  return (
    <>
      <div className="row">
        <div className="col-md-4 col-sm-4">
          <div className="stafOverviewBlocksContent greenContent">
            <div className="description">
              <h4>
                <small>This Month</small>
                Holiday
              </h4>
            </div>
            <div className="contentNumber">{holidayCounts?.length}</div>
          </div>
        </div>
        <div className="col-md-4 col-sm-4">
          <div className="stafOverviewBlocksContent warningContent">
            <div className="description">
              <h4>
                <small>This Month</small>
                Leaves
              </h4>
            </div>
            <div className="contentNumber">{absentCounts?.length}</div>
          </div>
        </div>
        <div className="col-md-4 col-sm-4">
          <div className="stafOverviewBlocksContent dangerContent">
            <div className="description">
              <h4>
                <small>This Month</small>
                Sick Leaves
              </h4>
            </div>
            <div className="contentNumber">{sickLeaveCounts?.length}</div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffOverviewBlocks;
