import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
// import PayrollBlocks from "./PayrollBlocks";
import ProgressComponent from "../../components/general/ProgressComponent";
import folderIcon from "../../assets/images/icons/folder.svg";

function AllDocuments() {
  const [search, setSearch] = useState("");
  const [payroll, setPayroll] = useState([]);
  const [filterSeach, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getPayroll = async () => {
    try {
      const response = await axios.get("https://dummyjson.com/users");
      setPayroll(response.data.users);
      setFilterSearch(response.data.users);
      setIsLoading(false);
    } catch (error) {}
  };

  const columns = [
    {
      name: "All Documents",
      selector: (row) => {
        return (
          <span className="usersNameInfo">
            <span className="iconImage">
              <img src={folderIcon} alt={`${row.firstName}`} />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row.company.name}`}</span>
            </span>
          </span>
        );
      },
      width: "85%",
    },

    {
      name: "",
      selector: (row) => {
        return row.birthDate;
      },
      center: true,
      width: "15%",
    },
  ];
  useEffect(() => {
    getPayroll();
  }, []);

  useEffect(() => {
    const result = payroll.filter((user) => {
      return user.firstName.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, payroll]);

  return (
    <>
      {/* <PayrollBlocks /> */}
      <div className="mainContent">
        <div className="dataTableWrapper">
          <DataTable
            className="maxWidthTable"
            columns={columns}
            data={filterSeach}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="600px"
            highlightOnHover
            noHeader
            responsive={true}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          />
        </div>
      </div>
    </>
  );
}

export default AllDocuments;
