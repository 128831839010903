import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import { Tab, Nav, Button } from "react-bootstrap";
import Location from "./Location";
import { GET_ALL_LOCATIONS_URL } from "../../../env";
import { getRecords, isSuccess } from "../../../helper/helper";
import ProgressComponent from "../../../components/general/ProgressComponent";
import PublishRota from "./PublishRota";

function RotaTemplateSchedule() {
  const storedRotaTemplateDetails = localStorage.getItem("RotaTemplateSchedule");
  const [rotaTemplateDetail, setRotaTemplateDetail] = useState(JSON.parse(storedRotaTemplateDetails));
  const [rotaLocationURL, setRotaLocationURL] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  // Getting Users records from DB
  function processFetchRecords(method, data) {
    if (method === "AllLocationsUrl" && data?.status === isSuccess) {
      const result = data?.data?.shiftLocations.map((item) => item);
      const validServiceUsers = result?.filter((item) => item?._id);
      setRotaLocationURL(validServiceUsers);
    } else if (data?.status !== isSuccess) {
      console.error(data);
    }
  }

  // Fetch all Location
  const fetchAllLocatinos = async () => {
    try {
      const query = {
        sortproperty: "createdAt",
        sortorder: 1,
        offset: 0,
        query: {
          critarion: {
            active: true,
          },
        },
      };
      const response = await getRecords(GET_ALL_LOCATIONS_URL, query);
      processFetchRecords("AllLocationsUrl", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllRecords = async () => {
    try {
      await fetchAllLocatinos();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const [publishDataTemplate, setPublishDataTemplate] = useState(false);
  const handleSuccess = () => {
    setPublishDataTemplate(true)
    setTimeout(() => {
      setPublishDataTemplate(false)
    }, 1000);
  }
  return (
    <Layout pageTitleText={`Rota Template: ${rotaTemplateDetail?.rotaTemplateTitle}`}>
      {
        isLoading ? (
          <div>
            <div className="mainContent minHeight p-0 mt-3 mb-3">
              <div className="subTabsWrapper">
                <ProgressComponent />
              </div>
            </div>
          </div>
        ) : (
          <div className="holidaysWrapper rosterWrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="tabsWrapper">
                  <Tab.Container id="myTab" defaultActiveKey="location01">
                    <div className="tabsHeader">
                      <div className="holidayHeaderStuff">
                        {rotaTemplateDetail?.status !== "active" ? (
                          <>
                            <div className="actionButtons">
                              <PublishRota publishDataTemplate={publishDataTemplate} onSuccess={handleSuccess} />
                            </div>
                          </>
                        ) : null}

                      </div>
                      <Nav variant="tabs">
                        {rotaLocationURL?.map((locationItem, locationIndex) => (
                          <Nav.Item key={locationIndex}>
                            <Nav.Link eventKey={`location0${locationIndex + 1}`}>
                              {locationItem?.shiftLocationName}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>

                    <Tab.Content>
                      {rotaLocationURL?.map((locationItem, locationIndex) => (
                        <Tab.Pane eventKey={`location0${locationIndex + 1}`} key={locationIndex}>
                          <div className="rosterTabsContent">
                            {isLoading || publishDataTemplate ? (
                              <div className="mainContent minHeight p-0 mt-3 mb-3">
                                <div className="subTabsWrapper">
                                  <ProgressComponent />
                                </div>
                              </div>
                            ) : (
                              <Location shiftLocationName={locationItem?.shiftLocationName} shiftLocationId={locationItem?._id} />
                            )}
                          </div>
                        </Tab.Pane>
                      ))}
                    </Tab.Content>
                  </Tab.Container>
                </div>
              </div>
            </div>
          </div>
        )
      }


    </Layout>
  );
}

export default RotaTemplateSchedule;
