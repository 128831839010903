import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import StatusUpdated from "../../components/general/StatusUpdated";
import {
  BASE_URL,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
  PUT_HOLIDAY_STAFF_MEMBER_URL,
  ADMIN_ID,
} from "../../env";
import {
  getRecords,
  updateRecords,
  isSuccess,
  formateDate,
  calculateHours,
  filterColumns,
  filterRowsByProperty,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
} from "../../helper/helper";

function HolidaysGroup() {
  const [isLoading, setIsLoading] = useState(true);
  const [userData, setUserData] = useState([]);
  const [isSuccessReturn, setIsSuccessReturn] = useState(false);
  const [isToastHolidaysGroups, setIsToastHolidaysGroups] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      staffRequesterFields: "_id email name profile_picture_url",
      staffMemberFields: "staffMemberName",
      usersFields: "profile_picture_url",
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastHolidaysGroups(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const userData = data?.data?.staffHolidayRequests || [];
      console.log(userData);
      setUserData(userData);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      fetchAllRecords();
      setIsToastHolidaysGroups(true);
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    try {
      const response = await getRecords(
        GET_STAFFS_HOLIDAYS_DETAILS_URL,
        apiQuery
      );
      processFetchRecords("get", response);
    } catch (error) {
      console.error("Error fetching staff holidays:", error);
    }
  });

  const updateStaffHoliday = catchAsync(async (req, res) => {
    try {
      console.log(req);
      const response = await updateRecords(PUT_HOLIDAY_STAFF_MEMBER_URL, req);
      processFetchRecords("update", response);
    } catch (error) {
      console.error("Error updating staff holidays:", error);
    }
  });

  const handleStatusChange = (event, statusValue, row) => {
    event.preventDefault();

    const startDate = row?.hldRqStartDate;
    const endDate = row?.hldRqEndDate;
    const totalDays = row?.hldRqTotalDays;
    const totalHours = row?.hldRqTotalHours;
    const holidayId = row?._id;
    const status = statusValue;
    const approveBy = ADMIN_ID;
    const staffId = row?.staffRequester?.modelId?._id;

    if (
      startDate &&
      endDate &&
      totalDays &&
      totalHours &&
      holidayId &&
      status &&
      approveBy &&
      staffId
    ) {
      const payload = {
        hldRqStartDate: startDate,
        hldRqEndDate: endDate,
        hldRqTotalDays: totalDays,
        hldRqTotalHours: totalHours,
        staffHolidayRequestid: holidayId,
        hldRqStatus: status,
        approvedBy: approveBy,
        staffRequester: staffId,
      };
      updateStaffHoliday(payload);
    } else {
      console.error("Invalid data for updating status.");
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const columns = [
    {
      name: "#",
      selector: (row, index) => <span>{index + 1}</span>,
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => (
        <span className="usersNameInfo">
          <span className="userImage">
            <img
              src={`${BASE_URL}${row?.staffRequester?.profile_picture_url}`}
              alt={row?.staffRequester?.name}
            />
          </span>
          <span className="userInfo">
            <span className="nameBlock">{row?.staffRequester?.name}</span>
          </span>
        </span>
      ),
      width: "200px",
    },
    {
      name: "Request",
      selector: (row) => {
        const formatEdDate = formateDate(row?.createdAt, "m/d");
        const formatEdHours = calculateHours(
          row?.hldRqStartDate,
          row?.hldRqEndDate
        );
        return (
          <span className="tableText danger">{`${formatEdDate} - ${formatEdHours} hours`}</span>
        );
      },
      width: "200px",
    },
    {
      name: "Remain Hours",
      selector: (row) => <span>{`${row?.hldRqTotalHours} hours`}</span>,
    },
    {
      name: "Carried Over",
      selector: (row) => <span>{`${row?.hldRqTotalHours} hours`}</span>,
    },
    {
      name: "Entitlement",
      selector: (row) => <span>{`${row?.hldRqTotalHours} hours`}</span>,
    },
    {
      name: "Allowance",
      selector: (row) => <span>{`${row?.hldRqTotalHours} hours`}</span>,
    },
    {
      name: "Status",
      selector: (row) => (
        <StatusUpdated
          initialStatus={row?.hldRqStatus}
          isSuccessReturn={isSuccessReturn}
          onChangeFun={(event, newStatus) =>
            handleStatusChange(event, newStatus, row)
          }
        />
      ),
      width: "12%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== "");
  const filteredRows = filterRowsByProperty(
    userData,
    (row) => row?.staffRequester?.name && row?.hldRqStatus !== undefined
  );

  return (
    <div className="mainContent rowClickUnset absenceLeave">
      <h3>Holidays & Absence</h3>
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable minWidthTable"
          columns={filteredColumns}
          data={filteredRows}
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={10}
          fixedHeader
          fixedHeaderScrollHeight="470px"
          highlightOnHover
          responsive={true}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
        />
      </div>
      {isToastHolidaysGroups && <ToastContainer />}
    </div>
  );
}

export default HolidaysGroup;
