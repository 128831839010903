import React from "react";
import Layout from "../../../components/layouts/Layout";
import UserDetailHeader from "../../../components/user-detail/UserDetailHeader";

import ServiceUserEnquiryList from "../enquiry/ServiceUserEnquiryList";
import CurrentServiceUsers from "../CurrentServiceUsers";
import PastServiceUsers from "../PastServiceUsers";
import { Link } from "react-router-dom";
import ProtectedComponent from "../../../permissions/ProtectedComponent";
import { permissions } from '../../../permissions/roles';
function ServiceUsers() {

  return (
    <>
      <Layout pageTitleText="ServiceUsers">
        <ProtectedComponent requiredPermissions={[permissions.ADD]}>
          <UserDetailHeader serviceUsers={true} />
        </ProtectedComponent>
        <div className="currentServiceUser">
          <header>
            <h3>Current Service User</h3>
            <Link to="/internal-reviews">View Internal Reviews</Link>
          </header>
          <div className="reviewsContentCards">
            <CurrentServiceUsers />
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="currentServiceUser newNpastServiceUser">
              <header>
                <h3>New Enquiries</h3>
                <Link to="/outstanding-request">
                  View Outstanding Requests
                </Link>
              </header>
              <ServiceUserEnquiryList />
            </div>
          </div>
          <div className="col-md-6">
            <div className="currentServiceUser newNpastServiceUser">
              <header>
                <h3>Past Service User</h3>
              </header>
              <div className="reviewsContentCards">
                <PastServiceUsers />
              </div>
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default ServiceUsers;
