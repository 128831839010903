import React, { useState, useEffect } from "react";
import avatarEdit from "../../assets/images/icons/avatarEdit.svg";
import avatarMessage from "../../assets/images/icons/avatarMessage.svg";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../env";
import { displayEnumProperty, formateDate } from "../../helper/helper";
import UserProfileUpdate from "../modals/UserProfileUpdate";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../general/ProgressComponent";
import {
  isSuccess,
  catchAsync,
  toastErrorMessages,
  filterRowsByProperty,
  getRecords,
} from "../../helper/helper";

function ProfileDetail({ getUserRecord }) {
  const [userData, setUserData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isToastProfileDetails, setIsToastProfileDetails] = useState(false);
  const [isShowModel, setIsShowModel] = useState(false);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true, _id: getUserRecord?._id },
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastProfileDetails(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const staffMember = data?.data?.staffMembers[0];
      setUserData(staffMember);
      setIsLoading(false);
    }
  };
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const handleIsShowModel = () => {
    fetchAllRecords();
    setIsShowModel(true);
  };
  const handleCloseModal = () => {
    setIsShowModel(false);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <div className="profileDetailBlock">
          <div className="userAvatar">
            <img
              src={BASE_URL + userData?.user?.profile_picture_url}
              alt={"profilePic"}
            />
            <div className="avatarAction">
              <span onClick={handleIsShowModel}>
                <img src={avatarEdit} alt=" avatar edit" />
              </span>
              <span>
                <img src={avatarMessage} alt="avatar message" />
              </span>
            </div>
            {userData?.user?.name && <h3>{userData?.user?.name}</h3>}
          </div>
          <div className="userDetail">
            <p>
              <small>Role</small>
              {displayEnumProperty("role", userData?.user?.role)}
            </p>
            <p>
              <small>Manager</small>
              {userData?.managerId?.name}
            </p>
            <p>
              <small>Email</small>
              <a href={`mailto:${userData?.user?.email}`} className="greyColor">
                <small>{userData?.user?.email}</small>
              </a>
            </p>
            <p>
              <small>Contact Number</small>
              {userData?.user?.phoneNumber}
            </p>
            <p>
              <small>Address</small> {userData?.user?.address}
            </p>
            <p>
              <small>Post Code</small> {userData?.user?.postCode}
            </p>
            <p>
              <small>Dob</small> {formateDate(userData?.stafDob, "m/d/y")}
            </p>
            <p>
              <small>NI Number</small>
              {userData?.niNumber}
            </p>
            <p>
              <small>Kin</small> {userData?.kin}
            </p>
          </div>
        </div>
      )}
      {isShowModel && (
        <UserProfileUpdate
          showModal={isShowModel}
          closeModal={handleCloseModal}
          userData={userData}
          fetchAllRecords={fetchAllRecords}
        />
      )}
      {isToastProfileDetails && <ToastContainer />}
    </>
  );
}

export default ProfileDetail;
