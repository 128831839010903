import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addPbsPlansStock } from "../../schemas";
import { useFormik } from "formik";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_PBS_PLANS_URL } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../components/forms-fields/TextArea";
import { Link } from "react-router-dom";
import Layout from "../../components/layouts/Layout";

function EditPbsPlans() {
  const storedPBSDetails = localStorage.getItem("pbsPlanDetail");
  const pbsPlansDetail = JSON.parse(storedPBSDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(pbsPlansDetail?.staffId?._id);
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("staffId", userId)
  };

  const editor = useRef(null);
  const [aboutPlan, setAboutPlan] = useState(pbsPlansDetail?.aboutPlan);
  const [managementOfBehaviorPlan, setManagementOfBehaviorPlan] = useState(pbsPlansDetail?.managementOfBehaviorPlan);
  const [secondaryPrevention, setSecondaryPrevention] = useState(pbsPlansDetail?.secondaryPrevention);
  const [reactiveStartegies, setReactiveStartegies] = useState(pbsPlansDetail?.reactiveStartegies);
  const [postIncidentSupport, setPostIncidentSupport] = useState(pbsPlansDetail?.postIncidentSupport);
  const [suFormData, setSuFormData] = useState({
    userId: pbsPlansDetail?.userId?._id,
    staffId: pbsPlansDetail?.staffId?._id,
    aboutPlan: aboutPlan,
    managementOfBehaviorPlan: managementOfBehaviorPlan,
    secondaryPrevention: secondaryPrevention,
    reactiveStartegies: reactiveStartegies,
    postIncidentSupport: postIncidentSupport,
    id: pbsPlansDetail?._id
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addPbsPlansStock,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });
  // console.log("🚀 ~ EditPbsPlans ~ values22222:", values)

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postEditPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "/pbs-plans", 500, 500);

      setIsSubmitButtonClicked(false);
      setTimeout(() => {
      }, 2000);
    }
  };

  const postEditPbsPlansFormData = catchAsync(async (req, res) => {
    const updatedValues = {
      ...values,
      aboutPlan: aboutPlan,
      managementOfBehaviorPlan: managementOfBehaviorPlan,
      secondaryPrevention: secondaryPrevention,
      reactiveStartegies: reactiveStartegies,
      postIncidentSupport: postIncidentSupport,

    }
    const response = await postRecords(UPDTE_PBS_PLANS_URL, updatedValues);
    console.log("🚀 ~ postEditPbsPlansFormData ~ updatedValues:", updatedValues)
    console.log("🚀 ~ postEditPbsPlansFormData ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postEditPbsPlansFormData", response);
  });


  // const handleChangeTextEditor = (newContent) => {
  //   setContent(newContent);
  // };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            PBS Plans
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent">
            <div className="mainContentText">
              <h3>Edit PBS Record</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="staffId"
                          onSelect={handleUserSelect}
                          filterBy={""}
                          defaultValue={values?.staffId}
                          label={"Select Staff"}
                          errorClass={isStaffId ? "validationError" : ""}
                          customError={isStaffId}
                          errorMessage={
                            isStaffId ? errorMessage : "Please select staff"
                          }
                        />
                      </div>
                    </div>
                    <div className="col-md-12">

                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark">
                          <h3>About Plan</h3>
                        </header>
                        <JoditEditor
                          name="aboutPlanEditor"
                          ref={editor}
                          value={values?.aboutPlan}
                          tabIndex={1}
                          onChange={(newContent) => { setAboutPlan(newContent) }}
                          onBlur={handleBlur}
                        />
                        {/* {errors.aboutPlanEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.aboutPlanEditor}</div>
                          </div>
                        )} */}


                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark mobpBg mobpBg">
                          <h3>Management of behavioural presentation</h3>
                        </header>
                        <JoditEditor
                          name="managementOfBehaviorPlanEditor"
                          ref={editor}
                          value={values?.managementOfBehaviorPlan}
                          tabIndex={1}
                          onChange={(newContent) => { setManagementOfBehaviorPlan(newContent) }}
                          onBlur={handleBlur}
                          className='mobp'
                        />
                        {/* {errors.managementOfBehaviorPlanEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.managementOfBehaviorPlanEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>

                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark spBg">
                          <h3>Secondary Prevention</h3>
                        </header>
                        <JoditEditor
                          name="secondaryPreventionEditor"
                          ref={editor}
                          value={values?.secondaryPrevention}
                          tabIndex={1}
                          onChange={(newContent) => { setSecondaryPrevention(newContent) }}
                          onBlur={handleBlur}
                          className='secondaryPrevention'
                        />
                        {/* {errors.secondaryPreventionEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.secondaryPreventionEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark rsBg">
                          <h3>Reactive Strategies</h3>
                        </header>
                        <JoditEditor
                          name="reactiveStartegiesEditor"
                          ref={editor}
                          value={values?.reactiveStartegies}
                          tabIndex={1}
                          onChange={(newContent) => { setReactiveStartegies(newContent) }}
                          onBlur={handleBlur}
                          className='reactiveStartegies'
                        />
                        {/* {errors.reactiveStartegiesEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.reactiveStartegiesEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>


                    <div className="col-md-12">
                      <div className="pbsFieldWrapper">
                        <header className="alert alert-dark pisrBg">
                          <h3>Post Incident Support- Recovery</h3>
                        </header>
                        <JoditEditor
                          name="postIncidentSupportEditor"
                          ref={editor}
                          value={values?.postIncidentSupport}
                          tabIndex={1}
                          onChange={(newContent) => { setPostIncidentSupport(newContent) }}
                          onBlur={handleBlur}
                          className='pisr'
                        />
                        {/* {errors.postIncidentSupportEditor && (
                          <div className="validationError">
                            <div className="text-danger">{errors.postIncidentSupportEditor}</div>
                          </div>
                        )} */}
                      </div>
                    </div>

                  </div>
                </div>

                <div className="formActionButtons mt-5">
                  <Link to="/pbs-plans">
                    <span
                      className="btn btn-sm btn-outline-primary"
                    >
                      Cancel
                    </span>
                  </Link>
                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default EditPbsPlans;