import React from "react";

function ProgressComponent({ extraClasses }) {
  return (
    <>
      <div className={`preloader-container ${extraClasses}`}>
        <div className="preloader-spinner"></div>
      </div>
    </>
  );
}

export default ProgressComponent;
