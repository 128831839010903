import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addMoodRating } from "../../../../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_CARE_PLAN_URL, ADMIN_ID } from "../../../../../../env";
import ReactDOMServer from 'react-dom/server';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
import { Form, Table, OverlayTrigger, Tooltip } from "react-bootstrap";


import actionButtonDel from "../../../../../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../../../../../assets/images/icons/actionButtonTick.svg";

import Angry from "../../../../../../assets/images/mood-rating/Angry.svg";
import Bored from "../../../../../../assets/images/mood-rating/Bored.svg";
import Calm from "../../../../../../assets/images/mood-rating/Calm.svg";
import Confident from "../../../../../../assets/images/mood-rating/Confident.svg";
import Excited from "../../../../../../assets/images/mood-rating/Excited.svg";
import Happy from "../../../../../../assets/images/mood-rating/Happy.svg";
import Hopeful from "../../../../../../assets/images/mood-rating/Hopeful.svg";
import Nervous from "../../../../../../assets/images/mood-rating/Nervous.svg";
import Proud from "../../../../../../assets/images/mood-rating/Proud.svg";
import Relaxed from "../../../../../../assets/images/mood-rating/Relaxed.svg";
import Sad from "../../../../../../assets/images/mood-rating/Sad.svg";
import Scared from "../../../../../../assets/images/mood-rating/Scared.svg";
import Tired from "../../../../../../assets/images/mood-rating/Tired.svg";
import Worried from "../../../../../../assets/images/mood-rating/Worried.svg";

function AddMoodRating() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(ADMIN_ID);
  console.log("🚀 ~ AddMoodRating ~ selectedUserId:", selectedUserId)
  const [moodRatingIndex, setMoodRatingIndex] = useState(null);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedby", userId);
  };

  const [suFormData, setSuFormData] = useState({
    userId: userDetails?._id,
    addedby: selectedUserId,
    noteType: "MoodRating",
    title: "",
    flag: false,
    noteDetails: "",
    moodRating: moodRatingIndex,
    eventDateTime: moment().valueOf(),

  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addMoodRating,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      ...values,
      noteDetails: "Mood Rating is " + moodRatingIndex + " at " + moment(values.eventDateTime).format('hh:mm A') + " on " + moment(values.eventDateTime).format('DD/MM/YYYY'),
      title: "Mood Rating is " + moodRatingIndex,
      moodRating: moodRatingIndex,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }
    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_CARE_PLAN_URL, updateValues);
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };

  useEffect(() => {
  }, [values])
  const [htmlData, setHtmlData] = useState("")

  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-6">
          <p>Duration of incident (Mins)<br />
            <span id="durationOfIncidentsData">{values?.durationOfIncidents}</span></p>
        </div>
      </>
    );
  }, [values]);


  // how parent contact check boxes list
  const [moodRatingSmileys, setMoodRatingSmileys] = useState([
    { id: 1, values: "Angry", label: "Angry", imgPath: Angry, checked: false },
    { id: 2, values: "Bored", label: "Bored", imgPath: Bored, checked: false },
    { id: 3, values: "Calm", label: "Calm", imgPath: Calm, checked: false },
    { id: 4, values: "Confident", label: "Confident", imgPath: Confident, checked: false },
    { id: 5, values: "Excited", label: "Excited", imgPath: Excited, checked: false },
    { id: 6, values: "Happy", label: "Happy", imgPath: Happy, checked: false },
    { id: 7, values: "Hopeful", label: "Hopeful", imgPath: Hopeful, checked: false },
    { id: 8, values: "Nervous", label: "Nervous", imgPath: Nervous, checked: false },
    { id: 9, values: "Proud", label: "Proud", imgPath: Proud, checked: false },
    { id: 10, values: "Relaxed", label: "Relaxed", imgPath: Relaxed, checked: false },
    { id: 11, values: "Sad", label: "Sad", imgPath: Sad, checked: false },
    { id: 12, values: "Scared", label: "Scared", imgPath: Scared, checked: false },
    { id: 13, values: "Tired", label: "Tired", imgPath: Tired, checked: false },
    { id: 14, values: "Worried", label: "Worried", imgPath: Worried, checked: false },
  ]);


  /*<div className='col text-center'>
  <input
    type="radio"
    name="mood"
    id="mood"
    className="d-none imgbgchk"
    value={1}
  // onChange={() => handleSurveyChange(question?._id, 1)}
  />
  <label htmlFor="mood">
    <img src={Inadequate} alt={Inadequate} className='Inadequate' />
    <span>Inadequate</span>
  </label>
</div> */

  return (

    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Add Mood Rating</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-12 mb-3">
                      <div className="emojiRadioButtons moodRatingButtons">
                        {

                          moodRatingSmileys?.map((items, index) => (
                            <div className='col text-center'>
                              <input
                                type="radio"
                                name="mood"
                                id={`rating-${items?.id}`}
                                className="d-none imgbgchk"
                                value={items?.values}
                                onChange={() => setMoodRatingIndex(items?.values)}
                              />
                              <label htmlFor={`rating-${items?.id}`}>
                                <img src={items?.imgPath} alt={items?.label} className={items?.label} />
                                <span>{items?.label}</span>
                              </label>
                            </div>
                          ))
                        }

                      </div>

                    </div>
                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>

              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default AddMoodRating