import React, { useEffect, useState } from "react";
import Layout from "../../components/layouts/Layout";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate, Link, useLocation } from "react-router-dom";
import ToggleSwitchButton from "../../components/general/ToggleSwitchButton";
import PublishRotaTemplate from "./popup/PublishRotaTemplate";
import WeeklyLocationTabs from "./tabs/WeeklyLocationTabs";
import CreateTemplateEditMode from "./popup/CreateTemplateEditMode";
import ParentTab from "./ParentTab";
import {
  ADMIN_ID,
  GET_ALL_LOCATIONS_URL,
  POST_NEW_ROTA_TEMPLATE_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  postRecords,
  toastSuccessMessages,
} from "../../helper/helper";
import ProgressComponent from "../../components/general/ProgressComponent";

function RotaSchedule({
  scheduleTemplateData,
  isLoadingScheduleState,
  fetchAllTempRecords,
}) {
  const location = useLocation();
  const activeWeekTabNum = location?.state && location?.state?.activeWeekTab;
  const [allLocations, setAllLocations] = useState([]);
  const [isToast, setIsToast] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultKeyLocation, setDefaultKeyLocation] = useState("");
  const [isCheckedState, setIsCheckedState] = useState(false);
  const [showAddRotaTemplate, setShowAddRotaTemplate] = useState(false);
  const [showPublishScheduleModal, setShowPublishScheduleModal] =
    useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: 1,
    offset: 0,
    query: {
      critarion: {
        active: true,
      },
    },
  };

  function processFetchRecords(method, data) {
    if (data?.status !== isSuccess) {
      console.error(data);
      setIsLoading(false);
    } else if (method === "get" && data?.status === isSuccess) {
      const response = data?.data?.shiftLocations?.map((item) => item);
      const initialResponse = response && response[0];
      setDefaultKeyLocation(
        initialResponse && initialResponse?.shiftLocationName
      );
      setAllLocations(response);
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      setIsToast(true);
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
    }
  }

  const fetchAllLocations = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_LOCATIONS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const handleChangeTempStatus = catchAsync(async (req, res) => {
    const response = await postRecords(POST_NEW_ROTA_TEMPLATE_URL, req);
    processFetchRecords("update", response);
  });

  const handleSaveTemplate = (e, data) => {
    e?.preventDefault();
    const initialDocument = {
      rotaTemplateTitle: data?.rotaTemplateTitle,
      templateWeeks: data?.templateWeeks,
      status: "saved",
      startDate: 0,
      endDate: 0,
      shiftLocation: data?.shiftLocation,
      addedby: ADMIN_ID,
    };
    handleChangeTempStatus(initialDocument);
  };

  const handleSwitchChange = async (newCheckedValue) => {
    setIsCheckedState(newCheckedValue);
    setShowAddRotaTemplate(scheduleTemplateData);
  };

  const handleHideAddNewTemplate = () => {
    setShowAddRotaTemplate(false);
  };

  const handleShowPublishScheduleModal = (e) => {
    e?.preventDefault();
    setShowPublishScheduleModal(true);
  };

  const handleClosePublishScheduleModal = () => {
    setIsCheckedState(false);
    setShowPublishScheduleModal(false);
  };

  useEffect(() => {
    fetchAllLocations();
  }, []);

  return (
    <Layout pageTitleText="Rota Template">
      {isLoading ? (
        <ProgressComponent />
      ) : (
        allLocations?.length > 0 && (
          <div className="holidaysWrapper rosterWrapper">
            <div className="row">
              <div className="col-md-12">
                <div className="tabsWrapper">
                  {defaultKeyLocation !== "" && (
                    <Tab.Container
                      id="myTabss"
                      defaultActiveKey={defaultKeyLocation}
                    >
                      <div className="tabsHeader">
                        <div className="holidayHeaderStuff">
                          <div className="actionButtons">
                            {/* <Button
                              className="btn btn-sm btn-primary"
                              onClick={(e) =>
                                handleSaveTemplate(e, scheduleTemplateData)
                              }
                            >
                              Save
                            </Button> */}

                            {scheduleTemplateData?.status !== "active" ? (
                              <>
                                &nbsp;
                                <Button
                                  className="btn btn-sm btn-primary"
                                  onClick={(e) =>
                                    handleShowPublishScheduleModal(e)
                                  }
                                >
                                  Publish
                                </Button>
                              </>
                            ) : (
                              <ToggleSwitchButton
                                labelOn="Edit Mode On"
                                labelOff="Edit Mode Off"
                                initialChecked={isCheckedState}
                                onChange={handleSwitchChange}
                              />
                              // <Button
                              //   className="btn btn-sm btn-primary"
                              //   onClick={(e) =>
                              //     handleShowPublishScheduleModal(e)
                              //   }
                              // >
                              //   Future Publish
                              // </Button>
                            )}
                          </div>
                        </div>
                        {allLocations?.length > 0 && (
                          <Nav variant="tabs">
                            {allLocations?.map((item, index) => (
                              <Nav.Item key={index}>
                                <Nav.Link eventKey={item?.shiftLocationName}>
                                  {item?.shiftLocationName}
                                </Nav.Link>
                              </Nav.Item>
                            ))}
                          </Nav>
                        )}
                      </div>
                      <Tab.Content>
                        {allLocations?.map((item) => (
                          <Tab.Pane eventKey={item?.shiftLocationName}>
                            <>
                              <ParentTab
                                getInitialLocationId={item?._id}
                                templateDataState={scheduleTemplateData}
                                templateWeekNum={
                                  scheduleTemplateData?.templateWeeks
                                }
                                activeWeekTabNum={activeWeekTabNum}
                                fetchAllTempRecords={fetchAllTempRecords}
                              />
                              <WeeklyLocationTabs
                                getInitialLocationId={item?._id}
                                templateDataState={scheduleTemplateData}
                                templateWeekNum={
                                  scheduleTemplateData?.templateWeeks
                                }
                                activeWeekTabNum={activeWeekTabNum}
                                isLoadingScheduleState={isLoadingScheduleState}
                                fetchAllTempRecords={fetchAllTempRecords}
                              />
                            </>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  )}
                </div>
              </div>
            </div>
          </div>
        )
      )}

      {showPublishScheduleModal && scheduleTemplateData && (
        <PublishRotaTemplate
          handleShowModel={showPublishScheduleModal}
          handleCloseModel={handleClosePublishScheduleModal}
          tempData={scheduleTemplateData}
          fetchAllRecords={fetchAllTempRecords}
          scheduleTitle={"Publish"}
        />
      )}
      {showAddRotaTemplate && (
        <CreateTemplateEditMode
          handleShowModel={showAddRotaTemplate}
          handleCloseModel={handleHideAddNewTemplate}
          tempData={showAddRotaTemplate}
          fetchAllRecords={fetchAllTempRecords}
        />
      )}
      {isToast && <ToastContainer />}
    </Layout>
  );
}

export default RotaSchedule;
