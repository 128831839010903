import React from 'react'
import ftcLogo from '../../../assets/images/ftc_logo.svg';

function EmptyChatScreen({ toogleButton }) {
  return (
    <>
      <div className="emptyChatRoom">
        {toogleButton && (
          <>
            <header>
              {toogleButton}
            </header>
          </>
        )}

        <div className="chatBoxContainer">
          <img src={ftcLogo} alt="FTC Logo" className="FTCLogo" />
          <p>Start a conversation to connect with your team.<br />Your messages will appear here.</p>
        </div>
      </div>
    </>
  )
}

export default EmptyChatScreen