import React from "react";
import { Modal, Button } from "react-bootstrap";
import { BASE_URL } from "../../env";

const ItemListModal = ({ handleShowModel, onClose, items, status }) => {
  return (
    <Modal show={handleShowModel} onHide={onClose} className="ftcModalPopup">
      <Modal.Header closeButton>
        <Modal.Title>{`${status} Users Listings`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          {items?.users?.map((item, index) => (
            <div className="usersNameInfo mt-1 col-6" key={index}>
              <span className="userImage">
                <img
                  src={`${BASE_URL}${item?.user?.profile_picture_url}`}
                  alt={item?.user?.name}
                  style={{ maxWidth: "100%" }}
                />
              </span>
              <span className="userInfo">
                <span className="nameBlock">{`${item?.user?.name}`}</span>
              </span>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button className="btn-sm" variant="outline-primary" onClick={onClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ItemListModal;
