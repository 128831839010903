import React, { useEffect, useState } from "react";
import Select from "react-select";
import ProgressComponent from "../../components/general/ProgressComponent";
import { useNavigate } from "react-router-dom";

function RotaWeeksDropDown({
  selectedLocationId,
  templateWeekNum,
  activeWeekTabNum,
}) {
  const navigate = useNavigate();
  const [selectedWeeks, setSelectedWeeks] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultWeek, setDefaultWeek] = useState([]);

  const handleWeekChange = (selectedOption) => {
    const weekNumber = parseInt(selectedOption?.value);
    setDefaultWeek(weekNumber);
    navigateState(weekNumber);
  };

  useEffect(() => {
    const numberOfWeeks = templateWeekNum && templateWeekNum;

    const weeks = [];
    for (let week = 1; week <= numberOfWeeks; week++) {
      weeks?.push({
        label: `Week ${week}`,
        value: week?.toString(),
      });
    }

    if (weeks?.length > 0) {
      setSelectedWeeks(weeks);
      setDefaultWeek(weeks[0]);
      navigateState(1);
      setIsLoading(false);
    }
  }, []);

  function navigateState(activeWeekNum) {
    navigate("#", { state: { activeWeekTab: activeWeekNum } });
  }

  return (
    <>
      <div className="customSelectBox">
        {isLoading ? (
          <ProgressComponent />
        ) : (
          <div className="weekSelector selectWeekWidthRota">
            <Select
              className="basic-multi-select cursor-pointer"
              classNamePrefix="select"
              defaultValue={defaultWeek}
              options={selectedWeeks}
              isSearchable
              onChange={handleWeekChange}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default RotaWeeksDropDown;
