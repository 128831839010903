import React, { useEffect, useState, useRef } from "react";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from "../../../components/user-detail/UserDetailReview";
import Table from 'react-bootstrap/Table';
import moment from 'moment';
import actionButtonDel from "../../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../../assets/images/icons/actionButtonTick.svg";
import { useNavigate } from "react-router-dom";
import { PDFExport, savePDF } from "@progress/kendo-react-pdf";
import '@progress/kendo-theme-default/dist/all.css';
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
function ViewIndividualReviews() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const userReviewDetails = localStorage.getItem("userReviewData");
  const userReviewData = JSON.parse(userReviewDetails);
  const navigate = useNavigate();
  // console.log("🚀 ~ userReviewData ~ userReviewData:", userReviewData)



  const pdfExportComponent = useRef(null);
  const [showInPrint, setShowInPrint] = useState(false);
  const exportPDF = () => {
    if (pdfExportComponent.current) {
      setShowInPrint(true);
      setTimeout(() => {
        pdfExportComponent.current.save();
      }, 100);
      setTimeout(() => {
        setShowInPrint(false);
      }, 500);


    }
  };
  return (
    <>

      <Layout
        pageTitleText={
          <>
            Detail <small>Internal Reviews</small>
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="mainContent mt-3">
          <header className="reviewListHeader mt-2" style={{ paddingRight: "20px" }}>
            <div className="actionColumns">
              <span className="btn btn-sm btn-outline-primary" onClick={exportPDF}>Export to PDF</span>

              <span className="btn btn-sm btn-primary" onClick={() => {
                navigate("/individual-reviews");
              }}>Back to Review List</span>
              <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                <span className="btn btn-sm btn-secondary" onClick={() => {
                  navigate("/edit-reviews");
                }}>Edit</span>
              </ProtectedComponent>
            </div>
          </header>
          <PDFExport
            ref={pdfExportComponent}
            paperSize={["900pt", "900pt"]}
            margin={40}
            fileName={`Internal Review for ${userData?.name}  ${userData?.suLastName} by ${userReviewData?.commentBy?.name}`}
          >
            <div className="reviewContent userDetailContainer">

              {/* Step 1 Data */}
              <div className='wizardSteps step1'>
                {/* Personal Detail  */}
                <div className="personalDetail">
                  {
                    showInPrint && (
                      <div className="showInPrint">
                        <p className="m-0">
                          <small className="m-0">Internal Reviews</small>
                          Service User: <strong>{userData?.name} {userData?.modelId?.suLastName}</strong>
                        </p>
                      </div>
                    )
                  }
                  <header className="mainContentHeader">
                    <h3>Personal Detail</h3>

                  </header>
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Review By</small>
                        {userReviewData?.commentBy?.name}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Locality</small>
                        {userReviewData?.personalDetails?.locality}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Liquid Logic</small>
                        {userReviewData?.personalDetails?.liquidLogic}
                      </p>
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>DOLS Applicable</small>
                        {
                          userReviewData?.personalDetails?.dolsApplicable ? "Yes" : "No"
                        }
                      </p>
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Date of Review</small>
                        {moment(userReviewData?.personalDetails?.dor).format('DD/MM/YYYY')}
                      </p>
                    </div>
                  </div>
                </div>
                {/* MDT Details */}
                <div className="mdtDetails">
                  <header className="mainContentHeader">
                    <h3>MDT Details</h3>
                  </header>
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Nominated Individual</small>
                        {userReviewData?.mdtDetails?.nominatedIndividual}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Service Manager</small>
                        {userReviewData?.mdtDetails?.suManager}
                      </p>

                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Deputy Service Manager (s)</small>
                        {userReviewData?.mdtDetails?.dptSuManager}
                      </p>
                    </div>



                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Social Worker</small>
                        {userReviewData?.mdtDetails?.socWorker}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Family</small>
                        {userReviewData?.mdtDetails?.family}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Other</small>
                        {userReviewData?.mdtDetails?.other}
                      </p>
                    </div>
                  </div>
                </div>
                {/* ICD 10 Diagnosis & any other */}
                < div className="diagnosisOther" >
                  <header className="mainContentHeader">
                    <h3>ICD 10 Diagnosis & any other</h3>
                  </header>
                  <div className="row">
                    <div className="col-sm-12 mt-2">
                      <p>
                        {userReviewData?.icDiagnosisOther}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Please Tick Support Area(s) Applicable */}
                < div className="supportArea" >
                  <header className="mainContentHeader">
                    <h3>Support Area(s) Applicable</h3>
                  </header>
                  <div className="row">
                    <div className="col-md-12">
                      <ul className="supportAbleArea">
                        {
                          userReviewData?.supportAreas.map((row, index) => {
                            return (
                              <>
                                {row?.checked ? (
                                  <>
                                    <li>
                                      <span className="mt-2">{row?.values}</span>
                                    </li>
                                  </>
                                ) : null}
                              </>
                            )
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* Step 2 Data */}
              < div className='wizardSteps step2' >

                {/* Medication (Dose, frequency) */}
                < div className="medicationDoseFrequency" >
                  <header className="mainContentHeader">
                    <h3>Medication (Dose, frequency)</h3>
                  </header>
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Nominated Individual</small>
                        {userReviewData?.medications?.regular}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Nominated Individual</small>
                        {userReviewData?.medications?.prn}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Nominated Individual</small>
                        {userReviewData?.medications?.physicalHealth}
                      </p>
                    </div>
                  </div>
                  <div className="actionsPoint">
                    <div className="table-responsive">
                      <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Action Points from previous meeting</th>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Achieved?</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            userReviewData?.actionPoints.map((row, index) => {
                              return (
                                <>
                                  <tr>
                                    <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                      <span className="mt-2">{row?.action}</span>
                                    </td>
                                    <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                      <span className="mt-2">
                                        {
                                          row?.achieved ? "Yes" : "No"
                                        }
                                      </span>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}

                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {/* Deputy Feedback */}
                < div className="deputyFeedback" >
                  <header className="mainContentHeader">
                    <h3>Deputy Feedback</h3>
                  </header>
                  <div className="row">

                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Legal Status</small>
                        {userReviewData?.legalStatus?.regular}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Mood & Mental State</small>
                        {userReviewData?.legalStatus?.mood_mentalState}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Interactions & Engagement</small>
                        {userReviewData?.legalStatus?.interactions}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Diet/ Fluid Intake</small>
                        {userReviewData?.legalStatus?.diet}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Medication Concordance</small>
                        {userReviewData?.legalStatus?.medConcordance}
                      </p>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p>
                        <small>Sleep</small>
                        {userReviewData?.legalStatus?.sleep}
                      </p>
                    </div>
                  </div>
                </div>
                {/* Any Incident/ABC logged since last internal review */}
                < div className="anyIncident" >
                  <header className="mainContentHeader">
                    <h3>Any Incident/ABC logged since last internal review</h3>
                  </header>

                  <div className="incidentsPoint">
                    <div className="table-responsive">
                      <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Date</th>
                            <th className="leftAlignText" style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Incident/ABC</th>
                          </tr>
                        </thead>
                        <tbody>

                          {
                            userReviewData?.incidentLogged.map((row, index) => {
                              return (
                                <>
                                  <tr>
                                    <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                      <span className="mt-2">{moment(row?.date).format('DD/MM/YYYY')}</span>
                                    </td>
                                    <td className="leftAlignText" style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                      <span className="mt-2">{row?.incident}</span>
                                    </td>
                                  </tr>
                                </>
                              )
                            })}

                        </tbody>
                      </Table>
                    </div>
                  </div>

                  <div className="row">
                    <div className="col-sm-12">
                      <p>
                        <small>Additional Comments</small>
                        {userReviewData?.comments}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Step 3 Data */}
              < div className='wizardSteps step3' >

                {/* Manager Feedback */}
                < div className="managerFeedback" >
                  <header className="mainContentHeader">
                    <h3>Manager Feedback</h3>
                  </header>
                  <div className="row">
                    <div className="col-sm-12">
                      <p>
                        <small>Comments</small>
                        {userReviewData?.managerFeedbackComments}
                      </p>

                    </div>
                  </div>
                </div>
                {/* Key Checks by Team */}
                < div className="keyChecksWrapper" >
                  <header className="mainContentHeader">
                    <h3>Key Checks by Team</h3>
                  </header>
                  <div className="keyPoints">
                    <div className="table-responsive">
                      <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Requirement</th>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}></th>
                          </tr>
                        </thead>
                        <tbody>


                          {userReviewData?.keyCheckByTeam.map((row, index) => (
                            <tr key={row.id}>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>{row?.requirements}</td>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {
                                  row?.fullfil ? (
                                    <>
                                      <img
                                        src={actionButtonTick}
                                        alt="Tick Answer"
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <img
                                        src={actionButtonDel}
                                        alt="Close"
                                      />
                                    </>
                                  )
                                }
                              </td>
                            </tr>
                          ))}


                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {/* Education Feedback */}
                < div className="educationFeedback" >
                  <header className="mainContentHeader">
                    <h3>Education Feedback</h3>
                  </header>
                  <div className="row">
                    <div className="col-sm-12">
                      <p>
                        <small>Comments</small>
                        {userReviewData?.educationFeedbackComments}
                      </p>

                    </div>
                  </div>
                </div>
              </div>
              {/* Step 4 Data */}
              < div className='wizardSteps step4' >

                {/* Engagement */}
                < div className="engagement" >
                  <header className="mainContentHeader">
                    <h3>Engagement</h3>
                  </header>
                  <div className="row">
                    <div className="col-sm-6">
                      <p>
                        <small>Activity / social engagement</small>
                        {userReviewData?.engagement?.activityOrSocial}
                      </p>


                    </div>
                    <div className="col-sm-6">
                      <p>
                        <small>Engagement in therapeutic timetable</small>
                        {userReviewData?.engagement?.engInTheraputicTimeTable}
                      </p>


                    </div>
                  </div>
                </div>
                {/* Social Work Feedback */}
                < div className="socialWorkFeedback" >
                  <header className="mainContentHeader">
                    <h3>Social Work Feedback</h3>
                  </header>
                  <div className="row">
                    <div className="col-sm-6">
                      <p>
                        <small>Involvement</small>
                        {userReviewData?.socialWorkFeedback?.involvement}
                      </p>
                    </div>
                    <div className="col-sm-6">
                      <p>
                        <small>Safeguarding</small>
                        {userReviewData?.socialWorkFeedback?.safeguarding}
                      </p>


                    </div>
                  </div>
                </div>
                {/* Family Feedback */}
                < div className="familyFeedback" >

                  <div className="row">

                    <div className="col-sm-6">
                      <header className="mainContentHeader">
                        <h3>Family Feedback</h3>
                      </header>
                      <p>
                        <small>Family Feedback</small>
                        {userReviewData?.familyFeedback?.involvement}
                      </p>

                    </div>
                    <div className="col-sm-6">
                      <header className="mainContentHeader">
                        <h3>MDT Discussion/Comments</h3>
                      </header>
                      <p>
                        <small>MDT Discussion/Comments</small>
                        {userReviewData?.mdtDiscussions?.safeguarding}
                      </p>

                    </div>
                  </div>
                </div>
                {/* Risk Assessment */}
                < div className="riskAssementReview" >
                  <header className="mainContentHeader">
                    <h3>Risk Assessment</h3>
                  </header>
                  <div className="riskAssesmentsPoints">
                    <div className="table-responsive">
                      <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                        <thead>
                          <tr>
                            <th colSpan={2} className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Adverse Outcomes</th>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Threat</th>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Future Risk</th>
                          </tr>
                        </thead>
                        <tbody>

                          {userReviewData?.riskAssesments.map((row, index) => (
                            <tr>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>{index + 1}</td>
                              <td className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>{row?.outcomes}</td>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                <span className={
                                  row?.threat === "Yes" ? "btn btn-sm btn-success" : "btn btn-sm btn-danger"
                                }>
                                  {row?.threat}
                                </span>
                              </td>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                <span className={
                                  row?.futureRisk === "High" ? "btn btn-sm btn-danger" : row?.futureRisk === "Medium" ? "btn btn-sm btn-warning" : "btn btn-sm btn-success"
                                }>
                                  {row?.futureRisk}
                                </span>
                              </td>
                              {/* <td><img className='removeRow' src={eventClose} alt="Close" onClick={() => { removeRiskAssesmentsPoints(row.id) }} /></td> */}
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <p>
                        <small>Risk Assesment Comments</small>
                        {userReviewData?.riskAssesmentComments}
                      </p>

                    </div>
                  </div>
                </div>
              </div>
              {/* Step 5 Data */}
              < div className='wizardSteps step5' >

                {/* Summary of plan agreed based on risk and progress */}
                < div className="summaryOfPlans" >
                  <header className="mainContentHeader">
                    <h3>Summary of plan agreed based on risk and progress</h3>
                  </header>
                  {/* Summary of Plan Points */}
                  <div className="summaryOfPlansPoints">
                    <div className="table-responsive">
                      <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Action Points from previous meeting</th>
                            <th className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Achieved?</th>

                          </tr>
                        </thead>
                        <tbody>
                          {userReviewData?.summaryOfPlan.map((row, index) => (
                            <tr>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {row.plan}
                              </td>
                              <td className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {row.comment}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>

                  {/* My Say Request */}
                  <div className="mySayRequestPoints">
                    <div className="table-responsive">
                      <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>My Say Requests</th>
                            <th className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Discussion/Agreement</th>

                          </tr>
                        </thead>
                        <tbody>
                          {userReviewData?.requests.map((row, index) => (
                            <tr>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {row.myRequests}
                              </td>
                              <td className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {row.discussion}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {/* Agreed Actions */}
                < div className="agreedActionWrapper" >
                  <header className="mainContentHeader">
                    <h3>Agreed Actions</h3>
                  </header>
                  <div className="agreedActionsPoints">
                    <div className="table-responsive">
                      <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                        <thead>
                          <tr>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Action</th>
                            <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Responsible Person</th>
                            <th className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>To complete by</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userReviewData?.agreedActions.map((row, index) => (
                            <tr>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {row.action}
                              </td>
                              <td style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {row.resonsiblePerson}
                              </td>
                              <td className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                                {row.completedBy}
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </div>
                  </div>
                </div>
                {/* Management Use only */}
                {
                  userReviewData?.reviewApprovedBy?.active === true && (
                    <>
                      <div className="managementUseOnly" >
                        <header className="mainContentHeader">
                          <h3>Review Approval</h3>
                        </header>
                        <div className="managementUseOnlyPoints">
                          <div className="table-responsive">
                            <Table striped bordered hover className='dynamicRows' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>
                              <thead>
                                <tr>
                                  <th style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Approved by</th>
                                  <th className='leftAlignText' style={{ border: "1px solid rgba(0,0,0,0.1)" }}>Email</th>

                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>{userReviewData?.reviewApprovedBy?.name}</td>
                                  <td className='leftAlignText'>{userReviewData?.reviewApprovedBy?.email}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </>
                  )
                }

              </div>
            </div>
          </PDFExport>
        </div>
      </Layout >
    </>
  );
}

export default ViewIndividualReviews;