import React from 'react'
import Layout from "../../../../../../components/layouts/Layout";
import EditCareNotes from "../../../EditCareNotes";


function EditTelephoneCalls() {
  return (
    <Layout pageTitleText="Notes">
    <div className="incidents consentCapacityAddNew">
      <div className="mainContent ">
        <div className="mainContentText">
          <h3>Edit TelephoneCalls note</h3>
          <EditCareNotes noteType="TelephoneCalls" parentListNoteUrl="/care-plan/general-notes" />
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default EditTelephoneCalls