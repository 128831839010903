// addNewChatSingleUserReducer.jsx
import { SELECTED_STAFF_MEMBER } from "../constants";
const initialState = {
  memberData: null,
};
const addNewChatSingleUserReducer = (state = initialState, action) => {
  switch (action.type) {
    case SELECTED_STAFF_MEMBER:
      return {
        ...state,
        memberData: action.payload,
      };
    default:
      return state;
  }
};
export default addNewChatSingleUserReducer;