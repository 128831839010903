import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

function Input({
  type,
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  labelText,
  readOnly,
  errorClass,
  customError,
  errorMessage,
  requiredStar,
  disabledStatus,
  marginBottom,
  hidden,
  hiddenField,
  min,
  max,
  pattern,
  style,
  accept,
  selected,
  datePicker
}) {
  return (
    <div className={`form-group ${errorClass ? "validationError" : ""} ${marginBottom ? "mb-2" : ""} ${hidden ? "hiddenDiv" : ""}`}>
      {
        datePicker ? (
          <DatePicker
            selected={selected}
            dateFormat="dd/MM/yyyy"
            onChange={onChange}
            className="datePicker"
            // isClearable={false}
            // readOnly={true}
            disabled={disabledStatus}
          />
        ) : (
          <>
            <input
              type={type}
              id={id}
              name={name}
              placeholder={placeholder}
              value={value}
              onChange={onChange}
              required={required}
              readOnly={readOnly}
              onBlur={onBlur}
              disabled={disabledStatus}
              min={min}
              accept={accept}
              max={max}
              pattern={pattern}
              style={style}
              hidden={hiddenField}
            />
            <label htmlFor={id} className="floatingLabel">
              {labelText}
              {requiredStar ? <span className="text-danger">*</span> : null}
            </label>
            {customError ? <div className="text-danger">{errorMessage}</div> : null}
          </>
        )
      }

    </div>
  );
}

export default Input;
