import React, { useState, useEffect } from "react";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from "../../../components/user-detail/UserDetailReview";
import { Link, useNavigate } from "react-router-dom";
import questionEdit from "../../../assets/images/icons/questionEdit.svg";
import deleteBasket from "../../../assets/images/icons/deleteBasket.svg";
import password_eye_icon from "../../../assets/images/icons/password_eye_icon.svg";
import actionButtonDel from "../../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../../assets/images/icons/actionButtonTick.svg";
import eventDel from "../../../assets/images/icons/eventDel.svg";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  BASE_URL,
  GET_ALL_SERVICE_USERS_REVIEW_LIST,
  REMOVE_SERVICE_USERS_REVIEW,
  UPDATE_SERVICE_USERS_REVIEW,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  updateRecords,
  isManager,
  isAdmin,
  isUser,
} from "../../../helper/helper";
import ProgressComponent from "../../../components/general/ProgressComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextArea from "../../../components/forms-fields/TextArea";
import AddReview from "./AddReview";
import IsReviewedApproved from "./IsReviewedApproved";

function IndividualReviews() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);

  // const location = useLocation();
  // const userData = location?.state ? location?.state?.userDetails : "";
  const initialUserData = userData;
  console.log("🚀 ~ IndividualReviews ~ initialUserData:", initialUserData)
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [dataID, setDataID] = useState({ reviewId: null });
  const [isEditable, setIsEditable] = useState(false);
  const [editableRow, setEditableRow] = useState(null);
  const [answer, setAnswer] = useState("");
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastIndividualReviews, setIsToastIndividualReviews] =
    useState(false);
  const handleModalEvent = () => setShow(!show);
  const apiQuery = {
    userId: initialUserData,
  };
  const [isLoading, setIsLoading] = useState(true);
  const [allRecords, setRecords] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [recordsUpdated, setRecordsUpdated] = useState(false);
  const [addReviewForm, setAddReviewForm] = useState(false);
  const [reviewReviewListData, setReviewReviewListData] = useState([]);
  const [isReviewed, setIsReviewed] = useState(false);

  const handleRemoveModalEvent = (reviewId) => {
    setShow(!show);
    setDataID({ reviewId: reviewId });
  };

  const toggleAddReviewForm = () => {
    setAddReviewForm(!addReviewForm);
  };
  const processReviewData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;
      console.log("my outstanding enq list: ", allData);
      if (allData && allData.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        setRecords(allData);
        setReviewReviewListData(allData);
      }
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    const getReview = await getRecords(
      GET_ALL_SERVICE_USERS_REVIEW_LIST,
      apiQuery
    );

    processReviewData("get", getReview);
    console.log("review asdfsdfsdfe4433444333333333333:", getReview?.data);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);
  useEffect(() => {
    fetchAllRecords();
  }, [isReviewed]);

  // Remove Review

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      console.log({ data });
      setIsToastIndividualReviews(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (data?.status === isSuccess) {
      setIsToastIndividualReviews(true);
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
  };
  const deleteRecord = catchAsync(async (req, res) => {
    if (dataID !== "") {
      const response = await deleteRecords(REMOVE_SERVICE_USERS_REVIEW, dataID);
      setShow(!show);
      processFetchRecords("deleteReview", response);
      console.log("review response: ", response);
      fetchAllRecords();
    } else {
      setShow(!show);
      toastErrorMessages(toast?.error, "Unable to remove review", 500);
    }
  });

  const editAnswer = (itemId) => {
    setEditableRow(itemId === editableRow ? null : itemId);
  };

  // Update Review

  const updateReviewRecords = (method, data) => {
    console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastIndividualReviews(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    }
    if (method === "updateFormDataReview" && data?.status === isSuccess) {
      setIsToastIndividualReviews(true);
      setIsSubmitButtonClicked(false);
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
  };

  const submitUpdatedReview = async (itemId, updatedReview) => {
    const updateApiQuery = {
      id: itemId,
      review: updatedReview,
    };
    const response = await updateRecords(
      UPDATE_SERVICE_USERS_REVIEW,
      updateApiQuery
    );
    updateReviewRecords("updateFormDataReview", response);
    console.log("response: ", response);
    fetchAllRecords();
  };

  // const navigate = useNavigate();
  const storeReviewDataInStorage = (data, url) => {
    try {
      const serializedData = JSON.stringify(data);

      localStorage.setItem("userReviewData", serializedData);
      navigate(url);
    } catch (error) {
      console.error('Error storing data:', error);
    }
  }

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>Internal Reviews</small>
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={false}
        />
        <div className="mainContent mt-3">
          <div className="reviewContent">
            <header className="mainContentHeader">
              <h3>Internal Reviews</h3>
              <div className="actionButtons">
                {!addReviewForm ? (
                  <>
                    <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                      <Link to="/add-reviews"
                        // onClick={toggleAddReviewForm}
                        className="btn btn-sm btn-primary"
                      >
                        Add Review
                      </Link>
                    </ProtectedComponent>
                  </>
                ) : (
                  ""
                )}
              </div>
            </header>
            <div className="questionWrapper pt-0">
              {/* {addReviewForm ? (
                <>
                  <AddReview
                    userIdData={userData}
                    fetchAllRecords={fetchAllRecords}
                    onSuccessSubmit={() => setRecordsUpdated(true)}
                    toggleAddReviewForm={toggleAddReviewForm}
                  />
                </>
              ) : null} */}

              {/* Review Comment  */}
              {noRecordFound ? (
                <>
                  <div className="noRecordFound">
                    <p>No reviews available.</p>
                  </div>
                </>
              ) : isLoading ? (
                <ProgressComponent />
              ) : (
                <>
                  <div className="requestContentCards pt-0">
                    <div className="answer">
                      {reviewReviewListData.map((item, index) => (
                        <>
                          {/* {setAnswer(item?.review)} */}
                          <div className="profileBox" key={index}>
                            <div className="usersNameInfo">
                              <div className="userImage">
                                <img
                                  src={`${BASE_URL}${item?.commentBy?.profile_picture_url}`}
                                  alt={item?.commentBy?.name}
                                />
                              </div>
                            </div>
                            <div className="contentBox">
                              <span className="nameBlock">
                                {item?.commentBy?.name}
                              </span>

                              {/* {editableRow === item?._id ? (
                                <>
                                  <textarea
                                    id={item?._id}
                                    name={item?._id}
                                    readOnly={editableRow !== item?._id}
                                    className="editableTextArea"
                                    value={
                                      editableRow === item?._id
                                        ? answer
                                        : item?.review
                                    }
                                    onChange={(e) => setAnswer(e.target.value)}
                                  />
                                  <div className="editableActionButton">
                                    <span
                                      className="editQuestion"
                                      onClick={() => {
                                        editAnswer(item?._id);
                                        setAnswer(item?.review);
                                        submitUpdatedReview(item?._id, answer);
                                      }}
                                    >
                                      <img
                                        src={actionButtonTick}
                                        alt="Tick Answer"
                                      />
                                    </span>
                                    <span
                                      className="addQuestion"
                                      onClick={() => {
                                        editAnswer(item?._id);
                                      }}
                                    >
                                      <img src={actionButtonDel} alt="Close" />
                                    </span>
                                  </div>
                                </>
                              ) : null} */}

                              <p>
                                {editableRow !== item?._id ? (
                                  <>{item?.icDiagnosisOther} </>
                                ) : null}

                                <small>
                                  {formateDate(item?.updatedAt, "customFormat")}
                                </small>
                              </p>
                              <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                                <span className='isReviewed'>
                                  <IsReviewedApproved
                                    isReviewed={item?.reviewApprovedBy?.active === true}
                                    setIsReviewed={setIsReviewed}
                                    individualReviewedData={item} />
                                </span>
                              </ProtectedComponent>
                            </div>
                            {isManager || isAdmin ? (
                              <>
                                <div className="actionButtons individualReviewsActions">
                                  <span onClick={() => { storeReviewDataInStorage(item, "/view-individual-reviews") }} className="editQuestion"
                                  >
                                    <img
                                      src={password_eye_icon}
                                      alt="View"
                                      title="View"
                                    />
                                  </span>

                                  <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                                    <span onClick={() => { storeReviewDataInStorage(item, "/edit-reviews") }} className="editQuestion"
                                    >
                                      <img
                                        src={questionEdit}
                                        alt="Edit Question"
                                      />
                                    </span>
                                  </ProtectedComponent>

                                  {/* <span
                                    className="editQuestion"
                                    onClick={() => {
                                      setAnswer(item?.review);
                                      editAnswer(item?._id);
                                    }}
                                  >
                                    <img
                                      src={questionEdit}
                                      alt="Edit Question"
                                    />
                                  </span> */}

                                  <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
                                    <span
                                      className="delIcon"
                                      onClick={() => {
                                        handleRemoveModalEvent(item?._id);
                                      }}
                                    >
                                      <img
                                        src={deleteBasket}
                                        alt="Delete Question"
                                        title="Delete"
                                      />
                                    </span>
                                  </ProtectedComponent>

                                </div>
                              </>
                            ) : null}

                          </div>
                        </>
                      ))}
                    </div>
                  </div>
                </>
              )}
              {/* Review comment end */}
            </div>
          </div>
        </div>
      </Layout>
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={show}
          onHide={handleModalEvent}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this user request?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
              Yes
            </Button>
            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={handleModalEvent}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isToastIndividualReviews && <ToastContainer />}
    </>
  );
}

export default IndividualReviews;
