import React, { useState, useEffect } from "react";
import { BASE_URL, GET_ALL_SERVICE_USERS_URL } from "../../../env";
import { getRecords, isSuccess, catchAsync } from "../../../helper/helper";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { Link, useNavigate } from "react-router-dom";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Layout from "../../../components/layouts/Layout";

function InternalReviewsList() {
  const [isLoading, setIsLoading] = useState(true);
  const [allRecords, setRecords] = useState([]);
  const [reviewListData, setReviewListData] = useState([]);
  const [show, setShow] = useState(false);
  const handleModalEvent = () => setShow(!show);
  const navigate = useNavigate();
  const [noRecordFound, setNoRecordFound] = useState(false);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true, role: "serviceuser" },
      addedby: "_id email first_name",
      lastModifiedBy: "_id email first_name",
    },
  };

  const processEnquiryData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {

      const allRecords = data?.data?.users;

      if (allRecords && allRecords.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        setRecords(allRecords);
        setReviewListData(allRecords);
      }

    }
  };

  const getAllServiceUserEnquiry = catchAsync(async () => {
    const response = await getRecords(GET_ALL_SERVICE_USERS_URL, apiQuery);
    processEnquiryData("get", response);
  });

  useEffect(() => {
    getAllServiceUserEnquiry();
  }, []);

  // const serviceProfile = () => {
  //   navigate("/service-user-profile");
  // };
  // const userDetailsArrays = [{ name: item?.modelId?.suFirstMiddleName }];
  // const handleRowClickStaffMember = (item) => {
  //   navigate("/individual-reviews", { state: { userDetails: [item] } });
  // };
  const handleRowClickStaffMember = (item) => {
    localStorage.setItem(
      "profileUserDetails",
      JSON.stringify(item)
    );
    navigate("/individual-reviews");
  };
  return (
    <>
      {" "}
      <Layout
        pageTitleText={
          <>
            Service User <small>Internal Reviews</small>
          </>
        }
      >
        <div className="addNewRequestSU">
          <div className="mainContent p-0">
            <header className="mainContentHeader">
              <h3>Service Users</h3>
              {/* <div className="actionButtons">
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input type="text" placeholder="Type your search" />
                  </div>
                </div>
              </div> */}
            </header>
          </div>
          <div className="reviewsContentCards">
            {noRecordFound ? (
              <>
                <div className="noRecordFound">
                  <p>No records found.</p>
                </div>
              </>
            ) : isLoading ? (
              <ProgressComponent />
            ) : (
              reviewListData.map((item, index) => (
                <>
                  {item?.modelId?.currSU ? (
                    <div className="cards">
                      <div className="cardsUserDetail">
                        <div
                          className="usersNameInfo curSorPointer"
                          onClick={() => {
                            handleRowClickStaffMember(item);
                          }}
                        >
                          <div className="userImage">
                            <img
                              src={BASE_URL + item?.profile_picture_url}
                              alt={item?.modelId?.suFirstMiddleName}
                            />
                          </div>
                          <div className="userInfo">
                            <span className="nameBlock">
                              {item?.modelId?.suFirstMiddleName}{" "}
                              {item?.modelId?.suLastName}
                            </span>
                            <span className="infoBlock">
                              {item?.modelId?.suAge} Yrs |{" "}
                              {item?.modelId?.suSex}
                            </span>
                          </div>
                        </div>
                        <span
                          onClick={() => {
                            handleRowClickStaffMember(item);
                          }}
                          className="link"
                        >
                          Review
                        </span>
                        {/* <Link to="/individual-reviews">Review</Link> */}
                      </div>
                    </div>
                  ) : null}
                </>
              ))
            )}
            {/* {reviewListData.map((item, index) => (
              <div className="cards">
                <div className="cardsUserDetail">
                  <div className="usersNameInfo">
                    <div className="userImage">
                      <img src={item.imgPath} alt={item.name} />
                    </div>
                    <div className="userInfo">
                      <span className="nameBlock">{item.name}</span>
                      <span className="infoBlock">
                        {item.hours} hrs | {item.shifts} shifts
                      </span>
                    </div>
                  </div>
                  <Link to="/individual-reviews">Review</Link>
                </div>
              </div>
            ))} */}
          </div>
        </div>
      </Layout>
    </>
  );
}

export default InternalReviewsList;
