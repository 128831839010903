import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { BASE_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
  handleRedirectIfProfileNotPresent,
} from "../../helper/helper";
import moment from "moment";
function UserDetailReviewVertical() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  // console.log("🚀 ~ file: UserDetailReviewVertical.jsx:15 ~ UserDetailReviewVertical ~ userDetails:", userDetails)

  const navigate = useNavigate();

  useEffect(() => {
    handleRedirectIfProfileNotPresent(
      "profileUserDetails",
      "/service-users",
      navigate
    );
  }, [navigate]);

  return (
    <>
      <div className="profileDetailBlock">
        <div className="userAvatar">
          {!userDetails?.profile_picture_url ? (
            <>
              <img
                src="https://placehold.co/600x400"
                alt={userDetails?.modelId?.suFirstMiddleName}
              />
            </>
          ) : (
            <>
              <img
                src={BASE_URL + userDetails?.profile_picture_url}
                alt={userDetails?.modelId?.suFirstMiddleName}
              />
            </>
          )}
          <div className="text-center mt-2">
            <h3>
              {userDetails?.modelId?.suFirstMiddleName}&nbsp;{userDetails?.modelId?.suLastName}
            </h3>
          </div>
        </div>
        <div className="userDetail">
          <p className="currentStatus">
            {userDetails?.modelId?.currSU ? (
              <>
                <strong>Current Status:</strong> Active <span>&nbsp;</span>
              </>
            ) : (
              <>
                <strong>Current Status:</strong> Inactive{" "}
                <span style={{ background: "red" }}>&nbsp;</span>
              </>
            )}
          </p>
          <p>
            <small>Email (home)</small>
            <a href={userDetails?.modelId?.suEmailHome}>{userDetails?.modelId?.suEmailHome}</a>
          </p>
          <p>
            <small>Emergency contact no</small>
            {userDetails?.modelId?.suEmergencyContact}
          </p>
          <p>
            <small>First visit date</small>
            {userDetails?.modelId?.suFirstVisitDate
              ? moment(userDetails?.modelId?.suFirstVisitDate).format("DD/MM/YYYY")
              : ""}
          </p>
          <p>
            <small>Last visit date</small>
            {userDetails?.modelId?.suLastVisitDate
              ? moment(userDetails?.modelId?.suFirstVisitDate).format("DD/MM/YYYY")
              : ""}
          </p>
          <p>
            <small>Address1</small> {userDetails?.modelId?.suAddress1}
          </p>
          <p>
            <small>Address2</small> {userDetails?.modelId?.suAddress2}
          </p>
        </div>
      </div>
    </>
  );
}

export default UserDetailReviewVertical;
