import React, { useState, useEffects } from "react";
import { Link } from "react-router-dom";

function StaffRecordHeader({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUser] = useState(initialUserData);
  return (
    <>
      <header className="headerContainerSearchAction">
        <div className="actionButtons">
          <>
            <Link to="/messaging-system" className="btn btn-sm btn-primary">
              Chat Now
            </Link>
          </>
        </div>
        <div className="searchContainer">
          <p className="currentStatus">
            {userData?.user?.active ? (
              <>
                <strong>Current Status:</strong> Active <span>&nbsp;</span>
              </>
            ) : (
              <>
                <strong>Current Status:</strong> Inactive{" "}
                <span style={{ background: "red" }}>&nbsp;</span>
              </>
            )}
          </p>
        </div>
      </header>
    </>
  );
}

export default StaffRecordHeader;
