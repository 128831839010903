import React, { useState, useEffect } from "react";
import ScheduleBlocks from "../../components/general/ScheduleBlocks";
import clockSnooze from "../../assets/images/icons/clockSnooze.svg";
import folderTick from "../../assets/images/icons/folderTick.svg";
import folder from "../../assets/images/icons/folder.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import database from "../../assets/images/icons/database.svg";
import { GET_STAFF_SUPERVISION_COUNTS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
} from "../../helper/helper";

function SupervisionBlocks({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(initialUserData);
  const [counts, setCounts] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isToastSupervisionBlocks, setIsToastSupervisionBlocks] =
    useState(false);

  const apiQuery = {
    staffMemberId: userData?._id,
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastSupervisionBlocks(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      console.log(data);
      const result = data?.data;
      setCounts(result);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(
      GET_STAFF_SUPERVISION_COUNTS_URL,
      apiQuery
    );
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    setUserData(initialUserData);
  }, [setUserData]);
  //Ameer
  const assigned = counts?.assigned ?? 0;
  const completed = counts?.completed ?? 0;
  const overdue = counts?.overdue ?? 0;
  const total = assigned + completed + overdue;

  return (
    <>
      <div className="scheduleBlocks trainingBlocks">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={folder}
              heading={assigned}
              subHeading={"Assigned"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={folderTick}
              heading={completed}
              subHeading={"Completed"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={clockSnooze}
              heading={overdue}
              subHeading={"Over Due"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={database}
              heading={total}
              subHeading={"Total"}
            />
          </div>
        </div>
      </div>
      {isToastSupervisionBlocks && <ToastContainer />}
    </>
  );
}

export default SupervisionBlocks;
