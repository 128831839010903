import React from "react";

function TextArea({
  id,
  name,
  placeholder,
  value,
  onChange,
  onBlur,
  required,
  labelText,
  rows,
  cols,
  disabled,
  readOnly,
  limitCounter,
  errorClass,
  customError,
  errorMessage,
  requiredStar,
  disabledStatus,
  extraClass,
}) {
  const maxLength = 3000;
  return (
    <div
      className={`form-group ${errorClass ? "validationError" : null} ${
        extraClass ? extraClass : null
      }`}
    >
      <textarea
        id={id}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        cols={cols}
        rows={rows}
        maxLength={maxLength}
        readOnly={readOnly}
        onBlur={onBlur}
        disabled={disabledStatus}
        required={required}
        // readOnly={readOnly}
      ></textarea>
      <label htmlFor={id} className="floatingLabel">
        {labelText}
        {requiredStar ? <span className="text-danger">*</span> : null}
      </label>
      {customError ? <div className="text-danger">{errorMessage}</div> : null}
      {limitCounter ? (
        <p>
          <small className="m-0">
            Characters Remaining: {maxLength - value?.length || maxLength}{" "}
          </small>
        </p>
      ) : (
        ""
      )}
    </div>
  );
}

export default TextArea;
