import React from "react";

function Authentication({ children }) {
  return (
    <>
      <div className="FTCAuthenticationWrapper">
        <div className="authenticationContainer">
          <div className="column left-column">&nbsp;</div>
          <div className="column right-column">{children}</div>
        </div>
      </div>
    </>
  );
}

export default Authentication;
