import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import editIconCircle from "../../assets/images/icons/editIconCircle.svg";
import mailIconCircle from "../../assets/images/icons/mailIconCircle.svg";
import phoneIconCirlce from "../../assets/images/icons/phoneIconCirlce.svg";
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { GET_RECRUITMENT_CANDIDATES_URL, BASE_URL } from "../../env";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import {
  catchAsync,
  isSuccess,
  getRecords,
  filterColumns,
  calculateAgeDifference,
  toastErrorMessages,
} from "../../helper/helper";

function CandidatesBlocks({ candidatesBlocksHeading }) {
  const [isLoading, setIsLoading] = useState(true);
  const [archivedCandidates, setArchivedCandidates] = useState([]);
  const [newCandidates, setNewCandidates] = useState([]);
  const [isToastCandidatesBlocks, setIsToastCandidatesBlocks] = useState(false);
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState([]);
  const navigate = useNavigate();
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastCandidatesBlocks(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      console.log("🚀 ~ processFetchRecords ~ data:", data)
      const archivedCandidates = data?.data?.recruitment?.filter(
        (candidate) => candidate?.status === "archive" && candidate?.isDeleted === false
      );
      const newCandidates = data?.data?.recruitment?.filter(
        (candidate) => candidate?.status === "new" && candidate?.isDeleted === false
      );
      setArchivedCandidates(archivedCandidates);
      setNewCandidates(newCandidates);
      setFilterSearch(newCandidates); // initialize filterSearch with newCandidates
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_RECRUITMENT_CANDIDATES_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const handleEmailClick = (e, row) => {
    window.location.href = "mailto:" + row?.email;
  };

  const handlePhoneClick = (e, row) => {
    window.location.href = "tel:" + row?.phone;
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleRecCadndidateDetail = (item, url) => {
    localStorage.setItem(
      "recCandidateDetail",
      JSON.stringify(item)
    );
    navigate(url);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        return (
          <span className="usersNameInfo cursor-pointer">
            <span className="userImage">
              <img src={`${BASE_URL + row?.dp}`} alt={`${row?.firstName}`} />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.firstName}`}</span>
              <span className="infoBlock">
                {`${calculateAgeDifference(row?.dob)}`} Yrs | {`${row?.gender}`}
              </span>
            </span>
          </span>
        );
      },
      width: "60%",
    },
    {
      name: "Contract Hours",
      selector: (row) => {
        return (
          <div className="iconList cursor-pointer">
            <span onClick={(e) => handleEmailClick(e, row)}>
              <img src={mailIconCircle} alt={mailIconCircle} />{" "}
            </span>
            <span onClick={(e) => handlePhoneClick(e, row)}>
              <img src={phoneIconCirlce} alt={phoneIconCirlce} />{" "}
            </span>
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span to="/edit-candidate" onClick={() => handleRecCadndidateDetail(row, "/edit-candidate")}>
                <img src={editIconCircle} alt={editIconCircle} />{" "}
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "40%",
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  // Search Filter
  useEffect(() => {
    const result = newCandidates.filter((user) => {
      return user?.firstName.toLowerCase().includes(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, newCandidates]);

  return (
    <>
      <div className="candidatesBlocks h-100">
        <div className="mainContent h-100">
          <h4>{candidatesBlocksHeading}</h4>
          <div className="searchContainer">
            <div className="searchTextBox">
              <input
                type="text"
                placeholder="Search New Candidate"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </div>
          </div>
          <div className="dataTableWrapper">
            <DataTable
              className="maxWidthTable"
              columns={filteredColumns}
              data={filterSearch} // pass filterSearch here
              fixedHeader
              fixedHeaderScrollHeight="288px"
              highlightOnHover
              responsive={true}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
            />
          </div>
        </div>
      </div>
      {isToastCandidatesBlocks && <ToastContainer />}
    </>
  );
}

export default CandidatesBlocks;
