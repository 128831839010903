
import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Layout from "../../../components/layouts/Layout";
import { ToastContainer, toast } from "react-toastify";
import { permissions } from '../../../permissions/roles';
import useColumnsWithPermission from "../../../permissions/useColumnsWithPermission";
import "react-toastify/dist/ReactToastify.css";
import {
    BASE_URL,
    GET_CLAIMED_LIVE_ROSTER_SHIFTS_URL,
    UPDATE_CLAIMED_LIVE_ROSTER_SHIFTS_URL,
} from "../../../env";
import {
    updateRecords,
    getRecords,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
    catchAsync,
} from "../../../helper/helper";
import moment from "moment";
import StatusUpdated from "../../../components/general/StatusUpdated";
function ClaimedShifts() {

    const [shiftData, setShiftData] = useState({});
    const [isToastEditRosterShift, setIsToastEditRosterShift] = useState(false);
    const [userData, setUserData] = useState([]);

    const apiQuery = {
        sortproperty: "createdAt",
        sortorder: 0,
        offset: 0,
        limit: 10,
        query: {
            critarion: {
            }
        }
    };

    const [search, setSearch] = useState("");
    const [filterSearch, setFilterSearch] = useState();
    const [isLoading, setIsLoading] = useState(true);

    // Getting records from DB
    const processFetchRecords = (method, data) => {
        if (method === "get" && data?.status === isSuccess) {
            const actionRecentIncidentsData = data?.data?.claimedShifts
            const rosterShiftListData = actionRecentIncidentsData?.map((item) => item);
            setUserData(rosterShiftListData);
            setFilterSearch(rosterShiftListData);
            setIsLoading(false);
        } else if (method === "delete" && data?.status === isSuccess) {
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
        }
    };

    const fetchAllRecords = catchAsync(async (req, res) => {
        const response = await getRecords(GET_CLAIMED_LIVE_ROSTER_SHIFTS_URL, apiQuery);
        processFetchRecords("get", response);
    });

    useEffect(() => {
        fetchAllRecords();
    }, []);

    // Table Columns
    const baseColumns = [
        {
            name: "Staff Member",
            selector: (row) => {
                return (
                    <span className="usersNameInfo">
                        <span className="userImage">
                            <img
                                src={`${BASE_URL}${row?.staffUserId?.profile_picture_url}`}
                                alt={row?.staffUserId?.name}
                            />
                        </span>
                        <span className="userInfo">
                            <span className="nameBlock">{row?.staffUserId?.name}</span>
                        </span>
                    </span>
                );
            },
        },
        {
            name: "Shift Date",
            selector: (row) => {
                return (
                    <span>{moment(row?.staffUserId?.shiftDate).format("DD/MM/YYYY")}</span>
                );
            },
            width: "200px",
        },
        {
            name: "Shift Time",
            selector: (row) => <span><span class="time">{row?.shiftStartTime} - {row?.shiftEndTime}</span></span>,
            center: true
        },
        {
            name: "Shift Hours",
            selector: (row) => <span>{`${row?.workHrs} hours`}</span>,
            center: true
        },
        {
            name: "Request Type",
            selector: (row) => <span className="requestType">{row?.requestType === "CLAIMED" ? "Claimed" : "Cancelled"}</span>,
            center: true
        },
        {
            name: "Action",
            selector: (row) => (
                <StatusUpdated
                    initialStatus={row?.status}
                    isSuccessReturn={""}
                    onChange={(e) => subModalUpdateRosterUserShift(e.target.value, row)}
                // isDisabled={row?.status !== "PENDING" ? true : false}
                />
            ),
            width: "12%",
            center: true,
        },
    ];

    const columns = useColumnsWithPermission(baseColumns, [permissions.EDIT, permissions.DELETE]);

    // Search Filter
    useEffect(() => {
        const result = userData.filter((user) => {
            const filterClaimedShifts = user?.staffUserId?.name
            return filterClaimedShifts.toLowerCase().match(search.toLowerCase());
        });
        setFilterSearch(result);
    }, [search, userData]);

    // Update Status of the Shifts
    const subModalUpdateRosterUserShift = (onChanageReject, shiftData) => {
        setShiftData({
            claimShiftId: shiftData?._id,
            status: onChanageReject === "rejected" ? "REJECTED" : onChanageReject === "approved" ? "APPROVED" : "PENDING"
        });
    };

    useEffect(() => {
        if (shiftData.claimShiftId) { // Check if shiftData has been updated
            postEditRosterShiftFormData();
            fetchAllRecords();
        }
    }, [shiftData]);

    const processPostRecords = (method, data) => {
        if (data?.status !== isSuccess) {
            setIsToastEditRosterShift(true);
            toastErrorMessages(toast.error, data?.message, "", 500, 500);
        } else if (
            method === "postEditRosterShiftFormData" &&
            data?.status === isSuccess
        ) {
            setIsToastEditRosterShift(true);
            toastSuccessMessages(toast.success, data?.message, "", 500, 500);
        }
    };

    const postEditRosterShiftFormData = catchAsync(async (req, res) => {
        const response = await updateRecords(UPDATE_CLAIMED_LIVE_ROSTER_SHIFTS_URL, shiftData);
        processPostRecords("postEditRosterShiftFormData", response);

    });



    return (
        <>
            <Layout pageTitleText="Shifts Requests">
                <div className="rowClickUnset pbsPlanUpdated">
                    <div className="mainContent">
                        <div className="dataTableWrapper">
                            <DataTable
                                className="maxWidthTable minWidthTable"
                                columns={columns}
                                data={filterSearch}
                                pagination
                                paginationRowsPerPageOptions={[1, 25, 50, 100]}
                                paginationPerPage={10}
                                fixedHeader
                                fixedHeaderScrollHeight="520px"
                                // highlightOnHover
                                responsive={true}
                                subHeader={true}
                                subHeaderComponent={
                                    <div className="searchContainer">
                                        <div className="searchTextBox">
                                            <input
                                                type="text"
                                                placeholder="Search by Staff Member"
                                                value={search}
                                                onChange={(e) => setSearch(e.target.value)}
                                            />
                                        </div>
                                    </div>
                                }
                                subHeaderAlign="left"
                                progressPending={isLoading}
                                progressComponent={<ProgressComponent />}
                            />
                        </div>
                    </div>
                </div>
            </Layout>

            {isToastEditRosterShift && <ToastContainer />}
        </>
    );
}

export default ClaimedShifts