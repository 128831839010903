// NewPasswordForm.js
import React, { useState } from 'react';
import PasswordInput from './PasswordInput';

function NewPasswordForm() {
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isNewPasswordVisible, setIsNewPasswordVisible] = useState(false);
    const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);

    const toggleNewPasswordVisibility = () => {
        setIsNewPasswordVisible(!isNewPasswordVisible);
    };

    const toggleConfirmPasswordVisibility = () => {
        setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Handle form submission here
        // You can access newPassword and confirmPassword state values
    };

    return (
        <form onSubmit={handleSubmit}>
            <PasswordInput
                label="Enter New Password"
                name="new_password"
                type={isNewPasswordVisible ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
                isPasswordVisible={isNewPasswordVisible}
                onToggleVisibility={toggleNewPasswordVisibility}
            />
            <PasswordInput
                label="Confirm New Password"
                name="confirm_password"
                type={isConfirmPasswordVisible ? 'text' : 'password'}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                isPasswordVisible={isConfirmPasswordVisible}
                onToggleVisibility={toggleConfirmPasswordVisibility}
            />
            <div className="form-group">
                <button className="button" type="submit">
                    Update Password
                </button>
            </div>
        </form>
    );
}

export default NewPasswordForm;
