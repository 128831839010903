import React, { useState } from "react";
import CareNotesTable from "./CareNotesTable";
import KeyContacts from "./KeyContacts";
import { Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { POST_SERVICE_USER_SIGN_UP_URL } from "../../env";
import {
  postRecords,
  isSuccess,
  catchAsync,
  updateFormFields,
} from "../../helper/helper";
import Input from "../../components/forms-fields/Input";
import TextArea from "../../components/forms-fields/TextArea";

function InformationForm() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    profilePic: "/uploads/dp/default.png",
    name: "Sajjad Ali",
    email: "atifazad@gmail.com",
    password: "shayan09",
    phoneNumber: "+923074901291",
    deviceId: "123",
    version: "0",
    role: "serviceuser",
    userModelName: "serviceUser",
    active: true,
    is_verified: true,
    approved: "approved",
    fcm_tokens: {
      token:
        "fY6_DGfGQ06OW4BUVEsEwG:APA91bGUFza2uhaaR0miN2jtY0ut7RuA5ObleZvqv2X8KLOBgksmDmgc9sHDdea-DBvHmz1aUwX1uhkysk92x50WQCLHPDD1VwGX5ybKhUwVdq4aBfI24vhXqMB-FksWXEZwzDAi9BA_",
      deviceType: "android",
    },
    location: {
      type: "Point",
      coordinates: [74.28911289869138, 31.624848273644956],
    },
    //service user fields
    suLastName: "Ali",
    suFirstMiddleName: "Sajjad",
    suPreferredName: "Saji",
    suSsn: "515615",
    suSex: "Male",
    suTitle: "Sir",
    suDOB: "2005-01-01 00:00:00",
    suAge: "18",
    suReferredBY: "Jamshaid",
    suFamilyHead: "Sabir",
    suAddress1: "Lahore",
    suAddress2: "Khanaspur",
    suCity: "Lahore",
    suState: "Punjab",
    suZip: "54000",
    suFirstVisitDate: "2023-11-01 00:05:00",
    suLastVisitDate: "2023-11-01 00:05:00",
    suHomePhone: "0427933415",
    suWorkPhone: "0427933415",
    suMobileHomeNo: "+923074901291",
    suMobileWorkNo: "+923074901291",
    suEmailHome: "home@gmail.com",
    suEmailWork: "home@gmail.com",
    suPrefHomeNo: "0427933415",
    suPrefWorkNo: "0427933415",
    suEmergencyContact: "0427933415",
    seMedicalAlert: "medical alert",
    suNote: "note",
    diagnosises: [
      {
        diagnosisText: "some text",
        diagnosisDate: "2023-11-01 00:05:00",
        diagnosisBy: "Dr. Alam",
        isCurrentDiagnosis: true,
      },
    ],
    aboutPatient: {
      aboutText: "About",
      aboutDate: "2023-11-01 00:00:00",
      aboutBy: "Jamshaid",
    },
    shifts: [],
    serviceUserMedications: [],
    homeVisitSignOut: [],
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Function to handle the profile picture
  const handleProfilePicChange = (e) => {
    e?.preventDefault();
    updateFormFields(e, setFormData, "image");
  };

  const handleFormSubmit = catchAsync(async (req, res) => {
    req.preventDefault();

    const result = await postRecords(POST_SERVICE_USER_SIGN_UP_URL, formData);

    if (result?.status === isSuccess) {
      navigate("/service-users");
    } else {
    }
  });
  return (
    <>
      <div className="youngInformationForm">
        {/* First Row */}
        <div className="row">
          <div className="col-md-4">
            <div className="form-group">
              <Input
                type="file"
                name={"profilePic"}
                accept={"image/*"}
                onChangeFun={(e) => handleProfilePicChange(e)}
                labelText={"Profile Picture"}
                className="form-control uploadProfileImage"
              />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-4">
            <Input
              type="text"
              id="suLastName"
              name="suLastName"
              value={formData?.suLastName}
              onChange={handleInputChange}
              labelText="Last Name"
            />
            {/*  <div className="form-group">
                           <input
                type="text"
                id="lastname"
                name="lastname"
                value={formData.lastname}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="lastname" className="floatingLabel">
                Last Name
              </label> 
            </div>*/}
          </div>
          <div className="col-md-4">
            <Input
              type="text"
              id="suFirstMiddleName"
              name="suFirstMiddleName"
              value={formData?.suFirstMiddleName}
              onChange={handleInputChange}
              labelText="First & Middle Name"
            />
            {/* <div className="form-group">
              <input
                type="text"
                id="firstMidleName"
                name="firstMidleName"
                value={formData.firstMidleName}
                onChange={handleInputChange}
                required
              />
              <label htmlFor="firstMidleName" className="floatingLabel">
                First & Middle Name
              </label>
            </div> */}
          </div>
          <div className="col-md-4">
            <Input
              type="text"
              id="suPreferredName"
              name="suPreferredName"
              value={formData?.suPreferredName}
              onChange={handleInputChange}
              labelText="Preferred Name"
            />
            {/* <div className="form-group">
              <input
                type="text"
                id="PreferredName"
                name="PreferredName"
                required
              />
              <label htmlFor="PreferredName" className="floatingLabel">
                Preferred Name
              </label>
            </div> */}
          </div>
        </div>
        {/* Second Row */}
        <div className="row">
          <div className="col-md-4">
            <Input
              type="text"
              id="suSsn"
              name="suSsn"
              value={formData?.suSsn}
              onChange={handleInputChange}
              labelText="SSN"
            />
            {/* <div className="form-group">
              <input type="text" id="SSN" name="SSN" required />
              <label htmlFor="SSN" className="floatingLabel">
                SSN
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pr-0">
            <div className="form-group div30">
              <select name="sex" id="sex">
                <option value="Male">Male</option>
                <option value="Female">Female</option>
              </select>
              <label htmlFor="sex" className="floatingLabel">
                Sex
              </label>
            </div>
          </div>
          <div className="col-lg-2 col-md-4">
            <Input
              type="text"
              id="suTitle"
              name="suTitle"
              value={formData?.suTitle}
              onChange={handleInputChange}
              labelText="Title"
            />
            {/* <div className="form-group">
              <input type="text" id="title" name="title" required />
              <label htmlFor="title" className="floatingLabel">
                Title
              </label>
            </div> */}
          </div>

          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="date"
              id="suDOB"
              name="suDOB"
              value={formData?.suDOB}
              onChange={handleInputChange}
              labelText="Date of Birth"
            />
            {/* <div className="form-group">
              <input type="date" id="date" name="date" required />
              <label htmlFor="date" className="floatingLabel">
                Date
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pl-0">
            <Input
              type="number"
              id="suAge"
              name="suAge"
              value={formData?.suAge}
              onChange={handleInputChange}
              labelText="Age"
            />
            {/* <div className="form-group">
              <input type="number" id="age" name="age" required />
              <label htmlFor="age" className="floatingLabel">
                Age
              </label>
            </div> */}
          </div>
        </div>
        {/* Third Row */}
        <div className="row">
          <div className="col-md-4">
            <Input
              type="text"
              id="suReferredBY"
              name="suReferredBY"
              value={formData?.suReferredBY}
              onChange={handleInputChange}
              labelText="Referred by"
            />
            {/* <div className="form-group">
              <input type="text" id="referedBy" name="referedBy" required />
              <label htmlFor="referedBy" className="floatingLabel">
                Referred by
              </label>
            </div> */}
          </div>
          <div className="col-md-4">
            <Input
              type="text"
              id="suFamilyHead"
              name="suFamilyHead"
              value={formData?.suFamilyHead}
              onChange={handleInputChange}
              labelText="Family Head"
            />
            {/* <div className="form-group">
              <input type="text" id="familyHead" name="familyHead" required />
              <label htmlFor="familyHead" className="floatingLabel">
                Family Head
              </label>
            </div> */}
          </div>
          <div className="col-md-4">
            <Input
              type="text"
              id="suAddress1"
              name="suAddress1"
              value={formData?.suAddress1}
              onChange={handleInputChange}
              labelText="Address 1"
            />
            {/* <div className="form-group">
              <input type="text" id="address1" name="address1" required />
              <label htmlFor="address1" className="floatingLabel">
                Address 1
              </label>
            </div> */}
          </div>
        </div>
        {/* Fourth Row */}
        <div className="row">
          <div className="col-md-4">
            <Input
              type="text"
              id="suAddress2"
              name="suAddress2"
              value={formData?.suAddress2}
              onChange={handleInputChange}
              labelText="Address 2"
            />
            {/* <div className="form-group">
              <input type="text" id="address2" name="address2" required />
              <label htmlFor="address2" className="floatingLabel">
                Address 2
              </label>
            </div> */}
          </div>
          <div className="col-md-4">
            <Input
              type="text"
              id="suCity"
              name="suCity"
              value={formData?.suCity}
              onChange={handleInputChange}
              labelText="City"
            />
            {/* <div className="form-group">
              <input type="text" id="city" name="city" required />
              <label htmlFor="city" className="floatingLabel">
                City
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="text"
              id="suState"
              name="suState"
              value={formData?.suState}
              onChange={handleInputChange}
              labelText="State"
            />
            {/* <div className="form-group">
              <input type="text" id="state" name="state" required />
              <label htmlFor="state" className="floatingLabel">
                State
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pl-0">
            <Input
              type="text"
              id="suZip"
              name="suZip"
              value={formData?.suZip}
              onChange={handleInputChange}
              labelText="Zip"
            />
            {/* <div className="form-group">
              <input type="text" id="zip" name="zip" required />
              <label htmlFor="zip" className="floatingLabel">
                Zip
              </label>
            </div> */}
          </div>
        </div>
        {/* Fifth Row */}
        <div className="row">
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="date"
              id="suFirstVisitDate"
              name="suFirstVisitDate"
              value={formData?.suFirstVisitDate}
              onChange={handleInputChange}
              labelText="First Visit Date"
            />
            {/* <div className="form-group">
              <input
                type="date"
                id="firstVisitDate"
                name="firstVisitDate"
                required
              />
              <label htmlFor="firstVisitDate" className="floatingLabel">
                First Visit Date
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="date"
              id="suLastVisitDate"
              name="suLastVisitDate"
              value={formData?.suLastVisitDate}
              onChange={handleInputChange}
              labelText="Last Visit Date"
            />
          </div>

          <div className="col-md-4">
            <Input
              type="text"
              id="provider"
              name="provider"
              value={formData?.provider}
              onChange={handleInputChange}
              labelText="Provider"
            />
            {/* <div className="form-group">
              <input type="text" id="provider" name="provider" required />
              <label htmlFor="provider" className="floatingLabel">
                Provider
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="text"
              id="suHomePhone"
              name="suHomePhone"
              value={formData?.suHomePhone}
              onChange={handleInputChange}
              labelText="Home Phone No."
            />
            {/* <div className="form-group">
              <input type="number" id="homePhone" name="homePhone" required />
              <label htmlFor="homePhone" className="floatingLabel">
                Home Phone No.
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="text"
              id="suWorkPhone"
              name="suWorkPhone"
              value={formData?.suWorkPhone}
              onChange={handleInputChange}
              labelText="Work Phone No."
            />
            {/* <div className="form-group">
              <input type="number" id="workPhone" name="workPhone" required />
              <label htmlFor="workPhone" className="floatingLabel">
                Work Phone No.
              </label>
            </div> */}
          </div>
        </div>
        {/* Sixth Row */}
        <div className="row">
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="text"
              id="suMobileHomeNo"
              name="suMobileHomeNo"
              value={formData?.suMobileHomeNo}
              onChange={handleInputChange}
              labelText="Mobile Home No."
            />
            {/* <div className="form-group">
              <input
                type="number"
                id="mobileHomeNo"
                name="mobileHomeNo"
                required
              />
              <label htmlFor="mobileHomeNo" className="floatingLabel">
                Mobile Home No.
              </label>
            </div> */}
          </div>
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="text"
              id="suMobileWorkNo"
              name="suMobileWorkNo"
              value={formData?.suMobileWorkNo}
              onChange={handleInputChange}
              labelText="Mobile Work No."
            />
            {/* <div className="form-group">
              <input type="number" id="mobileWork" name="mobileWork" required />
              <label htmlFor="mobileWork" className="floatingLabel">
                Mobile Work No.
              </label>
            </div> */}
          </div>

          <div className="col-md-4">
            <Input
              type="text"
              id="suEmailHome"
              name="suEmailHome"
              value={formData?.suEmailHome}
              onChange={handleInputChange}
              labelText="Email Home"
            />
          </div>
          <div className="col-md-4">
            <Input
              type="text"
              id="suEmailWork"
              name="suEmailWork"
              value={formData?.suEmailWork}
              onChange={handleInputChange}
              labelText="Email Work"
            />
          </div>
        </div>
        {/* Seventh Row */}
        <div className="row">
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="text"
              id="suPrefHomeNo"
              name="suPrefHomeNo"
              value={formData?.suPrefHomeNo}
              onChange={handleInputChange}
              labelText="Pref. Home No."
            />
          </div>
          <div className="col-lg-2 col-md-4 pr-0">
            <Input
              type="text"
              id="suPrefWorkNo"
              name="suPrefWorkNo"
              value={formData?.suPrefWorkNo}
              onChange={handleInputChange}
              labelText="Pref. Work No."
            />
          </div>

          <div className="col-md-4">
            <Input
              type="text"
              id="suEmergencyContact"
              name="suEmergencyContact"
              value={formData?.suEmergencyContact}
              onChange={handleInputChange}
              labelText="Emergency Contact"
            />
          </div>
          <div className="col-md-4">
            <div className="form-group div30">
              <select name="fundingBy" id="fundingBy">
                <option value="1">Option1</option>
                <option value="2">Option2</option>
              </select>
              <label htmlFor="fundingBy" className="floatingLabel">
                Funding by
              </label>
            </div>
          </div>
        </div>
        {/* Eight Row */}
        <div className="row">
          <div className="col-md-12">
            <TextArea
              id="suNote"
              name="suNote"
              value={formData?.suNote}
              onChange={handleInputChange}
              labelText="Note"
              rows="3"
            />
          </div>
        </div>

        <br />
        <CareNotesTable />
        <br />
        <KeyContacts />
        <footer>
          <div className="formActionButtons">
            <Button
              variant="primary"
              className="btn-sm"
              onClick={handleFormSubmit}
            >
              Save
            </Button>
          </div>
        </footer>
      </div>
    </>
  );
}

export default InformationForm;
