import { VIEW_USER_PROFILE } from "../constants";


const initialState = {
  userProfileData: null,
};

const userProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case VIEW_USER_PROFILE:
      return {
        ...state,
        userProfileData: action.payload,
      };
    default:
      return state;
  }
};

export default userProfileReducer;