import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
import Input from "../../components/forms-fields/Input";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Tab, Nav, Button, Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../components/general/ProgressComponent";
import folderIcon from "../../assets/images/icons/folder.svg";
import SelectBox from "../../components/forms-fields/SelectBox";
import CustomSelectUsers from "../../components/forms-fields/CustomSelectUsers";
import CustomSelectTrainingTemp from "../../components/forms-fields/CustomSelectTrainingTemp";
import ItemListModal from "../../components/modals/TrainingsListing";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import UpdateTrainingModel from "../../components/modals/UpdateTrainingModel";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL,
  GET_ALL_TRAININGS_WITH_FULL_DETAILS_URL,
  POST_ASSIGN_TRAINING_TEMPLATE_URL,
  REMOVE_TRAINING_TEMPLATE_URL,
} from "../../env";
import {
  catchAsync,
  filterColumns,
  isSuccess,
  getRecords,
  deleteRecords,
  postRecords,
  toastErrorMessages,
  toastSuccessMessages,
  filterRowsByProperty,
  filterArrayBySearchTerm,
} from "../../helper/helper";
import TextArea from "../../components/forms-fields/TextArea";
import AssignTraining from "./components/AssignTraining";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import password_eye_icon_circle from "../../assets/images/icons/password_eye_icon_circle.svg";
import ViewTrainingModel from "../../components/modals/ViewTrainingModel";


function TrainingGroup() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isFormSubmit, setIsFormSubmit] = useState(false);
  const [isAssignTrainingFormSubmit, setIsAssignTraTempFormSubmit] =
    useState(false);
  const [selectedTrainingTemplateId, setSelectedTrainingTemplateId] =
    useState(null);
  const [filteredRows, setFilteredRows] = useState();
  const [userData, setUserData] = useState();
  const [isToastAssignTraTempGroup, setIsToastAssignTraTempGroup] =
    useState(false);
  const [isAssignTrainingModelOpen, setIsAssignTraTempModelOpen] =
    useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isUsersTraLisModelOpen, setIsUsersTraLisModelOpen] = useState(null);
  const [usersListings, setUsersListings] = useState(false);
  const [trainingStatus, setTrainingStatus] = useState(false);
  const [updateTrainingTemp, setUpdateTrainingTemp] = useState(false);
  const [viewTrainingTemp, setViewTrainingTemp] = useState(false);
  const [isTraUpdateModel, setIsTraUpdateModel] = useState();
  const [isUpdateTrainingTempToast, setIsUpdateTrainingTempToast] =
    useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      usersFields: "_id email name profile_picture_url",
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const assignTrainingTempFormFields = [
    { name: "trainingId", label: "Training Template" },
    { name: "user", label: "User" },
  ];

  const generateValidationSchema = (fields) => {
    const validationObject = {};

    fields?.forEach((field) => {
      if (field.name === "user") {
        validationObject[field.name] = Yup.array()
          .min(1, `${field.label} is required`)
          .required(`${field.label} is required`);
      } else {
        validationObject[field.name] = Yup.string().required(
          `${field.label} is required`
        );
      }
    });

    return Yup.object().shape(validationObject);
  };

  const validationSchema = generateValidationSchema(
    assignTrainingTempFormFields
  );


  const [showDeleteTraining, setShowDeleteTraining] = useState(false);
  const [trainingId, setTrainingIdData] = useState({
    id: null,
  });
  // const [isTraining, setIsTraining] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);


  const suFormData = {
    trainingId: "",
    user: [],
  }
  const assignTraTempFormData = useFormik({
    initialValues: suFormData,
    validationSchema: validationSchema,
    onSubmit: async (values, { setSubmitting, setErrors, resetForm }) => {
      try {
        setIsAssignTraTempFormSubmit(true);
        await assignTrainingTemp(values);
        console.log("Form submitted successfully!");
        resetForm();
      } catch (error) {
        console.error("Error submitting form:", error);
        setIsAssignTraTempFormSubmit(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = assignTraTempFormData;

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAssignTraTempGroup(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data?.data?.proposedTrainings?.map((item) => item);
      setUserData(result);
      setFilteredRows(result);
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      // setTimeout(() => {
      //   setIsTraining(false);
      // }, 2000);
    }
    else if (
      method === "postTrainingTemplate" &&
      data?.status === isSuccess
    ) {
      fetchAllRecords(apiQuery);
      handleCloseAssignTraTempModel();
      setIsToastAssignTraTempGroup(true);
      toastSuccessMessages(toast?.success, data?.message, "", 1000, 1000);
      setIsAssignTraTempFormSubmit(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(
      GET_ALL_TRAININGS_WITH_FULL_DETAILS_URL,
      req
    );
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  const assignTrainingTemp = catchAsync(async (req, res) => {

    const response = await getRecords(POST_ASSIGN_TRAINING_TEMPLATE_URL, req);
    // console.log(response);
    processFetchRecords("postTrainingTemplate", response);
  });

  useEffect(() => {
    fetchAllRecords(apiQuery);
  }, []);

  const handleNavigate = () => {
    navigate("/add-training");
  };

  const handleShowAssignTraTempModel = (e) => {
    e?.preventDefault();
    setIsAssignTraTempModelOpen(true);
  };

  const handleCloseAssignTraTempModel = (e) => {
    resetForm();
    setIsAssignTraTempModelOpen(false);
    fetchAllRecords(apiQuery);
  };

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        userData,
        lowercaseSearch,
        (record) => record?.prpsName?.toLowerCase()
      );
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };





  // const handleUsersRowClicked = (e, row, status) => {
  //   e?.preventDefault();
  //   setTrainingStatus(status);
  //   setUsersListings(row);
  //   setIsUsersTraLisModelOpen(true);
  // };
  const [trainingData, setTrainingData] = useState();
  const handleTrainingViewClick = (row) => {
    setViewTrainingTemp(!viewTrainingTemp);
    setTrainingData(row)
  };
  const handleTrainingUpdateClick = (row) => {
    setUpdateTrainingTemp(row);
    setIsTraUpdateModel(true);
  };

  const handleCloseTraUpdateModel = (isUpdated) => {
    setUpdateTrainingTemp(false);
    fetchAllRecords(apiQuery);
    setIsTraUpdateModel(false);
    if (isUpdated) {
      setIsUpdateTrainingTempToast(true);
      toastSuccessMessages(toast?.success, isUpdated, "", 1000, 1000);
    }
  };

  const handleCloseUsersTraLisModel = () => {
    setTrainingStatus(false);
    setUsersListings(false);
    setIsUsersTraLisModelOpen(false);
  };

  // const handleTableRowClick = (row, e) => {
  //   const clickedOnFirstColumn = e?.target?.dataset?.columnId === "1";
  //   if (clickedOnFirstColumn) {
  //     console.log("Clicked on the first column:", row);
  //     setUpdateTrainingTemp(row);
  //     setIsTraUpdateModel(true);
  //   }
  // };

  // console.log(userData);
  const handleUsersRowClicked = (e, row, status) => {
    e?.preventDefault();
    setTrainingStatus(status);
    setUsersListings(row);
    setIsUsersTraLisModelOpen(true);
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        return (
          <span className="w-10">
            {row?.prpsName}
          </span>
        );
      },
      width: "25%",
    },
    {
      name: "Assigned",
      cell: (row) => {
        const imageElements = [];
        if (Array?.isArray(row?.users)) {
          row?.users?.slice(0, 6).forEach((user, index) => {
            const { profile_picture_url, name } = user?.user;
            imageElements?.push(
              <OverlayTrigger key={index} overlay={<Tooltip id="tooltip-disabled">{user?.user?.name}</Tooltip>}>
                <span className="imageChainBlock" style={{ zIndex: index }}>
                  <span className="chainImage" key={`image-${index}`}>
                    <img src={`${BASE_URL}${profile_picture_url}`} alt={name} />
                  </span>
                </span>
              </OverlayTrigger>

            );
          });
        }
        return (
          <div
            className="chainBlocks d-flex-m-left"
            onClick={(e) => handleUsersRowClicked(e, row, "Assigned")}
          >
            {imageElements?.length > 0 ? (
              <>
                {imageElements}{" "}
                {row?.users?.length > 6 && (
                  <span className="moreUsersCounts">
                    {row?.users?.length - 6} +
                  </span>
                )}
              </>
            ) : null}
          </div>
        );
      },
      width: "20%",
    },
    {
      name: "Completed",
      cell: (row) => {
        const imageElements = [];
        if (row?.stfTrgStatus === "completed" && Array.isArray(row?.user)) {
          row?.user?.forEach((user, index) => {
            const { profile_picture_url, name } = user;
            imageElements.push(
              <OverlayTrigger key={index} overlay={<Tooltip id="tooltip-disabled">{user?.user?.name}</Tooltip>}>
                <span className="imageChainBlock" style={{ zIndex: index }}>
                  <span className="chainImage" key={`image-${index}`}>
                    <img src={`${BASE_URL}${profile_picture_url}`} alt={name} />
                  </span>
                </span>
              </OverlayTrigger>
            );
          });
        }

        return (
          <div
            className="chainBlocks"
            onClick={(e) => handleUsersRowClicked(e, row, "Completed")}
          >
            {imageElements?.length > 0 ? (
              <>
                {imageElements}{" "}
                <span className="moreUsersCounts">
                  {imageElements?.length - 1} +
                </span>
              </>
            ) : (
              <span></span>
            )}
          </div>
        );
      },
      width: "20%",
    },
    {
      name: "Over Due",
      cell: (row) => {
        const imageElements = [];
        if (row?.stfTrgStatus === "overdue" && Array.isArray(row?.user)) {
          row?.user?.forEach((user, index) => {
            const { profile_picture_url, name } = user;
            imageElements.push(
              <OverlayTrigger key={index} overlay={<Tooltip id="tooltip-disabled">{user?.user?.name}</Tooltip>}>
                <span className="imageChainBlock" style={{ zIndex: index }}>
                  <span className="chainImage" key={`image-${index}`}>
                    <img src={`${BASE_URL}${profile_picture_url}`} alt={name} />
                  </span>
                </span>
              </OverlayTrigger>
            );
          });
        }
        return (
          <div
            className="chainBlocks"
            onClick={(e) => handleUsersRowClicked(e, row, "Over Due")}
          >
            {imageElements?.length > 0 ? (
              <>
                {imageElements}{" "}
                <span className="moreUsersCounts">
                  {imageElements?.length - 1} +
                </span>
              </>
            ) : (
              <span></span>
            )}
          </div>
        );
      },
      width: "20%",
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">
            <span className="edit"
              onClick={() => handleTrainingViewClick(row)}
            >
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span>
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span
                className="edit"
                onClick={(e) => handleTrainingUpdateClick(row)}
              >
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeleteTraining(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      width: "15%",
      center: true,
      wrap: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  // Delete Training records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (trainingId !== "") {
      const response = await deleteRecords(
        REMOVE_TRAINING_TEMPLATE_URL,
        trainingId
      );

      // setIsTraining(true);
      processFetchRecords("delete", response);
      fetchAllRecords(apiQuery);
      setIsSubmitButtonClicked(false);
      setShowDeleteTraining(!showDeleteTraining);
    } else {
      setShowDeleteTraining(!showDeleteTraining);
      setIsSubmitButtonClicked(false);
      toastErrorMessages(toast.error, "Unable to remove", 1000);
    }
  });

  const subModalDeleteTraining = (delTrainingId) => {
    setTrainingIdData({ id: delTrainingId });
    setShowDeleteTraining(!showDeleteTraining);
  };

  return (
    <>
      <div className="mainContent pbsPlan">
        <div className="dataTableWrapper">
          <DataTable
            className="maxWidthTable"
            columns={filteredColumns}
            data={filteredRows}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="600px"
            highlightOnHover
            actions={
              <>
                <span className="btn btn-sm btn-primary" onClick={handleNavigate}>Add New Training</span>
                {" "}
                <span className="btn btn-sm btn-outline-primary" onClick={handleShowAssignTraTempModel}>
                  Assign Training
                </span>
              </>
            }
            responsive={true}
            subHeader={true}
            subHeaderComponent={
              <div className="searchContainer">
                <div className="searchTextBox">
                  <input
                    type="text"
                    placeholder="Type your search"
                    value={search}
                    onChange={handleSearch}
                  />
                </div>
              </div>
            }
            subHeaderAlign="left"
            subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          // onRowClicked={handleTableRowClick}
          />
        </div>
      </div>

      {isAssignTrainingModelOpen && (
        <Modal
          show={isAssignTrainingModelOpen}
          onHide={handleCloseAssignTraTempModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Assign Training Template</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            <>
              <AssignTraining handleCloseAssignTraTempModel={handleCloseAssignTraTempModel} />
            </>
          </Modal.Body>

        </Modal>
      )}


      {/* Delete Training */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteTraining}
          onHide={subModalDeleteTraining}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this item?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteTraining}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>

      {usersListings && (
        <ItemListModal
          handleShowModel={isUsersTraLisModelOpen}
          onClose={handleCloseUsersTraLisModel}
          items={usersListings}
          status={trainingStatus}
        />
      )}
      {updateTrainingTemp && (
        <UpdateTrainingModel
          handleShowModel={isTraUpdateModel}
          onClose={(e) => handleCloseTraUpdateModel(e)}
          item={updateTrainingTemp}
        />
      )}

      {viewTrainingTemp && (
        <ViewTrainingModel
          // handleShowModel={isTraUpdateModel}
          trainingData={trainingData}
          onClose={handleTrainingViewClick}
          item={viewTrainingTemp}
        />
      )}
      {isToastAssignTraTempGroup && <ToastContainer />}
      {isUpdateTrainingTempToast && <ToastContainer />}
    </>
  );
}

export default TrainingGroup;
