// RosterHelper.js
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_URL, ADMIN_ID } from "../../../env";
import {
    postRecords,
    catchAsync,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
} from "../../../helper/helper";

// Drag and Drop Staff Member component functionality
export function HandleDragDropStaffMember(
    event,
    variableRosterData,
    variableStaffData,
    variableSetter,
    draggedLocationId,
    calendarDays,
    fetchAllLiveRostersUrl,
    setIsRotaUserShift) {
    const { active, over } = event;

    if (!over) return;

    const sourceId = active.id;
    const destinationId = over.id;

    if (sourceId === destinationId) return;

    const [destinationStaffId, destinationDayName] = destinationId.split('-');

    // Create a shallow copy of variableRosterData to modify
    const updatedRosterData = variableRosterData.map(location => ({
        ...location,
        shiftLocation: location.shiftLocation.map(shift => ({
            ...shift,
            weekArray: shift.weekArray.map(week => ({
                ...week,
                daysArray: [...week.daysArray], // Shallow copy of daysArray
            })),
        })),
    }));

    let sourceDay;

    // Find the source cell
    for (const location of updatedRosterData) {
        for (const shift of location.shiftLocation) {
            for (const week of shift.weekArray) {
                for (let day of week.daysArray) {
                    if (day._id === sourceId) {
                        sourceDay = day;
                        break;
                    }
                }
            }
        }
    }

    if (sourceDay) {
        const destinationStaff = variableStaffData?.find(staff => staff._id === destinationStaffId);

        if (destinationStaff) {
            // Find the calendarDaysItem corresponding to the destination day name
            const destinationCalendarDay = calendarDays.find(item =>
                moment(item).format("ddd") === destinationDayName
            );

            // Update the source day's `dayName`, `shiftDate`, and `staffUserId`
            sourceDay.dayName = destinationDayName;
            sourceDay.staffUserId = {
                _id: destinationStaff._id,
                staffMemberName: destinationStaff.staffMemberName,
            };

            // Ensure the shiftDate is updated to the new date
            sourceDay.shiftDate = destinationCalendarDay;

            const staffMemberId = destinationStaff?._id;
            const dayName = destinationDayName;
            const shiftDate = moment(destinationCalendarDay).valueOf();
            const shiftId = sourceDay?._id;
            const serviceUserId = sourceDay?.serviceUserId?._id;

            const targetId = shiftId;

            const locationWithWeek = updatedRosterData[0].shiftLocation?.find(location =>
                location.weekArray?.some(week =>
                    week.daysArray?.some(day => day._id === targetId)
                )
            );

            const weekNo = locationWithWeek
                ? locationWithWeek.weekArray.find(week =>
                    week.daysArray?.some(day => day._id === targetId)
                )?.weekNo
                : null;

            // console.log("🚀 ~ handleDragDropStaffMember ~ sourceDay:", sourceDay?._id);

            variableSetter(updatedRosterData);
            const dateStatus = moment(shiftDate).endOf("day").valueOf() >= moment().startOf("day").valueOf() ? true : false;
            // console.log("🚀 ~ HandleDragDropStaffMember ~ dateStatus:", dateStatus)

            const suFormData = {
                rosterId: variableRosterData[0]?._id,
                locationId: draggedLocationId,
                shiftId: shiftId,
                dayName: dayName,
                staffUserId: staffMemberId,
                shiftDate: dateStatus ? shiftDate : "",
                serviceUserId: serviceUserId,
                active: sourceDay?.active,
                addedby: ADMIN_ID,
                isDelete: sourceDay?.isDelete,
                isOverNightStay: sourceDay?.isOverNightStay,
                isSleepOver: sourceDay?.isSleepOver,
                lastModifiedBy: ADMIN_ID,
                note: sourceDay?.note,
                serviceUserAssigned: sourceDay?.serviceUserAssigned,
                shiftEndTime: sourceDay?.shiftEndTime,
                shiftStartTime: sourceDay?.shiftStartTime,
                staffAssigned: sourceDay?.staffAssigned,
                weekNo: weekNo,
                workHrs: sourceDay?.workHrs
            };
            // console.log("🚀 ~ handleDragDropStaffMember ~ suFormData:", suFormData)


            const processPostRecords = (method, data) => {
                // console.log(data);
                if (data?.status !== isSuccess) {
                    setIsRotaUserShift(true)
                    toastErrorMessages(toast.error, data?.message, "", 500, 500);
                } else if (
                    method === "postEditUserShiftFormData" &&
                    data?.status === isSuccess
                ) {
                    setIsRotaUserShift(true)
                    toastSuccessMessages(toast.success, data?.message, "", 500, 500);
                }
                setTimeout(() => {
                    setIsRotaUserShift(false);
                }, 2000);
            };

            const postEditUserShiftFormData = catchAsync(async (req, res) => {
                console.log("🚀 ~ postEditUserShiftFormData ~ suFormData:", suFormData)
                if (!dateStatus) {
                    setIsRotaUserShift(true)
                    toastErrorMessages(toast.error, "Unable to assign shifts to previous dates.", "", 500, 500);
                    fetchAllLiveRostersUrl();
                    setTimeout(() => {
                        setIsRotaUserShift(false);
                    }, 2000);
                }
                else {
                    const response = await postRecords(POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_URL, suFormData);
                    console.log("🚀 ~ postEditUserShiftFormData ~ response:", response)
                    processPostRecords("postEditUserShiftFormData", response);
                }

            });

            postEditUserShiftFormData();

        }
    }
}

// Drag and Drop Service Users component functionality
export function HandleDragDropServiceUser(
    event,
    variableRosterData,
    variableServiceUserData,
    variableSetter,
    draggedLocationId,
    calendarDays,
    fetchAllLiveRostersUrl,
    setIsRotaUserShift) {
    const { active, over } = event;

    if (!over) return;

    const sourceId = active.id;
    const destinationId = over.id;

    if (sourceId === destinationId) return;

    const [destinationServiceUserId, destinationDayName] = destinationId.split('-');

    // Create a shallow copy of variableRosterData to modify
    const updatedRosterData = variableRosterData.map(location => ({
        ...location,
        shiftLocation: location.shiftLocation.map(shift => ({
            ...shift,
            weekArray: shift.weekArray.map(week => ({
                ...week,
                daysArray: [...week.daysArray], // Shallow copy of daysArray
            })),
        })),
    }));

    let sourceDay;

    // Find the source cell
    for (const location of updatedRosterData) {
        for (const shift of location.shiftLocation) {
            for (const week of shift.weekArray) {
                for (let day of week.daysArray) {
                    if (day._id === sourceId) {
                        sourceDay = day;
                        break;
                    }
                }
            }
        }
    }

    if (sourceDay) {
        const destinationServiceUser = variableServiceUserData?.find(serviceUser => serviceUser._id === destinationServiceUserId);

        if (destinationServiceUser) {
            // Find the calendarDaysItem corresponding to the destination day name
            const destinationCalendarDay = calendarDays.find(item =>
                moment(item).format("ddd") === destinationDayName
            );

            // Update the source day's `dayName`, `shiftDate`, and `serviceUserId`
            sourceDay.dayName = destinationDayName;
            sourceDay.serviceUserId = {
                _id: destinationServiceUser._id,
                name: destinationServiceUser.name,
            };

            // Ensure the shiftDate is updated to the new date
            sourceDay.shiftDate = destinationCalendarDay;

            const serviceUserId = destinationServiceUser?._id;
            const dayName = destinationDayName;
            const shiftDate = moment(destinationCalendarDay).valueOf();
            const shiftId = sourceDay?._id;
            const staffUserId = sourceDay?.staffUserId?._id;

            const targetId = shiftId;

            const locationWithWeek = updatedRosterData[0].shiftLocation?.find(location =>
                location.weekArray?.some(week =>
                    week.daysArray?.some(day => day._id === targetId)
                )
            );

            const weekNo = locationWithWeek
                ? locationWithWeek.weekArray.find(week =>
                    week.daysArray?.some(day => day._id === targetId)
                )?.weekNo
                : null;

            // console.log("🚀 ~ handleDragDropserviceUser ~ sourceDay:", sourceDay);

            variableSetter(updatedRosterData);
            const dateStatus = moment(shiftDate).endOf("day").valueOf() >= moment().startOf("day").valueOf() ? true : false;
            console.log("🚀 ~ HandleDragDropServiceUser ~ dateStatus:", dateStatus)

            const suFormData = {
                rosterId: variableRosterData[0]?._id,
                locationId: draggedLocationId,
                shiftId: shiftId,
                dayName: dayName,
                staffUserId: staffUserId,
                shiftDate: dateStatus ? shiftDate : "",
                serviceUserId: serviceUserId,
                active: sourceDay?.active,
                addedby: ADMIN_ID,
                isDelete: sourceDay?.isDelete,
                isOverNightStay: sourceDay?.isOverNightStay,
                isSleepOver: sourceDay?.isSleepOver,
                lastModifiedBy: ADMIN_ID,
                note: sourceDay?.note,
                serviceUserAssigned: sourceDay?.serviceUserAssigned,
                shiftEndTime: sourceDay?.shiftEndTime,
                shiftStartTime: sourceDay?.shiftStartTime,
                staffAssigned: sourceDay?.staffAssigned,
                weekNo: weekNo,
                workHrs: sourceDay?.workHrs
            };
            // console.log("🚀 ~ handleDragDropStaffMember ~ suFormData:", suFormData)


            const processPostRecords = (method, data) => {
                // console.log(data);
                if (data?.status !== isSuccess) {
                    setIsRotaUserShift(true)
                    toastErrorMessages(toast.error, data?.message, "", 500, 500);
                } else if (
                    method === "postEditUserShiftFormData" &&
                    data?.status === isSuccess
                ) {
                    setIsRotaUserShift(true)
                    toastSuccessMessages(toast.success, data?.message, "", 500, 500);
                }
                setTimeout(() => {
                    setIsRotaUserShift(false);
                }, 2000);
            };

            const postEditUserShiftFormData = catchAsync(async (req, res) => {
                console.log("🚀 ~ postEditUserShiftFormData ~ suFormData:", suFormData)
                if (!dateStatus) {
                    setIsRotaUserShift(true)
                    toastErrorMessages(toast.error, "Unable to assign shifts to previous dates.", "", 500, 500);
                    fetchAllLiveRostersUrl();
                    setTimeout(() => {
                        setIsRotaUserShift(false);
                    }, 2000);
                }
                else {
                    const response = await postRecords(POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_URL, suFormData);
                    console.log("🚀 ~ postEditUserShiftFormData ~ response:", response)
                    processPostRecords("postEditUserShiftFormData", response);
                }

            });

            postEditUserShiftFormData();
        }
    }
}
