import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import TrainingGroup from "./TrainingGroup";
import TrainingIndividual from "./TrainingIndividual";

function Training() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userID = searchParams.get("userId");

  return (
    <Layout pageTitleText="Training">
      {userID ? <TrainingIndividual userIdData={userID} /> : <TrainingGroup />}
    </Layout>
  );
}

export default Training;
