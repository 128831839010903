import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { POST_SERVICE_USER_SIGNUP_URL } from "../../env";
import {
  postRecords,
  isSuccess,
  isFail,
  catchAsync,
  updateFormFields,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import Input from "../../components/forms-fields/Input";
import TextArea from "../../components/forms-fields/TextArea";
import { useFormik } from "formik";
import { addSUSignUp } from "../../schemas";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function InformationForm() {
  const [formData, setFormData] = useState({
    profilePic: "/uploads/dp/default.png",
    name: "",
    email: "",
    password: "123456",
    phoneNumber: "",
    deviceId: "123",
    version: "0",
    role: "serviceuser",
    userModelName: "serviceUser",
    active: true,
    is_verified: true,
    approved: true,
    fcm_tokens: {
      token:
        "fY6_DGfGQ06OW4BUVEsEwG:APA91bGUFza2uhaaR0miN2jtY0ut7RuA5ObleZvqv2X8KLOBgksmDmgc9sHDdea-DBvHmz1aUwX1uhkysk92x50WQCLHPDD1VwGX5ybKhUwVdq4aBfI24vhXqMB-FksWXEZwzDAi9BA_",
      deviceType: "android",
    },
    location: {
      type: "Point",
      coordinates: [74.28911289869138, 31.624848273644956],
    },
    //service user fields
    suLastName: "",
    referenceId: "",
    suFirstMiddleName: "",
    suPreferredName: "",
    suSsn: "",
    suSex: "Male",
    suTitle: "",
    suDOB: "",
    suAge: "",
    suReferredBY: "",
    suFamilyHead: "",
    suAddress1: "",
    suAddress2: "",
    suCity: "",
    suState: "",
    suZip: "",
    suFirstVisitDate: "",
    suLastVisitDate: "",
    providerName: "",
    suHomePhone: "",
    suWorkPhone: "",
    suMobileHomeNo: "",
    suMobileWorkNo: "",
    suEmailHome: "",
    suEmailWork: "",
    suPrefHomeNo: "",
    suPrefWorkNo: "",
    suEmergencyContact: "",
    seMedicalAlert: "",
    suNote:
      "",
    diagnosises: [
      {
        diagnosisText: "some text",
        diagnosisDate: "2023-11-01 00:05:00",
        diagnosisBy: "Dr. Michael Taylor",
        isCurrentDiagnosis: true,
      },
    ],
    aboutPatient: {
      aboutText: "About",
      aboutDate: "2023-11-01 00:00:00",
      aboutBy: "John Smith",
    },
    shifts: [],
    serviceUserMedications: [],
    homeVisitSignOut: [],
  });

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: addSUSignUp,

      onSubmit: catchAsync(async (req, res) => {
        try {
          const result = await postRecords(
            POST_SERVICE_USER_SIGNUP_URL,
            formData
          );

          if (result?.status === isSuccess) {
            toastSuccessMessages(
              toast?.success,
              result?.message,
              "/service-users",
              2000,
              2000
            );
          } else if (result?.status === isFail) {
            toastErrorMessages(toast?.error, result?.message, 3000);
          }
        } catch (error) {}
      }),
    });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...formData };
    let trimmedValue = value.trim();

    if (name === "suFirstMiddleName") {
      updatedData = {
        ...updatedData,
        name: trimmedValue,
        [name]: trimmedValue,
      };
    }
    if (name === "suEmailHome") {
      updatedData = {
        ...updatedData,
        email: trimmedValue,
        [name]: trimmedValue,
      };
    } else {
      updatedData = {
        ...updatedData,
        [name]: trimmedValue,
      };
    }

    setFormData(updatedData);
    handleChange(e);
    handleBlur(e);
  };

  // Function to handle the profile picture
  const handleProfilePicChange = (e) => {
    e?.preventDefault();
    updateFormFields(e, setFormData, "image");
  };

  return (
    <>
      <div className="youngInformationForm">
        <form onSubmit={handleSubmit}>
          {/* First Row */}
          <div className="row">
            <div className="col-md-4">
              <Input
                type="text"
                id="referenceId"
                name="referenceId"
                value={values?.referenceId}
                onChange={handleInputChange}
                onBlur={handleBlur}
                labelText="Reference ID"
                customError={
                  errors.referenceId && touched.referenceId
                }
                errorMessage={errors.referenceId}
                errorClass={
                  errors.referenceId && touched.referenceId
                }
                requiredStar={true}
              />
            </div>
          </div>
          <div className="row">

            <div className="col-md-4">
              <Input
                type="text"
                id="suFirstMiddleName"
                name="suFirstMiddleName"
                value={values?.suFirstMiddleName}
                onChange={handleInputChange}
                onBlur={handleBlur}
                labelText="First Name"
                customError={
                  errors.suFirstMiddleName && touched.suFirstMiddleName
                }
                errorMessage={errors.suFirstMiddleName}
                errorClass={
                  errors.suFirstMiddleName && touched.suFirstMiddleName
                }
                requiredStar={true}
              />
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                id="suLastName"
                name="suLastName"
                value={values?.suLastName}
                onChange={handleInputChange}
                labelText="Last Name"
              />
            </div>

            <div className="col-md-4">
              <Input
                type="text"
                id="suPreferredName"
                name="suPreferredName"
                value={values?.suPreferredName}
                onChange={handleInputChange}
                labelText="Preferred Name"
              />
            </div>
          </div>
          {/* Second Row */}
          <div className="row">
            <div className="col-md-4">
              <Input
                type="text"
                id="suSsn"
                name="suSsn"
                value={values?.suSsn}
                onChange={handleInputChange}
                labelText="SSN"
              />
            </div>
            <div className="col-lg-2 col-md-4 pr-0">
              <div className="form-group div30">
                <select
                  name="suSex"
                  id="suSex"
                  value={values?.suSex}
                  onChange={handleInputChange}
                >
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                </select>
                <label htmlFor="suSex" className="floatingLabel">
                  Gender
                </label>
              </div>
            </div>
            <div className="col-lg-2 col-md-4">
              <div className="form-group div30">
                <select
                  name="suTitle"
                  id="suTitle"
                  value={values?.suTitle}
                  onChange={handleInputChange}
                >
                  <option value="Mr">Mr.</option>
                  <option value="Ms">Ms.</option>
                </select>
                <label htmlFor="suTitle" className="floatingLabel">
                  Title
                </label>
              </div>
            </div>

            <div className="col-lg-2 col-md-4 pr-0">
              <Input
                type="date"
                id="suDOB"
                name="suDOB"
                value={values?.suDOB}
                onChange={handleInputChange}
                labelText="Date of Birth"
              />
            </div>
            <div className="col-lg-2 col-md-4 pl-0">
              <Input
                type="number"
                id="suAge"
                name="suAge"
                value={values?.suAge}
                onChange={handleInputChange}
                labelText="Age"
              />
            </div>
          </div>
          {/* Third Row */}
          <div className="row">
            <div className="col-md-4">
              <Input
                type="text"
                id="suReferredBY"
                name="suReferredBY"
                value={values?.suReferredBY}
                onChange={handleInputChange}
                labelText="Referred by"
              />
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                id="suFamilyHead"
                name="suFamilyHead"
                value={values?.suFamilyHead}
                onChange={handleInputChange}
                labelText="Family Head"
              />
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                id="suAddress1"
                name="suAddress1"
                value={values?.suAddress1}
                onChange={handleInputChange}
                labelText="Address 1"
              />
            </div>
          </div>
          {/* Fourth Row */}
          <div className="row">
            <div className="col-md-4">
              <Input
                type="text"
                id="suAddress2"
                name="suAddress2"
                value={values?.suAddress2}
                onChange={handleInputChange}
                labelText="Address 2"
              />
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                id="suCity"
                name="suCity"
                value={values?.suCity}
                onChange={handleInputChange}
                labelText="City"
              />
            </div>
            <div className="col-lg-2 col-md-4 pr-0">
              <Input
                type="text"
                id="suState"
                name="suState"
                value={values?.suState}
                onChange={handleInputChange}
                labelText="State"
              />
            </div>
            <div className="col-lg-2 col-md-4 pl-0">
              <Input
                type="text"
                id="suZip"
                name="suZip"
                value={values?.suZip}
                onChange={handleInputChange}
                labelText="Zip"
              />
            </div>
          </div>
          {/* Fifth Row */}
          <div className="row">
            <div className="col-lg-2 col-md-4 pr-0">
              <Input
                type="date"
                id="suFirstVisitDate"
                name="suFirstVisitDate"
                value={values?.suFirstVisitDate}
                onChange={handleInputChange}
                labelText="First Visit Date"
              />
            </div>
            <div className="col-lg-2 col-md-4">
              <Input
                type="date"
                id="suLastVisitDate"
                name="suLastVisitDate"
                value={values?.suLastVisitDate}
                onChange={handleInputChange}
                labelText="Last Visit Date"
              />
            </div>

            <div className="col-md-4">
              <Input
                type="text"
                id="providerName"
                name="providerName"
                value={values?.providerName}
                onChange={handleInputChange}
                labelText="Provider"
              />
            </div>
            <div className="col-lg-2 col-md-4 pr-0">
              <Input
                type="text"
                id="suHomePhone"
                name="suHomePhone"
                value={values?.suHomePhone}
                onChange={handleInputChange}
                labelText="Home Phone No."
                customError={errors.suHomePhone && touched.suHomePhone}
                errorMessage={errors.suHomePhone}
                errorClass={errors.suHomePhone && touched.suHomePhone}
              />
            </div>
            <div className="col-lg-2 col-md-4">
              <Input
                type="text"
                id="suWorkPhone"
                name="suWorkPhone"
                value={values?.suWorkPhone}
                onChange={handleInputChange}
                labelText="Work Phone No."
                customError={errors.suWorkPhone && touched.suWorkPhone}
                errorMessage={errors.suWorkPhone}
                errorClass={errors.suWorkPhone && touched.suWorkPhone}
              />
            </div>
          </div>
          {/* Sixth Row */}
          <div className="row">
            <div className="col-lg-2 col-md-4 pr-0">
              <Input
                type="text"
                id="suMobileHomeNo"
                name="suMobileHomeNo"
                value={values?.suMobileHomeNo}
                onChange={handleInputChange}
                onBlur={handleBlur}
                labelText="Mobile Home No."
                customError={errors.suMobileHomeNo && touched.suMobileHomeNo}
                errorMessage={errors.suMobileHomeNo}
                errorClass={errors.suMobileHomeNo && touched.suMobileHomeNo}
                requiredStar={true}
              />
            </div>
            <div className="col-lg-2 col-md-4">
              <Input
                type="text"
                id="suMobileWorkNo"
                name="suMobileWorkNo"
                value={values?.suMobileWorkNo}
                onChange={handleInputChange}
                labelText="Mobile Work No."
                customError={errors.suMobileWorkNo && touched.suMobileWorkNo}
                errorMessage={errors.suMobileWorkNo}
                errorClass={errors.suMobileWorkNo && touched.suMobileWorkNo}
              />
            </div>

            <div className="col-md-4">
              <Input
                type="text"
                id="suEmailHome"
                name="suEmailHome"
                value={values?.suEmailHome}
                onChange={handleInputChange}
                onBlur={handleBlur}
                labelText="Email Home"
                customError={errors.suEmailHome && touched.suEmailHome}
                errorMessage={errors.suEmailHome}
                errorClass={errors.suEmailHome && touched.suEmailHome}
                requiredStar={true}
              />
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                id="suEmailWork"
                name="suEmailWork"
                value={values?.suEmailWork}
                onChange={handleInputChange}
                labelText="Email Work"
                customError={errors.suEmailWork && touched.suEmailWork}
                errorMessage={errors.suEmailWork}
                errorClass={errors.suEmailWork && touched.suEmailWork}
              />
            </div>
          </div>
          {/* Seventh Row */}
          <div className="row">
            <div className="col-lg-2 col-md-4 pr-0">
              <Input
                type="text"
                id="suPrefHomeNo"
                name="suPrefHomeNo"
                value={values?.suPrefHomeNo}
                onChange={handleInputChange}
                labelText="Pref. Home No."
                customError={errors.suPrefHomeNo && touched.suPrefHomeNo}
                errorMessage={errors.suPrefHomeNo}
                errorClass={errors.suPrefHomeNo && touched.suPrefHomeNo}
              />
            </div>
            <div className="col-lg-2 col-md-4">
              <Input
                type="text"
                id="suPrefWorkNo"
                name="suPrefWorkNo"
                value={values?.suPrefWorkNo}
                onChange={handleInputChange}
                labelText="Pref. Work No."
                customError={errors.suPrefWorkNo && touched.suPrefWorkNo}
                errorMessage={errors.suPrefWorkNo}
                errorClass={errors.suPrefWorkNo && touched.suPrefWorkNo}
              />
            </div>

            <div className="col-md-4">
              <Input
                type="text"
                id="suEmergencyContact"
                name="suEmergencyContact"
                value={values?.suEmergencyContact}
                onChange={handleInputChange}
                labelText="Emergency Contact"
                customError={
                  errors.suEmergencyContact && touched.suEmergencyContact
                }
                errorMessage={errors.suEmergencyContact}
                errorClass={
                  errors.suEmergencyContact && touched.suEmergencyContact
                }
              />
            </div>
            <div className="col-md-4">
              <Input
                type="text"
                id="seMedicalAlert"
                name="seMedicalAlert"
                value={values?.seMedicalAlert}
                onChange={handleInputChange}
                labelText="Funding by"
                customError={errors.seMedicalAlert && touched.seMedicalAlert}
                errorMessage={errors.seMedicalAlert}
                erroClass={errors.seMedicalAlert && touched.seMedicalAlert}
              />
            </div>
            <div className="col-md-4">
              <div className="form-group">
                <Input
                  type="file"
                  name="suVideo"
                  accept={"video/*"}
                  // onChangeFun={(e) => handleProfilePicChange(e)}
                  labelText="Upload Video"
                  className="form-control uploadProfileImage"
                />
              </div>
            </div>
          </div>
          {/* Eight Row */}
          <div className="row">
            <div className="col-md-12">
              <TextArea
                id="suNote"
                name="suNote"
                value={values?.suNote}
                onChange={handleInputChange}
                labelText="Note"
                rows="3"
              />
            </div>
          </div>
          <footer>
            <div className="formActionButtons">
              <Link
                to="/service-users"
                className="btn btn-sm btn-outline-primary"
              >
                Back
              </Link>
              <Button
                variant="primary"
                className="btn-sm"
                // onClick={handleFormSubmit}
                type="submit"
              >
                Submit
              </Button>
            </div>
          </footer>
        </form>
      </div>
      <ToastContainer />
    </>
  );
}

export default InformationForm;
