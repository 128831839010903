import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { legacy_createStore as createStore } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "./redux/reducers/rootReducer";
import { AuthProvider } from "./permissions/AuthContext";
import App from "./App";

// Load state from local storage (if available)
const loadState = () => {
  try {
    const serializedState = localStorage.getItem("reduxState");
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

// Save state to local storage
const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem("reduxState", serializedState);
  } catch {
    // Ignore write errors
  }
};

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(), // Initialize store with the saved state
  // Add middleware, devtools, etc. here if needed
});

store.subscribe(() => {
  saveState(store.getState()); // Save state to local storage whenever it changes
});

ReactDOM.render(
  <DndProvider backend={HTML5Backend}>
    <Provider store={store}>
      <AuthProvider>
        <App />
      </AuthProvider>
    </Provider>
  </DndProvider>,
  document.getElementById("root")
);
