import React from "react";
import Layout from "../../components/layouts/Layout";
import InformationForm from "./InformationForm";

function YoungInformation() {
  return (
    <>
      <Layout pageTitleText="Young Information">
        <div className="mainContent youngInformation">
          <header>
            <div className="usersNameInfo">
              <div className="userImage">
                <img src="https://placehold.co/150x150" alt="Terry" />
              </div>
              <div className="userInfo">
                <span className="infoBlock">Terry</span>
              </div>
            </div>
          </header>

          <InformationForm />
        </div>
      </Layout>
    </>
  );
}

export default YoungInformation;
