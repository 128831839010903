import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  filterColumns,
  filterRowsByProperty,
  displayEnumProperty,
  filterArrayBySearchTerm,
  toastErrorMessages,
  formateDate,
  catchAsync,
  calculateHours,
} from "../../helper/helper";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";

function StaffRecordsGroup() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isToastStaffRecords, setIsToastStaffRecords] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastStaffRecords(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const staffMembers = data?.data?.staffMembers?.map((item) => item);
      const filteredRows = filterRowsByProperty(
        staffMembers,
        (row) => row?.user?.name
      );
      setUserData(filteredRows);
      setFilteredRows(filteredRows);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        userData,
        lowercaseSearch,
        (record) => record?.user?.name?.toLowerCase()
      );
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };

  const handleRowClickStaffMember = (e, row) => {
    e.preventDefault();
    navigate("/staff-profile", { state: { userId: row } });
  };

  const handleTableRowClick = (row) => {
    navigate("/staff-profile", { state: { userId: row } });
  };
  const columns = [
    {
      name: "#",
      selector: (row, index) => (
        <span onClick={(e) => handleRowClickStaffMember(e, row)}>
          {index + 1}
        </span>
      ),
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <span
            className="usersNameInfo"
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.user?.profile_picture_url}`}
                alt={row?.name}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.user?.name}`}</span>
            </span>
          </span>
        );
      },
      width: "220px",
    },
    {
      name: "Supervisions",
      selector: (row) => {
        const formatEdDate = formateDate(row?.supervision?.sprDueDate, "m/d");
        const currentDate = new Date();
        const dueDate = new Date(row?.supervision?.sprDueDate);
        const isDueThisMonth =
          currentDate?.getMonth() === dueDate.getMonth() &&
          currentDate?.getFullYear() === dueDate.getFullYear();
        const className = isDueThisMonth
          ? "tableText danger"
          : "tableText warning";
        return (
          <span
            className={className}
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >{`Due Date - ${formatEdDate}`}</span>
        );
      },
      width: "150px",
    },
    {
      name: "Manager",
      selector: (row) => {
        return (
          <span onClick={(e) => handleRowClickStaffMember(e, row)}>
            {row?.managerId?.name}
          </span>
        );
      },
      width: "150px",
    },
    {
      name: "Role",
      selector: (row) => {
        const userRole = displayEnumProperty("role", row?.user?.role);
        return (
          <span onClick={(e) => handleRowClickStaffMember(e, row)}>
            {userRole}
          </span>
        );
      },
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => {
        return (
          <a
            href={`mailto:${row?.user?.email}`}
            className="blackColor"
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >
            {row?.user?.email}
          </a>
        );
      },
      width: "180px",
    },
    {
      name: "Contact No.",
      selector: (row) => {
        return (
          <a
            href={`tel:${row?.user?.phoneNumber}`}
            className="blackColor"
            onClick={(e) => handleRowClickStaffMember(e, row)}
          >
            {row?.user?.phoneNumber}
          </a>
        );
      },
      width: "150px",
    },
    {
      name: "DOB",
      selector: (row) => {
        const result = formateDate(row?.stafDob, "m/d/y");
        return (
          <span onClick={(e) => handleRowClickStaffMember(e, row)}>
            {result}
          </span>
        );
      },
      width: "150px",
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  return (
    <div className="mainContent staffRecordsGroup">
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable"
          columns={filteredColumns}
          data={filteredRows}
          pagination
          paginationRowsPerPageOptions={[10, 25, 50, 100]}
          paginationPerPage={10}
          fixedHeader
          fixedHeaderScrollHeight="520px"
          highlightOnHover
          actions={
            <>
              {/* <button className="btn btn-sm btn-outline-primary">
                Archived Staff
              </button>{" "} */}
              <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                <Link to="/add-new-staff" className="btn btn-sm btn-primary">
                  Add New
                </Link>
              </ProtectedComponent>
            </>
          }
          responsive={true}
          subHeader={true}
          subHeaderComponent={
            <div className="searchContainer">
              <div className="searchTextBox">
                <input
                  type="text"
                  placeholder="Type your search"
                  value={search}
                  onChange={handleSearch}
                />
              </div>
            </div>
          }
          subHeaderAlign="left"
          subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
          onRowClicked={handleTableRowClick}
        />
      </div>
      {isToastStaffRecords && <ToastContainer />}
    </div>
  );
}

export default StaffRecordsGroup;
