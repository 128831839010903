import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { onBoardingstageValidation } from "../../../schemas";
import { Form } from "react-bootstrap";
import {
  POST_UPDATE_RECRUITMENT_CANDIDATE_URL,
  UPLOAD_REFERENCE_DOCUMENT_URL,
  ADMIN_AUTH_TOKEN,
  BASE_URL,
  ADMIN_ID
} from "../../../env";
import {
  postRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  catchAsync,
} from "../../../helper/helper";
import TextArea from "../../../components/forms-fields/TextArea";
import moment from "moment";
import Input from "../../../components/forms-fields/Input";

function OnBoardingProcessPopup({ recCandidateDetail, subModalViewCarePlans }) {
  // console.log("🚀 ~ OnBoardingProcessPopup ~ recCandidateDetail:", recCandidateDetail)
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [uploadedFileLink, setUploadedFileLink] = useState(false);
  const [isRecrUserData, setIsRecrUserData] = useState(false);
  const [trainingStatus, setTrainingStatus] = useState(false);
  const [joiningDate, setJoiningDate] = useState(moment().valueOf());
  const [commentBox, setCommentBox] = useState("");
  const handleClose = () => {
    subModalViewCarePlans(subModalViewCarePlans);
  };





  const [suFormData, setSuFormData] = useState({
    recruitId: recCandidateDetail?._id || "",
    inductionDate: joiningDate,
    trainingCompletion: trainingStatus,
    commentBox: ""
  });
  // console.log("🚀 ~ OnBoardingProcessPopup ~ suFormDatasuFormDatasuFormData:", suFormData)
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: onBoardingstageValidation,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsRecrUserData(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (method === "postAddCarePlansFormData" && data?.status === isSuccess) {
      setIsRecrUserData(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleClose();
      }, 2000);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const updateValues = {

      ...values,
      inductionDate: moment(joiningDate).valueOf(),
      trainingCompletion: trainingStatus,
      notes: [
        ...recCandidateDetail?.notes,
        ...(commentBox !== "" ? [{
          staffId: ADMIN_ID,
          note: commentBox,
          process: recCandidateDetail?.processStatus,
        }] : [])
      ],
    };
    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_UPDATE_RECRUITMENT_CANDIDATE_URL, updateValues);
    console.log("🚀 ~ postAddCarePlansFormData ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });





  return (
    <>
      <main>
        <form onSubmit={handleSubmit} encType="multipart/form-data">

          <div className="row">

            <div className="col-md-12">
              <Input
                type="date"
                id=""
                name="inductionDate"
                value={moment(values?.inductionDate).format('YYYY-MM-DD')}
                onChange={(e) => { setJoiningDate(e.target.value) }}
                onBlur={handleBlur}
                labelText="Induction Date"
                customError={touched?.inductionDate && errors?.inductionDate}
                errorMessage={errors?.inductionDate}
                erroClass={
                  touched?.inductionDate && errors?.inductionDate
                    ? "validationError"
                    : ""
                }
                requiredStar={true}
              />

            </div>

            <div className="col-md-12 mb-3">
              <Form.Check
                type="checkbox"
                id={'trainingCompletion'}
                name={'trainingCompletion'}
                checked={values?.trainingCompletion}
                onChange={(e) => {
                  setFieldValue('trainingCompletion', e.target.checked);
                  setTrainingStatus(!trainingStatus);
                }}
                label={'Please tick to confirm of completion of online training'}
              />
              {errors.trainingCompletion && touched.trainingCompletion && (
                <div className="validationError">
                  <div className="text-danger">{errors.trainingCompletion}</div>
                </div>
              )}
            </div>

            <div className="col-md-12">
              <TextArea
                name="commentBox"
                value={commentBox}
                onChange={(e) => { setCommentBox(e.target.value); handleChange(e); }}
                onBlur={handleBlur}
                cols={5}
                rows={5}
                labelText="Notes"

              />
            </div>
          </div>
          <div className="formActionButtons">
            {isSubmitButtonClicked ? (
              <Button
                onClick={handleClose}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
            <span
              onClick={handleClose}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
          </div>
        </form>
      </main>
      {isRecrUserData && <ToastContainer />}
    </>
  );
}

export default OnBoardingProcessPopup;
