import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getRecords, catchAsync, isSuccess } from "../../helper/helper";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../env";
import { CustomOption } from "../../helper/helperJsx";

const CustomSelect = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  filterBy,
  label,
  defaultValue,
  isDisabled,
  onBlur,
}) => {
  const [userData, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultSelection, setDefaultSelection] = useState(defaultValue);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processStaffRecordsData = (data) => {
    if (data?.status === isSuccess) {
      const filteredStaffMembers = filterBy
        ? data?.data?.staffMembers?.filter(
            (item) => item?.staffDesignation === filterBy
          )
        : data?.data?.staffMembers?.filter((item) => item?.user?.name);

      const getUsers = filteredStaffMembers?.map((item) => ({
        value: item?.user?._id,
        // value: item?._id,
        label: item?.user?.name,
        image: item?.user?.profile_picture_url,
      }));
      const foundUser = getUsers?.find(
        (user) => user?.value === defaultSelection
      );
      if (foundUser) {
        setDefaultSelection(foundUser);
        onSelect(foundUser?.value);
      }
      setRecords(getUsers);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    processStaffRecordsData(response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    fetchAllRecords();
  }, [defaultValue]);

  const handleInputChange = () => {
    setIsLoading(false);
  };

  return (
    <div
      className={`customSelectBox ${
        isDisabled ? "disabled" : ""
      } ${errorClass}`}
    >
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          <Select
            className="basic-single cursor-pointer"
            classNamePrefix="select"
            options={userData}
            defaultValue={defaultSelection}
            isSearchable
            onChange={(selectedOption) => {
              onSelect(selectedOption?.value);
            }}
            onInputChange={handleInputChange}
            getOptionLabel={(selectedOption) => selectedOption?.label}
            getOptionValue={(selectedOption) => selectedOption?.value}
            components={{ Option: CustomOption }}
            isDisabled={isDisabled}
            onBlur={onBlur}
          />
          {label && <label className="floatingLabel">{label}</label>}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default CustomSelect;
