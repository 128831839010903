import React from "react";
import { Modal, Button } from "react-bootstrap";
import PropTypes from "prop-types";
import eventDel from "../../assets/images/icons/eventDel.svg";
import ProgressComponent from "../general/ProgressComponent";

const DeletePopup = ({
  show,
  onHide,
  title,
  content,
  onConfirm,
  onCancel,
  isConfirmDeleteLoading,
}) => {
  return (
    <Modal
      show={show}
      onHide={onHide}
      className="ftcModalPopup confirmationPopup deletionConfirmation"
    >
      <Modal.Header closeButton>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="editBlockContent text-center">
          <img src={eventDel} alt={eventDel} />
          <p>{content}</p>
        </div>
      </Modal.Body>
      <Modal.Footer>
        {isConfirmDeleteLoading ? (
          <ProgressComponent />
        ) : (
          <>
            <Button className="btn-sm" variant="danger" onClick={onConfirm}>
              Yes
            </Button>
            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={onCancel}
            >
              No
            </Button>
          </>
        )}
      </Modal.Footer>
    </Modal>
  );
};

DeletePopup.propTypes = {
  show: PropTypes?.bool?.isRequired,
  onHide: PropTypes?.func?.isRequired,
  title: PropTypes?.string?.isRequired,
  content: PropTypes?.string?.isRequired,
  onConfirm: PropTypes?.func?.isRequired,
  onCancel: PropTypes?.func?.isRequired,
};

export default DeletePopup;
