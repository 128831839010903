import React, { useState, useEffect } from "react";
import moment from "moment";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import ProgressComponent from "../../../components/general/ProgressComponent";
import CustomSelectServiceUser from "../../../components/forms-fields/CustomSelectServiceUsers";
import CustomSelectWeeks from "../../../components/forms-fields/CustomSelectWeek";
import {
  PUT_UPDATE_ROTA_OPEN_PATIENT_SHIFT_SCHEDULE_URL,
  ADMIN_ID,
} from "../../../env";
import { isSuccess, postRecords } from "../../../helper/helper";
import { twoWeeksOptions, fourWeeksOptions } from "../../../helper/helperJsx";

function ModifyPatientOpenShift({
  handleShowModel,
  handleCloseModel,
  tempId,
  templateWeekNum,
  activeWeekTabNum,
  shiftArrayItem,
  selectedLocationId,
  fetchAllRecords,
  scheduleTitle,
}) {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    secondaryWeeks: Yup.array().min(1, "Please select at least one week."),
  });

  const formData = useFormik({
    initialValues: {
      tempId: "",
      locationId: "",
      shiftId: "",
      patientId: "",
      staffUserId: "",
      addeby: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitting(true);
        const res = await postRecords(
          PUT_UPDATE_ROTA_OPEN_PATIENT_SHIFT_SCHEDULE_URL,
          values
        );
        await fetchAllRecords();
        if (res?.status === isSuccess) {
          setIsFormSubmitting(false);
          handleCloseModel();
        }
      } catch (error) {
        console.error("Error", error);
        setIsFormSubmitting(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = formData;

  const handleSetValues = async () => {
    await setValues({
      tempId: tempId,
      locationId: selectedLocationId,
      shiftId: shiftArrayItem?._id,
      patientId: "",
      staffUserId: shiftArrayItem?.staffUserId?._id,
      addeby: ADMIN_ID,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (tempId) {
      handleSetValues();
    }
  }, [tempId]);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{scheduleTitle}</Modal.Title>
          </Modal.Header>
          <form method="post" onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row align-items-center mt-4">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectServiceUser
                      onSelect={(value) => setFieldValue("patientId", value)}
                      defaultValue={false}
                      label="Service User Name"
                      omBlurFunc={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isFormSubmitting ? (
                <ProgressComponent />
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={handleCloseModel}
                  >
                    Cancel
                  </Button>

                  <Button className="btn-sm" type="submit" variant="primary">
                    Save
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
}

export default ModifyPatientOpenShift;
