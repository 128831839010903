import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import PayrollGroup from "./PayrollGroup";
import PayrollIndividual from "./PayrollIndividual";

function Payroll() {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const userID = searchParams.get("userId");

  return (
    <Layout pageTitleText="Payroll">
      {userID ? <PayrollIndividual userIdData={userID} /> : <PayrollGroup />}
    </Layout>
  );
}

export default Payroll;
