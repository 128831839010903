import React, { useEffect, useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
// import PayrollBlocks from "./PayrollBlocks";
import ProgressComponent from "../../components/general/ProgressComponent";
import folderIcon from "../../assets/images/icons/folder.svg";

function AllTraining() {
  const [search, setSearch] = useState("");
  const [payroll, setPayroll] = useState([]);
  const [filterSeach, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);

  const getPayroll = async () => {
    try {
      const response = await axios.get("https://dummyjson.com/users");
      setPayroll(response.data.users);
      setFilterSearch(response.data.users);
      setIsLoading(false);
    } catch (error) {}
  };

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        return row.firstName;
      },
      width: "25%",
    },

    {
      name: "Assigned",
      selector: (row) => {
        return row.age;
      },
      center: true,
      width: "25%",
    },
    {
      name: "Due Date",
      selector: (row) => {
        return row.birthDate;
      },
      center: true,
      width: "25%",
    },
    {
      name: "Completed",
      selector: (row) => {
        return row.age;
      },
      center: true,
      width: "25%",
    },
  ];
  useEffect(() => {
    getPayroll();
  }, []);

  useEffect(() => {
    const result = payroll.filter((user) => {
      return user.firstName.toLowerCase().match(search.toLowerCase());
    });
    setFilterSearch(result);
  }, [search, payroll]);

  return (
    <>
      {/* <PayrollBlocks /> */}
      <div className="mainContent">
        <div className="dataTableWrapper">
          <DataTable
            className="maxWidthTable"
            columns={columns}
            data={filterSeach}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="600px"
            highlightOnHover
            responsive={true}
            subHeader={true}
            subHeaderComponent={
              <>
                <div className="parentTableHeader">
                  <h3>Trainings</h3>
                </div>
              </>
            }
            subHeaderAlign="left"
            subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          />
        </div>
      </div>
    </>
  );
}

export default AllTraining;
