import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import moment from "moment";
import actionButtonPlus from "../../../assets/images/icons/actionButtonPlus.svg";
import LeftArrow from "../../../assets/images/icons/LeftArrow.svg";
import RightArrow from "../../../assets/images/icons/rightArrow.svg";
import WeeklyTabsStaffView from "../WeeklyTabsStaffView";
import WeeklyTabsPatientView from "../WeeklyTabsPatientView";
import ProgressComponent from "../../../components/general/ProgressComponent";
import CreateRotaShift from "../popup/CreateRotaShift";
import {
  isSuccess,
  getRecords,
  filterRowsByProperty,
} from "../../../helper/helper";
import {
  GET_ALL_STAFF_MEMBERS_URL,
  GET_ALL_SERVICE_USERS_URL,
  GET_ALL_ROTA_TEMPLATES_URL,
} from "../../../env";

function WeeklyLocationTabs({
  getInitialLocationId,
  templateDataState,
  templateWeekNum,
  activeWeekTabNum,
  isLoadingScheduleState,
  fetchAllTempRecords,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [allPatientRecordsState, setAllPatientRecordsState] = useState(false);
  const [allStaffRecordsState, setAllStaffRecordsState] = useState(false);
  const [filteredSchedules, setFilteredSchedules] = useState(false);
  const [isShowAddScheduleModel, setIsShowAddScheduleModel] = useState(false);
  const [allRotaTemplates, setAllRotaTemplates] = useState(false);
  const defaultTbHeading = "Staff";
  const [tableHeadingNameState, setTableHeadingNameState] =
    useState(defaultTbHeading);
  const [isPatientView, setIsPatientView] = useState(false);

  function processFetchRecords(method, data) {
    if (data?.status !== isSuccess) {
      console.error(data);
    } else if (method === "getAllPatient" && data?.status === isSuccess) {
      const validServiceUsers = data?.data?.users?.filter(
        (item) => item?.modelId
      );
      setAllPatientRecordsState(validServiceUsers);
    } else if (method === "getAllStaff" && data?.status === isSuccess) {
      const result = data?.data?.staffMembers?.map((item) => item);
      const validServiceUsers = result?.filter((item) => item?.user?._id);
      setAllStaffRecordsState(validServiceUsers);
    }
  }

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: 1,
    offset: 0,
    query: {
      critarion: {
        active: true,
      },
    },
  };

  const fetchAllPatientRecords = async () => {
    try {
      const query = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
          critarion: {
            active: true,
            role: "serviceuser",
          },
        },
      };
      const response = await getRecords(GET_ALL_SERVICE_USERS_URL, query);
      processFetchRecords("getAllPatient", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllStaffRecords = async () => {
    try {
      const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
      processFetchRecords("getAllStaff", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllRotaTemplates = async () => {
    try {
      const query = {
        sortproperty: "createdAt",
        sortorder: 1,
        offset: 0,
        query: {
          critarion: {
            _id: templateDataState?._id,
          },
        },
      };
      const response = await getRecords(GET_ALL_ROTA_TEMPLATES_URL, query);
      const shiftsAtSelectedLocation =
        response?.data?.scheduleTemplates[0]?.shiftLocation?.filter((shift) => {
          return shift?.locationId?._id !== getInitialLocationId;
        });
      setAllRotaTemplates(shiftsAtSelectedLocation);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllRecords = async () => {
    try {
      await fetchAllPatientRecords();
      await fetchAllStaffRecords();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const handleShowPatientView = async () => {
    setIsLoading(true);
    await fetchAllRecords();
    setTableHeadingNameState("Service User");
    setIsPatientView(true);
  };

  const handleShowStaffView = async () => {
    setIsLoading(true);
    await fetchAllRecords();
    setTableHeadingNameState(defaultTbHeading);
    setIsPatientView(false);
  };

  const handleShowScheduleModel = (e) => {
    e?.preventDefault();
    setIsShowAddScheduleModel(true);
  };

  const handleCloseScheduleModel = () => {
    setIsShowAddScheduleModel(false);
  };

  const filterSchedules = async (
    tempRecord,
    selectedWeekNum,
    selectedLocationId
  ) => {
    setIsLoading(true);
    const filteredLocationRecord = tempRecord?.shiftLocation?.filter(
      (item) => item?.locationId?._id === selectedLocationId
    );
    if (filteredLocationRecord?.length > 0) {
      const filteredData = filteredLocationRecord?.flatMap((item) => {
        return item?.shiftArray?.filter((shiftItem) => {
          return (
            shiftItem?.primaryWeekNo === selectedWeekNum &&
            shiftItem?.isDelete === false
          );
        });
      });
      setFilteredSchedules(filteredData);
      setIsLoading(false);
    } else {
      setFilteredSchedules([]);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    fetchAllRotaTemplates();
  }, []);

  useEffect(() => {
    fetchAllRecords();
    fetchAllRotaTemplates();
  }, [activeWeekTabNum, templateDataState]);

  useEffect(() => {
    filterSchedules(templateDataState, activeWeekTabNum, getInitialLocationId);
    fetchAllRotaTemplates();
  }, []);

  useEffect(() => {
    filterSchedules(templateDataState, activeWeekTabNum, getInitialLocationId);
    fetchAllRotaTemplates();
  }, [activeWeekTabNum, templateDataState]);

  useEffect(() => {
    setIsLoading(isLoadingScheduleState);
  }, [isLoadingScheduleState]);

  return (
    <>
      <div className="rosterTabsContent">
        <div className="rosterTableContent individualStaffSchedule updateRotaSchedule">
          <div className="table-responsive">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>
                    <div className="staffCol rotaTableStaffPatientViewContainer">
                      <div
                        className={`thChildContainer rotaTableStaffPatientViewContainer  ${isPatientView && "position-left"
                          }`}
                      >
                        {isPatientView && (
                          <img
                            src={LeftArrow}
                            alt="Left Arrow"
                            className="cursor-pointer"
                            onClick={() => handleShowStaffView()}
                          />
                        )}
                        <span>{tableHeadingNameState}</span>
                        {!isPatientView && (
                          <img
                            src={RightArrow}
                            alt="Left Arrow"
                            className="cursor-pointer"
                            onClick={() => handleShowPatientView()}
                          />
                        )}
                      </div>
                      {templateDataState?.status !== "active" && (
                        <img
                          src={actionButtonPlus}
                          alt={actionButtonPlus}
                          className="cursor-pointer"
                          onClick={(e) => handleShowScheduleModel(e)}
                        />
                      )}
                    </div>
                  </th>
                  {["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]?.map(
                    (day, index) => (
                      <th key={index}>{day}</th>
                    )
                  )}
                </tr>
              </thead>
              <tbody>
                {isLoading ? (
                  <ProgressComponent />
                ) : isPatientView && !isLoading ? (
                  activeWeekTabNum &&
                  getInitialLocationId &&
                  templateDataState && (
                    <WeeklyTabsPatientView
                      selectedLocationId={getInitialLocationId}
                      scheduleTemplate={templateDataState}
                      filteredSchedule={filteredSchedules}
                      activeWeekTabNum={activeWeekTabNum}
                      allPatientRecordsState={allPatientRecordsState}
                      allRotaTemplates={allRotaTemplates}
                      getAllRecords={fetchAllTempRecords}
                    />
                  )
                ) : (
                  !isPatientView &&
                  !isLoading &&
                  activeWeekTabNum &&
                  getInitialLocationId &&
                  templateDataState && (
                    <WeeklyTabsStaffView
                      selectedLocationId={getInitialLocationId}
                      scheduleTemplate={templateDataState}
                      filteredSchedule={filteredSchedules}
                      activeWeekTabNum={activeWeekTabNum}
                      allStaffRecordsState={allStaffRecordsState}
                      allRotaTemplates={allRotaTemplates}
                      getAllRecords={fetchAllTempRecords}
                    />
                  )
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>

      {isShowAddScheduleModel &&
        getInitialLocationId &&
        templateDataState &&
        templateWeekNum &&
        activeWeekTabNum && (
          <CreateRotaShift
            handleShowModel={isShowAddScheduleModel}
            handleCloseModel={handleCloseScheduleModel}
            tempData={templateDataState && templateDataState}
            templateWeekNum={templateWeekNum}
            activeWeekTabNum={activeWeekTabNum}
            selectedLocationId={getInitialLocationId}
            fetchAllRecords={fetchAllTempRecords}
            scheduleTitle={"Create Shift"}
          />
        )}
    </>
  );
}

export default WeeklyLocationTabs;
