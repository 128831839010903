import React, { useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../../components/general/ProgressComponent";
import {
  catchAsync,
  isSuccess,
  getRecords,
  toastErrorMessages,
} from "../../../helper/helper";
import { GET_LIST_ALL_USERS_URL, BASE_URL } from "../../../env";
import { addNewChatSingleUser } from '../../../redux/actions/addNewChatSingleUserAction';
import { connect } from 'react-redux';
function ListAllMembers({ handleShowModel, onClose, addNewChatSingleUser }) {
  const navigate = useNavigate();
  const [allRecords, setAllRecords] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isToast, setIsToast] = useState(false);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: {
        active: true,
        role: {
          $in: [
            "serviceuser",
            "staffmember",
            "manager",
            "supervisor",
            "teamlead",
          ],
        },
      },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "getUsers" && data?.status === isSuccess) {
      const result = data?.data?.users?.map((item) => item);
      setAllRecords(result);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    try {
      const response = await getRecords(GET_LIST_ALL_USERS_URL, apiQuery);
      processFetchRecords("getUsers", response);
      // console.log("🚀 ~ fetchAllRecords ~ allRecordsallRecordsallRecords:", allRecords)
      console.log("🚀 ~ fetchAllRecords ~ responseresponseresponse:", response)
    } catch (error) {
      console.error("Error fetching records:", error);
    }

  });

  const handleSearchInputChange = (e) => {
    setSearchInput(e?.target?.value?.toLowerCase());
  };

  const handleOpenMessage = (userData) => {
    console.log("🚀 ~ handleOpenMessage ~ userData:", userData)
    try {

      addNewChatSingleUser(userData);
      onClose();
    } catch (error) {
      console.log("Error navigating:", error);
    }
    console.log("🚀 ~ handleOpenMessage ~ addNewChatSingleUser:", addNewChatSingleUser)
  };

  const messageJsx = (records) =>
    records?.map((item) => {
      return (
        <div
          className="usersNameInfo cursor-pointer childContainer col-md-6 col-sm-6"
          key={item?._id}
        >
          <div className="avatarContainer">
            <span className="userImage" onClick={() => handleOpenMessage(item)}>
              <img
                src={`${BASE_URL}${item?.profile_picture_url}`}
                alt={item?.name}
              />
            </span>
            <span className="userInfo" onClick={() => handleOpenMessage(item)}>
              <span className="nameBlock fs-10">{item?.name}</span>
            </span>
          </div>
        </div>
      );
    });

  const filteredRecords = searchInput
    ? allRecords?.filter((item) =>
      item?.name?.toLowerCase()?.includes(searchInput)
    )
    : allRecords;

  useEffect(() => {
    fetchAllRecords();
  }, []);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={onClose}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>All Users atifazad 1</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="searchContainer p-2">
              <div className="searchTextBox">
                <input
                  type="text"
                  placeholder="Search by name"
                  value={searchInput}
                  onChange={handleSearchInputChange}
                />
              </div>
            </div>
            <div className="row allUsersListingContainer">
              {messageJsx(filteredRecords)}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-sm"
              variant="outline-primary"
              onClick={onClose}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isToast && <ToastContainer />}
    </>
  );
}


// Map dispatch actions to component props
const mapDispatchToProps = (dispatch) => ({
  addNewChatSingleUser: (memberData) => dispatch(addNewChatSingleUser(memberData)),
});

export default connect(null, mapDispatchToProps)(ListAllMembers);

