import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addDocument } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ADMIN_AUTH_TOKEN, UPLOAD_SU_DOCUMENTS_FILE, POST_SU_DOCUMENTS_URL } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../components/forms-fields/TextArea";
function AddDocuments({ userId, subModalAddDocuments }) {
  const handleShowHideModal = () => {
    subModalAddDocuments((prevShow) => !prevShow);
  };
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddDocuments, setIsToastAddDocuments] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [uploadedFilePath, setUploadedFilePath] = useState("");
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedBy", userId);
  };

  const [suFormData, setSuFormData] = useState({
    userId: userId,
    addedBy: selectedUserId,
    details: "",
    title: "",
    docPath: uploadedFilePath
  });
  console.log("🚀 ~ AddMemoryBox ~ selectedUserId:", selectedUserId)

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addDocument,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddDocuments(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddDocuments(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {

    const updateValues = {

      ...values,
      docPath: uploadedFilePath,
      addedBy: selectedUserId,
    }
    // console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_SU_DOCUMENTS_URL, updateValues);
    console.log("🚀 ~ carenotes ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    handleChange(e);
    handleBlur(e);
  };

  const handleImageChange = async (e) => {
    setIsSubmitButtonClicked(true);
    try {
      const file = e.target.files[0];
      const fileType = file.type;

      // Validate file type
      if (
        fileType === 'application/pdf' ||
        fileType === 'application/msword' ||
        fileType === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
        fileType === 'application/vnd.ms-excel' ||
        fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
        fileType === 'application/vnd.ms-powerpoint' ||
        fileType === 'application/vnd.openxmlformats-officedocument.presentationml.presentation' ||
        fileType === 'text/plain' ||
        fileType === 'text/csv'
      ) {
        const fileData = new FormData();
        fileData.append('attachements', file);
        const apiResponse = await fetch(UPLOAD_SU_DOCUMENTS_FILE, {
          method: "POST",
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });
        const resultResponse = await apiResponse.json();
        setUploadedFilePath(resultResponse?.data[0]?.fileNameInDirectory);
        setFieldValue('docPath', resultResponse?.data[0]?.fileNameInDirectory);
        console.log("File uploaded successfully:", resultResponse?.data);
      } else {
        // Unsupported file format
        alert('Unsupported file format');
      }
      setIsSubmitButtonClicked(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitButtonClicked(false);
    }
  };
  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit} encType="multipart/form-data">
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
              
                <Input
                  type="file"
                  id="docPath"
                  name="docPath"
                  // value={values?.docPath}
                  onChange={handleImageChange}
                  onBlur={handleBlur}
                  labelText="Upload Document"
                  customError={
                    errors.docPath && touched.docPath
                  }
                  errorMessage={errors.docPath}
                  errorClass={
                    errors.docPath && touched.docPath
                  }
                  requiredStar={true}
                />

                  {/* <input
                    name="docPath"
                    id="docPath"
                    type="file"
                    onChange={handleImageChange}
                  />
                  <label className="floatingLabel">
                    Upload Document
                    <span className="text-danger">*</span>
                  </label> */}
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <CustomSelect
                    id=""
                    name="addedBy"
                    onSelect={handleUserSelect}
                    filterBy=""
                    label="Added By"
                    customError={touched?.addedBy && errors?.addedBy}
                    errorMessage={errors?.addedBy}
                    errorClass={
                      touched?.addedBy && errors?.addedBy
                        ? "validationError"
                        : ""
                    }
                  />
                </div>
              </div>
              <div className="col-md-12">
                <Input
                  type="text"
                  id="title"
                  name="title"
                  value={values?.title}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Title"
                  customError={
                    errors.title && touched.title
                  }
                  errorMessage={errors.title}
                  errorClass={
                    errors.title && touched.title
                  }
                  requiredStar={true}
                />
              </div>
              <div className="col-md-12">

                <TextArea
                  id="details"
                  name="details"
                  value={values?.details}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Details"
                  rows="3"
                  className="mb-3"
                  customError={touched?.details && errors?.details}
                  errorMessage={errors?.details}
                  erroClass={
                    touched?.details && errors?.details
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>
            </div>
          </div>

          <div className="formActionButtons">
            <span
              onClick={subModalAddDocuments}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={subModalAddDocuments}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastAddDocuments ? <ToastContainer /> : ''}

    </>
  );
}

export default AddDocuments;