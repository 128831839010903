import React from 'react'
import Layout from "../../../../../../components/layouts/Layout";
import EditCareNotes from "../../../EditCareNotes";



function EditMouthHygiene() {
  return (
    <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Mouth Hygiene note</h3>
              <EditCareNotes noteType="MouthHygiene" parentListNoteUrl="/care-plan/general-notes" />
            </div>
          </div>
        </div>
      </Layout>
  )
}

export default EditMouthHygiene