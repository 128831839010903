import React from "react";
import Layout from "../../../../../../components/layouts/Layout";
import EditCareNotes from "../../../EditCareNotes";

function EditActivities() {
  return (
    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Activities note</h3>
              <EditCareNotes noteType="Activities" parentListNoteUrl="/care-plan/general-notes" />
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}
export default EditActivities