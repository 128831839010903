import React from "react";
import { Link } from "react-router-dom";
import OtpInput from "./OtpInput";
import ButtonLoading from "../general/ButtonLoading";

function VerifyOTPForm({
  ftcLogo,
  errorStar,
  otpCode,
  handleOtpChange,
  handleResendOTP,
  timer,
  isEmailExits,
  isResend,
  handleVerifyOtp,
  loading,
}) {
  return (
    <div className="login-form">
      <img src={ftcLogo} alt="FTC Logo" className="FTCLogo" />
      <h3>Verify OTP</h3>
      <form action="/">
        <OtpInput
          otp={otpCode}
          onOtpChange={handleOtpChange}
          errorMessage={errorStar}
        />
        <div className="form-group text-right">
          <div className="timer-text">
            {isEmailExits && !isResend ? (
              <>
                <div className="otp-sent-message">
                  {timer === 0 ? (
                    <Link onClick={handleResendOTP}>Resend</Link>
                  ) : (
                    `OTP sent to your email address: ( ${timer} )`
                  )}
                </div>
              </>
            ) : isResend && isEmailExits ? (
              <div className="otp-sent-message">
                {timer === 0 ? (
                  <Link onClick={handleResendOTP}>Resend</Link>
                ) : (
                  `Please wait, OTP sending: ( ${timer} )`
                )}
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <div className="form-group">
          <button className="button" onClick={handleVerifyOtp}>
            {loading && <ButtonLoading />} Verify
          </button>
        </div>
      </form>
    </div>
  );
}

export default VerifyOTPForm;
