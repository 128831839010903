import React, { useEffect, useRef, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import Button from "react-bootstrap/Button";
import moment from "moment";
import DeletePopup from "../../components/modals/DeletePopup";
import SetGroupWorkingHours from "./modals/SetGroupWorkingHours";
import GroupInfo from "./modals/GroupInfo";
import EditGroupMembers from "./modals/EditGroupMembers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Popover from "react-bootstrap/Popover";
import menuToggleVertical from "../../assets/images/icons/menuToggleVertical.svg";
import submit from "../../assets/images/icons/message-chat/submit.svg";
import CrossIcon from "../../assets/images/icons/cross_icon.svg";
import doubleTick from "../../assets/images/icons/doubleTick.png";
import singleTick from "../../assets/images/icons/singleTick.svg";
import emoji from "../../assets/images/icons/message-chat/emoji.svg";
import attachment from "../../assets/images/icons/message-chat/attachment.svg";
import TextArea from "../../components/forms-fields/TextArea";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EmojiPicker from "emoji-picker-react";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import io from 'socket.io-client';


import {
  BASE_URL,
  GET_INDIVIDUAL_MESSAGES_URL,
  GET_INDIVIDUAL_GROUP_MESSAGES_URL,
  POST_SEND_MESSAGE_IN_GROUP_URL,
  DELETE_MESSAGE_URL,
  DELETE_GROUP_MESSAGE_URL,
  ADMIN_ID,
  POST_ADD_NEW_MESSAGE_URL,
  PUT_UPDATE_GROUP_MESSAGE_DETAILS_URL,
  PUT_UPDATE_MESSAGE_URL,
} from "../../env";
import {
  getRecords,
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
  formateDate,
} from "../../helper/helper";
import { SUPER_ADMIN, isCurrentTimeAfterEndTime } from "../../helper/helperJsx";
import ForwardMessage from "./modals/ForwardMessage";

function RoomSpace({ getMessages, isIndividual, getGroupMessages, isGroup, fetchAllMessagesRecords, onMobile }) {
  console.log("🚀 ~ RoomSpace ~ getMessages:", getMessages)
  console.log("🚀 ~ RoomSpace ~ getGroupMessages:", getGroupMessages)
  const socketUrl = BASE_URL;
  const [socket, setSocket] = useState(null);
  const listenId = isGroup ? getGroupMessages?._id : (getMessages?.senderId + getMessages?.recieverId);
  console.log("🚀 ~ RoomSpace ~ listenId:", listenId)
  const [individualMessageItem, setIndividualMessageItem] = useState(false);
  const [userMessages, setUserMessages] = useState();
  const [groupMessages, setGroupMessages] = useState(false);
  const [textUserMessages, setTextUserMessages] = useState("");
  const [disableTextAreaText, setDisableTextAreaText] = useState(
    "Chat is disabled outside of working hours."
  );
  const [isToastGetMessages, setIsToastGetMessages] = useState(false);
  const [isEmojisState, setIsEmojisState] = useState(false);
  const [isEmojisClicked, setIsEmojiClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isMsgSentClicked, setIsMsgSentClicked] = useState(false);
  const [showWorkingHoursModal, setShowWorkingHoursModal] = useState(false);
  const [showGroupInfoModal, setShowGroupInfoModal] = useState(false);
  const [showEditGroupMembersModal, setShowEditGroupMembersModal] =
    useState(false);
  const [showForwardMessageModal, setShowForwardMessageModel] = useState(false);
  const [deleteGroupMsgState, setDeleteGroupMsgState] = useState(false);
  const [deletePersonalMsgState, setDeletePersonalMsgState] = useState(false);
  const [editGroupMessageIdState, setEditGroupMessageIdState] = useState(false);
  const [editGroupMessageState, setEditGroupMessageState] = useState("");
  const [editPersonalMessageIdState, setEditPersonalMessageIdState] =
    useState(false);
  const [editPersonalMessageState, setEditPersonalMessageState] = useState("");
  const [isDeleteMsg, setIsDeleteMsg] = useState(false);

  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  useEffect(() => {
    if (getMessages !== false) {
      fetchUserMessages(getMessages);
      handleFalseAllStates();
      scrollToBottom();
    }
  }, [getMessages]);

  useEffect(() => {
    if (getGroupMessages !== false) {
      fetchGroupMessages(getGroupMessages);
      handleFalseAllStates();
      scrollToBottom();
    }
  }, [getGroupMessages]);


  useEffect(() => {
    scrollToBottom();
  }, [userMessages, groupMessages]);


  const fetchUserMessages = async (req) => {
    try {
      const request = {
        from: req?.senderId || req?.recieverId,
        to: req?.recieverId || req?.senderId,
      };
      const response = await getRecords(GET_INDIVIDUAL_MESSAGES_URL, request);
      if (!response) {
        console.error("No data received");
        return;
      } else if (response?.status !== isSuccess) {
        setIsToastGetMessages(true);
        toastErrorMessages(toast?.error, response?.message, "", 1000, 1000);
      } else if (response?.status === isSuccess) {
        const result = response?.data?.map((item) => item);
        setIndividualMessageItem(getMessages && getMessages);
        setUserMessages(result);
        setGroupMessages(false);
        setTextUserMessages("");
        setIsMsgSentClicked(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  const fetchGroupMessages = async (req) => {
    try {
      const request = {
        sortProperty: "createdAt",
        sortOrder: 1,
        offset: 0,
        limit: 100000,
        query: {
          critarion: {
            groupId: req?._id,
            isDeleted: false,
          },
        },
      };
      const response = await getRecords(
        GET_INDIVIDUAL_GROUP_MESSAGES_URL,
        request
      );
      if (!response) {
        console.error("No data received");
        return;
      } else if (response?.status !== isSuccess) {
        setIsToastGetMessages(true);
        toastErrorMessages(toast?.error, response?.message, "", 1000, 1000);
      } else if (response?.status === isSuccess) {
        const result = response?.data?.map((item) => item);
        setIndividualMessageItem(getGroupMessages && getGroupMessages);
        setGroupMessages(result);
        setUserMessages(false);
        setTextUserMessages("");
        setIsMsgSentClicked(false);
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  const fetchRecords = async () => {
    try {
      if (getMessages !== false) {
        fetchUserMessages(getMessages);
      }
      if (getGroupMessages !== false) {
        fetchGroupMessages(getGroupMessages);
      }
    } catch (error) {
      console.error("Error fetching records:", error);
    }
  };

  const sendGroupMessage = async (req, res) => {
    try {
      if (textUserMessages === "") {
        setIsMsgSentClicked(false);
        return;
      }
      const request = {
        groupId: req?._id,
        userId: ADMIN_ID && ADMIN_ID,
        message: textUserMessages && textUserMessages,
      };
      const result = await postRecords(POST_SEND_MESSAGE_IN_GROUP_URL, request);
      await fetchGroupMessages(getGroupMessages);
      await fetchGroupMessages(getGroupMessages);
      if (result?.status === isSuccess) {
        await fetchGroupMessages(getGroupMessages);
      } else if (result?.status !== isSuccess) {
        setIsMsgSentClicked(false);
        console.error("Message not sent error:", result);
      }
    } catch (error) {
      setIsMsgSentClicked(false);
      console.error("Error sending message:", error);
    }
  };

  const sendUserMessage = async (req, res) => {
    try {
      if (textUserMessages === "") {
        setIsMsgSentClicked(false);
        return;
      }
      const request = {
        from: ADMIN_ID,
        to:
          !req?.senderId || !req?.recieverId
            ? req?._id
            : req?.senderId !== ADMIN_ID
              ? req?.senderId
              : req?.recieverId,
        message: textUserMessages,
      };
      const result = await postRecords(POST_ADD_NEW_MESSAGE_URL, request);
      await fetchUserMessages(getMessages);
      if (result?.status === isSuccess) {
        await fetchUserMessages(getMessages);
      } else if (result?.status !== isSuccess) {
        setIsMsgSentClicked(false);
        console.error("Message not sent error:", result);
      }
    } catch (error) {
      setIsMsgSentClicked(false);
      console.error("Error sending message:", error);
    }
  };

  const deletePersonalMessageFunc = async () => {
    try {
      setIsDeleteMsg(true);
      const req = {
        messageId: deletePersonalMsgState?._id,
        lastModifiedBy: ADMIN_ID && ADMIN_ID,
      };
      await postRecords(DELETE_MESSAGE_URL, req);
      await fetchRecords();
      setIsDeleteMsg(false);
      handleCloseDeletePersonalMsgModel();
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const deleteGroupMessageFunc = async () => {
    try {
      setIsDeleteMsg(true);
      const req = {
        messageId: deleteGroupMsgState?._id,
        lastModifiedBy: ADMIN_ID && ADMIN_ID,
      };
      await postRecords(DELETE_GROUP_MESSAGE_URL, req);
      await fetchRecords();
      setIsDeleteMsg(false);
      handleCloseDeleteGroupMsgModel();
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const renderPersonalMessagesDropdownItems = (document) => {
    return (
      <>
        <Dropdown.Item onClick={(e) => handlePersonalMessageReply(e, document)}>
          Reply
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(e) => handlePersonalMessageEditMessage(e, document)}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleForwardMessage(e, document)}>
          Forward
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(e) => handlePersonalMessagePinStartMessage(e, document)}
        >
          Pin/Start message
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleDeletePersonalMsg(e, document)}>
          Delete
        </Dropdown.Item>
      </>
    );
  };

  const handleSendMessage = async (e, item) => {
    if (item?.isGroup === true) {
      setIsMsgSentClicked(true);
      sendGroupMessage(item);
    } else {
      setIsMsgSentClicked(true);
      sendUserMessage(item);
    }
  };

  const handleFalseAllStates = async () => {
    setEditGroupMessageIdState(false);
    setEditPersonalMessageIdState(false);
    setEditGroupMessageState("");
    setEditPersonalMessageState("");
    setTextUserMessages("");
  };

  const handleUpdateGroupMessage = async () => {
    try {
      if (editGroupMessageState !== "") {
        const req = {
          groupId: editGroupMessageIdState,
          message: editGroupMessageState,
          lastModifiedBy: ADMIN_ID && ADMIN_ID,
        };
        await postRecords(PUT_UPDATE_GROUP_MESSAGE_DETAILS_URL, req);
        await fetchRecords();
        handleFalseAllStates();
      }
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  const handleUpdatePersonalMessage = async () => {
    try {
      if (editPersonalMessageState !== "") {
        const req = {
          messageId: editPersonalMessageIdState,
          message: editPersonalMessageState,
          lastModifiedBy: ADMIN_ID && ADMIN_ID,
        };
        await postRecords(PUT_UPDATE_MESSAGE_URL, req);
        await fetchRecords();
        handleFalseAllStates();
      }
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  const handleUpdatePersonalMessageAsPinned = async (item) => {
    try {
      const req = {
        messageId: item?._id,
        isPin: true,
        lastModifiedBy: ADMIN_ID && ADMIN_ID,
      };
      const response = await postRecords(PUT_UPDATE_MESSAGE_URL, req);
      await fetchRecords();
      // console.log(response);
      handleFalseAllStates();
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  const updateMessageAsPinnedMessage = async (group) => {
    try {
      const req = {
        groupId: group?._id,
        isPin: true,
        lastModifiedBy: ADMIN_ID && ADMIN_ID,
      };
      const response = await postRecords(
        PUT_UPDATE_GROUP_MESSAGE_DETAILS_URL,
        req
      );
      await fetchRecords();
      handleFalseAllStates();
    } catch (error) {
      console.error("Error updating message:", error);
    }
  };

  const handleChangeTextMessage = (e) => {
    setIsEmojiClicked(false);
    setTextUserMessages(e?.target?.value);
  };

  const handleClickTextMessage = () => {
    setIsEmojisState(false);
  };

  const handleShowEmojis = () => {
    setIsEmojisState(true);
  };

  const handlePersonalMessageReply = (e, item) => { };

  const handlePersonalMessageEditMessage = (e, item) => {
    setEditPersonalMessageIdState(item && item?._id);
    setEditPersonalMessageState(item?.message);
  };

  const handlePersonalMessagePinStartMessage = (e, item) => {
    handleUpdatePersonalMessageAsPinned(item && item);
  };

  const handleDeletePersonalMsg = (e, item) => {
    setDeletePersonalMsgState(item);
  };

  const renderGroupMessagesDropdownItems = (document) => {
    return (
      <>
        <Dropdown.Item onClick={(e) => handleGroupMessageReply(e, document)}>
          Reply
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(e) => handleGroupMessageEditMessage(e, document)}
        >
          Edit
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleForwardMessage(e, document)}>
          Forward
        </Dropdown.Item>
        <Dropdown.Item
          onClick={(e) => handleGroupMessagePinStartMessage(e, document)}
        >
          Pin/Start message
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleDeleteGroupMsg(e, document)}>
          Delete
        </Dropdown.Item>
      </>
    );
  };

  const handleGroupMessageReply = (e, item) => {
    // console.log("handleGroupMessage reply");
  };

  const handleGroupMessageEditMessage = (e, item) => {
    setEditGroupMessageIdState(item && item?._id);
    setEditGroupMessageState(item?.message);
  };

  const handleForwardMessage = (e, item) => {
    setShowForwardMessageModel(item);
  };

  const handleGroupMessagePinStartMessage = (e, item) => {
    updateMessageAsPinnedMessage(item && item);
  };

  const handleDeleteGroupMsg = (e, item) => {
    setDeleteGroupMsgState(item);
  };

  const handleChangeEditGroupMessage = (e) => {
    setEditGroupMessageState(e?.target?.value);
  };

  const handleChangeEditPersonalMessage = (e) => {
    setEditPersonalMessageState(e?.target?.value);
  };

  const handleGroupInfo = (e, item) => {
    setShowGroupInfoModal(item);
  };

  const handleEditGroupMembers = (e, item) => {
    setShowEditGroupMembersModal(item);
  };

  const handleSetGroupWorkingHours = (e, item) => {
    setShowWorkingHoursModal(item);
  };

  const handleCloseWorkingHoursModel = () => {
    fetchAllMessagesRecords();
    setShowWorkingHoursModal(false);
  };

  const handleCloseGroupInfoModal = () => {
    setShowGroupInfoModal(false);
  };

  const handleCloseEditGroupMembersModal = () => {
    setShowEditGroupMembersModal(false);
  };

  const handleCloseDeletePersonalMsgModel = () => {
    setDeletePersonalMsgState(false);
  };

  const handleCloseDeleteGroupMsgModel = () => {
    setDeleteGroupMsgState(false);
  };

  const handleCloseForwardMessageModal = async () => {
    await fetchAllMessagesRecords();
    setShowForwardMessageModel(false);
  };

  const dropdownActions = (item) => (
    <Dropdown className="messageDropDownButton">
      <Dropdown.Toggle>
        <img src={menuToggleVertical} alt="Message Drop Down" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => handleGroupInfo(e, item)}>
          Group Info
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleEditGroupMembers(e, item)}>
          Edit members
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleSetGroupWorkingHours(e, item)}>
          Set working hours
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  const renderUserHeader = (item) => (
    <header>

      <div className="usersNameInfo">
        <span>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onMobile}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </span>
        <div className="userImage">
          <img
            src={`${BASE_URL}${item?.image || item?.profile_picture_url}`}
            alt={item?.name}
          />
        </div>
        <div className="userInfo chatHeaderNameBlock">
          <span>{item?.name}</span>
        </div>
      </div>
    </header>
  );

  const renderGroupHeader = (item) => (
    <header className="d-flex-space-betweens">
      <div className="usersNameInfo">
        <span>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={onMobile}
            sx={{ mr: 2, display: { lg: "none" } }}
          >
            <MenuIcon />
          </IconButton>
        </span>
        <div className="userImage">
          <img src={`${BASE_URL}${item?.groupImage}`} alt={item?.groupName} />
        </div>
        <div className="userInfo chatHeaderNameBlock">
          <span>{item?.groupName}</span>
        </div>
      </div>
      <div className="top-">{dropdownActions(individualMessageItem)}</div>
    </header>
  );

  const renderUserMessages = (messages) => {
    // console.log("🚀 ~ RoomSpace ~ TESTING MESSAGES:", messages);
    return (
      <div className="messagesPanel min-height">
        {messages &&
          messages.map((message, index) => (
            <div
              className={`messageBoxWrapper ${message.from?.role !== SUPER_ADMIN
                ? "senderWrapper"
                : "receiverWrapper"
                }`}
              key={index}
            >
              {
                message.from?.role === SUPER_ADMIN ? (
                  <div className="recieverMessageBox">
                    <div
                      className={"receiverMessage"}
                    >
                      <Dropdown
                        className={`messageDropDownButton ${message.from?.role !== SUPER_ADMIN
                          ? ""
                          : ""
                          }`}
                      >
                        <Dropdown.Toggle>
                          <img src={menuToggleVertical} alt="Message Drop Down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {renderPersonalMessagesDropdownItems(message)}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div
                        className={"textMessage"}
                      >
                        {/* {message?.from?.id} */}
                        {message.message}
                      </div>

                    </div>
                  </div>
                ) : (
                  <div
                    className={
                      message.from?.role !== SUPER_ADMIN
                        ? "senderMessage"
                        : "receiverMessage"
                    }
                  >
                    <div
                      className={"textMessage"}
                    >
                      {/* {message?.from?.id} */}
                      {message.message}
                    </div>
                    <Dropdown
                      className={`messageDropDownButton ${message.from?.role !== SUPER_ADMIN
                        ? ""
                        : ""
                        }`}
                    >
                      <Dropdown.Toggle>
                        <img src={menuToggleVertical} alt="Message Drop Down" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        {renderPersonalMessagesDropdownItems(message)}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                )
              }


              <div className="time fs-10 msgSeenUnseenContainer">
                {message.seenAt && (
                  <>
                    {formateDate(message.seenAt, "m/d/y")}
                    {"  "}
                    {moment(message.seenAt).format("LT")}
                  </>
                )}
                {/* <span className="child">
                  {message.seen ? (
                    <img src={doubleTick} alt="doubleTick" />
                  ) : (
                    <img src={singleTick} alt="singleTick" />
                  )}
                </span> */}
              </div>
            </div>
          ))}
        <div ref={messagesEndRef}></div>
      </div>
    );
  };


  const renderShowProfilePic = (infoState) => {
    if (!infoState) return null;
    return infoState?.map((user, index) => {
      const { profile_picture_url, name } = user;
      return (
        <span
          className="imageChainBlock"
          style={{ zIndex: index }}
          key={`image-${index}`}
        >
          <span className="chainImage firstView">
            <img src={`${BASE_URL}${profile_picture_url}`} alt={name} />
          </span>
        </span>
      );
    });
  };

  const renderViewProfilesJsx = (infoState) => {
    return infoState?.map((user, index) => {
      const { profile_picture_url, name } = user;
      return (
        <span className="usersNameInfo avatarContainer profileWidth">
          <span>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={onMobile}
              sx={{ mr: 2, display: { lg: "none" } }}
            >
              <MenuIcon />
            </IconButton>
          </span>
          <span className="userImage">
            <img src={`${BASE_URL}${profile_picture_url}`} alt={name} />
          </span>
          <span className="userInfo">
            <span className="nameBlock">{name}</span>
          </span>
        </span>
      );
    });
  };

  const GroupMsgSeenBy = ({ state }) => {
    const imageElements = renderShowProfilePic(state)?.slice(0, 3);
    const profileElements = renderViewProfilesJsx(state);
    const moreUsersCount = imageElements?.length;

    const [showPopover, setShowPopover] = useState(false);

    const handleTogglePopover = () => {
      setShowPopover(!showPopover);
    };

    return (
      <OverlayTrigger
        placement="left"
        delay={{ show: 250, hide: 400 }}
        overlay={
          <Popover className="popOver">
            <Popover.Header as="h3">{`READ BY`}</Popover.Header>
            <Popover.Body className="popupBody">
              <div className="forwardMessageModelContainer">
                {profileElements}
              </div>
            </Popover.Body>
          </Popover>
        }
      >
        <div
          className="chainBlocks cursor-pointer groupMessageSeenUsers"
          onClick={handleTogglePopover}
        >
          {imageElements}
          <span className="moreUsersCounts profileCounts">
            {moreUsersCount > 2 ? "+" + moreUsersCount : moreUsersCount}
          </span>
        </div>
      </OverlayTrigger>
    );
  };

  const renderGroupMessages = (messages) => {
    return (
      <div className="messagesPanel min-height">
        {messages?.map((message, index) => {
          const isSender = message?.userId?.role !== SUPER_ADMIN;
          const messageBoxWrapperClass = `messageBoxWrapper ${isSender ? "senderWrapper" : "receiverWrapper"
            }`;
          const messageContainerClass = isSender
            ? "senderMessage"
            : "recieverMessageBox";
          const textMessageContainerClass = isSender
            ? "textMessage"
            : "textMessage";
          const messageDropDownButtonClass = `messageDropDownButton ${isSender ? "" : ""
            }`;
          return (
            <div className={messageBoxWrapperClass} key={index}>
              <div className={messageContainerClass}>
                {
                  !isSender ? (
                    <div className="receiverMessage">
                      <Dropdown className={messageDropDownButtonClass}>
                        <Dropdown.Toggle>
                          <img src={menuToggleVertical} alt="Message Drop Down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {renderGroupMessagesDropdownItems(message)}
                        </Dropdown.Menu>
                      </Dropdown>
                      <div className={textMessageContainerClass}>
                        {message?.message}
                      </div>
                    </div>
                  ) : (
                    <>
                      <div className={textMessageContainerClass}>
                        {message?.message}
                      </div>
                      <Dropdown className={messageDropDownButtonClass}>
                        <Dropdown.Toggle>
                          <img src={menuToggleVertical} alt="Message Drop Down" />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          {renderGroupMessagesDropdownItems(message)}
                        </Dropdown.Menu>
                      </Dropdown>
                    </>
                  )
                }


              </div>
              <div
                className={`time fs-10 
             ${message?.seenBy?.length > 0 && "d-flex-container"}
              `}
              >
                {message?.createdAt && (
                  <>
                    {formateDate(message?.createdAt, "m/d/y")}
                    {"  "}
                    {moment(message?.createdAt).format("LT")}
                  </>
                )}
                <span className="groupMessageSeenByContainer">
                  {message?.seenBy?.length > 0 && (
                    <GroupMsgSeenBy state={message?.seenBy} />
                  )}
                </span>
              </div>
            </div>
          );
        })}
        <div ref={messagesEndRef}></div>
      </div>
    );
  };

  const handleFooterJsx = (item) => {
    if (item?.isGroup) {
      const { startTime, endTime } = item?.groupWorkingScheduleTime;
      if (startTime && endTime !== 0) {
        return isCurrentTimeAfterEndTime(startTime, endTime)
          ? disabledFooterJsx()
          : footerJsx(item);
      } else {
        return footerJsx(item);
      }
    } else {
      return footerJsx(item);
    }
  };

  const disabledFooterJsx = () => (
    <div className="messagesActions">
      <footer>

        <div className="textMessage">
          <TextArea
            type="text"
            extraClass="disableTextArea"
            value={disableTextAreaText}
            disabledStatus={true}
            placeholder="Send a Message2"
            cols={1}
            rows={1}
          />
        </div>

        <span className="msgFooterIcons">
          <span className="emoji sendMessageEmojiChildIcon">
            {isEmojisState ? (
              <EmojiPicker className="emojisContainer cursor-pointer" />
            ) : (
              <span>
                <img src={emoji} alt="emoji" />
              </span>
            )}
          </span>
          <span className="attachment sendMessageEmojiChildIcon">
            <span>
              <img src={attachment} alt="" />
            </span>
          </span>
          <span className="submit sendMessageEmojiChildSubmitIcon">
            <Button type="button" className="btn-sm disableButton cursor-default">
              <img src={submit} alt="" />
            </Button>
          </span>
        </span>
      </footer>
    </div>
  );

  const footerJsx = (item) => (
    <div className="messagesActions">
      <footer>

        <div
          className="textMessage"
          onClick={handleClickTextMessage}
        >
          {isMsgSentClicked ? (
            <textarea
              type="text"
              value=""
              cols={1}
              rows={1}
              disabled
            >Sending....</textarea>
          ) : editGroupMessageIdState ? (
            <textarea
              type="text"
              // value={}
              onChange={handleChangeEditGroupMessage}
              placeholder="Send a Message3"
              cols={1}
              rows={1}
            > {editGroupMessageState}</textarea>
          ) : editPersonalMessageIdState ? (
            <textarea
              type="text"
              // value=
              onChange={handleChangeEditPersonalMessage}
              placeholder="Send a Message4"
              cols={1}
              rows={1}
            >{editPersonalMessageState}</textarea>
          ) : (
            <textarea
              type="text"
              // value=
              onChange={handleChangeTextMessage}
              placeholder="Send a Message5"
              cols={1}
              rows={1}
            >{textUserMessages}</textarea>
          )}
          {editGroupMessageIdState ? (
            <img
              className="editMessageCrossContainer"
              src={CrossIcon}
              alt="cross Icon"
              onClick={handleFalseAllStates}
            />
          ) : editPersonalMessageIdState ? (
            <img
              className="editMessageCrossContainer"
              src={CrossIcon}
              alt="cross Icon"
              onClick={handleFalseAllStates}
            />
          ) : (
            ""
          )}
        </div>


        <div className="emoji">
          {isEmojisState ? (
            <EmojiPicker
              onEmojiClick={(e) => setIsEmojiClicked(e?.emoji)}
              className="emojisContainer cursor-pointer"
            />
          ) : (
            <span>
              <img
                src={emoji}
                alt="emoji"
                onClick={(e) => handleShowEmojis(e)}
              />
            </span>
          )}
        </div>
        <div
          className="attachment"
          style={{ right: "6px" }}
        >
          <span>
            <img src={attachment} alt="" />
          </span>
        </div>

        <div className="submit">
          {editGroupMessageIdState ? (
            <Button
              type="button"
              className="btn-sm btn-primary"
              onClick={() => handleUpdateGroupMessage()}
            >
              <img src={submit} alt="" />
            </Button>
          ) : editPersonalMessageIdState ? (
            <Button
              type="button"
              className="btn-sm btn-primary"
              onClick={() => handleUpdatePersonalMessage()}
            >
              <img src={submit} alt="" />
            </Button>
          ) : (
            <Button
              type="button"
              className="btn-sm btn-primary"
              onClick={(e) => handleSendMessage(e, item)}
            >
              <img src={submit} alt="" />
            </Button>
          )}
        </div>

      </footer>
    </div>
  );

  useEffect(() => {
    if (
      isEmojisClicked &&
      textUserMessages !== "" &&
      textUserMessages !== undefined
    ) {
      setTextUserMessages((prevState) => prevState + isEmojisClicked);
    } else if (isEmojisClicked) {
      setTextUserMessages(isEmojisClicked);
    }
  }, [isEmojisClicked, textUserMessages, setTextUserMessages]);

  return (
    <>
      <div className="roomSpace">
        {getMessages !== false ? (
          <>
            {individualMessageItem && renderUserHeader(individualMessageItem)}
            {userMessages && renderUserMessages(userMessages)}
            {handleFooterJsx(individualMessageItem)}
            {/* <div ref={messagesEndRef} /> */}
          </>
        ) : groupMessages !== false ? (
          <>
            {individualMessageItem && renderGroupHeader(individualMessageItem)}
            {groupMessages && renderGroupMessages(groupMessages)}
            {handleFooterJsx(individualMessageItem)}
            {/* <div ref={messagesEndRef} /> */}
          </>
        ) : (
          []
        )}

      </div>
      {deletePersonalMsgState && (
        <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
          <DeletePopup
            show={deletePersonalMsgState}
            onHide={handleCloseDeletePersonalMsgModel}
            title="Confirmation"
            content="Are you sure you want to delete this message?"
            onConfirm={deletePersonalMessageFunc}
            onCancel={handleCloseDeletePersonalMsgModel}
            isConfirmDeleteLoading={isDeleteMsg}
          />
        </ProtectedComponent>
      )}
      {deleteGroupMsgState && (
        <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
          <DeletePopup
            show={deleteGroupMsgState}
            onHide={handleCloseDeleteGroupMsgModel}
            title="Confirmation"
            content="Are you sure you want to delete this message?"
            onConfirm={deleteGroupMessageFunc}
            onCancel={handleCloseDeleteGroupMsgModel}
            isConfirmDeleteLoading={isDeleteMsg}
          />
        </ProtectedComponent>
      )}
      {showWorkingHoursModal && (
        <SetGroupWorkingHours
          handleShowModel={showWorkingHoursModal}
          handleCloseModel={handleCloseWorkingHoursModel}
          groupItem={showWorkingHoursModal}
          fetchCompleteRecords={fetchAllMessagesRecords}
        />
      )}
      {showGroupInfoModal && (
        <GroupInfo
          handleShowModel={showGroupInfoModal}
          handleCloseModel={handleCloseGroupInfoModal}
          groupItem={showGroupInfoModal}
          fetchCompleteRecords={fetchAllMessagesRecords}
        />
      )}
      {showEditGroupMembersModal && (
        <EditGroupMembers
          handleShowModel={showEditGroupMembersModal}
          handleCloseModel={handleCloseEditGroupMembersModal}
          groupItem={showEditGroupMembersModal}
          fetchCompleteRecords={fetchAllMessagesRecords}
        />
      )}
      {showForwardMessageModal && (
        <ForwardMessage
          handleShowModel={showForwardMessageModal}
          handleCloseModel={handleCloseForwardMessageModal}
          groupItem={showForwardMessageModal}
        />
      )}
      {isToastGetMessages && <ToastContainer />}
    </>
  );
}

export default RoomSpace;
