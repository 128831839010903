import React from 'react'
import Layout from "../../../../../../components/layouts/Layout";
import EditCareNotes from "../../../EditCareNotes";



function EditMeetings() {
  return (
    <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit Meetings note</h3>
              <EditCareNotes noteType="Meetings" parentListNoteUrl="/care-plan/general-notes" />
            </div>
          </div>
        </div>
      </Layout>
  )
}

export default EditMeetings