import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Angry from "../../../../assets/images/mood-rating/Angry.svg";
import Bored from "../../../../assets/images/mood-rating/Bored.svg";
import Calm from "../../../../assets/images/mood-rating/Calm.svg";
import Confident from "../../../../assets/images/mood-rating/Confident.svg";
import Excited from "../../../../assets/images/mood-rating/Excited.svg";
import Happy from "../../../../assets/images/mood-rating/Happy.svg";
import Hopeful from "../../../../assets/images/mood-rating/Hopeful.svg";
import Nervous from "../../../../assets/images/mood-rating/Nervous.svg";
import Proud from "../../../../assets/images/mood-rating/Proud.svg";
import Relaxed from "../../../../assets/images/mood-rating/Relaxed.svg";
import Sad from "../../../../assets/images/mood-rating/Sad.svg";
import Scared from "../../../../assets/images/mood-rating/Scared.svg";
import Tired from "../../../../assets/images/mood-rating/Tired.svg";
import Worried from "../../../../assets/images/mood-rating/Worried.svg";

const MoodImageComponent = ({ moodItem }) => {
  // Map mood ratings to corresponding images
  const moodImageMap = {
    Angry: Angry,
    Bored: Bored,
    Calm: Calm,
    Confident: Confident,
    Proud: Proud,
    Excited: Excited,
    Happy: Happy,
    Hopeful: Hopeful,
    Nervous: Nervous,
    Relaxed: Relaxed,
    Sad: Sad,
    Scared: Scared,
    Tired: Tired,
    Worried: Worried,
    // Add other mood ratings and their corresponding images here...
  };

  // Check if moodRating exists in the moodImageMap
  const selectedMoodImage = moodImageMap[moodItem?.moodRating];

  // Render the image if it exists, otherwise render a placeholder
  return (
    <div className={`feedBackIcons ${moodItem?.moodRating}`}>
      <OverlayTrigger placement='top' overlay={<Tooltip>{moodItem?.moodRating}</Tooltip>}>
        <img src={selectedMoodImage} alt={moodItem?.moodRating} className={moodItem?.moodRating} />
      </OverlayTrigger>
    </div>
  );
};

export default MoodImageComponent;
