import React from "react";
import { Link } from "react-router-dom";

function ForgotPasswordForm({ ftcLogo, handleAlreadyHaveAccount }) {
  return (
    <div className="login-form">
      <img src={ftcLogo} alt="FTC Logo" className="FTCLogo" />
      <h3>Forgot Password</h3>
      <p className="content">
        Enter your email for the verification process; we will send a new
        password to your email.
      </p>
      <form action="/newpassword">
        <div className="form-group">
          <input type="text" id="username" name="username" required />
          <label htmlFor="username" className="floatingLabel">
            Enter your username
          </label>
        </div>
        <div className="form-group remember-authentication">
          <div className="text-right" style={{ width: "15%" }}>
            <Link onClick={handleAlreadyHaveAccount}>Sign In</Link>
          </div>
        </div>
        <div className="form-group">
          <button className="button" type="submit">
            Continue
          </button>
        </div>
      </form>
    </div>
  );
}

export default ForgotPasswordForm;
