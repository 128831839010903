import React, { useEffect, useRef, useState } from 'react';
import { Button, Dropdown, Modal } from "react-bootstrap";
import menuToggleVertical from "../../assets/images/icons/menuToggleVertical.svg";
import submit from "../../assets/images/icons/message-chat/submit.svg";
import emoji from "../../assets/images/icons/message-chat/emoji.svg";
import attachment from "../../assets/images/icons/message-chat/attachment.svg";
import MenuIcon from "@mui/icons-material/Menu";
import eventDel from "../../assets/images/icons/eventDel.svg";
import pdfIcon from "../../assets/images/icons/pdfIcon.svg";
import wordIcon from "../../assets/images/icons/wordIcon.svg";
import IconButton from "@mui/material/IconButton";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import {
  BASE_URL,
  GET_INDIVIDUAL_MESSAGES_URL,
  GET_INDIVIDUAL_GROUP_MESSAGES_URL,
  POST_SEND_MESSAGE_IN_GROUP_URL,
  DELETE_MESSAGE_URL,
  DELETE_GROUP_MESSAGE_URL,
  ADMIN_ID,
  POST_ADD_NEW_MESSAGE_URL,
  UPLOAD_MESSAGING_SYSTEM_FILE,
  ADMIN_AUTH_TOKEN,
} from "../../env";
import {
  getRecords,
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  toastSuccessMessages,
} from "../../helper/helper";
import TextArea from '../../components/forms-fields/TextArea';
import io from 'socket.io-client';
import EmptyChatScreen from './model/EmptyChatScreen';
import SetGroupWorkingHours from "./modals/SetGroupWorkingHours";
import GroupInfo from "./modals/GroupInfo";
import EditGroupMembers from "./modals/EditGroupMembers";
import ForwardMessage from './modals/ForwardMessage';

function RoomSpace({ onMobile, openChatData, handleUpdateLastMessage, handleNewChatMessage }) {
  useEffect(() => {
    console.log("🚀 ~ RoomSpace ~ openChatData:", openChatData)
  }, [openChatData])

  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const messagesEndRef = useRef(null);
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'auto' });
  };

  const [isCluster, setIsCluster] = useState(null)
  useEffect(() => {
    setIsCluster(openChatData?.isGroup)
  }, [openChatData])


  const [openChatUserData, setOpenChatUserData] = useState(null)
  useEffect(() => {
    setOpenChatUserData(openChatData)
  }, [openChatData])


  /********************************
   * Getting chat messages from DB
   * *****************************/
  const [selectedChatData, setSelectedChatData] = useState([]);
  const [apiQuerySelectedChat, setApiQuerySelectedChat] = useState({});
  const [apiToFetchData, setApiToFetchData] = useState(GET_INDIVIDUAL_MESSAGES_URL)
  const [apiToDeleteData, setApiToDeleteData] = useState(GET_INDIVIDUAL_MESSAGES_URL)
  useEffect(() => {
    setApiQuerySelectedChat(
      isCluster
        ? {
          query: {
            critarion: {
              groupId: openChatUserData?._id,
              isDeleted: false
            }
          }
        }
        : {
          from: openChatUserData?.senderId || openChatUserData?.recieverId,
          to: openChatUserData?.recieverId || openChatUserData?.senderId
        }
    );
  }, [openChatUserData]);

  useEffect(() => {
    setApiToFetchData(
      isCluster
        ? GET_INDIVIDUAL_GROUP_MESSAGES_URL
        : GET_INDIVIDUAL_MESSAGES_URL
    )
    setApiToDeleteData(
      isCluster
        ? DELETE_GROUP_MESSAGE_URL
        : DELETE_MESSAGE_URL
    )
  }, [isCluster]);

  const processFetchRecordsSelectedChat = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const chatData = data?.data;
      setSelectedChatData(chatData?.map((item) => item));
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(apiToFetchData, apiQuerySelectedChat);
    processFetchRecordsSelectedChat("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
    scrollToBottom();
    handleUpdateLastMessage()
  }, [apiQuerySelectedChat]);

  useEffect(() => {
    scrollToBottom();
    handleUpdateLastMessage()
  }, [selectedChatData]);


  /********************************
 * Posting chat messages into DB
 * *****************************/
  const [apiToPostData, setApiToPostData] = useState(isCluster ? POST_SEND_MESSAGE_IN_GROUP_URL : POST_ADD_NEW_MESSAGE_URL);
  const [sendToUser, setSendToUser] = useState("");

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: {
      message: "",
      messageType: "message",
      filePath: "",
      ...(isCluster ? { groupId: openChatData?._id, userId: ADMIN_ID } : { from: ADMIN_ID, to: sendToUser }),
    },
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddMessageSystemFormData(values);
        socket?.emit('sendMessage', values);  // Emit the message to the server
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const handleUpdateNewChatMessageNameSpace = () => {
    if (!openChatData?.isGroup && openChatData?._id) {
      handleNewChatMessage()
    }
  }
  useEffect(() => {
    setIsCluster(openChatData?.isGroup);
    setApiToPostData(isCluster ? POST_SEND_MESSAGE_IN_GROUP_URL : POST_ADD_NEW_MESSAGE_URL);
    const sendToUserId = ADMIN_ID === openChatData?.senderId ? (
      openChatData?.recieverId
    ) : openChatData?.senderId ? (
      openChatData?.senderId
    ) : (
      openChatData?._id
    );
    setSendToUser(sendToUserId);
    setFieldValue("message", ""); // Reset the message field
    setFieldValue("messageType", "message"); // Reset the message field
    // setFieldValue(isCluster ? "groupId" : "from", openChatData?._id || ADMIN_ID);
    if (isCluster) {
      setFieldValue("groupId", openChatData?._id);
      setFieldValue("userId", ADMIN_ID);
    }
    if (!isCluster) {
      setFieldValue("to", sendToUserId);
      setFieldValue("from", ADMIN_ID);
    }
  }, [openChatData, isCluster, setFieldValue]);

  const postAddMessageSystemFormData = catchAsync(async () => {
    const response = await postRecords(apiToPostData, values);
    // console.log("🚀 ~ postAddMessageSystemFormData ~ response:", response)
    setIsSubmitButtonClicked(false);
    if (response?.status !== isSuccess) {
      toastErrorMessages(toast.error, response?.message, "", 10, 10);
    } else {
      setFieldValue("message", "");
      fetchAllRecords();
      scrollToBottom();
      handleUpdateLastMessage()
      handleUpdateNewChatMessageNameSpace()
    }
  });

  // WebSocket logic
  const URL = process.env.NODE_ENV === 'production' ? undefined : BASE_URL;
  const [socket, setSocket] = useState(null);
  useEffect(() => {
    if (openChatUserData) {
      const socketConnection = io(URL);  // Initialize the socket connection
      setSocket(socketConnection);

      const listenId = isCluster ? openChatUserData?._id : ADMIN_ID + sendToUser;

      socketConnection.on(listenId, (newMessage) => {
        setSelectedChatData((prevMessages) => [...prevMessages, newMessage]);
        scrollToBottom();  // Scroll to bottom when new message is received
        handleUpdateLastMessage()
      });

      return () => {
        socketConnection.emit('leave', listenId);  // Leave the chat room
        socketConnection.disconnect();  // Disconnect socket on component unmount
      };

    }
  }, [openChatUserData, isCluster, sendToUser]);

  const ToggleButton = () => {
    return (
      <span>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          edge="start"
          onClick={onMobile}
          sx={{ mr: 2, display: { lg: "none" } }}
        >
          <MenuIcon />
        </IconButton>
      </span>
    )
  }

  // Dropdown item/functions for reply edit delete etc
  const [showForwardMessageModal, setShowForwardMessageModel] = useState(false);
  const [deleteChatMessageState, setDeleteChatMessageState] = useState(false);

  const deleteChatMessageFunc = async () => {
    try {
      setIsSubmitButtonClicked(true);
      const req = {
        messageId: deleteChatMessageState?._id,
      };
      await postRecords(apiToDeleteData, req);
      await fetchAllRecords();
      setIsSubmitButtonClicked(false);
      handleCloseDeleteChatMessageModel();
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  };

  const handleForwardMessage = (e, item) => {
    setShowForwardMessageModel(item);
  };

  const handleDeleteChatMessage = (e, item) => {
    setDeleteChatMessageState(item);
  };

  const handleCloseForwardMessageModal = async () => {
    await fetchAllRecords();
    setShowForwardMessageModel(false);
  };

  const handleCloseDeleteChatMessageModel = async () => {
    await fetchAllRecords();
    setDeleteChatMessageState(false);
  };


  const renderChatMessagesDropdownItems = (document) => {
    return (
      <>
        <Dropdown.Item onClick={(e) => handleForwardMessage(e, document)}>
          Forward
        </Dropdown.Item>
        <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
          <Dropdown.Item onClick={(e) => handleDeleteChatMessage(e, document)}>
            Delete
          </Dropdown.Item>
        </ProtectedComponent>
      </>
    );
  };


  // Dropdown Group Actions

  const [showWorkingHoursModal, setShowWorkingHoursModal] = useState(false);
  const [showGroupInfoModal, setShowGroupInfoModal] = useState(false);
  const [showEditGroupMembersModal, setShowEditGroupMembersModal] = useState(false);

  const handleGroupInfo = (e, item) => {
    setShowGroupInfoModal(item);
  };

  const handleEditGroupMembers = (e, item) => {
    setShowEditGroupMembersModal(item);
  };

  const handleSetGroupWorkingHours = (e, item) => {
    setShowWorkingHoursModal(item);
  };

  const handleCloseWorkingHoursModel = async () => {
    await fetchAllRecords();
    handleUpdateLastMessage()
    setShowWorkingHoursModal(false);
  };

  const handleCloseGroupInfoModal = () => {
    fetchAllRecords();
    handleUpdateLastMessage()
    setShowGroupInfoModal(false);
  };

  const handleCloseEditGroupMembersModal = () => {
    fetchAllRecords();
    handleUpdateLastMessage()
    setShowEditGroupMembersModal(false);
  };

  const dropdownActions = (item) => (
    <Dropdown className="messageDropDownButton">
      <Dropdown.Toggle>
        <img src={menuToggleVertical} alt="Message Drop Down" />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={(e) => handleGroupInfo(e, item)}>
          Group Info
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleEditGroupMembers(e, item)}>
          Edit members
        </Dropdown.Item>
        <Dropdown.Item onClick={(e) => handleSetGroupWorkingHours(e, item)}>
          Set working hours
        </Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );

  // disabled group chat during the time 
  const [allowedGroupChat, setAllowedGroupChat] = useState(true);
  const [time, setTime] = useState(moment().format('HH:mm'));

  useEffect(() => {
    // Update time every second
    const interval = setInterval(() => {
      setTime(moment().format('HH:mm'));
    }, 1000);

    // Clear interval on unmount
    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (isCluster) {
      const startTimeCheck = moment(openChatData?.groupWorkingScheduleTime?.startTime).format('HH:mm');
      console.log("🚀 ~ useEffect ~ startTimeCheck:", startTimeCheck)
      const endTimeCheck = moment(openChatData?.groupWorkingScheduleTime?.endTime).format('HH:mm');
      console.log("🚀 ~ useEffect ~ endTimeCheck:", endTimeCheck)
      const currentTime = moment().format('HH:mm');
      console.log("🚀 ~ useEffect ~ currentTime:", currentTime)

      // Check if the current time is between start and end times
      if (startTimeCheck >= currentTime && endTimeCheck <= currentTime) {
        console.log("chat allowed");
        setAllowedGroupChat(true);
      } else {
        console.log("chat not allowed");
        setAllowedGroupChat(false);
      }
    }
  }, [time, openChatData]);
  // isCluster

  useEffect(() => {
    console.log("asdfasd fsdf sdf sdf", allowedGroupChat)
  }, [allowedGroupChat])


  // Attachment in chating window
  const [uploadedFilePath, setUploadedFilePath] = useState("");
  const [videoSizeError, setVideoSizeError] = useState(false)
  const validFileTypes = [
    'image/jpeg', 'image/png', 'image/jpg',     // Image formats
    'application/pdf',                          // PDF format
    'application/msword',                       // .doc format
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document' // .docx format
  ];

  const acceptFileTypes = validFileTypes.join(',');

  const handleImageChange = async (e) => {
    setIsSubmitButtonClicked(true);
    try {
      const file = e.target.files[0];
      const fileType = file.type;
      const fileSize = file.size;
      console.log("🚀 ~ handleImageChange ~ fileSize:", fileSize);

      // Validate file size (1MB = 1,048,576 bytes)
      if (fileSize > 1048576) {
        alert('File size exceeds 1MB');
        setVideoSizeError(true);
        setIsSubmitButtonClicked(false);
        return;
      }

      if (validFileTypes.includes(fileType)) {
        const fileData = new FormData();
        fileData.append('attachment', file);

        const apiResponse = await fetch(UPLOAD_MESSAGING_SYSTEM_FILE, {
          method: "POST",
          body: fileData,
          headers: {
            Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
          },
        });

        const resultResponse = await apiResponse.json();
        const uploadedFilePath = resultResponse?.data;
        setUploadedFilePath(uploadedFilePath);
        // Set the message field value and then submit the form
        await setFieldValue("message", uploadedFilePath);
        await setFieldValue("filePath", uploadedFilePath);
        await setFieldValue("messageType", "file");
        handleSubmit();  // Trigger the form submission

        console.log("File uploaded successfully:", uploadedFilePath);
      } else {
        // Unsupported file format
        alert('Unsupported file format');
      }
      setIsSubmitButtonClicked(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsSubmitButtonClicked(false);
    }
  };

  // Render messages screen
  const RenderMessages = ({ message, userDetail }) => {
    const fileExtension = message.message.split('.').pop().toLowerCase();
    const imageExtensions = ['png', 'jpg', 'jpeg'];
    const pdfExtensions = ['pdf'];
    const wordExtensions = ['doc', 'docx'];
    if (message.message.includes("/uploads/messagedoc")) {
      if (imageExtensions.includes(fileExtension)) {
        // Show image preview
        return (
          <div className="textMessage attachment attachmentMessage">
            <small className="groupMemberName">{userDetail}</small>
            <img src={BASE_URL + message.message} alt="attachment" />
          </div>
        );
      } else if (pdfExtensions.includes(fileExtension)) {
        // Show PDF attached message or icon
        return (
          <div className="textMessage attachment attachmentMessage">
            <small className="groupMemberName">{userDetail}</small>
            <a href={BASE_URL + message.message} target="_blank" rel="noopener noreferrer">
              <img className='icon' src={pdfIcon} alt="PDF Attached" />
            </a>
          </div>
        );
      } else if (wordExtensions.includes(fileExtension)) {
        // Show Word doc attached message or icon
        return (
          <div className="textMessage attachment attachmentMessage">
            <small className="groupMemberName">{userDetail}</small>
            <a href={BASE_URL + message.message} target="_blank" rel="noopener noreferrer">
              <img className='icon' src={wordIcon} alt="Word Document Attached" />
            </a>
          </div>
        );
      } else {
        // Fallback for other file types
        return (
          <div className="textMessage attachment attachmentMessage">
            <small className="groupMemberName">{userDetail}</small>
            <a href={BASE_URL + message.message} target="_blank" rel="noopener noreferrer">
              <img className='icon' src={wordIcon} alt="File Attached" />
            </a>
          </div>
        );
      }
    } else {
      return (
        <div className="textMessage">
          <small className="groupMemberName">{userDetail}</small>
          {message.message}
        </div>
      );
    }
  };
  return (
    <>
      <div className="roomSpace">
        {/* <p>time {time} - start time: {moment(openChatData?.groupWorkingScheduleTime?.startTime).format("HH:mm")} - end time:  {moment(openChatData?.groupWorkingScheduleTime?.endTime).format("HH:mm")} </p> */}
        {
          openChatData ? (
            <>
              <header>
                <div className="usersNameInfo">
                  <ToggleButton />
                  <div className="userImage">
                    {openChatUserData?.image ? (
                      <img src={BASE_URL + openChatUserData?.image} alt={openChatUserData?.name} />
                    ) : openChatUserData?.groupImage ? (
                      <img src={BASE_URL + openChatUserData?.groupImage} alt={openChatUserData?.groupName} />
                    ) : openChatUserData?.profile_picture_url ? (
                      <img src={BASE_URL + openChatUserData?.profile_picture_url} alt={openChatUserData?.name} />
                    ) : null}
                  </div>
                  <div className="userInfo">
                    <span>
                      {openChatUserData?.name ? openChatUserData?.name : openChatUserData?.groupName ? openChatUserData?.groupName : null}
                    </span>
                  </div>
                  {
                    isCluster && <div className="top">
                      {dropdownActions(openChatData)}
                    </div>
                  }
                </div>


              </header>
              <div className="messagesPanel min-height">
                {selectedChatData &&
                  selectedChatData.map((message, index) => (
                    <div
                      className={`messageBoxWrapper ${message.from?._id === ADMIN_ID
                        ? "receiverWrapper"
                        : "senderWrapper"
                        }`}
                      key={index}
                    >
                      {message.from?._id === ADMIN_ID || message?.userId?._id === ADMIN_ID ? (
                        <>
                          <div className="recieverMessageBox">
                            <div className="receiverMessage">
                              <Dropdown className="messageDropDownButton">
                                <Dropdown.Toggle>
                                  <img src={menuToggleVertical} alt="Message Drop Down" />
                                </Dropdown.Toggle>

                                <Dropdown.Menu>
                                  {renderChatMessagesDropdownItems(message)}
                                </Dropdown.Menu>
                              </Dropdown>



                              <RenderMessages message={message} userDetail={"You"} />

                              {/* {message.message} */}

                            </div>
                          </div>
                        </>
                      ) : (
                        <div className="senderMessage">
                          <RenderMessages message={message} userDetail={message?.userId?.name} />
                          {/* <div className="textMessage">
                            {message?.userId?._id && (
                              <>
                                <small className="groupMemberName">{message?.userId?.name}</small>
                              </>
                            )}
                            {message.message}
                          </div> */}
                          <Dropdown className="messageDropDownButton">
                            <Dropdown.Toggle>
                              <img src={menuToggleVertical} alt="Message Drop Down" />
                            </Dropdown.Toggle>

                            <Dropdown.Menu>
                              {renderChatMessagesDropdownItems(message)}
                            </Dropdown.Menu>
                          </Dropdown>
                        </div>
                      )}
                      <div className="time">
                        {message.seenAt && (
                          <>
                            {moment(message.seenAt).format("LT")}
                          </>
                        )}
                      </div>
                    </div>
                  ))}
                <div ref={messagesEndRef}></div>
              </div>
              <div className="messagesActions">
                <form onSubmit={handleSubmit}>
                  <footer>
                    <div className="textMessage">
                      <TextArea
                        id="message"
                        name="message"
                        value={values.message}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder={"Type your message"}
                        // labelText="Note Details"
                        rows="3"
                        className="mb-0"
                        customError={touched.message && errors.message}
                        errorMessage={errors.message}
                        erroClass={touched.message && errors.message ? "validationError" : ""}
                        disabledStatus={isCluster ? allowedGroupChat : false}
                      />
                    </div>
                    {/* <div className="emoji">
                      <span>
                        <img src={emoji} alt="Emoji" />
                      </span>
                    </div> */}
                    <div className="attachment">
                      <span>
                        <img src={attachment} alt="Attachment" />
                        <input
                          name="fileName"
                          id="fileName"
                          type="file"
                          accept={acceptFileTypes}
                          onChange={handleImageChange}
                        />
                      </span>

                    </div>
                    <div className="submit">
                      <Button className="btn-sm" variant="primary" type="submit" disabled={isSubmitButtonClicked}>
                        <img src={submit} alt="Submit" />
                      </Button>
                    </div>
                  </footer>
                </form>
              </div>
            </>
          ) : (
            <>
              <EmptyChatScreen toogleButton={<ToggleButton />} />
            </>
          )
        }
      </div >
      {deleteChatMessageState && (
        <>
          <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
            <Modal
              show={deleteChatMessageState}
              onHide={handleCloseDeleteChatMessageModel}
              className="ftcModalPopup confirmationPopup deletionConfirmation"
            >
              <Modal.Header closeButton>
                <Modal.Title>Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="editBlockContent text-center">
                  <img src={eventDel} alt={eventDel} />
                  <p>Are you sure you want to delete this message?</p>
                </div>
              </Modal.Body>
              <Modal.Footer>
                {isSubmitButtonClicked ? (
                  <Button className="btn-sm" variant="danger" disabled>
                    Loading...
                  </Button>
                ) : (
                  <Button className="btn-sm" variant="danger" onClick={deleteChatMessageFunc}>
                    Yes
                  </Button>
                )}
                <Button
                  className="btn-sm"
                  variant="outline-secondary"
                  onClick={handleCloseDeleteChatMessageModel}
                >
                  No
                </Button>
              </Modal.Footer>
            </Modal>
          </ProtectedComponent>
        </>
      )
      }
      {
        showWorkingHoursModal && (
          <SetGroupWorkingHours
            handleShowModel={showWorkingHoursModal}
            handleCloseModel={handleCloseWorkingHoursModel}
            groupItem={showWorkingHoursModal}
            fetchCompleteRecords={fetchAllRecords}
          />
        )
      }
      {
        showGroupInfoModal && (
          <GroupInfo
            handleShowModel={showGroupInfoModal}
            handleCloseModel={handleCloseGroupInfoModal}
            groupItem={showGroupInfoModal}
            fetchCompleteRecords={fetchAllRecords}
          />
        )
      }
      {
        showEditGroupMembersModal && (
          <EditGroupMembers
            handleShowModel={showEditGroupMembersModal}
            handleCloseModel={handleCloseEditGroupMembersModal}
            groupItem={showEditGroupMembersModal}
            fetchCompleteRecords={fetchAllRecords}
          />
        )
      }

      {
        showForwardMessageModal && (
          <ForwardMessage
            handleShowModel={showForwardMessageModal}
            handleCloseModel={handleCloseForwardMessageModal}
            groupItem={showForwardMessageModal}
          />
        )
      }
    </>
  );
}
export default RoomSpace;