import React, { useState } from "react";
import ProfileDetails from "../../components/user-detail/ProfileDetails";
import SicknessScore from "./SicknessScore";

function BradfordScoreIndividual({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(initialUserData);
  console.log(userData);
  return (
    <>
      <div className="profileDetailWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails getUserRecord={userData} />
          </div>
          <div className="col-md-9">
            <div className="userDetail bradfordDetail">
              <div className="mainContent p-0">
                <div className="sicknessScore">
                  <SicknessScore getUserRecord={userData} />
                </div>
                {/* <br />
                <p className="p-3 pt-0 pb-4">
                  No. of leave occurrences: <strong>02</strong>, No. of missed
                  workdays: <strong>03</strong>, Bradford Score:{" "}
                  <strong>2* 2* 3.00</strong>
                </p> */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BradfordScoreIndividual;
