import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import EditSuEnquiry from "./EditSuEnquiry";
import AddSuEnquiry from "./AddSuEnquiry";

function ServiceUserEnquiries() {
  const location = useLocation();
  const userData = location?.state ? location?.state?.userDetails : null; // Use null instead of an empty string
  const [suEnquiryData, setSuEnquiryData] = useState(userData);
  const [defaultSuValue, setDefaultSuValue] = useState(
    suEnquiryData?.[0]?.serviceUser?._id
  );
  
  // Use useEffect to handle state updates based on suEnquiryData changes
  useEffect(() => {
    if (suEnquiryData !== null && suEnquiryData !== "") {
      setFormStatus(false);
    }
  }, [suEnquiryData]);

  const [formStatus, setFormStatus] = useState(true);

  return <>{formStatus ? <AddSuEnquiry /> : <EditSuEnquiry />}</>;
}

export default ServiceUserEnquiries;
