// ProtectedConditionalComponent.jsx
import React from "react";
import useAuthorization from "./useAuthorization";
import { useAuth } from "./AuthContext";

const ProtectedConditionalComponent = ({ requiredPermissions, children, fallback = null }) => {
  const { userRoles } = useAuth();
  const isAuthorized = useAuthorization(userRoles, requiredPermissions);

  // Log statements for debugging if needed
  // console.log("🚀 ~ ProtectedComponent ~ userRoles:", userRoles);
  // console.log("🚀 ~ ProtectedComponent ~ isAuthorized:", isAuthorized);

  if (!isAuthorized) {
    return fallback; // Render fallback content if not authorized
  }

  return <>{children}</>;
};

export default ProtectedConditionalComponent;
