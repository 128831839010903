import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProgressComponent from "../../components/general/ProgressComponent";
import assignRole from "../../assets/images/icons/assignRole.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  filterColumns,
  filterRowsByProperty,
  displayEnumProperty,
  filterArrayBySearchTerm,
  toastErrorMessages,
  formateDate,
  catchAsync,
  calculateHours,
} from "../../helper/helper";
import Layout from "../../components/layouts/Layout";
import AssignRole from "./AssignRole";
import { wrap } from "lodash";

function Advanced() {
  const navigate = useNavigate();
  const [search, setSearch] = useState("");
  const [userData, setUserData] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]);
  console.log("🚀 ~ Advanced ~ filteredRows:", filteredRows)
  const [isLoading, setIsLoading] = useState(true);
  const [isToastStaffRecords, setIsToastStaffRecords] = useState(false);
  const [userProfileDetail, setUserProfileDetail] = useState("")

  const [showAssignRole, setShowAssignRole] = useState(false);
  const subModalAssignRole = (emarItem) => {
    setUserProfileDetail(emarItem);
    setShowAssignRole(!showAssignRole);
    fetchAllRecords();
  };

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
    },
  };

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastStaffRecords(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "get" && data?.status === isSuccess) {
      const staffMembers = data?.data?.staffMembers?.map((item) => item);
      const filteredRows = filterRowsByProperty(
        staffMembers,
        (row) => row?.user?.name
      );
      setUserData(filteredRows);
      setFilteredRows(filteredRows);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);
  
  useEffect(() => {
    fetchAllRecords();
  }, [showAssignRole]);

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        userData,
        lowercaseSearch,
        (record) => record?.user?.name?.toLowerCase()
      );
      setFilteredRows(filteredRecords);
    } else {
      setFilteredRows(userData);
    }
  };

  // const handleRowClickStaffMember = (e, row) => {
  //   e.preventDefault();
  //   navigate("/staff-records", { state: { userId: row } });
  // };

  const handleTableRowClick = (row) => {
    navigate("/staff-records", { state: { userId: row } });
  };
  const columns = [
    {
      name: "#",
      selector: (row, index) => (
        <span>
          {index + 1}
        </span>
      ),
      width: "5%",
      center: true,
    },
    {
      name: "Name",
      selector: (row) => {
        return (
          <span
            className="usersNameInfo"
          >
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.user?.profile_picture_url}`}
                alt={row?.name}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.user?.name}`}</span>
            </span>
          </span>
        );
      },
      width: "60%",
    },
    {
      name: "Staff Roles",
      selector: (row) => {
        if (!row?.user?.staffRoles) return ""; // Check if staffRoles exist

        return (
          <ul className="staffRolesList">
            {row?.user?.staffRoles.map((role, index) => (
              <li key={index}>
                {displayEnumProperty("staffRoles", role)}
              </li>
            ))}
          </ul>
        );
      },
      width: "25%",
      wrap: true
    },
    {
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns">

            <span
              className="assignRole"
              onClick={() => {
                subModalAssignRole(row);
              }}
            >
              <img src={assignRole} alt="Assign Role" title="Assign Role" />
            </span>

          </div>
        );
      },
      width: "10%",
      center: true,
    },

  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);


  return (
    <>
      <Layout pageTitleText="Access Control">
        <div className="mainContent">
          <div className="dataTableWrapper">
            <DataTable
              className="maxWidthTable"
              columns={filteredColumns}
              data={filteredRows}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              fixedHeader
              fixedHeaderScrollHeight="520px"
              highlightOnHover
              actions={
                <>
                  {/* <button className="btn btn-sm btn-outline-primary">
                Archived Staff
              </button>{" "} */}
                  {/* <Link to="/add-new-staff" className="btn btn-sm btn-primary">
                    Add New
                  </Link> */}
                </>
              }
              responsive={true}
              subHeader={true}
              subHeaderComponent={
                <div className="searchContainer">
                  <div className="searchTextBox">
                    <input
                      type="text"
                      placeholder="Type your search"
                      value={search}
                      onChange={handleSearch}
                    />
                  </div>
                </div>
              }
              subHeaderAlign="left"
              subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
            // onRowClicked={handleTableRowClick}
            />
          </div>
          {isToastStaffRecords && <ToastContainer />}
        </div>
      </Layout>


      {/* Assign Role PopUp */}
      <Modal
        show={showAssignRole}
        onHide={subModalAssignRole}
        className="ftcModalPopup medicineInventoryStock"
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Assign Role</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <AssignRole userProfileDetail={userProfileDetail} showAssignRole={setShowAssignRole}  />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Advanced;