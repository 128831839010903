import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import TextArea from "../../components/forms-fields/TextArea";
import Input from "../../components/forms-fields/Input";
import { addMedicationValidation } from "../../schemas";
import { useFormik } from "formik";
import Select from 'react-select'
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_EMAR_MEDICATION_URL, ADMIN_ID } from "../../env";
import MedicationStocksDetail from "../../components/forms-fields/MedicationStocksDetail";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import Layout from "../../components/layouts/Layout";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import SelectBox from "../../components/forms-fields/SelectBox";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import eventClose from "../../assets/images/icons/eventClose.svg";
import eventAdd from "../../assets/images/icons/eventAdd.svg";
// import HealthCategories from "./HealthCategories";

function AddMedicationSchedule() {
  // const navigate = useNavigate();
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  // console.log("🚀 ~ AddMedicationSchedule ~ userDetails:", userDetails)
  const [serviceUserId, setServiceUserId] = useState(userDetails?.modelId?._id)
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [errorMessageHourlyDose, setErrorMessageHourlyDose] = useState(false);
  const [errorMessageStandardDose, setErrorMessageStandardDose] = useState(false);
  const [errorMessageSpecificDose, setErrorMessageSpecificDose] = useState(false);
  const [isSuFormData, setIsSuFormData] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddAppointment, setIsToastAddAppointment] = useState(false);
  const [isToastAddHealthIssue, setIsToastAddHealthIssue] = useState(false);
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  const [doseTimeType, setDoseTimeType] = useState('hourly'); // State to hold the selected time type
  // const [startTime, setStartTime] = useState(''); // State to hold the selected time type
  const [standardDoseTiming, setStandardDoseTiming] = useState("");


  // get medication data
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [medicationData, setMedicationData] = useState([]);
  // console.log("🚀 ~ AddMedicationSchedule ~ medicationData:", medicationData)
  const [selectedMedicationData, setSelectedMedicationData] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  // console.log("🚀 ~ AddMedicationSchedule ~ selectedUserId:", selectedUserId)
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedby", userId);
  };
  const [filteredMedicationID, setFilteredMedicationID] = useState("");
  const [medicationTypeOfSelected, setMedicationTypeOfSelected] = useState();
  // console.log("🚀 ~ AddMedicationSchedule ~ medicationTypeOfSelected:", medicationTypeOfSelected)
  useEffect(() => {
    const filteredData = medicationData.filter(item => {
      if (item.medicationName === selectedMedication) {
        setSelectedMedicationData(item.medicationName);
        setFilteredMedicationID(item?._id);
        return true;  // return true to include the item in filteredData
      }
      return false; // return false to exclude the item from filteredData
    });

    const medicationType = filteredData.length > 0 ? filteredData[0].medicineType : null;
    setMedicationTypeOfSelected(medicationType);
  }, [medicationData, selectedMedication]);

  const handleMedicationSelect = (value) => {
    setSelectedMedication(value);
    // setFieldValue("medicationName", selectedMedication)
  };

  const handleMedicationDataChange = (data) => {
    setMedicationData(data);
    setFieldValue("medicationName", medicationData?.medicationName)
  };

  // console.log("🚀 ~ setMedicationDat sfs fsdf sd fsd fsa:", medicationData);
  // console.log("🚀 ~ medicationData:", medicationData);
  // console.log("🚀 ~ selectedMedicationData:", selectedMedicationData);



  const options = [
    { value: 'Monday', label: 'Monday' },
    { value: 'Tuesday', label: 'Tuesday' },
    { value: 'Wednesday', label: 'Wednesday' },
    { value: 'Thursday', label: 'Thursday' },
    { value: 'Friday', label: 'Friday' },
    { value: 'Saturday', label: 'Saturday' },
    { value: 'Sunday', label: 'Sunday' }
  ];

  const orderedDays = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // const handleUserSelect = (userId) => {
  //   setSelectedUserId(userId);
  // };

  const sortDays = (selectedDays) => {
    return selectedDays.sort((a, b) => orderedDays.indexOf(a) - orderedDays.indexOf(b));
  };

  const [suFormData, setSuFormData] = useState({
    addedby: selectedUserId,
    medicationName: "",
    userId: userDetails?.modelId?._id,
    prescriberName: "",
    days: [],
    dose: [],
    note: "",
    reasonForMedication: "",
    prescriptionText: "",
    evidence: "",
    gpName: "",
    medicationStartDate: "",
    medicationEndDate: "",
    medicationId: "",
    isBlisterPack: false,
    isPrn: false,
    withFood: false,
    selfAdministrator: false,
    bodyMap: false,
    multiDescription: false,
    treatAsCream: false,
    seconfSing: false,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addMedicationValidation,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);
        setIsSuFormData(false);
        setIsSubmitButtonClicked(true);
        await postHealthIssueFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });



  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddAppointment(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      // handleShowHideModal();
    } else if (
      method === "postHealthIssueFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddAppointment(true);
      toastSuccessMessages(toast.success, data?.message, "/emar", 500, 500);
      setIsSubmitButtonClicked(false);
      // setTimeout(() => {
      //   handleShowHideModal();
      // }, 2000);
    }
  };


  const postHealthIssueFormData = catchAsync(async (req, res) => {
    const orderedSelectedDays = sortDays(values.days);
    let doseArray = [];
    let hasEmptyDose = false;

    if (doseTimeType === "hourly") {
      doseArray = hourlyDose.map(({ noOfDoses, startTime, doseTime }) => {
        if (doseTime === "" || doseTime === null) {
          hasEmptyDose = true;
          setErrorMessageHourlyDose(true)
        }

        return {
          // dose: selectedUserId,
          noOfDoses: noOfDoses,
          startTime: moment(startTime, 'HH:mm').format('h:mm A'),
          doseTime: moment(doseTime, 'HH:mm').format('h:mm A'),
          medicationType: medicationTypeOfSelected,
          timeType: doseTimeType,
        };
      });
    } else if (doseTimeType === "standard") {
      doseArray = standardDose.map(({ noOfDoses, doseTime }) => {
        if (doseTime === "" || doseTime === null) {
          hasEmptyDose = true;
          setErrorMessageStandardDose(true)
        }

        return {
          // dose,
          noOfDoses: noOfDoses,
          medicationType: medicationTypeOfSelected,
          doseTime: moment(doseTime, 'HH:mm').format('h:mm A'),
          timeType: doseTimeType,
        };
      });
    } else if (doseTimeType === "specific") {
      doseArray = dose.map(({ dose, doseTime, noOfDoses }) => {
        if (doseTime === "" || doseTime === null) {
          hasEmptyDose = true;
          setErrorMessageSpecificDose(true)
        }

        return {
          // dose,
          medicationType: medicationTypeOfSelected,
          noOfDoses: noOfDoses,
          doseTime: moment(doseTime, 'HH:mm').format('h:mm A'),
          timeType: doseTimeType,
        };
      });
    }


    if (hasEmptyDose) {
      // Display error message or handle the error accordingly

      setIsSubmitButtonClicked(false)
    } else {
      // Continue with the rest of the code
      setErrorMessageHourlyDose(false)
      setErrorMessageStandardDose(false)
      setErrorMessageSpecificDose(false)
      const updatedValues = {
        ...values,
        medicationName: selectedMedication,
        medicationStartDate: moment(values.medicationStartDate).valueOf(),
        medicationEndDate: moment(values.medicationEndDate).valueOf(),
        days: orderedSelectedDays,
        dose: doseArray,
        medicationId: filteredMedicationID
      };
      const response = await postRecords(POST_EMAR_MEDICATION_URL, updatedValues);
      processPostRecords("postHealthIssueFormData", response);
      console.log("🚀 ~ postHealthIssueFormData ~ updatedValues:", updatedValues)
      console.log("🚀 ~ postHealthIssueFormData ~ response:", response)
      setIsSubmitButtonClicked(false);
    }

  });


  const handleInputChangePrevFields = (e) => {
    handleChange(e);
    handleBlur(e);
  };

  const handleRadioInputChangePrevFields = (e) => {
    setDoseTimeType(e.target.value);
  };


  // Specific Timing
  const [dose, setDose] = useState([
    {
      id: 1,
      // dose: selectedUserId,
      noOfDoses: "",
      medicationType: medicationTypeOfSelected,
      doseTime: ''
    }

  ]);
  // console.log("🚀 ~ AddMedicationSchedule ~ selecsdfsdffsdfsdftedUserId:", selectedUserId)
  const addSpecificDoseTmeRow = () => {
    const newRow = {
      id: dose.length + 1,
      noOfDoses: "",
      medicationType: medicationTypeOfSelected,
      doseTime: ''
    };
    setDose([...dose, newRow]);
  };

  const removeSpecificDoseTmeRow = (id) => {
    const newRows = dose.filter((row) => row.id !== id);
    setDose(newRows);
  };

  const handleInputChange = (id, field, value) => {
    const updatedRows = dose.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setDose(updatedRows);
    const updatedValues = {
      ...values,
      dose: updatedRows,
    };
    setFieldValue(`dose[${id}].${field}`, value);
    setFieldValue('dose', updatedRows);
  };


  // Standard Timing
  const [standardDose, setStandardDose] = useState([
    {
      id: 1,
      // dose: selectedUserId,
      noOfDoses: "",
      medicationType: medicationTypeOfSelected,
      doseTime: "1:00 AM"
    }
  ]);
  // console.log("🚀 ~ AddMedicationSchedule ~ standardDose:", standardDose)

  const addStandardDoseTimeRow = () => {
    // console.log("Adding Standard Dose Time Row");
    const newRow = {
      id: standardDose.length + 1,
      // dose: '',
      noOfDoses: '',
      medicationType: medicationTypeOfSelected,
      doseTime: ''
    };
    setStandardDose([...standardDose, newRow]);
  };

  const removeStandardDoseTimeRow = (id) => {
    const newRows = standardDose.filter((row) => row.id !== id);
    setStandardDose(newRows);
  };

  const handleStandardInputChange = (id, field, value) => {
    const updatedRows = standardDose.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setStandardDose(updatedRows);
    const updatedValues = {
      ...values,
      dose: updatedRows,
    };
    setFieldValue(`dose[${id}].${field}`, value);
    setFieldValue('dose', updatedRows);
  };


  // Hourly Timing
  const [hourlyDose, setHourlyDose] = useState([
    {
      noOfDoses: '',
      startTime: '',
      medicationType: medicationTypeOfSelected,
      doseTime: ''
    }
  ]);

  const handleHourlyInputChange = (field, value) => {
    const updatedRows = hourlyDose.map((row) => ({
      ...row,
      [field]: field === "doseTime" ? `${value}:00 AM` : value,
    }));
    setHourlyDose(updatedRows);

    const updatedValues = {
      ...values,
      dose: updatedRows,
    };
    setFieldValue('dose', updatedRows);
  };

  const handleHourlyInputChangeArray = (value) => {
    const startTime = hourlyDose[0]?.startTime || "01:00"; // Default start time in 24-hour format if not set
    const interval = parseInt(value); // Dose interval in hours
    const endTime = "23:59"; // Stop time in 24-hour format
  
    const updatedRows = [];
    let currentDoseTime = moment(startTime, "HH:mm"); // Start time in 24-hour format
  
    // Generate dose objects until the current time exceeds the end time
    while (currentDoseTime.isBefore(moment(endTime, "HH:mm"))) {
      updatedRows.push({
        noOfDoses: hourlyDose[0]?.noOfDoses || '', // Take value from state
        startTime: currentDoseTime.format("HH:mm"), // Format startTime in 24-hour format
        medicationType: hourlyDose[0]?.medicationType || 'Regular Medication', // Take value from state
        doseTime: hourlyDose[0]?.doseTime, // Keep the existing doseTime unchanged
        timeType: "hourly", // Customize if needed
      });
  
      // Increment the current time by the dose interval (e.g., 4 hours)
      currentDoseTime = currentDoseTime.add(interval, 'hours');
    }
  
    setHourlyDose(updatedRows);
  
    const updatedValues = {
      ...values,
      dose: updatedRows,
    };
    setFieldValue('dose', updatedRows);
  };
  
  

  // Trigger useEffect when startTime or doseTime changes
  useEffect(() => {
    const doseTime = hourlyDose[0]?.doseTime; // Get the current doseTime from the state
    if (doseTime) {
      handleHourlyInputChangeArray(doseTime);
    }
  }, [hourlyDose[0]?.doseTime, hourlyDose[0]?.startTime]); // Add dependencies

  const standardTiming = [
    { value: "1", label: "Morning" },
    { value: "2", label: "Afternoon" },
    { value: "3", label: "Evening" },
    { value: "4", label: "Night" },
  ];

  const calculateDoseTime = (selectedValue) => {
    switch (selectedValue) {
      case "1":
        return "8:00 AM";
      case "2":
        return "12:00 PM";
      case "3":
        return "5:00 PM";
      case "4":
        return "8:00 PM";
      default:
        return "8:00 AM";
    }
  };


  // Step 1 support area check boxes list
  const [emarMedicineCheckList, setEmarMedicineCheckList] = useState([
    { id: 1, name: "isBlisterPack", values: "In Blister Pack", label: "In Blister Pack", named: "", checked: values?.isBlisterPack },
    { id: 2, name: "isPrn", values: "PRN", label: "PRN", checked: values?.isPrn },
    { id: 3, name: "withFood", values: "With Food", label: "With Food", checked: values?.withFood },
    { id: 4, name: "selfAdministrator", values: "Self Administer", label: "Self Administer", checked: values?.selfAdministrator },
    { id: 5, name: "bodyMap", values: "Body Map", label: "Body Map", checked: values?.bodyMap },
    { id: 6, name: "multiDescription", values: "Multi Description", label: "Multi Description", checked: values?.multiDescription },
    { id: 7, name: "treatAsCream", values: "Treat as Cream", label: "Treat as Cream", checked: values?.treatAsCream },
    { id: 8, name: "seconfSing", values: "Second Sig", label: "Second Sig", checked: values?.seconfSing },
  ]);

  const handleInputCheckListChange = (id, field, value) => {
    const updatedRows = emarMedicineCheckList.map((row) =>
      row.id === id ? { ...row, [field]: value } : row
    );
    setEmarMedicineCheckList(updatedRows);
  };

  useEffect(() => {
    console.log("sdfasdf sdfs asdf asdf sdfasd fsd f", selectedMedication)
  }, [selectedMedication])
  return (
    <>
      <Layout pageTitleText="Add Medication Schedule">
        <div className="incidents consentCapacityAddNew">
          <div className="incidents consentCapacityAddNew">
            <div className="mainContent ">
              <div className="mainContentText">
                <h3>Medication Schedule </h3>
                {/* <small>{userDetails?.modelId?._id}</small> */}
                <form onSubmit={handleSubmit}>
                  <div className="formBlockContent">
                    <div className="row align-items-start">
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <CustomSelect
                            id=""
                            name="addedby"
                            onSelect={handleUserSelect}
                            defaultValue={ADMIN_ID}
                            filterBy=""
                            label="Added By"
                            customError={touched?.addedby && errors?.addedby}
                            errorMessage={errors?.addedby}
                            errorClass={
                              touched?.addedby && errors?.addedby
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <MedicationStocksDetail
                            id=""
                            name="medicationName"
                            userId={userDetails?.modelId?._id}
                            onSelect={handleMedicationSelect}
                            onMedicationDataChange={handleMedicationDataChange}
                            label="Medicine Name"
                            customError={touched?.medicationName && errors?.medicationName}
                            errorMessage={errors?.medicationName}
                            errorClass={
                              touched?.medicationName && errors?.medicationName
                                ? "validationError"
                                : ""
                            }
                          />
                        </div>
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <Input
                          type="text"
                          id="prescriberName"
                          name="prescriberName"
                          value={values?.prescriberName}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Prescriber Name"
                          marginBottom={true}
                        // customError={touched?.prescriberName && errors?.prescriberName}
                        // errorMessage={errors?.prescriberName}
                        // erroClass={
                        //   touched?.prescriberName && errors?.prescriberName
                        //     ? "validationError"
                        //     : ""
                        // }
                        />
                      </div>
                      <div className="col-sm-6 col-md-3">
                        <div className="form-group">
                          <div className="selectBoxLevel">
                            <span className="multiSelectCustomLabel">Days</span>
                            <Select
                              name="days"
                              isMulti={true}
                              options={options}
                              value={values?.days?.value} // Set the value of the react-select to Formik's selected days
                              onChange={(selectedOptions) =>
                                setFieldValue('days', selectedOptions.map(option => option.value))
                              }
                            />
                            {errors.days && touched.days && (
                              <div className="text-danger">{errors.days}</div>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="checkboxesList">
                          <div key={'hourly'}>
                            <Form.Check
                              type="radio"
                              id={'hourly'}
                              name={'doseTimeType'}
                              value={'hourly'}
                              checked={doseTimeType === 'hourly'}
                              onChange={handleRadioInputChangePrevFields}
                              label={'Hourly'}
                            />
                          </div>
                          <div key={'standard'}>
                            <Form.Check
                              type="radio"
                              id={'standard'}
                              name={'doseTimeType'}
                              value={'standard'}
                              checked={doseTimeType === 'standard'}
                              onChange={handleRadioInputChangePrevFields}
                              label={'Standard'}
                            />
                          </div>
                          <div key={'specific'}>
                            <Form.Check
                              type="radio"
                              id={'specific'}
                              name={'doseTimeType'}
                              value={'specific'}
                              checked={doseTimeType === 'specific'}
                              onChange={handleRadioInputChangePrevFields}
                              label={'Specific'}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="checkboxesContent">
                      {doseTimeType === 'hourly' ? (
                        <>
                          <div className="doseSchedule">
                            {/* {hourlyDose[0].map((row, index) => ( */}
                            <div className="row align-items-center">
                              {/*  <div className="col-sm-5 col-md-4">
                                  <div className="form-group">
                                    <CustomSelect
                                      id=""
                                      name="dose"
                                      onSelect={handleUserSelect}
                                      filterBy={""}
                                      label={"Select Staff"}
                                      errorClass={isStaffId ? "validationError" : ""}
                                      customError={isStaffId}
                                      errorMessage={
                                        isStaffId ? errorMessage : "Please select staff"
                                      }
                                    />
                                    {
                                      errorMessageHourlyDose && (
                                        <>
                                          <div className="text-danger">Dose or doseTime cannot be empty or null.</div>
                                        </>
                                      )
                                    }
                                  </div>
                                  <Input
                                    type="text"
                                    id={`dose`}
                                    name={`dose`}
                                    value={values?.dose?.dose}
                                    onChange={(e) => handleHourlyInputChange('dose', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText="Staff Name"
                                    customError={touched?.dose?.dose && errors?.dose?.dose}
                                    errorMessage={errors?.dose?.dose}
                                    erroClass={
                                      touched?.dose?.dose && errors?.dose?.dose
                                        ? "validationError"
                                        : ""
                                    }
                                  /> 
                                </div>*/}
                              <div className="col-sm-12 col-md-2">
                                <Input
                                  type="number"
                                  id={`noOfDoses`}
                                  name={`noOfDoses`}
                                  value={values?.noOfDoses}
                                  onChange={(e) => handleHourlyInputChange('noOfDoses', e.target.value)}
                                  onBlur={handleBlur}
                                  labelText={`No of Doses`}
                                  min={1}
                                  max={12}
                                  pattern="[1-9]"
                                />

                              </div>

                              <div className="col-sm-12 col-md-3">
                                <Input
                                  type="time"
                                  id={`startTime`}
                                  name={`startTime`}
                                  value={values?.dose?.startTime}
                                  onChange={(e) => handleHourlyInputChange('startTime', e.target.value)}
                                  onBlur={handleBlur}
                                  labelText={`Start Time`}
                                  min={1}
                                  max={12}
                                  pattern="[1-9]"
                                />
                              </div>
                              <div className="col-sm-12 col-md-3">
                                <Input
                                  type="number"
                                  id={`doseTime`}
                                  name={`doseTime`}
                                  value={values?.dose?.doseTime}
                                  onChange={(e) => handleHourlyInputChange('doseTime', e.target.value)}
                                  onBlur={handleBlur}
                                  labelText={`Hourly Time for Dose`}
                                  min={1}
                                  max={12}
                                  pattern="[1-9]"
                                />

                              </div>
                              <div className="col-sm-12 col-md-3">
                                <Input
                                  type="text"
                                  id={`medicationType`}
                                  name={`medicationType`}
                                  value={medicationTypeOfSelected}
                                  onChange={(e) => handleHourlyInputChange('medicationType', e.target.value)}
                                  onBlur={handleBlur}
                                  labelText={`Medication Type`}
                                  disabledStatus={true}
                                />

                              </div>
                            </div>

                            {/* ))}*/}

                          </div>
                        </>
                      ) : doseTimeType === 'standard' ? (
                        <>
                          <div className="doseSchedule">
                            {standardDose.map((row, index) => (
                              <div className="row align-items-center" key={row?.id}>
                                {/* <div className="col-sm-5 col-md-4">
                                  <div className="form-group">
                                    <CustomSelect
                                      id={`dose_${row.id}`}
                                      name={`dose_${row.id}`}
                                      onSelect={(selectedUserId) => handleStandardInputChange(row.id, 'dose', selectedUserId)}
                                      filterBy={""}
                                      label={"Select Staff"}
                                      errorClass={isStaffId ? "validationError" : ""}
                                      customError={isStaffId}
                                      errorMessage={
                                        isStaffId ? errorMessage : "Please select staff"
                                      }
                                    />
                                    {
                                      errorMessageStandardDose && (
                                        <>
                                          <div className="text-danger">Dose or doseTime cannot be empty or null.</div>
                                        </>
                                      )
                                    }
                                  </div>
                                  <Input
                                    type="text"
                                    id={`dose_${row.id}`}
                                    name={`dose_${row.id}`}
                                    value={row.dose}
                                    onChange={(e) => handleStandardInputChange(row.id, 'dose', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText="Staff Name"
                                    customError={touched?.dose?.[row.id]?.dose && errors?.dose?.[row.id]?.dose}
                                    errorMessage={errors?.dose?.[row.id]?.dose}
                                    erroClass={
                                      touched?.dose?.[row.id]?.dose && errors?.dose?.[row.id]?.dose
                                        ? "validationError"
                                        : ""
                                    }
                                  /> 
                                </div>*/}
                                <div className="col-sm-12 col-md-2">
                                  <Input
                                    type="number"
                                    id={`noOfDoses_${row.id}`}
                                    name={`noOfDoses_${row.id}`}
                                    value={row?.noOfDoses}
                                    onChange={(e) => handleStandardInputChange(row.id, 'noOfDoses', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText={`No of Doses`}
                                    min={1}
                                    max={12}
                                    pattern="[1-9]"
                                  />

                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <Input
                                    hidden={true}
                                    type="time"
                                    id={`doseTime_${row.id}`}
                                    name={`doseTime_${row.id}`}
                                    value={row.doseTime}
                                    onChange={(e) => handleStandardInputChange(row.id, 'doseTime', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText={`Standard Time for Dose ${row.id}`}
                                  // customError={touched?.dose?.[row.id]?.doseTime && errors?.dose?.[row.id]?.doseTime}
                                  // errorMessage={errors?.dose?.[row.id]?.doseTime}
                                  // erroClass={
                                  //   touched?.dose?.[row.id]?.doseTime && errors?.dose?.[row.id]?.doseTime
                                  //     ? "validationError"
                                  //     : ""
                                  // }
                                  />

                                  <SelectBox
                                    name={`doseTime_${row.id}`}
                                    value={row.standardDoseTiming}
                                    onChange={(e) => {
                                      const updatedRows = standardDose.map((r) =>
                                        r.id === row.id ? { ...r, standardDoseTiming: e.target.value } : r
                                      );
                                      setStandardDose([...updatedRows]);
                                      handleStandardInputChange(row.id, 'doseTime', calculateDoseTime(e.target.value));
                                    }}
                                    options={standardTiming}
                                    labelText="Dose Time"
                                    requiredStar={true}
                                  />
                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <Input
                                    type="text"
                                    id={`medicationType`}
                                    name={`medicationType`}
                                    value={medicationTypeOfSelected}
                                    onChange={(e) => handleHourlyInputChange('medicationType', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText={`Medication Type`}
                                    disabledStatus={true}
                                  />

                                </div>

                                <div className="col-sm-2 col-md-2">
                                  <img className='removeRow' src={eventClose} alt="Close" onClick={() => removeStandardDoseTimeRow(row.id)} />
                                  {/* <span
                                    className="btn btn-sm btn-danger"
                                    onClick={() => removeStandardDoseTimeRow(row.id)}
                                  >
                                    Remove
                                  </span> */}
                                </div>
                              </div>
                            ))}

                            <div className="row mb-3">
                              <div className="col-sm-6 col-md-5"></div>
                              <div className="col-sm-6 col-md-3"></div>
                              <div className="col-md-2">
                                <img className='addRow' src={eventAdd} alt="Add" onClick={addStandardDoseTimeRow} />
                                {/* <span className="btn btn-sm btn-success" onClick={addStandardDoseTimeRow}>
                                  Add Stadndard Dose
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : doseTimeType === 'specific' ? (
                        <>
                          <div className="doseSchedule">
                            {dose.map((row, index) => (
                              <div className="row align-items-center" key={row?.id}>
                                {/*<div className="col-sm-5 col-md-4">
                                  <div className="form-group">
                                    <CustomSelect
                                      id={`dose_${row.id}`}
                                      name={`dose_${row.id}`}
                                      onSelect={(selectedUserId) => handleInputChange(row.id, 'dose', selectedUserId)}
                                      filterBy={""}
                                      label={"Select Staff"}
                                      errorClass={isStaffId ? "validationError" : ""}
                                      customError={isStaffId}
                                      errorMessage={
                                        isStaffId ? errorMessage : "Please select staff"
                                      }
                                    />

                                  </div>
                                   <Input
                                    type="text"
                                    id={`dose_${row.id}`}
                                    name={`dose_${row.id}`}
                                    value={row.dose}
                                    onChange={(e) => handleInputChange(row.id, 'dose', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText="Staff Name"
                                    customError={touched?.dose?.[row.id]?.dose && errors?.dose?.[row.id]?.dose}
                                    errorMessage={errors?.dose?.[row.id]?.dose}
                                    erroClass={
                                      touched?.dose?.[row.id]?.dose && errors?.dose?.[row.id]?.dose
                                        ? "validationError"
                                        : ""
                                    }
                                  /> 
                                </div>*/}
                                <div className="col-sm-12 col-md-2">
                                  <Input
                                    type="number"
                                    id={`noOfDoses_${row.id}`}
                                    name={`noOfDoses_${row.id}`}
                                    value={row?.noOfDoses}
                                    onChange={(e) => handleInputChange(row.id, 'noOfDoses', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText={`No of Doses`}
                                    min={1}
                                    max={12}
                                    pattern="[1-9]"
                                  />

                                </div>
                                <div className="col-sm-5 col-md-3">
                                  <Input
                                    type="time"
                                    id={`doseTime_${row.id}`}
                                    name={`doseTime_${row.id}`}
                                    value={row.doseTime}
                                    onChange={(e) => handleInputChange(row.id, 'doseTime', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText={`Time for Dose ${row.id}`}
                                    customError={touched?.dose?.[row.id]?.doseTime && errors?.dose?.[row.id]?.doseTime}
                                    errorMessage={errors?.dose?.[row.id]?.doseTime}
                                    erroClass={
                                      touched?.dose?.[row.id]?.doseTime && errors?.dose?.[row.id]?.doseTime
                                        ? "validationError"
                                        : ""
                                    }
                                  />
                                </div>
                                <div className="col-sm-12 col-md-3">
                                  <Input
                                    type="text"
                                    id={`medicationType`}
                                    name={`medicationType`}
                                    value={medicationTypeOfSelected}
                                    onChange={(e) => handleHourlyInputChange('medicationType', e.target.value)}
                                    onBlur={handleBlur}
                                    labelText={`Medication Type`}
                                    disabledStatus={true}
                                  />

                                </div>
                                <div className="col-sm-2 col-md-2">
                                  {/* {index !== 0 && ( */}
                                  <img className='removeRow' src={eventClose} alt="Close" onClick={() => removeSpecificDoseTmeRow(row.id)} />

                                  {/* <span
                                    className="btn btn-sm btn-danger"
                                    onClick={() => removeSpecificDoseTmeRow(row.id)}
                                  >
                                    Remove
                                  </span> */}
                                  {/* )} */}
                                </div>
                              </div>
                            ))}
                            <div className="row mb-3">
                              <div className="col-sm-6 col-md-5">
                                {
                                  errorMessageSpecificDose && (
                                    <>
                                      <div className="form-group">
                                        <div className="text-danger">Dose or doseTime cannot be empty or null.</div>
                                      </div>
                                    </>
                                  )
                                }
                              </div>
                              <div className="col-sm-6 col-md-4"></div>
                              <div className="col-md-2">
                                <img className='addRow' src={eventAdd} alt="Add" onClick={addSpecificDoseTmeRow} />
                                {/* <span className="btn btn-sm btn-success" onClick={addSpecificDoseTmeRow}>
                                  Add Dose
                                </span> */}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                    <div className="row">
                      <div className="col-sm-6 col-md-4">
                        <Input
                          type="date"
                          id="medicationStartDate"
                          name="medicationStartDate"
                          value={moment(values?.medicationStartDate).format("YYYY-MM-DD")}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Medication Start Date"
                          customError={touched?.medicationStartDate && errors?.medicationStartDate}
                          errorMessage={errors?.medicationStartDate}
                          erroClass={
                            touched?.medicationStartDate && errors?.medicationStartDate
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <Input
                          type="date"
                          id="medicationEndDate"
                          name="medicationEndDate"
                          value={moment(values?.medicationEndDate).format("YYYY-MM-DD")}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Medication End Date"
                          customError={touched?.medicationEndDate && errors?.medicationEndDate}
                          errorMessage={errors?.medicationEndDate}
                          erroClass={
                            touched?.medicationEndDate && errors?.medicationEndDate
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-sm-6 col-md-4">
                        <Input
                          type="text"
                          id="evidence"
                          name="evidence"
                          value={values?.evidence}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Evidence"

                        />
                      </div>
                      <div className="col-sm-6 col-md-4">
                        <Input
                          type="text"
                          id="gpName"
                          name="gpName"
                          value={values?.gpName}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="GP Name"

                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-md-4">
                        <TextArea
                          id="note"
                          name="note"
                          value={values?.note}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Note"
                          rows="3"
                          className="mb-3"
                          customError={errors.note && touched.note}
                          errorMessage={errors.note}
                          erroClass={errors.note && touched.note}
                          requiredStar={true}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextArea
                          id="reasonForMedication"
                          name="reasonForMedication"
                          value={values?.reasonForMedication}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Reason For Medication"
                          rows="3"
                          className="mb-3"
                          customError={errors.reasonForMedication && touched.reasonForMedication}
                          errorMessage={errors.reasonForMedication}
                          erroClass={errors.reasonForMedication && touched.reasonForMedication}
                          requiredStar={true}
                        />
                      </div>
                      <div className="col-md-4">
                        <TextArea
                          id="prescriptionText"
                          name="prescriptionText"
                          value={values?.prescriptionText}
                          onChange={handleInputChangePrevFields}
                          onBlur={handleBlur}
                          labelText="Prescription"
                          rows="3"
                          className="mb-3"
                          customError={errors.prescriptionText && touched.prescriptionText}
                          errorMessage={errors.prescriptionText}
                          erroClass={errors.prescriptionText && touched.prescriptionText}
                          requiredStar={true}
                        />
                      </div>

                    </div>

                    <div className="row">
                      <div className="checkboxList medicineCheckList">
                        {emarMedicineCheckList.map((row, index) => (
                          <>
                            <Form.Check
                              name={row?.name}
                              id={row?.name}
                              aria-label={row?.values}
                              values={row?.values}
                              label={row?.label}
                              checked={row?.checked}
                              onChange={(e) => {
                                handleInputCheckListChange(row?.id, 'checked', e.target.checked)
                                setFieldValue(row?.name, e.target.checked)
                              }}
                            />
                          </>
                        ))}

                      </div>
                    </div>
                  </div>

                  <div className="formActionButtons">
                    <Link
                      to="/emar"
                      className="btn btn-sm btn-outline-primary"
                    >
                      Back
                    </Link>
                    {isSubmitButtonClicked ? (
                      <Button
                        className="btn-sm"
                        variant="primary"
                        disabled
                      >
                        Loading...
                      </Button>
                    ) : (
                      <Button className="btn-sm" variant="primary" type="submit">
                        Save
                      </Button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Layout >
      {isToastAddAppointment && <ToastContainer />}
    </>
  );
}

export default AddMedicationSchedule;