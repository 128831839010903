import React from "react";
import Layout from "../../../components/layouts/Layout";
import UnReadMessages from "../components/UnReadMessages";
import CarePlanCalendar from "../components/CarePlanCalendar";
import CarePlansModule from "../components/CarePlansModule";
import UserDetailWithStatus from "../../../components/user-detail/UserDetailWithStatus";
import LimitedGeneralCareNotes from "../common-options/general-note/LimitedGeneralCareNotes";

function CareNotes() {
  return (
    <Layout pageTitleText="Care Plans">
      <UserDetailWithStatus carePlans={true} />
      <div className="row">
        <div className="col-md-9">
          <CarePlansModule />
          <LimitedGeneralCareNotes />
        </div>
        <div className="col-md-3">
          <CarePlanCalendar />
          {/* <UnReadMessages /> */}
        </div>
      </div>
    </Layout>
  );
}


export default CareNotes