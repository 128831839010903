import React from "react";
import linkArrowImage from "../../assets/images/icons/linkArrow.svg";
import { Link } from "react-router-dom";

function ScheduleBlocks({
  editIconClass,
  blockIcon,
  heading,
  subHeading,
  paragraph,
  linkArrow,
}) {
  return (
    <>
      <div className="cards">
        {editIconClass ? <span className={editIconClass}>&nbsp;</span> : null}
        {linkArrow ? (
          <Link to={linkArrow}>
            <img src={linkArrowImage} alt={heading} />
          </Link>
        ) : null}

        <div className="clearfix">&nbsp;</div>
        <Link to={linkArrow}>
          <div className="cardContent">
            <span className="contentIcon">
              <img src={blockIcon} alt={subHeading} />
            </span>
            <h3>
              <small>{subHeading}</small>
              {heading}
              {paragraph ? (
                <small className="contentIcon">{paragraph}</small>
              ) : null}
            </h3>
          </div>
        </Link>
      </div>
    </>
  );
}

export default ScheduleBlocks;
