import React, { useState, useEffect, useRef } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addABC } from "../../../../../../schemas";
import { useFormik } from "formik";
import ReactDOMServer from "react-dom/server";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_URL } from "../../../../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link, useLocation } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";

function EditABC() {
  const location = useLocation();
  const carePlanDetail = location?.state
    ? location?.state?.editCarePlanDetail[0]
    : null;
  console.log("🚀 ~ EditCareNotes ~ carePlanDetail:", carePlanDetail);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(carePlanDetail?.addedby);
  const [errorMessage, setErrorMessage] = useState(false);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
  };

  const antecedentEventsRef = useRef(null);
  const behaviourRef = useRef(null);
  const consequenceEventsRef = useRef(null);
  useEffect(() => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = carePlanDetail?.noteDetails || "";

    antecedentEventsRef.current.innerText =
      tempDiv.querySelector("#antecedentEventsData")?.innerText || "";
    behaviourRef.current.innerText =
      tempDiv.querySelector("#behaviourData")?.innerText || "";
    consequenceEventsRef.current.innerText =
      tempDiv.querySelector("#consequenceEventsData")?.innerText || "";
  }, [carePlanDetail?.noteDetails]);

  const [suFormData, setSuFormData] = useState({
    addedby: carePlanDetail?.addedby?._id,
    behaviour: "",
    antecedentEvents: "",
    consequenceEvents: "",
    eventDateTime: carePlanDetail?.eventDateTime,
  });
  useEffect(() => {
    setValues((prevState) => ({
      ...prevState,
      behaviour: behaviourRef.current?.innerText || "",
      antecedentEvents: antecedentEventsRef.current?.innerText || "",
      consequenceEvents: consequenceEventsRef.current?.innerText || "",
    }));
  }, [carePlanDetail?.noteDetails]);

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addABC,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);

        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(
        toast.success,
        data?.message,
        "/care-plan/general-notes",
        10,
        10
      );
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      carePlanid: carePlanDetail?._id,
      userId: carePlanDetail?.userId,
      noteType: carePlanDetail?.noteType,
      title: values?.consequenceEvents,
      flag: carePlanDetail?.flag,
      noteDetails: carePlanDetail?.noteDetails,
      eventDateTime: moment(values.eventDateTime).valueOf(),

      addedby: selectedUserId,
      isHTML: true,
      noteDetails: noteDetailsString,
    };

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues);
    const response = await postRecords(UPDTE_CARE_PLAN_URL, updateValues);
    console.log("🚀 ~ response ~ response:", response);
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });

  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData({
      ...suFormData,
      [name]: value,
    });
    handleChange(e);
    handleBlur(e);
  };

  const [htmlData, setHtmlData] = useState("");
  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-12">
          <p>
            <strong>Antecedent Events: </strong>
            <span id="antecedentEventsData">{values?.antecedentEvents} </span>
          </p>
        </div>
        <div className="col-md-12">
          <p>
            <strong>Behaviour : </strong>
            <span id="behaviourData">{values?.behaviour}</span>
          </p>
        </div>
        <div className="col-md-12">
          <p>
            <strong>Consequence Events: </strong>
            <span id="consequenceEventsData">{values?.consequenceEvents}</span>
          </p>
        </div>
      </>
    );
  }, [values]);

  return (
    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Edit ABC note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={values?.addedby}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-4">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format(
                          "YYYY-MM-DDTHH:mm"
                        )}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={
                          touched?.eventDateTime && errors?.eventDateTime
                        }
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4"></div>
                    <div className="col-md-4">
                      <TextArea
                        // type="text"
                        id="antecedentEvents"
                        name="antecedentEvents"
                        value={values?.antecedentEvents}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Antecedent Events"
                        rows="3"
                        className="mb-0"
                        customError={
                          touched?.antecedentEvents && errors?.antecedentEvents
                        }
                        errorMessage={errors?.antecedentEvents}
                        erroClass={
                          touched?.antecedentEvents && errors?.antecedentEvents
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextArea
                        // type="text"
                        id="behaviour"
                        name="behaviour"
                        value={values?.behaviour}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Behaviour"
                        rows="3"
                        className="mb-0"
                        customError={touched?.behaviour && errors?.behaviour}
                        errorMessage={errors?.behaviour}
                        erroClass={
                          touched?.behaviour && errors?.behaviour
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-4">
                      <TextArea
                        id="consequenceEvents"
                        name="consequenceEvents"
                        value={values?.consequenceEvents}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Consequence Events"
                        rows="3"
                        className="mb-0"
                        customError={
                          touched?.consequenceEvents &&
                          errors?.consequenceEvents
                        }
                        errorMessage={errors?.consequenceEvents}
                        erroClass={
                          touched?.consequenceEvents &&
                          errors?.consequenceEvents
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>
              <div className="referenceDiv">
                <div ref={antecedentEventsRef}></div>
                <div ref={behaviourRef}></div>
                <div ref={consequenceEventsRef}></div>
              </div>
              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default EditABC;
