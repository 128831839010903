// RosterHelper.js
import moment from 'moment';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { UPDATE_TEMPLATE_SHIFTS, ADMIN_ID } from "../../../env";
import {
    postRecords,
    catchAsync,
    isSuccess,
    toastSuccessMessages,
    toastErrorMessages,
} from "../../../helper/helper";

// Drag and Drop Staff Member component functionality
export function HandleDragDropStaffMember(
    event,
    variableRosterData,
    variableStaffData,
    variableSetter,
    draggedLocationId,
    weekNumber,
    setIsRotaUserShift,
    fetchAllRecords) {

    const { active, over } = event;

    if (!over) return;

    const sourceId = active.id;
    const destinationId = over.id;

    if (sourceId === destinationId) return;

    const [destinationStaffId, destinationDayName] = destinationId.split('-');

    // Create a shallow copy of variableRosterData to modify
    const updatedRosterData = variableRosterData.map(location => ({
        ...location,
        shiftLocation: location.shiftLocation.map(shift => ({
            ...shift,
            weekArray: shift.weekArray.map(week => ({
                ...week,
                daysArray: [...week.daysArray], // Shallow copy of daysArray
            })),
        })),
    }));

    let sourceDay;

    // Find the source cell
    for (const location of updatedRosterData) {
        for (const shift of location.shiftLocation) {
            for (const week of shift.weekArray) {
                for (let day of week.daysArray) {
                    if (day._id === sourceId) {
                        sourceDay = day;
                        break;
                    }
                }
            }
        }
    }

    if (sourceDay) {
        const destinationStaff = variableStaffData?.find(staff => staff._id === destinationStaffId);

        if (destinationStaff) {

            // Update the source day's `dayName` and `staffUserId`
            sourceDay.dayName = destinationDayName;
            sourceDay.staffUserId = {
                _id: destinationStaff._id,
                staffMemberName: destinationStaff.staffMemberName,
            };

            const staffMemberId = destinationStaff?._id;
            const dayName = destinationDayName;
            const shiftId = sourceDay?._id;
            const serviceUserId = sourceDay?.serviceUserId?._id;

            // console.log("🚀 ~ handleDragDropStaffMember ~ sourceDay:", sourceDay);

            variableSetter(updatedRosterData);

            const suFormData = {
                tempId: variableRosterData[0]?._id,
                locationId: draggedLocationId,
                shiftId: shiftId,
                dayName: dayName,
                staffUserId: staffMemberId,
                serviceUserId: serviceUserId,
                active: sourceDay?.active,
                addedby: ADMIN_ID,
                isDelete: sourceDay?.isDelete,
                isOverNightStay: sourceDay?.isOverNightStay,
                isSleepOver: sourceDay?.isSleepOver,
                lastModifiedBy: ADMIN_ID,
                note: sourceDay?.note,
                serviceUserAssigned: sourceDay?.serviceUserAssigned,
                shiftEndTime: sourceDay?.shiftEndTime,
                shiftStartTime: sourceDay?.shiftStartTime,
                staffAssigned: sourceDay?.staffAssigned,
                weekNo: weekNumber,
                workHrs: sourceDay?.workHrs
            };
            // console.log("🚀 ~ handleDragDropStaffMember ~ suFormData:", suFormData)


            const processPostRecords = (method, data) => {
                // console.log(data);
                if (data?.status !== isSuccess) {
                    toastErrorMessages(toast.error, data?.message, "", 500, 500);
                    setIsRotaUserShift(true)
                    fetchAllRecords()
                } else if (
                    method === "postEditUserShiftFormData" &&
                    data?.status === isSuccess
                ) {
                    toastSuccessMessages(toast.success, data?.message, "", 500, 500);
                    setIsRotaUserShift(true)
                    fetchAllRecords()
                }
                setTimeout(() => {
                    setIsRotaUserShift(false);
                }, 2000);
            };

            const postEditUserShiftFormData = catchAsync(async (req, res) => {
                // console.log("🚀 ~ postEditUserShiftFormData ~ suFormData:", suFormData)
                const response = await postRecords(UPDATE_TEMPLATE_SHIFTS, suFormData);
                // console.log("🚀 ~ postEditUserShiftFormData ~ response:", response)
                processPostRecords("postEditUserShiftFormData", response);
            });

            if (variableRosterData[0]?.status !== "active" && variableRosterData[0]?.status !== "Inactive") {
                postEditUserShiftFormData();
            } else {
                setIsRotaUserShift(true)
                toastErrorMessages(toast.error, `Unable to update shift Rota Template is ${variableRosterData[0]?.status}`, "", 500, 500);
                fetchAllRecords()
            }


        }
    }
}

// Drag and Drop Service Users component functionality
export function HandleDragDropServiceUser(
    event,
    variableRosterData,
    variableServiceUserData,
    variableSetter,
    draggedLocationId,
    weekNumber,
    setIsRotaUserShift,
    fetchAllRecords) {
    const { active, over } = event;

    if (!over) return;

    const sourceId = active.id;
    const destinationId = over.id;

    if (sourceId === destinationId) return;

    const [destinationServiceUserId, destinationDayName] = destinationId.split('-');

    // Create a shallow copy of variableRosterData to modify
    const updatedRosterData = variableRosterData.map(location => ({
        ...location,
        shiftLocation: location.shiftLocation.map(shift => ({
            ...shift,
            weekArray: shift.weekArray.map(week => ({
                ...week,
                daysArray: [...week.daysArray], // Shallow copy of daysArray
            })),
        })),
    }));

    let sourceDay;

    // Find the source cell
    for (const location of updatedRosterData) {
        for (const shift of location.shiftLocation) {
            for (const week of shift.weekArray) {
                for (let day of week.daysArray) {
                    if (day._id === sourceId) {
                        sourceDay = day;
                        break;
                    }
                }
            }
        }
    }

    if (sourceDay) {
        const destinationServiceUser = variableServiceUserData?.find(serviceUser => serviceUser._id === destinationServiceUserId);

        if (destinationServiceUser) {

            // Update the source day's `dayName`, and `serviceUserId`
            sourceDay.dayName = destinationDayName;
            sourceDay.serviceUserId = {
                _id: destinationServiceUser._id,
                name: destinationServiceUser.name,
            };


            const serviceUserId = destinationServiceUser?._id;
            const dayName = destinationDayName;
            const shiftId = sourceDay?._id;
            const staffUserId = sourceDay?.staffUserId?._id;

            // console.log("🚀 ~ handleDragDropserviceUser ~ sourceDay:", sourceDay);

            variableSetter(updatedRosterData);

            const suFormData = {
                tempId: variableRosterData[0]?._id,
                locationId: draggedLocationId,
                shiftId: shiftId,
                dayName: dayName,
                staffUserId: staffUserId,
                serviceUserId: serviceUserId,
                active: sourceDay?.active,
                addedby: ADMIN_ID,
                isDelete: sourceDay?.isDelete,
                isOverNightStay: sourceDay?.isOverNightStay,
                isSleepOver: sourceDay?.isSleepOver,
                lastModifiedBy: ADMIN_ID,
                note: sourceDay?.note,
                serviceUserAssigned: sourceDay?.serviceUserAssigned,
                shiftEndTime: sourceDay?.shiftEndTime,
                shiftStartTime: sourceDay?.shiftStartTime,
                staffAssigned: sourceDay?.staffAssigned,
                weekNo: weekNumber,
                workHrs: sourceDay?.workHrs
            };
            // console.log("🚀 ~ handleDragDropStaffMember ~ suFormData:", suFormData)


            const processPostRecords = (method, data) => {
                // console.log(data);
                if (data?.status !== isSuccess) {
                    toastErrorMessages(toast.error, data?.message, "", 500, 500);
                    setIsRotaUserShift(true)
                    fetchAllRecords()
                } else if (
                    method === "postEditUserShiftFormData" &&
                    data?.status === isSuccess
                ) {
                    toastSuccessMessages(toast.success, data?.message, "", 500, 500);
                    setIsRotaUserShift(true)
                    fetchAllRecords()
                }
                setTimeout(() => {
                    setIsRotaUserShift(false);
                }, 2000);
            };

            const postEditUserShiftFormData = catchAsync(async (req, res) => {
                // console.log("🚀 ~ postEditUserShiftFormData ~ suFormData:", suFormData)
                const response = await postRecords(UPDATE_TEMPLATE_SHIFTS, suFormData);
                // console.log("🚀 ~ postEditUserShiftFormData ~ response:", response)
                processPostRecords("postEditUserShiftFormData", response);
            });

            if (variableRosterData[0]?.status !== "active" && variableRosterData[0]?.status !== "Inactive") {
                postEditUserShiftFormData();
            } else {
                setIsRotaUserShift(true)
                toastErrorMessages(toast.error, `Unable to update shift Rota Template is ${variableRosterData[0]?.status}`, "", 500, 500);
                fetchAllRecords()
            }
        }
    }
}
