import React, { useState, useEffect } from "react";
import { BASE_URL, GET_ALL_SERVICE_USERS_URL } from "../../env";
import { getRecords, isSuccess, catchAsync } from "../../helper/helper";
import { useNavigate } from "react-router-dom";
import ProgressComponent from "../../components/general/ProgressComponent";

function CurrentServiceUsers() {
  const [isLoading, setIsLoading] = useState(true);
  const [allRecords, setRecords] = useState([]);
  const [reviewListData, setReviewListData] = useState([]);
  console.log("🚀 ~ CurrentServiceUsers ~ reviewListData:", reviewListData)
  const [noRecordFound, setNoRecordFound] = useState(false);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    // offset: 0,
    // limit: 50,
    query: {
      critarion: { role: "serviceuser", active: true },
      // usersFields: "profile_picture_url",
      // addedby: "_id email first_name",
      // lastModifiedBy: "_id email first_name",
    },
  };
  const navigate = useNavigate();

  const processServiceUserData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data?.users;
      if (allData && allData.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        setRecords(allData);
        setReviewListData(allData);
      }
    }
  };

  const getAllServiceUserData = catchAsync(async () => {
    const response = await getRecords(GET_ALL_SERVICE_USERS_URL, apiQuery);
    processServiceUserData("get", response);
    console.log("🚀 ~ getAllServiceUserData ~ response:", response)
  });


  useEffect(() => {
    getAllServiceUserData();
  }, []);


  const handleRowClickServiceUser = (item) => {
    navigate("/service-user-profile", { state: { userDetails: [item] } });
  };
  return (
    <>
      {noRecordFound ? (
        <>
          <div className="noRecordFound">
            <p>No records found.</p>
          </div>
        </>
      ) : isLoading ? (
        <ProgressComponent />
      ) : (
        reviewListData.map((item, index) => (
          <>
            {item?.modelId?.currSU ? (
              <div className="cards">
                <div className="cardsUserDetail">
                  <div
                    className="usersNameInfo curSorPointer"
                    onClick={() => {
                      handleRowClickServiceUser(item);
                    }}
                  >
                    <div className="userImage">
                      <img
                        src={BASE_URL + item?.profile_picture_url}
                        alt={item?.modelId?.name}
                      />
                    </div>
                    <div className="userInfo">
                      <span className="nameBlock">
                        {item?.modelId?.name}{" "}
                        {item?.modelId?.suLastName}
                      </span>
                      <span className="infoBlock">
                        {item?.modelId?.suAge} Yrs | {item?.modelId?.suSex}
                      </span>
                    </div>
                  </div>

                  <span
                    onClick={() => {
                      handleRowClickServiceUser(item);
                    }}
                    className="link"
                  >
                    View Profile
                  </span>
                </div>
              </div>
            ) : null}
          </>
        ))
      )}


    </>
  );
}
export default CurrentServiceUsers;
