import React, { useEffect, useState, useRef } from "react";
import Layout from "../../../../components/layouts/Layout";
import UserDetailReviewVertical from "../../../../components/user-detail/UserDetailReviewVertical";
import { Link } from "react-router-dom";
import DailyData from "./DailyData";



function DailySummary() {

  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);



  return (
    <>
      <Layout pageTitleText="Daily Summary">
        <div className="profileDetailWrapper currentHealthIssuesWrapper">
          <div className="row">
            <div className="col-md-3">
              <UserDetailReviewVertical
                getUserRecord={userDetails}
                avatarActionButtons={false}
                viewNotes={false}
                profileDetail={false}
                verticalDetail={true}
              />
            </div>
            <div className="col-md-9">


              <div className="userDetail currentHealthIssues">
                <DailyData titleandButton={true} dailySummaryDate={true} />
              </div>

            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}

export default DailySummary