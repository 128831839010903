import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import RotaWeeksHeader from "../components/RotaWeeksHeader";
import StaffMembersWeeklyTabs from "./StaffMembersWeeklyTabs";
import ServiceUsersWeeklyTabs from "./ServiceUsersWeeklyTabs";


function Location({ shiftLocationId }) {


  const locationId = shiftLocationId
  const [weekNumber, setWeekNumber] = useState(1);
  const [staffMemberTab, setStaffMemberTab] = useState(true)
  const [serviceUserTab, setServiceUserTab] = useState(false)

  const preLoadComponent = (activeSetter, inactiveSetter) => {
    activeSetter(true);
    inactiveSetter(false);
  };


  return (
    <>
      {/* Rota Weeks Dropdown with Color Marks */}
      <RotaWeeksHeader setWeekNumber={setWeekNumber} />

      <div className="mainContent p-0">
        <div className="subTabsWrapper">
          <Tab.Container id="myTab" defaultActiveKey="staffMember">
            {/* {shiftLocationName} */}
            <div className="subTabsHeader radioTabsHeader">
              <div className="tabsBlocks">
                <Nav variant="tabs">
                  <Nav.Item>
                    <Nav.Link eventKey="staffMember" onClick={() => (preLoadComponent(setStaffMemberTab, setServiceUserTab))}>Staff Member</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="serviceUser" onClick={() => (preLoadComponent(setServiceUserTab, setStaffMemberTab))}>Service User</Nav.Link>
                  </Nav.Item>
                </Nav>
              </div>


            </div>

            <Tab.Content>
              <Tab.Pane eventKey="staffMember">
                <div className="rosterTabsContent">
                  {
                    staffMemberTab && (
                      <StaffMembersWeeklyTabs
                        shiftLocationId={locationId}
                        weekNumber={weekNumber}
                      />
                    )
                  }


                </div>
              </Tab.Pane>
              <Tab.Pane eventKey="serviceUser">
                <div className="rosterTabsContent">

                  {serviceUserTab && (
                    <ServiceUsersWeeklyTabs
                      shiftLocationId={locationId}
                      weekNumber={weekNumber} />
                  )}


                </div>
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
        </div>
      </div>

    </>
  );
}

export default Location;