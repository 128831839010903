import React, { useState, useEffect } from "react";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getRecords,
  catchAsync,
  toastErrorMessages,
  isSuccess,
} from "../../helper/helper";
import { BASE_URL, GET_ALL_SERVICE_USERS_URL } from "../../env";
import { CustomOption } from "../../helper/helperJsx";

const CustomSelectServiceUsers = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  label,
  defaultSelectedValue,
  isDisabled,
  requiredStar,
}) => {
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isToastSelectServiceUser, setIsToastSelectServiceUser] =
    useState(false);
  const [defaultValue, setDefaultValue] = useState(null);

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true, role: "serviceuser" },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processFetchRecords = (data) => {
    if (data?.status !== isSuccess) {
      setIsToastSelectServiceUser(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_SERVICE_USERS_URL, apiQuery);
    if (response?.status === isSuccess) {
      const filteredServiceUsers = response?.data?.users;
      const validServiceUsers = filteredServiceUsers?.filter(
        (item) => item?._id !== "" && item?.modelId?.currSU === true
      );
      const getUsers = validServiceUsers?.map((item) => ({
        value: item?._id,
        label:
          item?.modelId?.suFirstMiddleName + " " + item?.modelId?.suLastName,
        image: item?.profile_picture_url,
      }));
      if (defaultSelectedValue && getUsers?.length > 0) {
        const foundUser = getUsers?.find(
          (user) => user?.value === defaultSelectedValue
        );
        if (foundUser) {
          setDefaultValue(foundUser);
          onSelect(foundUser?.value);
        }
      }
      setUserData(getUsers);
      setIsLoading(false);
    } else {
      processFetchRecords(response);
    }
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    fetchAllRecords();
  }, [defaultSelectedValue]);

  const handleInputChange = (e) => {
    setIsLoading(false);
  };

  return (

    <div className={`customSelectBox ${isDisabled ? "disabled" : ""
      } ${errorClass}`}>
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          <Select
            className="basic-single cursor-pointer"
            classNamePrefix="select"
            value={defaultValue}
            onInputChange={handleInputChange}
            options={userData}
            isDisabled={isDisabled}
            isSearchable
            // isClearable={true}
            onChange={(selectedOption) => {
              setDefaultValue(selectedOption);
              onSelect(selectedOption?.value);
            }}
            getOptionLabel={(selectedOption) => selectedOption?.label}
            getOptionValue={(selectedOption) => selectedOption?.value}
            components={{ Option: CustomOption }}
          />
          {label && (
            <label className="floatingLabel">
              {label}
              {requiredStar ? <span className="text-danger">*</span> : null}
            </label>
          )}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
      {isToastSelectServiceUser && <ToastContainer />}
    </div>
  );
};

export default CustomSelectServiceUsers;
