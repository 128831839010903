import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../../components/general/ProgressComponent";
import editBlock from "../../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../../assets/images/icons/eventDel.svg";
import password_eye_icon_circle from "../../../../assets/images/icons/password_eye_icon_circle.svg";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import Dropdown from 'react-bootstrap/Dropdown';
import ReactHtmlParser from 'html-react-parser';
import humanBody from "../../../../assets/images/humanBody.svg";
import circleHealthIssue from "../../../../assets/images/icons/circleHealthIssue.svg";
import ProtectedComponent from '../../../../permissions/ProtectedComponent';
import { permissions } from '../../../../permissions/roles';
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  GET_CARE_PLAN_14_URL,
  DELETE_CARE_PLAN_URL,
} from "../../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
  ExportButton,
  pdfStyles
} from "../../../../helper/helper";
import { Link } from "react-router-dom";
// BlobProvider, Canvas, Circle, ClipPath, Defs, Document, Ellipse, Font, G, Image, Line, LinearGradient, Link, Note, PDFDownloadLink, PDFViewer, Page, Path, Polygon, Polyline, RadialGradient, Rect, Stop, StyleSheet, Svg, Text, TextInstance, Tspan, View, createRenderer, default, pdf, render, renderToBuffer, renderToFile, renderToStream, renderToString, usePDF, version
import { Document, Page, Text, View, StyleSheet, PDFDownloadLink, Line } from '@react-pdf/renderer';
import { PDFViewer } from '@react-pdf/renderer';
function LimitedGeneralCareNotes() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [userData, setUserData] = useState([]);
  const today = moment();
  const apiQuery = {
    userId: userDetails?._id,
    endDate: today.subtract(14, 'days').valueOf(),
  };
  const [search, setSearch] = useState("");
  const [searchNoteDetail, setSearchNoteDetail] = useState("");
  const [filterSearch, setFilterSearch] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [careNoteIdData, setCareNoteIdData] = useState({
    id: null,
  });
  const [carePlanDetail, setCarePlansDetail] = useState();
  const [isCarePlan, setIsCarePlan] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const navigate = useNavigate();

  // View Care Plan Detail Modal Popup
  const [showViewCarePlan, setShowViewCarePlan] = useState(false);
  const subModalViewCarePlans = (details) => {
    setCarePlansDetail(details);
    setShowViewCarePlan(!showViewCarePlan);
  };

  // Modal Popup
  const [showDeleteCarePlan, setShowDeleteCarePlan] = useState(false);
  const subModalDeleteCareNotes = (careNoteDelId) => {
    setCareNoteIdData({ id: careNoteDelId });
    setShowDeleteCarePlan(!showDeleteCarePlan);
    fetchAllRecords();
  };

  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const careNoteData = data?.data?.carePlans?.filter((item) => item.active === true);
      setUserData(careNoteData);
      setFilterSearch(careNoteData);
      setIsLoading(false);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsCarePlan(false);
      }, 2000);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CARE_PLAN_14_URL, apiQuery);
    console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Table Columns
  const columns = [
    {
      name: "Event Date / Time",
      selector: (row) => {
        return <p>{moment(row.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</p>;
      },
      width: "20%",
      wrap: true
    },
    {
      name: "Entered By",
      selector: (row) => {
        return (
          <p>
            <strong>{row?.addedby?.name}</strong>
            <br />
            {moment(row.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}
          </p>
        );
      },
      width: "20%",
      wrap: true
    },
    {
      name: "Category",
      selector: (row) => {
        return (
          <p>
            {row?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}
          </p>
        );
      },
      width: "15%",
      wrap: true
    },
    {
      name: "Note Details",
      selector: (row) => {
        return (
          <>
            <p>
              {/* {row?.noteDetails ? row?.noteDetails?.slice(0, 90) + "..." : ""} */}
              {row?.noteDetails ? (
                row.noteDetails.replace(/<\/p>/g, '\n\n').replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '')?.slice(0, 90) + "...") : (
                row.noteDetails.replace(/<\/p>/g, '\n\n').replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '')
              )}
              {/* {row?.title.length > 130 ? row?.title?.slice(0, 130) + "..." : row?.title} */}
            </p>
          </>
        );
      },
      width: "30%",
      wrap: true
    },
    {
      name: "",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">
            <span className="edit" onClick={() => {
              subModalViewCarePlans(row);
            }}>
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span>
            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
              <span
                className="edit"
                onClick={() => {
                  editGeneralNotes(`/care-plan/edit-${row?.noteType.replace(/([a-z])([A-Z])/g, '$1-$2').toLowerCase()}-notes`, row);
                }}
              >
                <img src={editBlock} alt="Edit" title="Edit" />
              </span>
            </ProtectedComponent>
            {/* <span
              className="edit"
              onClick={() => {
                subModalEditCarePlan(row);
              }}
            >
              <img src={editBlock} alt="Edit" title="Edit" />
            </span> */}
            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
              <span
                className="delete"
                onClick={() => {
                  subModalDeleteCareNotes(row?._id);
                }}
              >
                <img src={eventDel} alt="Delete" title="Delete" />
              </span>
            </ProtectedComponent>
          </div>
        );
      },
      center: true,
      width: "15%",
      wrap: true,
    },
  ];


  // Delete Care Notes
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (careNoteIdData !== "") {
      const response = await deleteRecords(
        DELETE_CARE_PLAN_URL,
        careNoteIdData
      );
      console.log("🚀 ~ deleteRecord ~ response:", response)
      setIsCarePlan(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteCarePlan(!showDeleteCarePlan);
    } else {
      setShowDeleteCarePlan(!showDeleteCarePlan);
      setIsSubmitButtonClicked(false);
      setIsCarePlan(true);
      toastErrorMessages(toast.error, "Unable to remove", 1000);
    }
  });
  useEffect(() => {
    setTimeout(() => {
      setIsCarePlan(false);
    }, 2000);
  }, [isCarePlan])


  useEffect(() => {
    const result = userData.filter((user) => {
      return user.noteDetails.toLowerCase().match(searchNoteDetail.toLowerCase());
    });
    setFilterSearch(result);
  }, [searchNoteDetail, userData]);

  const editGeneralNotes = (editUrl, item) => {
    navigate(editUrl, { state: { editCarePlanDetail: [item] } });
  };

  // Exporting to PDF
  // Create styles
  const styles = StyleSheet.create({
    pages: {
      padding: "10px",
    },
    sectionTitle: {
      fontSize: "16px",
      fontWeight: "black",
      marginBottom: "5px",
      textAlign: "center",
      padding: "10px",
      backgroundColor: "#f0f0f0"
    },
    sectionSubTitle: {
      fontSize: "14px",
      fontWeight: "black",
      marginBottom: "5px",
      textAlign: "center",
      padding: "10px",
      backgroundColor: "#f0f0f0"
    },
    section: {
      margin: 10,
      padding: 10,
      flexGrow: 1,
    },
    tableHead: {
      flexDirection: "row",
      justifyContent: "space-between",
      backgroundColor: "#f0f0f0",
      padding: "10px 10px",
      marginBottom: "5px",
      fontSize: "10px",
      fontWeight: "bold",
      wordWrap: "break-word",
    },
    tabBody: {
      flexDirection: "row",
      justifyContent: "space-between",
      padding: "10px 10px",
      borderBottom: "1px solid #ccc",
      fontSize: "10px",
      wordWrap: "break-word",
    },
    wordwrap: {
      flexBasis: "50%",
      wordWrap: "break-word",
    },
  });
  // Create Document Component
  const MyDocument = () => (
    <Document>
      <Page size="A4" orientation="landscape" style={styles.pages}>
        <View>
          <Text style={styles.sectionTitle}>All Care Plans Notes</Text>
          <Text style={styles.sectionSubTitle}>Service User: {userDetails?.modelId?.suFirstMiddleName} {userDetails?.modelId?.suLastName}</Text>
          <View style={styles.tableHead}>
            <Text style={{ width: "18%" }}>Event Date / Time</Text>
            <Text style={{ width: "18%" }}>Entered By</Text>
            <Text style={{ width: "15%" }}>Category</Text>
            <Text style={{ width: "49%" }}>Note Details</Text>
          </View>
          {filterSearch.map((row, index) => (
            <View key={index} style={styles.tabBody}>
              <Text style={{ width: "18%" }}>{moment(row.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</Text>
              <Text style={{ width: "19%" }}>
                {row.addedby.name}
                {"\n"}
                {moment(row.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}
              </Text>
              <Text style={{ width: "16%" }}>{row?.noteType.replace(/([a-z])([A-Z])/g, '$1 $2')}</Text>
              <Text style={[styles.wordwrap, { width: "47%" }]}>{row.noteDetails.replace(/<\/p>/g, '\n\n').replace(/<br\s*\/?>/gi, '\n').replace(/<[^>]+>/g, '')}</Text>
            </View>
          ))}
        </View>
      </Page>
    </Document>
  );
  return (
    <>
      {/* <PDFViewer style={{ width: "100%", height: "500px" }}>
        <MyDocument />
      </PDFViewer> */}

      <div className="careNotes">
        <header>
          <h3>Care Plan Summary</h3>
          <div className="personalAllowance">
            <p>
              <Link className="btn-sm" to="/care-plan/general-notes">
                View All Notes
              </Link>
            </p>
          </div>

        </header>

        <div className="dataTableWrapper exportDataTable limitedCarePlansNotes">
          <DataTable
            className="maxWidthTable minWidthTable"
            columns={columns}
            data={filterSearch}
            pagination
            paginationRowsPerPageOptions={[10, 25, 50, 100]}
            paginationPerPage={10}
            fixedHeader
            fixedHeaderScrollHeight="375px"
            highlightOnHover
            actions={
              <>
                <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                  <Link className="btn btn-sm btn-primary mt-2" to="/common-options">
                    Add Care Note
                  </Link>
                </ProtectedComponent>

                {
                  filterSearch.length > 0 && (
                    <Dropdown>
                      <Dropdown.Toggle variant="outline-success" size="sm" id="dropdown-basic">
                        Export Data
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="exportDataList">
                        <>
                          <span className="">
                            <PDFDownloadLink document={<MyDocument columns={columns} data={filterSearch} />} fileName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - Summary of Care Notes"}>
                              {({ loading }) =>
                                loading ? '...' : 'Export as PDF'
                              }
                            </PDFDownloadLink>
                          </span>
                          <span className="">
                            {/* <ExportButton data={filterSearch} patientName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - Care Notes Summary" } /> */}

                            <ExportButton data={filterSearch} patientName={userDetails?.modelId?.suFirstMiddleName + userDetails?.modelId?.suLastName + " - " + " Care Notes Summary"} removeMoreColumns={["active", "title", "flag", "isHTML", "updatedAt"]} />
                          </span>
                        </>

                      </Dropdown.Menu>
                    </Dropdown>
                  )
                }
              </>
            }
            responsive={true}
            subHeader={true}
            subHeaderComponent={
              <div className="searchContainer">
                <div className="searchTextBox">
                  <input
                    type="text"
                    placeholder="Search by note detail"
                    value={searchNoteDetail}
                    onChange={(e) => setSearchNoteDetail(e.target.value)}
                  />
                </div>
              </div>
            }
            subHeaderAlign="left"
            subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
            progressPending={isLoading}
            progressComponent={<ProgressComponent />}
          />
        </div>
      </div>

      {/* View Care Notes */}
      <Modal
        show={showViewCarePlan}
        onHide={subModalViewCarePlans}
        className="ftcModalPopup medicineInventoryStock"
        size={carePlanDetail?.isHTML ? "lg" : "md"}
      >
        <Modal.Header closeButton>
          <Modal.Title>View Care Note</Modal.Title>
        </Modal.Header>
        <Modal.Body className="careNotesPopup">
          <p>
            <strong>{carePlanDetail?.addedby?.name}</strong>
            <small>{moment(carePlanDetail?.eventDateTime).format("DD/MM/YYYY / hh:mm:ss A")}</small>
          </p>
          {
            carePlanDetail?.title !== '' && (
              <p><strong>Title:</strong> {carePlanDetail?.title}</p>
            )
          }
          {
            carePlanDetail?.isHTML ? (
              ReactHtmlParser(String(carePlanDetail?.noteDetails))
            ) : (
              <>

                {
                  carePlanDetail?.noteType != "MoodRating" && (
                    <>
                      <p>{carePlanDetail?.noteDetails}</p>
                      {carePlanDetail?.flag ? (
                        <p className="text-success">Flagged for handover</p>
                      ) : (
                        <p className="text-danger">Not Flagged for handover</p>
                      )
                      }
                    </>
                  )
                }
              </>
            )
          }
          {
            carePlanDetail?.healthIssueId ? (
              <>
                <div className="col-md-12">
                  <p>
                    <strong>{carePlanDetail?.noteType === "PhysicalIntervention" ? "Physical intervention used on body part" : "Effected Body Part"} </strong>
                    {carePlanDetail?.healthIssueId?.category?.name}
                  </p>
                </div>
                <div className="profileDetailWrapper currentHealthIssuesWrapper">
                  <div className="userDetail currentHealthIssues carePlanCurrentHealthIssues">
                    <div className="partsOfBodyContent p-0">
                      <div className="humanBody">
                        <div className={`targetPoint ${carePlanDetail?.healthIssueId?.category?.enum}`}>

                          <OverlayTrigger
                            overlay={<Tooltip className="careNoteToolTip">{carePlanDetail?.healthIssueId?.category?.name}</Tooltip>}
                            placement="top"
                            delayShow={300}
                            delayHide={150}

                          >
                            <img
                              className={`target`}
                              src={circleHealthIssue}
                              alt=""
                            />
                          </OverlayTrigger>

                        </div>
                        <img src={humanBody} alt="Human Body" />
                      </div>
                    </div>
                  </div>
                </div>
              </>
            ) : ""
          }
        </Modal.Body>
      </Modal>



      {/* Delete Care Note */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteCarePlan}
          onHide={subModalDeleteCareNotes}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this record?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}
            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteCareNotes}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isCarePlan && <ToastContainer />}
    </>
  );
}

export default LimitedGeneralCareNotes;