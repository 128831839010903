import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getRecords,
  catchAsync,
  isSuccess,
  // apiQuery,
} from "../../../helper/helper";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../../env";

const TeamLead = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  label,
  filterBy,
  defaultSelectedValue,
}) => {
  const [userData, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedValue, setSelectedValue] = useState(defaultSelectedValue);

  const selectClass = selectedValue
    ? "assignBox selectedClass"
    : "assignBox selectValueClass";

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      addedby: "_id email first_name",
      lastModifiedBy: "_id email first_name",
    },
  };

  const processStaffRecordsData = (data) => {
    if (data?.status === isSuccess) {
      // console.log("teamlead", data?.data);
      const filteredStaffMembers = data?.data?.staffMembers?.filter(
        (item) => item?.staffDesignation === filterBy
      );

      const getUsers = filteredStaffMembers?.map((item) => ({
        value: item?._id,
        label: item?.staffMemberName,
        image: item?.profile_picture_url,
      }));

      setRecords(getUsers);
      setIsLoading(false);
      // console.log("getUsers:", getUsers);
    }
  };

  const getUserData = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    processStaffRecordsData(response);
  });

  useEffect(() => {
    getUserData();
  }, []);

  const handleInputChange = (e) => {
    setIsLoading(false);
  };

  return (
    <div className={`customSelectBox ${errorClass}`}>
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          {/* <small>{selectedValue}</small> */}
          <Select
            options={userData}
            isSearchable
            placeholder="Assign"
            onChange={(selectedOption) => {
              setSelectedValue(selectedOption.value);
              onSelect(selectedOption.value);
            }}
            value={userData.find((option) => option.value === selectedValue)}
            getOptionLabel={(option) => option.label}
            getOptionValue={(option) => option.value}
            className={selectClass}
          />
          {label && <label className="floatingLabel">{label}</label>}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default TeamLead;
