import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addSafeguarding } from "../../../../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_CARE_PLAN_URL } from "../../../../../../env";
import ReactDOMServer from 'react-dom/server';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link } from "react-router-dom";
import { Form } from "react-bootstrap";
import Layout from "../../../../../../components/layouts/Layout";
import AddHealthIssues from "../../../../../health-issue/AddHealthIssues";
import HealthCategories from "../../../../../health-issue/HealthCategories";
function AddSafeguarding() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  // const handleUserSelect = (userId) => {
  //   setSelectedUserId(userId);
  //   setFieldValue("addedby", userId);
  // };

  const [suFormData, setSuFormData] = useState({
    addedby: selectedUserId,
    concernsAboutServiceUser: "",
    voiceOfTheServiceUser: "",
    areThereAnyImmediateRisks:  "",
    whatActionDoYouFeel:  "",
    comments:  "",
    yourName:  "",
    yourNameDslDdsl:  "",
    anyWitnesses:  "",
    actionTaken:  "",
    dateTimeReporting: moment().valueOf(),
    category: selectedCategoryId,
    subcategory: selectedCategoryId,
    eventDateTime: moment().valueOf(),
  });


  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldTouched,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addSafeguarding,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      userId: userDetails?._id,
      addedby: selectedUserId,
      noteType: "Safeguarding",
      isHTML: true,
      title: values?.areThereAnyImmediateRisks,
      flag: false,
      noteDetails: noteDetailsString,
      category: values?.category,
      // subcategory: selectedCategoryId,
      healthNote: values?.concernsAboutServiceUser,
      complaint: values?.areThereAnyImmediateRisks,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }

    // console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    console.log("🚀 ~ postAddCarePlansFormData ~ values:", values)
    const response = await postRecords(POST_CARE_PLAN_URL, updateValues);
    console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });


  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });
    handleChange(e);
    handleBlur(e);
  };

  const [htmlData, setHtmlData] = useState("")
  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-12">
          <p><strong>Concerns about the service user: </strong><span id="concernsAboutServiceUserData">{values?.concernsAboutServiceUser}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Voice of the service user: </strong><span id="voiceOfTheServiceUserData">{values?.voiceOfTheServiceUser} </span></p>
        </div>

        <div className="col-md-12">
          <p><strong>Are there any immediate risks: </strong><span id="areThereAnyImmediateRisksData">{values?.areThereAnyImmediateRisks}</span></p>
        </div>

        <div className="col-md-12">
          <p><strong>What action do you feel should be taken?: </strong><span id="whatActionDoYouFeelData">{values?.whatActionDoYouFeel}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Comments: </strong><span id="commentsData">{values?.comments}</span></p>
        </div>
        <div className="col-md-4">
          <p><strong>Your Name: </strong><span id="yourNameData">{values?.yourName}</span></p>
        </div>
        <div className="col-md-4">
          <p><strong>Any witnesses: </strong><span id="anyWitnessesData">{values?.anyWitnesses}</span></p>
        </div>
        <div className="col-md-4">
          <p><strong>Date and time of reporting</strong>
            <span id="dateTimeReportingData">{moment(values?.dateTimeReporting).format("DD/MM/YYYY / hh:mm:ss A")}</span></p>
        </div>
        <div className="col-md-6">
          <p>To be completed by DSL/DDSL<br /><strong>Your Name: </strong><span id="yourNameDslDdslData">{values?.yourNameDslDdsl}</span></p>
        </div>
        <div className="col-md-6">
          <p><br /><strong>Action taken: </strong><span id="actionTakenData">{values?.actionTaken}</span></p>
        </div>
      </>
    );

  }, [values]);


  const handleUserSelect = async (userId) => {
    setSelectedUserId(userId)
    setFieldValue("addedby", userId);
  }
  const handleBodyPartSelect = async (catId) => {
    setSelectedCategoryId(catId);

    setSuFormData((prevFormData) => ({
      ...prevFormData,
      category: catId,
    }));
  };


  const handleSubCatValidationStatus = (status) => {
    // console.log("Sub Cat Validation Status (Parent):", status);
    setParentSubCatValidationStatus(status);
  };

  useEffect(() => {
    // console.log("Parent Sub Cat Validation Status 01:", parentSubCatValidationStatus);
  }, [parentSubCatValidationStatus]);

  useEffect(() => {
    setFieldValue("category", selectedCategoryId);
    if (!parentSubCatValidationStatus) {
      setFieldValue("subcategory", "no-child");
    }
    else {
      setFieldValue("subcategory", "");
    }
  }, [selectedCategoryId, parentSubCatValidationStatus]);
  // console.log("🚀 ~ file: AddHealthIssues.jsx:112 ~ useEffect ~ selectedCategoryId:", selectedCategoryId)

  const [receivedSubCategory, setReceivedSubCategory] = useState(null);
  // console.log("🚀 ~asfsdfdsfsdfry:", receivedSubCategory)

  const handleSubCategoryChange = (subCategoryValue) => {
    setReceivedSubCategory(subCategoryValue);
    setFieldValue("category", subCategoryValue?.value);
    setFieldValue("subcategory", subCategoryValue?.value);
    setFieldTouched("subcategory", false); // Mark subcategory as touched
    // Other handling logic if needed
  };


  return (
    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Add Safeguarding note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={selectedUserId}
                          filterBy=""
                          label="Added By"
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date and time of disclosure/findings"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="concernsAboutServiceUser"
                        name="concernsAboutServiceUser"
                        value={values?.concernsAboutServiceUser}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Concerns about the service user"
                        rows="3"
                        className="mb-0"
                        customError={touched?.concernsAboutServiceUser && errors?.concernsAboutServiceUser}
                        errorMessage={errors?.concernsAboutServiceUser}
                        erroClass={
                          touched?.concernsAboutServiceUser && errors?.concernsAboutServiceUser
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="voiceOfTheServiceUser"
                        name="voiceOfTheServiceUser"
                        value={values?.voiceOfTheServiceUser}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Voice of the service user"
                        rows="3"
                        className="mb-0"
                        customError={touched?.voiceOfTheServiceUser && errors?.voiceOfTheServiceUser}
                        errorMessage={errors?.voiceOfTheServiceUser}
                        erroClass={
                          touched?.voiceOfTheServiceUser && errors?.voiceOfTheServiceUser
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="areThereAnyImmediateRisks"
                        name="areThereAnyImmediateRisks"
                        value={values?.areThereAnyImmediateRisks}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Are there any immediate risks"
                        rows="3"
                        className="mb-0"
                        customError={touched?.areThereAnyImmediateRisks && errors?.areThereAnyImmediateRisks}
                        errorMessage={errors?.areThereAnyImmediateRisks}
                        erroClass={
                          touched?.areThereAnyImmediateRisks && errors?.areThereAnyImmediateRisks
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                    <div className="col-md-6">

                      <TextArea
                        id="whatActionDoYouFeel"
                        name="whatActionDoYouFeel"
                        value={values?.whatActionDoYouFeel}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="What action do you feel should be taken?"
                        rows="3"
                        className="mb-0"
                        customError={touched?.whatActionDoYouFeel && errors?.whatActionDoYouFeel}
                        errorMessage={errors?.whatActionDoYouFeel}
                        erroClass={
                          touched?.whatActionDoYouFeel && errors?.whatActionDoYouFeel
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">

                      <TextArea
                        id="comments"
                        name="comments"
                        value={values?.comments}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Comments"
                        rows="3"
                        className="mb-0"
                        customError={touched?.comments && errors?.comments}
                        errorMessage={errors?.comments}
                        erroClass={
                          touched?.comments && errors?.comments
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">
                      <HealthCategories
                        name="category"
                        onSelect={handleBodyPartSelect}
                        onSelectSubCat={handleSubCategoryChange}
                        selectedSubCategory={receivedSubCategory}
                        selectedCategoryId={selectedCategoryId}
                        value={values?.category}
                        filterBy={""}
                        label="Select Category"
                        sublabel="Select Subcategory"
                        subCatValidationStatus={parentSubCatValidationStatus}
                        setSubCatValidationStatus={handleSubCatValidationStatus}
                        erroClassCategory={touched.category && errors.category ? "validationError" : ""}
                        customErrorCategory={touched.category && errors.category}
                        errorMessageCategory={touched.category && errors.category ? errors.category : ""}
                        customErrorSubcategory={touched.subcategory && errors.subcategory}
                        errorMessageSubcategory={touched.subcategory && errors.subcategory ? errors.subcategory : ""}
                        erroClassSubcategory={touched.subcategory && errors.subcategory ? "validationError" : ""}

                      />
                    </div>

                    <div className="col-md-4">
                      <Input
                        type="text"
                        id="yourName"
                        name="yourName"
                        value={values?.yourName}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Your Name"
                        customError={touched?.yourName && errors?.yourName}
                        errorMessage={errors?.yourName}
                        erroClass={
                          touched?.yourName && errors?.yourName
                            ? "validationError"
                            : ""
                        }
                      />

                    </div>

                    <div className="col-md-4">
                      <Input
                        type="text"
                        id="anyWitnesses"
                        name="anyWitnesses"
                        value={values?.anyWitnesses}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Any witnesses"
                        customError={touched?.anyWitnesses && errors?.anyWitnesses}
                        errorMessage={errors?.anyWitnesses}
                        erroClass={
                          touched?.anyWitnesses && errors?.anyWitnesses
                            ? "validationError"
                            : ""
                        }
                      />

                    </div>

                    <div className="col-md-4">
                      <Input
                        type="datetime-local"
                        id="dateTimeReporting"
                        name="dateTimeReporting"
                        value={moment(values?.dateTimeReporting).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date and time of reporting"
                        customError={touched?.dateTimeReporting && errors?.dateTimeReporting}
                        errorMessage={errors?.dateTimeReporting}
                        erroClass={
                          touched?.dateTimeReporting && errors?.dateTimeReporting
                            ? "validationError"
                            : ""
                        }
                      />
                    </div>

                    <div className="col-md-12">
                      <p>To be completed by DSL/DDSL</p>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="text"
                        id="yourNameDslDdsl"
                        name="yourNameDslDdsl"
                        value={values?.yourNameDslDdsl}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Your Name"
                        customError={touched?.yourNameDslDdsl && errors?.yourNameDslDdsl}
                        errorMessage={errors?.yourNameDslDdsl}
                        erroClass={
                          touched?.yourNameDslDdsl && errors?.yourNameDslDdsl
                            ? "validationError"
                            : ""
                        }
                      />

                    </div>

                    <div className="col-md-6">
                      <Input
                        type="text"
                        id="actionTaken"
                        name="actionTaken"
                        value={values?.actionTaken}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Action taken"
                        customError={touched?.actionTaken && errors?.actionTaken}
                        errorMessage={errors?.actionTaken}
                        erroClass={
                          touched?.actionTaken && errors?.actionTaken
                            ? "validationError"
                            : ""
                        }
                      />

                    </div>

                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      // onClick={subModalAddCarePlans}
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddCarePlans && <ToastContainer />}
    </>
  );
}


export default AddSafeguarding