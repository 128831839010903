import React from 'react'
import Authentication from '../components/layouts/Authentication'
import appointmentCancel from "../assets/images/icons/appointmentCancel.svg";
import { Link } from 'react-router-dom';

function PermissionError() {
  return (
    <Authentication>
      <div class="permissionDenied text-center">
        <img src={appointmentCancel} alt="Access Denied" />
        <p>You don't have permission to access this web page.<br />Please contact your administrator.</p>
        <Link to="/staff-overview" className="btn btn-sm btn-outline-danger">
          Go to Dashboard
        </Link>
      </div>
    </Authentication>
  )
}

export default PermissionError