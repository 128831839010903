import React, { useState, useEffect } from "react";
import { UPDATE_HEALTHISSUES_URL } from "../../env";
import {
  updateRecords,
  isSuccess,
  catchAsync,
  formateDate,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import editBlock from "../../assets/images/icons/editBlock.svg";
import moment from "moment";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';

function HealthIssuePopover({
  issueItemDetail,
  // healthNote,
  // healthNoteTitle,
  // healthComplaint,
  // lastUpdate,
  currentIssueStatus,
  issueId,
  handleToastMessage,
  handleSuccessSubmission,
  // userId,
  handleShowHide,
}) {
  // console.log("🚀 ~ file: HealthIssuePopover.jsx:26 ~ issueItemDetail:", issueItemDetail)

  // Constant
  const [show, setShow] = useState(false);
  const [issueStatus, setIssueStatus] = useState();
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [editHealthIssueData, setEditHealthIssueData] = useState();
  // const [currentIssueStatus, setCurrentIssueStatus] = useState(issueItemDetail?.status)

  // Functions

  // getting the values and class for selectbox (status)
  useEffect(() => {
    setIssueStatus(currentIssueStatus);
  }, [currentIssueStatus]);

  // Updating the status of the health issue

  const updateEnquiryRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      handleToastMessage(false, data.message);
      setIsSubmitButtonClicked(false);
    }
    if (method === "updateHealthIssueStatus" && data?.status === isSuccess) {
      setIsSubmitButtonClicked(false);
      handleToastMessage(true, data.message);
      handleSuccessSubmission();
    }
  };

  const updateHealthIssueStatus = catchAsync(async (req, res) => {
    const response = await updateRecords(UPDATE_HEALTHISSUES_URL, req);
    updateEnquiryRecords("updateHealthIssueStatus", response);
    // console.log("🚀 ~ file: HealthIssuePopover.jsx:50 ~ updateEnquiryRecords ~ data:", response)
  });

  // Issue Status on Change event
  const issueStatusOnChange = (e) => {
    const value = e.target.value;
    const parsedValue = value === "true";
    setIssueStatus(parsedValue);
    const apiQuery = {
      issueId: issueItemDetail?._id,
      category: issueItemDetail?.category?._id,
      status: parsedValue,
    };
    console.log(
      "🚀 ~ file: HealthIssuePopover.jsx:83 ~ issueStatusOnChange ~ apiQuery:",
      apiQuery
    );
    updateHealthIssueStatus(apiQuery);
    setIsSubmitButtonClicked(true);
  };

  // Edit Modal Popup
  useEffect(() => {
    setEditHealthIssueData({
      userId: issueItemDetail?.userId,
      issueId: issueItemDetail?._id,
      complaint: issueItemDetail?.complaint,
      healthNote: issueItemDetail?.healthNote,
      category: issueItemDetail?.category?.name,
      categoryId: issueItemDetail?.category?._id,
    });
  }, []);
  const handleClick = () => {
    handleShowHide(editHealthIssueData); // Pass issueId when invoking handleShowHide
  };

  return (
    <>
      <header>
        <div className="headerRow">
          <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
            <span className="edit" onClick={handleClick}>
              <img src={editBlock} alt="Edit" title="Edit" />
            </span>
          </ProtectedComponent>
          <select
            name=""
            id=""
            className={`statusButton ${issueStatus === true
              ? "btn-danger"
              : issueStatus === false
                ? "btn-success"
                : ""
              }`}
            onChange={issueStatusOnChange}
            value={issueStatus}
            disabled={isSubmitButtonClicked ? true : false}
          >
            <option value={true}>Active</option>
            <option value={false}>Resolved</option>
          </select>
        </div>
        <p>
          <strong>Health Note - {issueItemDetail?.category?.name}</strong>
        </p>
        <p>{issueItemDetail?.healthNote}</p>
      </header>
      <div className="healthIssueContent">
        <p>
          <strong>Complaint:</strong> {issueItemDetail?.complaint}
        </p>
        <p>
          <strong>Last Update:</strong>{" "}
          <small>{moment(issueItemDetail?.updatedAt).format("DD/MM/YYYY")}</small>
        </p>
      </div>
    </>
  );
}

export default HealthIssuePopover;
