// Draggable.jsx
import React from 'react';
import { useDraggable } from '@dnd-kit/core';
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import editBlock from "../../../assets/images/icons/editBlock.svg";
import logsIcon from "../../../assets/images/icons/logsIcon.svg";
export function Draggable(props) {
  const { attributes, listeners, setNodeRef, transform } = useDraggable({
    id: props.id,
  });

  const style = {
    transform: transform
      ? `translate3d(${transform.x}px, ${transform.y}px, 0)`
      : undefined,
    cursor: 'move',
  };

  const handleEditButtonClick = (event) => {
    // Stop the event from propagating to the draggable element
    event.stopPropagation();
    if (props.onEditButtonClick) {
      props.onEditButtonClick(event);
    }
  };
  const handleViewLogsClick = (event) => {
    // Stop the event from propagating to the draggable element
    event.stopPropagation();
    if (props.onEditButtonClick) {
      props.onViewLogClick(event);
    }
  };

  return (
    <>
      <div ref={setNodeRef} style={style} {...listeners} {...attributes}>
        {props.children}
      </div>
      <div className="shiftActionButtons">
        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
          <img
            src={editBlock}
            alt="Edit"
            className="edit actionItem"
            onClick={handleEditButtonClick}
          />
        </ProtectedComponent>
        <div className="viewLogs">
          <img
            src={logsIcon}
            alt="Logs Icon"
            className="logs actionItem"
            onClick={handleViewLogsClick}
          />
        </div>
      </div>
    </>
  );
}
