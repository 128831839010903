import React, { useState, useEffect } from "react";
import moment from "moment";
import * as Yup from "yup";
import { Field, useFormik } from "formik";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import ProgressComponent from "../../../components/general/ProgressComponent";
import CustomSelectStaffMember from "../../../components/forms-fields/CustomSelectStaffMembers";
import CustomSelectWeeks from "../../../components/forms-fields/CustomSelectWeek";
import {
  PUT_UPDATE_ROTA_OPEN_STAFF_SHIFT_SCHEDULE_URL,
  ADMIN_ID,
} from "../../../env";
import { postRecords } from "../../../helper/helper";
import { twoWeeksOptions, fourWeeksOptions } from "../../../helper/helperJsx";

function ModifyStaffOpenShift({
  handleShowModel,
  handleCloseModel,
  tempId,
  templateWeekNum,
  activeWeekTabNum,
  shiftArrayItem,
  selectedLocationId,
  fetchAllRecords,
  scheduleTitle,
}) {
  const [isFormSubmitting, setIsFormSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const validationSchema = Yup.object().shape({
    secondaryWeeks: Yup.array().min(1, "Please select at least one week."),
  });

  const formData = useFormik({
    initialValues: {
      tempId: "",
      shiftId: "",
      daysArray: "",
      shiftArray: "",
      secondaryWeeks: [activeWeekTabNum?.toString()],
      locationId: "",
      lastModifiedBy: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitting(true);
        await postRecords(
          PUT_UPDATE_ROTA_OPEN_STAFF_SHIFT_SCHEDULE_URL,
          values
        );
        await fetchAllRecords();
        handleCloseModel();
        setIsFormSubmitting(false);
      } catch (error) {
        console.error("Error", error);
        setIsFormSubmitting(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = formData;

  const handleWeeksSelect = async (weeks) => {
    setFieldValue("secondaryWeeks", weeks);
  };

  const handleSetValues = async () => {
    await setValues({
      tempId: tempId,
      shiftId: shiftArrayItem?._id,
      daysArray: shiftArrayItem?.days,
      shiftArray: shiftArrayItem,
      secondaryWeeks: [activeWeekTabNum?.toString()],
      locationId: selectedLocationId,
      lastModifiedBy: ADMIN_ID,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (tempId) {
      handleSetValues();
    }
  }, [tempId]);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{scheduleTitle}</Modal.Title>
          </Modal.Header>
          <form method="post" onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row align-items-center mt-4">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectWeeks
                      onSelect={handleWeeksSelect}
                      label="Weeks"
                      filterBy="weeks"
                      defaultWeeks={values?.secondaryWeeks}
                      weekOptions={
                        templateWeekNum === 2
                          ? twoWeeksOptions
                          : fourWeeksOptions
                      }
                      omBlurFunc={handleBlur}
                      customError={
                        touched?.secondaryWeeks && errors?.secondaryWeeks
                      }
                      errorMessage={errors?.secondaryWeeks}
                      errorClass={
                        touched?.secondaryWeeks && errors?.secondaryWeeks
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectStaffMember
                      onSelect={(value) => setFieldValue("staffUserId", value)}
                      defaultValue={false}
                      label="Staff Name"
                      omBlurFunc={handleBlur}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isFormSubmitting ? (
                <ProgressComponent />
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={handleCloseModel}
                  >
                    Cancel
                  </Button>

                  <Button className="btn-sm" type="submit" variant="primary">
                    Save
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      )}
    </>
  );
}

export default ModifyStaffOpenShift;
