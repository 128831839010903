import React, { useState } from "react";
import ProfileDetails from "../../components/user-detail/ProfileDetails";
import UserDetailHeader from "../../components/user-detail/UserDetailHeader";
import SupervisionDetails from "./SupervisionDetails";
import SupervisionBlocks from "./SupervisionBlocks";

function SupervisionIndividual({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(initialUserData);

  return (
    <>
      <div className="profileDetailWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails getUserRecord={userData} />
          </div>
          <div className="col-md-9">
            <div className="userDetail rowClickUnset">
              <UserDetailHeader supervision={userData} />
              <SupervisionBlocks getUserRecord={userData} />
              <SupervisionDetails getUserRecord={userData} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default SupervisionIndividual;
