import React, { useState } from "react";
// import UserDetailReview from "./UserDetailReview";
import UserDetailReview from "../../../components/user-detail/UserDetailReview";
import Layout from "../../../components/layouts/Layout";
import { useLocation } from "react-router-dom";
function ServiceProfile() {
  // const initialUserData = getUserRecord;
  // const [userData, setUserData] = useState(initialUserData);
  // console.log("userData record:", getUserRecord) 

  const location = useLocation();
  const userData = location?.state ? location?.state?.userDetails : "";
  console.log("userData record:", userData)
  
  return (
    <>
      <Layout
        pageTitleText={
          <>
            Service User <small>Profile</small>
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={true}
          viewNotes={true}
          profileDetail={true}
          patientOptions={true}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
      </Layout>
    </>
  );
}

export default ServiceProfile;
