import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import moment from "moment";
import { Tab, Nav } from "react-bootstrap";
import { GET_ALL_LOCATIONS_URL } from "../../env";
import { getRecords, isSuccess } from "../../helper/helper";
import ProgressComponent from "../../components/general/ProgressComponent";
import LocationRoster from "./LocationRoster";
import { Link } from "react-router-dom";



function LiveRosterList() {
  const [rotaLocationURL, setRotaLocationURL] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isCurrentDate, setIsCurrentDate] = useState(false);

  const [currentDateToAddRoster, setCurrentDateToAddRoster] = useState()
  const [currentStartDate, setCurrentStartDate] = useState()

  const handleRosterData = (rosterDate) => {
    setCurrentDateToAddRoster(rosterDate)
  }
  useEffect(() => {
    setCurrentStartDate(currentDateToAddRoster)
  }, [])

  // Getting Location records from DB
  function processFetchRecords(method, data) {
    if (method === "AllLocationsUrl" && data?.status === isSuccess) {
      const result = data?.data?.shiftLocations.map((item) => item);
      const validServiceUsers = result?.filter((item) => item?._id);
      setRotaLocationURL(validServiceUsers);
    } else if (data?.status !== isSuccess) {
      console.error(data);
    }
  }

  // Fetch all Location
  const fetchAllLocatinos = async () => {
    try {
      const query = {
        sortproperty: "createdAt",
        sortorder: 1,
        offset: 0,
        query: {
          critarion: {
            active: true,
          },
        },
      };
      const response = await getRecords(GET_ALL_LOCATIONS_URL, query);
      processFetchRecords("AllLocationsUrl", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllRecords = async () => {
    try {
      await fetchAllLocatinos();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {

    fetchAllRecords();
    setIsCurrentDate(true)
  }, []);


  return (
    <>
      <Layout pageTitleText="Live Roster">
        {
          isLoading ? (
            <div>
              <div className="mainContent minHeight p-0 mt-3 mb-3">
                <div className="subTabsWrapper">
                  <ProgressComponent />
                </div>
              </div>
            </div>
          ) : (
            <div className="holidaysWrapper rosterWrapper">
              <div className="row">
                <div className="col-md-12">
                  <div className="tabsWrapper">
                    <Tab.Container id="myTab" defaultActiveKey="location01">
                      <div className="tabsHeader">
                        <div className="holidayHeaderStuff">
                          <Link to="/shifts-requests" className="btn btn-sm btn-outline-danger">Shifts Requests</Link>
                        </div>
                        <Nav variant="tabs">
                          {rotaLocationURL?.map((locationItem, locationIndex) => (
                            <Nav.Item key={locationIndex}>
                              <Nav.Link eventKey={`location0${locationIndex + 1}`}>
                                {locationItem?.shiftLocationName}
                              </Nav.Link>
                            </Nav.Item>
                          ))}
                        </Nav>
                      </div>

                      <Tab.Content>
                        {rotaLocationURL?.map((locationItem, locationIndex) => (
                          <Tab.Pane eventKey={`location0${locationIndex + 1}`} key={locationIndex}>
                            <div className="rosterTabsContent">
                              {isLoading ? (
                                <div className="mainContent minHeight p-0 mt-3 mb-3">
                                  <div className="subTabsWrapper">
                                    <ProgressComponent />
                                  </div>
                                </div>
                              ) : (
                                isCurrentDate ? (
                                  // locationItem?.shiftLocationName === "Location 01" ? (
                                  //   <Location01 shiftLocationName={locationItem?.shiftLocationName} shiftLocationId={locationItem?._id}/>
                                  // ) : locationItem?.shiftLocationName === "Location 02" ? (
                                  //   <Location02 shiftLocationName={locationItem?.shiftLocationName} shiftLocationId={locationItem?._id}/>
                                  // ) : locationItem?.shiftLocationName === "Location 03" ? (
                                  //   <Location03 shiftLocationName={locationItem?.shiftLocationName} shiftLocationId={locationItem?._id}/>
                                  // ) : null
                                  <>
                                    <LocationRoster
                                      shiftLocationName={locationItem?.shiftLocationName}
                                      shiftLocationId={locationItem?._id}
                                      onData={handleRosterData} />
                                  </>
                                ) : null

                              )}
                            </div>
                          </Tab.Pane>
                        ))}
                      </Tab.Content>
                    </Tab.Container>
                  </div>
                </div>
              </div>
            </div>
          )
        }

      </Layout >
    </>
  );
}

export default LiveRosterList;