// Api's
// const BASE_URL = "http://16.171.242.62:3000";
const BASE_URL = "https://ftcaresoftware.co.uk/ft_care_server";
// const BASE_URL = "http://localhost:3000";

// Authentication Token
const IS_SUPERADMIN = true;
const ADMIN_BASE_URL = `${BASE_URL}/users`;
const ADMIN_LOGIN_URL = `${ADMIN_BASE_URL}/signin`;
const POST_VERIFY_OTP_URL = `${ADMIN_BASE_URL}/verify-code`;
const ADMIN_ID = localStorage.getItem("userId");
const USER_AUTH_FCM_TOKEN =
  "fY6_DGfGQ06OW4BUVEsEwG:APA91bGUFza2uhaaR0miN2jtY0ut7RuA5ObleZvqv2X8KLOBgksmDmgc9sHDdea-DBvHmz1aUwX1uhkysk92x50WQCLHPDD1VwGX5ybKhUwVdq4aBfI24vhXqMB-FksWXEZwzDAi9BA_";

// STAFF MEMBER API'S
const GET_LIST_ALL_USERS_URL = `${ADMIN_BASE_URL}/listAllUsers`;
const GET_LIST_ALL_STAFF_MEMBERS_URL = `${ADMIN_BASE_URL}/listAllStaffMembers`;
const GET_ALL_STAFF_MEMBERS_URL = `${BASE_URL}/staffMembers/getStaffMembersWithFullDetails`;
const POST_STAFF_RECORD_URL = `${ADMIN_BASE_URL}/signup`;
const POST_UPDATE_STAFF_MEMBER_DETAILS_URL = `${ADMIN_BASE_URL}/updateUserAll`;
const POST_USER_PROFILE_PICTURE_URL = `${ADMIN_BASE_URL}/uploadPicForPath`;
const PUT_USERS_PROFILE_PICTURE_URL = `${ADMIN_BASE_URL}/updateProfilePicture`;
const PUT_USERS_PROFILE_VIDEO_URL = `${BASE_URL}/uploads/uploadProfileVideo`;
const PUT_GROUP_PROFILE_PICTURE_URL = `${ADMIN_BASE_URL}/updateGroupDp`;

// GET SERVICE USER REVIEW API'S
const GET_ALL_SERVICE_USERS_REVIEW_LIST = `${BASE_URL}/review/getReviewList`;
const REMOVE_SERVICE_USERS_REVIEW = `${BASE_URL}/review/deleteReviewList`;
const UPDATE_SERVICE_USERS_REVIEW = `${BASE_URL}/review/updateReview`;
const ADD_SERVICE_USERS_REVIEW = `${BASE_URL}/review/addReview`;

// HOLIDAYS API'S
const GET_STAFFS_HOLIDAYS_DETAILS_URL = `${BASE_URL}/staffHolidayRequests/getStaffHolidayRequestsWithFullDetails`;
const GET_STAFF_HOLIDAYS_REQUEST_URL = `${BASE_URL}/staffHolidayRequests/getStaffHolidayRequestsList`;
const POST_HOLIDAY_STAFF_MEMBER_URL = `${BASE_URL}/staffHolidayRequests/createStaffHolidayRequest`;
const PUT_HOLIDAY_STAFF_MEMBER_URL = `${BASE_URL}/staffHolidayRequests/updateStaffHolidayRequest`;

// Consent and Capitcity
const GET_CONSENT_TEMPLATE_URL = `${BASE_URL}/consentTemplateRoute/getconsentTemplate`;
const POST_CONSENT_TEMPLATE_URL = `${BASE_URL}/consentTemplateRoute/addConsentTemplate`;
const UPDATE_CONSENT_TEMPLATE_URL = `${BASE_URL}/consentTemplateRoute/updateConsentTemplate`;
const DELETE_CONSENT_TEMPLATE_URL = `${BASE_URL}/consentTemplateRoute/removeConsentTemplate`;
const GET_CONSENT_TEMPLATE_CAT_URL = `${BASE_URL}/consentTemplateRoute/getHealthCategories`;

// Health Issues
const POST_HEALTH_ISSUES_URL = `${BASE_URL}/consentTemplateRoute/addHealthIssues`;
const GET_HEALTH_ISSUES_URL = `${BASE_URL}/consentTemplateRoute/getHealthIssues`;
const UPDATE_HEALTHISSUES_URL = `${BASE_URL}/consentTemplateRoute/updateHealthIssues`;

// STAFF WORKLOADS API'S
const GET_STAFF_WORKLOADS_URL = `${BASE_URL}/staffWorkLoads/getStaffWorkLoadsWithFullDetails`;
const PUT_STAFF_WORKLOADS_URL = `${BASE_URL}/staffWorkLoads/updateStaffWorkLoad`;

// SERVICE USER API'S
const POST_SERVICE_USER_SIGN_UP_URL = `${BASE_URL}/users/signup`;
const UPDATE_SERVICE_USER_ENQUIRY_URL = `${BASE_URL}/suEnquiryies/updateSuEnquiry`;
const GET_ALL_SERVICE_USERS_URL_URL_ENQUIRY_URL = `${BASE_URL}/suEnquiryies/getSuEnquirysWithFullDetails`;
const GET_ALL_SERVICE_USERS_URL = `${ADMIN_BASE_URL}/listAllServiceUsers`;
const GET_ALL_SERVICE_USERS_FULL_DETAILS_URL = `${BASE_URL}/serviceUsers/getServiceUsersWithFullDetails`;
const GET_ALL_SERVICE_USERS_LIST_URL = `${BASE_URL}/serviceUsers/getServiceUsersList`;
const POST_SERVICE_USER_SIGNUP_URL = `${ADMIN_BASE_URL}/signup`;
const POST_SERVICE_USER_ENQUIRY_URL = `${BASE_URL}/suEnquiryies/createSuEnquiry`;
const GET_ALL_SERVICE_USERS_URL_ENQUIRY = `${BASE_URL}/suEnquiryies/getSuEnquirysWithFullDetails`;
const GET_SERVICE_USER_BY_ID_URL = `${BASE_URL}/serviceUsers/findServiceUserById`;
const UPDATE_SERVICE_USER_BY_ID_URL = `${BASE_URL}/serviceUsers/updateServiceUser`;
const REMOVE_SERVICE_USER_BY_ID_URL = `${BASE_URL}/suEnquiryies/removeSuEnquiry`;

// SUPERVISION SAPI'S
const GET_ALL_SUPERVISIONS_FULL_DETAILS_URL = `${BASE_URL}/staffMembers/getAllSupervisionsForAStaffMember`;
const POST_STAFF_NEXT_SUPERVISION_URL = `${BASE_URL}/supervisions/createSupervision`;

// TRANINGS API'S
const GET_ALL_TRAININGS_WITH_FULL_DETAILS_URL = `${BASE_URL}/proposedTrainings/getProposedTrainingsWithFullDetails`;
const POST_CREATE_TRAINING_TEMPLATE_URL = `${BASE_URL}/proposedTrainings/createProposedTraining`;
const POST_ASSIGN_TRAINING_TEMPLATE_URL = `${BASE_URL}/staffTrainings/createStaffTraining`;
const POST_UPLOAD_TRAINING_FILE_URL = `${BASE_URL}/proposedTrainings/uploadContentDoc`;
const POST_UPDATE_TRAINING_TEMPLATE_URL = `${BASE_URL}/proposedTrainings/updateProposedTraining`;
const REMOVE_TRAINING_TEMPLATE_URL = `${BASE_URL}/proposedTrainings/removeProposedTraining`;

// STAFF COMPLIANCE/REFRENCES DOCUMENTS API'S
const GET_STAFF_COMPLIANCE_DOCUMENTS_URL = `${BASE_URL}/docs/getComplianceDocs`;
const POST_STAFF_COMPLIANCE_DOCUMENTS_URL = `${BASE_URL}/docs/addComplianceDocument`;
const PUT_STAFF_COMPLIANCE_DOCUMENTS_URL = `${BASE_URL}/docs/updateDocStatus`;
const DELETE_STAFF_COMPLIANCE_DOCUMENTS_URL = `${BASE_URL}/docs/removeComplianceDocument`;

// STAFF COMPLIANCE/REFRENCES/ COMMENTS  API'S
const GET_STAFF_COMPLIANCE_DOC_COMMENTS_URL = `${BASE_URL}/docs/getComplainceComment`;
const POST_STAFF_COMPLIANCE_DOC_COMMENTS_URL = `${BASE_URL}/docs/addComplianceComments`;
const PUT_STAFF_COMPLIANCE_DOC_COMMENTS_URL = `${BASE_URL}/docs/updateComplianceComment`;
const DELETE_STAFF_COMPLIANCE_DOC_COMMENTS_URL = `${BASE_URL}/docs/removeComplainceComment`;

// SUPERVISION TEMPLATES API'S
const GET_ALL_SUPERVISIONS_TEMPLATES_URL = `${BASE_URL}/supervisionQuestionTemplate/getSupervisionQuestionTemplateList`;
const GET_STAFF_SUPERVISION_COUNTS_URL = `${BASE_URL}/supervisions/getSupervisionsCountForAStaffmember`;
const POST_SUPERVISION_TEMPLATES_URL = `${BASE_URL}/supervisionQuestionTemplate/getSupervisionQuestionTemplateList`;
const POST_ASSIGN_NEXT_SUPERVISION_URL = `${BASE_URL}/supervisions/assignNextSupervision`;

//  RECRUITMENT API'S
const GET_RECRUITMENT_CANDIDATES_URL = `${BASE_URL}/recruitment/getCandidateList`;
const POST_RECRUITMENT_CANDIDATE_URL = `${BASE_URL}/recruitment/addCandidate`;
const POST_UPDATE_RECRUITMENT_CANDIDATE_URL = `${BASE_URL}/recruitment/updateCandidate`;

// STAFF SUPERVISIONS Q/ANSWERS API'S
const GET_ALL_STAFF_MEMBERS_SUPERVISIONS_QA_URL = `${BASE_URL}/staffMembers/getAllSupervisionsForAStaffMember`;
const POST_STAFF_SUPERVISION_Q_ANSWERS_URL = `${BASE_URL}/supervisionQuestionsAnswers/updateSupervisionQuestionsAnswers`;
const POST_SUPERVISION_TEMPLATE_COMMENT_URL = `${BASE_URL}/supervisionQuestionTemplate/addSupervisionTemplateComments`;

// BRADFORD SCORE API'S
const GET_ALL_BRADFORD_INCIDENTS_URL = `${BASE_URL}/staffIncidentsRequests/getStaffIncidentsRequestWithFullDetails`;
const POST_BRADFORD_INCIDENT_URL = `${BASE_URL}/staffIncidentsRequests/updateStaffIncidentsRequest`;

// ROTA TEMPLATES API'S
const GET_ALL_ROTA_TEMPLATES_URL = `${BASE_URL}/scheduleTemplates/getScheduleTemplatesWithFullDetails`;
const POST_NEW_ROTA_TEMPLATE_URL = `${BASE_URL}/scheduleTemplates/createTemplate`;
const PUT_UPDATE_ROTA_TEMPLATE_URL = `${BASE_URL}/scheduleTemplates/updateScheduleTemplateOnly`;
const PUT_ADD_NEXT_DATE_ROTA_TEMPLATE_URL = `${BASE_URL}/scheduleTemplates/updateAndAddNewTemplate`;
const DELETE_ROTA_TEMPLATE_URL = `${BASE_URL}/scheduleTemplates/removeScheduleTemplate`;

// ROTA SCHEDULE API'S
const GET_ALL_ROTA_TEMPLATE_SCHEDULES_URL = `${BASE_URL}/scheduleTemplates/getScheduleTemplatesWithFullDetails`;
const PUT_UPDATE_ROTA_OPEN_STAFF_SHIFT_SCHEDULE_URL = `${BASE_URL}/scheduleTemplates/updateOpenShiftSchedule`;
const PUT_UPDATE_ROTA_OPEN_PATIENT_SHIFT_SCHEDULE_URL = `${BASE_URL}/scheduleTemplates/updateOpenPatientShiftSchedule`;
const POST_NEW_ROTA_TEMPLATE_SCHEDULE_URL = `${BASE_URL}/scheduleTemplates/createScheduleTemplate`;
const POST_ADD_ROTA_NEW_INDIVIDUAL_TEMPLATE_SHIFT_DAY_URL = `${BASE_URL}/scheduleTemplates/addIndividualShiftDay`;
const POST_ADD_ROTA_2ND_INDIVIDUAL_SHIFT_DAY_URL = `${BASE_URL}/scheduleTemplates/add2ndIndividualShiftDay`;
const POST_UPDATE_ROTA_INDIVIDUAL_SHIFT_DAY_URL = `${BASE_URL}/scheduleTemplates/updateIndividualShiftDay`;
const POST_UPDATE_ROTA_INDIVIDUAL_SHIFT_FROM_STAFF_LISTING_URL = `${BASE_URL}/scheduleTemplates/updateIndividualShiftDayStaff`;
const DELETE_ROTA_SCHEDULE_SHIFT_DAY_URL = `${BASE_URL}/scheduleTemplates/removeScheduleShift`;
const DELETE_ROTA_SCHEDULE_OPEN_SHIFT_URL = `${BASE_URL}/scheduleTemplates/removeOpenScheduleShift`;


// Rota Updated APIs
const POST_TEMPLATE_SHIFTS = `${BASE_URL}/scheduleTemplates/addTemplateShifts`;
const UPDATE_TEMPLATE_SHIFTS = `${BASE_URL}/scheduleTemplates/updateTemplateShifts`;
const DELETE_TEMPLATE_SHIFTS = `${BASE_URL}/scheduleTemplates/deleteTemplateShifts`;

// ROSTER SHIFTS API'S
const UPDATE_LIVE_ROSTER_LOCATIONS_URL = `${BASE_URL}/liveRoster/updateRosterTemplateShiftLocations`;
const PUT_UPDATE_ROSTER_OPEN_STAFF_SHIFT_SCHEDULE_URL = `${BASE_URL}/liveRoster/updateOpenShiftSchedule`;
const PUT_UPDATE_ROSTER_OPEN_PATIENT_SHIFT_SCHEDULE_URL = `${BASE_URL}/liveRoster/updateOpenPatientShiftSchedule`;
const POST_NEW_ROSTER_TEMPLATE_SCHEDULE_URL = `${BASE_URL}/liveRoster/createScheduleTemplate`;
const CHECK_EXITS_TEMPLATE_SHIFTS_URL = `${BASE_URL}/liveRoster/checkExistShift`;
const GET_ALL_LIVE_ROSTER_TEMPLATES_URL = `${BASE_URL}/liveRoster/getScheduleTemplatesWithFullDetails`;
const POST_CREATE_ROSTER_TEMPLATE_URL = `${BASE_URL}/liveRoster/createRosterTemplateTemplate`;
const POST_UPDATE_ROSTER_TEMPLATE_URL = `${BASE_URL}/liveRoster/updateRosterTemplateTemplate`;
const PUT_UPDATE_ROSTER_TEMPLATE_SHIFTS_URL = `${BASE_URL}/liveRoster/updateScheduleTemplate`;
const PUT_INDIVIDUAL_UPDATE_ROSTER_TEMPLATE_SHIFTS_URL = `${BASE_URL}/liveRoster/updateScheduleTemplate`;
const POST_ADD_NEW_INDIVIDUAL_ROSTER_SHIFT_DAY_URL = `${BASE_URL}/liveRoster/addIndividualShiftDay`;
const POST_ADD_2ND_INDIVIDUAL_ROSTER_SHIFT_DAY_URL = `${BASE_URL}/liveRoster/add2ndIndividualShiftDay`;
const POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_DAY_URL = `${BASE_URL}/liveRoster/updateIndividualShiftDay`;
const POST_ADD_INDIVIDUAL_ROSTER_SHIFT_URL = `${BASE_URL}/liveRoster/updateIndividualShift`;
const POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_URL = `${BASE_URL}/liveRoster/updateSingleRoster`;
const POST_UPDATE_ROSTER_INDIVIDUAL_SHIFT_FROM_STAFF_LISTING_URL = `${BASE_URL}/liveRoster/updateIndividualShiftDayStaff`;
const DELETE_ROSTER_SCHEDULE_SHIFT_DAY_URL = `${BASE_URL}/liveRoster/removeScheduleShift`;
const DELETE_ROSTER_SCHEDULE_OPEN_SHIFT_URL = `${BASE_URL}/liveRoster/removeOpenScheduleShift`;
const POST_LIVE_ROSTER_DATA_URL = `${BASE_URL}/liveRoster/addLiveRosterData`;

// Claimed Shifts
const GET_CLAIMED_LIVE_ROSTER_SHIFTS_URL = `${BASE_URL}/liveRoster/claimedShifts`;
const UPDATE_CLAIMED_LIVE_ROSTER_SHIFTS_URL = `${BASE_URL}/liveRoster/updateStatusClaimedShift`;

// eMAR
const POST_EMAR_MEDICATION_URL = `${BASE_URL}/emar/addMedicationSchedule`;
const GET_EMAR_MEDICATION_URL = `${BASE_URL}/emar/medicationList`;
const UPDTE_EMAR_MEDICATION_URL = `${BASE_URL}/emar/updateMedication`;
const DELETE_EMAR_MEDICATION_URL = `${BASE_URL}/emar/removeMedication`;

// Medicine Stock
const POST_MEDICINE_STOCK_URL = `${BASE_URL}/medicationinventory/addMedicationInventory`;
const GET_MEDICINE_STOCK_URL = `${BASE_URL}/medicationinventory/getMedicationInventory`;
const UPDTE_MEDICINE_STOCK_URL = `${BASE_URL}/medicationinventory/updateMedicationInventory`;
const DELETE_MEDICINE_STOCK_URL = `${BASE_URL}/medicationinventory/removeMedicationInventory`;

// RISK ASSESSMENTS
const POST_RISK_ASSESSMENTS_URL = `${BASE_URL}/riskassesments/createRiskAssesments`;
const GET_RISK_ASSESSMENTS_URL = `${BASE_URL}/riskassesments/getRiskAssesments`;
const UPDTE_RISK_ASSESSMENTS_URL = `${BASE_URL}/riskassesments/updateRiskAssesments`;
const DELETE_RISK_ASSESSMENTS_URL = `${BASE_URL}/riskassesments/removeRiskAssesments`;

// APPOINTMENTS
const POST_APPOINTMENTS_URL = `${BASE_URL}/appointment/addAppointment`;
const GET_APPOINTMENTS_URL = `${BASE_URL}/appointment/getAppointmentsByDate`;
const UPDTE_APPOINTMENTS_URL = `${BASE_URL}/appointment/updateAppointments`;
const DELETE_APPOINTMENTS_URL = `${BASE_URL}/appointment/cancelAppointment`;
const FIND_BY_ID_APPOINTMENTS_URL = `${BASE_URL}/appointment/findAppointmentById`;

// MESSAGING API'S
const GET_ALL_MESSAGES_URL = `${BASE_URL}/messages/getAllMessage`;
const GET_INDIVIDUAL_MESSAGES_URL = `${BASE_URL}/messages/getMessage`;
const POST_ADD_NEW_MESSAGE_URL = `${BASE_URL}/messages/addMessage`;
const PUT_UPDATE_MESSAGE_URL = `${BASE_URL}/messages/updateMessage`;
const DELETE_MESSAGE_URL = `${BASE_URL}/messages/deleteMessage`;
const DELETE_MESSAGE_COMPLETE_CHAT_URL = `${BASE_URL}/messages/deleteChat`;
const UPLOAD_MESSAGING_SYSTEM_FILE = `${BASE_URL}/messages/uploadMessageAttachments`;

// GROUPS MESSAGING API'S
const POST_CREATE_GROUP_CHAT_URL = `${BASE_URL}/messages/createGroup`;
const GET_ALL_GROUPS_MESSAGES_URL = `${BASE_URL}/messages/groupByUserId`;
const GET_INDIVIDUAL_GROUP_MESSAGES_URL = `${BASE_URL}/messages/messageByGroupId`;
const POST_SEND_MESSAGE_IN_GROUP_URL = `${BASE_URL}/messages/addGroupMessage`;
const PUT_SET_UPDATE_GROUP_SCHEDULE_TIMING_URL = `${BASE_URL}/messages/setGroupScheduleTiming`;
const PUT_UPDATE_GROUP_DETAILS_URL = `${BASE_URL}/messages/updateGroup`;
const PUT_UPDATE_GROUP_MESSAGE_DETAILS_URL = `${BASE_URL}/messages/updateGroupMessage`;
const PUT_REMOVE_MEMBER_FROM_GROUP_URL = `${BASE_URL}/messages/removeMemberFromGroup`;
const GET_GROUP_INFO_URL = `${BASE_URL}/messages/getGroupDetails`;
const DELETE_GROUP_MESSAGE_URL = `${BASE_URL}/messages/deleteGroupMessage`;

// GET ALL MESSAGING API'S
const GET_ALL_COMBINED_MESSAGES_URL = `${BASE_URL}/messages/combinedLastMessages`;

// PBS Plans
const POST_PBS_PLANS_URL = `${BASE_URL}/pbsplans/addPbsplan`;
const GET_PBS_PLANS_URL = `${BASE_URL}/pbsplans/getPbsPlanList`;
const UPDTE_PBS_PLANS_URL = `${BASE_URL}/pbsplans/updatePbsplan`;
const DELETE_PBS_PLANS_URL = `${BASE_URL}/pbsplans/deletePbsPlanList`;

// Memory Box
const POST_MEMORY_BOX_URL = `${BASE_URL}/carePlans/addMemoryBox`;
const GET_MEMORY_BOX_URL = `${BASE_URL}/carePlans/getMemoryList`;
const UPDTE_MEMORY_BOX_URL = `${BASE_URL}/carePlans/updateMemoryBox`;
const UPDATE_MEMORY_BOX_URL = `${BASE_URL}/carePlans/updateMemoryBox`;
const DELETE_MEMORY_BOX_URL = `${BASE_URL}/carePlans/deleteMemoryBox`;
const UPLOAD_MEMORY_BOX_FILE = `${BASE_URL}/carePlans/uploadMemoryBoxFile`;

// locations
const GET_ALL_LOCATIONS_URL = `${BASE_URL}/shiftLocations/getShiftLocationsWithFullDetails`;
const POST_CREATE_LOCATION_URL = `${BASE_URL}/shiftLocations/createShiftLocation`;
const PUT_UPDATE_LOCATION_URL = `${BASE_URL}/shiftLocations/updateShiftLocation`;
const POST_DELETE_LOCATIONS_URL = `${BASE_URL}/shiftLocations/removeShiftLocation`;

// Recent Incidents
const POST_RECENT_INCIDENT_URL = `${BASE_URL}/incident/addIncident`;
const GET_RECENT_INCIDENT_URL = `${BASE_URL}/incident/getIncidentWithFullDetails`;
const UPDATE_RECENT_INCIDENT_URL = `${BASE_URL}/incident/updateIncident`;
const DELETE_RECENT_INCIDENT_URL = `${BASE_URL}/incident/removeIncident`;

// CarePlan
const POST_CARE_PLAN_URL = `${BASE_URL}/carePlans/createCarePlan`;
const GET_CARE_PLAN_URL = `${BASE_URL}/carePlans/getCarePlansList`;
const GET_CARE_PLAN_14_URL = `${BASE_URL}/carePlans/getCarePlans14Days`;
const GET_CARE_DAILY_SUMMARY_URL = `${BASE_URL}/carePlans/carePlanAndIncidentList`;
const UPDTE_CARE_PLAN_URL = `${BASE_URL}/carePlans/updateCarePlan`;
const DELETE_CARE_PLAN_URL = `${BASE_URL}/carePlans/removeCarePlan`;

// CarePlan Documents
const POST_CARE_PLAN_DOC_URL = `${BASE_URL}/carePlans/addCarePlanNote`;
const GET_CARE_PLAN_DOC_URL = `${BASE_URL}/carePlans/getCarePlanNote`;
const GET_CARE_PLAN_DOC_BY_ID_URL = `${BASE_URL}/carePlans/getCarePlanNoteById`;
const UPDTE_CARE_PLAN_DOC_URL = `${BASE_URL}/carePlans/updateCarePlanNote`;
const DELETE_CARE_PLAN_DOC_URL = `${BASE_URL}/carePlans/deleteCarePlanNote`;

// UPLOAD REFERENCE DOCUMENTS API'S
const UPLOAD_REFERENCE_DOCUMENT_URL = `${BASE_URL}/uploads/uploadReferenceDoc`;

// ADMIN AUTH TOKEN
const ADMIN_AUTH_TOKEN = `eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJhIjp0cnVlLCJlIjoiamFtc2hhaWRzYWJpcjQxMTk4MEBnbWFpbC5jb20iLCJkIjoiNjM5ZDk2NTQwYjg5YzIxZDU0MjFkZmMxIiwicCI6Ii91cGxvYWRzL2RwLzE2NzEyNzIwMjA2OTEtMy5qcGciLCJyIjoiX2EiLCJpYXQiOjE2NzE2NDgxMDd9.woISb4KszBypN8cLGRWIdvPzBpA-R_M6gemg8r4FSh4`;

// Support Plan
const POST_SUPPORT_PLAN_URL = `${BASE_URL}/supportplan/createSupportPlan`;
const GET_SUPPORT_PLAN_URL = `${BASE_URL}/supportplan/getSupportPlansWithFullDetails`;
const GET_SUPPORT_PLAN_LIST_URL = `${BASE_URL}/supportplan/getSupportPlansList`;
const DELETE_SUPPORT_PLAN_URL = `${BASE_URL}/supportplan/removeSupportPlan`;

// ServiceUser Documents
const POST_SU_DOCUMENTS_URL = `${BASE_URL}/document/addDocument`;
const GET_SU_DOCUMENTS_URL = `${BASE_URL}/document/documentList`;
const UPDATE_SU_DOCUMENTS_URL = `${BASE_URL}/document/updateDocument`;
const DELETE_SU_DOCUMENTS_URL = `${BASE_URL}/document/removeDocument`;
const UPLOAD_SU_DOCUMENTS_FILE = `${BASE_URL}/uploads/uploadReferenceDoc`;

// Quality Survey
const POST_QUALITY_SURVEY_URL = `${BASE_URL}/qualitySurvey/createQualitySurvey`;
const GET_QUALITY_SURVEY_URL = `${BASE_URL}/qualitySurvey/qualitySurveyWithFullDetails`;
const GET_QUALITY_SURVEY_QUESTIONS_BY_CAT_URL = `${BASE_URL}/qualitySurvey/getQualitySurveyQuestionByCat`;
const UPDATE_QUALITY_SURVEY_URL = `${BASE_URL}/qualitySurvey/updateQualitySurvey`;
const DELETE_QUALITY_SURVEY_URL = `${BASE_URL}/qualitySurvey/removeQualitySurvey`;

//Payroll
const GET_PAYROLL_LIST = `${BASE_URL}/payroll/getPayrollList`;

// Export all
export {
  ADMIN_AUTH_TOKEN,
  ADMIN_BASE_URL,
  ADMIN_ID,
  ADMIN_LOGIN_URL,
  BASE_URL,
  DELETE_STAFF_COMPLIANCE_DOCUMENTS_URL,
  GET_ALL_SERVICE_USERS_URL,
  GET_ALL_SERVICE_USERS_FULL_DETAILS_URL,
  GET_ALL_SERVICE_USERS_LIST_URL,
  GET_ALL_SERVICE_USERS_REVIEW_LIST,
  REMOVE_SERVICE_USERS_REVIEW,
  UPDATE_SERVICE_USERS_REVIEW,
  ADD_SERVICE_USERS_REVIEW,
  GET_ALL_SERVICE_USERS_URL_ENQUIRY,
  GET_ALL_SERVICE_USERS_URL_URL_ENQUIRY_URL,
  GET_ALL_STAFF_MEMBERS_SUPERVISIONS_QA_URL,
  GET_ALL_STAFF_MEMBERS_URL,
  GET_LIST_ALL_STAFF_MEMBERS_URL,
  GET_LIST_ALL_USERS_URL,
  GET_RECRUITMENT_CANDIDATES_URL,
  GET_SERVICE_USER_BY_ID_URL,
  GET_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  GET_STAFF_COMPLIANCE_DOCUMENTS_URL,
  GET_STAFF_HOLIDAYS_REQUEST_URL,
  GET_STAFFS_HOLIDAYS_DETAILS_URL,
  GET_ALL_SUPERVISIONS_FULL_DETAILS_URL,
  GET_ALL_SUPERVISIONS_TEMPLATES_URL,
  GET_STAFF_SUPERVISION_COUNTS_URL,
  GET_STAFF_WORKLOADS_URL,
  POST_VERIFY_OTP_URL,
  POST_ASSIGN_NEXT_SUPERVISION_URL,
  POST_HOLIDAY_STAFF_MEMBER_URL,
  POST_RECRUITMENT_CANDIDATE_URL,
  POST_SERVICE_USER_ENQUIRY_URL,
  POST_SERVICE_USER_SIGNUP_URL,
  POST_SERVICE_USER_SIGN_UP_URL,
  POST_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  POST_STAFF_COMPLIANCE_DOCUMENTS_URL,
  POST_STAFF_NEXT_SUPERVISION_URL,
  POST_STAFF_RECORD_URL,
  POST_STAFF_SUPERVISION_Q_ANSWERS_URL,
  POST_SUPERVISION_TEMPLATE_COMMENT_URL,
  POST_SUPERVISION_TEMPLATES_URL,
  POST_UPDATE_STAFF_MEMBER_DETAILS_URL,
  POST_USER_PROFILE_PICTURE_URL,
  POST_UPDATE_RECRUITMENT_CANDIDATE_URL,
  PUT_HOLIDAY_STAFF_MEMBER_URL,
  PUT_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  PUT_STAFF_COMPLIANCE_DOCUMENTS_URL,
  PUT_STAFF_WORKLOADS_URL,
  PUT_USERS_PROFILE_PICTURE_URL,
  PUT_USERS_PROFILE_VIDEO_URL,
  REMOVE_SERVICE_USER_BY_ID_URL,
  DELETE_STAFF_COMPLIANCE_DOC_COMMENTS_URL,
  UPDATE_SERVICE_USER_ENQUIRY_URL,
  UPLOAD_REFERENCE_DOCUMENT_URL,
  UPDATE_SERVICE_USER_BY_ID_URL,
  USER_AUTH_FCM_TOKEN,
  IS_SUPERADMIN,
  GET_ALL_BRADFORD_INCIDENTS_URL,
  POST_BRADFORD_INCIDENT_URL,
  GET_ALL_TRAININGS_WITH_FULL_DETAILS_URL,
  POST_CREATE_TRAINING_TEMPLATE_URL,
  GET_CONSENT_TEMPLATE_URL,
  POST_UPDATE_TRAINING_TEMPLATE_URL,
  REMOVE_TRAINING_TEMPLATE_URL,
  POST_CONSENT_TEMPLATE_URL,
  UPDATE_CONSENT_TEMPLATE_URL,
  DELETE_CONSENT_TEMPLATE_URL,
  POST_HEALTH_ISSUES_URL,
  GET_HEALTH_ISSUES_URL,
  UPDATE_HEALTHISSUES_URL,
  GET_CONSENT_TEMPLATE_CAT_URL,
  POST_ASSIGN_TRAINING_TEMPLATE_URL,
  POST_UPLOAD_TRAINING_FILE_URL,
  GET_ALL_ROTA_TEMPLATE_SCHEDULES_URL,
  PUT_UPDATE_ROTA_OPEN_STAFF_SHIFT_SCHEDULE_URL,
  POST_NEW_ROTA_TEMPLATE_SCHEDULE_URL,
  DELETE_ROTA_SCHEDULE_SHIFT_DAY_URL,
  POST_ADD_ROTA_NEW_INDIVIDUAL_TEMPLATE_SHIFT_DAY_URL,
  POST_ADD_ROTA_2ND_INDIVIDUAL_SHIFT_DAY_URL,
  POST_UPDATE_ROTA_INDIVIDUAL_SHIFT_DAY_URL,
  POST_UPDATE_ROTA_INDIVIDUAL_SHIFT_FROM_STAFF_LISTING_URL,
  POST_UPDATE_ROSTER_INDIVIDUAL_SHIFT_FROM_STAFF_LISTING_URL,
  POST_EMAR_MEDICATION_URL,
  GET_EMAR_MEDICATION_URL,
  UPDTE_EMAR_MEDICATION_URL,
  DELETE_EMAR_MEDICATION_URL,
  POST_MEDICINE_STOCK_URL,
  GET_MEDICINE_STOCK_URL,
  UPDTE_MEDICINE_STOCK_URL,
  DELETE_MEDICINE_STOCK_URL,
  GET_ALL_ROTA_TEMPLATES_URL,
  POST_NEW_ROTA_TEMPLATE_URL,
  PUT_UPDATE_ROTA_TEMPLATE_URL,
  PUT_ADD_NEXT_DATE_ROTA_TEMPLATE_URL,
  DELETE_ROTA_TEMPLATE_URL,
  POST_APPOINTMENTS_URL,
  GET_APPOINTMENTS_URL,
  UPDTE_APPOINTMENTS_URL,
  DELETE_APPOINTMENTS_URL,
  FIND_BY_ID_APPOINTMENTS_URL,
  POST_RISK_ASSESSMENTS_URL,
  GET_RISK_ASSESSMENTS_URL,
  UPDTE_RISK_ASSESSMENTS_URL,
  DELETE_RISK_ASSESSMENTS_URL,
  GET_ALL_MESSAGES_URL,
  GET_INDIVIDUAL_MESSAGES_URL,
  POST_ADD_NEW_MESSAGE_URL,
  PUT_UPDATE_MESSAGE_URL,
  DELETE_MESSAGE_URL,
  DELETE_MESSAGE_COMPLETE_CHAT_URL,
  UPLOAD_MESSAGING_SYSTEM_FILE,
  CHECK_EXITS_TEMPLATE_SHIFTS_URL,
  GET_ALL_LIVE_ROSTER_TEMPLATES_URL,
  POST_CREATE_ROSTER_TEMPLATE_URL,
  POST_UPDATE_ROSTER_TEMPLATE_URL,
  PUT_UPDATE_ROSTER_TEMPLATE_SHIFTS_URL,
  PUT_INDIVIDUAL_UPDATE_ROSTER_TEMPLATE_SHIFTS_URL,
  POST_ADD_NEW_INDIVIDUAL_ROSTER_SHIFT_DAY_URL,
  POST_ADD_2ND_INDIVIDUAL_ROSTER_SHIFT_DAY_URL,
  POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_DAY_URL,
  POST_ADD_INDIVIDUAL_ROSTER_SHIFT_URL,
  POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_URL,
  POST_PBS_PLANS_URL,
  GET_PBS_PLANS_URL,
  UPDTE_PBS_PLANS_URL,
  DELETE_PBS_PLANS_URL,
  POST_MEMORY_BOX_URL,
  GET_MEMORY_BOX_URL,
  UPDTE_MEMORY_BOX_URL,
  DELETE_MEMORY_BOX_URL,
  GET_ALL_LOCATIONS_URL,
  POST_CREATE_LOCATION_URL,
  PUT_UPDATE_LOCATION_URL,
  POST_DELETE_LOCATIONS_URL,
  UPDATE_MEMORY_BOX_URL,
  UPLOAD_MEMORY_BOX_FILE,
  POST_RECENT_INCIDENT_URL,
  GET_RECENT_INCIDENT_URL,
  UPDATE_RECENT_INCIDENT_URL,
  DELETE_RECENT_INCIDENT_URL,
  POST_CARE_PLAN_URL,
  GET_CARE_PLAN_URL,
  GET_CARE_PLAN_14_URL,
  GET_CARE_DAILY_SUMMARY_URL,
  UPDTE_CARE_PLAN_URL,
  DELETE_CARE_PLAN_URL,
  POST_CARE_PLAN_DOC_URL,
  GET_CARE_PLAN_DOC_URL,
  GET_CARE_PLAN_DOC_BY_ID_URL,
  UPDTE_CARE_PLAN_DOC_URL,
  DELETE_CARE_PLAN_DOC_URL,
  POST_SUPPORT_PLAN_URL,
  GET_SUPPORT_PLAN_URL,
  GET_SUPPORT_PLAN_LIST_URL,
  DELETE_SUPPORT_PLAN_URL,
  POST_QUALITY_SURVEY_URL,
  GET_QUALITY_SURVEY_URL,
  GET_QUALITY_SURVEY_QUESTIONS_BY_CAT_URL,
  UPDATE_QUALITY_SURVEY_URL,
  DELETE_QUALITY_SURVEY_URL,
  GET_ALL_GROUPS_MESSAGES_URL,
  POST_CREATE_GROUP_CHAT_URL,
  GET_INDIVIDUAL_GROUP_MESSAGES_URL,
  GET_ALL_COMBINED_MESSAGES_URL,
  POST_SEND_MESSAGE_IN_GROUP_URL,
  PUT_SET_UPDATE_GROUP_SCHEDULE_TIMING_URL,
  PUT_UPDATE_GROUP_DETAILS_URL,
  PUT_UPDATE_GROUP_MESSAGE_DETAILS_URL,
  PUT_REMOVE_MEMBER_FROM_GROUP_URL,
  GET_GROUP_INFO_URL,
  DELETE_GROUP_MESSAGE_URL,
  PUT_GROUP_PROFILE_PICTURE_URL,
  POST_SU_DOCUMENTS_URL,
  GET_SU_DOCUMENTS_URL,
  UPDATE_SU_DOCUMENTS_URL,
  DELETE_SU_DOCUMENTS_URL,
  UPLOAD_SU_DOCUMENTS_FILE,
  DELETE_ROTA_SCHEDULE_OPEN_SHIFT_URL,
  PUT_UPDATE_ROTA_OPEN_PATIENT_SHIFT_SCHEDULE_URL,
  DELETE_ROSTER_SCHEDULE_SHIFT_DAY_URL,
  DELETE_ROSTER_SCHEDULE_OPEN_SHIFT_URL,
  POST_LIVE_ROSTER_DATA_URL,
  GET_CLAIMED_LIVE_ROSTER_SHIFTS_URL,
  UPDATE_CLAIMED_LIVE_ROSTER_SHIFTS_URL,
  UPDATE_LIVE_ROSTER_LOCATIONS_URL,
  PUT_UPDATE_ROSTER_OPEN_STAFF_SHIFT_SCHEDULE_URL,
  PUT_UPDATE_ROSTER_OPEN_PATIENT_SHIFT_SCHEDULE_URL,
  POST_NEW_ROSTER_TEMPLATE_SCHEDULE_URL,
  POST_TEMPLATE_SHIFTS,
  UPDATE_TEMPLATE_SHIFTS,
  DELETE_TEMPLATE_SHIFTS,
  GET_PAYROLL_LIST
};
