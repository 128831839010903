import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { BASE_URL } from "../../../env";
import ProgressComponent from "../../../components/general/ProgressComponent";

const GroupMembersListing = ({
  handleShowModel,
  handleCloseModel,
  groupName,
  groupMembers,
}) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (groupMembers) {
      setIsLoading(false);
    }
  }, [groupMembers]);

  return (
    <Modal
      show={handleShowModel}
      onHide={handleCloseModel}
      className="ftcModalPopup"
    >
      <Modal.Header closeButton>
        <Modal.Title>Members of {groupName}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {isLoading ? (
          <ProgressComponent />
        ) : (
          <div className="userListAddChat">
            {groupMembers?.map((item, index) => {
              const { profile_picture_url, name } = item;
              return (
                <div className="cards">
                  <div className="cardsUserDetail">
                    <div
                      className="usersNameInfo">
                      <span className="userImage">
                        {profile_picture_url && (
                          <img
                            src={`${BASE_URL}${profile_picture_url}`}
                            alt={name}
                            style={{ maxWidth: "100%" }}
                          />
                        )}
                      </span>
                      <span className="userInfo">
                        <span className="nameBlock">{name}</span>
                        <span className="infoBlock">
                          {item?.email}
                        </span>
                      </span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          className="btn-sm"
          variant="outline-primary"
          onClick={handleCloseModel}
        >
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupMembersListing;
