import React, { useEffect, useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import moment from "moment";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { formatLiveRosterDates } from "../../helper/helperJsx";
import RotaWeeksDropDown from "./RotaWeeksDropDown";

function ParentTab({
  getInitialLocationId,
  templateDataState,
  templateWeekNum,
  activeWeekTabNum,
  fetchAllTempRecords,
}) {
  const renderColorStatus = () => (
    <div className="colorStatus">
      <div className="colorStatusItem">
        <span className="color lightBlue">&nbsp;</span> Published
      </div>
      <div className="colorStatusItem">
        <span className="color green">&nbsp;</span> Future
      </div>
      <div className="colorStatusItem">
        <span className="color lightYellow">&nbsp;</span> Saved
      </div>
    </div>
  );

  const renderTabsHeader = () => (
    <div className="subTabsHeader radioTabsHeader">
      {getInitialLocationId && (
        <>
          <div className="tabsBlocks rangNavigation">
            {
              <RotaWeeksDropDown
                selectedLocationId={getInitialLocationId}
                templateWeekNum={templateWeekNum}
                activeWeekTabNum={activeWeekTabNum}
              />
            }
          </div>
          <div className="tabsBlocks tBlocks">
            <div className=""></div>
          </div>
          <div className="tabsBlocks">{renderColorStatus()}</div>
        </>
      )}
    </div>
  );

  return (
    <div className="mainContent p-0">
      <div className="subTabsWrapper">
        {getInitialLocationId && (
          <Tab.Container id="myTab" defaultActiveKey="weekly">
            {renderTabsHeader()}
          </Tab.Container>
        )}
      </div>
    </div>
  );
}

export default ParentTab;
