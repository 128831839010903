import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addScheduleWeekDays } from "../../schemas";
import { useFormik } from "formik";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_URL, ADMIN_ID } from "../../env";
import moment from 'moment';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import MedicineListDetail from "../../components/forms-fields/MedicineListDetail";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import CustomSelectServiceUsers from "../../components/forms-fields/CustomSelectServiceUsers";
import TextArea from "../../components/forms-fields/TextArea";
import { Form } from "react-bootstrap";

function EditRosterSchedule({
  shiftDetail,
  shiftId,
  staffId,
  serviceUserId,
  shiftLocationId,
  weekNumberLevel3,
  subModalEditUserShift,
  dayName,
  templateId }) {
  // console.log("🚀 ~ weekNumberLevel3:", weekNumberLevel3)
  // console.log("🚀 ~ EditRosterSchedule ~ shiftDetail:", shiftDetail)

  const [currentTemplateId, setCurrentTemplateId] = useState(templateId)
  useEffect(() => {
    setCurrentTemplateId(templateId)
  }, [templateId])
  const [dayNameField, setDayNameField] = useState(dayName);
  useEffect(() => {
    setDayNameField(dayName)
  })
  const handleShowHideModal = () => {
    subModalEditUserShift((prevShow) => !prevShow);
  };

  const [locationId, setLocationId] = useState(shiftLocationId)
  useEffect(() => {
    setLocationId(shiftLocationId)
  }, [shiftLocationId])

  const [rotaShiftId, setRotaShiftId] = useState(shiftId)
  useEffect(() => {
    setRotaShiftId(shiftId)
  }, [shiftId])

  const [weekNumber, setWeekNumber] = useState(weekNumberLevel3);
  // console.log("🚀 ~ EditRosterSchedule ~ weekNumberLevel3:", weekNumber)
  useEffect(() => {
    setWeekNumber(weekNumberLevel3);
  }, [weekNumberLevel3])

  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastEditUserShift, setIsToastEditUserShift] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [medicationData, setMedicationData] = useState([]);
  const [medicationFilteredData, setMedicationFilteredData] = useState([]);
  const [durationHours, setDurationHours] = useState(0);
  const [durationMinutes, setDurationMinutes] = useState(0);
  const [aptMinutes, setAptMinutes] = useState("");

  const [selectedUserId, setSelectedUserId] = useState(staffId);

  useEffect(() => {
    setSelectedUserId(staffId)
    setFieldValue("staffUserId", staffId);
  }, [staffId])
  const handleUserSelect = async (staffId) => {
    setSelectedUserId(staffId)
    setFieldValue("staffUserId", staffId);
  }

  const [selectedServiceUserId, setSelectedServiceUserId] = useState(serviceUserId);

  useEffect(() => {
    setSelectedServiceUserId(serviceUserId)
    setFieldValue("serviceUserId", serviceUserId);
  }, [serviceUserId])

  const handleServiceUserSelect = async (serviceUserId) => {
    setSelectedServiceUserId(serviceUserId)
    setFieldValue("serviceUserId", serviceUserId);
  }



  const daysOptions = [
    { value: 'Mon', label: 'Monday' },
    { value: 'Tue', label: 'Tuesday' },
    { value: 'Wed', label: 'Wednesday' },
    { value: 'Thu', label: 'Thursday' },
    { value: 'Fri', label: 'Friday' },
    { value: 'Sat', label: 'Saturday' },
    { value: 'Sun', label: 'Sunday' }
  ];

  const weeksOptions = [
    { value: 1, label: 'Week 1' },
    { value: 2, label: 'Week 2' },
    { value: 3, label: 'Week 3' },
    { value: 4, label: 'Week 4' },
  ];

  const [suFormData, setSuFormData] = useState({
    rosterId: currentTemplateId,
    locationId: locationId,
    shiftId: rotaShiftId,
    weekNo: weekNumber,
    dayName: dayNameField,
    staffUserId: selectedUserId,
    shiftStartTime: moment(shiftDetail?.shiftStartTime, 'HH:mm').format('HH:mm'),
    shiftEndTime: moment(shiftDetail?.shiftEndTime, 'HH:mm').format('HH:mm'),
    shiftDate: shiftDetail?.shiftDate,
    workHrs: shiftDetail?.workHrs,
    note: shiftDetail?.note,
    serviceUserId: selectedServiceUserId,
    isSleepOver: shiftDetail?.isSleepOver,
    isOverNightStay: shiftDetail?.isOverNightStay,
    addedby: ADMIN_ID,
    lastModifiedBy: ADMIN_ID,
    active: true,
    isDelete: false,
    staffAssigned: true,
    serviceUserAssigned: true
  });
  useEffect(() => {
    console.log("🚀 ~ suFormData:", suFormData)
  }, [suFormData])
  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({

    initialValues: suFormData,
    validationSchema: addScheduleWeekDays,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postEditUserShiftFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastEditUserShift(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (
      method === "postEditUserShiftFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastEditUserShift(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postEditUserShiftFormData = catchAsync(async (req, res) => {
    const updateValues = {
      ...values,
      rosterId: currentTemplateId,
      locationId: locationId,
      workHrs: values?.isSleepOver ? 0 : durationHours,
      dayName: dayNameField,
      weekNo: weekNumber,
    }
    console.log("🚀 ~ postEditUserShiftFormData ~ values:", updateValues)
    const response = await postRecords(POST_UPDATE_INDIVIDUAL_ROSTER_SHIFT_URL, updateValues);
    console.log("🚀 ~ postEditUserShiftFormData ~ response:", response)
    processPostRecords("postEditUserShiftFormData", response);
    setIsSubmitButtonClicked(false);
  });




  const [selectedShiftSchedule, setSelectedShiftSchedule] = useState('');

  useEffect(() => {
    const startTime = moment(shiftDetail?.shiftStartTime, 'HH:mm').format('HH:mm');
    const endTime = moment(shiftDetail?.shiftEndTime, 'HH:mm').format('HH:mm');

    if (startTime === '10:00' && endTime === '16:00') {
      setSelectedShiftSchedule('10 AM to 4 PM');
    } else if (startTime === '08:00' && endTime === '14:00') {
      setSelectedShiftSchedule('8 AM to 2 PM');
    } else if (startTime === '14:00' && endTime === '20:00') {
      setSelectedShiftSchedule('2 PM to 8 PM');
    } else if (startTime === '08:00' && endTime === '20:00') {
      setSelectedShiftSchedule('8 AM to 8 PM');
    } else if (startTime === '20:00' && endTime === '08:00') {
      setSelectedShiftSchedule('8 PM to 8 AM');
    } else {
      setSelectedShiftSchedule('');
    }
  }, [shiftDetail]);

  const handleShiftScheduleTimeChange = (event) => {
    const selectedTime = event.target.value;
    let startTime = '';
    let endTime = '';

    switch (selectedTime) {
      case '10 AM to 4 PM':
        startTime = '10:00';
        endTime = '16:00';
        break;
      case '8 AM to 2 PM':
        startTime = '08:00';
        endTime = '14:00';
        break;
      case '2 PM to 8 PM':
        startTime = '14:00';
        endTime = '20:00';
        break;
      case '8 AM to 8 PM':
        startTime = '08:00';
        endTime = '20:00';
        break;
      case '8 PM to 8 AM':
        startTime = '20:00';
        endTime = '08:00';
        break;
      default:
        startTime = '';
        endTime = '';
    }

    setFieldValue('shiftStartTime', startTime);
    setFieldValue('shiftEndTime', endTime);
    setSelectedShiftSchedule(selectedTime);
  };


  useEffect(() => {
    const startTime = moment(values.shiftStartTime, 'HH:mm').toDate();
    const endTime = moment(values.shiftEndTime, 'HH:mm').toDate();

    if (moment(startTime, 'HH:mm').format('HH:mm') === "20:00" && moment(endTime, 'HH:mm').format('HH:mm') === "08:00") {
      setDurationHours(12);
    } else if ((startTime !== NaN) && (endTime !== NaN)) {
      const durationInMilliseconds = endTime - startTime;
      // console.log("🚀 ~ file: EditAppointments.jsx:107 ~ useEffect ~ durationInMilliseconds:",  )

      const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
      const minutes = Math.floor((durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60));
      const durationInMinutes = durationInMilliseconds / (1000 * 60);
      setAptMinutes(durationInMinutes);
      setDurationHours(hours);
      setDurationMinutes(minutes);
    }
    else {
      setDurationHours(0);
      setDurationMinutes(0);
      setAptMinutes(0);
    }
  }, [values.shiftStartTime, values.shiftEndTime]);
  // console.log("asd fsd", values?.dayName)
  return (
    <>
      <div className="incidents consentCapacityAddNew">
        {/* {staffId}<br />
        {serviceUserId}<br /> */}

        <form onSubmit={handleSubmit}>
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-12">
                <div className="form-group">
                  <div className="selectBoxLevel shiftSchedule">
                    <span className="multiSelectCustomLabel disabledLabel">Day <span className="text-danger">*</span></span>
                    <Select
                      name="dayName"
                      isMulti={false}
                      options={daysOptions}
                      defaultValue={daysOptions.find(option => option.value === dayNameField)}
                      isDisabled={true}
                    />
                    {errors.dayName && touched.dayName && (
                      <div className="text-danger">{errors.dayName}</div>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="form-group">
                  <div className="selectBoxLevel shiftSchedule">
                    <span className="multiSelectCustomLabel disabledLabel">Week <span className="text-danger">*</span></span>
                    <Select
                      name="weekNo"
                      isMulti={false}
                      options={weeksOptions}
                      defaultValue={weeksOptions.find(option => option.value === weekNumber)}
                      isDisabled={true}
                    />
                    {errors.weekNo && touched.weekNo && (
                      <div className="text-danger">{errors.weekNo}</div>
                    )}
                  </div>
                </div>

              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <CustomSelect
                    id=""
                    name="staffUserId"
                    onSelect={handleUserSelect}
                    defaultValue={selectedUserId}
                    filterBy=""
                    label="Staff Member"
                    customError={touched?.staffUserId && errors?.staffUserId}
                    errorMessage={errors?.staffUserId}
                    errorClass={
                      touched?.staffUserId && errors?.staffUserId
                        ? "validationError"
                        : ""
                    }
                  // isDisabled={staffId ? true : false}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <CustomSelectServiceUsers
                    id=""
                    name="serviceUserId"
                    onSelect={handleServiceUserSelect}
                    defaultSelectedValue={selectedServiceUserId}
                    filterBy=""
                    label="Service User"
                    customError={touched?.serviceUserId && errors?.serviceUserId}
                    errorMessage={errors?.serviceUserId}
                    errorClass={
                      touched?.serviceUserId && errors?.serviceUserId
                        ? "validationError"
                        : ""
                    }
                  // isDisabled={serviceUserId ? true : false}
                  />
                </div>
              </div>

              <div className="col-md-6">
                <Input
                  type="time"
                  id="shiftStartTime"
                  name="shiftStartTime"
                  value={values?.shiftStartTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Shift Start Time"
                  marginBottom={true}
                  customError={touched?.shiftStartTime && errors?.shiftStartTime}
                  errorMessage={errors?.shiftStartTime}
                  erroClass={
                    touched?.shiftStartTime && errors?.shiftStartTime
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>

              <div className="col-md-6">
                <Input
                  type="time"
                  id="shiftEndTime"
                  name="shiftEndTime"
                  value={values?.shiftEndTime}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Shift End Time"
                  marginBottom={true}
                  customError={touched?.shiftEndTime && errors?.shiftEndTime}
                  errorMessage={errors?.shiftEndTime}
                  erroClass={
                    touched?.shiftEndTime && errors?.shiftEndTime
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>

              <div className="col-md-12">
                <p>
                  <strong><small>Shift duration {durationHours} hours and {durationMinutes} minutes</small> </strong>
                </p>
              </div>
              <div className="col-md-12">
                <div className="addScheduleTiming">
                  <div>
                    <Form.Check
                      type="radio"
                      name="shiftScheduleTime"
                      value="10 AM to 4 PM"
                      label="10 AM to 4 PM"
                      checked={selectedShiftSchedule === '10 AM to 4 PM'}
                      onChange={handleShiftScheduleTimeChange}
                    />
                  </div>
                  <div>
                    <Form.Check
                      type="radio"
                      name="shiftScheduleTime"
                      value="8 AM to 2 PM"
                      label="8 AM to 2 PM"
                      checked={selectedShiftSchedule === '8 AM to 2 PM'}
                      onChange={handleShiftScheduleTimeChange}
                    />
                  </div>
                  <div>
                    <Form.Check
                      type="radio"
                      name="shiftScheduleTime"
                      value="2 PM to 8 PM"
                      label="2 PM to 8 PM"
                      checked={selectedShiftSchedule === '2 PM to 8 PM'}
                      onChange={handleShiftScheduleTimeChange}
                    />
                  </div>
                  <div>
                    <Form.Check
                      type="radio"
                      name="shiftScheduleTime"
                      value="8 AM to 8 PM"
                      label="8 AM to 8 PM"
                      checked={selectedShiftSchedule === '8 AM to 8 PM'}
                      onChange={handleShiftScheduleTimeChange}
                    />
                  </div>
                  <div>
                    <Form.Check
                      type="radio"
                      name="shiftScheduleTime"
                      value="8 PM to 8 AM"
                      label="8 PM to 8 AM"
                      checked={selectedShiftSchedule === '8 PM to 8 AM'}
                      onChange={handleShiftScheduleTimeChange}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <div className="addScheduleTiming">
                  <div>
                    <Form.Check
                      type="checkbox"
                      name={'overNightStatus'}
                      value={values?.isOverNightStay}
                      checked={values?.isOverNightStay}
                      label={'Waking Night'}
                      onChange={() => {
                        setFieldValue('isOverNightStay', !values?.isOverNightStay);
                      }}
                    />
                  </div>
                  <div>
                    <Form.Check
                      type="checkbox"
                      name={'overNightStatus'}
                      value={values?.isSleepOver}
                      checked={values?.isSleepOver}
                      label={'Overnight Sleep'}
                      onChange={() => {
                        setFieldValue('isSleepOver', !values?.isSleepOver);
                      }}
                    />
                  </div>
                </div>
              </div>

              <div className="col-md-12">
                <TextArea
                  id="note"
                  name="note"
                  value={values?.note}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Note (Optional)"
                  rows="3"
                  className="mb-3"
                  customError={errors.note && touched.note}
                  errorMessage={errors.note}
                  erroClass={errors.note && touched.note}

                />
              </div>
            </div>
          </div>

          <div className="formActionButtons">
            <span
              onClick={handleShowHideModal}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastEditUserShift && <ToastContainer />}
    </>
  );
}

export default EditRosterSchedule