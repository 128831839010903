// useAuthorization.js
import { rolePermissions } from './rolePermissions';

const useAuthorization = (userRoles, requiredPermissions) => {
  // Handle cases where userRoles is empty
  if (!userRoles.length) {
    return false;
  }

  // Combine permissions for all roles
  const userPermissions = userRoles.reduce((acc, role) => {
    const permissions = rolePermissions[role] || [];
    return [...acc, ...permissions];
  }, []);

  // console.log("🚀 ~ useAuthorization ~ userRoles:", userRoles);
  // console.log("🚀 ~ useAuthorization ~ userPermissions:", userPermissions);
  // console.log("🚀 ~ useAuthorization ~ requiredPermissions:", requiredPermissions);

  // Check if user has at least one of the required permissions
  return requiredPermissions.some((permission) =>
    userPermissions.includes(permission)
  );
};

export default useAuthorization;
