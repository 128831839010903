import React from "react";
import ScheduleBlocks from "../../components/general/ScheduleBlocks";
import clockSnooze from "../../assets/images/icons/clockSnooze.svg";
import folderTick from "../../assets/images/icons/folderTick.svg";
import folder from "../../assets/images/icons/folder.svg";
import database from "../../assets/images/icons/database.svg";

function TrainingBlocks({ currentStatus }) {
  return (
    <>
      <div className="scheduleBlocks trainingBlocks">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={folder}
              heading={"10"}
              subHeading={"Assigned"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={folderTick}
              heading={"05"}
              subHeading={"Completed"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={clockSnooze}
              heading={"03"}
              subHeading={"Over Due"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={database}
              heading={"10"}
              subHeading={"Total"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default TrainingBlocks;
