import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
// import UserDetailReview from "../profile/UserDetailReview";
import { Link, useLocation, useNavigate } from "react-router-dom";
import questionEdit from "../../assets/images/icons/questionEdit.svg";
import deleteBasket from "../../assets/images/icons/deleteBasket.svg";
import actionButtonDel from "../../assets/images/icons/actionButtonDel.svg";
import actionButtonTick from "../../assets/images/icons/actionButtonTick.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import {
  BASE_URL,
  GET_CONSENT_TEMPLATE_URL,
  DELETE_CONSENT_TEMPLATE_URL,
  UPDATE_CONSENT_TEMPLATE_URL,
} from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  formateDate,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  updateRecords,
  handleRedirectIfProfileNotPresent
} from "../../helper/helper";
import ProgressComponent from "../../components/general/ProgressComponent";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TextArea from "../../components/forms-fields/TextArea";
import moment from 'moment';

function ConsentCapacity() {
  // Getting user detail data
  // const location = useLocation();
  // const userDetails = location.state?.userDetails[0];

  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  console.log("🚀 ~ ConsentCapacity ~ userDetails:", userDetails)

  const navigate = useNavigate();

  useEffect(() => {
    handleRedirectIfProfileNotPresent('profileUserDetails', '/service-users', navigate)
  }, [navigate]);

  // General Const

  const [show, setShow] = useState(false);
  const [dataID, setDataID] = useState({ templateId: null });
  const [isEditable, setIsEditable] = useState(false);
  const [editableRow, setEditableRow] = useState(null);
  const [answer, setAnswer] = useState("");
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToast, setIsToast] = useState(false);
  const handleModalEvent = () => setShow(!show);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    staffId: userDetails?.modelId?._id,
  };
  const [isLoading, setIsLoading] = useState(true);
  const [allRecords, setRecords] = useState([]);
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [recordsUpdated, setRecordsUpdated] = useState(false);
  const [addReviewForm, setAddReviewForm] = useState(false);
  const [consentCapacityListData, setConsentCapacityListData] = useState([]);

  const handleRemoveModalEvent = (templateId) => {
    setShow(!show);
    setDataID({ templateId: templateId });
  };

  const toggleAddReviewForm = () => {
    setAddReviewForm(!addReviewForm);
  };
  const processReviewData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;
      // console.log("my outstanding consent list: ", allData);
      if (allData && allData.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);
        setRecords(allData);
        setConsentCapacityListData(allData);
      }
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    const getConsentCapacity = await getRecords(GET_CONSENT_TEMPLATE_URL, apiQuery);

    processReviewData("get", getConsentCapacity);
    console.log("review:", getConsentCapacity?.data);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Remove Review

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      console.log({ data });
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (data?.status === isSuccess) {
      setIsToast(true);
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
  };
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (dataID !== "") {
      const response = await deleteRecords(DELETE_CONSENT_TEMPLATE_URL, dataID);
      setShow(!show);
      processFetchRecords("deleteReview", response);
      console.log("review response: ", response);
      setIsSubmitButtonClicked(false);
      fetchAllRecords();
    } else {
      setShow(!show);
      toastErrorMessages(toast?.error, "Unable to remove review", 1000);
    }
  });

  const editAnswer = (itemId) => {
    setEditableRow(itemId === editableRow ? null : itemId);
  };

  // Update Review

  const updateConsentCapacity = (method, data) => {
    console.log(data);
    if (data?.status !== isSuccess) {
      setIsToast(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    }
    if (method === "updateConsentCapacityData" && data?.status === isSuccess) {
      setIsToast(true);
      setIsSubmitButtonClicked(false);
      toastSuccessMessages(toast?.success, data?.message, "", 500, 500);
    }
  };

  const submitUpdatedReview = async (itemId, updatedReview) => {
    const updateApiQuery = {
      templateId: itemId,
      description: updatedReview,
    };
    const response = await updateRecords(
      UPDATE_CONSENT_TEMPLATE_URL,
      updateApiQuery
    );
    updateConsentCapacity("updateConsentCapacityData", response);
    console.log("response: ", response);
    fetchAllRecords();
  };

  return (
    <>
      <Layout pageTitleText="Consent & Capacity">
        <div className="incidents">
          <ProtectedComponent requiredPermissions={[permissions.ADD]}>
            <header className="headerContainerSearchAction">
              <div className="actionButtons">
                <Link
                  to="/add-consent-capacity"
                  className="btn btn-sm btn-primary"
                >
                  Create New Form
                </Link>
              </div>
            </header>
          </ProtectedComponent>
          <div className="mainContent ">
            <div className="mainContentText">
              <p className="mb-1">
                <strong>Name: </strong> {userDetails?.modelId?.suFirstMiddleName}{" "}
                {userDetails?.modelId?.suLastName}
              </p>
              <p className="mb-1">
                <strong>DOB: </strong>{" "}
                {moment(userDetails?.modelId?.suDOB).format("DD/MM/YYYY")}
              </p>
              <p className="mb-1">
                <strong>Gender: </strong> {userDetails?.modelId?.suSex}
              </p>
              <p className="mb-1">
                <strong>Contact No: </strong> {userDetails?.modelId?.suMobileHomeNo}
              </p>
              <p className="mb-2">
                <strong>Email: </strong> {userDetails?.modelId?.suEmailHome}
              </p>

              <div className="reviewContent">
                <div className="questionWrapper pt-0">
                  {/* Review Comment  */}
                  {noRecordFound ? (
                    <>
                      <div className="noRecordFound">
                        <p>No records found.</p>
                      </div>
                    </>
                  ) : isLoading ? (
                    <ProgressComponent />
                  ) : (
                    <>
                      <div className="requestContentCards pt-0">
                        <div className="answer">
                          {consentCapacityListData.map((item, index) => (
                            <>
                              {/* {setAnswer(item?.description)} */}
                              <div className="profileBox" key={index}>

                                <div className="contentBox">
                                  {editableRow === item?._id ? (
                                    <>
                                      <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                                        <textarea
                                          id={item?._id}
                                          name={item?._id}
                                          readOnly={editableRow !== item?._id}
                                          className="editableTextArea"
                                          value={
                                            editableRow === item?._id
                                              ? answer
                                              : item?.description
                                          }
                                          onChange={(e) =>
                                            setAnswer(e.target.value)
                                          }
                                        />
                                        <div className="editableActionButton">
                                          <span
                                            className="editQuestion"
                                            onClick={() => {
                                              editAnswer(item?._id);
                                              setAnswer(item?.description);
                                              submitUpdatedReview(
                                                item?._id,
                                                answer
                                              );
                                            }}
                                          >
                                            <img
                                              src={actionButtonTick}
                                              alt="Tick Answer"
                                            />
                                          </span>
                                          <span
                                            className="addQuestion"
                                            onClick={() => {
                                              editAnswer(item?._id);
                                            }}
                                          >
                                            <img
                                              src={actionButtonDel}
                                              alt="Close"
                                            />
                                          </span>
                                        </div>
                                      </ProtectedComponent>
                                    </>
                                  ) : null}

                                  <p>
                                    {editableRow !== item?._id ? (
                                      <>{item?.description} </>
                                    ) : null}

                                    <small>
                                      {moment(item?.updatedAt).format("DD/MM/YYYY / hh:mm:ss A")}

                                    </small>
                                  </p>
                                </div>

                                <ProtectedComponent requiredPermissions={[permissions.DELETE, permissions.EDIT]}>
                                  <div className="actionButtons">
                                    <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                                      <span
                                        className="editQuestion"
                                        onClick={() => {
                                          setAnswer(item?.description);
                                          editAnswer(item?._id);
                                        }}
                                      >
                                        <img
                                          src={questionEdit}
                                          alt="Edit Question"
                                        />
                                      </span>
                                    </ProtectedComponent>
                                    <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
                                      <span
                                        className="delIcon"
                                        onClick={() => {
                                          handleRemoveModalEvent(item?._id);
                                        }}
                                      >
                                        <img
                                          src={deleteBasket}
                                          alt="Delete Question"
                                          title="Delete"
                                        />
                                      </span>
                                    </ProtectedComponent>
                                  </div>
                                </ProtectedComponent>
                              </div>
                            </>
                          ))}
                        </div>
                      </div>
                    </>
                  )}
                  {/* Review comment end */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={show}
          onHide={handleModalEvent}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this record?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}


            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={handleModalEvent}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isToast && <ToastContainer />}
    </>
  );
}

export default ConsentCapacity;
