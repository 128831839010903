const sampleDataAboutPlan = `
<p>Jack is a 19-year-old young man, who lives at home with his mum (Kerry) and brother (Freddie), aged
    7, who also has Autism. Jack sees his dad (Mike) regularly, he does not live with him but supports his
    family. He is often at the house when staff drop Jack home. Jack also sees his maternal Grandma and
    Uncle (James) regularly, who is often called to help in a physical incident at home.
    Jack has a diagnosis of Moderate Learning Difficulties, Severe Autism Spectrum Disorder, and
    evidence of Disturbance of Hyperactivity and impulsively (ADHD). Jack also experiences sleep related
    difficulties.</p>
<p>Jack can be shy at first but when he feels more comfortable, he will approach you more. Jack likes
    being in the garden, upcycling things, watching TV, playing on the iPad, researching foreign movies,
    and chatting to staff about Harry Potter, foreign languages, and VHS tapes.
    Jack often repeats things and phrases he has heard / things from the past, and he loves it when
    people engage in role play with him.
</p>
<p>Jack used to go to the Apperley Centre for education, however due to his behaviours being difficult to
    manage there, this stopped, and he now receives an education provision from FTCS.
    Jack likes his own space and will say “all done” or “all fine” or “you can leave now” to indicate he
    wants space or does not want to continue the conversation. Whilst travelling with staff in the car, Jack
    will often opt to sit in the front but can sometimes choose to sit in the back. Jack will need reminding
    to be careful of other cars when opening his door, however he does have a blue badge so please
    utilise a disabled space.
</p>
<p>Jack has previously received support on a 3:1 staffing ratio decreasing to a 2:1 staffing ratio. Jack is
    now receiving support on a 1:1 staff basis due to a steep decline in target behaviour.
</p>
<hr>
<h3>Aim of Positive Behaviour Support Plan </h3>
<p>The aim of the positive behaviour support plan in implementation is to promote alternative coping
    mechanisms for Jack in times of crisis.
</p>
<ul>
    <li><span style="font-size: 14px;">To enable Jack to access the community and engage in activities.</span></li>
    <li><span style="font-size: 14px;">To reduce target behaviours.</span></li>
    <li><span style="font-size: 14px;">Promote emotional wellbeing and managing ongoing heightened behaviours.</span></li>
    <li><span style="font-size: 14px;">Promote positive relationships with those around Jack and encouraging positive family
            relationships.</span></li>
</ul>
<hr>
<h3>Setting Events (Slow triggers)</h3>
<ul>
    <li><span style="font-size: 14px;">Constipation</span></li>
    <li><span style="font-size: 14px;">Absence of his brother e.g., when he is at school and Jack is at home.</span></li>
    <li><span style="font-size: 14px;">In growing toenail</span></li>
    <li><span style="font-size: 14px;">Illness</span></li>
    <li><span style="font-size: 14px;">Underlying anxieties</span></li>
    <li><span style="font-size: 14px;">Busy or crowded environments</span></li>
    <li><span style="font-size: 14px;">Lack of closure of past events</span></li>
    <li><span style="font-size: 14px;">Historical DVD’s</span></li>
    <li><span style="font-size: 14px;">Historical events (available in history books).</span></li>
</ul>
<hr>
<h3>Discriminative Stimuli (Fast triggers)</h3>
<ul>
    <li><span style="font-size: 14px;">Recalling a past event / missed opportunity.</span></li>
    <li><span style="font-size: 14px;">Over-excitement</span></li>
    <li><span style="font-size: 14px;">Being asked to turn his iPad down.</span></li>
    <li><span style="font-size: 14px;">Frustration.</span></li>
    <li><span style="font-size: 14px;">His education timetable being changed / the Butterfly Centre being cancelled.</span></li>
    <li><span style="font-size: 14px;">Being asked to do something difficult.</span></li>
    <li><span style="font-size: 14px;">Loud music / radio (of music he doesn’t like)</span></li>
    <li><span style="font-size: 14px;">Being interrupted whilst e.g., on his iPad</span></li>
    <li><span style="font-size: 14px;">People in his personal space.</span></li>
    <li><span style="font-size: 14px;">Mention of “Stroud Gym”.</span></li>
    <li><span style="font-size: 14px;">Planned activities being cancelled.</span></li>
    <li><span style="font-size: 14px;">Waiting for others/in queues.</span></li>
    <li><span style="font-size: 14px;">Others not paying attention to Jack.</span></li>
    <li><span style="font-size: 14px;">Unexpected change.</span></li>
    <li><span style="font-size: 14px;">Being told he can’t go somewhere he has requested.</span></li>
    <li><span style="font-size: 14px;">Not understanding what to do.</span></li>
    <li><span style="font-size: 14px;">Ruminating on past/missed events.</span></li>
    <li><span style="font-size: 14px;">If Jack notices an abrasion on himself</span></li>
    <li><span style="font-size: 14px;">If people Jack is communicating to, do not understand what he is talking about / meaning.</span></li>
    <li><span style="font-size: 14px;">Not understanding what he is doing / where he’s going.</span></li>
    <li><span style="font-size: 14px;">Demands/requests to do something he doesn’t want to do.</span></li>
    <li><span style="font-size: 14px;">Something being taken from him.</span></li>
    <li><span style="font-size: 14px;">Noises made by others /brother shouting.</span></li>
    <li><span style="font-size: 14px;">Changes to his routine or the order in which he completes his routine.</span></li>
    <li><span style="font-size: 14px;">Denied access to items/activities/food.</span></li>
</ul>
<hr>
<h3>Behaviour of concern
</h3>
<p><strong>Self-Injurious behaviour: </strong></p>
<p>Jack displays self-injurious behaviour, typically:
</p>
<ul>
    <li><span style="font-size: 14px;">Biting his lower arms</span></li>
    <li><span style="font-size: 14px;">Biting the skin around his fingers</span></li>
    <li><span style="font-size: 14px;">Biting his fingernails until they bleed</span></li>
    <li><span style="font-size: 14px;">Picking scabs off wounds</span></li>
    <li><span style="font-size: 14px;">Picking his toenails off.</span></li>
</ul>
<p><strong>Verbal &amp; Physical Aggression:</strong></p>
<p> When heightened, Jack can display verbal aggression towards staff, such as:</p>
<ul>
    <li> <span style="font-size: 14px;">Screaming</span></li>
    <li><span style="font-size: 14px;">Shouting</span></li>
</ul>
<p>Jack has also been known to display physical aggression towards staff, such as:
</p>
<ul>
    <li><span style="font-size: 14px;">Biting</span></li>
    <li><span style="font-size: 14px;">Punching</span></li>
    <li><span style="font-size: 14px;">Grabbing</span></li>
    <li><span style="font-size: 14px;">Charging</span></li>
</ul>
<p><strong>Repetitive Behaviour:</strong> </p>
<p>Jack is known to repeat questions around events that have not taken place, particularly Billy Elliot
    anniversary being cancelled due to ‘bad year 2020’ (COVID 19), this will often further distress him.</p>
<p>Jack is known to frequently ask “what is your first film”, this is Jack asking what the first film is you
    have ever watched. Jack will become frustrated if you cannot recall this detail or if he doesn’t
    know the date the film was released. </p>
<p>Jack is known to pace back and forth when heightened. Jack will usually do this with his fingers in
    his ears.</p>
`;

const sampleDataMOBP = `
<p><strong>Primary prevention- proactive</strong></p>
<p><strong>When Jack is calm:</strong>
</p>
<ul>
                    <li><span style="font-size: 14px;">Jack will ask questions and wait for a response</span></li>
                    <li><span style="font-size: 14px;">Jack is full of energy and presents restlessness daily</span></li>
                    <li><span style="font-size: 14px;">When out for a meal, Jack spends his time looking around. He will put his fingers in his
                    ears and can makes lots of noise</span></li>
                    <li><span style="font-size: 14px;">Jack will smile and laugh</span></li>
                    <li><span style="font-size: 14px;">Jack enjoys playing on his tablet</span></li>
                    <li><span style="font-size: 14px;">Jack enjoys watching the TV</span></li>
                    <li><span style="font-size: 14px;">Jack will talk about topics of interest such as films and the cinema.</span></li>
                    <li><span style="font-size: 14px;">Jack likes to cook his own meals such as homemade curry, fish and chips and baking
                        desserts such as cakes.</span></li>
                        <li><span style="font-size: 14px;">Jack likes to access museums as he enjoys learning about history and dates of events
                        that have happened.</span></li>
                        <li><span style="font-size: 14px;">Jack enjoys going bowling and likes using the amusements such as pinball and money
                        dropping machines.</span></li>
                        <li><span style="font-size: 14px;">Jack loves going to Weston Super Mare, particularly enjoy going to the pier and using
                        the amusements and rides.</span></li>
                        <li><span style="font-size: 14px;">Jack also enjoys going to the Butterfly Garden in Cheltenham</span></li>
                        <li><span style="font-size: 14px;">Jack likes to take part in role play with staff and will frequently recite lines from his
                        favourite films.</span></li>
                        <li><span style="font-size: 14px;">Jack will speak out loud to himself about various dates and DVD’s.</span></li>
                </ul>
<p><strong>Reducing likelihood of target behaviour occurring:</strong></p>
<p><strong>Environmental</strong>
</p>
<ul>
    <li><span style="font-size: 14px;">Jack prefers low stimulus environments that are quiet, calm, and relaxed, it is important
            to make Jack aware of any unexpected noises.</span></li>
    <li><span style="font-size: 14px;">Avoid taking Jack to loud, busy, and crowded environments.</span></li>
    <li><span style="font-size: 14px;">Provide Jack with large open spaces to move around freely, such as by going on walks,
            visiting the park, Weston beach. Offer Jack regular opportunities to access outdoor space.</span></li>
    <li><span style="font-size: 14px;">Predictable routines are important for Jack including the timings of when he has his meals
            and snacks. Jack can become anxious if he doesn’t know what is happening next.</span></li>
</ul>
<p><strong>Communication</strong></p>
<ul>
    <li> <span style="font-size: 14px;">If Jack is presenting as anxious or upset, keep verbal information to a minimum. Giving no
            more than 2 instructions at a time e.g. ‘take your shoes off and wash your hands’</span></li>
    <li><span style="font-size: 14px;">Ensure you communicate with Jack using clear and concise language, using sentences
            containing no more than 3 key words at a time. Repetition will help Jack to process
            information, although ensure to allow plenty of time in between verbal instruction, to
            prevent overload.</span></li>
    <li><span style="font-size: 14px;">Refer to yourself in the third person e.g. ‘Can Jess sit next to Jack?’ rather than ‘Can I sit
            next to you?’.</span></li>
    <li><span style="font-size: 14px;">Establish joint attention by saying Jack’s name before giving him an instruction. Ensure
            your instructions are explicit and rephrase information if Jack appears not to have
            understood what you have said/asked him to do.</span></li>
    <li><span style="font-size: 14px;">Support your own speech with symbols or sign. This will improve Jack’s ability to attend to
            what you have said to him.</span></li>
</ul>
<p><strong>Supporting with transitions:</strong> </p>
<ul>
    <li><span style="font-size: 14px;">Jack experiences a high level of anxiety during transitions and it is important that
            events/activities are communicated clearly to Jack so that he knows what to expect and
            what is coming next.</span></li>
    <li><span style="font-size: 14px;">When supporting the transition from one activity to another, use Jack’s timetable as a visual
            reminder of what is planned. Start by asking Jack what the first activity is. When he has
            completed this, ask Jack to identify what is next.</span></li>
    <li><span style="font-size: 14px;">It is important that Jack does not have to wait long periods of time between the activities
            he is doing as this may act as a trigger. In addition to this if for any reason a diversion is
            made e.g., a stop to the service station on a long journey, this may present as triggering for
            Jack as he has not yet reached his destination and he is required to wait a period. Try and
            schedule in a toilet/fuel stop, communicating this to Jack in advance.</span></li>
</ul>
<p><strong>Daily Routine</strong> </p>
<ul>
    <li><span style="font-size: 14px;">Routine is important to Jack, and he does not respond well to unexplained changes. If
            changes to his routine are made, these should be communicated to Jack at the earliest
            convenience with a rationale and alternative.</span></li>
    <li><span style="font-size: 14px;">Explain to Jack again what is happening and what is expected of Jack. Be specific about
            where you are going i.e., 1 shop to buy X and Y. Communicate what are expected prosocial behaviours according to the activity in which is being created.</span></li>
    <li><span style="font-size: 14px;">Give Jack clear instruction, enabling him to know what to expect and reduce escalations.
            Explain what he will be doing and for how long.</span></li>
    <li><span style="font-size: 14px;">Use Jack’s timetable when carrying out tasks/activities to provide him with predictability
            when taking part in his daily activities.</span></li>
</ul>
<p><strong>Meals</strong></p>
<ul>
    <li><span style="font-size: 14px;">Jack needs supervision and guidance when eating to ensure that he does not put too much
            food in his mouth. Jack can cram food into his mouth and eat too fast.</span></li>
    <li><span style="font-size: 14px;">Staff should verbally prompt Jack to take a minute to finish what is in his mouth before
            taking a further mouthful of what he is eating.
        </span></li>
    <li><span style="font-size: 14px;">Jack will often eat until he is sick as it is reported he doesn’t know when he is full, he
            therefore needs reminding he has already e.g. eaten his lunch.</span></li>
    <li><span style="font-size: 14px;"> Jack is motivated by food and drink during his day e.g. knowing that after he has completed
            a task he will have his snack.</span></li>
    <li><span style="font-size: 14px;">Staff should support Jack with choice with regards to his meal choices, supporting him to
            create his menu for the week before going shopping to purchase the ingredients.</span></li>
    <li><span style="font-size: 14px;">Jack should be reminded to blow his food and drink before eating/drinking as Jack will not
            do this and risks burning himself.</span></li>
</ul>
<p><strong>Health, Personal Hygiene, and emotional wellbeing </strong></p>
<ul>
    <li> <span style="font-size: 14px;">Though Jack is relatively healthy physically, he suffers with ingrown toenails and at times
            may require antibiotics.</span></li>
    <li><span style="font-size: 14px;">Jack experiences a high level of distress, anxiety, and frustration with regards to several
            different events that have not taken place at all or on the date he expected it to. Jack will
            proceed to question those supporting him on these events and why these did not take
            place and requires an appropriate response to provide him with reassurance to be able to
            move on to the next activity / next part of his day.</span></li>
    <li><span style="font-size: 14px;">Jack can struggle to recognise his and others’ emotions and requires greater support with
            this.
        </span></li>
</ul>
<p><strong>Accessing the community</strong> </p>
<ul>
    <li><span style="font-size: 14px;">Prepare Jack ahead of entering an environment that is likely to be busy and noisy, this can
            be a trigger for Jack and result in an increase in anxiety. Ensure that Jack has the
            necessary tools with him to regulate how he is feeling.</span></li>
    <li><span style="font-size: 14px;">It is important that Jack is given clear instructions when he is going out as to what and
            where he is going / going to do. Communicate what are expected pro-social behaviours
            according to the activity in which is being created.</span></li>
    <li><span style="font-size: 14px;">Jack has previously approached members of the public asking them questions on “why”
            something in the community is happening e.g. “why are they filming over there on the
            beach” and has on occasions grabbed members of the public and invaded their personal
            space. Jack should be reminded of personal space and redirected verbally by those
            supporting him that they are “strangers”.</span></li>
    <li><span style="font-size: 14px;">With support, Jack has shown the ability to enter a shop and choose and pay for his
            chosen item e.g., buying his snack and drink before coming to the centre, counting his
            money, and collecting the receipt with the support of staff.</span></li>
</ul>
<p><strong>Social Interaction</strong></p>
<ul>
    <li> <span style="font-size: 14px;">Jack enjoys interacting with those around him, including his support staff and family. He
            enjoys talking about topics of interest such as films/credits and videos that he has
            watched on TV.</span></li>
    <li><span style="font-size: 14px;">Jack also enjoys engaging in ‘role play’ e.g., pretending to buy things from the shop from a
            shop keeper and role playing waking up at “1am” and being told to “get to bed”. Jack
            requires those around him to respond with a consistent response and answer this in the
            correct way or he will become highly anxious and frustrated.</span></li>
    <li><span style="font-size: 14px;">Jack typically gravitates more towards adults, although he will tolerate being in the
            presence of others, he will often then tell people to leave or remark “all fine” when he has
            had enough of any company.</span></li>
</ul>
<p><strong>Sensory Needs</strong>
</p>
<ul>
    <li><span style="font-size: 14px;">Jack is reported to be sensitive to noise and will often generate his own sounds to block
            out the sounds and noise of others. Jack does not like those around him playing the radio
            or their music out loud and he will often request this is turned off.</span></li>
    <li><span style="font-size: 14px;">Jack appears to not like many noises/sounds generated by others and will be more
            tolerant of the noises/sounds he is in control of.</span></li>
    <li><span style="font-size: 14px;">If Jack does not like the sounds around him he will often put his fingers in his ears. Jack is
            to be reminded to take his fingers out of his ears and communicate using his words.</span></li>
</ul>
<p><strong>Access to meaningful activities</strong>
</p>
<ul>
    <li><span style="font-size: 14px;">Jack enjoys engaging in and accessing several external activities within the community, for
            example the cinema, residential trips and going to museums.</span></li>
    <li><span style="font-size: 14px;">Some of Jack’s preferred activities include Going for walks, watching videos on YouTube,
            watching films, going to the cinema, going to the park, and researching different topics. It
            is important that Jack is monitored when accessing the internet to ensure no
            inappropriate material is accessed.</span></li>
    <li><span style="font-size: 14px;">Staff should try to sandwich the least preferred activities between preferred, to
            encourage Jack and to maintain his motivation.</span></li>
</ul>
`;
const sampleDataSecondaryPrevention = `
<p><strong>Indicators Jack is moving away from baseline:</strong>
</p>
<ul>
                <li><span style="font-size: 14px;">Placing his fingers in his ears</span></li>
                <li><span style="font-size: 14px;">Pacing</span></li>
                <li><span style="font-size: 14px;">Excessively chewing on his bite buddy</span></li>
                <li><span style="font-size: 14px;">Repeating ‘all fine,’ ‘cancelled’ or ‘postponed’.</span> </li>
                <li><span style="font-size: 14px;">Crying</span></li>
                <li><span style="font-size: 14px;">Discussing Billy Elliott</span></li>
                <li><span style="font-size: 14px;">Bites his own toes and finger nails.</span></li>
                <li><span style="font-size: 14px;">Squeezing adults/grabbing arms</span></li>
                <li><span style="font-size: 14px;">Pulling adults’ face to face his to gain attention/eye-contact</span></li>
                <li><span style="font-size: 14px;">Running around</span></li>
                <li><span style="font-size: 14px;">Scratching</span></li>
                <li><span style="font-size: 14px;">Hitting</span></li>
                <li><span style="font-size: 14px;">Head-butting</span></li>
                <li><span style="font-size: 14px;">Excessive vocalisation</span></li>
            </ul>

<p><strong>Things to do when Jack displays amber behaviours:</strong>
</p>
<ul>
<li><span style="font-size: 14px;">Reduce / remove known trigger</span></li>
<li><span style="font-size: 14px;">Make the environment calmer (less busy / noisy), especially noise of other people</span></li>
<li><span style="font-size: 14px;">Maintain calm facial expressions and a calm body posture</span></li>
<li><span style="font-size: 14px;">Give Jack time to process requests particularly if anxious.</span></li>
<li><span style="font-size: 14px;">Continue to keep verbal communication concrete and use simple language.</span></li>
<li><span style="font-size: 14px;">Be mindful of your responses to Jack’s repetitive questioning, ignoring Jack can result in an
        increase in escalation and extended responses can have the same effect. Validate what Jack
        has said and then move onto the activity or what is going on around him. Avoid giving Jack
        detailed responses e.g. regarding the specific date and time this will happen in case this
        cannot be honoured, instead reassure Jack you will have a look.</span></li>
<li><span style="font-size: 14px;">Use his name to gain his attention e.g. “Jack, mummy wants to ask a question”. In addition,
        counting back from 5 has proven successful when re-engaging Jack.</span></li>
<li><span style="font-size: 14px;">Keep your voice calm and ‘non-confrontational’.</span></li>
<li><span style="font-size: 14px;">When off baseline Jack responds well to an upbeat ‘jolly’ approach, in addition to one being
        direct/assertive with Jack.</span></li>
<li><span style="font-size: 14px;">When out in the community staff have reported that Jack responds well to sitting down on
        e.g. a bench and having some ‘time out’ which is replicated by staff also having ‘time out’.</span></li>
<li><span style="font-size: 14px;">Encourage him to move away from the trigger of the situation if possible and identifiable.</span></li>
<li><span style="font-size: 14px;">Ensure Jack has his bite buddy available and direct Jack to this if his behaviour is escalating.</span></li>
<li><span style="font-size: 14px;"> Encourage Jack to “be a good boy” (a phrase he uses), encourage him to make good choices
        by directing him to his calming strategies.</span></li>
</ul>
`;
const sampleDataReactiveStartegies = `
<p><strong>Behaviours displayed by Jack in crisis include:</strong>
</p>
<ul>
<li><span style="font-size: 14px;"><strong>Verbal aggression:</strong> High pitched scream, raised voice, shouting</span></li>
<li><span style="font-size: 14px;"><strong>Repetitive questioning and statements:</strong> referring to previous events that have not
        taken place.</span></li>
<li><span style="font-size: 14px;"><strong>Damage to property:</strong> Banging and pushing at the door, often following a lack of
        response.</span></li>
<li><span style="font-size: 14px;"><strong>Physical aggression:</strong> Biting, hitting, scratching, pinching, punching, grabbing, kicking,
        charging, headbutting and using objects to hit others.</span></li>
<li><span style="font-size: 14px;"><strong>Self-injury: </strong>Biting his arm (this can be an indicator to hit others) and hands, feet.
        Pinching himself.</span></li>
<li><span style="font-size: 14px;"><strong>Crying</strong></span></li>
</ul>
<p><strong>Things to try when Jack is in a crisis:</strong></p>
<ul>
<li> <span style="font-size: 14px;">Remain calm and keep a neutral tone of voice and neutral body language.</span></li>
<li><span style="font-size: 14px;">Those supporting Jack must maintain line of sight contact with Jack when he is
        displaying behaviours as he has a history of self-injury and physical aggression.</span></li>
<li><span style="font-size: 14px;">Increase space whilst maintaining observation of Jack.</span></li>
<li><span style="font-size: 14px;"> If safe to do so, create a safe distance between you and Jack and allow him to calm
        down</span></li>
<li><span style="font-size: 14px;">Remain calm and remain in a calmed stance position</span></li>
<li><span style="font-size: 14px;">Encourage others in the immediate area to access another room</span></li>
<li><span style="font-size: 14px;">Always maintain observation of Jack</span></li>
<li><span style="font-size: 14px;">Ensure clear pathways to exit, do not corner yourself in a closed space</span></li>
</ul>
<p><strong>Physical Intervention should only be used at a last resort</strong>
</p>
<ul>
<li><span style="font-size: 14px;">In the instance Jack is at a high risk to others, property and himself physical
        intervention may be used by Team Teach trained members of staff.</span></li>
<li><span style="font-size: 14px;">Physical intervention should only be used to take control of a potentially dangerous
        situation.</span></li>
<li><span style="font-size: 14px;">Physical intervention should be used for the minimum time necessary</span></li>
<li><span style="font-size: 14px;">All physical interventions such be reported and recorded appropriately.</span></li>
<li><span style="font-size: 14px;">An appropriate physical intervention to be used with Jack is a TT single elbow.</span></li>
</ul>
`;
const sampleDataPostIncidentSupport = `
<ul>
<li><span style="font-size: 14px;">Resume normal activities and routine as soon as possible.</span></li>
<li><span style="font-size: 14px;">Record and report the incident.</span></li>
<li><span style="font-size: 14px;">Staff to seek medical assistance if injured.</span></li>
<li><span style="font-size: 14px;"> Give Jack space and time to calm, it may take at least 90 minutes for Jack to return to
        baseline</span></li>
<li><span style="font-size: 14px;">If Jack says he is calm, approach with caution. Distract Jack with a positive distraction i.e., a
        snack or more time on the iPad. </span></li>
</ul>`;
export {
    sampleDataAboutPlan,
    sampleDataMOBP,
    sampleDataSecondaryPrevention,
    sampleDataReactiveStartegies,
    sampleDataPostIncidentSupport
}