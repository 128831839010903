import React from 'react';
import ftcLogo from "../../assets/images/ftc_logo.svg";


function SuccessMessageDisplay() {
    return (
        <div className="login-form">
            <img src={ftcLogo} alt="FTC Logo" className="FTCLogo" />
            <h3>Password Update Successfully</h3>
            <p>Your password has been reset successfully</p>
            <div className="successIcon">
                <img src="./assets/images/tick.svg" alt="" />
            </div>
            <div className="form-group">
                <a className="button" href="/">
                    Sign in
                </a>
            </div>
        </div>
    );
}

export default SuccessMessageDisplay;
