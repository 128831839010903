import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addRotaTemplate } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_NEW_ROTA_TEMPLATE_URL, ADMIN_ID } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import SelectBox from "../../components/forms-fields/SelectBox";
import moment from "moment/moment";

function AddRotaTemplate({ userId, subModalAddRotaTemplate }) {
  const handleShowHideModal = () => {
    subModalAddRotaTemplate((prevShow) => !prevShow);
  };
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddRotaTemplate, setIsToastAddRotaTemplate] = useState(false);
  const [suFormData, setSuFormData] = useState({
    rotaTemplateTitle: "",
    templateWeeks: 2,
    addedby: ADMIN_ID,
    // startDate: "-",
  });

  const rotaTempWeeksOptions = [
    { value: 2, label: 2 },
    { value: 4, label: 4 },
  ];

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addRotaTemplate,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postAddRotaTemplateFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddRotaTemplate(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (
      method === "postAddRotaTemplateFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddRotaTemplate(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postAddRotaTemplateFormData = catchAsync(async (req, res) => {
    // console.log(values);
    console.log("🚀 ~ postAddRotaTemplateFormData ~ values:", values)
    const response = await postRecords(POST_NEW_ROTA_TEMPLATE_URL, values);
    console.log("🚀 ~ postAddRotaTemplateFormData ~ response:", response)
    processPostRecords("postAddRotaTemplateFormData", response);
    setIsSubmitButtonClicked(false);
  });





  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit}>
          <div className="formBlockContent">
            <div className="row">


              <div className="col-md-12">
                <Input
                  type="text"
                  id=""
                  name="rotaTemplateTitle"
                  value={values?.rotaTemplateTitle}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  labelText="Name"
                  customError={touched?.rotaTemplateTitle && errors?.rotaTemplateTitle}
                  errorMessage={errors?.rotaTemplateTitle}
                  erroClass={
                    touched?.rotaTemplateTitle && errors?.rotaTemplateTitle
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>

             
              <div className="col-md-12">
                <SelectBox
                  id={"templateWeeks"}
                  name={"templateWeeks"}
                  value={values?.templateWeeks}
                  onChange={handleChange}
                  options={rotaTempWeeksOptions}
                  labelText="Weeks"
                  requiredStar={true}
                />

              </div>

            </div>
          </div>

          <div className="formActionButtons">
            <span
              onClick={subModalAddRotaTemplate}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={subModalAddRotaTemplate}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastAddRotaTemplate && <ToastContainer />}
    </>
  );
}

export default AddRotaTemplate;