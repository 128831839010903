// rootReducer.jsx
import { combineReducers } from 'redux';
import userProfileReducer from './userProfileReducer';
import selectStaffMemberReducer from './selectStaffMemberReducer';
import addNewChatSingleUserReducer from './addNewChatSingleUserReducer';

const rootReducer = combineReducers({
  userProfile: userProfileReducer,
  selectStaffMember: selectStaffMemberReducer,
  addNewChatSingleUser: addNewChatSingleUserReducer,
  // Other reducers...
});

export default rootReducer;