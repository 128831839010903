import React, { useState, useEffect } from "react";
import Layout from "../../components/layouts/Layout";
import UserDetailWithStatus from "../../components/user-detail/UserDetailWithStatus";
import { Dropdown, OverlayTrigger, Table, Tooltip } from "react-bootstrap";
import infoIcon from "../../assets/images/icons/infoIcon.svg";
import lessThan from "../../assets/images/icons/lessThan.svg";
import greaterThan from "../../assets/images/icons/greaterThan.svg";
import documentSearch from "../../assets/images/icons/documentSearch.svg";
import carePlanSummary from "../../assets/images/icons/careplan/carePlanSummary.svg";
import carePlanPersonalDetail from "../../assets/images/icons/careplan/carePlanPersonalDetail.svg";
import carePlanKeyContacts from "../../assets/images/icons/careplan/carePlanKeyContacts.svg";
import carePlanCareNotes from "../../assets/images/icons/careplan/carePlanCareNotes.svg";
import carePlanMedicationProfile from "../../assets/images/icons/careplan/carePlanMedicationProfile.svg";
import carePlanElectricMar from "../../assets/images/icons/careplan/carePlanElectricMar.svg";
import editBlock from "../../assets/images/icons/editBlock.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import MedicineStatus from "../../components/general/MedicineStatus";
import MedicineDetail from "../../components/modals/MedicineDetail";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import medicineImage from "../../assets/images/medicineImage.png";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { GET_EMAR_MEDICATION_URL, DELETE_EMAR_MEDICATION_URL } from "../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  handleRedirectIfProfileNotPresent,
  filterArrayBySearchTerm,
} from "../../helper/helper";
import AddMedicationSchedule from "./AddMedicationSchedule";
import ProgressComponent from "../../components/general/ProgressComponent";
import moment from "moment";
import EditMedicationSchedule from "./EditMedicationSchedule";

function Emar() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const navigate = useNavigate();
  const [noRecordFound, setNoRecordFound] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [emarListData, setEmarListData] = useState([]);
  const [emarFilteredListData, setEmarFilteredListData] = useState([]);
  const [uniqueDoseTimes, setUniqueDoseTimes] = useState([]);
  const [uniqueDoseTimeType, setUniqueDoseTimeType] = useState([]);
  const [calendarDays, setCalendarDaysArray] = useState([]);
  // console.log("🚀 ~ Emar ~ calendarDays:", calendarDays)
  const [emarMedicationDetail, setEmarMedicationDetail] = useState();
  console.log("🚀 ~ Emar ~ emarMedicationDetail:", emarMedicationDetail);
  const [medicationIdData, setMedicationIdData] = useState({
    medicationId: null,
  });
  const [isEmarToast, setIsEmarToast] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [medicineDetailPopup, setMedicineDetailPopup] = useState(false);
  const [search, setSearch] = useState("");

  useEffect(() => {
    handleRedirectIfProfileNotPresent(
      "profileUserDetails",
      "/service-users",
      navigate
    );
  }, [navigate]);

  const [currentStartDate, setCurrentStartDate] = useState(
    moment().startOf("isoWeek")
  );
  const getCurrentWeekDates = () => {
    const currentDate = moment();
    const startDate = currentDate.startOf("isoWeek").valueOf();
    // const endDate = currentDate.endOf('week').valueOf();
    const endDate = currentStartDate.endOf("isoWeek").valueOf();
    return { startDate, endDate };
  };
  const handleDateChange = (direction) => {
    setCurrentStartDate((prevStartDate) => {
      return direction === "next"
        ? moment(prevStartDate).add(7, "days")
        : moment(prevStartDate).subtract(7, "days");
    });
  };
  useEffect(() => {
    // Perform any additional logic when the start date changes
    // For example, you can fetch new data for the selected week here
    const updatedWeekDates = getCurrentWeekDates(currentStartDate);
    fetchAllRecords();
    setCalendarDaysArray(
      getDaysArray(
        currentStartDate.startOf("isoWeek").valueOf(),
        currentStartDate.endOf("isoWeek").valueOf()
      )
    );
    // Perform any action with updatedWeekDates
  }, [currentStartDate]);

  // const currentWeekDates = getCurrentWeekDates();

  const getEmarApiQuery = {
    userId: userDetails?._id,
    startDate: currentStartDate.startOf("isoWeek").valueOf(),
    endDate: currentStartDate.endOf("isoWeek").valueOf(),
  };
  // console.log("🚀 ~ Emar ~ getEmarApiQuery:", getEmarApiQuery)

  const tooltip = (
    <Tooltip id="tooltip" className="toolTip">
      More Information
    </Tooltip>
  );

  const medicineDetailStatus = () => {
    setMedicineDetailPopup(!medicineDetailPopup);
  };

  const [show, setShow] = useState(false);
  const handleModalEvent = (emarMedicineDetail) => {
    setEmarMedicationDetail(emarMedicineDetail);
    setShow(!show);
  };

  const [showSubModal, setShowSubModal] = useState(false);
  const subModalHandleModalEvent = () => setShowSubModal(!showSubModal);

  const [showCarePlan, setShowCarePlan] = useState(false);
  const subModalCarePlan = () => setShowCarePlan(!showCarePlan);

  const [showAddMedication, setShowAddMedication] = useState(false);
  const subModalAddMedication = () => {
    setShowAddMedication(!showAddMedication);
    fetchAllRecords();
  };

  const [showEditMedication, setShowEditMedication] = useState(false);
  const subModalEditMedication = (emarItem) => {
    setEmarMedicationDetail(emarItem);
    setShowEditMedication(!showEditMedication);
    fetchAllRecords();
  };

  const [showDeleteMedication, setShowDeleteMedication] = useState(false);
  const subModalDeleteMedication = (emarMedicationId) => {
    setMedicationIdData({ medicationId: emarMedicationId });
    setShowDeleteMedication(!showDeleteMedication);
    fetchAllRecords();
  };

  const weekdays = [

    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ];

  // Get All eMAR records for the patient

  const processPatientDoseData = (method, data) => {
    if (method === "get" && data?.status === "Success") {
      const allData = data?.data;
      if (allData && allData.length > 0) {
        const doseTimes = new Set(); // Using Set to maintain uniqueness
        const doseTimeType = new Set(); // Using Set to maintain uniqueness
        allData.forEach((item) => {
          if (item && item.dose && Array.isArray(item.dose)) {
            item.dose.forEach((doseItem) => {
              if (doseItem && doseItem.doseTime && doseItem.timeType) {
                doseTimes.add(doseItem.doseTime); // Adding doseTime to the Set
                doseTimeType.add(doseItem.timeType); // Adding doseTime to the Set
              }
            });
          }
        });

        const sortedDoseTimes = Array.from(doseTimes).sort((a, b) =>
          moment(a, "h:mm A").diff(moment(b, "h:mm A"))
        );

        setUniqueDoseTimes(sortedDoseTimes);
        setUniqueDoseTimeType(Array.from(doseTimeType));
        // setUniqueDoseTimeType(Array.from(doseTimes));
      }
    }
  };

  const processEmarData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;
      if (allData && allData.length <= 0) {
        setNoRecordFound(true);
      } else {
        setNoRecordFound(false);
        setIsLoading(false);

        setEmarListData(allData);
      }
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    const getEmarRecords = await getRecords(
      GET_EMAR_MEDICATION_URL,
      getEmarApiQuery
    );
    processEmarData("get", getEmarRecords);
    processPatientDoseData("get", getEmarRecords);
    // console.log("eMAR Data check this out:", getEmarRecords);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Function to generate an array of days between two dates
  const getDaysArray = (start, end) => {
    const days = [];
    for (
      let date = new Date(start);
      date <= end;
      date.setDate(date.getDate() + 1)
    ) {
      days.push(date.getTime()); // Push the millisecond timestamp
    }
    return days;
  };

  // Generate the array of days between medicationStartDate and medicationEndDate
  // const startDate = new Date(1705258800000);
  // const endDate = new Date(1705777200000);

  // Remove Medication,
  const removeRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      // console.log({ data });
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsEmarToast(false);
      }, 2000);
    }
  };

  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (medicationIdData !== "") {
      const response = await deleteRecords(
        DELETE_EMAR_MEDICATION_URL,
        medicationIdData
      );
      removeRecords("delete", response);
      setIsEmarToast(true);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteMedication(!showDeleteMedication);
    } else {
      setShowDeleteMedication(!showDeleteMedication);
      setIsSubmitButtonClicked(false);
      setIsEmarToast(true);
      toastErrorMessages(toast.error, "Unable to remove", 1000);
    }
  });

  // Search Filter
  // useEffect(() => {
  //   const result = emarListData.filter((user) => {
  //     return user.medicationName.toLowerCase().match(search.toLowerCase());
  //   });
  //   setEmarFilteredListData(result);
  // }, [search, emarListData]);

  const handleSearch = (e) => {
    const searchVal = e?.target?.value;
    setSearch(searchVal);
    if (searchVal !== "") {
      const lowercaseSearch = searchVal?.toLowerCase();
      const filteredRecords = filterArrayBySearchTerm(
        emarListData,
        lowercaseSearch,
        (record) => record?.medicationName.toLowerCase()
      );
      setEmarFilteredListData(filteredRecords);
    } else {
      setEmarFilteredListData(emarListData);
    }
  };

  useEffect(() => {
    setEmarFilteredListData(emarListData);
  }, [emarListData]);

  const [medicineDetailPopups, setMedicineDetailPopups] = useState([]);
  useEffect(() => {
    setMedicineDetailPopups(new Array(emarListData.length).fill(false));
  }, [emarListData]);

  // Update the click handler to toggle the MedicineDetail for a specific item
  const toggleMedicineDetail = (index) => {
    // Create a copy of the medicineDetailPopups array to update the specific index
    const updatedPopups = [...medicineDetailPopups];
    updatedPopups[index] = !updatedPopups[index];
    setMedicineDetailPopups(updatedPopups);
  };

  const getInitials = (fullName) => {
    const names = fullName.split(" ");
    const initials = names.map((name) => name[0].toUpperCase()).join(".");
    return initials;
  };

  const editMedicationSchedule = (item) => {
    navigate("/edit-medication", { state: { medicationDetails: [item] } });
  };

  return (
    <>
      <Layout pageTitleText="eMAR">
        <div className="rowClickUnset">
          <UserDetailWithStatus
            eMAR={true}
            userDetails={userDetails}
            subModalAddMedication={subModalAddMedication}
          />
          <div className="timeTableWrapper">
            {/* {userDetails?._id} */}
            <div className="timeTableItem">
              {/* Range Filter and action button */}
              <main>
                <div className="rangeWithButtons">
                  <div className="rangNavigation">
                    <span onClick={() => handleDateChange("prev")}>
                      <img src={lessThan} alt="Less Then" />
                    </span>
                    <p>
                      {/* {moment(currentStartDate).format("ll")} */}
                      {moment(currentStartDate)
                        .startOf("isoWeek")
                        .format("DD/MM/YYYY")}
                      &nbsp;to&nbsp;
                      {moment(currentStartDate)
                        .endOf("isoWeek")
                        .format("DD/MM/YYYY")}
                    </p>
                    <span onClick={() => handleDateChange("next")}>
                      <img src={greaterThan} alt="Greater Then" />
                    </span>
                  </div>
                  <div className="actionButtons">
                    <div className="searchContainer">
                      <div className="searchTextBox">
                        <input
                          type="text"
                          placeholder="Type your search"
                          value={search}
                          onChange={handleSearch}
                        />
                      </div>
                    </div>

                    {/* <span className="btn btn-sm btn-primary" onClick={subModalCarePlan}>
                      View Care Plan
                    </span> */}
                  </div>
                </div>
              </main>
              {noRecordFound ? (
                <>
                  <div className="noRecordFound">
                    <p>No records found.</p>
                  </div>
                </>
              ) : isLoading ? (
                <ProgressComponent />
              ) : emarFilteredListData && emarFilteredListData.length > 0 ? (
                uniqueDoseTimeType
                  .filter((item) => {
                    // Filter out uniqueDoseTimes for which there is data in emarFilteredListData
                    return emarFilteredListData.some(
                      (emarItem) =>
                        emarItem.dose &&
                        emarItem.dose.some(
                          (doseItem) => doseItem.timeType === item
                        )
                    );
                  })
                  .map((timeTypeItem, timeTypeIndex) => (
                    <>
                      <div className="scheduleType">
                        {/* Dose Schedule Time */}
                        <header>
                          <p key={timeTypeItem}>
                            <strong>Schedule Type - {timeTypeItem}</strong>
                          </p>
                        </header>
                      </div>
                      {uniqueDoseTimes
                        .filter((itemDoseTime) =>
                          emarFilteredListData.some(
                            (emarItem) =>
                              emarItem.dose &&
                              emarItem.dose.some(
                                (doseItem) =>
                                  doseItem.timeType === timeTypeItem &&
                                  doseItem.doseTime === itemDoseTime
                              )
                          )
                        )
                        .map((itemDoseTime, index) => (
                          <div className="doseScheduleTimeWrapper" key={index}>
                            {timeTypeItem === "hourly" ? (
                              <>
                                <header>
                                  <p key={index}>
                                    <strong>
                                      After every{" "}
                                      {moment(itemDoseTime, "h:mm A").format(
                                        "h"
                                      )}{" "}
                                      hours
                                    </strong>
                                  </p>
                                </header>
                              </>
                            ) : timeTypeItem === "standard" ? (
                              <header>
                                <p key={index}>
                                  <strong>
                                    Dose {index + 1} -&nbsp;
                                    {itemDoseTime === "1:00 AM" ? (
                                      <>Morning</>
                                    ) : itemDoseTime === "2:00 AM" ? (
                                      <>Afternoon</>
                                    ) : itemDoseTime === "3:00 AM" ? (
                                      <>Evening</>
                                    ) : itemDoseTime === "4:00 AM" ? (
                                      <>Night</>
                                    ) : null}
                                  </strong>
                                </p>
                              </header>
                            ) : timeTypeItem === "specific" ? (
                              <>
                                <header>
                                  <p key={index}>
                                    <strong>
                                      Dose {index + 1} - {itemDoseTime}
                                    </strong>
                                  </p>
                                </header>
                              </>
                            ) : null}

                            <main>
                              <div className="medicineTableData">
                                <Table
                                  responsive="xl"
                                  bordered
                                  hover
                                  size="sm"
                                  className="tableStriped"
                                >
                                  <thead>
                                    <tr>
                                      <th>Medicine Name</th>
                                      <th></th>
                                      {weekdays.map(
                                        (weekDaysItem, weekDaysIndex) => (
                                          <>
                                            {calendarDays.map(
                                              (
                                                calendarDaysItem,
                                                calendarDaysIndex
                                              ) =>
                                                moment(calendarDaysItem).format(
                                                  "dddd"
                                                ) === weekDaysItem ? (
                                                  <>
                                                    <th>
                                                      {moment(
                                                        calendarDaysItem
                                                      ).format("DD")}{" "}
                                                      - {weekDaysItem}
                                                    </th>
                                                  </>
                                                ) : null
                                            )}
                                          </>
                                        )
                                      )}
                                    </tr>
                                  </thead>
                                  {emarFilteredListData.map(
                                    (emarItem, emarIndex) =>
                                      emarItem?.dose?.map(
                                        (doseItem, doseIndex) =>
                                          doseItem?.timeType === timeTypeItem &&
                                          doseItem?.doseTime ===
                                          itemDoseTime && (
                                            <>
                                              <tbody>
                                                <>
                                                  {doseItem?.doseTime ===
                                                    doseItem?.doseTime &&
                                                    doseItem?.timeType ===
                                                    timeTypeItem ? (
                                                    <>
                                                      <tr>
                                                        <td>
                                                          <div className="actionRow">
                                                            {/* {currentStartDate.startOf("isoWeek").valueOf() >= emarItem?.medicationEndDate ? "success" : 'failed'} */}
                                                            <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                                                              <span
                                                                className="edit"
                                                                onClick={() => {
                                                                  editMedicationSchedule(
                                                                    emarItem
                                                                  );
                                                                }}
                                                              >
                                                                <img
                                                                  src={editBlock}
                                                                  alt="Edit"
                                                                />
                                                              </span>
                                                            </ProtectedComponent>
                                                            <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
                                                              <span
                                                                className="delete"
                                                                onClick={() => {
                                                                  subModalDeleteMedication(
                                                                    emarItem?._id
                                                                  );
                                                                }}
                                                              >
                                                                <img
                                                                  src={eventDel}
                                                                  alt="Delete"
                                                                />
                                                              </span>
                                                            </ProtectedComponent>
                                                          </div>
                                                          <span></span>
                                                          <span
                                                            className="link"
                                                            onClick={() =>
                                                              toggleMedicineDetail(
                                                                emarIndex,
                                                                timeTypeItem
                                                              )
                                                            }
                                                          >
                                                            {
                                                              emarItem?.medicationName
                                                            }
                                                          </span>
                                                          {medicineDetailPopups[
                                                            emarIndex
                                                          ] && (
                                                              <MedicineDetail
                                                                onClose={() =>
                                                                  toggleMedicineDetail(
                                                                    emarIndex
                                                                  )
                                                                }
                                                              />
                                                            )}
                                                          {doseItem?.timeType ===
                                                            "hourly" && (
                                                              <>
                                                                <small>
                                                                  Medication Type:{" "}
                                                                  <strong>
                                                                    {
                                                                      doseItem?.medicationType
                                                                    }
                                                                  </strong>
                                                                </small>

                                                                <small>
                                                                  Start time:{" "}
                                                                  <strong>
                                                                    {
                                                                      doseItem?.startTime
                                                                    }
                                                                  </strong>
                                                                </small>
                                                              </>
                                                            )}

                                                          
                                                            
                                                                <small>
                                                                  Take{" "}
                                                                  <strong>
                                                                    {
                                                                      doseItem?.noOfDoses
                                                                    }{" "}
                                                                    -{" "}
                                                                    {
                                                                      doseItem?.medicationType
                                                                    }
                                                                  </strong>{" "}
                                                                  in one dose
                                                                </small>
                                                            
                                                            
                                                          <small>
                                                            {emarItem?.note}
                                                          </small>
                                                          <small>
                                                            Prescribe by:
                                                            {
                                                              emarItem?.prescriberName
                                                            }
                                                          </small>
                                                        </td>
                                                        <td>
                                                          <OverlayTrigger
                                                            placement="top"
                                                            overlay={tooltip}
                                                          >
                                                            <img
                                                              className="curSorPointer"
                                                              onClick={() =>
                                                                handleModalEvent(
                                                                  emarItem
                                                                )
                                                              }
                                                              src={infoIcon}
                                                              alt="Settings"
                                                            />
                                                          </OverlayTrigger>
                                                        </td>

                                                        {calendarDays.map(
                                                          (
                                                            calendarDaysItem,
                                                            calendarDaysIndex
                                                          ) => (
                                                            <>
                                                              <td>
                                                                {/* {
                                                                  (calendarDaysItem >= emarItem?.medicationStartDate && calendarDaysItem <= emarItem?.medicationEndDate) ? (
                                                                    <>

                                                                      {emarItem?.days?.map((daysItem, daysIndex) => (
                                                                        moment(calendarDaysItem).format('dddd') === daysItem ? (
                                                                          <div key={daysIndex}>
                                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{doseItem?.dose?.staffMemberName}</Tooltip>}>
                                                                              <h1></h1>
                                                                            </OverlayTrigger>
                                                                          </div>
                                                                        ) : null
                                                                      ))}
                                                                    </>
                                                                  ) : null
                                                                } */}

                                                                {/* {
                                                                  moment(calendarDaysItem).format('DD MMM YYYY') >= moment(emarItem?.medicationStartDate).format('DD MMM YYYY') ? (
                                                                    <>
                                                                      {emarItem?.days?.map((daysItem, daysIndex) => (
                                                                        moment(calendarDaysItem).format('dddd') === daysItem ? (
                                                                          <>
                                                                            <OverlayTrigger overlay={<Tooltip id="tooltip-disabled">{doseItem?.dose?.staffMemberName}</Tooltip>}>
                                                                              <span
                                                                                className="doseType"
                                                                                key={
                                                                                  doseIndex
                                                                                }
                                                                              >
                                                                                {
                                                                                  getInitials(doseItem?.dose?.staffMemberName)
                                                                                }
                                                                              </span>
                                                                            </OverlayTrigger>
                                                                          </>
                                                                        ) : null
                                                                      ))}

                                                                    </>
                                                                  ) : null
                                                                } */}
                                                              </td>
                                                            </>
                                                          )
                                                        )}
                                                      </tr>
                                                    </>
                                                  ) : null}
                                                </>
                                              </tbody>
                                            </>
                                          )
                                      )
                                  )}
                                </Table>
                              </div>
                            </main>
                            <footer>
                              <MedicineStatus />
                            </footer>
                          </div>
                        ))}
                    </>
                  ))
              ) : (
                <>
                  <div className="noRecordFound">
                    <p>No records found.</p>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </Layout>

      {/* Prescription */}
      <Modal
        show={show}
        onHide={() => setShow(false)}
        className="ftcModalPopup markLeaveModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Prescription For - 0 - A </Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <div className="rosterPopupContent">
            <div className="rosterBlock">
              <p>
                <strong>Medicine Description</strong>
              </p>
              <table className="table table-bordered tableStriped">
                <tr>
                  <th>Medication Details</th>
                  <td>
                    <span className="text-danger">
                      {emarMedicationDetail?.medicationId?.medicationName}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Medication Type</th>
                  <td>
                    {emarMedicationDetail?.medicationId?.medicineType}
                    {/* <span
                      className="popupIcon"
                      onClick={subModalHandleModalEvent}
                    >
                      <img src={documentSearch} alt="documentSearch" />
                    </span> */}
                  </td>
                </tr>
                <tr>
                  <th>Strength Value</th>
                  <td>{emarMedicationDetail?.medicationId?.strength}</td>
                </tr>
                <tr>
                  <th>Unit Type</th>
                  <td>
                    <span>{emarMedicationDetail?.medicationId?.unitType}</span>
                  </td>
                </tr>
                <tr>
                  <th>In Blister Pack</th>
                  <td>
                    <label className="checkboxContainer">
                      <input type="checkbox" checked={emarMedicationDetail?.isBlisterPack} disabled />
                      <span className="checkmark"></span>
                    </label>
                  </td>
                </tr>
              </table>
            </div>
            <div className="rosterBlock">
              <p>
                <strong>Reason for Medication</strong>
              </p>
              <div className="form-group">
                <textarea name="description" id="description" disabled>
                  {emarMedicationDetail?.reasonForMedication}
                </textarea>
                <label htmlFor="description" className="floatingLabel">
                  Description
                </label>
              </div>
            </div>
            <div className="rosterBlock">
              <p>
                <strong>Prescription Detail</strong>
              </p>
              <table className="table table-bordered tableStriped">
                <tr>
                  <th>Start Date</th>
                  <td>
                    {moment(emarMedicationDetail?.medicationStartDate).format(
                      "DD/MM/YYYY"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>Start Time</th>
                  <td>
                    {moment(emarMedicationDetail?.medicationStartDate).format(
                      "hh:mm:ss A"
                    )}
                  </td>
                </tr>
                <tr>
                  <th>End Date</th>
                  <td>{
                    emarMedicationDetail?.medicationEndDate ? (
                      moment(emarMedicationDetail?.medicationEndDate).format("DD/MM/YYYY")
                    ) : "No end date"
                  }</td>
                </tr>
                <tr>
                  <th>Schedule Type</th>
                  <td>
                    <span className="capitalizeTransform">
                      {emarMedicationDetail?.dose[0]?.timeType}
                    </span>
                  </td>
                </tr>
                <tr>
                  <th>Status</th>
                  <td>
                    {emarMedicationDetail?.active ? "Active" : "Not Active"}
                  </td>
                </tr>
              </table>
            </div>
            <div className="rosterBlock">
              <p>
                <strong>Prescription Text</strong>
              </p>
              <div className="form-group">
                <textarea name="description" id="description" disabled>
                  {emarMedicationDetail?.prescriptionText}
                </textarea>
                <label htmlFor="description" className="floatingLabel">
                  Description
                </label>
              </div>
            </div>
            <div className="rosterBlock">
              <div className="checkboxList">
                <div className="checkboxContainer">
                  <label>
                    <span className="labelText">PRN</span>
                    <input type="checkbox" checked={emarMedicationDetail?.isPrn} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkboxContainer">
                  <label className="checkboxContainer">
                    <span className="labelText">With Food</span>
                    <input type="checkbox" checked={emarMedicationDetail?.withFood} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkboxContainer">
                  <label className="checkboxContainer">
                    <span className="labelText">Self Administer</span>
                    <input type="checkbox" checked={emarMedicationDetail?.selfAdministrator} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkboxContainer">
                  <label className="checkboxContainer">
                    <span className="labelText">Body Map</span>
                    <input type="checkbox" checked={emarMedicationDetail?.bodyMap} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkboxContainer">
                  <label className="checkboxContainer">
                    <span className="labelText">Multi Description</span>
                    <input type="checkbox" checked={emarMedicationDetail?.multiDescription} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkboxContainer">
                  <label className="checkboxContainer">
                    <span className="labelText">Treat as Cream</span>
                    <input type="checkbox" checked={emarMedicationDetail?.treatAsCream} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkboxContainer">
                  <label className="checkboxContainer">
                    <span className="labelText">Second Sig</span>
                    <input type="checkbox" checked={emarMedicationDetail?.seconfSing} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
                <div className="checkboxContainer">
                  <label className="checkboxContainer">
                    <span className="labelText">Without Food</span>
                    <input type="checkbox" checked={!emarMedicationDetail?.withFood} disabled />
                    <span className="checkmark"></span>
                  </label>
                </div>
              </div>
            </div>

            <div className="rosterBlock">
              <p>
                <strong>Prescription Entered By</strong>
              </p>
              <table className="table table-bordered tableStriped">
                <tr>
                  <th>Entered By</th>
                  <td>{emarMedicationDetail?.addedby?.name}</td>
                </tr>
                <tr>
                  <th>Entered Date</th>
                  <td>{moment(emarMedicationDetail?.createdAt).format("DD/MM/YYY hh:mm A")}</td>
                </tr>
                {/* <tr>
                  <th>Seconded By</th>
                  <td>NA</td>
                </tr> */}
              </table>
            </div>

            <div className="rosterBlock">
              <p>
                <strong>Evidence for this prescription</strong>
              </p>
              <table className="table table-bordered tableStriped">
                <tr>
                  <th>Evidence</th>
                  <td> {
                    emarMedicationDetail?.evidence ? (
                      emarMedicationDetail?.evidence
                    ) : ("Not present")
                  }
                  </td>
                </tr>
                <tr>
                  <th>GP Name</th>
                  <td> {
                    emarMedicationDetail?.gpName ? (
                      emarMedicationDetail?.gpName
                    ) : ("Not present")
                  }
                  </td>
                </tr>
              </table>
            </div>

            <div className="rosterBlock">
              <p>
                <strong>Administrable Stock</strong>
              </p>
              <table className="table table-bordered tableStriped">
                <tr>
                  <th>Current Stock</th>
                  <td>{emarMedicationDetail?.medicationId?.inventory}</td>
                </tr>
              </table>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Medical Detail */}
      <Modal
        show={showSubModal}
        onHide={subModalHandleModalEvent}
        className="ftcModalPopup markLeaveModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Medication Details</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <div className="rosterPopupContent pt-2">
            <div className="rosterBlock">
              <table className="table table-bordered tableStriped">
                <tr>
                  <th>Medication Name</th>
                  <td>
                    <span className="text-danger">Pregabalin 50mg</span>
                  </td>
                </tr>
                <tr>
                  <th>Medication Type</th>
                  <td>Regular Medication</td>
                </tr>
                <tr>
                  <th>Strength</th>
                  <td>50mg</td>
                </tr>
                <tr>
                  <th>Delivery Method</th>
                  <td>Tablets</td>
                </tr>
                <tr>
                  <th>01 Dose Equals</th>
                  <td>01 Tablet</td>
                </tr>
                <tr>
                  <th>Default Dosage</th>
                  <td>01 Tablet</td>
                </tr>
                <tr>
                  <th>Default Drug Location</th>
                  <td>Dosette Box</td>
                </tr>
              </table>
            </div>
            <div className="rosterBlock">
              <p>
                <strong>Purpose of Medication</strong>
              </p>
              <div className="form-group">
                <textarea name="description" id="description"></textarea>
                <label htmlFor="description" className="floatingLabel">
                  Description
                </label>
              </div>
            </div>
            <div className="rosterBlock">
              <p>
                <strong>Medication Picture</strong>
              </p>
              <div className="medicationPicture">
                <img
                  className="medicImage"
                  src={medicineImage}
                  alt="Medicine"
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* CarePlan Modal */}
      <Modal
        show={showCarePlan}
        onHide={subModalCarePlan}
        className="ftcModalPopup carePlanSectionsModal"
      >
        <Modal.Header closeButton>
          <Modal.Title>Care Plan Sections</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <div className="rosterPopupContent pt-2">
            <div className="rosterBlock carePlanSections">
              <span className="buttonLink">
                Summary
                <img src={carePlanSummary} alt="" />
              </span>
              <p>
                <strong>Essentials</strong>
              </p>
              <span className="buttonLink">
                Personal Details
                <img src={carePlanPersonalDetail} alt="" />
              </span>
              <span className="buttonLink">
                Key Contacts
                <img src={carePlanKeyContacts} alt="" />
              </span>
              <span className="buttonLink">
                Care Notes
                <img src={carePlanCareNotes} alt="" />
              </span>
              <p>
                <strong>Medical</strong>
              </p>
              <span className="buttonLink">
                Medication Profile
                <img src={carePlanMedicationProfile} alt="" />
              </span>
              <span className="buttonLink">
                Electronic MAR
                <img src={carePlanElectricMar} alt="" />
              </span>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* Edit eMAR Medication */}
      <Modal
        show={showEditMedication}
        onHide={subModalEditMedication}
        className="ftcModalPopup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Medication</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <EditMedicationSchedule
            subModalEditMedication={subModalEditMedication}
            emarMedicationDetail={emarMedicationDetail}
            userDataId={userDetails?._id}
          />
        </Modal.Body>
      </Modal>

      {/* Delete eMAR Medication */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteMedication}
          onHide={subModalDeleteMedication}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this medication?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {/* onClick={deleteRecord} */}
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteMedication}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {isEmarToast && <ToastContainer />}
    </>
  );
}

export default Emar;
