import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/forms-fields/Input";
import Select from "react-select";
import ProgressComponent from "../../../components/general/ProgressComponent";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
} from "../../../helper/helper";
import { rotaTempWeeksOptions, CustomOption } from "../../../helper/helperJsx";
import { ADMIN_ID, POST_NEW_ROTA_TEMPLATE_URL } from "../../../env";
import { useNavigate } from "react-router-dom";

function CreateRotaTemplate({
  handleShowModel,
  handleCloseModel,
  tempData,
  fetchAllRecords,
}) {
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [isToastRotaSchedule, setIsToastRotaSchedule] = useState(false);
  const navigate = useNavigate();
  const validationSchema = Yup?.object().shape({
    rotaTemplateTitle: Yup?.string()?.required("Template name is required."),
    templateWeeks: Yup?.number()
      .required("No of weeks is required.")
      .oneOf([2, 4], "Please select either 2 weeks or 4 weeks."),
  });

  const rotaScheduleFormData = useFormik({
    initialValues: {
      rotaTemplateTitle: "",
      tempId: tempData?._id,
      templateWeeks: 2,
      shiftLocation: tempData?.shiftLocation,
      addedby: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitClicked(true);
        await handleAddRotaTemp(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          1000,
          1000
        );
        setIsFormSubmitClicked(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = rotaScheduleFormData;

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsFormSubmitClicked(false);
      setIsToastRotaSchedule(true);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "post" && data?.status === isSuccess) {
      navigate("/rota-templates", {
        state: {
          editModeTemplateCreated: data?.data,
        },
      });
      handleCloseModel();
    }
  };

  const handleAddRotaTemp = catchAsync(async (req, res) => {
    const response = await postRecords(POST_NEW_ROTA_TEMPLATE_URL, req);
    await fetchAllRecords();
    processFetchRecords("post", response);
  });

  return (
    <>
      <Modal
        show={handleShowModel}
        onHide={handleCloseModel}
        className="ftcModalPopup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Create New Template</Modal.Title>
        </Modal.Header>
        <form method="post" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="row align-items-center mt-4">
              <div className="col-sm-12">
                <div className="form-group">
                  <Input
                    type="text"
                    name="rotaTemplateTitle"
                    value={values?.rotaTemplateTitle}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    labelText="Name"
                    placeholder={"Type here.."}
                    customError={
                      touched?.rotaTemplateTitle && errors?.rotaTemplateTitle
                    }
                    errorMessage={errors?.rotaTemplateTitle}
                    errorClass={
                      touched?.rotaTemplateTitle && errors?.rotaTemplateTitle
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isFormSubmitClicked ? (
              <ProgressComponent />
            ) : (
              <>
                <Button
                  className="btn-sm"
                  variant="outline-primary"
                  onClick={handleCloseModel}
                >
                  Cancel
                </Button>

                <Button className="btn-sm" type="submit" variant="primary">
                  Save
                </Button>
              </>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {isToastRotaSchedule && <ToastContainer />}
    </>
  );
}

export default CreateRotaTemplate;
