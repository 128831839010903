import React, { useState } from "react";
import StaffRecordsBlocks from "./StaffRecordsBlocks";
import ProfileDetails from "../../components/user-detail/ProfileDetails";
import { Tab, Nav } from "react-bootstrap";
import StaffRecordHeader from "./StaffRecordHeader";
import { Link } from "react-router-dom";
import StaffRecordTabsContent from "./records/StaffRecordTabsContent";
import ComplianceDocumentsTab from "./records/ComplianceDocuments";
import References from "./records/ReferencesDocuments";
import AbsenceLeave from "./records/AbsenceLeave";
import Supervisions from "./records/Supervisions";
import Training from "./records/Trainings";
import Holidays from "./records/Holidays";
import Disciplinary from "./records/DisciplinaryDocuments";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";

function StaffRecordsIndividual({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUserData] = useState(initialUserData);
  return (
    <>
      <div className="profileDetailWrapper staffRecordDetailWrapper customTabsWrapper">
        <div className="row">
          <div className="col-md-3">
            <ProfileDetails getUserRecord={userData} />
          </div>
          <div className="col-md-9">
            <div className="userDetail userPayrollDetail">
              <StaffRecordHeader />
              <div className="tabsWrapper">
                <Tab.Container id="myTab" defaultActiveKey="contractedHoursTab">
                  <div className="tabsHeader">
                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="contractedHoursTab">
                          Contracted Hours
                        </Nav.Link>
                      </Nav.Item>
                      <ProtectedComponent requiredPermissions={[permissions.SUPERVISION]}>
                        <Nav.Item>
                          <Nav.Link eventKey="supervisions">
                            Supervisions
                          </Nav.Link>
                        </Nav.Item>
                      </ProtectedComponent>
                      <ProtectedComponent requiredPermissions={[permissions.TRAINING]}>
                        <Nav.Item>
                          <Nav.Link eventKey="training">Trainings</Nav.Link>
                        </Nav.Item>
                      </ProtectedComponent>
                      <ProtectedComponent requiredPermissions={[permissions.HOLIDAYS]}>
                        <Nav.Item>
                          <Nav.Link eventKey="holidays">Holidays</Nav.Link>
                        </Nav.Item>
                      </ProtectedComponent>
                      <ProtectedComponent requiredPermissions={[permissions.HOLIDAYS]}>
                        <Nav.Item>
                          <Nav.Link eventKey="absenceLeave">
                            Absence & Leaves
                          </Nav.Link>
                        </Nav.Item>
                      </ProtectedComponent>
                      <Nav.Item>
                        <Nav.Link eventKey="complianceDocumentsTab">
                          Compliance Documents
                        </Nav.Link>
                      </Nav.Item>
                      <ProtectedComponent requiredPermissions={[permissions.DISCIPLINARYINFO]}>
                        <Nav.Item>
                          <Nav.Link eventKey="disciplinaryTab">
                            Disciplinary
                          </Nav.Link>
                        </Nav.Item>
                      </ProtectedComponent>
                      <Nav.Item>
                        <Nav.Link eventKey="referencesTab">References</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="securityTab">Security</Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <Tab.Content>
                    <Tab.Pane eventKey="contractedHoursTab">
                      <div className="staffRecordTabsContent">
                        <StaffRecordsBlocks
                          currentStatus={true}
                          getUserRecord={userData}
                        />
                        <StaffRecordTabsContent getUserRecord={userData} />
                      </div>
                    </Tab.Pane>
                    <ProtectedComponent requiredPermissions={[permissions.SUPERVISION]}>
                      <Tab.Pane eventKey="supervisions">
                        <div className="staffRecordTabsContent">
                          <Supervisions getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </ProtectedComponent>
                    <ProtectedComponent requiredPermissions={[permissions.HOLIDAYS]}>
                      <Tab.Pane eventKey="holidays">
                        <div className="staffRecordTabsContent">
                          <Holidays getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </ProtectedComponent>
                    <ProtectedComponent requiredPermissions={[permissions.HOLIDAYS]}>
                      <Tab.Pane eventKey="absenceLeave">
                        <div className="staffRecordTabsContent">
                          <AbsenceLeave getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </ProtectedComponent>
                    <ProtectedComponent requiredPermissions={[permissions.TRAINING]}>
                      <Tab.Pane eventKey="training">
                        <div className="staffRecordTabsContent">
                          <Training getUserRecord={userData} />
                        </div>
                      </Tab.Pane>
                    </ProtectedComponent>
                    <Tab.Pane eventKey="complianceDocumentsTab">
                      <div className="staffRecordTabsContent">
                        <ComplianceDocumentsTab getUserRecord={userData} />
                      </div>
                    </Tab.Pane>
                    <ProtectedComponent requiredPermissions={[permissions.DISCIPLINARYINFO]}>
                      <Tab.Pane eventKey="disciplinaryTab">
                        <Disciplinary getUserRecord={userData} />
                      </Tab.Pane>
                    </ProtectedComponent>
                    <Tab.Pane eventKey="referencesTab">
                      <div className="staffRecordTabsContent">
                        <References getUserRecord={userData} />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="securityTab">
                      <div className="staffRecordTabsContent">Coming Soon</div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffRecordsIndividual;
