import React, { useState, useEffect } from "react";
import Select from "react-select";
import { CustomOption } from "../../helper/helperJsx";
import { weeksOptions } from "../../helper/helperJsx";

const CustomSelectWeekDays = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  label,
  defaultWeeks,
  weekOptions,
}) => {
  const [defaultWeeksState, setDefaultWeeksState] = useState(defaultWeeks);
  const [isLoading, setIsLoading] = useState(true);

  const fetchAllRecords = async () => {
    if (defaultWeeks !== "") {
      const result = weeksOptions
        ?.map((option) =>
          defaultWeeksState?.includes(option?.value) ? option?.value : null
        )
        ?.filter(Boolean);
      setDefaultWeeksState(result);
      onSelect(result);
    } else {
      setDefaultWeeksState(weeksOptions[0]);
      onSelect(weeksOptions[0]?.value);
    }
    setIsLoading(false);
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  return (
    <div className={`customSelectBox ${errorClass}`}>
      {isLoading && <p className="loadingText">Loading...</p>}
      {!isLoading && (
        <>
          <Select
            className="basic-multi-select cursor-pointer"
            classNamePrefix="select"
            options={weekOptions}
            isMulti
            isSearchable
            defaultValue={weeksOptions?.filter((option) =>
              defaultWeeksState?.includes(option?.value)
            )}
            isClearable
            onChange={(selectedOptions) => {
              const selectedOption = selectedOptions?.map(
                (option) => option?.value
              );
              onSelect(selectedOption);
            }}
            closeMenuOnSelect={false}
            placeholder="Select"
            getOptionLabel={(selectedOptions) => selectedOptions.label}
            getOptionValue={(selectedOptions) => selectedOptions.value}
            components={{ Option: CustomOption }}
          />
          {label && <label className="floatingLabel">{label}</label>}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default CustomSelectWeekDays;
