import React from "react";
import Layout from "../../components/layouts/Layout";
import MessagingSystem from "./overview/MessagingSystem";
import StaffRecords from "./overview/StaffRecords";
import HolidaysAbsence from "./overview/HolidaysAbsence";
import Roster from "./overview/Roster";
import StaffOverviewBlocks from "../../components/general/StaffOverviewBlocks";
import ProtectedComponent from "../../permissions/ProtectedComponent";
import { permissions } from "../../permissions/roles";
function StaffOverview() {
  return (
    <Layout pageTitleText="Staff Overview">
      <div className="staffOverview">
        <StaffOverviewBlocks />
        <div className="row">
          <ProtectedComponent requiredPermissions={[permissions.ROSTER]}>
            <div className="col-md-6">
              <div className="mainContent Roster rowClickUnset">
                <Roster staffHeadingBlock="Roster" />
              </div>
            </div>
          </ProtectedComponent>
          <ProtectedComponent requiredPermissions={[permissions.HOLIDAYS]}>
            <div className="col-md-6">
              <div className="mainContent holidaysAbsence">
                <HolidaysAbsence staffHeadingBlock="Holidays & Absence" />
              </div>
            </div>
          </ProtectedComponent>
          <div className="col-md-12">
            <div className="mainContent staffRecords">
              <StaffRecords staffHeadingBlock="Staff Records" />
            </div>
          </div>
          {/* <div className="col-md-4">
            <div className="mainContent messagingSystem">
              <MessagingSystem staffHeadingBlock="Messaging System" />
            </div>
          </div> */}
        </div>
      </div>
    </Layout>
  );
}

export default StaffOverview;
