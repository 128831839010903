import React, { useState, useEffect } from "react";
import ScheduleBlocks from "../../components/general/ScheduleBlocks";
import calendarIcon from "../../assets/images/icons/calendar.svg";
import clockIcon from "../../assets/images/icons/clock.svg";

function StaffRecordsBlocks({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUser] = useState(initialUserData);

  return (
    <>
      <div className="scheduleBlocks staffRecordsBlocks">
        <div className="row">
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={clockIcon}
              heading={userData?.contractedHours?.contractedHours}
              subHeading={"Contracted Hours"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={calendarIcon}
              heading={userData?.contractedHours?.totalShiftHoursWeek}
              subHeading={"Total shift hrs"}
            />
          </div>
          <div className="col-md-3 col-sm-6">
            <ScheduleBlocks
              blockIcon={calendarIcon}
              heading={userData?.contractedHours?.noOfShifts}
              subHeading={"No. of shifts"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffRecordsBlocks;
