import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../../../../../components/forms-fields/Input";
import { addShoweringBath } from "../../../../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_CARE_PLAN_URL } from "../../../../../../env";
import ReactDOMServer from 'react-dom/server';
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../../../../helper/helper";
import CustomSelect from "../../../../../../components/forms-fields/CustomSelectStaffMembers";
import TextArea from "../../../../../../components/forms-fields/TextArea";
import moment from "moment";
import { Link } from "react-router-dom";
import Layout from "../../../../../../components/layouts/Layout";
import { Form } from "react-bootstrap";

function AddShoweringBath() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddCarePlans, setIsToastAddCarePlans] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("addedby", userId);
  };

  const [suFormData, setSuFormData] = useState({
    addedby: selectedUserId,
    showeringBathType: "Bath",
    comments: "",
    eventDateTime: moment().valueOf(),
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addShoweringBath,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postAddCarePlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddCarePlans(true);
      toastErrorMessages(toast.error, data?.message, "", 10, 10);
      setIsSubmitButtonClicked(false);

    } else if (
      method === "postAddCarePlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddCarePlans(true);
      toastSuccessMessages(toast.success, data?.message, "/care-plan/general-notes", 10, 10);
      setIsSubmitButtonClicked(false);
    }
  };

  const postAddCarePlansFormData = catchAsync(async (req, res) => {
    const noteDetailsString = ReactDOMServer.renderToString(htmlData);
    const updateValues = {
      userId: userDetails?._id,
      addedby: selectedUserId,
      noteType: "ShoweringBath",
      isHTML: true,
      title: values?.comments,
      flag: false,
      noteDetails: noteDetailsString,
      eventDateTime: moment(values.eventDateTime).valueOf(),
    }

    console.log("🚀 ~ postAddCarePlansFormData ~ updateValues:", updateValues)
    const response = await postRecords(POST_CARE_PLAN_URL, updateValues);
    console.log("🚀 ~ response ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postAddCarePlansFormData", response);
  });



  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setValues({
      ...values,
      [name]: value
    });

    handleChange(e);
    handleBlur(e);
  };




  const [htmlData, setHtmlData] = useState("")

  // bath type check boxes list
  const [whichShowerBathWash, setWhichShowerBathWash] = useState([
    { id: 1, value: "Bath", label: "Bath", },
    { id: 2, value: "Shower", label: "Shower", },
    { id: 2, value: "Wash", label: "Wash", },
  ]);


  useEffect(() => {
    setHtmlData(
      <>
        <div className="col-md-12">
          <p><strong>Type: </strong><span id="showeringBathTypeData">{values?.showeringBathType}</span></p>
        </div>
        <div className="col-md-12">
          <p><strong>Comments: </strong><span id="commentsData">{values?.comments !== "" ? values?.comments : "No assistance requried"}</span></p>
        </div>
      </>
    );
  }, [values]);

  return (

    <>
      <Layout pageTitleText="Notes">
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent ">
            <div className="mainContentText">
              <h3>Add ShoweringBath note</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-group">
                        <CustomSelect
                          id=""
                          name="addedby"
                          onSelect={handleUserSelect}
                          defaultValue={selectedUserId}
                          filterBy=""
                          label="Added By"
                          onBlur={handleBlur}
                          customError={touched?.addedby && errors?.addedby}
                          errorMessage={errors?.addedby}
                          errorClass={
                            touched?.addedby && errors?.addedby
                              ? "validationError"
                              : ""
                          }
                        />
                      </div>
                    </div>

                    <div className="col-md-6">
                      <Input
                        type="datetime-local"
                        id="eventDateTime"
                        name="eventDateTime"
                        value={moment(values?.eventDateTime).format('YYYY-MM-DDTHH:mm')}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Date / Time"
                        customError={touched?.eventDateTime && errors?.eventDateTime}
                        errorMessage={errors?.eventDateTime}
                        erroClass={
                          touched?.eventDateTime && errors?.eventDateTime
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />

                    </div>
                    <div className="col-md-12 mb-3">
                      <div className="checkboxList spaceInCheckList">
                        {whichShowerBathWash.map((row, index) => (
                          <>
                            <Form.Check
                              name="showeringBathType"
                              id={row?.label}
                              type="radio"
                              label={row?.label}
                              value={row?.value}
                              onClick={() => setValues(prevState => ({ ...prevState, showeringBathType: row?.value }))}
                              checked={values?.showeringBathType === row?.label && true}
                              onChange={handleInputChangePrevFields}
                              onBlur={handleBlur}
                            />
                          </>
                        ))}

                      </div>
                    </div>
                    <div className="col-md-12">
                      <TextArea
                        id="comments"
                        name="comments"
                        value={values?.comments}
                        onChange={handleInputChangePrevFields}
                        onBlur={handleBlur}
                        labelText="Comments"
                        rows="3"
                        className="mb-0"
                        customError={touched?.comments && errors?.comments}
                        errorMessage={errors?.comments}
                        erroClass={
                          touched?.comments && errors?.comments
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>

                  </div>
                </div>

                <div className="formActionButtons">
                  <Link
                    to={"/care-plan/general-notes"}
                    className="btn btn-sm btn-outline-primary"
                  >
                    Back
                  </Link>

                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>

              {isToastAddCarePlans && <ToastContainer />}
            </div>
          </div>
        </div>
      </Layout>
    </>
  );
}


export default AddShoweringBath