import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import BradfordScoreIndividual from "./BradfordScoreIndividual";
import BradfordScoreGroup from "./BradfordScoreGroup";

function BradfordScore() {
  const location = useLocation();
  const userData = location?.state && location?.state?.userRecord;

  return (
    <Layout pageTitleText="BradfordScore">
      {userData ? (
        <BradfordScoreIndividual getUserRecord={userData} />
      ) : (
        <BradfordScoreGroup />
      )}
    </Layout>
  );
}

export default BradfordScore;
