import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import { useFormik } from "formik";
import {
  BASE_URL,
  POST_LIVE_ROSTER_DATA_URL
} from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const AutoAddShifts = ({ templates, userCurrentStartDate, fetchAllRecords }) => {
  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []); // This effect runs once, after the component mounts

  // console.log("🚀 ~ AutoAddShifts ~ templates:", templates)
  const [liveRoster, setLiveRoster] = useState(templates?.liveRoster || []);
  // console.log("🚀 ~ AutoAddShifts ~ templates?.templates[0]?.templates:", templates?.templates[0]);
  const [proceedToAddData, setProceedToAddData] = useState(false)

  useEffect(() => {
    if (templates?.liveRoster) {
      templates.liveRoster.length !== 0 ? setProceedToAddData(false) : setProceedToAddData(true)
    }
  }, [templates])

  const [proceedWithCheckedDate, setProceedWithCheckedDate] = useState(false)
  // console.log("🚀 ~ AutoAddShifts ~ proceedWithCheckedDate:", proceedWithCheckedDate)

  // console.log("🚀 ~ AutoAddShifts ~ templateStartDate:", moment(userCurrentStartDate).format("DD/MM/YYYY"))
  // console.log(moment(templates[0]?.templates.startDate).startOf("day").valueOf())
  // console.log(moment(userCurrentStartDate).endOf("day").valueOf())
  useEffect(() => {
    if (templates?.templates) {
      const templateStartDate = moment(templates?.templates[0]?.startDate).startOf("day").valueOf();
      // console.log("🚀 ~ useEffect ~ templateStartDate:", templateStartDate)
      const checkCurrentStartDate = moment(userCurrentStartDate).endOf("day").valueOf();
      if (moment(templateStartDate).format("DD/MM/YYYY") > moment(checkCurrentStartDate).format("DD/MM/YYYY")) {
        setProceedWithCheckedDate(false)
      }
      else {
        setProceedWithCheckedDate(true)
      }
    }
  }, [templates, userCurrentStartDate])

  useEffect(() => {
    if (templates?.templates?.length > 0) {
      const template = templates?.templates[0]; // Assuming you want to use the first template
      const startDate = moment(template.startDate);
      const templateWeeks = template.templateWeeks || 4; // Defaulting to 4 weeks if not provided

      // Calculate the difference in weeks between template start date and userCurrentStartDate
      const weekDifference = moment(userCurrentStartDate).diff(startDate, 'weeks');

      // Calculate the offset to find the start date of the nearest 4-week block
      const blockOffset = Math.floor(weekDifference / templateWeeks) * templateWeeks;
      const adjustedStartDate = startDate.clone().add(blockOffset * 7, 'days');

      // Adjust the roster end date accordingly
      const rosterEndDate = adjustedStartDate.clone().add(templateWeeks * 7, 'days');

      const initialLiveRoster = {
        rotaTemplateId: template?._id,
        rosterStartDate: adjustedStartDate.valueOf(), // Start from the adjusted start date
        rosterEndDate: rosterEndDate.valueOf(), // End after 4 weeks
        templateWeeks: templateWeeks,
        shiftLocation: [] // Initialize as empty array
      };

      const dayNameMap = {
        "Mon": 0,
        "Tue": 1,
        "Wed": 2,
        "Thu": 3,
        "Fri": 4,
        "Sat": 5,
        "Sun": 6,
      };

      const newLiveRoster = template.shiftLocation.map(location => {
        return {
          locationId: location.locationId,
          weekArray: location.weekArray.map(week => {
            return {
              ...week,
              daysArray: week.daysArray.map(day => {
                const dayOffset = dayNameMap[day.dayName];
                const dayOfWeek = adjustedStartDate.clone().add((week.weekNo - 1) * 7 + dayOffset, 'days');
                return {
                  ...day,
                  shiftDate: dayOfWeek.valueOf() // Assign the correct date based on dayName
                };
              })
            };
          })
        };
      });

      initialLiveRoster.shiftLocation = newLiveRoster;

      setLiveRoster([initialLiveRoster]);
    }
  }, [userCurrentStartDate, templates]); // Added 'templates' to dependencies to handle any updates in templates

  // useEffect(() => {
  //   console.log("🚀 ~ AutoAddShifts ~ liveRoster:", liveRoster);
  //   console.log("🚀 ~ AutoAddShifts ~ userCurrentStartDate:", moment(userCurrentStartDate).format("DD/MM/YYYY"));
  // }, [liveRoster]); // Log when liveRoster changes

  const [suFormData, setSuFormData] = useState();
  useEffect(() => {
    setSuFormData(liveRoster[0])
  }, [liveRoster])

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        await postAddMedicineFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      console.log(data?.message)

    } else if (
      method === "postAddMedicineFormData" &&
      data?.status === isSuccess
    ) {
      fetchAllRecords()
      console.log(data?.message)
      setProceedWithCheckedDate(false)
    }
  };

  const postAddMedicineFormData = catchAsync(async (req, res) => {
    // console.log("🚀 ~ postAddMedicineFormData ~ values:", suFormData)
    const response = await postRecords(POST_LIVE_ROSTER_DATA_URL, suFormData);
    // console.log("🚀 ~ postAddMedicineFormData ~ response:", response)
    processPostRecords("postAddMedicineFormData", response);
    setProceedToAddData(false)

  });
  // console.log("🚀 ~ postAddMedicineFormData ~ suFormData:", suFormData)
  useEffect(() => {
    if (proceedWithCheckedDate) {
      if (proceedToAddData) {
        postAddMedicineFormData();
      }
    }
  }, [proceedWithCheckedDate, proceedToAddData]);

  // if (hasMounted.current && proceedToAddData) {
  //   postAddMedicineFormData();
  // }

  return (

    <div>
      {/* {
        proceedToAddData ? (
          <p onClick={postAddMedicineFormData}><strong>Add Data</strong></p>
        ) : (
          <p>no need to add data</p>
        )
      } */}

    </div>
  );
};

export default AutoAddShifts;
