import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getRecords,
  catchAsync,
  isSuccess,
} from "../../helper/helper";
import { GET_MEDICINE_STOCK_URL } from "../../env";
import { CustomOption } from "../../helper/helperJsx";

const MedicineListDetail = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  label,
  name,
  filterBy,
  defaultSelectedValue,
  isDisabled,
  isClearable,
  requiredStar,
  userId,
  onMedicationDataChange
}) => {
  const [medicineSelectBox, setMedicineSelectBox] = useState([]);

  const [isLoading, setIsLoading] = useState(true);
  const [defaultValue, setDefaultValue] = useState(null);
  const [selectedMedicine, setSelectedMedicine] = useState(null);
  const apiQuery = {
    // userId: userId,
    skip: 0,
    limit: 0
  };

  const processFetchRecords = (data) => {
    if (data?.status !== isSuccess) {
      // Handle error here
    }
  };

  const fetchAllRecords = catchAsync(async () => {
    try {
      const response = await getRecords(GET_MEDICINE_STOCK_URL, apiQuery);
      console.log("🚀 ~ medicine list detail ~ response:", response)
      if (response?.status === isSuccess) {
        const filteredMedicationData = response?.data?.result;
        const validMedicationData = filteredMedicationData?.filter(
          (item) => item?._id !== ""
        );
        const getMedicationData = validMedicationData?.map((item) => ({
          value: item?._id,
          label: item?.medicationName,
        }));
        setMedicineSelectBox(getMedicationData);
        setIsLoading(false);
        if (onMedicationDataChange) {
          onMedicationDataChange(validMedicationData); // Pass validMedicationData to the parent component
        }
      } else {
        processFetchRecords(response);
      }
    } catch (error) {
      console.error('Error fetching medication records:', error);
      // Handle error here
    }
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    fetchAllRecords();
  }, [defaultSelectedValue]);

  const handleInputChange = (e) => {
    setIsLoading(false);
  };

  useEffect(() => {
    if (defaultSelectedValue) {
      // Find the default option from medicineSelectBox based on the defaultSelectedValue
      const defaultOption = medicineSelectBox.find(option => option.value === defaultSelectedValue);
      if (defaultOption) {
        setDefaultValue(defaultOption);
      }
    }
  }, [defaultSelectedValue, medicineSelectBox]);

  // const selectedMedicine = medicineSelectBox?.filter((item) => item?._id === "664b6387719f03befbd073d6");

  return (
    <div className={`customSelectBox ${errorClass}`}>
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          <Select
            className="basic-single cursor-pointer"
            classNamePrefix="select"
            name={name}
            value={defaultValue}
            onInputChange={handleInputChange}
            options={medicineSelectBox}
            isDisabled={isDisabled}
            isSearchable
            onChange={(selectedOption) => {
              setDefaultValue(selectedOption);
              onSelect(selectedOption?.value);
            }}
            getOptionLabel={(selectedOption) => selectedOption?.label}
            getOptionValue={(selectedOption) => selectedOption?.value}
            components={{ Option: CustomOption }}
          />
          {label && (
            <label className="floatingLabel">
              {label}
              {requiredStar ? <span className="text-danger">*</span> : null}
            </label>
          )}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default MedicineListDetail;
