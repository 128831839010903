import React from 'react'
import Layout from "../../../../../../components/layouts/Layout";
import EditCareNotes from "../../../EditCareNotes";



function EditMentalWellBeingOthers() {
  return (
    <Layout pageTitleText="Notes">
    <div className="incidents consentCapacityAddNew">
      <div className="mainContent ">
        <div className="mainContentText">
          <h3>Edit Mental Wellbeing Other note</h3>
          <EditCareNotes noteType="MentalWellbeingOther" parentListNoteUrl="/care-plan/general-notes" />
        </div>
      </div>
    </div>
  </Layout>
  )
}

export default EditMentalWellBeingOthers