import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import {
  ADMIN_ID,
  BASE_URL,
  GET_GROUP_INFO_URL,
  PUT_UPDATE_GROUP_DETAILS_URL,
  GET_LIST_ALL_USERS_URL,
} from "../../../env";
import ProgressComponent from "../../../components/general/ProgressComponent";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
  getRecords,
} from "../../../helper/helper";
import { CustomOption } from "../../../helper/helperJsx";

function EditGroupMembers({
  handleShowModel,
  handleCloseModel,
  groupItem,
  fetchRecords,
}) {
  const [isToastRotaSchedule, setIsToast] = useState(false);
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [usersListingData, setUsersListingData] = useState([]);
  const [defaultMembersState, setDefaultMembersState] = useState([]);

  const getAllUsersListApiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: {
        active: true,
        role: {
          $in: [
            "serviceuser",
            "staffmember",
            "manager",
            "supervisor",
            "teamlead",
          ],
        },
      },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const apiQuery = {
    groupId: groupItem && groupItem?._id,
  };

  const validationSchema = Yup.object().shape({
    groupMembers: Yup.array()
      .min(1, "At least one group member is required.")
      .required("Group members is required."),
  });

  const formData = useFormik({
    initialValues: {
      groupId: null,
      groupMembers: [],
      lastModifiedBy: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitClicked(true);
        if (ADMIN_ID) {
          if (!values?.groupMembers?.includes(ADMIN_ID)) {
            values?.groupMembers?.push(ADMIN_ID);
          }
          await handleUpdateGroupInfo(values);
        }
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          1000,
          1000
        );
        setIsFormSubmitClicked(false);
      }
    },
  });

  const {
    handleChange,
    handleSubmit,
    handleBlur,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = formData;

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_GROUP_INFO_URL, apiQuery);
    processFetchRecords("get", response);
  });

  const handleUpdateGroupInfo = catchAsync(async (req, res) => {
    const response = await postRecords(PUT_UPDATE_GROUP_DETAILS_URL, req);
    processFetchRecords("update", response);
  });

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToast(true);
      setIsFormSubmitClicked(false);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "get" && data?.status === isSuccess) {
      const result = data && data?.data;
      const groupMembersIdsArray = data?.data?.groupMembers?.map(
        (item) => item?._id
      );
      const document = {
        groupId: result?._id,
        groupMembers: groupMembersIdsArray,
      };
      setValues(document);
      const getUserListing = await getRecords(
        GET_LIST_ALL_USERS_URL,
        getAllUsersListApiQuery
      );
      if (getUserListing?.status === isSuccess) {
        const validServiceUsers = getUserListing?.data?.users?.filter(
          (item) => item?.name !== ""
        );
        const getUsers = validServiceUsers?.map((item) => ({
          value: item?._id,
          label: item?.name,
          image: item?.profile_picture_url,
        }));
        const findMembersResult = getUsers?.filter((user) =>
          groupMembersIdsArray?.includes(user?.value)
        );
        setDefaultMembersState(findMembersResult);
        setUsersListingData(getUsers);
      }
      setIsLoading(false);
    } else if (method === "update" && data?.status === isSuccess) {
      await fetchRecords();
      setTimeout(() => {
        setIsFormSubmitClicked(false);
        handleCloseModel();
      }, 1000);
      console.log(data);
    }
  };

  const handleInputChange = (e) => {
    setIsLoading(false);
  };

  const handleSetArray = (array) => {
    const filteredData = array?.map((option) => option?.value);
    if (filteredData) {
      setValues({
        ...values,
        groupMembers: filteredData,
      });
      setDefaultMembersState(array);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Add Group Members</Modal.Title>
          </Modal.Header>
          <form onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row mt-3">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <div className="customSelectBox">
                      {isLoading ? (
                        <p className="loadingText">Loading...</p>
                      ) : (
                        <>
                          <Select
                            className="basic-single cursor-pointer"
                            classNamePrefix="select"
                            options={usersListingData}
                            value={defaultMembersState}
                            isSearchable
                            isMulti={true}
                            placeholder="Select"
                            isClearable={false}
                            closeMenuOnSelect={false}
                            onChange={(selectedOption) =>
                              handleSetArray(selectedOption)
                            }
                            onInputChange={handleInputChange}
                            getOptionLabel={(option) => option?.label}
                            getOptionValue={(option) => option?.value}
                            components={{ Option: CustomOption }}
                          />

                          <label className="floatingLabel">Members</label>
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isFormSubmitClicked ? (
                <ProgressComponent />
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={handleCloseModel}
                  >
                    Close
                  </Button>
                  <Button
                    className="btn-sm cursor-pointer"
                    type="submit"
                    variant="primary"
                    onClick={handleSubmit}
                  >
                    Save
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isToastRotaSchedule && <ToastContainer />}
    </>
  );
}

export default EditGroupMembers;
