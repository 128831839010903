import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import Layout from "../../components/layouts/Layout";
import HolidaysGroup from "./HolidaysGroup";
import StaffOverviewBlocks from "../../components/general/StaffOverviewBlocks";
import AbsenceLeave from "./AbsenceLeave";
import StaffWorkLoads from "./StaffWorkLoads";
import Input from "../../components/forms-fields/Input";
import Select from "react-select";
import CustomSelect from "../../components/forms-fields/CustomSelectStaffMembers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import {
  postRecords,
  updateFormFields,
  catchAsync,
  isSuccess,
  calculateTimeDifferenceAndHolidayHours,
  toastErrorMessages,
  toastSuccessMessages,
  handleDateChangeNumeric,
} from "../../helper/helper";
import { POST_HOLIDAY_STAFF_MEMBER_URL } from "../../env";
import {
  handleReload,
  hldRequestTypeOptions,
  CustomOption,
} from "../../helper/helperJsx";

function Holiday() {
  const [showAddHolidayModal, setShowHolidayModal] = useState(false);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isHldRqReason, setIsHldRqReason] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [isToastHolidays, setIsToastHolidays] = useState(false);
  const [startTimeState, setStartTimeState] = useState(false);
  const [endTimeState, setEndTimeState] = useState(false);
  const [isSubmitButton, setIsSubmitButton] = useState(false);

  const validationSchema = Yup.object().shape({
    staffRequester: Yup.string().required("Staff Member is required"),
    hldRqStartDate: Yup.number().required("Start Date is required"),
    hldRqEndDate: Yup.number()
      .required("End Date is required")
      .min(
        Yup.ref("hldRqStartDate"),
        "End Date must be greater than Start Date"
      ),
    hldRequestType: Yup.string().required("Holiday Type is required"),
  });

  const formikFormData = useFormik({
    initialValues: {
      staffRequester: null,
      hldRqStartDate: null,
      hldRqEndDate: null,
      hldRqTotalDays: 0,
      hldRqTotalHours: 0,
      hldRqStatus: "pending",
      hldRequestType: "holiday",
      hldRqReason: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values, { resetForm }) => {
      try {
        await handlePostHolidayFun(values);
      } catch (error) {
        console.error("Submission error:", error);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = formikFormData;

  const processFetchRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastHolidays(true);
      toastErrorMessages(toast?.error, data?.message, "", 1000, 1000);
    } else if (method === "post" && data?.status === isSuccess) {
      setShowHolidayModal(false);
      handleReload();
    }
  };

  const handlePostHolidayFun = catchAsync(async (req, res) => {
    const response = await postRecords(POST_HOLIDAY_STAFF_MEMBER_URL, req);
    console.log(response);
    processFetchRecords("post", response);
  });

  const handleFormUpdate = async (fieldName, value) => {
    setFieldValue(fieldName, value);
  };

  const handleUserSelect = (userId) => {
    handleFormUpdate("staffRequester", userId);
  };

  const handleOpenModal = (e) => {
    e.preventDefault();
    handleFormUpdate("staffRequester", selectedUserId);
    setShowHolidayModal(true);
  };

  const handleCloseModal = () => {
    resetForm();
    setShowHolidayModal(false);
  };

  useEffect(() => {
    if (values?.hldRqStartDate || values?.hldRqEndDate) {
      const result = calculateTimeDifferenceAndHolidayHours(
        startTimeState,
        endTimeState
      );
      handleFormUpdate("hldRqTotalDays", result?.daysDifference);
      handleFormUpdate("hldRqTotalHours", result?.hoursDifference);
    }
  }, [values?.hldRqStartDate, values?.hldRqEndDate]);

  useEffect(() => {
    if (values?.hldRqReason !== "holiday" && values?.hldRqReason === "") {
      setErrorMessage("Reason is required");
      setIsSubmitButton(true);
    }
    if (values?.hldRqReason !== "holiday" && values?.hldRqReason !== "") {
      setErrorMessage(false);
      setIsSubmitButton(false);
    }
  }, [values]);

  const convertToMilliseconds = (htmlDate) => {
    const selectedDate = new Date(htmlDate);
    const millisecondsValue = selectedDate.getTime();
    console.log(millisecondsValue);
    return millisecondsValue;
  };

  const handleShiftTimeChangeToNumeric = async (e) => {
    const { name, value } = e.target;
    if (name === "hldRqStartDate") {
      setStartTimeState(value);
      handleFormUpdate(name, convertToMilliseconds(value));
    }
    if (name === "hldRqEndDate") {
      setEndTimeState(value);
      handleFormUpdate(name, convertToMilliseconds(value));
    }
  };

  return (
    <>
      <Layout pageTitleText="Holiday">
        <div className="holidaysWrapper">
          <div className="row">
            <div className="col-md-12">
              <div className="tabsWrapper">
                <Tab.Container id="myTab" defaultActiveKey="holidaysTab">
                  <div className="tabsHeader">

                    <div className="holidayHeaderStuff">
                      <div className="actionButtons">
                        <ProtectedComponent requiredPermissions={[permissions.ADD]}>
                          <Button
                            className="btn-sm btn-primary"
                            onClick={(event) => handleOpenModal(event)}
                          >
                            Add New Holiday
                          </Button>
                        </ProtectedComponent>
                      </div>
                    </div>

                    <Nav variant="tabs">
                      <Nav.Item>
                        <Nav.Link eventKey="holidaysTab">Holidays</Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="archivedCandidates">
                          Absence & Leaves
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item>
                        <Nav.Link eventKey="staffWorkloads">
                          Staff Workloads
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                  <StaffOverviewBlocks />
                  <Tab.Content>
                    <Tab.Pane eventKey="holidaysTab">
                      <div className="holidaysTabsContent">
                        <HolidaysGroup />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="archivedCandidates">
                      <div className="holidaysTabsContent">
                        <AbsenceLeave />
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="staffWorkloads">
                      <div className="holidaysTabsContent">
                        <StaffWorkLoads />
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </div>
          </div>
        </div>
      </Layout>
      <Modal
        show={showAddHolidayModal}
        onHide={handleCloseModal}
        className="ftcModalPopup"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Staff Holiday</Modal.Title>
        </Modal.Header>
        <form action="" onSubmit={handleSubmit}>
          <Modal.Body>
            <div className="addHolidayWrapper">
              <div className="row">
                <div className="col-md-12">
                  <div className="form-group">
                    <CustomSelect
                      onSelect={handleUserSelect}
                      label={"Staff Members"}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      customError={
                        errors?.staffRequester && touched?.staffRequester
                      }
                      errorMessage={touched?.staffRequester}
                      errorClass={
                        errors?.staffRequester && touched?.staffRequester
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <Input
                      type="date"
                      name="hldRqStartDate"
                      value={startTimeState}
                      onChange={handleShiftTimeChangeToNumeric}
                      onBlur={handleBlur}
                      labelText="Start Date"
                      customError={
                        errors?.hldRqStartDate && touched?.hldRqStartDate
                      }
                      errorMessage={errors?.hldRqStartDate}
                      errorClass={
                        errors?.hldRqStartDate && touched?.hldRqStartDate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <Input
                      type="date"
                      name="hldRqEndDate"
                      value={endTimeState}
                      onChange={handleShiftTimeChangeToNumeric}
                      onBlur={handleBlur}
                      labelText="End Date"
                      customError={
                        touched?.hldRqEndDate && errors?.hldRqEndDate
                      }
                      errorMessage={errors?.hldRqEndDate}
                      errorClass={
                        touched?.hldRqEndDate && errors?.hldRqEndDate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <Input
                      type="number"
                      name="hldRqTotalDays"
                      value={values?.hldRqTotalDays}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelText="Total Days"
                      customError={
                        touched?.hldRqTotalDays && errors?.hldRqTotalDays
                      }
                      errorMessage={errors?.hldRqTotalDays}
                      errorClass={
                        touched?.hldRqTotalDays && errors?.hldRqTotalDays
                          ? "validationError"
                          : ""
                      }
                      disabledStatus={true}
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-6">
                  <div className="form-group">
                    <Input
                      type="number"
                      name="hldRqTotalHours"
                      value={values?.hldRqTotalHours}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelText="Total Hours"
                      customError={
                        errors?.hldRqTotalHours && touched?.hldRqTotalHours
                      }
                      errorMessage={errors?.hldRqTotalHours}
                      errorClass={
                        errors?.hldRqTotalHours && touched?.hldRqTotalHours
                          ? "validationError"
                          : ""
                      }
                      disabledStatus={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <div
                      className={`customSelectBox ${errors?.hldRequestType ? "validationError" : ""
                        }`}
                    >
                      <Select
                        name="hldRequestType"
                        value={hldRequestTypeOptions?.find(
                          (option) => option?.value === values?.hldRequestType
                        )}
                        className="basic-multi-select cursor-pointer"
                        classNamePrefix="select"
                        options={hldRequestTypeOptions}
                        onChange={(selectedOption) => {
                          setFieldValue(
                            "hldRequestType",
                            selectedOption?.value
                          );
                        }}
                        isSearchable
                        getOptionLabel={(option) => option?.label}
                        getOptionValue={(option) => option?.value}
                        components={{ Option: CustomOption }}
                      />
                      <label className="floatingLabel">
                        Holiday Type
                        <span className="text-danger">*</span>
                      </label>
                      {errors?.hldRequestType && (
                        <div className="text-danger">
                          {errors?.hldRequestType}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {values?.hldRequestType !== "holiday" ? (
                  <div className="col-sm-12 col-md-12">
                    <div className="form-group">
                      <Input
                        type="text"
                        name="hldRqReason"
                        value={values?.hldRqReason}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Reason"
                        customError={errorMessage}
                        errorMessage={errorMessage}
                        errorClass={errorMessage ? "validationError" : ""}
                        requiredStar={true}
                      />
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-sm"
              variant="outline-primary"
              onClick={handleCloseModal}
            >
              Close
            </Button>
            {isSubmitButton && values?.hldRequestType !== "holiday" ? (
              <Button className="btn-sm" disabled variant="primary">
                Submit
              </Button>
            ) : (
              <Button className="btn-sm" type="submit" variant="primary">
                Submit
              </Button>
            )}
          </Modal.Footer>
        </form>
      </Modal>
      {isToastHolidays && <ToastContainer />}
    </>
  );
}

export default Holiday;
