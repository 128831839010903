import { useEffect, useState } from "react";
import { ADMIN_AUTH_TOKEN, BASE_URL } from "../env";
import moment from "moment";
import * as XLSX from "xlsx";
import { StyleSheet, Font } from "@react-pdf/renderer";

const isSuccess = "Success";
const isFail = "Fail";
const isManager = false;
const isAdmin = true;
const isUser = false;
const phoneRegExp = /^[\d()+\s-]+$/;
const MAX_FILE_SIZE = 1024 * 1024 * 5;
const passWordRequired =
  "/^(?=.*[A-Z])(?=.*[a-z])(?=.*d)(?=.*[@$!%*?&])[A-Za-zd@$!%*?&]{8,}$/";

const authHeaders = {
  Authorization: `Bearer ${ADMIN_AUTH_TOKEN}`,
  "Content-Type": "application/json",
};

const apiQuery = {
  sortproperty: "createdAt",
  sortorder: -1,
  offset: 0,
  query: {
    critarion: { active: true },
    addedby: "_id email name",
    lastModifiedBy: "_id email name",
  },
};

async function sendRequest(url, method, body, headers) {
  try {
    const response = await fetch(url, {
      method: method,
      headers: headers,
      body: JSON.stringify(body),
    });
    return await response.json();
  } catch (error) {
    console.error("Error:", error);
    throw error;
  }
}

function performRequest(url, method, data) {
  switch (method) {
    case "POST":
    case "POST":
    case "POST":
    case "POST":
      return sendRequest(url, method, data, authHeaders);
    default:
      throw new Error("Invalid method");
  }
}

const getRecords = (url, query) => performRequest(url, "POST", query);
const postRecords = (url, data) => performRequest(url, "POST", data);
const updateRecords = (url, data) => performRequest(url, "POST", data);
const deleteRecords = (url, data) => performRequest(url, "POST", data);

const updateFormFields = (
  e,
  fieldSetter,
  fieldType,
  fieldKey = "",
  fieldVal = ""
) => {
  switch (fieldType) {
    case "text":
      const { name, value } = e?.target;
      fieldSetter((prevFormState) => ({
        ...prevFormState,
        [name]: value,
      }));
      break;
    case "image":
    case "doc":
      const file = e?.target?.files[0];
      fieldSetter((prevFormState) => ({
        ...prevFormState,
        profilePic: file,
      }));
      break;
    case "complianceDoc":
      const files = e?.target?.files[0];
      fieldSetter((prevFormState) => ({
        ...prevFormState,
        file: files,
      }));
      break;
    case "uploadRefDoc":
      const attachements = e?.target?.files[0];
      fieldSetter((prevFormState) => ({
        ...prevFormState,
        attachements: attachements,
      }));
      break;
    case "selfUpdate":
      fieldSetter((prevFormState) => ({
        ...prevFormState,
        [fieldKey]: fieldVal,
      }));
      break;
    default:
      // Handle other cases or throw an error if needed
      break;
  }
};

function formateDate(dateStr, type) {
  const dateObj = new Date(dateStr);
  if (isNaN(dateObj.getTime())) {
    return "N/A";
  }
  switch (type) {
    case "m/d/y":
      return dateObj
        .toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "2-digit",
        })
        .replace(/,/g, "")
        .replace(/\s/g, "/");
    case "m/d/":
      return dateObj
        .toLocaleDateString("en-US", {
          month: "short",
          day: "2-digit",
        })
        .replace(/,/g, "")
        .replace(/\s/g, "/");
    case "m/d":
      return dateObj.toLocaleDateString("en-US", {
        month: "short",
        day: "2-digit",
      });
    case "time":
      const timeOptions = {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      };
      const date = new Date(dateStr);
      const formattedTimes = new Intl.DateTimeFormat(
        "en-US",
        timeOptions
      ).format(date);

      return formattedTimes.toLowerCase().replace(/\s+/g, "");
    case "yyyy-mm-dd":
      return dateObj.toISOString().slice(0, 10);
    case "customFormat":
      const month = dateObj.toLocaleString("en-US", { month: "short" });
      const day = dateObj.getDate();
      const year = dateObj.getFullYear();
      let hours = dateObj.getHours();
      let minutes = dateObj.getMinutes();
      const amPm = hours >= 12 ? "pm" : "am";
      hours = hours % 12 || 12;
      const formattedTime = `${hours}:${
        minutes < 10 ? "0" : ""
      }${minutes}${amPm}`;

      return `${month}/${day}/${year} / ${formattedTime}`;
    default:
      const options = {
        weekday: "short",
        month: "short",
        day: "numeric",
        year: "numeric",
      };
      return dateObj?.toLocaleDateString("en-US", options);
  }
}

function calculateHours(startDate, endDate) {
  const isValidDate = (date) => !isNaN(date?.getTime());

  const startingDate = new Date(startDate);
  const endingDate = new Date(endDate);

  if (isValidDate(startingDate) && isValidDate(endingDate)) {
    const hoursDifference = Math.floor(
      Math.abs(endingDate - startingDate) / (1000 * 60 * 60)
    );
    return hoursDifference;
  } else {
    return "N/A";
  }
}

const catchAsync = (fn) => {
  return async (req, res) => {
    try {
      await fn(req, res);
    } catch (err) {
      console.error("Error", err);
    }
  };
};

function filterColumns(columns, filterFunc) {
  if (!Array.isArray(columns)) {
    throw new Error("Columns must be an array.");
  }
  if (typeof filterFunc !== "function") {
    throw new Error("filterFunc must be a function.");
  }
  const filteredColumns = columns?.filter(filterFunc);
  return filteredColumns;
}

function filterRowsByProperty(rows, filterFunc) {
  if (!Array.isArray(rows)) {
    throw new Error("Rows must be an array.");
  }
  if (typeof filterFunc !== "function") {
    throw new Error("filterFunc must be a function.");
  }
  const filteredRows = rows?.filter(filterFunc);
  return filteredRows;
}

function calculateTimeDifferenceAndHolidayHours(startDate, endDate) {
  if (startDate && endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    if (startDateObj < endDateObj) {
      const timeDifference = endDateObj - startDateObj;
      const millisecondsInADay = 1000 * 60 * 60 * 24;
      const millisecondsInAWeek = millisecondsInADay * 7;

      const daysDifference = Math.floor(timeDifference / millisecondsInADay);
      const weeksDifference = Math.floor(timeDifference / millisecondsInAWeek);
      const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

      return {
        dateError: false,
        daysDifference: daysDifference,
        hoursDifference: hoursDifference,
        weeksDifference: weeksDifference,
        appliedHolidayHours: daysDifference * 8,
      };
    } else {
      return {
        dateError: true,
        weeksDifference: 0,
        daysDifference: 0,
        hoursDifference: 0,
        appliedHolidayHours: 0,
      };
    }
  } else {
    return {
      dateError: false,
      weeksDifference: 0,
      daysDifference: 0,
      hoursDifference: 0,
      appliedHolidayHours: 0,
    };
  }
}

function calculateHolidayHours(startDate, endDate) {
  if (startDate && endDate) {
    const startDateObj = new Date(startDate);
    const endDateObj = new Date(endDate);

    const timeDifference = endDateObj - startDateObj;
    const millisecondsInADay = 1000 * 60 * 60 * 24;
    const millisecondsInAWeek = millisecondsInADay * 7;

    const daysDifference = Math.floor(timeDifference / millisecondsInADay);
    const weeksDifference = Math.floor(timeDifference / millisecondsInAWeek);
    const hoursDifference = Math.floor(timeDifference / (1000 * 60 * 60));

    return {
      dateError: false,
      daysDifference: daysDifference,
      hoursDifference: hoursDifference,
      weeksDifference: weeksDifference,
      appliedHolidayHours: daysDifference * 8,
    };
  } else {
    return {
      dateError: false,
      weeksDifference: 0,
      daysDifference: 0,
      hoursDifference: 0,
      appliedHolidayHours: 0,
    };
  }
}

function displayEnumProperty(propertyName, propertyValue) {
  switch (propertyName) {
    case "role":
      switch (propertyValue) {
        case "user":
          return "User";
        case "superadmin":
          return "Super Admin";
        case "serviceuser":
          return "Service User";
        case "staffmember":
          return "Staff Member";
        default:
          return "";
      }
    case "staffRoles":
      switch (propertyValue) {
        case "superadmin":
          return "Super Admin";
        case "slthead":
          return "Senior Leadership Team (SLT) HEAD";
        case "sltgeneral":
          return "Senior Leadership Team (General)";
        case "officestaff":
          return "Office Staff";
        case "financeadmin":
          return "Finance Admin";
        case "staffuser":
          return "Staff User";
        case "supervisionaccess":
          return "Supervision Access";
        case "rosteraccess":
          return "Roster Access";
        default:
          return "";
      }
    case "attendanceStatus":
      switch (propertyValue) {
        case "present":
          return "Present";
        case "absent":
          return "Absent";
        default:
          return "";
      }
    case "applicationStatus":
      switch (propertyValue) {
        case "pending":
          return "Pending";
        case "inverview":
          return "Interview";
        case "docsub":
          return "DocSub";
        case "dbsc":
          return "DBSC";
        case "finalintro":
          return "Final Intro";
        case "onboarding":
          return "On Boarding";
        case "hired":
          return "Hired";
        default:
          return "";
      }
    case "prpsTrgStatus":
      switch (propertyValue) {
        case "pending":
          return "Pending";
        case "joined":
          return "Joined";
        case "completed":
          return "Completed";
        case "underprocess":
          return "Under Process";
        case "overdue":
          return "Over Due";
        case "cancelled":
          return "Cancelled";
        case "postponed":
          return "Postponed";
        default:
          return "";
      }
    case "hldRqStatus":
      switch (propertyValue) {
        case "pending":
          return "Pending";
        case "approved":
          return "Approved";
        case "rejected":
          return "Rejected";
        default:
          return "Unknown Status";
      }
    case "hldRequestType":
      switch (propertyValue) {
        case "sickLeave":
          return "Sick Leave";
        case "absent":
          return "Absent";
        case "holiday":
          return "Holiday";
        default:
          return "";
      }
    case "staffDesignation":
      switch (propertyValue) {
        case "staffmember":
          return "Staff Member";
        case "manager":
          return "Manager";
        case "supervisor":
          return "Supervisor";
        case "teamlead":
          return "Team Lead";
        default:
          return "";
      }
    case "stfTrgStatus":
      switch (propertyValue) {
        case "pending":
          return "Pending";
        case "joined":
          return "Joined";
        case "completed":
          return "Completed";
        case "underprocess":
          return "Under Process";
        case "overdue":
          return "Over Due";
        case "cancelled":
          return "Cancelled";
        case "postponed":
          return "Postponed";
        default:
          return "";
      }
    case "sprStatus":
      switch (propertyValue) {
        case "assigned":
          return "Assigned";
        case "completed":
          return "Completed";
        case "overdue":
          return "Over Due";
        default:
          return "";
      }
    case "complianceDoc":
      switch (propertyValue) {
        case "pending":
          return "Pending";
        case "rejected":
          return "Rejected";
        case "approved":
          return "Approved";
        default:
          return "";
      }
    default:
      return propertyValue;
  }
}
function filterArrayBySearchTerm(array, searchTerm, propertyAccessor) {
  if (searchTerm == null || searchTerm.trim() === "") {
    return array;
  }

  const lowerSearchTerm = searchTerm?.toLowerCase();

  return array.filter((item) => {
    const itemPropertyValue = propertyAccessor(item)?.toLowerCase() ?? "";
    return itemPropertyValue.includes(lowerSearchTerm);
  });
}
function filterRecordsByCurrentMonthAndLabel(records, label) {
  const currentMonth = new Date().getMonth();
  return records.filter((row) => {
    return (
      row?.hldRequestType === label &&
      new Date(row?.hldRqStartDate).getMonth() === currentMonth
    );
  });
}
function calculateNextYearEndDate(startDate) {
  if (startDate) {
    const parts = startDate?.split("-");
    if (parts.length === 3) {
      const year = parseInt(parts[0]);
      const newYear = year;
      return `${newYear}-12-31`;
    }
  }
  return "";
}

function useCurrentTime() {
  const [time, setTime] = useState(new Date());

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return time;
}

function toastSuccessMessages(toast, message, redirectUrl, autoClose, onClose) {
  toast(message, {
    autoClose,
    onClose: () => {
      setTimeout(() => {
        if (redirectUrl !== "") {
          window.location.href = redirectUrl;
        }
      }, onClose);
    },
    theme: "colored",
  });
}

function toastErrorMessages(toast, message, redirectUrl, autoClose, onClose) {
  toast(message, {
    autoClose,
    onClose: () => {
      setTimeout(() => {
        if (redirectUrl !== "") {
          window.location.href = redirectUrl;
        }
      }, onClose);
    },
    theme: "colored",
  });
}

const calculateAgeDifference = (milliseconds) => {
  const dateObject = new Date(parseInt(milliseconds, 10));
  const birthYear = dateObject?.getFullYear();
  const currentYear = new Date().getFullYear();
  const ageDifference = currentYear - birthYear;
  return ageDifference;
};

const handleDateChangeNumeric = (dateValue, format) => {
  const dateObject = new Date(dateValue);

  switch (format) {
    case "numeric":
      return dateObject.getTime();

    case "customTimeString":
      return dateObject.toLocaleDateString();

    case "yyyy-MM-dd":
      const formattedDate = `${dateObject.getFullYear()}-${(
        dateObject.getMonth() + 1
      )
        .toString()
        .padStart(2, "0")}-${dateObject.getDate().toString().padStart(2, "0")}`;
      return formattedDate;

    case "htmlTimeToNumeric":
      const [hours, minutes] = dateValue.split(":");
      dateObject.setHours(parseInt(hours, 10));
      dateObject.setMinutes(parseInt(minutes, 10));
      return dateObject.getTime();

    default:
      return null;
  }
};

const calculateOnlyTimeDifferenceDigits = (shiftStartTime, shiftEndTime) => {
  const startDateTime = new Date(shiftStartTime);
  const endDateTime = new Date(shiftEndTime);
  if (isNaN(startDateTime) || isNaN(endDateTime)) {
    return null;
  }
  const timeDifferenceMs = endDateTime - startDateTime;
  const roundedTimeDifference = Math.floor(timeDifferenceMs / (1000 * 60));
  return isNaN(roundedTimeDifference) ? null : roundedTimeDifference;
};

const openLocationToGoogleMaps = (address) => {
  const formattedAddress = address?.replace(/ /g, "+");
  const googleMapsURL = `https://www.google.com/maps/search/?api=1&query=${formattedAddress}`;
  window.open(googleMapsURL, "_blank");
};

const handleRedirectIfProfileNotPresent = (
  localStorageItem,
  navigationLink,
  navigate
) => {
  const storedValue = localStorage.getItem(localStorageItem);
  if (storedValue === null || storedValue === undefined) {
    navigate(navigationLink);
  }
};

function calculateAppointmentDuration(
  start,
  end,
  setDurationHours,
  setDurationMinutes,
  setAptMinutes
) {
  const startTime = moment(start, "HH:mm").toDate();
  const endTime = moment(end, "HH:mm").toDate();

  if (!isNaN(startTime) && !isNaN(endTime)) {
    const durationInMilliseconds = endTime - startTime;
    const hours = Math.floor(durationInMilliseconds / (1000 * 60 * 60));
    const minutes = Math.floor(
      (durationInMilliseconds % (1000 * 60 * 60)) / (1000 * 60)
    );
    const durationInMinutes = durationInMilliseconds / (1000 * 60);

    setAptMinutes(durationInMinutes);
    setDurationHours(hours);
    setDurationMinutes(minutes);
  } else {
    setDurationHours(0);
    setDurationMinutes(0);
    setAptMinutes(0);
  }
}

const ExportButton = ({ data, data2, patientName, removeMoreColumns }) => {
  const formatDate = (milliseconds) => {
    return moment(milliseconds).format("DD/MM/YYYY / hh:mm:ss A");
  };
  const handleExport = () => {
    // Preprocess data to format dates before exporting
    const formattedData = data.map(
      ({
        lastModifiedBy,
        _id,
        userId,
        keycontacts,
        riskAssesments,
        __v,
        addedby,
        eventDateTime,
        createdAt,
        updatedAt,
        ...item
      }) => {
        removeMoreColumns.forEach((column) => {
          delete item[column];
        });

        const formattedNoteDetails =
          item?.noteDetails &&
          item?.noteDetails.replace(/<\/p>/g, " ").replace(/<[^>]+>/g, "");

        return {
          ...item,
          addedby: addedby?.name,
          eventDateTime: formatDate(eventDateTime),
          createdAt: formatDate(createdAt),
          updatedAt: formatDate(updatedAt),
          noteDetails: formattedNoteDetails,
        };
      }
    );

    const formattedData2 = [{ "Service User": patientName }];

    // Add title row with patient's name
    // const titleRow = { patientName: patientName };
    // const dataWithTitles = [titleRow, ...formattedData];

    const fileType =
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";
    const fileName = patientName;

    // Convert dataWithTitles array to worksheet
    let ws = XLSX.utils.json_to_sheet(formattedData2);

    // Add second data set to existing worksheet
    const ws2 = XLSX.utils.json_to_sheet(formattedData, {
      header: Object.keys(formattedData[0]),
      skipHeader: true,
    });

    XLSX.utils.sheet_add_json(ws, formattedData, {
      header: Object.keys(formattedData[0]),
      skipHeader: false,
      origin: -1,
    });

    // Create workbook
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

    // Write workbook to array buffer
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    // Convert array buffer to Blob
    const blob = new Blob([excelBuffer], { type: fileType });

    // Create download link and trigger download
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName + fileExtension;
    a.click();
  };

  return <span onClick={handleExport}>Export as xlsx</span>;
};

export const logout = () => {
  // Iterate through all keys in localStorage
  for (let i = 0; i < localStorage.length; i++) {
    const key = localStorage.key(i);

    // Check if the key contains the BASE_URL
    if (key && key.includes(BASE_URL)) {
      localStorage.removeItem(key);
    }
  }

  // Remove token specifically, if not covered by BASE_URL
  localStorage.removeItem("token");
  localStorage.removeItem("profileUserDetails");
  localStorage.removeItem("staffRoles");
  localStorage.removeItem("reduxState");
  localStorage.removeItem("userId");
  localStorage.removeItem("userLoggedInDetails");

  // Redirect to login page
  window.location.href = "/";
};

// Skip html tags
const stripHtmlTagsAndSlice = (html, maxLength) => {
  return html.replace(/<[^>]+>/g, "").slice(0, maxLength) + "...";
};

const fontFamily = Font.register({
  family: "Ubuntu",
  fonts: [
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "bold",
    },
    {
      src: "https://fonts.gstatic.com/s/questrial/v13/QdVUSTchPBm7nuUeVf7EuStkm20oJA.ttf",
      fontWeight: "normal",
      fontStyle: "italic",
    },
  ],
});
// PDF Style
const pdfStyles = StyleSheet.create({
  pages: {
    padding: "10px",
  },
  sectionTitle: {
    fontSize: "16px",
    fontWeight: "black",
    marginBottom: "5px",
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#f0f0f0",
  },
  sectionSubTitle: {
    fontSize: "14px",
    fontWeight: "black",
    marginBottom: "5px",
    textAlign: "center",
    padding: "10px",
    backgroundColor: "#f0f0f0",
  },
  smallFont: {
    fontSize: "6px",
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  tableHead: {
    flexDirection: "row",
    justifyContent: "space-between",
    backgroundColor: "#f0f0f0",
    padding: "10px 10px",
    marginBottom: "5px",
    fontSize: "10px",
    fontWeight: "bold",
    wordWrap: "break-word",
  },
  tabBody: {
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "10px 10px",
    borderBottom: "1px solid #ccc",
    fontSize: "10px",
    wordWrap: "break-word",
  },
  singleDataBody: {
    padding: "10px 10px",
    borderBottom: "1px solid #ccc",
    fontSize: "10px",
    wordWrap: "break-word",
  },
  singleDataBodyHeding: {
    fontSize: "12px",
  },
  wordwrap: {
    flexBasis: "50%",
    wordWrap: "break-word",
  },
  line: {
    width: "100%",
    borderBottomColor: "#000000",
    borderBottomWidth: 1,
    marginTop: 10,
    marginBottom: 10,
  },
  imageHumanBody: {
    width: "200px",
    height: "200px",
  },
});
export {
  apiQuery,
  getRecords,
  postRecords,
  updateRecords,
  deleteRecords,
  updateFormFields,
  formateDate,
  calculateHours,
  isSuccess,
  isFail,
  isManager,
  isAdmin,
  isUser,
  catchAsync,
  filterColumns,
  filterRowsByProperty,
  calculateTimeDifferenceAndHolidayHours,
  displayEnumProperty,
  filterArrayBySearchTerm,
  filterRecordsByCurrentMonthAndLabel,
  calculateHolidayHours,
  calculateNextYearEndDate,
  useCurrentTime,
  toastSuccessMessages,
  toastErrorMessages,
  calculateAgeDifference,
  phoneRegExp,
  MAX_FILE_SIZE,
  passWordRequired,
  handleRedirectIfProfileNotPresent,
  handleDateChangeNumeric,
  calculateOnlyTimeDifferenceDigits,
  openLocationToGoogleMaps,
  calculateAppointmentDuration,
  ExportButton,
  stripHtmlTagsAndSlice,
  pdfStyles,
};
