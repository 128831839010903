import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/forms-fields/Input";
import TextArea from "../../../components/forms-fields/TextArea";
import ProgressComponent from "../../../components/general/ProgressComponent";
import CustomSelectWeekDays from "../../../components/forms-fields/CustomSelectWeek";
import CustomSelectDays from "../../../components/forms-fields/CustomSelectDays";
import CustomSelectPatient from "../../../components/forms-fields/CustomSelectServiceUsers";
import CustomSelectStaffMembers from "../../../components/forms-fields/CustomSelectMultipleStaffMembers";
import {
  postRecords,
  isSuccess,
  catchAsync,
  toastErrorMessages,
} from "../../../helper/helper";
import {
  twoWeeksOptions,
  fourWeeksOptions,
  TIME_10_16,
  TIME_08_14,
  TIME_14_20,
  TIME_08_20,
  TIME_20_08,
  defaultHours,
  handleTimeChangeToNumeric,
  useShiftEffect,
  handleSelectPreDefinedTime,
  handleReload,
} from "../../../helper/helperJsx";
import { ADMIN_ID, POST_NEW_ROTA_TEMPLATE_SCHEDULE_URL } from "../../../env";

function CreateRotaShift({
  handleShowModel,
  handleCloseModel,
  tempData,
  templateWeekNum,
  activeWeekTabNum,
  selectedLocationId,
  fetchAllRecords,
  scheduleTitle,
}) {
  const [isLoading, setIsLoading] = useState(true);
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [shiftStartTimeState, setShiftStartTimeState] = useState(
    TIME_10_16?.startTime
  );
  const [shiftEndTimeState, setShiftEndTimeState] = useState(
    TIME_10_16?.endTime
  );
  const [isOverNightStay, setIsOverNightStay] = useState(false);
  const [isSleepOver, setIsSleepOver] = useState(false);
  const [totalShiftHours, setTotalShiftHours] = useState(defaultHours);
  const [isToastRotaSchedule, setIsToastRotaSchedule] = useState(false);
  const [shiftPreDefinedTimeState1, setShiftPreDefinedTimeState1] =
    useState(true);
  const [shiftPreDefinedTimeState2, setShiftPreDefinedTimeState2] =
    useState(false);
  const [shiftPreDefinedTimeState3, setShiftPreDefinedTimeState3] =
    useState(false);
  const [shiftPreDefinedTimeState4, setShiftPreDefinedTimeState4] =
    useState(false);
  const [shiftPreDefinedTimeState5, setShiftPreDefinedTimeState5] =
    useState(false);

  const validationSchema = Yup?.object().shape({
    // patientId: Yup?.string()?.required("Service User is required."),
    days: Yup?.array().min(1, "Please select at least one day."),
    shiftStartTime: Yup?.number()?.required("Start Time is required."),
    shiftEndTime: Yup.number()
      .required("End Time is required")
      .min(
        Yup.ref("shiftStartTime"),
        "End Time must be greater than Start Time"
      ),
    secondaryWeeks: Yup?.array().min(1, "Please select at least one week."),
    workHrs: Yup?.string()?.required("Work Hours is required."),
    note: Yup?.string(),
    isSleepOver: Yup?.boolean(),
  });

  const rotaScheduleFormData = useFormik({
    initialValues: {
      tempId: "",
      locationId: "",
      staffUserId: [],
      patientId: "",
      secondaryWeeks: [],
      days: [],
      shiftStartTime: "",
      shiftEndTime: "",
      workHrs: null,
      isSleepOver: false,
      isOverNightStay: false,
      note: "",
      addedby: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      setValues((prevValues) => ({
        ...prevValues,
        note: values?.note?.trim(),
      }));
      try {
        setIsFormSubmitClicked(true);
        await addRotaScheduleFunc(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          1000,
          1000
        );
        setIsFormSubmitClicked(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = rotaScheduleFormData;

  const processFetchRecords = async (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastRotaSchedule(true);
      setIsFormSubmitClicked(false);
      toastErrorMessages(toast?.error, data?.message, "", 500, 500);
    } else if (method === "post" && data?.status === isSuccess) {
      await fetchAllRecords();
      handleCloseModel();
    }
  };

  const addRotaScheduleFunc = catchAsync(async (req, res) => {
    const response = await postRecords(
      POST_NEW_ROTA_TEMPLATE_SCHEDULE_URL,
      req
    );
    console.log("api response", response);
    processFetchRecords("post", response);
  });

  const handleFormUpdate = async (fieldName, value) => {
    setFieldValue(fieldName, value);
  };

  const handleDaysSelect = (array) => {
    setFieldValue("days", array);
  };

  const handleWeeksSelect = (array) => {
    setFieldValue("secondaryWeeks", array);
  };

  const handleStaffSelect = (array) => {
    handleFormUpdate("staffUserId", array);
  };

  const handlePatientSelect = (patientId) => {
    handleFormUpdate("patientId", patientId);
  };

  const handleTimeChangeNumericFunc = async (e, name, setFieldValue) => {
    const numericValue = await handleTimeChangeToNumeric(e);
    if (numericValue) {
      setFieldValue(name, numericValue);
    }
  };

  useShiftEffect(values, setTotalShiftHours, setFieldValue);

  const handleSetValues = async () => {
    await setValues({
      tempId: tempData?._id,
      locationId: selectedLocationId,
      secondaryWeeks: [activeWeekTabNum?.toString()],
      shiftStartTime: TIME_10_16?.startTimeNum,
      shiftEndTime: TIME_10_16?.endTimeNum,
      staffUserId: [],
      patientId: "",
      days: [],
      workHrs: null,
      isSleepOver: false,
      isOverNightStay: false,
      note: "",
      addedby: ADMIN_ID,
    });
    setIsLoading(false);
  };

  const handleOvernightStayChange = (valuesSetter) => {
    const updatedValue = !isOverNightStay;
    setIsOverNightStay(updatedValue);
    setIsSleepOver(false);
    valuesSetter("isOverNightStay", updatedValue);
    valuesSetter("isSleepOver", false);
  };

  const handleOvernightSleepChange = (valuesSetter) => {
    const updatedValue = !isSleepOver;
    setIsSleepOver(updatedValue);
    setIsOverNightStay(false);
    valuesSetter("isSleepOver", updatedValue);
    valuesSetter("isOverNightStay", false);
  };

  useEffect(() => {
    if (tempData) {
      handleSetValues();
    }
  }, [tempData]);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>{scheduleTitle}</Modal.Title>
          </Modal.Header>
          <form method="post" onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row align-items-center mt-4">
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectDays
                      label="Days"
                      defaultDays={"Monday"}
                      onSelect={handleDaysSelect}
                      omBlurFunc={handleBlur}
                      customError={touched?.days && errors?.days}
                      errorMessage={errors?.days}
                      errorClass={
                        touched?.days && errors?.days ? "validationError" : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectWeekDays
                      onSelect={handleWeeksSelect}
                      label="Week"
                      filterBy="weeks"
                      defaultWeeks={activeWeekTabNum?.toString()}
                      weekOptions={
                        templateWeekNum === 2
                          ? twoWeeksOptions
                          : fourWeeksOptions
                      }
                      omBlurFunc={handleBlur}
                      customError={
                        touched?.secondaryWeeks && errors?.secondaryWeeks
                      }
                      errorMessage={errors?.secondaryWeeks}
                      errorClass={
                        touched?.secondaryWeeks && errors?.secondaryWeeks
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectStaffMembers
                      onSelect={handleStaffSelect}
                      label="Staff Name"
                      isMulti={true}
                      onBlur={handleBlur}
                      customError={touched?.staffUserId && errors?.staffUserId}
                      errorMessage={errors?.staffUserId}
                      errorClass={
                        touched?.staffUserId &&
                        errors?.staffUserId &&
                        "validationError"
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectPatient
                      onSelect={handlePatientSelect}
                      label="Service User"
                      onChange={handleChange}
                      omBlurFunc={handleBlur}
                      customError={touched?.patientId && errors?.patientId}
                      errorMessage={errors?.patientId}
                      errorClass={
                        touched?.patientId && errors?.patientId
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="custom-d-grid">
                  <Input
                    type="time"
                    name="shiftStartTime"
                    value={shiftStartTimeState}
                    onChange={(e) => {
                      handleTimeChangeNumericFunc(
                        e,
                        "shiftStartTime",
                        setFieldValue
                      );
                      setShiftStartTimeState(e?.target?.value);
                    }}
                    onBlur={handleBlur}
                    labelText="Start Time"
                    customError={
                      touched?.shiftStartTime && errors?.shiftStartTime
                    }
                    errorMessage={errors?.shiftStartTime}
                    errorClass={
                      touched?.shiftStartTime && errors?.shiftStartTime
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                  <Input
                    type="time"
                    name="shiftEndTime"
                    value={shiftEndTimeState}
                    onChange={(e) => {
                      handleTimeChangeNumericFunc(
                        e,
                        "shiftStartTime",
                        setFieldValue
                      );
                      setShiftEndTimeState(e?.target?.value);
                    }}
                    onBlur={handleBlur}
                    labelText="End Time"
                    customError={touched?.shiftEndTime && errors?.shiftEndTime}
                    errorMessage={errors?.shiftEndTime}
                    errorClass={
                      touched?.shiftEndTime && errors?.shiftEndTime
                        ? "validationError"
                        : ""
                    }
                    requiredStar={true}
                  />
                  <div className="ui-ux-color font-size">
                    {totalShiftHours + "  "}Hours
                  </div>
                </div>
                <div className="d-flex-space-between mb-3">
                  <label className="radioContainer">
                    <input
                      type="radio"
                      className="radioBoxSize"
                      name="shiftTime"
                      checked={shiftPreDefinedTimeState1}
                      onChange={(e) => {
                        handleSelectPreDefinedTime(
                          e,
                          1,
                          setShiftPreDefinedTimeState1,
                          setShiftStartTimeState,
                          setShiftEndTimeState,
                          setFieldValue,
                          TIME_10_16?.startTimeNum,
                          TIME_10_16?.endTimeNum,
                          setShiftPreDefinedTimeState2,
                          setShiftPreDefinedTimeState3,
                          setShiftPreDefinedTimeState4,
                          setShiftPreDefinedTimeState5
                        );
                      }}
                    />
                    <span className="labelText radioLabel">
                      {TIME_10_16?.label}
                    </span>
                    <span className="radioMark"></span>
                  </label>
                  <label className="radioContainer">
                    <input
                      type="radio"
                      className="radioBoxSize"
                      name="shiftTime"
                      checked={shiftPreDefinedTimeState2}
                      onChange={(e) => {
                        handleSelectPreDefinedTime(
                          e,
                          2,
                          setShiftPreDefinedTimeState2,
                          setShiftStartTimeState,
                          setShiftEndTimeState,
                          setFieldValue,
                          TIME_08_14?.startTimeNum,
                          TIME_08_14?.endTimeNum,
                          setShiftPreDefinedTimeState1,
                          setShiftPreDefinedTimeState3,
                          setShiftPreDefinedTimeState4,
                          setShiftPreDefinedTimeState5
                        );
                      }}
                    />
                    <span className="labelText radioLabel">
                      {TIME_08_14?.label}
                    </span>
                    <span className="radioMark"></span>
                  </label>
                  <label className="radioContainer">
                    <input
                      type="radio"
                      className="radioBoxSize"
                      name="shiftTime"
                      checked={shiftPreDefinedTimeState3}
                      onChange={(e) => {
                        handleSelectPreDefinedTime(
                          e,
                          3,
                          setShiftPreDefinedTimeState3,
                          setShiftStartTimeState,
                          setShiftEndTimeState,
                          setFieldValue,
                          TIME_14_20?.startTimeNum,
                          TIME_14_20?.endTimeNum,
                          setShiftPreDefinedTimeState1,
                          setShiftPreDefinedTimeState2,
                          setShiftPreDefinedTimeState4,
                          setShiftPreDefinedTimeState5
                        );
                      }}
                    />
                    <span className="labelText radioLabel">
                      {TIME_14_20?.label}
                    </span>
                    <span className="radioMark"></span>
                  </label>
                  <label className="radioContainer mt-2">
                    <input
                      type="radio"
                      className="radioBoxSize"
                      name="shiftTime"
                      checked={shiftPreDefinedTimeState4}
                      onChange={(e) => {
                        handleSelectPreDefinedTime(
                          e,
                          4,
                          setShiftPreDefinedTimeState4,
                          setShiftStartTimeState,
                          setShiftEndTimeState,
                          setFieldValue,
                          TIME_08_20?.startTimeNum,
                          TIME_08_20?.endTimeNum,
                          setShiftPreDefinedTimeState1,
                          setShiftPreDefinedTimeState3,
                          setShiftPreDefinedTimeState2,
                          setShiftPreDefinedTimeState5
                        );
                      }}
                    />
                    <span className="labelText radioLabel">
                      {TIME_08_20?.label}
                    </span>
                    <span className="radioMark"></span>
                  </label>
                  <label className="radioContainer mt-2">
                    <input
                      type="radio"
                      className="radioBoxSize"
                      name="shiftTime"
                      checked={shiftPreDefinedTimeState5}
                      onChange={(e) => {
                        handleSelectPreDefinedTime(
                          e,
                          5,
                          setShiftPreDefinedTimeState5,
                          setShiftStartTimeState,
                          setShiftEndTimeState,
                          setFieldValue,
                          TIME_20_08?.startTimeNum,
                          TIME_20_08?.endTimeNum,
                          setShiftPreDefinedTimeState1,
                          setShiftPreDefinedTimeState2,
                          setShiftPreDefinedTimeState3,
                          setShiftPreDefinedTimeState4
                        );
                      }}
                    />
                    <span className="labelText radioLabel">
                      {TIME_20_08?.label}
                    </span>
                    <span className="radioMark"></span>
                  </label>
                </div>
                <div className="mb-3 d-flex-gap-43">
                  <label className="radioContainer cursor-pointer">
                    <input
                      type="checkbox"
                      className="radioBoxSize cursor-pointer"
                      name="overnightOption"
                      checked={isOverNightStay}
                      onChange={() => handleOvernightStayChange(setFieldValue)}
                    />
                    <span className="labelText radioLabel">
                      {"Waking Night"}
                    </span>
                    <span className="radioMark"></span>
                  </label>
                  <label className="radioContainer cursor-pointer">
                    <input
                      type="checkbox"
                      className="radioBoxSize cursor-pointer"
                      name="overnightOption"
                      checked={isSleepOver}
                      onChange={() => handleOvernightSleepChange(setFieldValue)}
                    />
                    <span className="labelText radioLabel">
                      {"Overnight Sleep"}
                    </span>
                    <span className="radioMark "></span>
                  </label>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <TextArea
                      type="text"
                      name="note"
                      value={values?.note}
                      onChange={(e) =>
                        setFieldValue("note", e?.target?.value)
                      }
                      omBlurFunc={handleBlur}
                      labelText="Note (Optional)"
                      rows={5}
                      cols={5}
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isFormSubmitClicked ? (
                <ProgressComponent />
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={handleCloseModel}
                  >
                    Cancel
                  </Button>

                  <Button className="btn-sm" type="submit" variant="primary">
                    Save
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isToastRotaSchedule && <ToastContainer />}
    </>
  );
}

export default CreateRotaShift;
