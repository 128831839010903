import React from "react";

function OtpInput({ otp, onOtpChange, errorMessage }) {
  const inputRefs = Array(6)
    .fill(null)
    .map(() => React.createRef());

  const handleInputChange = (e, index) => {
    const value = e.target.value;
    if (/^\d*$/.test(value) && value.length <= 1) {
      const newOtp = [...otp];
      newOtp[index] = value;
      onOtpChange(newOtp);
      if (index < 5 && value) {
        inputRefs[index + 1].current.focus();
      }
    }
  };

  const handleOnKeyDown = (e, index) => {
    const value = e.target.value;
    if (e.key === "Backspace" && index > 0 && !value) {
      inputRefs[index - 1].current.focus();
    }
  };

  return (
    <div className="otpContainer">
      <div className={`form-group ${errorMessage ? "validationError" : null}`}>
        {errorMessage
          ? otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleOnKeyDown(e, index)}
                ref={inputRefs[index]}
              />
            ))
          : otp.map((digit, index) => (
              <input
                key={index}
                type="text"
                maxLength="1"
                value={digit}
                onChange={(e) => handleInputChange(e, index)}
                onKeyDown={(e) => handleOnKeyDown(e, index)}
                ref={inputRefs[index]}
              />
            ))}
      </div>
    </div>
  );
}

export default OtpInput;
