import React, { useState, useEffect } from "react";
import Select from "react-select";
import { getRecords, catchAsync, isSuccess } from "../../helper/helper";
import { BASE_URL, GET_ALL_SUPERVISIONS_TEMPLATES_URL } from "../../env";

const CustomSelect = ({
  onSelect,
  errorClass,
  customError,
  errorMessage,
  defaultValue,
  label,
}) => {
  const [userData, setUsersData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [defaultSelection, setDefaultSelection] = useState(null);
  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: -1,
    offset: 0,
    query: {
      critarion: { active: true },
      addedby: "_id email name",
      lastModifiedBy: "_id email name",
    },
  };

  const processTemplateData = (responseData) => {
    if (responseData?.status === isSuccess) {
      const getUsers = responseData?.data?.supervisionQuestionTemplates?.map(
        (item) => ({
          value: item?._id,
          label: item?.title,
        })
      );

      const foundUser = getUsers?.find((user) => user?.value === defaultValue);
      if (!defaultSelection && getUsers?.length > 0) {
        if (foundUser) {
          setDefaultSelection(foundUser);
          onSelect(foundUser?.value);
        } else {
          setDefaultSelection(getUsers[0]);
          onSelect(getUsers[0]?.value);
        }
      }
      setUsersData(getUsers);
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(
      GET_ALL_SUPERVISIONS_TEMPLATES_URL,
      apiQuery
    );
    processTemplateData(response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const handleInputChange = (e) => {
    setIsLoading(false);
  };

  return (
    <div className={`customSelectBox ${errorClass}`}>
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          <Select
            className="basic-single cursor-pointer"
            classNamePrefix="select"
            value={defaultSelection}
            options={userData}
            isSearchable
            isClearable={false}
            onChange={(selectedOption) => {
              setDefaultSelection(selectedOption);
              onSelect(selectedOption?.value);
            }}
            onInputChange={handleInputChange}
            getOptionLabel={(selectedOption) => selectedOption?.label}
            getOptionValue={(selectedOption) => selectedOption?.value}
          />
          {label && <label className="floatingLabel">{label}</label>}
          {customError && <div className="text-danger">{errorMessage}</div>}
        </>
      )}
    </div>
  );
};

export default CustomSelect;
