import React from "react";
import { useNavigate } from "react-router-dom";
import pageTitleIcon from "../../assets/images/pageTitleBack.svg";

function PageTitle({ pageTitleProps }) {
  const navigate = useNavigate();

  const handleBackClick = () => {
    if (pageTitleProps === "Rota Template") {
      navigate("#", { state: { navigateState: true } });
    } else {
      window.history.back();
    }
  };

  return (
    <h3 className="pageTitleText cursor-pointer" onClick={handleBackClick}>
      <img
        className="cursor-pointer"
        src={pageTitleIcon}
        alt={pageTitleProps}
      />{" "}
      {pageTitleProps}
    </h3>
  );
}

export default PageTitle;
