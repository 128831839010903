import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import TextArea from "../../components/forms-fields/TextArea";
import Input from "../../components/forms-fields/Input";
import { addHealthIssue } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_HEALTH_ISSUES_URL } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import HealthCategories from "./HealthCategories";

function AddHealthIssues({ handleShowHide, userDataId }) {
  const [userId, setUserId] = useState(userDataId);
  const handleShowHideModal = () => {
    handleShowHide((prevShow) => !prevShow);
  };
  const [isStaffId, setIsStaffId] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [isSuFormData, setIsSuFormData] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddHealthIssue, setIsToastAddHealthIssue] = useState(false);
  const [parentSubCatValidationStatus, setParentSubCatValidationStatus] = useState(false);
  const [suFormData, setSuFormData] = useState({
    userId: userId,
    category: selectedCategoryId,
    subcategory: selectedCategoryId,
    healthNote: "",
    complaint: "",
    status: true,
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldTouched,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addHealthIssue,
    onSubmit: async (values) => {
      try {

        console.log("Form Data:", values);
        setIsSuFormData(false);
        setIsSubmitButtonClicked(true);
        await postHealthIssueFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processFetchRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddHealthIssue(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (
      method === "postHealthIssueFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddHealthIssue(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postHealthIssueFormData = catchAsync(async (req, res) => {
    // console.log({ req });
    const response = await postRecords(POST_HEALTH_ISSUES_URL, req);
    processFetchRecords("postHealthIssueFormData", response);
  });

  const handleUserSelect = async (userId) => {
    setSelectedCategoryId(userId);
    setIsSuFormData(true);

    setSuFormData((prevFormData) => ({
      ...prevFormData,
      category: userId,
    }));
  };

  const handleInputChangePrevFields = (e) => {
    const { name, value } = e.target;
    setSuFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    handleChange(e);
    handleBlur(e);
  };
  const handleSubCatValidationStatus = (status) => {
    // console.log("Sub Cat Validation Status (Parent):", status);
    setParentSubCatValidationStatus(status);
  };

  useEffect(() => {
    // console.log("Parent Sub Cat Validation Status 01:", parentSubCatValidationStatus);
  }, [parentSubCatValidationStatus]);

  useEffect(() => {
    setFieldValue("category", selectedCategoryId);
    if (!parentSubCatValidationStatus) {
      setFieldValue("subcategory", "no-child");
    }
    else {
      setFieldValue("subcategory", "");
    }
  }, [selectedCategoryId, parentSubCatValidationStatus]);
  // console.log("🚀 ~ file: AddHealthIssues.jsx:112 ~ useEffect ~ selectedCategoryId:", selectedCategoryId)

  const [receivedSubCategory, setReceivedSubCategory] = useState(null);
  // console.log("🚀 ~asfsdfdsfsdfry:", receivedSubCategory)

  const handleSubCategoryChange = (subCategoryValue) => {
    setReceivedSubCategory(subCategoryValue);
    setFieldValue("category", subCategoryValue?.value);
    setFieldValue("subcategory", subCategoryValue?.value);
    setFieldTouched("subcategory", false); // Mark subcategory as touched
    // Other handling logic if needed
  };



  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit}>
          <div className="formBlockContent">
            <div className="row">

              <HealthCategories
                name="category"
                onSelect={handleUserSelect}
                onSelectSubCat={handleSubCategoryChange}
                selectedSubCategory={receivedSubCategory}
                selectedCategoryId={selectedCategoryId}
                value={values?.category}
                filterBy={""}
                label="Select Category"
                sublabel="Select Subcategory"
                subCatValidationStatus={parentSubCatValidationStatus}
                setSubCatValidationStatus={handleSubCatValidationStatus}
                erroClassCategory={touched.category && errors.category ? "validationError" : ""}
                customErrorCategory={touched.category && errors.category}
                errorMessageCategory={touched.category && errors.category ? errors.category : ""}
                customErrorSubcategory={touched.subcategory && errors.subcategory}
                errorMessageSubcategory={touched.subcategory && errors.subcategory ? errors.subcategory : ""}
                erroClassSubcategory={touched.subcategory && errors.subcategory ? "validationError" : ""}

              />


              <div className="col-md-12">
                <Input
                  type="text"
                  id=""
                  name="healthNote"
                  value={values?.healthNote}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Health Note"
                  customError={touched?.healthNote && errors?.healthNote}
                  errorMessage={errors?.healthNote}
                  erroClass={
                    touched?.healthNote && errors?.healthNote
                      ? "validationError"
                      : ""
                  }
                  requiredStar={true}
                />
              </div>

              <div className="col-md-12">
                <TextArea
                  id="complaint"
                  name="complaint"
                  value={values?.complaint}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Complaint"
                  rows="3"
                  className="mb-3"
                  customError={errors.complaint && touched.complaint}
                  errorMessage={errors.complaint}
                  erroClass={errors.complaint && touched.complaint}
                  requiredStar={true}
                />
              </div>
            </div>
          </div>

          <div className="formActionButtons">
            <span
              onClick={handleShowHideModal}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={handleShowHideModal}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastAddHealthIssue && <ToastContainer />}
    </>
  );
}

export default AddHealthIssues;
