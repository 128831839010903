


import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import Layout from "../../../components/layouts/Layout";
import editBlock from "../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../assets/images/icons/eventDel.svg";
import password_eye_icon from "../../../assets/images/icons/password_eye_icon.svg";
import eventClose from "../../../assets/images/icons/eventClose.svg";
import downloadIcon from "../../../assets/images/icons/downloadIcon.svg";
import UserDetailWithStatus from "../../../components/user-detail/UserDetailWithStatus";
import memoryContent from "../../../assets/images/memoryContent.png";
import videoPlayThumbnail from "../../../assets/images/videoPlayThumbnail.png";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { ToastContainer, toast } from "react-toastify";
import moment from 'moment';
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  BASE_URL,
  GET_MEMORY_BOX_URL,
  DELETE_MEMORY_BOX_URL,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../../helper/helper";
import { Link } from "react-router-dom";
import AddMemoryBox from "./AddMemoryBox";
import EditMemoryBox from "./EditMemoryBox";
// import AddCareNotes from "./AddCareNotes";
// import EditCareNotes from "./EditCareNotes";


function MemoryBox() {

  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const [userData, setUserData] = useState([]);
  const apiQuery = {
    query: {
      critarion: {
        userId: userDetails?._id,
      },
    },
    sortproperty: "createdAt",
    sortorder: -1
  };
  const [search, setSearch] = useState("");
  const [filterSearch, setFilterSearch] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [careNoteIdData, setCareNoteIdData] = useState({
    id: null,
  });
  const [carePlanDetail, setCarePlansDetail] = useState();
  const [isCarePlan, setIsCarePlan] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const navigate = useNavigate();

  // Modal Popup
  const [showAddCarePlan, setShowAddCarePlan] = useState(false);
  const subModalAddCarePlans = () => {
    setShowAddCarePlan(!showAddCarePlan);
    fetchAllRecords();
  };

  const [showDeleteCarePlan, setShowDeleteCarePlan] = useState(false);
  const subModalDeleteCareNotes = (careNoteDelId) => {
    setCareNoteIdData({ id: careNoteDelId });
    setShowDeleteCarePlan(!showDeleteCarePlan);
    fetchAllRecords();
  };

  const [showEditCarePlan, setShowEditCarePlan] = useState(false);
  const subModalEditCarePlan = (details) => {
    setCarePlansDetail(details);
    setShowEditCarePlan(!showEditCarePlan);
    fetchAllRecords();
  };
  // Getting records from DB
  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {

      const careNoteData = data?.data?.list?.filter((item) => item.isDeleted === false);
      console.log("🚀 ~ processFetchRecords ~ careNoteData:", careNoteData)

      // const careNoteData = data?.data?.carePlans?.filter((item) => item?.active === true);
      setUserData(careNoteData);
      setFilterSearch(careNoteData);
      setIsLoading(false);
      // console.log("🚀 ~ processFetchRecords ~ userData:", userData)
      // toastSuccessMessages(toast.success, data?.message, "", 500, 500);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {

        setIsCarePlan(false);
      }, 2000);
    }
    // else {
    //   console.log({ data });
    //   toastErrorMessages(toast.error, data?.message, 1000);
    // }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_MEMORY_BOX_URL, apiQuery);
    // console.log("🚀 ~ fetchAllRecords ~ response:", response)
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);




  // Delete Care Notes
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (careNoteIdData !== "") {
      const response = await deleteRecords(
        DELETE_MEMORY_BOX_URL,
        careNoteIdData
      );
      setIsCarePlan(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteCarePlan(!showDeleteCarePlan);
    } else {
      setShowDeleteCarePlan(!showDeleteCarePlan);
      setIsSubmitButtonClicked(false);
      setIsCarePlan(true);
      toastErrorMessages(toast.error, "Unable to remove", 1000);
    }
  });
  useEffect(() => {

    setTimeout(() => {
      setIsCarePlan(false);
    }, 2000);
  }, [isCarePlan])


  // const editSuEnquiry = (item) => {
  //   navigate("/edit-risk-assessments", { state: { carePlanDetail: [item] } });
  // };

  return (
    <>
      <Layout pageTitleText="Care Plans">
        <UserDetailWithStatus carePlans={true} />
        <div className="mainContent headingContent memoryBoxHeader">
          <h3>Memory Box</h3>
          <ProtectedComponent requiredPermissions={[permissions.ADD]}>
            <span
              className="btn btn-sm btn-primary"
              onClick={() => {
                subModalAddCarePlans(filterSearch);
              }}
            >
              Add Memory Box
            </span>
          </ProtectedComponent>
        </div>
        <div className="mainContent mt-3">
          <div className="row">
            {userData?.length > 0 ? (
              userData?.map((row, index) => (
                <>
                  <div className="col-md-6">

                    <div className="memoryBoxContentWrapper">
                      <header className="memoryBoxContentHeader">
                        <ProtectedComponent requiredPermissions={[permissions.EDIT]}>
                          <span
                            onClick={() => {
                              subModalEditCarePlan(row);
                            }}
                          >
                            <img src={editBlock} alt="Event Edit" title="Edit" />
                          </span>
                        </ProtectedComponent>
                        <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
                          <span
                            className="delIcon"
                            onClick={() => {
                              subModalDeleteCareNotes(row?._id);
                            }}
                          >
                            <img src={eventClose} alt="Event Del" />
                          </span>
                        </ProtectedComponent>
                      </header>
                      <div className="memoryBoxContent">
                        <div className="imageBox">
                          {/* <img
                          src={`${BASE_URL}/${row?.filePath}`}
                          alt={row?._id}
                        /> */}
                          {
                            row?.filePath !== '' ? (
                              <>
                                {row?.filePath.match(/\.(jpeg|jpg|png|gif)$/i) ? (
                                  <>
                                    <img
                                      src={`${BASE_URL}${row?.filePath}`}
                                      alt={row?._id}
                                      className="curSorPointer"
                                      onClick={() => {
                                        subModalEditCarePlan(row);
                                      }} />
                                    <span className="downloadIconButton">
                                      <a href={BASE_URL + row?.filePath} target="_blank" download>
                                        <img src={downloadIcon} alt="Download" />
                                      </a>
                                    </span>
                                  </>
                                ) : (
                                  <>
                                    <img
                                      src={videoPlayThumbnail}
                                      alt={row?._id}
                                      className="curSorPointer"
                                      onClick={() => {
                                        subModalEditCarePlan(row);
                                      }} />
                                    <span className="downloadIconButton">
                                      <a href={BASE_URL + row?.filePath} target="_blank" download>
                                        <img src={downloadIcon} alt="Download" />
                                      </a>
                                    </span>
                                  </>
                                )}
                              </>
                            ) : (
                              <img
                                src={memoryContent}
                                alt={row?._id} />
                            )
                          }
                        </div>
                        {/* {
                            row?.filePath !== "" ? (
                              <>
                                <img
                                  src={`${BASE_URL}${row?.filePath}`}
                                  alt={row?._id} />
                                <span className="downloadIconButton">
                                  <a href={BASE_URL + row?.filePath} target="_blank" download>
                                    <img src={downloadIcon} alt="Download" />
                                  </a>
                                </span>
                              </>
                            ) : (
                              <img
                                src={videoPlayThumbnail}
                                alt={row?._id} />
                            )
                          } */}


                        <div className="textBox">

                          <p>
                            {row?.note}
                          </p>
                          <p className="m-0"><small>Staff Member: {row?.addedBy?.name}</small></p>
                          <p className="m-0">
                            <small>Created at: {moment(row?.createdAt).format("DD/MM/YYYY / hh:mm:ss A")}</small>
                          </p>
                        </div>
                      </div>
                    </div>

                  </div>
                </>
              ))
            ) : (
              <div className="noRecordFound">
                <p>Record not found</p>
              </div>
            )}

            {/* <div className="col-md-6">
              <div className="memoryBoxContent">
                <div className="imageBox">
                  <img src={memoryContent} alt="" />
                </div>
                <div className="textBox">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <p className="m-0">
                    <small>Jul/0/2023 / 13:00:00</small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="memoryBoxContent">
                <div className="imageBox">
                  <img src={memoryContent} alt="" />
                </div>
                <div className="textBox">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <p className="m-0">
                    <small>Jul/0/2023 / 13:00:00</small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="memoryBoxContent">
                <div className="imageBox">
                  <img src={memoryContent} alt="" />
                </div>
                <div className="textBox">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <p className="m-0">
                    <small>Jul/0/2023 / 13:00:00</small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="memoryBoxContent">
                <div className="imageBox">
                  <img src={memoryContent} alt="" />
                </div>
                <div className="textBox">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <p className="m-0">
                    <small>Jul/0/2023 / 13:00:00</small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="memoryBoxContent">
                <div className="imageBox">
                  <img src={memoryContent} alt="" />
                </div>
                <div className="textBox">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <p className="m-0">
                    <small>Jul/0/2023 / 13:00:00</small>
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="memoryBoxContent">
                <div className="imageBox">
                  <img src={memoryContent} alt="" />
                </div>
                <div className="textBox">
                  <p>
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text.Lorem Ipsum has been the industry's
                    standard dummy text.
                  </p>
                  <p className="m-0">
                    <small>Jul/0/2023 / 13:00:00</small>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </Layout>

      {/* Add Memory Box */}
      <Modal
        show={showAddCarePlan}
        onHide={subModalAddCarePlans}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add Memory Box</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <AddMemoryBox
            userId={userDetails?._id}
            subModalAddCarePlans={subModalAddCarePlans}
          />
        </Modal.Body>
      </Modal>


      {/* Edit Memory Box */}
      <Modal
        show={showEditCarePlan}
        onHide={subModalEditCarePlan}
        className="ftcModalPopup medicineInventoryStock"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Memory Box</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <EditMemoryBox
            userId={userDetails?._id}
            subModalEditCarePlan={subModalEditCarePlan}
            carePlanDetail={carePlanDetail}
          />
        </Modal.Body>
      </Modal>


      {/* Delete Care Note */}
      <ProtectedComponent requiredPermissions={[permissions.DELETE]}>
        <Modal
          show={showDeleteCarePlan}
          onHide={subModalDeleteCareNotes}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this record?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger" onClick={deleteRecord}>
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteCareNotes}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent>
      {
        isCarePlan ? <ToastContainer /> : ""
      }


    </>
  );
}

export default MemoryBox;
