import React from "react";

function CommonOptionsBlocks({ blockIcon, heading, onClick }) {
  return (
    <>
      <div className="cards" onClick={onClick}>
        <div className="cardContent">
          <span className="contentIcon">
            <img src={blockIcon} alt={heading} />
          </span>
          <h3>{heading}</h3>
        </div>
      </div>
    </>
  );
}

export default CommonOptionsBlocks;
