import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import Modal from "react-bootstrap/Modal";
import moment from "moment";
import {
  BASE_URL,
  GET_APPOINTMENTS_URL,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  catchAsync,
} from "../../../helper/helper";

function CarePlanCalendar() {

  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);

  const [appointmentListData, setAppointmentListData] = useState([]);

  const [viewAppointmentDetail, setViewAppointmentDetail] = useState(false);
  const [viewAppointmentItem, setViewAppointmentItem] = useState([])
  const currentDateMilliseconds = moment().startOf('day').valueOf();

  const viewAppointmentModal = (appointmentItem) => {
    setViewAppointmentItem(appointmentItem)
    setViewAppointmentDetail(!viewAppointmentDetail);
    console.log("🚀 ~ CarePlanAppointment ~ viewAppointmentItem:", viewAppointmentItem)
  };


  const currentYearStart = moment().startOf('year').valueOf();
  const currentYearEnd = moment().endOf('year').valueOf();

  const getAppointmentApiQuery = {
    userId: userDetails?._id,
    startDate: currentYearStart,
    endDate: currentYearEnd,
  };

  // Get Appointments Data
  const processAppointmentData = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const allData = data?.data;
      if (allData && allData.length <= 0) {
        // setNoRecordFound(true);
      } else {
        setAppointmentListData(allData);
      }
    }
    else {
      console.log({ data });
    }
  };

  const fetchAppointmentsRecords = catchAsync(async () => {
    const getAppointmentRecords = await getRecords(
      GET_APPOINTMENTS_URL,
      getAppointmentApiQuery
    );
    processAppointmentData("get", getAppointmentRecords);


  });

  useEffect(() => {
    fetchAppointmentsRecords();
  }, []);

  function renderEventContent(eventInfo) {
    return (
      <>
        <div className="eventAppointmentWrapper">
          {
            appointmentListData.map(appointmentItem => {
              if (appointmentItem._id === eventInfo.event.id) {
                return (
                  <>
                    <p className="viewDetailIcon" onClick={() => viewAppointmentModal(appointmentItem)}>
                      <span className="text-success">*</span>
                    </p>

                  </>
                )
              }
            })
          }
        </div>
      </>
    );
  }

  const events = appointmentListData.map(appointmentItem => (
    appointmentItem?.status !== "cancelled" ? {
      id: appointmentItem._id, // Use the appropriate ID field from your appointment data
      title: appointmentItem.appointmentTitle, // Use the appropriate title field from your appointment data
      start: appointmentItem.appointmentDate, // Use the appropriate start date field from your appointment data
      end: appointmentItem.appointmentDate,
    } : null)).filter(appointment => appointment !== null);

  return (
    <>
      <div className="carePlanCalendar">
        <div className="p-2 mb-2 clearfix">
          <FullCalendar
            plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]} // Include the timeGridPlugin
            headerToolbar={{
              center: "prev,title,next",
              left: "",
              right: "",
            }}
            initialView="dayGridMonth"
            weekends={true}
            events={events}
            eventContent={renderEventContent}
            views={{
              timeGridWeek: {
                type: "timeGridWeek",
                duration: { days: 7 },
              },
              timeGridDay: {
                type: "timeGridDay",
                duration: { days: 1 },
              },
            }}
            buttonText={false}
          />
        </div>

      </div>
      <div className="carePlanCalendar">
        <div className="todayAppointment">
          <h4>Today</h4>
          {
            appointmentListData.filter(todayAppointmentItem => (
              todayAppointmentItem?.status !== "cancelled" && todayAppointmentItem?.appointmentDate === currentDateMilliseconds
            )).length > 0 ? (
              appointmentListData.filter(todayAppointmentItem => (
                todayAppointmentItem?.status !== "cancelled" && todayAppointmentItem?.appointmentDate === currentDateMilliseconds
              )).map(todayAppointmentItem => (
                <>
                  <p key={todayAppointmentItem.id}>
                    {userDetails?.modelId?.suFirstMiddleName} {userDetails?.modelId?.suLastName} will be visiting at {todayAppointmentItem?.appointmentStartTime} to {todayAppointmentItem?.appointmentEndTime}&nbsp;-&nbsp;
                    <span onClick={() => viewAppointmentModal(todayAppointmentItem)}>View Detail</span>
                  </p>
                  <hr />
                </>
              ))
            ) : (
              <p>No appointment for today</p>
            )
          }

        </div>
      </div>
      {/* View Appointments Detail */}
      < Modal
        show={viewAppointmentDetail}
        onHide={viewAppointmentModal}
        className="ftcModalPopup"
        size="sm"
      >
        <Modal.Header closeButton>
          <Modal.Title>Appointment Detail</Modal.Title>
        </Modal.Header>
        <Modal.Body className="eventDetailPopup">
          <div className="event-details" key={viewAppointmentItem?._id}>

            <div className="eventContent">
              <p>
                <strong>Title:</strong>
                {viewAppointmentItem?.appointmentTitle}
              </p>
              <p>
                <strong>Date & Time:</strong>
                {moment(viewAppointmentItem?.appointmentDate).format("DD/MM/YYYY")}<br />
                {viewAppointmentItem?.appointmentStartTime} to {viewAppointmentItem?.appointmentEndTime}
              </p>
              <p>
                <strong>Duration:</strong>
                {Math.floor(viewAppointmentItem?.appointmentMin / 60)} hours and {viewAppointmentItem?.appointmentMin % 60} minutes
              </p>

              <p><strong>Detail:</strong> {viewAppointmentItem?.appointmentDetails}</p>
              {
                viewAppointmentItem?.staff?.name && (
                  <>
                    <div className="usersNameInfo">
                      <div className="userImage">
                        <img
                          src={BASE_URL + viewAppointmentItem?.staff?.profile_picture_url}
                          alt={viewAppointmentItem?.staff?.name}
                        />
                      </div>
                      <div className="userInfo">
                        <span className="nameBlock">
                          {viewAppointmentItem?.staff?.name}
                        </span>
                        <span className="infoBlock">Contact No. {viewAppointmentItem?.staff?.phoneNumber}</span>
                      </div>
                    </div>
                  </>
                )
              }

            </div>
          </div>
        </Modal.Body>
      </Modal >
    </>
  );
}
export default CarePlanCalendar;
