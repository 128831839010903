// NewPassword.js
import React, { useState } from 'react';
import Authentication from '../components/layouts/Authentication';
import ftcLogo from '../assets/images/ftc_logo.svg';
import PasswordInput from '../components/auth/PasswordInput';
import NewPasswordForm from '../components/auth/NewPasswordForm';

function NewPassword() {
  const [isPasswordVisible, setIsPasswordVisible] = useState({
    new_password: false,
    confirm_password: false,
  });

  const togglePassword = (fieldId) => {
    setIsPasswordVisible((prevState) => ({
      ...prevState,
      [fieldId]: !prevState[fieldId],
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Add your form submission logic here
  };

  return (
    <Authentication>
      <div className="login-form">
        <img src={ftcLogo} alt="FTC Logo" className="FTCLogo" />
        <h3>New Password</h3>
        <p>Enter the new password so you can log in and access all features.</p>
        {/* Include the NewPasswordForm component */}
        <NewPasswordForm onSubmit={handleSubmit} />
      </div>
    </Authentication>
  );
}

export default NewPassword;
