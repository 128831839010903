import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import { editCarePlanDocument } from "../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDATE_SERVICE_USERS_REVIEW, ADMIN_ID } from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
function IsReviewedApproved({ isReviewed, setIsReviewed, individualReviewedData }) {
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  const editor = useRef(null);

  const [suFormData, setSuFormData] = useState({
    id: individualReviewedData?._id,
    reviewApprovedBy: ADMIN_ID,
  });

  const {
    values,
    handleChange,
    handleSubmit,
    handleBlur,
  } = useFormik({
    initialValues: suFormData,
    onSubmit: async (values) => {
      try {
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const postEditPbsPlansFormData = async (updatedValues) => {
    const response = await postRecords(UPDATE_SERVICE_USERS_REVIEW, updatedValues);
    processPostRecords("postEditPbsPlansFormData", response);
  };

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
    } else if (method === "postEditPbsPlansFormData" && data?.status === isSuccess) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsReviewed(true); // Update the isReviewed state in the parent
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit}>
        <Form.Check
          type="checkbox"
          id="isReviewed"
          name="isReviewed"
          checked={isReviewed || values?.isReviewed}
          onChange={(e) => {
            handleChange(e);
            handleSubmit();
          }}
          onBlur={handleBlur}
          disabled={isReviewed}
          label="Marked as Reviewed"
        />
      </form>

      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default IsReviewedApproved;
