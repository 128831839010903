// NewPasswordForm.js
import React from 'react';
import PasswordInput from './PasswordInput'; // Import the PasswordInput component

function NewPasswordForm({ onSubmit }) {
    return (
        <form action="/success-message" onSubmit={onSubmit}>
            {/* Include the PasswordInput component for New Password */}
            <PasswordInput
                label="Enter New Password"
                name="new_password"
                onSubmit={onSubmit}
            />
            {/* Include the PasswordInput component for Confirm Password */}
            <PasswordInput
                label="Confirm New Password"
                name="confirm_password"
                onSubmit={onSubmit}
            />
            <div className="form-group">
                <button className="button" type="submit">
                    Update Password
                </button>
            </div>
        </form>
    );
}

export default NewPasswordForm;
