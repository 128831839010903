import React, { useState } from "react";
import actionButtonDel from "../../assets/images/icons/actionButtonDel.svg";
import medicineImage from "../../assets/images/medicineImage.png";
function MedicineDetail({onClose}) {
 
  return (
    <>
      <div className="medicineDetailPopup">
        <div className="medDetail">
          <h3>
            <small>Medicine Name</small>
            Pregabalin 50mg
          </h3>
          <p>
            <strong>Details</strong>
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry. Lorem Ipsum has been the industry's standard dummy text
            ever since the 1500s.
          </p>
        </div>
        <div className="medImage">
          <span
            className="closeButton"
            onClick={onClose}
          >
            <img src={actionButtonDel} alt="Action Close" />
          </span>
          <img className="medicImage" src={medicineImage} alt="Medicine" />
        </div>
      </div>
    </>
  );
}

export default MedicineDetail;
