import React, { useState, useEffect } from "react";
import Select from "react-select";
import {
  getRecords,
  catchAsync,
  isSuccess,
  apiQuery,
} from "../../helper/helper";
import { BASE_URL, GET_CONSENT_TEMPLATE_CAT_URL } from "../../env";

const HealthCategories = ({
  onSelect,
  onSelectSubCat,
  // selectedSubCategory,
  erroClass,
  customError,
  errorMessage,
  label,
  sublabel,
  // filterBy,
  defaultSelectedCatValue,
  defaultSelectedSubCatValue,
  isDisabled,
  fieldName,
  selectedCategoryId,
  customErrorCategory,
  errorMessageCategory,
  customErrorSubcategory,
  errorMessageSubcategory,
  subCatValidationStatus,
  setSubCatValidationStatus,
}) => {
  const [allRecords, setRecords] = useState([]);
  const [subCatRecords, setSubCatRecords] = useState([]);
  // const [subCatValidationStatus, setSubCatValidationStatus] = useState(false);
  // console.log("🚀 ~ file: HealthCategories.jsx:33 ~ subCatValidationStatus:", subCatValidationStatus)
  const [selectedSubCategory, setSelectedSubCategory] = useState(null); // State to hold selected sub-category value
  // console.log("🚀 ~ file: HealthCategories.jsx:26 ~ selectedSubCategory:", selectedSubCategory)

  const [isLoading, setIsLoading] = useState(true);
  const [isStaffId, setIsStaffId] = useState(false);
  const [defaultCatValue, setDefaultCatValue] = useState(defaultSelectedCatValue);
  const [defaultSubCatValue, setDefaultSubCatValue] = useState(defaultSelectedSubCatValue);
  // console.log("🚀 ~ defaultSubCatValue:", defaultSubCatValue)
  // apiQuery.query.critarion.role = "serviceuser";

  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      const filteredServiceUsers = data?.data;
      const validServiceUsers = filteredServiceUsers?.filter(
        (item) => item?.enum !== ""
      );
      const subCategories = filteredServiceUsers?.filter(
        (item) => item?.subcat?.length !== 0
      )

      const getSubCat = subCategories.flatMap((item) =>
        item.subcat.map((sub) => ({
          value: sub?._id,
          name: sub?.name,
          parentCategory: sub?.parentCategory,
        }))
      );
      const getCategories = validServiceUsers?.map((item) => ({
        value: item?._id,
        label: item?.name,
      }));
      setRecords(getCategories);
      setIsLoading(false);
      setSubCatRecords(getSubCat)
    }
  };
  // console.log(
  //   "🚀 ~ file: HealthCategories.jsx:48 ~ fetchAllRecords ~ response:",
  //   selectedCategoryId
  // );
  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CONSENT_TEMPLATE_CAT_URL, apiQuery);
    // console.log(
    //   "🚀 ~ file: HealthCategories.jsx:48 ~ fetchAllRecords ~ response:",
    //   response
    // );
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
    setSubCatRecords(subCatRecords);
  }, []);

  // useEffect(() => {
  //   if (defaultSelectedCatValue) {
  //     setDefaultValueofSu(defaultSelectedCatValue);
  //   }
  // }, [defaultSelectedCatValue]);

  const handleInputChange = (e) => {
    setIsLoading(false);

  };
  const filteredSubCatOptions = subCatRecords.filter(
    (subCat) => subCat.parentCategory === selectedCategoryId
  ).map((subCat) => ({
    value: subCat.value,
    label: subCat.name,
  }));
  // console.log("🚀 ~ file: HealthCategories.jsx:90 ~ filteredSubCatOptions:", filteredSubCatOptions)
  useEffect(() => {
    setSelectedSubCategory(null);
  }, [selectedCategoryId]);


  useEffect(() => {
    if (filteredSubCatOptions.length > 0) {
      setSubCatValidationStatus(true); // Update the parent with the new status
    } else {
      setSubCatValidationStatus(false); // Update the parent with the new status
    }
  }, [filteredSubCatOptions, setSubCatValidationStatus]);

  return (
    <div className={`customSelectBox ${erroClass}`}>
      {isLoading ? (
        <p className="loadingText">Loading...</p>
      ) : (
        <>
          <div className="col-md-12">
            <div className="form-group">
              <Select
                name={"category"}
                options={allRecords}
                isSearchable
                placeholder="Select..."
                onChange={(selectedOption) => {
                  onSelect(selectedOption?.value, selectedCategoryId);
                }}
                defaultValue={allRecords?.find(
                  (record) => record?.value === defaultCatValue
                )}
                onInputChange={handleInputChange}
                getOptionLabel={(option) => option?.label}
                getOptionValue={(option) => option?.value}
                isDisabled={isDisabled}
              />
              {label && <label className={`floatingLabel ${isDisabled}`}>{label}</label>}
              {customErrorCategory && (
                <div className="text-danger">{errorMessageCategory}</div>
              )}
            </div>
          </div>
          {selectedCategoryId && filteredSubCatOptions.length > 0 && (
            <div className="customSelectBox">
              <div className="col-md-12">
                <div className="form-group">
                  <Select
                    name="subcategory"
                    options={filteredSubCatOptions}
                    isSearchable
                    placeholder="Select..."
                    onChange={(selectedOption) => {
                      setSelectedSubCategory(selectedOption);
                      onSelectSubCat(selectedOption); // Triggering the prop here
                    }}
                    defaultValue={filteredSubCatOptions?.find(
                      (record) => record?.value === defaultSubCatValue
                    )}
                    isDisabled={isDisabled}
                  />
                  {sublabel && <label className={`floatingLabel ${isDisabled}`}>{sublabel}</label>}
                  {customErrorSubcategory && (
                    <div className="text-danger">{errorMessageSubcategory}</div>
                  )}
                </div>
              </div>

            </div>
          )}
        </>
      )}
    </div>
  );
};

export default HealthCategories;
