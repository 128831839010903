import React, { useEffect, useState } from "react";
import Layout from "../../../components/layouts/Layout";
import { Tab, Nav, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Link } from "react-router-dom";
import {
  BASE_URL,
  GET_ALL_STAFF_MEMBERS_URL,
  GET_ALL_SERVICE_USERS_URL,
  GET_ALL_ROTA_TEMPLATES_URL,
  DELETE_TEMPLATE_SHIFTS,
} from "../../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../../helper/helper";
import { ToastContainer, toast } from "react-toastify";
import RotaWeeksHeader from "../components/RotaWeeksHeader";
import ProgressComponent from "../../../components/general/ProgressComponent";
import schedulePlus from "../../../assets/images/icons/schedulePlus.svg";
import menuToggleVertical from "../../../assets/images/icons/menuToggleVertical.svg";
import overNightStay from "../../../assets/images/icons/overNightStay.svg";
import sleepOverIcon from "../../../assets/images/icons/sleepOverIcon.svg";
import editBlock from "../../../assets/images/icons/editBlock.svg";
import eventDel from "../../../assets/images/icons/eventDel.svg";
import deleteBasket from "../../../assets/images/icons/eventDel.svg";
import ProtectedComponent from '../../../permissions/ProtectedComponent';
import { permissions } from '../../../permissions/roles';
import Modal from "react-bootstrap/Modal";
import AddScheduleWeekDay from "./AddScheduleWeekDay";
import EditScheduleWeekDay from "./EditScheduleWeekDay";
import {
  HandleDragDropStaffMember,
} from "../components/RotaHelper";
import { DndContext } from '@dnd-kit/core';
import { Droppable } from '../components/Droppable';
import { Draggable } from '../components/Draggable';

function StaffMembersWeeklyTabs({
  shiftLocationId,
  weekNumber,
}) {

  const storedRotaTemplateDetails = localStorage.getItem("RotaTemplateSchedule");
  const [rotaTemplateDetail, setRotaTemplateDetail] = useState(JSON.parse(storedRotaTemplateDetails));


  const [shiftLocationIdLevel, setShiftLocationNameLevel] = useState(shiftLocationId);
  useEffect(() => {
    setShiftLocationNameLevel(shiftLocationId);
  }, [shiftLocationId]);

  const [weekNumberLevel2, setWeekNumberLevel2] = useState(weekNumber);
  useEffect(() => {
    setWeekNumberLevel2(weekNumber);
  }, [weekNumber])

  const [staffMemberData, setStaffMemberData] = useState([]);
  const [staffMemberFilterData, setStaffMemberFilterData] = useState([]);
  const [serviceUserData, setServiceUserData] = useState([]);
  const [rotaTemplateURL, setRotaTemplateURL] = useState([]);

  const [dayNameField, setDayNameField] = useState([]);
  const [userIdForRotaSchedule, setUserIdForRotaSchedule] = useState([]);
  const [shiftIdForRotaSchedule, setShiftIdForRotaSchedule] = useState([]);
  const [serviceUserIdForRotaSchedule, setServiceUserIdForRotaSchedule] = useState([]);
  const [editShiftDetailForrRotaSchedule, setEditShiftDetailForrRotaSchedule] = useState([]);
  // const [idForRotaUserSchedule, setIdForRotaUserSchedule] = useState([]);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isRotaUserShift, setIsRotaUserShift] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [shiftIdData, setShiftIdData] = useState({
    tempId: "",
    shiftId: "",
    locationId: ""
  });

  // Modal Popup
  const [showAddRotaUserShift, setShowAddRotaUserShift] = useState(false);
  const subModalAddUserShift = (staffId, dayName) => {
    setUserIdForRotaSchedule(staffId);
    // setIdForRotaUserSchedule(staffUserId);
    setDayNameField(dayName)
    setShowAddRotaUserShift(!showAddRotaUserShift);
    fetchAllRecords();
  };

  const [showEditRotaUserShift, setShowEditRotaUserShift] = useState(false);
  const subModalEditUserShift = (staffId, shiftId, serviceUserId, shift, dayName) => {
    setUserIdForRotaSchedule(staffId);
    setShiftIdForRotaSchedule(shiftId);
    setServiceUserIdForRotaSchedule(serviceUserId)
    setEditShiftDetailForrRotaSchedule(shift);
    setDayNameField(dayName)
    setShowEditRotaUserShift(!showEditRotaUserShift);
    fetchAllRecords();
  };

  const [showDeleteRota, setShowDeleteRota] = useState(false);
  const subModalDeleteRotaShift = (delTempId, delShiftId, delLocationId) => {
    setShiftIdData({
      tempId: delTempId,
      shiftId: delShiftId,
      locationId: delLocationId
    });
    setShowDeleteRota(!showDeleteRota);
    fetchAllRecords();
  };


  useEffect(() => {
    console.log("🚀 ~ rotaTemplateURL:", rotaTemplateURL);
  }, [rotaTemplateURL]);

  const daysTableHeaTD = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];



  function processFetchRecords(method, data) {
    if (method === "getAllServiceUsers" && data?.status === isSuccess) {
      const validServiceUsers = data?.data?.users?.filter(
        (item) => item?.modelId
      );
      setServiceUserData(validServiceUsers);
    } else if (method === "getAllStaff" && data?.status === isSuccess) {
      const result = data?.data?.staffMembers?.map((item) => item);
      const validServiceUsers = result?.filter((item) => item?.user?._id);
      setStaffMemberData(validServiceUsers);
      setStaffMemberFilterData(validServiceUsers);
    } else if (method === "AllRotaTemplatesUrl" && data?.status === isSuccess) {
      const result = data?.data?.scheduleTemplates.filter(
        (item) => item?._id === rotaTemplateDetail?._id);
      setRotaTemplateURL(result);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsRotaUserShift(false);
      }, 2000);
    } else if (data?.status !== isSuccess) {
      console.error(data);
    }
  }


  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: 1,
    offset: 0,
    query: {
      critarion: {
        active: true,
      },
    },
  };

  const fetchAllPatientRecords = async () => {
    try {
      const query = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
          critarion: {
            active: true,
            role: "serviceuser",
          },
        },
      };
      const response = await getRecords(GET_ALL_SERVICE_USERS_URL, query);
      // console.log("🚀 ~ fetchAllPatientRecords ~ GET_ALL_SERVICE_USERS_URL:", response)
      processFetchRecords("getAllServiceUsers", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllRotaTemplatesUrl = async () => {
    try {
      const query = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
          critarion: {
            isDelete: false,
            _id: rotaTemplateURL[0]?._id
          }
        }
      }
      const response = await getRecords(GET_ALL_ROTA_TEMPLATES_URL, query);
      processFetchRecords("AllRotaTemplatesUrl", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllStaffRecords = async () => {
    try {
      const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
      // console.log("🚀 ~ fetchAllStaffRecords ~ GET_ALL_STAFF_MEMBERS_URL:", response)
      processFetchRecords("getAllStaff", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllRecords = async () => {
    try {
      await fetchAllPatientRecords();
      await fetchAllStaffRecords();
      await fetchAllRotaTemplatesUrl();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  // Helper function to group the days by staff member
  const groupByStaffMember = () => {
    const groupedData = {};

    rotaTemplateURL.forEach((itemLevel1) => {
      itemLevel1?.shiftLocation?.forEach((itemLevel2) => {
        itemLevel2?.weekArray?.forEach((itemLevel4) => {
          if (itemLevel2?.locationId?._id === shiftLocationIdLevel) {
            // && itemLevel4?.weekNo === weekNumberLevel2
            itemLevel4?.daysArray?.forEach((itemLevel3) => {
              const weekNumber = itemLevel4?.weekNo;
              const staffName = itemLevel3?.staffUserId?.staffMemberName;
              const dayName = itemLevel3?.dayName;
              const workHrs = itemLevel3?.workHrs || 0; // Ensure workHrs is a number

              const shift = {
                shiftLocationId: itemLevel2?.locationId?._id,
                shiftStartTime: itemLevel3?.shiftStartTime,
                shiftEndTime: itemLevel3?.shiftEndTime,
                isSleepOver: itemLevel3?.isSleepOver,
                isOverNightStay: itemLevel3?.isOverNightStay,
                shiftId: itemLevel3?._id,
                note: itemLevel3?.note,
                serviceUserId: itemLevel3?.serviceUserId?._id,
                serviceUserName: itemLevel3?.serviceUserId?.name,
                suFirstMiddleName: itemLevel3?.serviceUserId?.modelId?.suFirstMiddleName,
                suLastName: itemLevel3?.serviceUserId?.modelId?.suLastName,
                workHrs,
                weekNumber,
                // shiftId
              };

              if (!groupedData[staffName]) {
                groupedData[staffName] = { days: {}, totalWorkHrs: 0 };
              }

              if (!groupedData[staffName].days[dayName]) {
                groupedData[staffName].days[dayName] = [];
              }

              groupedData[staffName].days[dayName].push(shift);
              groupedData[staffName].totalWorkHrs += workHrs; // Accumulating work hours
            });
          }
        });
      });
    });

    return groupedData;
  };
  const groupedData = groupByStaffMember();
  const renderedStaffMembers = new Set();

  const sortedStaffMemberData = staffMemberData.sort((a, b) => {
    const staffNameA = a?.staffMemberName;
    const staffNameB = b?.staffMemberName;

    const hasDataA = Object.keys(groupedData[staffNameA]?.days || {}).length > 0;
    const hasDataB = Object.keys(groupedData[staffNameB]?.days || {}).length > 0;

    // Prioritize staff members with schedule data
    return hasDataB - hasDataA;
  });



  // Delete RotaTemplate records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (shiftIdData !== "") {
      const response = await deleteRecords(
        DELETE_TEMPLATE_SHIFTS,
        shiftIdData
      );
      setIsRotaUserShift(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteRota(!showDeleteRota);
    } else {
      setShowDeleteRota(!showDeleteRota);
      setIsSubmitButtonClicked(false);
      setIsRotaUserShift(true);
      toastErrorMessages(toast.error, "Unable to remove", 1000);
    }

  });


  // Search Staff Member
  useEffect(() => {

    const result = staffMemberFilterData.filter((user) => {
      return user.staffMemberName.toLowerCase().match(search.toLowerCase());
    });
    setStaffMemberData(result);
  }, [search, staffMemberFilterData]);


  // unAssigned shift according to staff members 
  const unAssignedGroupedData = rotaTemplateURL.reduce((acc, unAssignedItem) => {
    unAssignedItem?.shiftLocation?.forEach(shiftItem => {
      if (shiftItem?.locationId?._id === shiftLocationIdLevel) {
        shiftItem?.weekArray.forEach(weekItem => {
          weekItem?.daysArray.forEach(daysArrayItem => {
            if (daysArrayItem?.staffUserId === null) {
              const serviceUserName = daysArrayItem.serviceUserId.modelId?.suFirstMiddleName;
              if (!acc[serviceUserName]) {
                acc[serviceUserName] = [];
              }
              acc[serviceUserName].push({
                ...daysArrayItem,
                weekNo: weekItem?.weekNo
              });
            }
          });
        });
      }
    });
    return acc;
  }, {});

  const transformedData = Object.keys(unAssignedGroupedData).map(serviceUserName => ({
    serviceUserName,
    shifts: unAssignedGroupedData[serviceUserName]
  }));
  return (
    <>
      <div className="rosterTableContent individualStaffSchedule updateRotaSchedule">
        <div className="table-responsive rotaRosterDragDrop">
          <DndContext onDragEnd={(event) => HandleDragDropStaffMember(
            event,
            rotaTemplateURL,
            staffMemberData,
            setRotaTemplateURL,
            shiftLocationId,
            weekNumber,
            setIsRotaUserShift,
            fetchAllRecords)}>
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th className="text-left">
                    <div className="searchContainer">
                      <div className="searchTextBox">
                        <input
                          type="text"
                          placeholder="Search by Staff Name"
                          value={search}
                          onChange={(e) => setSearch(e.target.value)}
                        />
                      </div>
                    </div>
                  </th>
                  {daysTableHeaTD.map((daysName, daysIndex) => (
                    <th key={daysIndex}>{daysName}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {
                  isLoading ? (
                    <tr>
                      <td colSpan={8}>
                        <div className="subTabsWrapper p-5">
                          <ProgressComponent />
                        </div>
                      </td>
                    </tr>
                  ) : (
                    <>
                      {/* Un Assigned Shifts */}
                      {transformedData.map(({ staffName, shifts }, staffNameIndex) => (
                        <tr key={staffName}>
                          <td className="unAssignedShiftaDay">
                            <div
                              className="clearfix"></div>
                            <div className="usersNameInfo pointerEvent-none text-danger mt-2">
                              <div className="userImage">&nbsp;</div>
                              <div className="userInfo">
                                <span className="nameBlock fs-smaller">Unassigned Shift {staffNameIndex + 1}</span>
                                <span className="actionRows d-flex-start"></span>
                              </div>
                            </div>
                          </td>
                          {daysTableHeaTD.map((daysName, index) => {
                            const dayShifts = shifts.filter(shift => shift.dayName === daysName);
                            return (
                              <td key={index}>
                                {dayShifts.length > 0 ? (
                                  dayShifts.map((shift, shiftIndex) => (
                                    <Droppable key={`${shift?.staffUserId}-${daysName}`} id={`${shift?.staffUserId}-${daysName}`}>
                                      <Draggable
                                        key={shift?._id}
                                        id={shift?._id}
                                        onEditButtonClick={() => {
                                          if (rotaTemplateURL[0]?.status !== "active" && rotaTemplateURL[0]?.status !== "Inactive") {
                                            subModalEditUserShift(
                                              "",
                                              shift?._id,
                                              shift?.serviceUserId?._id,
                                              shift,
                                              daysName,
                                            );
                                          }
                                        }}
                                        onDeleteButtonClick={() => {
                                          if (rotaTemplateURL[0]?.status !== "active" && rotaTemplateURL[0]?.status !== "Inactive") {
                                            subModalDeleteRotaShift(
                                              rotaTemplateURL[0]?._id,
                                              shift?._id,
                                              shiftLocationIdLevel);
                                          }
                                        }}
                                      >
                                        <div key={shiftIndex} className={`contentcell redCell ${weekNumberLevel2 !== shift.weekNo ? "inActiveWeek" : "activeWeek"}`}>
                                          <div className="contentDataCell">
                                            <div className="dataContent">
                                              <span className="time">
                                                {shift?.shiftStartTime} - {shift?.shiftEndTime}
                                              </span>
                                              <span>
                                                {shift?.serviceUserId && (
                                                  <span>
                                                    <small>{shift?.serviceUserId?.modelId?.suFirstMiddleName}&nbsp; {shift?.serviceUserId?.modelId?.suLastName}</small>
                                                  </span>
                                                )}
                                              </span>
                                            </div>
                                            <div className="overNightStatus">
                                              {shift?.isSleepOver && (
                                                <span className="sleepOver-icon">
                                                  <img src={sleepOverIcon} alt="" />
                                                </span>
                                              )}
                                              {shift?.isOverNightStay && (
                                                <span className="sleepOver-icon">
                                                  <img src={overNightStay} alt="" />
                                                </span>
                                              )}
                                            </div>
                                          </div>
                                        </div>
                                      </Draggable>
                                    </Droppable>
                                  ))
                                ) : null}
                              </td>
                            );
                          })}
                        </tr>
                      ))}
                      {/* Assigned shifts */}
                      {
                        staffMemberData.map((itemStaff, indexStaff) => {
                          const staffName = itemStaff?.staffMemberName;
                          const shouldRenderRow = !renderedStaffMembers.has(staffName);

                          if (shouldRenderRow) {
                            renderedStaffMembers.add(staffName);
                            const totalWorkHrs = groupedData[staffName]?.totalWorkHrs || 0;

                            return (
                              <tr key={indexStaff}>
                                <td>
                                  <div className="userInfoWrapper">
                                    <div className="usersNameInfo pointerEvent-none">
                                      <div className="userImage">
                                        <img src={
                                          itemStaff?.user?.profile_picture_url ? (
                                            BASE_URL + itemStaff?.user?.profile_picture_url
                                          ) : (
                                            BASE_URL + "/uploads/dp/default.png"
                                          )
                                        } alt={itemStaff?.staffMemberName} />
                                      </div>
                                      <div className="userInfo">
                                        <span className="nameBlock">
                                          {itemStaff?.staffMemberName}
                                        </span>
                                        <span>
                                          <small>
                                            <strong>C:</strong>{
                                              itemStaff?.contractedHours?.contractedHours ? (
                                                itemStaff?.contractedHours?.contractedHours
                                              ) : (
                                                <>0</>
                                              )
                                            } hrs
                                            &nbsp;&nbsp;
                                            <strong>T:</strong>{totalWorkHrs} hrs
                                          </small>
                                        </span>
                                      </div>
                                    </div>
                                    {/* Contracted Hours Detail */}
                                    <OverlayTrigger
                                      placement="right"
                                      delay={{ show: 250, hide: 400 }}
                                      overlay={
                                        <Tooltip className="toolTipInfo dropDownRota">
                                          <p className="mb-2">
                                            <strong>Contracted hours:&nbsp;</strong>
                                            {
                                              itemStaff?.contractedHours?.contractedHours
                                                ? itemStaff?.contractedHours?.contractedHours + " hrs"
                                                : "0 hrs"
                                            }
                                          </p>
                                          <p className="mb-2">
                                            <strong>Total hours:&nbsp;</strong>
                                            {
                                              totalWorkHrs ? totalWorkHrs + " hrs" : "0 hrs"
                                            }
                                          </p>
                                          <p className="mb-2">
                                            <strong>Total shifts:&nbsp;</strong>
                                            {
                                              daysTableHeaTD.reduce((sum, daysName) => {
                                                const length = groupedData[staffName]?.days[daysName]?.length || 0;
                                                return sum + length;
                                              }, 0)
                                            } shift
                                          </p>
                                        </Tooltip>
                                      }
                                      className="tooltipOverlay"
                                    >
                                      <Button>
                                        <img src={menuToggleVertical} alt={menuToggleVertical} />
                                      </Button>
                                    </OverlayTrigger>
                                  </div>


                                </td>
                                {daysTableHeaTD.map((daysName, daysIndex) => (
                                  <td key={daysIndex}>
                                    {/* Assigned Shift Droppable */}
                                    <Droppable key={`${itemStaff?._id}-${daysName}`} id={`${itemStaff?._id}-${daysName}`}>

                                      {groupedData[staffName]?.days[daysName]?.length > 0 ? (
                                        <>
                                          {
                                            groupedData[staffName].days[daysName].map((shift, shiftIndex) => (
                                              <>
                                                <Draggable
                                                  key={shift?.shiftId}
                                                  id={shift?.shiftId}
                                                  onEditButtonClick={() => {
                                                    if (rotaTemplateURL[0]?.status !== "active" && rotaTemplateURL[0]?.status !== "Inactive") {
                                                      subModalEditUserShift(
                                                        itemStaff?._id,
                                                        shift?.shiftId,
                                                        shift?.serviceUserId,
                                                        shift,
                                                        daysName,
                                                      )
                                                    }
                                                  }}
                                                  onDeleteButtonClick={() => {
                                                    if (rotaTemplateURL[0]?.status !== "active" && rotaTemplateURL[0]?.status !== "Inactive") {
                                                      subModalDeleteRotaShift(
                                                        rotaTemplateURL[0]?._id,
                                                        shift?.shiftId,
                                                        shift?.shiftLocationId
                                                      );
                                                    }
                                                  }}
                                                >
                                                  <div key={shiftIndex}
                                                    className={`contentcell
                                              ${shift?.suFirstMiddleName ? "greenCell" : shift?.serviceUserName ? "greenCell" : "redCell"} 
                                              ${weekNumberLevel2 !== shift?.weekNumber ? "inActiveWeek" : "activeWeek"}`}>
                                                    <div className="contentDataCell">
                                                      <div className="dataContent">
                                                        <span className="time">
                                                          {shift.shiftStartTime} - {shift.shiftEndTime}
                                                        </span>
                                                        {
                                                          shift?.suFirstMiddleName ? (
                                                            <span>
                                                              <small>
                                                                {shift?.suFirstMiddleName}
                                                                &nbsp;
                                                                {shift?.suLastName}

                                                              </small>
                                                            </span>
                                                          ) : shift?.serviceUserName ? (
                                                            <span>
                                                              <small>
                                                                {shift?.serviceUserName}
                                                              </small>
                                                            </span>

                                                          ) : (
                                                            <span>
                                                              <small className="c-red">
                                                                Unassigned Shift
                                                              </small>
                                                            </span>
                                                          )
                                                        }
                                                      </div>
                                                      <div className="overNightStatus">
                                                        {/* Sleep Over / Night Stay */}
                                                        {
                                                          shift?.isSleepOver && (
                                                            <span className="sleepOver-icon">
                                                              <img src={sleepOverIcon} alt="" />
                                                            </span>
                                                          )
                                                        }
                                                        {
                                                          shift?.isOverNightStay && (
                                                            <span className="sleepOver-icon">
                                                              <img src={overNightStay} alt="" />
                                                            </span>
                                                          )
                                                        }
                                                      </div>
                                                    </div>
                                                  </div>
                                                </Draggable >
                                              </>
                                            ))
                                          }
                                          {
                                            rotaTemplateURL[0]?.status !== "active" && rotaTemplateURL[0]?.status !== "Inactive" ? (
                                              <span>
                                                <img
                                                  src={schedulePlus}
                                                  alt="update"
                                                  className="addNewDay cursor-pointer rotaImageWidth"
                                                  onClick={() => {
                                                    subModalAddUserShift(
                                                      itemStaff?._id,
                                                      daysName
                                                    );
                                                  }}
                                                />
                                              </span>
                                            ) : null
                                          }
                                        </>
                                      ) : (
                                        <>
                                          {
                                            rotaTemplateURL[0]?.status !== "active" && rotaTemplateURL[0]?.status !== "Inactive" ? (
                                              <span>
                                                <img
                                                  src={schedulePlus}
                                                  alt="update"
                                                  className="addNewDay cursor-pointer rotaImageWidth"
                                                  onClick={() => {
                                                    subModalAddUserShift(
                                                      itemStaff?._id,
                                                      daysName
                                                    );
                                                  }}
                                                />
                                              </span>
                                            ) : null
                                          }

                                        </>
                                      )}
                                    </Droppable>
                                  </td>
                                ))}
                              </tr>
                            );
                          } else {
                            return null;
                          }
                        })
                      }
                    </>
                  )
                }
              </tbody>
            </table>
          </DndContext>
        </div>
      </div >

      {/* Add Rota Shift */}
      < Modal
        show={showAddRotaUserShift}
        onHide={subModalAddUserShift}
        className="ftcModalPopup medicineInventoryStock"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Schedule</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <AddScheduleWeekDay
            staffId={userIdForRotaSchedule}
            dayName={dayNameField}
            shiftLocationId={shiftLocationIdLevel}
            weekNumberLevel3={weekNumberLevel2}
            subModalAddUserShift={subModalAddUserShift}
            templateId={rotaTemplateURL[0]?._id}
          />

        </Modal.Body>
      </Modal >


      {/* Edit Rota Shift */}
      < Modal
        show={showEditRotaUserShift}
        onHide={subModalEditUserShift}
        className="ftcModalPopup medicineInventoryStock"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Schedule - Rota</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          {/* {shiftIdForRotaSchedule} */}
          <EditScheduleWeekDay
            shiftDetail={editShiftDetailForrRotaSchedule}
            staffId={userIdForRotaSchedule}
            shiftId={shiftIdForRotaSchedule}
            serviceUserId={serviceUserIdForRotaSchedule}
            dayName={dayNameField}
            shiftLocationId={shiftLocationIdLevel}
            weekNumberLevel3={weekNumberLevel2}
            subModalEditUserShift={subModalEditUserShift}
            templateId={rotaTemplateURL[0]?._id}
          />

        </Modal.Body>
      </Modal >


      {/* Delete Rota Shift */}
      < ProtectedComponent requiredPermissions={[permissions.DELETE]} >
        <Modal
          show={showDeleteRota}
          onHide={subModalDeleteRotaShift}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this shift?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger"
                onClick={deleteRecord}
              >
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteRotaShift}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent >
      {
        isRotaUserShift && <ToastContainer />
      }

    </>
  );
}

export default StaffMembersWeeklyTabs;
