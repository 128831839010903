import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import { BASE_URL, GET_ALL_STAFF_MEMBERS_URL } from "../../../env";
import {
  getRecords,
  catchAsync,
  isSuccess,
  filterColumns,
  filterRowsByProperty,
  displayEnumProperty,
  formateDate,
  apiQuery,
} from "../../../helper/helper";

function StaffRecords({ staffHeadingBlock }) {
  const [userData, setRecords] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  apiQuery.limit = 6;

  const getUserData = catchAsync(async (req, res) => {
    const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
    processStaffRecordsData("get", response);
  });

  function processStaffRecordsData(method, data) {
    if (method === "get") {
      if (data?.status === isSuccess) {
        const getAllRecord = data?.data?.staffMembers?.map((item) => item);
        setIsLoading(false);
        setRecords(getAllRecord);
      }
    } else if (method === "post") {
      // Handle post method if needed
    }
  }

  useEffect(() => {
    getUserData();
  }, []);

  const columns = [
    {
      name: "Name",
      selector: (row) => {
        return (
          <span className="usersNameInfo">
            <span className="userImage">
              <img
                src={`${BASE_URL}${row?.user?.profile_picture_url}`}
                alt={`${row?.name}`}
              />
            </span>
            <span className="userInfo">
              <span className="nameBlock">{`${row?.user?.name}`}</span>
            </span>
          </span>
        );
      },
      width: "200px",
    },
    {
      name: "Supervisions",
      selector: (row) => {
        const result = formateDate(row?.createdAt, "m/d/");
        return (
          <>
            <span className="tableText danger">Due Date - {result}</span>
          </>
        );
      },
      width: "23%",
    },
    {
      name: "Manager",
      selector: (row) => {
        return <span>{row?.managerId?.name}</span>;
      },
      width: "15%",
    },
    {
      name: "Position",
      selector: (row) => {
        const userRole = displayEnumProperty(
          "staffDesignation",
          row?.user?.role
        );
        return <span>{userRole}</span>;
      },
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => {
        return (
          <a href={`mailto:${row?.user?.email}`} style={{ color: "black" }}>
            {row?.user?.email}
          </a>
        );
      },
      width: "25%",
    },
  ];
  const filteredColumns = filterColumns(columns, (column) => column !== null);
  const filteredRows = filterRowsByProperty(userData, (row) => row?.user?.name);
  return (
    <>
      <h4>{staffHeadingBlock}</h4>
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable"
          columns={filteredColumns}
          data={filteredRows}
          fixedHeader
          fixedHeaderScrollHeight="340px"
          highlightOnHover
          responsive={true}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
        />
      </div>
    </>
  );
}

export default StaffRecords;
