import React, { useEffect, useState } from "react";
import axios from "axios";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";

import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function Roster({ staffHeadingBlock }) {
  const [payroll, setPayroll] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [show, setShow] = useState(false);
  const handleModalEvent = () => setShow(!show);

  const getPayroll = async () => {
    try {
      const response = await axios.get("https://dummyjson.com/users");
      setPayroll(response.data.users);
      setIsLoading(false);
    } catch (error) {}
  };

  const columns = [
    {
      name: "Confirm Shift",
      selector: (row) => {
        return (
          <div className="shiftCell markLeave" onClick={handleModalEvent}>
            {/* User Info */}
            <div className="usersNameInfo">
              <div className="userImage">
                <img src={`${row.image}`} alt={`${row.firstName}`} />
              </div>
              <div className="userInfo">
                <span className="nameBlock">{`${row.firstName}`}</span>
                <span className="infoBlock">
                  {`${row.age}`} hrs | {`${row.age}`} shifts
                </span>
              </div>
            </div>
            {/* Shift Info */}
            <div className="shiftTimingDetail">
              {/* Items */}
              <div className="contentcell redCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
              {/* Items */}
              <div className="contentcell redCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
              {/* Items */}
              <div className="contentcell redCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
            </div>
          </div>
        );
      },

      width: "55%",
    },
    {
      name: "Open Shift",
      selector: (row) => {
        return (
          <div className="shiftCell">
            {/* Shift Info */}
            <div className="shiftInfo text-center">
              <span className="shiftType">Open Shift</span>
              <span className="shiftTitle">Work Title</span>
              <span className="infoBlock">
                {`${row.age}`} hrs | {`${row.age}`} shifts
              </span>
            </div>
            {/* Shift Info */}
            <div className="shiftTimingDetail">
              {/* Items */}
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
              {/* Items */}
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
              {/* Items */}
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
              {/* Items */}
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
            </div>
          </div>
        );
      },

      width: "45%",
    },
  ];
  useEffect(() => {
    getPayroll();
  }, []);

  return (
    <>
      <h4>{staffHeadingBlock}</h4>
      <div className="dataTableWrapper">
        <DataTable
          className="maxWidthTable"
          columns={columns}
          data={payroll}
          fixedHeader
          fixedHeaderScrollHeight="340px"
          highlightOnHover
          responsive={true}
          progressPending={isLoading}
          progressComponent={<ProgressComponent />}
          // onRowClicked={handleRowClick}
        />
      </div>
      <div>
        <Modal
          show={show}
          onHide={handleModalEvent}
          className="ftcModalPopup markLeaveModal"
        >
          <Modal.Header>
            <Modal.Title>Leave Mark</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="usersNameInfo">
              <div className="userImage">
                <img
                  src="https://robohash.org/consequunturautconsequatur.png"
                  alt="Terrill"
                />
              </div>
              <div className="userInfo">
                <span className="nameBlock">Terrill</span>
                <span className="infoBlock">38 hrs | 38 shifts</span>
              </div>
            </div>
            <div className="modalBodyContent">
              <p className="mb-2 mt-2">
                <strong>Mark As</strong>
              </p>
              <div className="leaveStatusApproval">
                <label className="radioButtonContainer">
                  Leave <span className="statusCircle green">&nbsp;</span>
                  <input type="radio" name="leave" />
                  <span className="checkmark"></span>
                </label>
                <label className="radioButtonContainer">
                  Sick Leave <span className="statusCircle red">&nbsp;</span>
                  <input type="radio" name="leave" />
                  <span className="checkmark"></span>
                </label>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              className="btn-sm"
              variant="primary"
              onClick={handleModalEvent}
            >
              Save
            </Button>
            <Button
              className="btn-sm"
              variant="outline-primary"
              onClick={handleModalEvent}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </>
  );
}

export default Roster;
