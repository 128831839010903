import React from "react";

function SelectBox({
  id,
  name,
  value,
  onChange,
  labelText,
  options,
  disabled,
  errorClass,
  customError,
  errorMessage,
  requiredStar,
}) {
  return (
    <div className={`customSelectBox form-group ${errorClass}`}>
      <select
        id={id}
        name={name}
        value={value}
        onChange={onChange}
        disabled={disabled}
        className="form-select"
      >
        {options?.map((option) => (
          <option key={option?.value} value={option?.value}>
            {option?.label}
          </option>
        ))}
      </select>
      {labelText && <label className="floatingLabel">
        {labelText}
        {requiredStar ? <span className="text-danger">*</span> : null}
        </label>}
      {customError && <div className="text-danger">{errorMessage}</div>}
    </div>
  );
}

export default SelectBox;
