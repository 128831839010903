import React, { useState, useEffect } from "react";

function StaffRecordTabsContent({ getUserRecord }) {
  const initialUserData = getUserRecord;
  const [userData, setUser] = useState(initialUserData);
  return (
    <>
      <div className="contentOverview payrollOverview">
        <h3>Staff Overview</h3>

        <div className="calendarOverview">
          <div className="headerRow dayRow">
            <div className="dayCell">19 Sat</div>
            <div className="dayCell">20 Sun</div>
            <div className="dayCell">21 Mon</div>
            <div className="dayCell">22 Tue</div>
            <div className="dayCell">23 Wed</div>
            <div className="dayCell">24 Thu</div>
            <div className="dayCell">25 Fri</div>
          </div>
          <div className="bodyRow dayRow">
            <div className="dayCell">
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
            </div>

            <div className="dayCell holidayCell">
              <div className="contentcell">
                <span className="request">Requested Holiday</span>
                <span className="requestStatus text-danger">Pending</span>
              </div>
            </div>

            <div className="dayCell">
              <div className="contentcell greyCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
            </div>

            <div className="dayCell holidayCell">
              <div className="contentcell">
                <span className="request">Requested Holiday</span>
                <span className="requestStatus text-success">Approved</span>
              </div>
            </div>

            <div className="dayCell">
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
            </div>

            <div className="dayCell">
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
            </div>

            <div className="dayCell">
              <div className="contentcell greenCell">
                <span className="time">
                  7am-7pm <span className="statusBall">&nbsp;</span>
                </span>
                <span className="type">12hrs, ‘Worker type’</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StaffRecordTabsContent;
