import React, { useState, useEffect } from 'react';
import ReactHtmlParser from 'html-react-parser';
import { GET_CARE_PLAN_DOC_URL } from "../../../env";
import {
  getRecords,
  catchAsync,
  isSuccess,
} from "../../../helper/helper";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../../components/layouts/Layout";
import UserDetailReview from '../../../components/user-detail/UserDetailReview';
import ProgressComponent from '../../../components/general/ProgressComponent';
import editGrid from "../../../assets/images/icons/editGrid.svg";
import Modal from 'react-bootstrap/Modal';
import AddCarePlanPopUp from './AddCarePlanPopUp';
function AddCarePlan() {
  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userData = JSON.parse(storedUserDetails);
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [carePlanData, setCarePlanData] = useState('')
  const [isLoading, setIsLoading] = useState(true);

  const [modalPopupData, setModalPopupData] = useState();
  const [modalPopupTitle, setModalPopupTitle] = useState();
  const handelModalPopup = (modalPopupDataItem) => {
    setModalPopupData(modalPopupDataItem)
    setModalPopupTitle(modalPopupDataItem?.sectionTitle)
    setShow(true)
  };
  const handelModalPopupClose = () => {
    setShow(false)
    fetchAllRecords();
  };
  // Getting records from DB

  const apiQuery = {
    query: {
      critarion: {
        userId: userData?._id
      }
    }
  }

  const processFetchRecords = (method, data) => {
    if (method === "get" && data?.status === isSuccess) {
      setCarePlanData(data?.data?.list[0])
      setIsLoading(false);
    }
  };

  const fetchAllRecords = catchAsync(async (req, res) => {
    const response = await getRecords(GET_CARE_PLAN_DOC_URL, apiQuery);
    processFetchRecords("get", response);
  });

  useEffect(() => {
    fetchAllRecords();
  }, []);

  const viewCarePlanData = [
    {
      sectionTitle: "Involvement & Consent", sectionId: "involvementAndConsent",
      fieldValueContent: ""
    },
    {
      sectionTitle: "One Page Profile", sectionId: "onePageProfile",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Information About Me", sectionId: "informationAboutMe",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Contacts", sectionId: "myContacts",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Life History", sectionId: "myLifeHistory",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Dreams and Aspirations", sectionId: "myDreamsAndAspirations",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Goals and Outcomes", sectionId: "myGoalsAndOutcomes",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Key Worker", sectionId: "myKeyWorker",
      fieldValueContent: ""
    },
    // {
    //   sectionTitle: "My Person Centred Support Plans", sectionId: "myPersonCentredSupportPlans",
    //   fieldValueContent: ""
    // },
    {
      sectionTitle: "Communication", sectionId: "communication",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Important People", sectionId: "myImportantPeople",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Likes and Dislikes", sectionId: "myLikesAndDislikes",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Relationships", sectionId: "myRelationships",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Routines", sectionId: "myRoutines",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Sleeping", sectionId: "mySleeping",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Personal Care", sectionId: "myPersonalCare",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Weekly Budget", sectionId: "myWeeklyBudget",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Activities and Interests", sectionId: "myActivitiesAndInterests",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Activity Plan", sectionId: "myActivityPlan",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Perfect Week", sectionId: "myPerfectWeek",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Being Part of the Community", sectionId: "myBeingPartOfTheCommunity",
      fieldValueContent: ""
    },
    {
      sectionTitle: "College, Career, Work Experience, Volunteering", sectionId: "myCollegeCareerWorkExperienceVolunteering",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Daily Living Skills", sectionId: "myDailyLivingSkills",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Mental Health", sectionId: "myMentalHealth",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Crisis Support", sectionId: "myCrisisSupport",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Travelling", sectionId: "myTravelling",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Food and Drink", sectionId: "myFoodAndDrink",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Support on Good and Bad Days", sectionId: "supportOnGoodBadDays",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Feelings and Behaviours", sectionId: "myFeelingsAndBehaviours",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Keeping Safe and Well", sectionId: "keepingSafeAndWell",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Making My Own Decisions", sectionId: "makingMyownDecisions",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Health", sectionId: "myHealth",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Medication", sectionId: "myMedication",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Sexual Health", sectionId: "mySexualHealth",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Religious, Spiritual, and Life Choices", sectionId: "religiousSpiritualAndLifeChoices",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Finances", sectionId: "myFinances",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Tenancy Information", sectionId: "myTenancyInformation",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Reviews", sectionId: "myReviews",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Health Action Plan", sectionId: "myHealthActionPlan",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Risk Assessments", sectionId: "riskAssessments",
      fieldValueContent: ""
    },
    {
      sectionTitle: "PEEP", sectionId: "PEEP",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Missing Persons Profile", sectionId: "missingPersonsProfile",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Personal Inventory", sectionId: "personalInventory",
      fieldValueContent: ""
    },
    {
      sectionTitle: "After My Death", sectionId: "afterMyDeath",
      fieldValueContent: ""
    },
    // {
    //   sectionTitle: "Recording File", sectionId: "recordingFile",
    //   fieldValueContent: ""
    // },
    {
      sectionTitle: "Daily Diary", sectionId: "dailyDiary",
      fieldValueContent: ""
    },
    {
      sectionTitle: "My Key Worker Key Work Meetings", sectionId: "myKeyWorkerKeyWorkMeetings",
      fieldValueContent: ""
    },
    {
      sectionTitle: "MCA Best Interests Decisions DoLS", sectionId: "MCABestInterestsDecisionsDoLS",
      fieldValueContent: ""
    },
    {
      sectionTitle: "ABC Charts", sectionId: "aBCCharts",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Health Appointment Records", sectionId: "healthAppointmentRecords",
      fieldValueContent: ""
    },
    // {
    //   sectionTitle: "Menstruation", sectionId: "menstruation",
    //   fieldValueContent: ""
    // },
    {
      sectionTitle: "Weight Records", sectionId: "weightRecords",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Seizure Records", sectionId: "seizureRecords",
      fieldValueContent: ""
    },
    {
      sectionTitle: "Financial Records", sectionId: "financialRecords",
      fieldValueContent: ""
    },
  ]
  return (
    <>
      <Layout
        pageTitleText={
          <>
            Care Plan
          </>
        }
      >
        <UserDetailReview
          getUserRecord={userData}
          avatarActionButtons={false}
          viewNotes={false}
          profileDetail={false}
          horizontalDetail={true}
          launchVideoButtons={true}
        />
        <div className="viewEditorWrapper">
          <div className="mainContent mt-3 pt-4">

            {isLoading ? (
              <ProgressComponent />
            ) : (
              <>
                <header className="mainContentHeader pt-0 pb-4">
                  <span></span>
                  <div>
                    <span className="btn btn-sm btn-primary" onClick={() => {
                      navigate("/care-plans");
                    }}>Back to Care Plan Summary</span>
                    {/* &nbsp;
                    <span className="btn btn-sm btn-secondary" onClick={() => {
                      navigate("/edit-care-plan");
                    }}>Modify</span> */}
                  </div>
                </header>
                <div className="viewEditorContent">
                  {
                    viewCarePlanData?.map((item, index) => (
                      <>
                        <div className="sectionWrapper">
                          <div className="sectionContent pbsFieldWrapper">
                            <header className="alert alert-dark">
                              <h3 className='viewCarePlanTitle'>
                                {item?.sectionTitle}
                                {
                                  item?.sectionId === "riskAssessments" ? (
                                    <>
                                      <Link to="/risk-assessments" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                    </>
                                  ) : item?.sectionId === "aBCCharts" ? (
                                    <>
                                      <Link to="/care-plan/abc" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                    </>
                                  ) : item?.sectionId === "MCABestInterestsDecisionsDoLS" ? (
                                    <>
                                      <Link to="/care-plan/consent-capacity-mca-dols" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                    </>
                                  ) : item?.sectionId === "healthAppointmentRecords" ? (
                                    <>
                                      <Link to="/care-plan/health-appointments" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                    </>
                                  ) : item?.sectionId === "weightRecords" ? (
                                    <>
                                      <Link to="/care-plan/weight-height" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                    </>
                                  ) : item?.sectionId === "dailyDiary" ? (
                                    <>
                                      <Link to="/care-plan/daily-summary" className="btn btn-sm btn-primary">Clicke here to view</Link>
                                    </>
                                  ) : (
                                    <span className="edit"
                                      onClick={() => handelModalPopup(item)}>
                                      <img src={editGrid} alt="Edit" />
                                    </span>
                                  )
                                }

                              </h3>
                            </header>
                            <div className="p-3">
                              {ReactHtmlParser(item?.fieldValueContent)}
                            </div>
                          </div>
                        </div>
                      </>
                    ))
                  }
                </div>
              </>
            )}
          </div>
        </div>
      </Layout>

      {/* Add Care Plan Document */}
      <Modal show={show} onHide={handelModalPopupClose} className="ftcModalPopup" size="lg">
        <Modal.Header closeButton>
          <Modal.Title>{modalPopupTitle} Add</Modal.Title>
        </Modal.Header>
        <AddCarePlanPopUp handelModalPopupClose={handelModalPopupClose} modalPopupData={modalPopupData} />
      </Modal>
    </>
  );
}

export default AddCarePlan