import React from "react";
import Select from "react-select";

const CustomSelectBox = ({
  name,
  defaultValue,
  options,
  onChange,
  isMulti,
  isClearable,
  isSearchable,
  isDisabled,
  errors,
  labelText,
  required,
}) => {
  return (
    <div className={`customSelectBox ${errors ? "validationError" : ""}`}>
      <Select
        name={name}
        defaultValue={defaultValue}
        className="basic-multi-select cursor-pointer"
        classNamePrefix="select"
        options={options}
        onChange={onChange}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isSearchable={isSearchable}
        getOptionLabel={(option) => option?.label}
        getOptionValue={(option) => option?.value}
      />
      <label className="floatingLabel">
        {labelText}
        {required && <span className="text-danger">*</span>}
      </label>
      <div className="text-danger customValidationMsg">{errors}</div>
    </div>
  );
};

export default CustomSelectBox;
