import React, { useEffect, useState } from "react";
import DataTable from "react-data-table-component";
import ProgressComponent from "../../../components/general/ProgressComponent";
import { BASE_URL } from "../../../env";
import { filterColumns, displayEnumProperty, formateDate } from "../../../helper/helper";
import moment from "moment";
import password_eye_icon_circle from "../../../assets/images/icons/password_eye_icon_circle.svg";
import ViewTrainingModel from "../../../components/modals/ViewTrainingModel";

function SupervisionGroup({ getUserRecord }) {
  const initialUserData = getUserRecord?.staffTrainings || [];
  console.log("🚀 ~ SupervisionGroup ~ initialUserData:", initialUserData)
  const [userData, setUserData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // Transform initialUserData to match the expected structure
    setUserData(initialUserData);
    setIsLoading(false);
  }, [initialUserData]);

  const columns = [
    {
      name: "Training Title",
      selector: (row) => (
        <span className="usersNameInfo">
          <span className="userInfo">
            <span className="nameBlock">{row?.trainingId?.prpsName}</span>
          </span>
        </span>
      ),
    },

    {
      name: "Due Date",
      selector: (row) => {
        return <span>{moment(row?.dueDate).format("DD/MM/YYYY")}</span>;
      },
      width: "20%",
    },
    {
      name: "Status",
      selector: (row) => {
        return (
          <span className="capitalizeTransform">{row?.trainingStatus}</span>
        )
      },
      width: "20%",
    },
    {
      /*======================================
         How status will be updated????????
      =========================================*/
      name: "Action",
      selector: (row) => {
        return (
          <div className="actionColumns individualReviewsActions">
            <span className="edit"
              onClick={() => handleTrainingViewClick(row?.trainingId)}
            >
              <img src={password_eye_icon_circle} alt="View" title="View" />
            </span>

          </div>
        );
      },
      width: "15%",
      center: true,
    },
  ];

  const filteredColumns = filterColumns(columns, (column) => column !== null);

  const [viewTrainingTemp, setViewTrainingTemp] = useState(false);
  const [trainingData, setTrainingData] = useState();
  const handleTrainingViewClick = (row) => {
    setViewTrainingTemp(!viewTrainingTemp);
    setTrainingData(row)
  };
  return (
    <>
      <div className="">
        <div className="mainContent">
          <div className="dataTableWrapper">
            <DataTable
              className="maxWidthTable"
              columns={filteredColumns}
              data={userData}
              pagination
              paginationRowsPerPageOptions={[10, 25, 50, 100]}
              paginationPerPage={10}
              fixedHeader
              fixedHeaderScrollHeight="600px"
              highlightOnHover
              actions={
                <button className="btn btn-sm btn-outline-primary">
                  Export Sheet
                </button>
              }
              responsive
              subHeader
              subHeaderComponent={
                <div className="searchContainer">
                  test
                </div>
              }
              subHeaderAlign="left"
              subHeaderWrapperProps={{ style: { padding: 0, margin: 0 } }}
              progressPending={isLoading}
              progressComponent={<ProgressComponent />}
            />
          </div>
        </div>
      </div>
      {viewTrainingTemp && (
        <ViewTrainingModel
          // handleShowModel={isTraUpdateModel}
          trainingData={trainingData}
          onClose={handleTrainingViewClick}
          item={viewTrainingTemp}
        />
      )}
    </>
  );
}

export default SupervisionGroup;
