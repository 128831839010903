import React from "react";
import { Routes, Route, BrowserRouter } from "react-router-dom";


import BasicRoutes from "./routes/BasicRoutes";

// Authentication
import Login from "./auth/Login";
import NewPassword from "./auth/NewPassword";
import VerifyCode from "./components/auth/VerifyOtpForm";
import ForgotPassword from "./components/auth/ForgetPasswordForm";
import SuccessMessage from "./components/auth/SuccessMessageDisplay";


import "./assets/css/style.css";
import PermissionError from "./auth/PermissionError";
import PermissionErrorForWebAccess from "./auth/PermissionErrorForWebAccess";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/permission-error" element={<PermissionError />} />
          <Route path="/restricted-web-access" element={<PermissionErrorForWebAccess />} />
          <Route path="/verify-code" element={<VerifyCode />} />
          <Route path="/new-password" element={<NewPassword />} />
          <Route path="/success-message" element={<SuccessMessage />} />
          <Route path="/forgot-password" element={<ForgotPassword />} />
        </Routes>
        <BasicRoutes />
      </BrowserRouter>
    </>
  );
}

export default App;
