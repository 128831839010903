import React, { useEffect, useState, useRef } from "react";
import { Table, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import moment from "moment";
import {
  BASE_URL,
  GET_ALL_STAFF_MEMBERS_URL,
  GET_ALL_SERVICE_USERS_URL,
  DELETE_TEMPLATE_SHIFTS,
  CHECK_EXITS_TEMPLATE_SHIFTS_URL
} from "../../env";
import {
  getRecords,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
  deleteRecords,
  catchAsync,
} from "../../helper/helper";

import { ToastContainer, toast } from "react-toastify";
import ProgressComponent from "../../components/general/ProgressComponent";
import schedulePlus from "../../assets/images/icons/schedulePlus.svg";
import menuToggleVertical from "../../assets/images/icons/menuToggleVertical.svg";
import overNightStay from "../../assets/images/icons/overNightStay.svg";
import sleepOverIcon from "../../assets/images/icons/sleepOverIcon.svg";
import eventDel from "../../assets/images/icons/eventDel.svg";
import deleteBasket from "../../assets/images/icons/eventDel.svg";
import ProtectedComponent from '../../permissions/ProtectedComponent';
import { permissions } from '../../permissions/roles';
import Modal from "react-bootstrap/Modal";
import AddRosterSchedule from "./AddRosterSchedule";
import EditRosterSchedule from "./EditRosterSchedule";
import {
  HandleDragDropStaffMember,
} from "./components/RosterHelper";
import { DndContext } from '@dnd-kit/core';
import { Droppable } from './components/Droppable';
import { Draggable } from './components/Draggable';
import AutoAddShifts from "./components/AutoAddShifts";
import ShiftLogs from "./logs/ShiftLogs";

function StaffMembersRosterTabs({
  shiftLocationId,
  weekNumber,
  weekdays,
  calendarDays,
  currentStartDate
}) {

  const [userCurrentStartDate, setUserCurrentStartDate] = useState(currentStartDate)
  useEffect(() => {
    setUserCurrentStartDate(currentStartDate);
  }, [currentStartDate]);

  const [shiftLocationIdLevel, setShiftLocationNameLevel] = useState(shiftLocationId);
  useEffect(() => {
    setShiftLocationNameLevel(shiftLocationId);
  }, [shiftLocationId]);

  const [weekNumberLevel2, setWeekNumberLevel2] = useState(weekNumber);
  useEffect(() => {
    setWeekNumberLevel2(weekNumber);
  }, [weekNumber])
  const [updateWeek, setUpdateWeek] = useState(null)
  const [staffMemberData, setStaffMemberData] = useState([]);
  const [staffMemberFilterData, setStaffMemberFilterData] = useState([]);
  const [serviceUserData, setServiceUserData] = useState([]);
  const [completeLiveRosterURL, setCompleteLiveRosterURL] = useState([]);
  // useEffect(() => {
  //   console.log("🚀 ~ completeLiveRosterURL:", completeLiveRosterURL)
  // }, [completeLiveRosterURL])
  const [liveRosterURL, setLiveRosterURL] = useState([]);

  const [dayNameField, setDayNameField] = useState([]);
  const [userIdForRotaSchedule, setUserIdForRotaSchedule] = useState([]);

  const [rosterIdSchedule, setRosterIdSchedule] = useState([]);
  useEffect(() => {
    setRosterIdSchedule(liveRosterURL[0]?._id)
  }, [liveRosterURL])

  const [shiftIdForRotaSchedule, setShiftIdForRotaSchedule] = useState([]);
  const [serviceUserIdForRotaSchedule, setServiceUserIdForRotaSchedule] = useState([]);
  const [editShiftDetailForrRotaSchedule, setEditShiftDetailForrRotaSchedule] = useState([]);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isRotaUserShift, setIsRotaUserShift] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [search, setSearch] = useState("");

  const [shiftIdData, setShiftIdData] = useState({
    tempId: "",
    shiftId: "",
    locationId: ""
  });

  // Modal Popup
  const [showAddRotaUserShift, setShowAddRotaUserShift] = useState(false);
  const [addRosterShiftDate, setAddRosterShiftDate] = useState(false);
  const subModalAddUserShift = (staffId, dayName, shiftDate) => {
    setUserIdForRotaSchedule(staffId);
    setAddRosterShiftDate(shiftDate);
    setDayNameField(dayName)
    setShowAddRotaUserShift(!showAddRotaUserShift);
    fetchAllRecords();
  };

  const [showEditRotaUserShift, setShowEditRotaUserShift] = useState(false);
  const subModalEditUserShift = (staffId, shiftId, serviceUserId, shift, dayName, weeekNo) => {
    setUserIdForRotaSchedule(staffId);
    setShiftIdForRotaSchedule(shiftId);
    setServiceUserIdForRotaSchedule(serviceUserId)
    setEditShiftDetailForrRotaSchedule(shift);
    setDayNameField(dayName)
    setShowEditRotaUserShift(!showEditRotaUserShift);
    setUpdateWeek(weeekNo)
    fetchAllRecords();
  };

  const [showViewShiftLogs, setShowViewShiftLogs] = useState(false);
  const subModalViewShiftLogs = (staffId, shiftId, serviceUserId, shift, dayName, weeekNo) => {
    setUserIdForRotaSchedule(staffId);
    setShiftIdForRotaSchedule(shiftId);
    setServiceUserIdForRotaSchedule(serviceUserId)
    setEditShiftDetailForrRotaSchedule(shift);
    setDayNameField(dayName)
    setShowViewShiftLogs(!showViewShiftLogs);

  };

  const [showDeleteRota, setShowDeleteRota] = useState(false);
  const subModalDeleteRotaShift = (delTempId, delShiftId, delLocationId) => {
    setShiftIdData({
      tempId: delTempId,
      shiftId: delShiftId,
      locationId: delLocationId
    });
    setShowDeleteRota(!showDeleteRota);
    fetchAllRecords();
  };

  const daysTableHeaTD = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

  function processFetchRecords(method, data) {
    if (method === "getAllServiceUsers" && data?.status === isSuccess) {
      const validServiceUsers = data?.data?.users?.filter(
        (item) => item?.modelId
      );
      setServiceUserData(validServiceUsers);
    } else if (method === "getAllStaff" && data?.status === isSuccess) {
      const result = data?.data?.staffMembers?.map((item) => item);
      const validServiceUsers = result?.filter((item) => item?.user?._id);
      setStaffMemberData(validServiceUsers);
      setStaffMemberFilterData(validServiceUsers);
    } else if (method === "AllLiveRostersUrl" && data?.status === isSuccess) {
      const completeResult = data?.data
      const result = data?.data?.liveRoster.map((item) => item);
      setLiveRosterURL(result);
      setCompleteLiveRosterURL(completeResult);
    } else if (method === "delete" && data?.status === isSuccess) {
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setTimeout(() => {
        setIsRotaUserShift(false);
      }, 2000);
    } else if (data?.status !== isSuccess) {
      console.error(data);
    }
  }

  const apiQuery = {
    sortproperty: "createdAt",
    sortorder: 1,
    offset: 0,
    query: {
      critarion: {
        active: true,
      },
    },
  };

  const fetchAllPatientRecords = async () => {
    try {
      const query = {
        sortproperty: "createdAt",
        sortorder: -1,
        offset: 0,
        query: {
          critarion: {
            active: true,
            role: "serviceuser",
          },
        },
      };
      const response = await getRecords(GET_ALL_SERVICE_USERS_URL, query);
      processFetchRecords("getAllServiceUsers", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllLiveRostersUrl = async () => {
    try {
      const query = {
        startDate: currentStartDate.startOf("isoWeek").valueOf(),
        endDate: currentStartDate.endOf("isoWeek").startOf("day").valueOf()
      }
      const response = await getRecords(CHECK_EXITS_TEMPLATE_SHIFTS_URL, query);
      processFetchRecords("AllLiveRostersUrl", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllStaffRecords = async () => {
    try {
      const response = await getRecords(GET_ALL_STAFF_MEMBERS_URL, apiQuery);
      processFetchRecords("getAllStaff", response);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchAllRecords = async () => {
    try {
      await fetchAllPatientRecords();
      await fetchAllStaffRecords();
      await fetchAllLiveRostersUrl();
      setIsLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchAllRecords();
  }, []);

  useEffect(() => {
    setIsLoading(false)
    fetchAllRecords();
    setIsLoading(true)
  }, [currentStartDate]);

  // Helper function to group the days by staff member
  const groupByStaffMember = () => {
    const groupedData = {};

    liveRosterURL.forEach((itemLevel1) => {

      itemLevel1?.shiftLocation?.forEach((itemLevel2) => {
        itemLevel2?.weekArray?.forEach((itemLevel4) => {
          if (itemLevel2?.locationId?._id === shiftLocationIdLevel) {
            itemLevel4?.daysArray?.forEach((itemLevel3) => {
              const weekNumber = itemLevel4?.weekNo;
              const staffName = itemLevel3?.staffUserId?.staffMemberName;
              const dayName = itemLevel3?.dayName;
              const workHrs = itemLevel3?.workHrs || 0; // Ensure workHrs is a number

              const shift = {
                // rosterId: itemLevel1?.id,
                shiftLocationId: itemLevel2?.locationId?._id,
                shiftStartTime: itemLevel3?.shiftStartTime,
                shiftEndTime: itemLevel3?.shiftEndTime,
                isSleepOver: itemLevel3?.isSleepOver,
                isOverNightStay: itemLevel3?.isOverNightStay,
                shiftDate: itemLevel3?.shiftDate,
                shiftId: itemLevel3?._id,
                note: itemLevel3?.note,
                serviceUserId: itemLevel3?.serviceUserId?._id,
                serviceUserName: itemLevel3?.serviceUserId?.name,
                suFirstMiddleName: itemLevel3?.serviceUserId?.modelId?.suFirstMiddleName,
                suLastName: itemLevel3?.serviceUserId?.modelId?.suLastName,
                workHrs,
                weekNo: itemLevel4?.weekNo,
                // weekNumber,
                // shiftId
              };

              if (!groupedData[staffName]) {
                groupedData[staffName] = { days: {}, totalWorkHrs: 0 };
              }

              if (!groupedData[staffName].days[dayName]) {
                groupedData[staffName].days[dayName] = [];
              }

              groupedData[staffName].days[dayName].push(shift);
              groupedData[staffName].totalWorkHrs += workHrs; // Accumulating work hours
            });
          }
        });
      });
    });

    return groupedData;
  };
  const groupedData = groupByStaffMember();
  const renderedStaffMembers = new Set();

  const sortedStaffMemberData = staffMemberData.sort((a, b) => {
    const staffNameA = a?.staffMemberName;
    const staffNameB = b?.staffMemberName;

    const hasDataA = Object.keys(groupedData[staffNameA]?.days || {}).length > 0;
    const hasDataB = Object.keys(groupedData[staffNameB]?.days || {}).length > 0;

    // Prioritize staff members with schedule data
    return hasDataB - hasDataA;
  });

  // Delete LiveRoster records
  const deleteRecord = catchAsync(async (req, res) => {
    setIsSubmitButtonClicked(true);
    if (shiftIdData !== "") {
      const response = await deleteRecords(
        DELETE_TEMPLATE_SHIFTS,
        shiftIdData
      );
      setIsRotaUserShift(true);
      processFetchRecords("delete", response);
      fetchAllRecords();
      setIsSubmitButtonClicked(false);
      setShowDeleteRota(!showDeleteRota);
    } else {
      setShowDeleteRota(!showDeleteRota);
      setIsSubmitButtonClicked(false);
      setIsRotaUserShift(true);
      toastErrorMessages(toast.error, "Unable to remove", 1000);
    }

  });

  // Search Staff Member
  useEffect(() => {

    const result = staffMemberFilterData.filter((user) => {
      return user.staffMemberName.toLowerCase().match(search.toLowerCase());
    });
    setStaffMemberData(result);
  }, [search, staffMemberFilterData]);


  // unAssigned shift according to staff members 
  const unAssignedGroupedData = liveRosterURL.reduce((acc, unAssignedItem) => {
    unAssignedItem?.shiftLocation?.forEach(shiftItem => {
      if (shiftItem?.locationId?._id === shiftLocationIdLevel) {
        shiftItem?.weekArray.forEach(weekItem => {
          weekItem?.daysArray.forEach(daysArrayItem => {
            if (daysArrayItem?.staffUserId === null) {
              const serviceUserName = daysArrayItem.serviceUserId.modelId?.suFirstMiddleName;
              if (!acc[serviceUserName]) {
                acc[serviceUserName] = [];
              }
              acc[serviceUserName].push({
                ...daysArrayItem,
                weekNo: weekItem?.weekNo
              });
            }
          });
        });
      }
    });
    return acc;
  }, {});

  const transformedData = Object.keys(unAssignedGroupedData).map(serviceUserName => ({
    serviceUserName,
    shifts: unAssignedGroupedData[serviceUserName]
  }));

  // Checking Date to add Data in liveRoster
  const [checkedDateToAddData, setCheckedDateToAddData] = useState(false);
  const todayDate = moment().startOf('day');
  const checkCurrentStartDate = moment(userCurrentStartDate).startOf('day');
  const diffDays = checkCurrentStartDate.diff(todayDate, 'days');

  // useEffect should only depend on userCurrentStartDate
  useEffect(() => {
    // This effect updates checkedDateToAddData based on the diffDays
    if (diffDays <= 365) {
      // console.log("Setting checkedDateToAddData to true");
      setCheckedDateToAddData(true);
    } else {
      console.log("Setting checkedDateToAddData to false");
      setCheckedDateToAddData(false);
    }
    // Only include userCurrentStartDate as a dependency
  }, [userCurrentStartDate]);

  const hasMounted = useRef(false);

  useEffect(() => {
    hasMounted.current = true;
  }, []); // This effect runs once, after the component mounts

  return (
    <>
      {/* <DragDropGridExample /> */}
      {
        hasMounted && checkedDateToAddData && (
          <AutoAddShifts userCurrentStartDate={userCurrentStartDate} templates={completeLiveRosterURL} fetchAllRecords={fetchAllRecords} />
        )
      }
      <div className="rosterTableContent individualStaffSchedule updateRotaSchedule">
        <div className="timeTableWrapper">
          <div className="timeTableItem">
            <div className="table-responsive">
              <DndContext onDragEnd={(event) => HandleDragDropStaffMember(
                event,
                liveRosterURL,
                staffMemberData,
                setLiveRosterURL,
                shiftLocationId,
                calendarDays,
                fetchAllLiveRostersUrl,
                setIsRotaUserShift)}>
                <table className="table table-bordered rotaRosterDragDrop">
                  <thead>
                    <tr>
                      <th>
                        <div className="searchContainer">
                          <div className="searchTextBox">
                            <input
                              type="text"
                              placeholder="Search by Staff Name"
                              value={search}
                              onChange={(e) => setSearch(e.target.value)}
                            />
                          </div>
                        </div>
                      </th>
                      {weekdays.map((weekDaysItem, weekDaysIndex) => (
                        calendarDays.map((calendarDaysItem, calendarDaysIndex) =>
                          moment(calendarDaysItem).format("dddd") === weekDaysItem ? (
                            <th>
                              {moment(
                                calendarDaysItem
                              ).format("DD")}{" "}
                              - {moment(weekDaysItem, 'dddd').format('ddd')}
                            </th>
                          ) : null
                        )))}
                    </tr>
                  </thead>
                  <tbody>
                    <>
                      {
                        isLoading ? (
                          <tr>
                            <td colSpan={8}>
                              <div className="subTabsWrapper p-5">
                                <ProgressComponent />
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {/* Un Assigned Shifts */}
                            {transformedData.map(({ shifts }, staffNameIndex) => (
                              <tr key={staffNameIndex}>
                                <td className="unAssignedShiftaDay">
                                  <div className="clearfix"></div>
                                  <div className="usersNameInfo pointerEvent-none text-danger mt-2">
                                    <div className="userImage">&nbsp;</div>
                                    <div className="userInfo">
                                      <span className="nameBlock fs-smaller">Unassigned Shift {staffNameIndex + 1}</span>
                                      <span className="actionRows d-flex-start"></span>
                                    </div>
                                  </div>
                                </td>
                                {daysTableHeaTD.map((daysName, index) => {
                                  const dayShifts = shifts.filter(shift => shift.dayName === daysName);
                                  return (
                                    <td key={index}>
                                      {dayShifts.length > 0 ? (
                                        dayShifts.map((shift, shiftIndex) => (
                                          <Droppable key={`${shift?.staffUserId}-${daysName}`} id={`${shift?.staffUserId}-${daysName}`}>
                                            {calendarDays.map((calendarDaysItem, calendarDaysIndex) => (
                                              moment(calendarDaysItem).format("DD/MM/YYYY") === moment(shift?.shiftDate).format("DD/MM/YYYY") && (
                                                moment(calendarDaysItem).endOf("day").valueOf() >= moment().startOf("day").valueOf() ? (
                                                  <Draggable
                                                    key={shift?._id}
                                                    id={shift?._id}
                                                    onEditButtonClick={() => {
                                                      subModalEditUserShift(
                                                        shift?.staffUserId,
                                                        shift?._id,
                                                        shift?.serviceUserId?._id,
                                                        shift,
                                                        daysName,
                                                        shift?.weekNo
                                                      );
                                                    }}
                                                    onViewLogClick={() => {
                                                      subModalViewShiftLogs(
                                                        shift?.staffUserId,
                                                        shift?._id,
                                                        shift?.serviceUserId?._id,
                                                        shift,
                                                        daysName,
                                                        shift?.weekNo
                                                      );
                                                    }}
                                                  >
                                                    <div key={shiftIndex}
                                                      className={`contentcell ${shift?.staffMemberName ? "greenCell" : shift?.staffMemberName ? "greenCell" : "redCell"}`}>
                                                      <div className="contentDataCell">
                                                        <div className="dataContent">
                                                          <span className="time">
                                                            {shift.shiftStartTime} - {shift.shiftEndTime}
                                                          </span>
                                                          {shift?.serviceUserId && (
                                                            <span>
                                                              <small>{shift?.serviceUserId?.modelId?.suFirstMiddleName}&nbsp; {shift?.serviceUserId?.modelId?.suLastName}</small>
                                                            </span>
                                                          )}
                                                        </div>
                                                        <div className="overNightStatus">
                                                          {/* Sleep Over / Night Stay */}
                                                          {shift?.isSleepOver && (
                                                            <span className="sleepOver-icon">
                                                              <img src={sleepOverIcon} alt="" />
                                                            </span>
                                                          )}
                                                          {shift?.isOverNightStay && (
                                                            <span className="sleepOver-icon">
                                                              <img src={overNightStay} alt="" />
                                                            </span>
                                                          )}
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </Draggable>
                                                ) : (
                                                  <div key={shiftIndex}
                                                    className={`previousDate contentcell ${shift?.staffMemberName ? "greenCell" : shift?.staffMemberName ? "greenCell" : "redCell"}`}>
                                                    <div className="contentDataCell">
                                                      <div className="dataContent">
                                                        <span className="time">
                                                          {shift.shiftStartTime} - {shift.shiftEndTime}
                                                        </span>
                                                        {shift?.serviceUserId && (
                                                          <span>
                                                            <small>{shift?.serviceUserId?.modelId?.suFirstMiddleName}&nbsp; {shift?.serviceUserId?.modelId?.suLastName}</small>
                                                          </span>
                                                        )}
                                                      </div>
                                                      <div className="overNightStatus">
                                                        {/* Sleep Over / Night Stay */}
                                                        {shift?.isSleepOver && (
                                                          <span className="sleepOver-icon">
                                                            <img src={sleepOverIcon} alt="" />
                                                          </span>
                                                        )}
                                                        {shift?.isOverNightStay && (
                                                          <span className="sleepOver-icon">
                                                            <img src={overNightStay} alt="" />
                                                          </span>
                                                        )}
                                                      </div>
                                                    </div>
                                                  </div>
                                                )
                                              )))}
                                          </Droppable>
                                        ))
                                      ) : null}
                                    </td>
                                  );
                                })}
                              </tr>
                            ))}

                            {/* Assigned shifts */}
                            {staffMemberData.map((itemStaff, indexStaff) => {
                              const staffName = itemStaff?.staffMemberName;
                              const shouldRenderRow = !renderedStaffMembers.has(staffName);

                              if (shouldRenderRow) {
                                renderedStaffMembers.add(staffName);
                                const totalWorkHrs = groupedData[staffName]?.totalWorkHrs || 0;

                                return (
                                  <tr key={indexStaff}>
                                    <td>
                                      <div className="userInfoWrapper">
                                        <div className="usersNameInfo pointerEvent-none">
                                          <div className="userImage">
                                            <img src={
                                              itemStaff?.user?.profile_picture_url ? (
                                                BASE_URL + itemStaff?.user?.profile_picture_url
                                              ) : (
                                                BASE_URL + "/uploads/dp/default.png"
                                              )
                                            } alt={itemStaff?.staffMemberName} />
                                          </div>
                                          <div className="userInfo">
                                            <span className="nameBlock">
                                              {itemStaff?.staffMemberName}
                                            </span>
                                            <span>
                                              <small>
                                                <strong>C:</strong>{
                                                  itemStaff?.contractedHours?.contractedHours ? (
                                                    itemStaff?.contractedHours?.contractedHours
                                                  ) : (
                                                    <>0</>
                                                  )
                                                } hrs
                                                &nbsp;&nbsp;
                                                <strong>T:</strong>{totalWorkHrs} hrs
                                              </small>
                                            </span>
                                          </div>
                                        </div>
                                        {/* Contracted Hours Detail */}
                                        <OverlayTrigger
                                          placement="right"
                                          delay={{ show: 250, hide: 400 }}
                                          overlay={
                                            <Tooltip className="toolTipInfo dropDownRota">
                                              <p className="mb-2">
                                                <strong>Contracted hours:&nbsp;</strong>
                                                {
                                                  itemStaff?.contractedHours?.contractedHours
                                                    ? itemStaff?.contractedHours?.contractedHours + " hrs"
                                                    : "0 hrs"
                                                }
                                              </p>
                                              <p className="mb-2">
                                                <strong>Total hours:&nbsp;</strong>
                                                {
                                                  totalWorkHrs ? totalWorkHrs + " hrs" : "0 hrs"
                                                }
                                              </p>
                                              <p className="mb-2">
                                                <strong>Total shifts:&nbsp;</strong>
                                                {
                                                  daysTableHeaTD.reduce((sum, daysName) => {
                                                    const length = groupedData[staffName]?.days[daysName]?.length || 0;
                                                    return sum + length;
                                                  }, 0)
                                                } shift
                                              </p>
                                            </Tooltip>
                                          }
                                          className="tooltipOverlay"
                                        >
                                          <Button>
                                            <img src={menuToggleVertical} alt={menuToggleVertical} />
                                          </Button>
                                        </OverlayTrigger>
                                      </div>
                                    </td>
                                    {daysTableHeaTD.map((daysName, daysIndex) => (
                                      <td key={daysIndex}>
                                        {/* Assigned Shift Droppable */}
                                        <Droppable key={`${itemStaff?._id}-${daysName}`} id={`${itemStaff?._id}-${daysName}`}>
                                          {groupedData[staffName]?.days[daysName]?.length > 0 ? (
                                            <>
                                              {
                                                groupedData[staffName].days[daysName].map((shift, shiftIndex) => (
                                                  <>
                                                    {
                                                      calendarDays.map((calendarDaysItem, calendarDaysIndex) => (
                                                        <>
                                                          {
                                                            moment(calendarDaysItem).format("DD/MM/YYYY") === moment(shift?.shiftDate).format("DD/MM/YYYY") && (
                                                              moment(calendarDaysItem).endOf("day").valueOf() >= moment().startOf("day").valueOf() ? (
                                                                <>
                                                                  <Draggable
                                                                    key={shift?.shiftId}
                                                                    id={shift?.shiftId}
                                                                    onEditButtonClick={() => {
                                                                      subModalEditUserShift(
                                                                        itemStaff?._id,
                                                                        shift?.shiftId,
                                                                        shift?.serviceUserId,
                                                                        shift,
                                                                        daysName,
                                                                        shift?.weekNo
                                                                      );
                                                                    }}
                                                                    onViewLogClick={() => {
                                                                      subModalViewShiftLogs(
                                                                        shift?.staffUserId,
                                                                        shift?._id,
                                                                        shift?.serviceUserId?._id,
                                                                        shift,
                                                                        daysName,
                                                                        shift?.weekNo
                                                                      );
                                                                    }}
                                                                  >
                                                                    <div key={shiftIndex}
                                                                      className={`contentcell
                                              ${shift?.suFirstMiddleName ? "greenCell" : shift?.serviceUserName ? "greenCell" : "redCell"} 
                                              `}>

                                                                      <div className="contentDataCell">
                                                                        <div className="dataContent">
                                                                          <span className="time">
                                                                            {shift.shiftStartTime} - {shift.shiftEndTime}
                                                                          </span>
                                                                          {
                                                                            shift?.suFirstMiddleName ? (
                                                                              <span>
                                                                                <small>
                                                                                  {shift?.suFirstMiddleName}
                                                                                  &nbsp;
                                                                                  {shift?.suLastName}ِ<br />
                                                                                </small>
                                                                              </span>
                                                                            ) : shift?.serviceUserName ? (
                                                                              <span>
                                                                                <small>
                                                                                  {shift?.serviceUserName}
                                                                                </small>
                                                                              </span>

                                                                            ) : (
                                                                              <span>
                                                                                <small className="c-red">
                                                                                  Unassigned Shift
                                                                                </small>
                                                                              </span>
                                                                            )
                                                                          }
                                                                        </div>
                                                                        <div className="overNightStatus">
                                                                          {/* Sleep Over / Night Stay */}
                                                                          {
                                                                            shift?.isSleepOver && (
                                                                              <span className="sleepOver-icon">
                                                                                <img src={sleepOverIcon} alt="" />
                                                                              </span>
                                                                            )
                                                                          }
                                                                          {
                                                                            shift?.isOverNightStay && (
                                                                              <span className="sleepOver-icon">
                                                                                <img src={overNightStay} alt="" />
                                                                              </span>
                                                                            )
                                                                          }
                                                                        </div>
                                                                      </div>
                                                                    </div>
                                                                  </Draggable>
                                                                </>
                                                              ) : (
                                                                <>

                                                                  <div key={shiftIndex}
                                                                    className={`previousDate contentcell
                                              ${shift?.suFirstMiddleName ? "greenCell" : shift?.serviceUserName ? "greenCell" : "redCell"} 
                                              `}>

                                                                    <div className="contentDataCell">
                                                                      <div className="dataContent">
                                                                        <span className="time">
                                                                          {shift.shiftStartTime} - {shift.shiftEndTime}
                                                                        </span>
                                                                        {
                                                                          shift?.suFirstMiddleName ? (
                                                                            <span>
                                                                              <small>
                                                                                {shift?.suFirstMiddleName}
                                                                                &nbsp;
                                                                                {shift?.suLastName}ِ<br />
                                                                              </small>
                                                                            </span>
                                                                          ) : shift?.serviceUserName ? (
                                                                            <span>
                                                                              <small>
                                                                                {shift?.serviceUserName}
                                                                              </small>
                                                                            </span>
                                                                          ) : (
                                                                            <span>
                                                                              <small className="c-red">
                                                                                Unassigned Shift
                                                                              </small>
                                                                            </span>
                                                                          )
                                                                        }
                                                                      </div>
                                                                      <div className="overNightStatus">
                                                                        {/* Sleep Over / Night Stay */}
                                                                        {
                                                                          shift?.isSleepOver && (
                                                                            <span className="sleepOver-icon">
                                                                              <img src={sleepOverIcon} alt="" />
                                                                            </span>
                                                                          )
                                                                        }
                                                                        {
                                                                          shift?.isOverNightStay && (
                                                                            <span className="sleepOver-icon">
                                                                              <img src={overNightStay} alt="" />
                                                                            </span>
                                                                          )
                                                                        }
                                                                      </div>
                                                                    </div>
                                                                  </div>
                                                                </>
                                                              )

                                                            )
                                                          }
                                                        </>
                                                      )
                                                      )}
                                                  </>
                                                ))
                                              }
                                              {
                                                calendarDays.map((calendarDaysItem, calendarDaysIndex) => (
                                                  <>
                                                    {
                                                      moment(calendarDaysItem).format("ddd") === daysName && (
                                                        moment(calendarDaysItem).endOf("day").valueOf() >= moment().startOf("day").valueOf() ? (
                                                          liveRosterURL[0]?.status !== "active" ? (
                                                            <span>
                                                              <img
                                                                src={schedulePlus}
                                                                alt="update"
                                                                className="addNewDay cursor-pointer rotaImageWidth"
                                                                onClick={() => {
                                                                  subModalAddUserShift(
                                                                    itemStaff?._id,
                                                                    daysName,
                                                                    moment(calendarDaysItem).valueOf()
                                                                  );
                                                                }}
                                                              />
                                                            </span>
                                                          ) : null
                                                        ) : (
                                                          <span>
                                                            <img
                                                              src={schedulePlus}
                                                              alt="update"
                                                              className="visibilityHidden"
                                                            />
                                                          </span>
                                                        )
                                                      )
                                                    }

                                                  </>
                                                ))
                                              }
                                            </>
                                          ) : (
                                            calendarDays.map((calendarDaysItem, calendarDaysIndex) => (
                                              moment(calendarDaysItem).format("ddd") === daysName && (
                                                moment(calendarDaysItem).endOf("day").valueOf() >= moment().startOf("day").valueOf() ? (
                                                  liveRosterURL[0]?.status !== "active" ? (
                                                    <span>
                                                      <img
                                                        src={schedulePlus}
                                                        alt="update"
                                                        className="addNewDay cursor-pointer rotaImageWidth"
                                                        onClick={() => {
                                                          subModalAddUserShift(
                                                            itemStaff?._id,
                                                            daysName,
                                                            moment(calendarDaysItem).valueOf()
                                                          );
                                                        }}
                                                      />
                                                    </span>
                                                  ) : null
                                                ) : null
                                              )
                                            ))
                                          )}
                                        </Droppable>
                                      </td>
                                    ))}
                                  </tr>
                                );
                              } else {
                                return null;
                              }
                            })}
                          </>
                        )}
                    </>
                  </tbody>
                </table>
              </DndContext>
            </div>
          </div>



        </div>
      </div >

      {/* Add Rota Shift */}
      < Modal
        show={showAddRotaUserShift}
        onHide={subModalAddUserShift}
        className="ftcModalPopup medicineInventoryStock"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Add New Schedule - Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          <AddRosterSchedule
            staffId={userIdForRotaSchedule}
            dayName={dayNameField}
            shiftLocationId={shiftLocationIdLevel}
            weekNumberLevel3={weekNumberLevel2}
            subModalAddUserShift={subModalAddUserShift}
            addRosterId={rosterIdSchedule}
            addRosterShiftDate={addRosterShiftDate}
          />

        </Modal.Body>
      </Modal >


      {/* Edit Rota Shift */}
      < Modal
        show={showEditRotaUserShift}
        onHide={subModalEditUserShift}
        className="ftcModalPopup medicineInventoryStock"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Edit Schedule - Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          {/* {shiftIdForRotaSchedule} */}
          <EditRosterSchedule
            shiftDetail={editShiftDetailForrRotaSchedule}
            staffId={userIdForRotaSchedule}
            shiftId={shiftIdForRotaSchedule}
            serviceUserId={serviceUserIdForRotaSchedule}
            dayName={dayNameField}
            shiftLocationId={shiftLocationIdLevel}
            weekNumberLevel3={updateWeek}
            subModalEditUserShift={subModalEditUserShift}
            templateId={liveRosterURL[0]?._id}
          />

        </Modal.Body>
      </Modal >


      {/* View Shift logs */}
      < Modal
        show={showViewShiftLogs}
        onHide={subModalViewShiftLogs}
        className="ftcModalPopup medicineInventoryStock"
        size="md"
      >
        <Modal.Header closeButton>
          <Modal.Title>Shifts Logs - Roster</Modal.Title>
        </Modal.Header>
        <Modal.Body className="rosterPopup">
          {/* {shiftIdForRotaSchedule} */}
          <ShiftLogs
            showViewShiftLogs={subModalViewShiftLogs}
          />

        </Modal.Body>
      </Modal >


      {/* Delete Rota Shift */}
      < ProtectedComponent requiredPermissions={[permissions.DELETE]} >
        <Modal
          show={showDeleteRota}
          onHide={subModalDeleteRotaShift}
          className="ftcModalPopup confirmationPopup deletionConfirmation"
        >
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="editBlockContent text-center">
              <img src={eventDel} alt={eventDel} />
              <p>Are you sure you want to delete this shift?</p>
            </div>
          </Modal.Body>
          <Modal.Footer>
            {isSubmitButtonClicked ? (
              <Button className="btn-sm" variant="danger" disabled>
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="danger"
                onClick={deleteRecord}
              >
                Yes
              </Button>
            )}

            <Button
              className="btn-sm"
              variant="outline-secondary"
              onClick={subModalDeleteRotaShift}
            >
              No
            </Button>
          </Modal.Footer>
        </Modal>
      </ProtectedComponent >
      {isRotaUserShift && <ToastContainer />}
    </>
  );
}

export default StaffMembersRosterTabs;