import { useAuth } from "./AuthContext";
import useAuthorization from "./useAuthorization";

const useColumnsWithPermission = (columns, requiredPermissions) => {
  const { userRoles } = useAuth();
  const isAuthorized = useAuthorization(userRoles, requiredPermissions);

  if (!isAuthorized) {
    return columns.filter((column) => column.name !== "Action");
  }

  return columns;
};

export default useColumnsWithPermission;