import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min.js";
import { Dropdown } from "react-bootstrap";
import { logout } from "../../helper/helper"
import { BASE_URL } from "../../env";


function ProfileDropdown() {
  const loggedInUserDetails = localStorage.getItem("userLoggedInDetails");
  const userDetails = JSON.parse(loggedInUserDetails);
  return (
    <Dropdown className="profileDropdown">
      <Dropdown.Toggle id="dropdown-basic">
        <img
          src={BASE_URL + userDetails?.profile_picture_url}
          alt={"profilePic"}
        />

        <p>
          {userDetails?.name}
          {/* <small>{userDetails?.role}</small> */}
        </p>
      </Dropdown.Toggle>

      <Dropdown.Menu>
        {/* <Dropdown.Item href="#">Profile</Dropdown.Item> */}
        <Dropdown.Item onClick={logout}>Logout</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
}

export default ProfileDropdown;
