import React, { useState, useRef } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addRecentIncidents } from "../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDATE_RECENT_INCIDENT_URL } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import { Link } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import moment from 'moment';

function EditRecentIncidents() {
  const storedIncidentDetail = localStorage.getItem("recentIncidentDetail");
  const recentIncidentDetail = JSON.parse(storedIncidentDetail);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);


  const editor = useRef(null);
  const [content, setContent] = useState(recentIncidentDetail?.note);

  const [suFormData, setSuFormData] = useState({
    userId: recentIncidentDetail?.userId?._id,
    incidentTitle: recentIncidentDetail?.incidentTitle,
    incidentDate: moment(recentIncidentDetail?.incidentDate).format('YYYY-MM-DDTHH:mm'),
    note: content,
    id: recentIncidentDetail?._id
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addRecentIncidents,
    onSubmit: async (values) => {
      try {
        console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postEditPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "/recent-incidents", 500, 500);

      setIsSubmitButtonClicked(false);
      setTimeout(() => {
      }, 2000);
    }
  };

  const postEditPbsPlansFormData = catchAsync(async (req, res) => {
    console.log(values);
    const updatedValues = {
      ...values,
      incidentDate: moment(values.incidentDate).valueOf(),
      note: content,
    }
    const response = await postRecords(UPDATE_RECENT_INCIDENT_URL, updatedValues);
    console.log("🚀 ~ postEditPbsPlansFormData ~ response:", response)
    setIsSubmitButtonClicked(false);
    processPostRecords("postEditPbsPlansFormData", response);
  });


  const handleChangeTextEditor = (newContent) => {
    setContent(newContent);
  };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Recent Incidents
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <div className="mainContent">
            <div className="mainContentText">
              <h3>Edit Incident Record</h3>
              <form onSubmit={handleSubmit}>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-md-12">
                      <Input
                        type="datetime-local"
                        id="incidentDate"
                        name="incidentDate"
                        value={values?.incidentDate}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Incident Date"
                        customError={touched?.incidentDate && errors?.incidentDate}
                        errorMessage={errors?.incidentDate}
                        erroClass={
                          touched?.incidentDate && errors?.incidentDate
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <Input
                        type="text"
                        id="incidentTitle"
                        name="incidentTitle"
                        value={values?.incidentTitle}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        labelText="Incident Title"
                        customError={touched?.incidentTitle && errors?.incidentTitle}
                        errorMessage={errors?.incidentTitle}
                        erroClass={
                          touched?.incidentTitle && errors?.incidentTitle
                            ? "validationError"
                            : ""
                        }
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-md-12">
                      <JoditEditor
                        ref={editor}
                        value={values?.note}
                        tabIndex={1}
                        onChange={handleChangeTextEditor}
                      />
                    </div>

                  </div>
                </div>

                <div className="formActionButtons mt-5">
                  <Link to="/recent-incidents">
                    <span
                      className="btn btn-sm btn-outline-primary"
                    >
                      Cancel
                    </span>
                  </Link>
                  {isSubmitButtonClicked ? (
                    <Button
                      className="btn-sm"
                      variant="primary"
                      disabled
                    >
                      Loading...
                    </Button>
                  ) : (
                    <Button className="btn-sm" variant="primary" type="submit">
                      Save
                    </Button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </Layout>
      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default EditRecentIncidents