import React from "react";
import Table from "react-bootstrap/Table";

function CareNotesTable() {
  return (
    <>
      <h3>Care Notes</h3>
      <Table striped bordered hover responsive>
        <thead>
          <tr>
            <th>Date</th>
            <th>Note Heading</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Aug/08/2023</td>
            <td>Medical Care</td>
          </tr>
        </tbody>
      </Table>
    </>
  );
}

export default CareNotesTable;
