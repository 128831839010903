import React from "react";
import { useLocation } from "react-router-dom";
import Layout from "../../components/layouts/Layout";
import SupervisionsGroup from "./SupervisionsGroup";
import SupervisionIndividual from "./SupervisionIndividual";
import SupervisionQAIndividual from "./SupervisionQAIndividual";

function Supervisions() {
  const location = useLocation();

  const userData = location?.state && location?.state?.userId;

  const superVisionUserData = location?.state?.userData;

  return (
    <Layout pageTitleText="Supervisions">
      {userData ? (
        <SupervisionIndividual getUserRecord={userData} />
      ) : superVisionUserData ? (
        <SupervisionQAIndividual getUserRecord={superVisionUserData} />
      ) : (
        <SupervisionsGroup />
      )}
    </Layout>
  );
}

export default Supervisions;
