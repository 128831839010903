import React, { useState, useRef, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import Button from "react-bootstrap/Button";
import { editCarePlanDocument } from "../../../schemas";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { UPDTE_CARE_PLAN_DOC_URL, ADMIN_ID } from "../../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../../helper/helper";
import CustomSelect from "../../../components/forms-fields/CustomSelectStaffMembers";
import moment from 'moment';
import Modal from 'react-bootstrap/Modal';
import { Form } from "react-bootstrap";
function IsReviewed({ modalPopupData, handelModalPopupClose }) {
  const storedIncidentDetail = localStorage.getItem("carePlanDetail");
  const carePlanDetail = JSON.parse(storedIncidentDetail);
  const [selectedUserId, setSelectedUserId] = useState(ADMIN_ID);
  useEffect(() => {
    carePlanDetail?.lastModifiedBy?._id ? (
      setSelectedUserId(carePlanDetail?.lastModifiedBy?._id)
    ) : (
      setSelectedUserId(carePlanDetail?.addedby?._id)
    )
  })
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddPbsPlans, setIsToastAddPbsPlans] = useState(false);
  // Editor Variable



  const handleUserSelect = (userId) => {
    setSelectedUserId(userId);
    setFieldValue("lastModifiedBy.modifiedUser", userId);
  };


  const editor = useRef(null);

  const [suFormData, setSuFormData] = useState({
    id: carePlanDetail?._id,
    isReviewed: false,
    reviewedBy: {
      reviewedUser: ADMIN_ID,
      reviewedTime: moment().valueOf(),
      involvementAndConsentCheck: false,
    },
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: editCarePlanDocument,
    onSubmit: async (values) => {
      try {
        setIsSubmitButtonClicked(true);
        await postEditPbsPlansFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    if (data?.status !== isSuccess) {
      setIsToastAddPbsPlans(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
    } else if (
      method === "postEditPbsPlansFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddPbsPlans(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handelModalPopupClose()
      }, 2000);

    }
  };

  const postEditPbsPlansFormData = catchAsync(async (req, res) => {
    const updatedValues = {
      ...values,

    };
    if (updatedValues?.isReviewed) {
      const response = await postRecords(UPDTE_CARE_PLAN_DOC_URL, updatedValues);
      // console.log("🚀 ~ postEditPbsPlansFormData ~ response:", updatedValues)
      setIsSubmitButtonClicked(false);
      processPostRecords("postEditPbsPlansFormData", response);
    }
  });



  return (
    <>


      <form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className="row p-3">
            <div className="col-md-12">
              <Form.Check
                type="checkbox"
                id={'isReviewed'}
                name={'isReviewed'}
                checked={values?.isReviewed}
                onChange={(e) => {
                  handleChange(e);
                  // handleSubmit();
                }}
                onBlur={handleBlur}
                label={'Checked as Reviewed'}
              />

            </div>
            <div className="col-md-12">
              <Form.Check
                type="checkbox"
                id={'involvementAndConsentCheck'}
                name={'involvementAndConsentCheck'}
                checked={values?.reviewedBy?.involvementAndConsentCheck}
                onChange={(e) => {
                  setFieldValue("reviewedBy.involvementAndConsentCheck", e.target.checked)
                  // handleSubmit();
                }}
                onBlur={handleBlur}
                label={'Has the service user been involved in the care plan?'}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div className="formActionButtons">

            <span
              className="btn btn-sm btn-outline-primary"
              onClick={handelModalPopupClose}
            >
              Cancel
            </span>

            {isSubmitButtonClicked ? (
              <Button
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </Modal.Footer>
      </form>


      {isToastAddPbsPlans && <ToastContainer />}
    </>
  );
}

export default IsReviewed