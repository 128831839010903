import React, { useState, useEffect } from "react";
import { Tab, Nav, Button, Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Input from "../../../components/forms-fields/Input";
import ProgressComponent from "../../../components/general/ProgressComponent";
import CustomSelectBox from "../../../components/forms-fields/CustomSelectBox";
import {
  postRecords,
  isSuccess,
  catchAsync,
  formateDate,
  toastErrorMessages,
} from "../../../helper/helper";
import {
  rotaTempStatusOptions,
  rotaTempFutureStatusOptions,
  rotaTempWeeksOptions,
} from "../../../helper/helperJsx";
import { ADMIN_ID, POST_NEW_ROTA_TEMPLATE_URL } from "../../../env";

function SaveFutureRotaTemplate({
  handleShowModel,
  handleCloseModel,
  scheduleTemplate,
  fetchAllRecords,
}) {
  const [isFormSubmitClicked, setIsFormSubmitClicked] = useState(false);
  const [startDateState, setStartDateState] = useState(
    scheduleTemplate &&
      scheduleTemplate?.startDate &&
      formateDate(scheduleTemplate && scheduleTemplate?.startDate, "yyyy-mm-dd")
  );
  const [isToastRotaSchedule, setIsToastRotaSchedule] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const validationSchema = Yup?.object().shape({
    rotaTemplateTitle: Yup?.string()?.required("Title is required."),
    startDate: Yup.number().required("Start date is required"),
    templateWeeks: Yup?.number()
      .required("No of weeks is required.")
      .oneOf([2, 4], "Please select either 2 weeks or 4 weeks."),
  });

  const saveAsNewCopyRotaTemplate = useFormik({
    initialValues: {
      tempId: "",
      rotaTemplateTitle: "",
      templateWeeks: "",
      startDate: null,
      status: "future",
      addedby: ADMIN_ID,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      try {
        setIsFormSubmitClicked(true);
        await handleAddNewDateTemp(values);
      } catch (error) {
        toastErrorMessages(
          toast?.error,
          "Error. Please try again.",
          "",
          1000,
          1000
        );
        setIsFormSubmitClicked(false);
      }
    },
  });

  const {
    handleChange,
    handleBlur,
    handleSubmit,
    values,
    errors,
    touched,
    setFieldValue,
    setValues,
    resetForm,
  } = saveAsNewCopyRotaTemplate;

  console.log("saveAsNewCopyRotaTemplate", saveAsNewCopyRotaTemplate);

  const processFetchRecords = async (method, scheduleTemplate) => {
    if (scheduleTemplate?.status !== isSuccess) {
      setIsFormSubmitClicked(false);
      setIsToastRotaSchedule(true);
      toastErrorMessages(toast?.error, scheduleTemplate?.message, "", 500, 500);
    } else if (
      method === "saveNewCopy" &&
      scheduleTemplate?.status === isSuccess
    ) {
      await fetchAllRecords();
      handleCloseModel();
    }
  };

  const handleAddNewDateTemp = catchAsync(async (req, res) => {
    const response = await postRecords(POST_NEW_ROTA_TEMPLATE_URL, req);
    await fetchAllRecords();
    processFetchRecords("saveNewCopy", response);
  });

  const handleDateChangeToNumeric = (e) => {
    const dateValue = e.target?.value;
    const dateObject = new Date(dateValue);
    setFieldValue("startDate", dateObject.getTime());
    setStartDateState(dateValue);
  };

  const handleSetValues = async (template) => {
    await setValues({
      tempId: template?._id,
      rotaTemplateTitle: template?.rotaTemplateTitle,
      templateWeeks: template?.templateWeeks,
      shiftLocation: template?.shiftLocation,
      startDate: template?.startDate,
      status: "future",
      lastModifiedBy: ADMIN_ID,
    });
    setIsLoading(false);
  };

  useEffect(() => {
    if (scheduleTemplate) {
      handleSetValues(scheduleTemplate);
    }
  }, [scheduleTemplate]);

  return (
    <>
      {isLoading ? (
        <ProgressComponent />
      ) : (
        <Modal
          show={handleShowModel}
          onHide={handleCloseModel}
          className="ftcModalPopup"
        >
          <Modal.Header closeButton>
            <Modal.Title>Create Future Template</Modal.Title>
          </Modal.Header>
          <form method="post" onSubmit={handleSubmit}>
            <Modal.Body>
              <div className="row align-items-center mt-4">
                <div className="col-sm-12">
                  <div className="form-group">
                    <Input
                      type="text"
                      name="rotaTemplateTitle"
                      value={values?.rotaTemplateTitle}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      labelText="Title"
                      customError={
                        touched?.rotaTemplateTitle && errors?.rotaTemplateTitle
                      }
                      errorMessage={errors?.rotaTemplateTitle}
                      errorClass={
                        touched?.rotaTemplateTitle && errors?.rotaTemplateTitle
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12">
                  <div className="form-group">
                    <Input
                      type="date"
                      name="startDate"
                      value={startDateState}
                      onChange={handleDateChangeToNumeric}
                      omBlurFunc={handleBlur}
                      labelText="Start Date"
                      customError={touched?.startDate && errors?.startDate}
                      errorMessage={errors?.startDate}
                      errorClass={
                        touched?.startDate && errors?.startDate
                          ? "validationError"
                          : ""
                      }
                      requiredStar={true}
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectBox
                      name="templateWeeks"
                      defaultValue={rotaTempWeeksOptions?.find((item) => {
                        return item?.value === values?.templateWeeks;
                      })}
                      options={rotaTempWeeksOptions}
                      onChange={(selectedOption) => {
                        setFieldValue("templateWeeks", selectedOption?.value);
                      }}
                      isSearchable={false}
                      errors={errors?.templateWeeks}
                      labelText="Weeks"
                      required
                    />
                  </div>
                </div>
                <div className="col-sm-12 col-md-12">
                  <div className="form-group">
                    <CustomSelectBox
                      name="status"
                      defaultValue={rotaTempFutureStatusOptions?.find(
                        (item) => {
                          return item?.value === values?.status;
                        }
                      )}
                      options={rotaTempFutureStatusOptions}
                      onChange={(selectedOption) => {
                        setFieldValue("status", selectedOption?.value);
                      }}
                      errors={errors?.status}
                      isDisabled={false}
                      isSearchable={false}
                      labelText="Status"
                      required
                    />
                  </div>
                </div>
              </div>
            </Modal.Body>
            <Modal.Footer>
              {isFormSubmitClicked ? (
                <ProgressComponent />
              ) : (
                <>
                  <Button
                    className="btn-sm"
                    variant="outline-primary"
                    onClick={handleCloseModel}
                  >
                    Cancel
                  </Button>

                  <Button className="btn-sm" type="submit" variant="primary">
                    Create
                  </Button>
                </>
              )}
            </Modal.Footer>
          </form>
        </Modal>
      )}
      {isToastRotaSchedule && <ToastContainer />}
    </>
  );
}

export default SaveFutureRotaTemplate;
