import React from "react";
import ScheduleBlocks from "../../../components/general/ScheduleBlocks";
import calendarIcon from "../../../assets/images/icons/calendar.svg";
import clockIcon from "../../../assets/images/icons/clock.svg";

function PayrollBlocks({ currentStatus }) {
  return (
    <>
      <div className="scheduleBlocks">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <ScheduleBlocks
              editIconClass={"editIcon"}
              blockIcon={clockIcon}
              heading={"72"}
              subHeading={"Total Hours"}
            />
          </div>
          <div className="col-md-6 col-sm-12">
            {currentStatus ? (
              <p className="currentStatus">
                <strong>Current Status: Active</strong> <span>&nbsp;</span>
              </p>
            ) : null}
            <ScheduleBlocks
              editIconClass={"editIcon"}
              blockIcon={calendarIcon}
              heading={"04"}
              subHeading={"Total Days"}
            />
          </div>
        </div>
      </div>
    </>
  );
}

export default PayrollBlocks;
