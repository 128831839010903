import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import Input from "../../components/forms-fields/Input";
import { addMedicationStock } from "../../schemas";
import { useFormik } from "formik";
import Select from 'react-select';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { POST_MEDICINE_STOCK_URL } from "../../env";
import {
  postRecords,
  catchAsync,
  isSuccess,
  toastSuccessMessages,
  toastErrorMessages,
} from "../../helper/helper";
import MedicineListDetail from "../../components/forms-fields/MedicineListDetail";

function AddMedicineStock({ userId, subModalAddMedicine }) {
  const handleShowHideModal = () => {
    subModalAddMedicine((prevShow) => !prevShow);
  };
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);
  const [isToastAddMedicine, setIsToastAddMedicine] = useState(false);
  const [selectedMedication, setSelectedMedication] = useState(null);
  const [medicationData, setMedicationData] = useState([]);
  const [medicationFilteredData, setMedicationFilteredData] = useState([]);
  const [suFormData, setSuFormData] = useState({
    medicationName: "",
    user: userId,
    inventory: "",
    unitType: "",
    strength: "",
    medicineType: "",
  });

  const {
    values,
    errors,
    touched,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setValues,
  } = useFormik({
    initialValues: suFormData,
    validationSchema: addMedicationStock,
    onSubmit: async (values) => {
      try {
        // console.log("Form Data:", values);
        setIsSubmitButtonClicked(true);
        await postAddMedicineFormData(values);
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    },
  });

  const processPostRecords = (method, data) => {
    // console.log(data);
    if (data?.status !== isSuccess) {
      setIsToastAddMedicine(true);
      toastErrorMessages(toast.error, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      handleShowHideModal();
    } else if (
      method === "postAddMedicineFormData" &&
      data?.status === isSuccess
    ) {
      setIsToastAddMedicine(true);
      toastSuccessMessages(toast.success, data?.message, "", 500, 500);
      setIsSubmitButtonClicked(false);
      setTimeout(() => {
        handleShowHideModal();
      }, 2000);
    }
  };

  const postAddMedicineFormData = catchAsync(async (req, res) => {
    // console.log(values);
    console.log("🚀 ~ postAddMedicineFormData ~ values:", values)
    const response = await postRecords(POST_MEDICINE_STOCK_URL, values);
    processPostRecords("postAddMedicineFormData", response);
    setIsSubmitButtonClicked(false);
  });



  const handleInputChangePrevFields = (e) => {
    if (e && e?.value !== undefined) {
      setFieldValue("unitType", e.value);
    }
    else {
      handleChange(e);
      handleBlur(e);
    }
  };

  const options = [
    { label: 'Liquid', value: 'Liquid' },
    { label: 'Tablet', value: 'Tablet' },
    { label: 'Capsules', value: 'Capsules' },
    { label: 'Drops', value: 'Drops' },
    { label: 'Suppositories', value: 'Suppositories' },
    { label: 'Inhalers', value: 'Inhalers' },
    { label: 'Injections', value: 'Injections' },
    // Add more options as needed
  ];

  const handleMedicationSelect = (value) => {
    setSelectedMedication(value);
  };

  const handleMedicationDataChange = (data) => {
    setMedicationData(data);
  };
  useEffect(() => {
    setMedicationFilteredData(medicationData?.filter((item) => item?._id === selectedMedication));
  }, [selectedMedication])

  useEffect(() => {
    if (medicationFilteredData && medicationFilteredData.length > 0) {
      setValues(prevValues => ({
        ...prevValues,
        unitType: medicationFilteredData[0].unitType,
        medicationName: medicationFilteredData[0].medicationName,
        strength: medicationFilteredData[0].strength,
        medicineType: medicationFilteredData[0].medicineType,
      }));
    }
  }, [medicationData, medicationFilteredData]);
  // console.log("🚀 ~ AddMedicineStock ~ valuesvaluesvaluesvaluesvalues:", values)
  return (
    <>
      <div className="incidents consentCapacityAddNew">
        <form onSubmit={handleSubmit}>
          <div className="formBlockContent">
            <div className="row">
              <div className="col-md-12">
                <div className={`form-group`}>
                  <MedicineListDetail
                    label={"Medicine Name"}
                    requiredStar={true}
                    name={"medicationName"}
                    onSelect={handleMedicationSelect}
                    onMedicationDataChange={handleMedicationDataChange}
                    customError={
                      errors.medicationName && touched.medicationName
                    }
                    errorMessage={errors.medicationName}
                    errorClass={
                      errors.medicationName && touched.medicationName
                    } />
                </div>
              </div>

              <div className="col-md-6">
                <Input
                  type="text"
                  id=""
                  name="unitType"
                  value={values?.unitType}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Unit Type"
                  disabledStatus={true}
                />
              </div>

              <div className="col-md-6">
                <Input
                  type="text"
                  id=""
                  name="strength"
                  value={values?.strength}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Strength"
                  disabledStatus={true}

                />
              </div>
              <div className="col-md-12">
                <Input
                  type="text"
                  id=""
                  name="medicineType"
                  value={values?.medicineType}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Medicine Type"
                  disabledStatus={true}

                />
              </div>

              {/* <div className="col-md-12">
                <Input
                  type="text"
                  id=""
                  name="medicationName"
                  value={values?.medicationName}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Medicine Name"
                  customError={
                    errors.medicationName && touched.medicationName
                  }
                  errorMessage={errors.medicationName}
                  errorClass={
                    errors.medicationName && touched.medicationName
                  }
                  requiredStar={true}
                />
              </div> */}
              <div className="col-md-12">
                <Input
                  type="number"
                  id="inventory"
                  name="inventory"
                  value={values?.inventory}
                  onChange={handleInputChangePrevFields}
                  onBlur={handleBlur}
                  labelText="Medicine Quantity"
                  marginBottom={true}
                  customError={
                    errors.inventory && touched.inventory
                  }
                  errorMessage={errors.inventory}
                  errorClass={
                    errors.inventory && touched.inventory
                  }
                  requiredStar={true}
                />
              </div>
            </div>
          </div>

          <div className="formActionButtons">
            <span
              onClick={subModalAddMedicine}
              className="btn btn-sm btn-outline-primary"
            >
              Close
            </span>
            {isSubmitButtonClicked ? (
              <Button
                onClick={subModalAddMedicine}
                className="btn-sm"
                variant="primary"
                disabled
              >
                Loading...
              </Button>
            ) : (
              <Button className="btn-sm" variant="primary" type="submit">
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {isToastAddMedicine && <ToastContainer />}
    </>
  );
}

export default AddMedicineStock;