import React, { useState, useEffect } from "react";

import Layout from "../../components/layouts/Layout";
import Button from "react-bootstrap/Button";
import { Link, useLocation, useNavigate } from "react-router-dom";
import Input from "../../components/forms-fields/Input";
import SelectBox from "../../components/forms-fields/SelectBox";
import {
  getRecords,
  isSuccess,
  catchAsync,
  isFail,
  formateDate,
  updateRecords,
  deleteRecords,
  toastSuccessMessages,
  toastErrorMessages,
  apiQuery,
  postRecords,
  handleRedirectIfProfileNotPresent
} from "../../helper/helper";
import {
  UPDTE_RISK_ASSESSMENTS_URL,
  POST_SERVICE_USER_ENQUIRY_URL,
} from "../../env";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useFormik } from "formik";
import { addRiskAssessment } from "../../schemas";
import TextArea from "../../components/forms-fields/TextArea";

function EditAssessments() {
  // Getting user detail data
  const location = useLocation();
  const riskAssessmentsData = location?.state ? location?.state?.riskAssessmentsDetail : null; // Use null instead of an empty string
  console.log("🚀 ~ EditAssessments ~ riskAssessmentsData:", riskAssessmentsData)

  const storedUserDetails = localStorage.getItem("profileUserDetails");
  const userDetails = JSON.parse(storedUserDetails);
  const riskRatingCalculator = [];

  for (let i = 1; i <= 9; i++) {
    riskRatingCalculator.push({ value: `${i}`, label: `${i}` })
  }

  const navigate = useNavigate();

  useEffect(() => {
    handleRedirectIfProfileNotPresent('profileUserDetails', '/service-users', navigate)
  }, [navigate]);

  // console.log("🚀 ~ file: CCAddNewForm.jsx:10 ~ CCAddNewForm ~ userDetails:", userDetails);
  const [disabledStatus, setDisableStatus] = useState(true);
  const [isToast, setIsToast] = useState(false);
  const [isSubmitButtonClicked, setIsSubmitButtonClicked] = useState(false);

  const suSexOptions = [
    { value: "Male", label: "Male" },
    { value: "Female", label: "Female" },
  ];

  const [formData, setFormData] = useState({
    id: riskAssessmentsData[0]?._id || "",
    userId: riskAssessmentsData[0]?.userId || "",
    hazard: riskAssessmentsData[0]?.hazard || "",
    personsExposedToHazard: riskAssessmentsData[0]?.personsExposedToHazard || "",
    riskIdentified: riskAssessmentsData[0]?.riskIdentified || "",
    coldMeasureRequired: riskAssessmentsData[0]?.coldMeasureRequired || "",
    pureRiskRating: {
      c: riskAssessmentsData[0]?.pureRiskRating?.c || 0,
      l: riskAssessmentsData[0]?.pureRiskRating?.l || 0,
      r: riskAssessmentsData[0]?.pureRiskRating?.r || 0
    },
    inPlace: {
      y: riskAssessmentsData[0]?.inPlace?.y || 0,
      n: riskAssessmentsData[0]?.inPlace?.n || 0
    },
    residualRiskRating: {
      c: riskAssessmentsData[0]?.residualRiskRating?.c || 0,
      l: riskAssessmentsData[0]?.residualRiskRating?.l || 0,
      r: riskAssessmentsData[0]?.residualRiskRating?.r || 0
    },
  });

  console.log("FormData:", riskAssessmentsData[0]?.userId);

  const handleFormSubmit = async (values) => {
    setIsSubmitButtonClicked(true);
    try {
      const result = await postRecords(UPDTE_RISK_ASSESSMENTS_URL, values);

      if (result?.status === isSuccess) {
        setIsSubmitButtonClicked(false);
        toastSuccessMessages(
          toast?.success,
          result?.message,
          "/risk-assessments",
          500,
          500
        );
      } else if (result?.status === isFail) {
        setIsSubmitButtonClicked(false);
        toastErrorMessages(toast.error, result?.message, 500);
      }
    } catch (error) {
      // Handle error
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: formData,
      validationSchema: addRiskAssessment,
      onSubmit: handleFormSubmit, // Use the custom form submission handler
    });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    let updatedData = { ...formData };
    let trimmedValue = value.trim();

    updatedData = {
      ...updatedData,
      [name]: trimmedValue,
    };

    setFormData(updatedData);

    handleChange(e);
    handleBlur(e);
    console.log(updatedData);
  };

  return (
    <>
      <Layout
        pageTitleText={
          <>
            Risk Assessment
          </>
        }
      >
        <div className="incidents consentCapacityAddNew">
          <form onSubmit={handleSubmit}>
            <div className="mainContent ">
              <div className="mainContentText">
                <h3>Edit Risk Assessment</h3>
                <div className="formBlockContent">
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <Input
                        type="text"
                        id="hazard"
                        name="hazard"
                        value={values?.hazard}
                        labelText="Hazard"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        customError={errors.hazard && touched.hazard}
                        errorMessage={errors.hazard}
                        erroClass={errors.hazard && touched.hazard}
                        requiredStar={true}
                      />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-6 col-md-4">
                      <p><strong><small className="mt-0">Pure Risk Rating</small></strong></p>
                      <div className="riskCalculatorFields">
                        <div className="field">
                          <SelectBox
                            name="pureRiskRating.c"
                            value={values?.pureRiskRating?.c}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="C"
                            customError={
                              touched?.values?.pureRiskRating?.c && errors?.values?.pureRiskRating?.c
                            }
                            errorMessage={errors?.values?.pureRiskRating?.c}
                            errorClass={
                              touched?.values?.pureRiskRating?.c && errors?.values?.pureRiskRating?.c
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                        <div className="field">
                          <SelectBox
                            name="pureRiskRating.l"
                            value={values?.pureRiskRating?.l}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="L"
                            customError={
                              touched?.pureRiskRating?.l && errors?.pureRiskRating?.l
                            }
                            errorMessage={errors?.pureRiskRating?.l}
                            errorClass={
                              touched?.pureRiskRating?.l && errors?.pureRiskRating?.l
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                        <div className="field">
                          <SelectBox
                            name="pureRiskRating.r"
                            value={values?.pureRiskRating?.r}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="R"
                            customError={
                              touched?.pureRiskRating?.r && errors?.pureRiskRating?.r
                            }
                            errorMessage={errors?.pureRiskRating?.r}
                            errorClass={
                              touched?.pureRiskRating?.r && errors?.pureRiskRating?.r
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p><strong><small className="mt-0">In Place</small></strong></p>
                      <div className="riskCalculatorFields">
                        <div className="field">
                          <SelectBox
                            name="inPlace.y"
                            value={values?.inPlace?.y}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="Y"
                            customError={
                              touched?.inPlace?.y && errors?.inPlace?.y
                            }
                            errorMessage={errors?.inPlace?.y}
                            errorClass={
                              touched?.inPlace?.y && errors?.inPlace?.y
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                        <div className="field">
                          <SelectBox
                            name="inPlace.n"
                            value={values?.inPlace?.n}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="N"
                            customError={
                              touched?.inPlace?.n && errors?.inPlace?.n
                            }
                            errorMessage={errors?.inPlace?.n}
                            errorClass={
                              touched?.inPlace?.n && errors?.inPlace?.n
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <p><strong><small className="mt-0">Residual Risk Rating</small></strong></p>
                      <div className="riskCalculatorFields">
                        <div className="field">
                          <SelectBox
                            name="residualRiskRating.c"
                            value={values?.residualRiskRating?.c}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="C"
                            customError={
                              touched?.residualRiskRating?.c && errors?.residualRiskRating?.c
                            }
                            errorMessage={errors?.residualRiskRating?.c}
                            errorClass={
                              touched?.residualRiskRating?.c && errors?.residualRiskRating?.c
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                        <div className="field">
                          <SelectBox
                            name="residualRiskRating.l"
                            value={values?.residualRiskRating?.l}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="L"
                            customError={
                              touched?.residualRiskRating?.l && errors?.residualRiskRating?.l
                            }
                            errorMessage={errors?.residualRiskRating?.l}
                            errorClass={
                              touched?.residualRiskRating?.l && errors?.residualRiskRating?.l
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                        <div className="field">
                          <SelectBox
                            name="residualRiskRating.r"
                            value={values?.residualRiskRating?.r}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            options={riskRatingCalculator}
                            labelText="R"
                            customError={
                              touched?.residualRiskRating?.r && errors?.residualRiskRating?.r
                            }
                            errorMessage={errors?.residualRiskRating?.r}
                            errorClass={
                              touched?.residualRiskRating?.r && errors?.residualRiskRating?.r
                                ? "validationError"
                                : ""
                            }
                            requiredStar={true}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-6 col-md-4">
                      <TextArea
                        id="personsExposedToHazard"
                        name="personsExposedToHazard"
                        value={values?.personsExposedToHazard}
                        rows="5"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        labelText="Personal (s) Exposed to hazard"
                        customError={errors.personsExposedToHazard && touched.personsExposedToHazard}
                        errorMessage={errors.personsExposedToHazard}
                        erroClass={errors.personsExposedToHazard && touched.personsExposedToHazard}
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <TextArea
                        id="riskIdentified"
                        name="riskIdentified"
                        value={values?.riskIdentified}
                        rows="5"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        labelText="Risk Identified"
                        customError={errors.riskIdentified && touched.riskIdentified}
                        errorMessage={errors.riskIdentified}
                        erroClass={errors.riskIdentified && touched.riskIdentified}
                        requiredStar={true}
                      />
                    </div>
                    <div className="col-sm-6 col-md-4">
                      <TextArea
                        id="coldMeasureRequired"
                        name="coldMeasureRequired"
                        value={values?.coldMeasureRequired}
                        rows="5"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        labelText="Control Measures Required"
                        customError={errors.coldMeasureRequired && touched.coldMeasureRequired}
                        errorMessage={errors.coldMeasureRequired}
                        erroClass={errors.coldMeasureRequired && touched.coldMeasureRequired}
                        requiredStar={true}
                      />


                    </div>
                    <div className="col-md-12">
                      <div className="formActionButtons">
                        <Link
                          to="/risk-assessments"
                          className="btn btn-sm btn-outline-primary"
                        >
                          Back
                        </Link>
                        {isSubmitButtonClicked ? (
                          <Button className="btn-sm" variant="primary" disabled>
                            Loading...
                          </Button>
                        ) : (
                          <Button type="submit" className="btn-sm" variant="primary">
                            Save
                          </Button>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </form>
        </div>
      </Layout>
      <ToastContainer />
    </>
  );
}

export default EditAssessments;