import React from "react";
import Modal from "react-bootstrap/Modal";


const RateInfo = ({ onClose, item, payrollViewData }) => {
    console.log("🚀 ~ RateInfo ~ payrollViewData:", payrollViewData)
    return (
        <Modal
            show={onClose}
            onHide={onClose}
            className="ftcModalPopup"
            size={"md"}
        >
            <Modal.Header closeButton>
                <Modal.Title>Per Hour Rate Info: {payrollViewData?.staffMemberName}</Modal.Title>
            </Modal.Header>

            <Modal.Body className="careNotesPopup">
                <div className="row">
                    {
                        payrollViewData?.contractedHours?.contractedHours !== '' && (
                            <>
                                <div className="col-md-12">
                                    <p><strong>Contract:</strong>
                                        {payrollViewData?.contractedHours?.contractedHours} hours </p>
                                </div>

                                <div className="col-md-6">
                                    <p><strong>Contracted Hours Rate:</strong>
                                        £{payrollViewData?.contractedHours?.perHourRate || 0}/hour </p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Over Night Sleep Rate:</strong>
                                        £{payrollViewData?.contractedHours?.overNightSleepRate || 0}/hour </p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Enhanced Rate:</strong>
                                        £{payrollViewData?.contractedHours?.enhancedRate || 0}/hour </p>
                                </div>

                                <div className="col-md-6">
                                    <p><strong>Training Rate:</strong>
                                        £{payrollViewData?.contractedHours?.trainingRate || 0}/hour </p>
                                </div>
                                <div className="col-md-6">
                                    <p><strong>Overtime Rate:</strong>
                                        £{payrollViewData?.contractedHours?.overtimeRate || 0}/hour</p>
                                </div>

                            </>
                        )
                    }
                </div>
            </Modal.Body>

        </Modal>
    );
};

export default RateInfo;